import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'state/graphql/hooks/use-sanity-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
}

export interface IAccordionEntry {
  readonly __typename?: 'AccordionEntry';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly collapsedContent: Maybe<ILocaleString>;
  readonly expandedContent: Maybe<ILocaleString>;
}

export interface IAccordionEntryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly collapsedContent?: InputMaybe<ILocaleStringFilter>;
  readonly expandedContent?: InputMaybe<ILocaleStringFilter>;
}

export interface IAccordionEntrySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly collapsedContent?: InputMaybe<ILocaleStringSorting>;
  readonly expandedContent?: InputMaybe<ILocaleStringSorting>;
}

export interface IAccordionWidget {
  readonly __typename?: 'AccordionWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly accordionContent: Maybe<ReadonlyArray<Maybe<IAccordionEntry>>>;
  readonly accordionTitle: Maybe<ILocaleString>;
}

export interface IAccordionWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly accordionTitle?: InputMaybe<ILocaleStringFilter>;
}

export type IAccordionWidgetOrAnchorLinksWidgetOrAnchorWidgetOrCallToActionWidgetOrDoubleImageWidgetOrDownloadFileWidgetOrHeaderWidgetOrImageWidgetOrInfoCellsWidgetOrLocaleBlockTextWidgetOrLoyaltyBannerWidgetOrLoyaltyTabSelectorWidgetOrMultiWidgetOrNutritionExplorerWidgetOrNutritionInfoWidgetOrPreviewWidgetOrQuoteWidgetOrRewardsCarouselWidgetOrVideoWidgetOrWebViewWidgetOrYoutubeWidget =

    | IAccordionWidget
    | IAnchorLinksWidget
    | IAnchorWidget
    | ICallToActionWidget
    | IDoubleImageWidget
    | IDownloadFileWidget
    | IHeaderWidget
    | IImageWidget
    | IInfoCellsWidget
    | ILocaleBlockTextWidget
    | ILoyaltyBannerWidget
    | ILoyaltyTabSelectorWidget
    | IMultiWidget
    | INutritionExplorerWidget
    | INutritionInfoWidget
    | IPreviewWidget
    | IQuoteWidget
    | IRewardsCarouselWidget
    | IVideoWidget
    | IWebViewWidget
    | IYoutubeWidget;

export interface IAccordionWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly accordionTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IActionButton {
  readonly __typename?: 'ActionButton';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttonPath: Maybe<ILocaleString>;
  readonly buttonText: Maybe<ILocaleString>;
}

export interface IActionButtonFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly buttonPath?: InputMaybe<ILocaleStringFilter>;
  readonly buttonText?: InputMaybe<ILocaleStringFilter>;
}

export interface IActionButtonNotRequired {
  readonly __typename?: 'ActionButtonNotRequired';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttonPath: Maybe<ILocaleString>;
  readonly buttonText: Maybe<ILocaleString>;
}

export interface IActionButtonNotRequiredFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly buttonPath?: InputMaybe<ILocaleStringFilter>;
  readonly buttonText?: InputMaybe<ILocaleStringFilter>;
}

export interface IActionButtonNotRequiredSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly buttonPath?: InputMaybe<ILocaleStringSorting>;
  readonly buttonText?: InputMaybe<ILocaleStringSorting>;
}

export interface IActionButtonSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly buttonPath?: InputMaybe<ILocaleStringSorting>;
  readonly buttonText?: InputMaybe<ILocaleStringSorting>;
}

export interface IAdaptableMenuCard extends IDocument {
  readonly __typename?: 'AdaptableMenuCard';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundColor: Maybe<IColor>;
  readonly buttonBackgroundColor: Maybe<IColor>;
  readonly buttonTextColor: Maybe<IColor>;
  readonly ctaButtonText: Maybe<ILocaleString>;
  readonly description: Maybe<ILocaleString>;
  /** Use custom text and background colors. Please ensure that the colors meet the guidelines for contrast accessibility (https://accessible-colors.com/) */
  readonly hasCustomColors: Maybe<Scalars['Boolean']['output']>;
  readonly hasTerms: Maybe<Scalars['Boolean']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  /** Menu element linked to the card, usefull to check availability and generate the card url link */
  readonly menuElement: Maybe<IComboOrItemOrPickerOrSystemwideOffer>;
  readonly path: Maybe<ILocaleUrl>;
  readonly termsButton: Maybe<ILinkAction>;
  readonly termsText: Maybe<ILocaleBlockContent>;
  readonly textColor: Maybe<IColor>;
}

export interface IAdaptableMenuCardFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundColor?: InputMaybe<IColorFilter>;
  readonly buttonBackgroundColor?: InputMaybe<IColorFilter>;
  readonly buttonTextColor?: InputMaybe<IColorFilter>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly hasCustomColors?: InputMaybe<IBooleanFilter>;
  readonly hasTerms?: InputMaybe<IBooleanFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly path?: InputMaybe<ILocaleUrlFilter>;
  readonly termsButton?: InputMaybe<ILinkActionFilter>;
  readonly termsText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly textColor?: InputMaybe<IColorFilter>;
}

export interface IAdaptableMenuCardGroup extends IDocument {
  readonly __typename?: 'AdaptableMenuCardGroup';
  readonly Cards: Maybe<ReadonlyArray<Maybe<IAdaptableMenuCard>>>;
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IAdaptableMenuCardGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IAdaptableMenuCardGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IAdaptableMenuCardSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundColor?: InputMaybe<IColorSorting>;
  readonly buttonBackgroundColor?: InputMaybe<IColorSorting>;
  readonly buttonTextColor?: InputMaybe<IColorSorting>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly hasCustomColors?: InputMaybe<SortOrder>;
  readonly hasTerms?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly path?: InputMaybe<ILocaleUrlSorting>;
  readonly termsButton?: InputMaybe<ILinkActionSorting>;
  readonly termsText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly textColor?: InputMaybe<IColorSorting>;
}

export interface IAddOnSection extends IDocument {
  readonly __typename?: 'AddOnSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** By turning this on, guests will be able to add the options in the list of add-ons for free to their order. You can choose which items in the order are eligible to grant free add-ons and also how many add-on units will be free of charge per item. Note: to list add-ons as free, there needs to be a 0.00 PLU item configured respectively to the regular price PLU. */
  readonly enableAddonAsFreeItem: Maybe<Scalars['Boolean']['output']>;
  /** This is the max limit for the entire section in total. This will override any max set for individual items if this limit is reached before the items limit. Leaving this blank will not put a limit on the section itself. */
  readonly maxAmount: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IAddOnSectionOption>>>;
  readonly sectionItemsServiceModes: Maybe<IAddOnSectionServiceModes>;
  /** All the items from this section will be available directly in the cart. The section name will be the header title. */
  readonly showSectionItemsOnCart: Maybe<Scalars['Boolean']['output']>;
}

export interface IAddOnSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly enableAddonAsFreeItem?: InputMaybe<IBooleanFilter>;
  readonly maxAmount?: InputMaybe<IFloatFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly sectionItemsServiceModes?: InputMaybe<IAddOnSectionServiceModesFilter>;
  readonly showSectionItemsOnCart?: InputMaybe<IBooleanFilter>;
}

export interface IAddOnSectionFreeModalFieldset {
  readonly __typename?: 'AddOnSectionFreeModalFieldset';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  readonly primaryButtonText: Maybe<ILocaleString>;
  readonly showFreeAddOnModal: Maybe<Scalars['Boolean']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface IAddOnSectionFreeModalFieldsetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly primaryButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly showFreeAddOnModal?: InputMaybe<IBooleanFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IAddOnSectionFreeModalFieldsetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly primaryButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly showFreeAddOnModal?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IAddOnSectionItemAcceptingFreeOffer {
  readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly itemAcceptingFreeOffer: Maybe<IComboOrItem>;
  /** @deprecated This has been deprecated */
  readonly itemAcceptingFreeOffers: Maybe<IItem>;
  /** This is the max limit for the Free Add On Item that will be offered by this Menu Option. */
  readonly maxFreeQuantity: Maybe<Scalars['Float']['output']>;
}

export interface IAddOnSectionItemAcceptingFreeOfferFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly itemAcceptingFreeOffers?: InputMaybe<IItemFilter>;
  readonly maxFreeQuantity?: InputMaybe<IFloatFilter>;
}

export interface IAddOnSectionItemAcceptingFreeOfferSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly maxFreeQuantity?: InputMaybe<SortOrder>;
}

export interface IAddOnSectionOption {
  readonly __typename?: 'AddOnSectionOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly addOnSectionFreeModalInfos: Maybe<IAddOnSectionFreeModalFieldset>;
  /** The free option needs to be a 0.00 PLU version of the regular price option. If the options listed here are not 0.00 PLU, they will NOT appear as free for the guest. */
  readonly freeOption: Maybe<IItem>;
  readonly itemsAcceptingFreeOffers: Maybe<
    ReadonlyArray<Maybe<IAddOnSectionItemAcceptingFreeOffer>>
  >;
  /** This is the max limit for this individual Add On Item. If there is a section max we will use that instead based on the other items in the section. If this is left blank this item will be unlimited unless there is a section max. */
  readonly maxAmount: Maybe<Scalars['Float']['output']>;
  readonly option: Maybe<IItem>;
}

export interface IAddOnSectionOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly addOnSectionFreeModalInfos?: InputMaybe<IAddOnSectionFreeModalFieldsetFilter>;
  readonly freeOption?: InputMaybe<IItemFilter>;
  readonly maxAmount?: InputMaybe<IFloatFilter>;
  readonly option?: InputMaybe<IItemFilter>;
}

export interface IAddOnSectionOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly addOnSectionFreeModalInfos?: InputMaybe<IAddOnSectionFreeModalFieldsetSorting>;
  readonly maxAmount?: InputMaybe<SortOrder>;
}

export interface IAddOnSectionServiceModes {
  readonly __typename?: 'AddOnSectionServiceModes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cateringDeliveryServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly cateringPickUpServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly curbsideServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly deliveryServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly dineInServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly driveThruServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly pickUpServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly tableServiceMode: Maybe<Scalars['Boolean']['output']>;
}

export interface IAddOnSectionServiceModesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cateringDeliveryServiceMode?: InputMaybe<IBooleanFilter>;
  readonly cateringPickUpServiceMode?: InputMaybe<IBooleanFilter>;
  readonly curbsideServiceMode?: InputMaybe<IBooleanFilter>;
  readonly deliveryServiceMode?: InputMaybe<IBooleanFilter>;
  readonly dineInServiceMode?: InputMaybe<IBooleanFilter>;
  readonly driveThruServiceMode?: InputMaybe<IBooleanFilter>;
  readonly pickUpServiceMode?: InputMaybe<IBooleanFilter>;
  readonly tableServiceMode?: InputMaybe<IBooleanFilter>;
}

export interface IAddOnSectionServiceModesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cateringDeliveryServiceMode?: InputMaybe<SortOrder>;
  readonly cateringPickUpServiceMode?: InputMaybe<SortOrder>;
  readonly curbsideServiceMode?: InputMaybe<SortOrder>;
  readonly deliveryServiceMode?: InputMaybe<SortOrder>;
  readonly dineInServiceMode?: InputMaybe<SortOrder>;
  readonly driveThruServiceMode?: InputMaybe<SortOrder>;
  readonly pickUpServiceMode?: InputMaybe<SortOrder>;
  readonly tableServiceMode?: InputMaybe<SortOrder>;
}

export interface IAddOnSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly enableAddonAsFreeItem?: InputMaybe<SortOrder>;
  readonly maxAmount?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly sectionItemsServiceModes?: InputMaybe<IAddOnSectionServiceModesSorting>;
  readonly showSectionItemsOnCart?: InputMaybe<SortOrder>;
}

export interface IAddPaymentMethod {
  readonly __typename?: 'AddPaymentMethod';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** If true, this indicates to the White Label platform that the payment will be processed online through the platform. If false, the payment will be handled offline, outside the platform. */
  readonly isOnlinePayment: Maybe<Scalars['Boolean']['output']>;
  /** The predefined payment method brand key used to link DOP payment methods with the White Label platform (e.g., PAYMENT_ON_DELIVERY_CARD). */
  readonly paymentMethodBrand: Maybe<Scalars['String']['output']>;
  /** This determines how the payment method will be displayed in the list of Payment Methods (Sanity only). The name displayed on Whitelabel and DOP must be configured in Lokalise. */
  readonly paymentName: Maybe<Scalars['String']['output']>;
}

export interface IAddPaymentMethodFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isOnlinePayment?: InputMaybe<IBooleanFilter>;
  readonly paymentMethodBrand?: InputMaybe<IStringFilter>;
  readonly paymentName?: InputMaybe<IStringFilter>;
}

export interface IAddPaymentMethodSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isOnlinePayment?: InputMaybe<SortOrder>;
  readonly paymentMethodBrand?: InputMaybe<SortOrder>;
  readonly paymentName?: InputMaybe<SortOrder>;
}

export interface IAdditionalDetails {
  readonly __typename?: 'AdditionalDetails';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly additionalDetailsList: Maybe<ReadonlyArray<Maybe<IAdditionalDetailsList>>>;
}

export interface IAdditionalDetailsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IAdditionalDetailsList {
  readonly __typename?: 'AdditionalDetailsList';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** To indicate whether to put inside a collapsible dropdown or not */
  readonly collapsed: Maybe<Scalars['Boolean']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** Is Personal Identifiable Information */
  readonly isPii: Maybe<Scalars['Boolean']['output']>;
  readonly key: Maybe<Scalars['String']['output']>;
  /** Regular expressions (regex) is a pretty standard way to do text validations. E.g: [A-Z]{9} validates for 9 capital letters without spaces and [0-9]{5,} validates for at least 5 digit numbers without spaces. [A-Za-z ]{9} validates for 9 letters with spaces. Checkout this link for more https://gist.github.com/vitorbritto/9ff58ef998100b8f19a0 */
  readonly regex: Maybe<Scalars['String']['output']>;
  readonly required: Maybe<Scalars['Boolean']['output']>;
  readonly validationError: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly validationErrorMessage: Maybe<Scalars['String']['output']>;
}

export interface IAdditionalDetailsListFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly collapsed?: InputMaybe<IBooleanFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly isPii?: InputMaybe<IBooleanFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly regex?: InputMaybe<IStringFilter>;
  readonly required?: InputMaybe<IBooleanFilter>;
  readonly validationError?: InputMaybe<ILocaleStringFilter>;
  readonly validationErrorMessage?: InputMaybe<IStringFilter>;
}

export interface IAdditionalDetailsListSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly collapsed?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly isPii?: InputMaybe<SortOrder>;
  readonly key?: InputMaybe<SortOrder>;
  readonly regex?: InputMaybe<SortOrder>;
  readonly required?: InputMaybe<SortOrder>;
  readonly validationError?: InputMaybe<ILocaleStringSorting>;
  readonly validationErrorMessage?: InputMaybe<SortOrder>;
}

export interface IAdditionalDetailsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IAdditionalItemInformation {
  readonly __typename?: 'AdditionalItemInformation';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly additives: Maybe<ILocaleString>;
  readonly ingredients: Maybe<ILocaleString>;
  readonly producerDetails: Maybe<ILocaleString>;
  /** Only used by BK DE - Legal Requirement */
  readonly sourcesOfGluten: Maybe<Scalars['String']['output']>;
}

export interface IAdditionalItemInformationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly additives?: InputMaybe<ILocaleStringFilter>;
  readonly ingredients?: InputMaybe<ILocaleStringFilter>;
  readonly producerDetails?: InputMaybe<ILocaleStringFilter>;
  readonly sourcesOfGluten?: InputMaybe<IStringFilter>;
}

export interface IAdditionalItemInformationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly additives?: InputMaybe<ILocaleStringSorting>;
  readonly ingredients?: InputMaybe<ILocaleStringSorting>;
  readonly producerDetails?: InputMaybe<ILocaleStringSorting>;
  readonly sourcesOfGluten?: InputMaybe<SortOrder>;
}

export interface IAdditionalTimeSlot {
  readonly __typename?: 'AdditionalTimeSlot';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly close: Maybe<Scalars['String']['output']>;
  readonly open: Maybe<Scalars['String']['output']>;
}

export interface IAdditionalTimeSlotFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly close?: InputMaybe<IStringFilter>;
  readonly open?: InputMaybe<IStringFilter>;
}

export interface IAdditionalTimeSlotSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly close?: InputMaybe<SortOrder>;
  readonly open?: InputMaybe<SortOrder>;
}

export interface IAddress {
  readonly __typename?: 'Address';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly address1: Maybe<Scalars['String']['output']>;
  readonly address2: Maybe<Scalars['String']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly stateProvince: Maybe<Scalars['String']['output']>;
  readonly stateProvinceShort: Maybe<Scalars['String']['output']>;
}

export interface IAddressFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly address1?: InputMaybe<IStringFilter>;
  readonly address2?: InputMaybe<IStringFilter>;
  readonly city?: InputMaybe<IStringFilter>;
  readonly country?: InputMaybe<IStringFilter>;
  readonly postalCode?: InputMaybe<IStringFilter>;
  readonly stateProvince?: InputMaybe<IStringFilter>;
  readonly stateProvinceShort?: InputMaybe<IStringFilter>;
}

export interface IAddressSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly address1?: InputMaybe<SortOrder>;
  readonly address2?: InputMaybe<SortOrder>;
  readonly city?: InputMaybe<SortOrder>;
  readonly country?: InputMaybe<SortOrder>;
  readonly postalCode?: InputMaybe<SortOrder>;
  readonly stateProvince?: InputMaybe<SortOrder>;
  readonly stateProvinceShort?: InputMaybe<SortOrder>;
}

export interface IAdminToolReleaseNotes extends IDocument {
  readonly __typename?: 'AdminToolReleaseNotes';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly releaseDate: Maybe<Scalars['Date']['output']>;
  readonly releaseNotesRaw: Maybe<Scalars['JSON']['output']>;
  readonly releaseNotesTitle: Maybe<Scalars['String']['output']>;
  readonly syncReleaseNotes: Maybe<Scalars['String']['output']>;
}

export interface IAdminToolReleaseNotesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly releaseDate?: InputMaybe<IDateFilter>;
  readonly releaseNotesTitle?: InputMaybe<IStringFilter>;
  readonly syncReleaseNotes?: InputMaybe<IStringFilter>;
}

export interface IAdminToolReleaseNotesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly releaseDate?: InputMaybe<SortOrder>;
  readonly releaseNotesTitle?: InputMaybe<SortOrder>;
  readonly syncReleaseNotes?: InputMaybe<SortOrder>;
}

export interface IAdvancedBookingConfig {
  readonly __typename?: 'AdvancedBookingConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly birthday: Maybe<Scalars['Float']['output']>;
  readonly catering: Maybe<Scalars['Float']['output']>;
}

export interface IAdvancedBookingConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly birthday?: InputMaybe<IFloatFilter>;
  readonly catering?: InputMaybe<IFloatFilter>;
}

export interface IAdvancedBookingConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly birthday?: InputMaybe<SortOrder>;
  readonly catering?: InputMaybe<SortOrder>;
}

export interface IAdyenConfig {
  readonly __typename?: 'AdyenConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly clientKey: Maybe<Scalars['String']['output']>;
  readonly environment: Maybe<Scalars['String']['output']>;
  readonly urlPrefix: Maybe<Scalars['String']['output']>;
}

export interface IAdyenConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly clientKey?: InputMaybe<IStringFilter>;
  readonly environment?: InputMaybe<IStringFilter>;
  readonly urlPrefix?: InputMaybe<IStringFilter>;
}

export interface IAdyenConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly clientKey?: InputMaybe<SortOrder>;
  readonly environment?: InputMaybe<SortOrder>;
  readonly urlPrefix?: InputMaybe<SortOrder>;
}

export interface IAggregatorRedirectButton {
  readonly __typename?: 'AggregatorRedirectButton';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isActive: Maybe<Scalars['Boolean']['output']>;
  /** Name of the aggregator */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<ILocaleString>;
  /** Url to the aggregator platform */
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface IAggregatorRedirectButtonFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isActive?: InputMaybe<IBooleanFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
  readonly url?: InputMaybe<IStringFilter>;
}

export interface IAggregatorRedirectButtonSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isActive?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
  readonly url?: InputMaybe<SortOrder>;
}

export interface IAlert extends IDocument {
  readonly __typename?: 'Alert';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly body: Maybe<ILocaleString>;
  readonly heading: Maybe<ILocaleString>;
  readonly learnMoreText: Maybe<ILocaleString>;
  readonly learnMoreUrl: Maybe<ILocaleString>;
}

export interface IAlertFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly heading?: InputMaybe<ILocaleStringFilter>;
  readonly learnMoreText?: InputMaybe<ILocaleStringFilter>;
  readonly learnMoreUrl?: InputMaybe<ILocaleStringFilter>;
}

export type IAlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrHeroOrLockedOffersOrMainHeroOrMarketingCardGroupOrMarketingTileCarouselOrMarketingTileGroupOrOfferCarouselSectionOrOffersSectionOrOurServicesOrQuestsSectionOrRecentItemsOrRewardsSectionOrRollUpTheRimSectionOrScanForRewardsOrSimpleHomePageOrTrending =

    | IAlert
    | IAppDownload
    | IBrazeContentCards
    | IFanFavorites
    | IHero
    | ILockedOffers
    | IMainHero
    | IMarketingCardGroup
    | IMarketingTileCarousel
    | IMarketingTileGroup
    | IOfferCarouselSection
    | IOffersSection
    | IOurServices
    | IQuestsSection
    | IRecentItems
    | IRewardsSection
    | IRollUpTheRimSection
    | IScanForRewards
    | ISimpleHomePage
    | ITrending;

export type IAlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrLockedOffersOrMainHeroOrMarketingTileGroupOrOffersSectionOrOurServicesOrRecentItemsOrTrending =

    | IAlert
    | IAppDownload
    | IBrazeContentCards
    | IFanFavorites
    | ILockedOffers
    | IMainHero
    | IMarketingTileGroup
    | IOffersSection
    | IOurServices
    | IRecentItems
    | ITrending;

export interface IAlertSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly heading?: InputMaybe<ILocaleStringSorting>;
  readonly learnMoreText?: InputMaybe<ILocaleStringSorting>;
  readonly learnMoreUrl?: InputMaybe<ILocaleStringSorting>;
}

export interface IAllergensConfig {
  readonly __typename?: 'AllergensConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly maximum: Maybe<Scalars['Float']['output']>;
  readonly minimum: Maybe<Scalars['Float']['output']>;
}

export interface IAllergensConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly maximum?: InputMaybe<IFloatFilter>;
  readonly minimum?: InputMaybe<IFloatFilter>;
}

export interface IAllergensConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly maximum?: InputMaybe<SortOrder>;
  readonly minimum?: InputMaybe<SortOrder>;
}

export interface IAllowRedemptionWithoutLogin {
  readonly __typename?: 'AllowRedemptionWithoutLogin';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly allowRedemptionWithoutLogin: Maybe<Scalars['String']['output']>;
}

export interface IAllowRedemptionWithoutLoginFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly allowRedemptionWithoutLogin?: InputMaybe<IStringFilter>;
}

export type IAllowRedemptionWithoutLoginOrAndOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrOrOrOrderHistoryOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions =

    | IAllowRedemptionWithoutLogin
    | IAnd
    | IBetweenDates
    | IBetweenTimes
    | ICartContainsPlu
    | ICartProperty
    | ICoolDown
    | IDayOfWeekBand
    | IFirstOrderOnly
    | ILimit
    | IOr
    | IOrderHistory
    | IPaymentCardType
    | IPurchase
    | IRequiresAssignment
    | IRestaurantGroupRestrictions;

export type IAllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrIsStackableOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions =

    | IAllowRedemptionWithoutLogin
    | IBetweenDates
    | IBetweenTimes
    | ICartContainsPlu
    | ICartProperty
    | ICartRequirement
    | ICoolDown
    | IDayOfWeekBand
    | IFirstOrderOnly
    | IIsStackable
    | ILimit
    | IPaymentCardType
    | IPurchase
    | IRequiresAssignment
    | IRestaurantGroupRestrictions;

export type IAllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions =

    | IAllowRedemptionWithoutLogin
    | IBetweenDates
    | IBetweenTimes
    | ICartContainsPlu
    | ICartProperty
    | ICartRequirement
    | ICoolDown
    | IDayOfWeekBand
    | IFirstOrderOnly
    | ILimit
    | IPaymentCardType
    | IPurchase
    | IRequiresAssignment
    | IRestaurantGroupRestrictions;

export interface IAllowRedemptionWithoutLoginSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly allowRedemptionWithoutLogin?: InputMaybe<SortOrder>;
}

export interface IAnchorLinksWidget {
  readonly __typename?: 'AnchorLinksWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface IAnchorLinksWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IAnchorLinksWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IAnchorWidget {
  readonly __typename?: 'AnchorWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly anchorName: Maybe<ILocaleString>;
}

export interface IAnchorWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly anchorName?: InputMaybe<ILocaleStringFilter>;
}

export interface IAnchorWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly anchorName?: InputMaybe<ILocaleStringSorting>;
}

export interface IAnd {
  readonly __typename?: 'And';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IAllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrIsStackableOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions>
    >
  >;
}

export interface IAndFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export type IAndOrBetweenTimesOrCartContainsPluOrCartPropertyOrDayOfWeekBandOrOrOrPaymentCardTypeOrPurchase =

    | IAnd
    | IBetweenTimes
    | ICartContainsPlu
    | ICartProperty
    | IDayOfWeekBand
    | IOr
    | IPaymentCardType
    | IPurchase;

export interface IAndSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IApiKeysConfig {
  readonly __typename?: 'ApiKeysConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly amplitude: Maybe<Scalars['String']['output']>;
  readonly amplitudeExperiments: Maybe<Scalars['String']['output']>;
  readonly bloomreachProjectToken: Maybe<Scalars['String']['output']>;
  /** This field is to declare which tenant of Bloomreach we are going to send the events */
  readonly bloomreachTarget: Maybe<Scalars['String']['output']>;
  readonly branch: Maybe<Scalars['String']['output']>;
  /** Found in "Braze" > "Manage Settings" > "Settings" > "API Key". */
  readonly braze: Maybe<Scalars['String']['output']>;
  /** Cluster defined in the market. */
  readonly brazeCluster: Maybe<Scalars['String']['output']>;
  readonly bringg: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. Do not use. */
  readonly googleAnalytics: Maybe<Scalars['String']['output']>;
  readonly googleMaps: Maybe<Scalars['String']['output']>;
  readonly googleMapsAndroid: Maybe<Scalars['String']['output']>;
  readonly googleMapsIOS: Maybe<Scalars['String']['output']>;
  readonly googleMapsWeb: Maybe<Scalars['String']['output']>;
  readonly launchDarkly: Maybe<Scalars['String']['output']>;
  readonly launchDarklyMobileKey: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. Do not use. */
  readonly launchDarklyRestApiKey: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. Do not use. */
  readonly logRocket: Maybe<Scalars['String']['output']>;
  readonly mParticle: Maybe<Scalars['String']['output']>;
  readonly radar: Maybe<Scalars['String']['output']>;
  readonly salesForceToken: Maybe<Scalars['String']['output']>;
  readonly tomtom: Maybe<Scalars['String']['output']>;
}

export interface IApiKeysConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly amplitude?: InputMaybe<IStringFilter>;
  readonly amplitudeExperiments?: InputMaybe<IStringFilter>;
  readonly bloomreachProjectToken?: InputMaybe<IStringFilter>;
  readonly bloomreachTarget?: InputMaybe<IStringFilter>;
  readonly branch?: InputMaybe<IStringFilter>;
  readonly braze?: InputMaybe<IStringFilter>;
  readonly brazeCluster?: InputMaybe<IStringFilter>;
  readonly bringg?: InputMaybe<IStringFilter>;
  readonly googleAnalytics?: InputMaybe<IStringFilter>;
  readonly googleMaps?: InputMaybe<IStringFilter>;
  readonly googleMapsAndroid?: InputMaybe<IStringFilter>;
  readonly googleMapsIOS?: InputMaybe<IStringFilter>;
  readonly googleMapsWeb?: InputMaybe<IStringFilter>;
  readonly launchDarkly?: InputMaybe<IStringFilter>;
  readonly launchDarklyMobileKey?: InputMaybe<IStringFilter>;
  readonly launchDarklyRestApiKey?: InputMaybe<IStringFilter>;
  readonly logRocket?: InputMaybe<IStringFilter>;
  readonly mParticle?: InputMaybe<IStringFilter>;
  readonly radar?: InputMaybe<IStringFilter>;
  readonly salesForceToken?: InputMaybe<IStringFilter>;
  readonly tomtom?: InputMaybe<IStringFilter>;
}

export interface IApiKeysConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly amplitude?: InputMaybe<SortOrder>;
  readonly amplitudeExperiments?: InputMaybe<SortOrder>;
  readonly bloomreachProjectToken?: InputMaybe<SortOrder>;
  readonly bloomreachTarget?: InputMaybe<SortOrder>;
  readonly branch?: InputMaybe<SortOrder>;
  readonly braze?: InputMaybe<SortOrder>;
  readonly brazeCluster?: InputMaybe<SortOrder>;
  readonly bringg?: InputMaybe<SortOrder>;
  readonly googleAnalytics?: InputMaybe<SortOrder>;
  readonly googleMaps?: InputMaybe<SortOrder>;
  readonly googleMapsAndroid?: InputMaybe<SortOrder>;
  readonly googleMapsIOS?: InputMaybe<SortOrder>;
  readonly googleMapsWeb?: InputMaybe<SortOrder>;
  readonly launchDarkly?: InputMaybe<SortOrder>;
  readonly launchDarklyMobileKey?: InputMaybe<SortOrder>;
  readonly launchDarklyRestApiKey?: InputMaybe<SortOrder>;
  readonly logRocket?: InputMaybe<SortOrder>;
  readonly mParticle?: InputMaybe<SortOrder>;
  readonly radar?: InputMaybe<SortOrder>;
  readonly salesForceToken?: InputMaybe<SortOrder>;
  readonly tomtom?: InputMaybe<SortOrder>;
}

export interface IAppData extends IDocument {
  readonly __typename?: 'AppData';
  /**
   * Date the document was created
   * @deprecated This has been deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This has been deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This has been deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This has been deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This has been deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This has been deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly aboutMenuEntries: Maybe<INavigationSection>;
  /** @deprecated This has been deprecated */
  readonly appDownloadSection: Maybe<IAppDownloadSection>;
  /** @deprecated This has been deprecated */
  readonly appHeaderText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly appImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly appImageLocale: Maybe<ILocaleImage>;
  /**
   * was moved to Feature Mobile App Configuration
   * @deprecated This has been deprecated
   */
  readonly appVersion: Maybe<IAppVersion>;
  /** @deprecated This has been deprecated */
  readonly appleWalletImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly calorieDisclaimer: Maybe<ILocaleBlockContent>;
  /**
   * was moved to Feature/Feature Menu
   * @deprecated This has been deprecated
   */
  readonly cateringMenu: Maybe<IMenu>;
  /** @deprecated This has been deprecated */
  readonly cateringSection: Maybe<ISection>;
  /** @deprecated This has been deprecated */
  readonly communicationPreferencesDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly communicationPreferencesDisclaimerBlock: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly contentCardsHeader: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly contentCardsSubheader: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly copyrightAndTrademarkDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly countryDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayPart>>>;
  /** @deprecated This has been deprecated */
  readonly defaultHeroImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly defaultHeroImageLocale: Maybe<ILocaleImage>;
  /**
   * was moved to Feature/Feature Menu
   * @deprecated This has been deprecated
   */
  readonly defaultMenu: Maybe<IMenu>;
  /** @deprecated This has been deprecated */
  readonly emailAndPushOptInSettings: Maybe<
    ReadonlyArray<Maybe<IOptInSettingBooleanOrOptInSettingEmailAndPushOrOptInSettingEnum>>
  >;
  /** @deprecated This has been deprecated */
  readonly fanFavoriteItems: Maybe<ReadonlyArray<Maybe<IComboOrItemOrOfferOrPickerOrSection>>>;
  /** @deprecated This has been deprecated */
  readonly favoriteSection: Maybe<IFavoriteSection>;
  /** @deprecated This has been deprecated */
  readonly geolocationModalImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly geolocationModalImageUseLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly giftCardImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly giftCardTextImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly googlePayImage: Maybe<ILocaleImage>;
  /**
   * Is the title of this document
   * @deprecated This has been deprecated
   */
  readonly headerText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly heroAction: Maybe<ILinkAction>;
  /** @deprecated This has been deprecated */
  readonly heroActionAuthenticated: Maybe<ILinkAction>;
  /** @deprecated This has been deprecated */
  readonly heroActionLeft: Maybe<ILinkAction>;
  /** @deprecated This has been deprecated */
  readonly heroActionLeftIcon: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly heroActionRight: Maybe<ILinkAction>;
  /** @deprecated This has been deprecated */
  readonly heroActionRightIcon: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly heroMaskCards: Maybe<ReadonlyArray<Maybe<ISimpleCard>>>;
  /** @deprecated This has been deprecated */
  readonly heroSubText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly heroSubTextAuthenticated: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly heroSuperText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly heroSuperTextAuthenticated: Maybe<ILocaleString>;
  /**
   * Is visible to Unauthenticated users
   * @deprecated This has been deprecated
   */
  readonly heroText: Maybe<Scalars['String']['output']>;
  /**
   * Is visible to Authenticated users
   * @deprecated This has been deprecated
   */
  readonly heroTextAuthenticated: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly heroTextAuthenticatedLocale: Maybe<ILocaleString>;
  /**
   * was moved to Components/Main Hero; Color for all hero content text. White and black align with the brand color scheme equivalent
   * @deprecated This has been deprecated
   */
  readonly heroTextColor: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly heroTextLocale: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly homeGraphic: Maybe<IHomeGraphic>;
  /** @deprecated This has been deprecated */
  readonly imagesDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly kioskAppData: Maybe<IKioskAppData>;
  /** @deprecated This has been deprecated */
  readonly kioskAttractLoopImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly kioskAttractLoopImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly loadingGraphics: Maybe<ILoadingGraphics>;
  /** @deprecated This has been deprecated */
  readonly loyaltyCardImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly loyaltyHeaderBackgroundImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly loyaltyHeroAction: Maybe<ILinkAction>;
  /** @deprecated This has been deprecated */
  readonly loyaltyHeroSubText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly loyaltyHeroSuperText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly loyaltyInfoImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroImageAuthenticated: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroImageAuthenticatedLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroMobileImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroMobileImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly menuHeroImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly menuHeroImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly menuHeroText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly menuHeroTextLocale: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly offersDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly ourAppHeroImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly ourAppHeroImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly ourServicesHeroImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly ourServicesHeroImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly phoneNumberDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly priceDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly promotions: Maybe<
    ReadonlyArray<Maybe<IComboOrExternalLinkOrItemOrOfferOrPickerOrSection>>
  >;
  /** @deprecated This has been deprecated */
  readonly recentOrderHeroText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly recentOrderHeroTextLocale: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly reservedRightsDisclaimer: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly rewardsMainImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly rewardsMainImageLocale: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly servicesHeaderText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly servicesHeaderTextLocale: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly servicesHeroText: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly servicesHeroTextLocale: Maybe<ILocaleString>;
  /**
   * can be added in Feature Homepage
   * @deprecated This has been deprecated
   */
  readonly siteAlert: Maybe<IAlert>;
  /** @deprecated This has been deprecated */
  readonly sodiumDisclaimer: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly sortedOffers: Maybe<ReadonlyArray<Maybe<IOffer>>>;
  /** @deprecated This has been deprecated */
  readonly storeLocatorContent: Maybe<IStoreLocatorContent>;
  /** @deprecated This has been deprecated */
  readonly superUpsellDisclaimer: Maybe<ILocaleBlockContent>;
  /**
   * was moved to Feature/Feature Menu
   * @deprecated This has been deprecated
   */
  readonly upsellItemAtCheckout: Maybe<IItem>;
  /** @deprecated This has been deprecated */
  readonly upsellItemsAtCheckout: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This has been deprecated */
  readonly upsellItemsAtCheckoutCatering: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This has been deprecated */
  readonly upsellItemsAtCheckoutDelivery: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This has been deprecated */
  readonly upsellItemsAtCheckoutRestaurant: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This has been deprecated */
  readonly useSideBySideHeroButtons: Maybe<Scalars['Boolean']['output']>;
}

export interface IAppDataFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionFilter>;
  readonly appDownloadSection?: InputMaybe<IAppDownloadSectionFilter>;
  readonly appHeaderText?: InputMaybe<IStringFilter>;
  readonly appImage?: InputMaybe<IImageFilter>;
  readonly appImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly appVersion?: InputMaybe<IAppVersionFilter>;
  readonly appleWalletImage?: InputMaybe<ILocaleImageFilter>;
  readonly calorieDisclaimer?: InputMaybe<ILocaleBlockContentFilter>;
  readonly cateringMenu?: InputMaybe<IMenuFilter>;
  readonly cateringSection?: InputMaybe<ISectionFilter>;
  readonly communicationPreferencesDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly communicationPreferencesDisclaimerBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly contentCardsHeader?: InputMaybe<ILocaleStringFilter>;
  readonly contentCardsSubheader?: InputMaybe<ILocaleStringFilter>;
  readonly copyrightAndTrademarkDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly countryDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly defaultHeroImage?: InputMaybe<IImageFilter>;
  readonly defaultHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly defaultMenu?: InputMaybe<IMenuFilter>;
  readonly favoriteSection?: InputMaybe<IFavoriteSectionFilter>;
  readonly geolocationModalImage?: InputMaybe<IImageFilter>;
  readonly geolocationModalImageUseLocale?: InputMaybe<ILocaleImageFilter>;
  readonly giftCardImage?: InputMaybe<ILocaleImageFilter>;
  readonly giftCardTextImage?: InputMaybe<ILocaleImageFilter>;
  readonly googlePayImage?: InputMaybe<ILocaleImageFilter>;
  readonly headerText?: InputMaybe<IStringFilter>;
  readonly heroAction?: InputMaybe<ILinkActionFilter>;
  readonly heroActionAuthenticated?: InputMaybe<ILinkActionFilter>;
  readonly heroActionLeft?: InputMaybe<ILinkActionFilter>;
  readonly heroActionLeftIcon?: InputMaybe<ILocaleImageFilter>;
  readonly heroActionRight?: InputMaybe<ILinkActionFilter>;
  readonly heroActionRightIcon?: InputMaybe<ILocaleImageFilter>;
  readonly heroSubText?: InputMaybe<ILocaleStringFilter>;
  readonly heroSubTextAuthenticated?: InputMaybe<ILocaleStringFilter>;
  readonly heroSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly heroSuperTextAuthenticated?: InputMaybe<ILocaleStringFilter>;
  readonly heroText?: InputMaybe<IStringFilter>;
  readonly heroTextAuthenticated?: InputMaybe<IStringFilter>;
  readonly heroTextAuthenticatedLocale?: InputMaybe<ILocaleStringFilter>;
  readonly heroTextColor?: InputMaybe<IStringFilter>;
  readonly heroTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly homeGraphic?: InputMaybe<IHomeGraphicFilter>;
  readonly imagesDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly kioskAppData?: InputMaybe<IKioskAppDataFilter>;
  readonly kioskAttractLoopImage?: InputMaybe<IImageFilter>;
  readonly kioskAttractLoopImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly loadingGraphics?: InputMaybe<ILoadingGraphicsFilter>;
  readonly loyaltyCardImage?: InputMaybe<ILocaleImageFilter>;
  readonly loyaltyHeaderBackgroundImage?: InputMaybe<IImageFilter>;
  readonly loyaltyHeroAction?: InputMaybe<ILinkActionFilter>;
  readonly loyaltyHeroSubText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyHeroSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyInfoImage?: InputMaybe<ILocaleImageFilter>;
  readonly mainHeroImage?: InputMaybe<IImageFilter>;
  readonly mainHeroImageAuthenticated?: InputMaybe<IImageFilter>;
  readonly mainHeroImageAuthenticatedLocale?: InputMaybe<ILocaleImageFilter>;
  readonly mainHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly mainHeroMobileImage?: InputMaybe<IImageFilter>;
  readonly mainHeroMobileImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly menuHeroImage?: InputMaybe<IImageFilter>;
  readonly menuHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly menuHeroText?: InputMaybe<IStringFilter>;
  readonly menuHeroTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly offersDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly ourAppHeroImage?: InputMaybe<IImageFilter>;
  readonly ourAppHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly ourServicesHeroImage?: InputMaybe<IImageFilter>;
  readonly ourServicesHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly phoneNumberDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly priceDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly recentOrderHeroText?: InputMaybe<IStringFilter>;
  readonly recentOrderHeroTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly reservedRightsDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly rewardsMainImage?: InputMaybe<IImageFilter>;
  readonly rewardsMainImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly servicesHeaderText?: InputMaybe<IStringFilter>;
  readonly servicesHeaderTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly servicesHeroText?: InputMaybe<IStringFilter>;
  readonly servicesHeroTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly siteAlert?: InputMaybe<IAlertFilter>;
  readonly sodiumDisclaimer?: InputMaybe<ILocaleBlockContentFilter>;
  readonly storeLocatorContent?: InputMaybe<IStoreLocatorContentFilter>;
  readonly superUpsellDisclaimer?: InputMaybe<ILocaleBlockContentFilter>;
  readonly upsellItemAtCheckout?: InputMaybe<IItemFilter>;
  readonly useSideBySideHeroButtons?: InputMaybe<IBooleanFilter>;
}

export interface IAppDataSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionSorting>;
  readonly appDownloadSection?: InputMaybe<IAppDownloadSectionSorting>;
  readonly appHeaderText?: InputMaybe<SortOrder>;
  readonly appImage?: InputMaybe<IImageSorting>;
  readonly appImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly appleWalletImage?: InputMaybe<ILocaleImageSorting>;
  readonly calorieDisclaimer?: InputMaybe<ILocaleBlockContentSorting>;
  readonly communicationPreferencesDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly communicationPreferencesDisclaimerBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly contentCardsHeader?: InputMaybe<ILocaleStringSorting>;
  readonly contentCardsSubheader?: InputMaybe<ILocaleStringSorting>;
  readonly copyrightAndTrademarkDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly countryDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly defaultHeroImage?: InputMaybe<IImageSorting>;
  readonly defaultHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly favoriteSection?: InputMaybe<IFavoriteSectionSorting>;
  readonly geolocationModalImage?: InputMaybe<IImageSorting>;
  readonly geolocationModalImageUseLocale?: InputMaybe<ILocaleImageSorting>;
  readonly giftCardImage?: InputMaybe<ILocaleImageSorting>;
  readonly giftCardTextImage?: InputMaybe<ILocaleImageSorting>;
  readonly googlePayImage?: InputMaybe<ILocaleImageSorting>;
  readonly headerText?: InputMaybe<SortOrder>;
  readonly heroAction?: InputMaybe<ILinkActionSorting>;
  readonly heroActionAuthenticated?: InputMaybe<ILinkActionSorting>;
  readonly heroActionLeft?: InputMaybe<ILinkActionSorting>;
  readonly heroActionLeftIcon?: InputMaybe<ILocaleImageSorting>;
  readonly heroActionRight?: InputMaybe<ILinkActionSorting>;
  readonly heroActionRightIcon?: InputMaybe<ILocaleImageSorting>;
  readonly heroSubText?: InputMaybe<ILocaleStringSorting>;
  readonly heroSubTextAuthenticated?: InputMaybe<ILocaleStringSorting>;
  readonly heroSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly heroSuperTextAuthenticated?: InputMaybe<ILocaleStringSorting>;
  readonly heroText?: InputMaybe<SortOrder>;
  readonly heroTextAuthenticated?: InputMaybe<SortOrder>;
  readonly heroTextAuthenticatedLocale?: InputMaybe<ILocaleStringSorting>;
  readonly heroTextColor?: InputMaybe<SortOrder>;
  readonly heroTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly homeGraphic?: InputMaybe<IHomeGraphicSorting>;
  readonly imagesDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly kioskAttractLoopImage?: InputMaybe<IImageSorting>;
  readonly kioskAttractLoopImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly loadingGraphics?: InputMaybe<ILoadingGraphicsSorting>;
  readonly loyaltyCardImage?: InputMaybe<ILocaleImageSorting>;
  readonly loyaltyHeaderBackgroundImage?: InputMaybe<IImageSorting>;
  readonly loyaltyHeroAction?: InputMaybe<ILinkActionSorting>;
  readonly loyaltyHeroSubText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyHeroSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyInfoImage?: InputMaybe<ILocaleImageSorting>;
  readonly mainHeroImage?: InputMaybe<IImageSorting>;
  readonly mainHeroImageAuthenticated?: InputMaybe<IImageSorting>;
  readonly mainHeroImageAuthenticatedLocale?: InputMaybe<ILocaleImageSorting>;
  readonly mainHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly mainHeroMobileImage?: InputMaybe<IImageSorting>;
  readonly mainHeroMobileImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly menuHeroImage?: InputMaybe<IImageSorting>;
  readonly menuHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly menuHeroText?: InputMaybe<SortOrder>;
  readonly menuHeroTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly offersDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly ourAppHeroImage?: InputMaybe<IImageSorting>;
  readonly ourAppHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly ourServicesHeroImage?: InputMaybe<IImageSorting>;
  readonly ourServicesHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly phoneNumberDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly priceDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly recentOrderHeroText?: InputMaybe<SortOrder>;
  readonly recentOrderHeroTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly reservedRightsDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly rewardsMainImage?: InputMaybe<IImageSorting>;
  readonly rewardsMainImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly servicesHeaderText?: InputMaybe<SortOrder>;
  readonly servicesHeaderTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly servicesHeroText?: InputMaybe<SortOrder>;
  readonly servicesHeroTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly sodiumDisclaimer?: InputMaybe<ILocaleBlockContentSorting>;
  readonly storeLocatorContent?: InputMaybe<IStoreLocatorContentSorting>;
  readonly superUpsellDisclaimer?: InputMaybe<ILocaleBlockContentSorting>;
  readonly useSideBySideHeroButtons?: InputMaybe<SortOrder>;
}

export interface IAppDownload extends IDocument {
  readonly __typename?: 'AppDownload';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly details: Maybe<IAppDownloadSection>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IAppDownloadFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly details?: InputMaybe<IAppDownloadSectionFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IAppDownloadSection {
  readonly __typename?: 'AppDownloadSection';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly androidDownloadImage: Maybe<ILocaleImage>;
  readonly androidDownloadLink: Maybe<Scalars['String']['output']>;
  readonly backgroundImage: Maybe<IImage>;
  readonly copy: Maybe<ILocaleString>;
  readonly header: Maybe<ILocaleString>;
  readonly headerSuperText: Maybe<ILocaleString>;
  readonly huaweiDownloadImage: Maybe<ILocaleImage>;
  readonly huaweiDownloadLink: Maybe<Scalars['String']['output']>;
  readonly iOSDownloadImage: Maybe<ILocaleImage>;
  readonly iOSDownloadLink: Maybe<Scalars['String']['output']>;
  readonly primaryImage: Maybe<ILocaleImage>;
  readonly submitButtonText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly textDecorationLeft: Maybe<IImage>;
  /** @deprecated This is deprecated */
  readonly textDecorationRight: Maybe<IImage>;
}

export interface IAppDownloadSectionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly androidDownloadImage?: InputMaybe<ILocaleImageFilter>;
  readonly androidDownloadLink?: InputMaybe<IStringFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly copy?: InputMaybe<ILocaleStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly headerSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly huaweiDownloadImage?: InputMaybe<ILocaleImageFilter>;
  readonly huaweiDownloadLink?: InputMaybe<IStringFilter>;
  readonly iOSDownloadImage?: InputMaybe<ILocaleImageFilter>;
  readonly iOSDownloadLink?: InputMaybe<IStringFilter>;
  readonly primaryImage?: InputMaybe<ILocaleImageFilter>;
  readonly submitButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly textDecorationLeft?: InputMaybe<IImageFilter>;
  readonly textDecorationRight?: InputMaybe<IImageFilter>;
}

export interface IAppDownloadSectionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly androidDownloadImage?: InputMaybe<ILocaleImageSorting>;
  readonly androidDownloadLink?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly copy?: InputMaybe<ILocaleStringSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly headerSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly huaweiDownloadImage?: InputMaybe<ILocaleImageSorting>;
  readonly huaweiDownloadLink?: InputMaybe<SortOrder>;
  readonly iOSDownloadImage?: InputMaybe<ILocaleImageSorting>;
  readonly iOSDownloadLink?: InputMaybe<SortOrder>;
  readonly primaryImage?: InputMaybe<ILocaleImageSorting>;
  readonly submitButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly textDecorationLeft?: InputMaybe<IImageSorting>;
  readonly textDecorationRight?: InputMaybe<IImageSorting>;
}

export interface IAppDownloadSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly details?: InputMaybe<IAppDownloadSectionSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IAppVersion extends IDocument {
  readonly __typename?: 'AppVersion';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** This version is used to determine minimum binary compatible version for the Appflow web build, to prevent release of incompatible changes */
  readonly appflowMinimumBinaryVersion: Maybe<Scalars['String']['output']>;
  /**
   * The kiosk app version to be deployed to the App store. This should use semantic versioning and have 2 decimal points, for example 1.0.0. The numbers signify <Major Release>.<Minor Release>.<Patch Number>
   * @deprecated This has been deprecated
   */
  readonly kioskVersionName: Maybe<Scalars['String']['output']>;
  /** The native app version to be deployed to the App store. This should use semantic versioning and have 2 decimal points, for example 1.0.0. The numbers signify <Major Release>.<Minor Release>.<Patch Number> */
  readonly nativeVersionName: Maybe<Scalars['String']['output']>;
  /** This is the build number currently released for the Version Name. When version name changes, this should be re-set to 1. */
  readonly nativeVersionNumber: Maybe<Scalars['Float']['output']>;
}

export interface IAppVersionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appflowMinimumBinaryVersion?: InputMaybe<IStringFilter>;
  readonly kioskVersionName?: InputMaybe<IStringFilter>;
  readonly nativeVersionName?: InputMaybe<IStringFilter>;
  readonly nativeVersionNumber?: InputMaybe<IFloatFilter>;
}

export interface IAppVersionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly appflowMinimumBinaryVersion?: InputMaybe<SortOrder>;
  readonly kioskVersionName?: InputMaybe<SortOrder>;
  readonly nativeVersionName?: InputMaybe<SortOrder>;
  readonly nativeVersionNumber?: InputMaybe<SortOrder>;
}

export interface IAppleConfig {
  readonly __typename?: 'AppleConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly merchantId: Maybe<Scalars['String']['output']>;
  /** This field should only be used when migrating from one PSP to another */
  readonly migrationMerchantId: Maybe<Scalars['String']['output']>;
  /** This field should only be used when migrating from one PSP to another */
  readonly migrationPrepaidMerchantId: Maybe<Scalars['String']['output']>;
  readonly paymentsNetworks: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly prepaidMerchantId: Maybe<Scalars['String']['output']>;
  readonly socialLogin: Maybe<ISocialLoginAppleConfig>;
}

export interface IAppleConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly merchantId?: InputMaybe<IStringFilter>;
  readonly migrationMerchantId?: InputMaybe<IStringFilter>;
  readonly migrationPrepaidMerchantId?: InputMaybe<IStringFilter>;
  readonly prepaidMerchantId?: InputMaybe<IStringFilter>;
  readonly socialLogin?: InputMaybe<ISocialLoginAppleConfigFilter>;
}

export interface IAppleConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly merchantId?: InputMaybe<SortOrder>;
  readonly migrationMerchantId?: InputMaybe<SortOrder>;
  readonly migrationPrepaidMerchantId?: InputMaybe<SortOrder>;
  readonly prepaidMerchantId?: InputMaybe<SortOrder>;
  readonly socialLogin?: InputMaybe<ISocialLoginAppleConfigSorting>;
}

export interface IAwsConfig {
  readonly __typename?: 'AwsConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly gqlApiUrl: Maybe<Scalars['String']['output']>;
  readonly gqlGatewayApiUrl: Maybe<Scalars['String']['output']>;
  readonly region: Maybe<Scalars['String']['output']>;
  readonly userPoolClientId: Maybe<Scalars['String']['output']>;
  readonly userPoolId: Maybe<Scalars['String']['output']>;
}

export interface IAwsConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly gqlApiUrl?: InputMaybe<IStringFilter>;
  readonly gqlGatewayApiUrl?: InputMaybe<IStringFilter>;
  readonly region?: InputMaybe<IStringFilter>;
  readonly userPoolClientId?: InputMaybe<IStringFilter>;
  readonly userPoolId?: InputMaybe<IStringFilter>;
}

export interface IAwsConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly gqlApiUrl?: InputMaybe<SortOrder>;
  readonly gqlGatewayApiUrl?: InputMaybe<SortOrder>;
  readonly region?: InputMaybe<SortOrder>;
  readonly userPoolClientId?: InputMaybe<SortOrder>;
  readonly userPoolId?: InputMaybe<SortOrder>;
}

export interface IBagFeeConfigurations {
  readonly __typename?: 'BagFeeConfigurations';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bagTaxExplainer: Maybe<ILocaleString>;
  readonly curbside: Maybe<IFeesAndTaxesServiceModeConfiguration>;
  readonly delivery: Maybe<IFeesAndTaxesServiceModeConfiguration>;
  readonly driveThru: Maybe<IFeesAndTaxesServiceModeConfiguration>;
  readonly pickup: Maybe<IFeesAndTaxesServiceModeConfiguration>;
}

export interface IBagFeeConfigurationsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bagTaxExplainer?: InputMaybe<ILocaleStringFilter>;
  readonly curbside?: InputMaybe<IFeesAndTaxesServiceModeConfigurationFilter>;
  readonly delivery?: InputMaybe<IFeesAndTaxesServiceModeConfigurationFilter>;
  readonly driveThru?: InputMaybe<IFeesAndTaxesServiceModeConfigurationFilter>;
  readonly pickup?: InputMaybe<IFeesAndTaxesServiceModeConfigurationFilter>;
}

export interface IBagFeeConfigurationsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bagTaxExplainer?: InputMaybe<ILocaleStringSorting>;
  readonly curbside?: InputMaybe<IFeesAndTaxesServiceModeConfigurationSorting>;
  readonly delivery?: InputMaybe<IFeesAndTaxesServiceModeConfigurationSorting>;
  readonly driveThru?: InputMaybe<IFeesAndTaxesServiceModeConfigurationSorting>;
  readonly pickup?: InputMaybe<IFeesAndTaxesServiceModeConfigurationSorting>;
}

export interface IBetweenDates {
  readonly __typename?: 'BetweenDates';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Ends on... (Time will be set to 23:59:59) */
  readonly endDate: Maybe<Scalars['DateTime']['output']>;
  /** Starts on... (Time will be set to 00:00:00) */
  readonly startDate: Maybe<Scalars['DateTime']['output']>;
}

export interface IBetweenDatesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endDate?: InputMaybe<IDatetimeFilter>;
  readonly startDate?: InputMaybe<IDatetimeFilter>;
}

export interface IBetweenDatesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endDate?: InputMaybe<SortOrder>;
  readonly startDate?: InputMaybe<SortOrder>;
}

export interface IBetweenTimes {
  readonly __typename?: 'BetweenTimes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Ends at: 23:59:59 */
  readonly endTime: Maybe<Scalars['String']['output']>;
  /** Starts at: 00:00:00 */
  readonly startTime: Maybe<Scalars['String']['output']>;
}

export interface IBetweenTimesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endTime?: InputMaybe<IStringFilter>;
  readonly startTime?: InputMaybe<IStringFilter>;
}

export interface IBetweenTimesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endTime?: InputMaybe<SortOrder>;
  readonly startTime?: InputMaybe<SortOrder>;
}

export interface IBirthdayAndCatering extends IDocument {
  readonly __typename?: 'BirthdayAndCatering';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly advancedBooking: Maybe<IAdvancedBookingConfig>;
  readonly availableHours: Maybe<ReadonlyArray<Maybe<ITimeRange>>>;
  /** Set maximum number of days between request and event. */
  readonly maximumDaysBetweenRequestAndEvent: Maybe<Scalars['Float']['output']>;
  /** Set minimum number of kid per request. */
  readonly minimumKids: Maybe<Scalars['Float']['output']>;
  readonly privacyPolicyDisclaimers: Maybe<IPrivacyPolicyDisclaimers>;
}

export interface IBirthdayAndCateringFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly advancedBooking?: InputMaybe<IAdvancedBookingConfigFilter>;
  readonly maximumDaysBetweenRequestAndEvent?: InputMaybe<IFloatFilter>;
  readonly minimumKids?: InputMaybe<IFloatFilter>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersFilter>;
}

export interface IBirthdayAndCateringSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly advancedBooking?: InputMaybe<IAdvancedBookingConfigSorting>;
  readonly maximumDaysBetweenRequestAndEvent?: InputMaybe<SortOrder>;
  readonly minimumKids?: InputMaybe<SortOrder>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersSorting>;
}

export interface IBirthdayAttendee {
  readonly __typename?: 'BirthdayAttendee';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly key: Maybe<Scalars['String']['output']>;
  readonly maximum: Maybe<Scalars['Float']['output']>;
  readonly minimum: Maybe<Scalars['Float']['output']>;
}

export interface IBirthdayAttendeeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly maximum?: InputMaybe<IFloatFilter>;
  readonly minimum?: InputMaybe<IFloatFilter>;
}

export interface IBirthdayAttendeeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly key?: InputMaybe<SortOrder>;
  readonly maximum?: InputMaybe<SortOrder>;
  readonly minimum?: InputMaybe<SortOrder>;
}

export interface IBirthdayMultiplier {
  readonly __typename?: 'BirthdayMultiplier';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Period (day, week, month) around user's birthday */
  readonly duration: Maybe<Scalars['String']['output']>;
}

export interface IBirthdayMultiplierFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly duration?: InputMaybe<IStringFilter>;
}

export type IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIncentiveRequirementOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserCreated =

    | IBirthdayMultiplier
    | ICartLimit
    | ICartRequirement
    | ICartRequirementExclusion
    | ICoolDown
    | IDayOfWeekBand
    | IEmailVerifiedRequired
    | IFirstOrderOnly
    | IIncentiveRequirement
    | ILimit
    | ILoyaltyBetweenDates
    | ILoyaltyBetweenTimes
    | IPaymentMethod
    | IRequiresAuthentication
    | IRestaurantGroupRestrictions
    | IServiceModeRestrictions
    | ISubtotalSpend
    | ITierList
    | ITotalSpend
    | ITransactionCount
    | IUserCreated;

export type IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIsStackableOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated =

    | IBirthdayMultiplier
    | ICartLimit
    | ICartRequirement
    | ICartRequirementExclusion
    | ICoolDown
    | IDayOfWeekBand
    | IEmailVerifiedRequired
    | IFirstOrderOnly
    | IIsStackable
    | ILimit
    | ILoyaltyBetweenDates
    | ILoyaltyBetweenTimes
    | IPaymentMethod
    | IRequiresAuthentication
    | IRestaurantGroupRestrictions
    | IServiceModeRestrictions
    | ISubtotalSpend
    | ITierList
    | ITotalSpend
    | ITransactionCount
    | IUserAttributes
    | IUserCreated;

export type IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrLoyaltyPointsOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrRewardPriceOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated =

    | IBirthdayMultiplier
    | ICartLimit
    | ICartRequirement
    | ICartRequirementExclusion
    | ICoolDown
    | IDayOfWeekBand
    | IEmailVerifiedRequired
    | IFirstOrderOnly
    | ILimit
    | ILoyaltyBetweenDates
    | ILoyaltyBetweenTimes
    | ILoyaltyPoints
    | IPaymentMethod
    | IRequiresAuthentication
    | IRestaurantGroupRestrictions
    | IRewardPrice
    | IServiceModeRestrictions
    | ISubtotalSpend
    | ITierList
    | ITotalSpend
    | ITransactionCount
    | IUserAttributes
    | IUserCreated;

export interface IBirthdayMultiplierSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly duration?: InputMaybe<SortOrder>;
}

export interface IBlacklistCharacterReplaceRule {
  readonly __typename?: 'BlacklistCharacterReplaceRule';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly filter: Maybe<Scalars['String']['output']>;
  readonly replace: Maybe<Scalars['String']['output']>;
}

export interface IBlacklistCharacterReplaceRuleFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly filter?: InputMaybe<IStringFilter>;
  readonly replace?: InputMaybe<IStringFilter>;
}

export interface IBlacklistCharacterReplaceRuleSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly filter?: InputMaybe<SortOrder>;
  readonly replace?: InputMaybe<SortOrder>;
}

export interface IBlock {
  readonly __typename?: 'Block';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly children: Maybe<ReadonlyArray<Maybe<ISpan>>>;
  readonly level: Maybe<Scalars['Float']['output']>;
  readonly listItem: Maybe<Scalars['String']['output']>;
  readonly style: Maybe<Scalars['String']['output']>;
}

export interface IBlockContentImage {
  readonly __typename?: 'BlockContentImage';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly asset: Maybe<ISanityImageAsset>;
  readonly caption: Maybe<Scalars['String']['output']>;
  readonly crop: Maybe<ISanityImageCrop>;
  readonly hotspot: Maybe<ISanityImageHotspot>;
}

export interface IBlockContentImageFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly asset?: InputMaybe<ISanityImageAssetFilter>;
  readonly caption?: InputMaybe<IStringFilter>;
  readonly crop?: InputMaybe<ISanityImageCropFilter>;
  readonly hotspot?: InputMaybe<ISanityImageHotspotFilter>;
}

export interface IBlockContentImageSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly caption?: InputMaybe<SortOrder>;
  readonly crop?: InputMaybe<ISanityImageCropSorting>;
  readonly hotspot?: InputMaybe<ISanityImageHotspotSorting>;
}

export type IBlockOrBlockContentImage = IBlock | IBlockContentImage;

export type IBlockOrBlockContentImageOrPlaceholderField =
  | IBlock
  | IBlockContentImage
  | IPlaceholderField;

export interface IBooleanFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IBrand {
  readonly __typename?: 'Brand';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly logo: Maybe<IImage>;
}

export interface IBrandFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly logo?: InputMaybe<IImageFilter>;
}

export interface IBrandSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly logo?: InputMaybe<IImageSorting>;
}

export interface IBrazeContentCards extends IDocument {
  readonly __typename?: 'BrazeContentCards';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly sectionSubheader: Maybe<ILocaleString>;
  readonly sectionheader: Maybe<ILocaleString>;
}

export interface IBrazeContentCardsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly sectionSubheader?: InputMaybe<ILocaleStringFilter>;
  readonly sectionheader?: InputMaybe<ILocaleStringFilter>;
}

export interface IBrazeContentCardsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly sectionSubheader?: InputMaybe<ILocaleStringSorting>;
  readonly sectionheader?: InputMaybe<ILocaleStringSorting>;
}

export interface IBringgConfig {
  readonly __typename?: 'BringgConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly region: Maybe<Scalars['String']['output']>;
}

export interface IBringgConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly region?: InputMaybe<IStringFilter>;
}

export interface IBringgConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly region?: InputMaybe<SortOrder>;
}

export interface IBuildConfiguration extends IDocument {
  readonly __typename?: 'BuildConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated. */
  readonly ionic: Maybe<IIonicConfig>;
}

export interface IBuildConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly ionic?: InputMaybe<IIonicConfigFilter>;
}

export interface IBuildConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly ionic?: InputMaybe<IIonicConfigSorting>;
}

export interface ICallToAction {
  readonly __typename?: 'CallToAction';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly buttonLink: Maybe<Scalars['String']['output']>;
  readonly buttonText: Maybe<ILocaleString>;
  readonly heading: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly link: Maybe<ILocaleUrl>;
  readonly subheading: Maybe<ILocaleString>;
}

export interface ICallToActionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly buttonLink?: InputMaybe<IStringFilter>;
  readonly buttonText?: InputMaybe<ILocaleStringFilter>;
  readonly heading?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly subheading?: InputMaybe<ILocaleStringFilter>;
}

export interface ICallToActionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly buttonLink?: InputMaybe<SortOrder>;
  readonly buttonText?: InputMaybe<ILocaleStringSorting>;
  readonly heading?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly subheading?: InputMaybe<ILocaleStringSorting>;
}

export interface ICallToActionWidget {
  readonly __typename?: 'CallToActionWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly callToActionContent: Maybe<ReadonlyArray<Maybe<ICallToAction>>>;
}

export interface ICallToActionWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ICallToActionWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ICartContainsPlu {
  readonly __typename?: 'CartContainsPlu';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly hdxPLUs: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly qstPLUs: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Quantity that must be in the cart */
  readonly quantity: Maybe<Scalars['Float']['output']>;
  readonly sicomPLUs: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
}

export interface ICartContainsPluFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly quantity?: InputMaybe<IFloatFilter>;
}

export interface ICartContainsPluSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly quantity?: InputMaybe<SortOrder>;
}

export interface ICartLimit {
  readonly __typename?: 'CartLimit';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly maximumRedemptions: Maybe<Scalars['Float']['output']>;
}

export interface ICartLimitFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly maximumRedemptions?: InputMaybe<IFloatFilter>;
}

export interface ICartLimitSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly maximumRedemptions?: InputMaybe<SortOrder>;
}

export interface ICartProperty {
  readonly __typename?: 'CartProperty';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** the cart property for comparison */
  readonly key: Maybe<Scalars['String']['output']>;
  /** This value must be set - use false if you are unsure. */
  readonly negated: Maybe<Scalars['Boolean']['output']>;
  /** Subtotal must be... */
  readonly operator: Maybe<Scalars['String']['output']>;
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface ICartPropertyFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly negated?: InputMaybe<IBooleanFilter>;
  readonly operator?: InputMaybe<IStringFilter>;
  readonly value?: InputMaybe<IStringFilter>;
}

export interface ICartPropertySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly key?: InputMaybe<SortOrder>;
  readonly negated?: InputMaybe<SortOrder>;
  readonly operator?: InputMaybe<SortOrder>;
  readonly value?: InputMaybe<SortOrder>;
}

export interface ICartRequirement {
  readonly __typename?: 'CartRequirement';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cartRequirement: Maybe<ReadonlyArray<Maybe<ICartRequirementEntry>>>;
}

export interface ICartRequirementEntry {
  readonly __typename?: 'CartRequirementEntry';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly item: Maybe<IComboOrItem>;
  /** Quantity that must be in the cart */
  readonly quantity: Maybe<Scalars['Float']['output']>;
}

export interface ICartRequirementEntryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly quantity?: InputMaybe<IFloatFilter>;
}

export interface ICartRequirementEntrySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly quantity?: InputMaybe<SortOrder>;
}

export interface ICartRequirementExclusion {
  readonly __typename?: 'CartRequirementExclusion';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cartRequirementExclusion: Maybe<ReadonlyArray<Maybe<ICartRequirementExclusionEntry>>>;
}

export interface ICartRequirementExclusionEntry {
  readonly __typename?: 'CartRequirementExclusionEntry';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly item: Maybe<IComboOrItem>;
}

export interface ICartRequirementExclusionEntryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ICartRequirementExclusionEntrySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ICartRequirementExclusionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ICartRequirementExclusionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ICartRequirementFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export type ICartRequirementOrCartRequirementExclusionOrDayOfWeekBandOrEmailVerifiedRequiredOrIncentiveRequirementOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrRequiresAuthenticationOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrUserAttributes =

    | ICartRequirement
    | ICartRequirementExclusion
    | IDayOfWeekBand
    | IEmailVerifiedRequired
    | IIncentiveRequirement
    | ILoyaltyBetweenDates
    | ILoyaltyBetweenTimes
    | IRequiresAuthentication
    | IServiceModeRestrictions
    | ISubtotalSpend
    | ITierList
    | ITotalSpend
    | IUserAttributes;

export interface ICartRequirementSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ICartTotalCountdown extends IDocument {
  readonly __typename?: 'CartTotalCountdown';
  /**
   * Date the document was created
   * @deprecated This has been deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This has been deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This has been deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This has been deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This has been deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This has been deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly bonusPointsPromotion: Maybe<ISystemwidePromotion>;
  /** @deprecated This has been deprecated */
  readonly cartSize: Maybe<Scalars['Float']['output']>;
  /**
   * Toggle between “Free Delivery” and “Bonus Points” modes.
   * @deprecated This has been deprecated
   */
  readonly enableBonusPointsMode: Maybe<Scalars['Boolean']['output']>;
  /**
   * Enable or Disable the component
   * @deprecated This has been deprecated
   */
  readonly enableCartTotalCountdown: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This has been deprecated */
  readonly objectiveAmount: Maybe<Scalars['String']['output']>;
}

export interface ICartTotalCountdownFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly bonusPointsPromotion?: InputMaybe<ISystemwidePromotionFilter>;
  readonly cartSize?: InputMaybe<IFloatFilter>;
  readonly enableBonusPointsMode?: InputMaybe<IBooleanFilter>;
  readonly enableCartTotalCountdown?: InputMaybe<IBooleanFilter>;
  readonly objectiveAmount?: InputMaybe<IStringFilter>;
}

export interface ICartTotalCountdownSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly cartSize?: InputMaybe<SortOrder>;
  readonly enableBonusPointsMode?: InputMaybe<SortOrder>;
  readonly enableCartTotalCountdown?: InputMaybe<SortOrder>;
  readonly objectiveAmount?: InputMaybe<SortOrder>;
}

export interface ICateringAttendee {
  readonly __typename?: 'CateringAttendee';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly key: Maybe<Scalars['String']['output']>;
  readonly maximum: Maybe<Scalars['Float']['output']>;
  readonly minimum: Maybe<Scalars['Float']['output']>;
}

export interface ICateringAttendeeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly maximum?: InputMaybe<IFloatFilter>;
  readonly minimum?: InputMaybe<IFloatFilter>;
}

export interface ICateringAttendeeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly key?: InputMaybe<SortOrder>;
  readonly maximum?: InputMaybe<SortOrder>;
  readonly minimum?: InputMaybe<SortOrder>;
}

export interface ICateringPageConfiguration extends IDocument {
  readonly __typename?: 'CateringPageConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly components: Maybe<
    ReadonlyArray<
      Maybe<IAlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrLockedOffersOrMainHeroOrMarketingTileGroupOrOffersSectionOrOurServicesOrRecentItemsOrTrending>
    >
  >;
}

export interface ICateringPageConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface ICateringPageConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IChannelConfigOption {
  readonly __typename?: 'ChannelConfigOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly channel: Maybe<Scalars['String']['output']>;
  /** Determines whether "delivery" service mode is availabile for the given channel. */
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  /** Determines whether "pickup" service mode is availabile for the given channel. */
  readonly hasPickup: Maybe<Scalars['Boolean']['output']>;
}

export interface IChannelConfigOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly channel?: InputMaybe<IStringFilter>;
  readonly hasDelivery?: InputMaybe<IBooleanFilter>;
  readonly hasPickup?: InputMaybe<IBooleanFilter>;
}

export interface IChannelConfigOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly channel?: InputMaybe<SortOrder>;
  readonly hasDelivery?: InputMaybe<SortOrder>;
  readonly hasPickup?: InputMaybe<SortOrder>;
}

export interface IChannelExclusions {
  readonly __typename?: 'ChannelExclusions';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly delivery: Maybe<Scalars['Boolean']['output']>;
  readonly googleFoodOrdering: Maybe<Scalars['Boolean']['output']>;
  readonly kiosk: Maybe<Scalars['Boolean']['output']>;
  readonly mobile: Maybe<Scalars['Boolean']['output']>;
  readonly pickup: Maybe<Scalars['Boolean']['output']>;
  readonly web: Maybe<Scalars['Boolean']['output']>;
}

export interface IChannelExclusionsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly delivery?: InputMaybe<IBooleanFilter>;
  readonly googleFoodOrdering?: InputMaybe<IBooleanFilter>;
  readonly kiosk?: InputMaybe<IBooleanFilter>;
  readonly mobile?: InputMaybe<IBooleanFilter>;
  readonly pickup?: InputMaybe<IBooleanFilter>;
  readonly web?: InputMaybe<IBooleanFilter>;
}

export interface IChannelExclusionsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly delivery?: InputMaybe<SortOrder>;
  readonly googleFoodOrdering?: InputMaybe<SortOrder>;
  readonly kiosk?: InputMaybe<SortOrder>;
  readonly mobile?: InputMaybe<SortOrder>;
  readonly pickup?: InputMaybe<SortOrder>;
  readonly web?: InputMaybe<SortOrder>;
}

export interface IChannelsConfiguration extends IDocument {
  readonly __typename?: 'ChannelsConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly channels: Maybe<ReadonlyArray<Maybe<IChannelConfigOption>>>;
}

export interface IChannelsConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IChannelsConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface ICheckoutDotComConfig {
  readonly __typename?: 'CheckoutDotComConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly publicKey: Maybe<Scalars['String']['output']>;
}

export interface ICheckoutDotComConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly publicKey?: InputMaybe<IStringFilter>;
}

export interface ICheckoutDotComConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly publicKey?: InputMaybe<SortOrder>;
}

export interface IClaimOnHistory {
  readonly __typename?: 'ClaimOnHistory';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly linkText: Maybe<ILocaleBlockContent>;
}

export interface IClaimOnHistoryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly linkText?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IClaimOnHistorySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly linkText?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IClaimPointsFailedSubmissionContent {
  readonly __typename?: 'ClaimPointsFailedSubmissionContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttonTitle: Maybe<ILocaleString>;
  readonly buttonUrl: Maybe<ILocaleUrl>;
  readonly failureTextBlock: Maybe<ILocaleBlockContent>;
  readonly screenTitle: Maybe<ILocaleString>;
}

export interface IClaimPointsFailedSubmissionContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly buttonTitle?: InputMaybe<ILocaleStringFilter>;
  readonly buttonUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly failureTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly screenTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IClaimPointsFailedSubmissionContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly buttonTitle?: InputMaybe<ILocaleStringSorting>;
  readonly buttonUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly failureTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly screenTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IClaimPointsScreenContent {
  readonly __typename?: 'ClaimPointsScreenContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bottomButtonTitle: Maybe<ILocaleString>;
  readonly bottomButtonUrl: Maybe<ILocaleUrl>;
  readonly instructionsTextBlock: Maybe<ILocaleBlockContent>;
  readonly rewardsIcon: Maybe<ILocaleImage>;
  readonly screenTitle: Maybe<ILocaleString>;
  readonly secondImage: Maybe<ILocaleImage>;
  readonly topButtonTitle: Maybe<ILocaleString>;
  readonly topButtonUrl: Maybe<ILocaleUrl>;
}

export interface IClaimPointsScreenContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bottomButtonTitle?: InputMaybe<ILocaleStringFilter>;
  readonly bottomButtonUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly instructionsTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly rewardsIcon?: InputMaybe<ILocaleImageFilter>;
  readonly screenTitle?: InputMaybe<ILocaleStringFilter>;
  readonly secondImage?: InputMaybe<ILocaleImageFilter>;
  readonly topButtonTitle?: InputMaybe<ILocaleStringFilter>;
  readonly topButtonUrl?: InputMaybe<ILocaleUrlFilter>;
}

export interface IClaimPointsScreenContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bottomButtonTitle?: InputMaybe<ILocaleStringSorting>;
  readonly bottomButtonUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly instructionsTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly rewardsIcon?: InputMaybe<ILocaleImageSorting>;
  readonly screenTitle?: InputMaybe<ILocaleStringSorting>;
  readonly secondImage?: InputMaybe<ILocaleImageSorting>;
  readonly topButtonTitle?: InputMaybe<ILocaleStringSorting>;
  readonly topButtonUrl?: InputMaybe<ILocaleUrlSorting>;
}

export interface IClaimPointsSurveyScreenContent {
  readonly __typename?: 'ClaimPointsSurveyScreenContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bottomButtonTitle: Maybe<ILocaleString>;
  readonly bottomButtonUrl: Maybe<ILocaleUrl>;
  readonly expirationDateInstructionsTextBlock: Maybe<ILocaleBlockContent>;
  /** To calculate the selection of the transaction date, it should be days in hours (multiple of 24) */
  readonly expirationTimeReceipts: Maybe<Scalars['Float']['output']>;
  readonly inputBoxExpirationDatePlaceholder: Maybe<ILocaleString>;
  readonly instructionsTextBlock: Maybe<ILocaleBlockContent>;
  readonly rewardsIcon: Maybe<ILocaleImage>;
  readonly screenTitle: Maybe<ILocaleString>;
  readonly secondImage: Maybe<ILocaleImage>;
  readonly topButtonTitle: Maybe<ILocaleString>;
  readonly topButtonUrl: Maybe<ILocaleUrl>;
}

export interface IClaimPointsSurveyScreenContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bottomButtonTitle?: InputMaybe<ILocaleStringFilter>;
  readonly bottomButtonUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly expirationDateInstructionsTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly expirationTimeReceipts?: InputMaybe<IFloatFilter>;
  readonly inputBoxExpirationDatePlaceholder?: InputMaybe<ILocaleStringFilter>;
  readonly instructionsTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly rewardsIcon?: InputMaybe<ILocaleImageFilter>;
  readonly screenTitle?: InputMaybe<ILocaleStringFilter>;
  readonly secondImage?: InputMaybe<ILocaleImageFilter>;
  readonly topButtonTitle?: InputMaybe<ILocaleStringFilter>;
  readonly topButtonUrl?: InputMaybe<ILocaleUrlFilter>;
}

export interface IClaimPointsSurveyScreenContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bottomButtonTitle?: InputMaybe<ILocaleStringSorting>;
  readonly bottomButtonUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly expirationDateInstructionsTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly expirationTimeReceipts?: InputMaybe<SortOrder>;
  readonly inputBoxExpirationDatePlaceholder?: InputMaybe<ILocaleStringSorting>;
  readonly instructionsTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly rewardsIcon?: InputMaybe<ILocaleImageSorting>;
  readonly screenTitle?: InputMaybe<ILocaleStringSorting>;
  readonly secondImage?: InputMaybe<ILocaleImageSorting>;
  readonly topButtonTitle?: InputMaybe<ILocaleStringSorting>;
  readonly topButtonUrl?: InputMaybe<ILocaleUrlSorting>;
}

export interface IClaimPointsUi extends IDocument {
  readonly __typename?: 'ClaimPointsUI';
  /**
   * Date the document was created
   * @deprecated Claim Points (SMG Codes) not being used at INTL
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated Claim Points (SMG Codes) not being used at INTL
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated Claim Points (SMG Codes) not being used at INTL
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated Claim Points (SMG Codes) not being used at INTL
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated Claim Points (SMG Codes) not being used at INTL
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimOnHistory: Maybe<IClaimOnHistory>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsAlreadyClaimedContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsDailyAmountExceededContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsDuplicateTransactionId: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsExpiredContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsHomeScreenContent: Maybe<IClaimPointsScreenContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsInvalidCodeContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsInvalidRestaurantContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsPendingContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsSuccessfulSubmissionScreenContent: Maybe<IClaimPointsScreenContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsSurveyContent: Maybe<IClaimPointsSurveyScreenContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsSurveyScreenContent: Maybe<IClaimPointsScreenContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsTooManyRequests: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimPointsTransactionNotFoundContent: Maybe<IClaimPointsFailedSubmissionContent>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly claimQrCode: Maybe<IClaimQrCode>;
}

export interface IClaimPointsUiFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly claimOnHistory?: InputMaybe<IClaimOnHistoryFilter>;
  readonly claimPointsAlreadyClaimedContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsDailyAmountExceededContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsDuplicateTransactionId?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsExpiredContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsHomeScreenContent?: InputMaybe<IClaimPointsScreenContentFilter>;
  readonly claimPointsInvalidCodeContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsInvalidRestaurantContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsPendingContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsSuccessfulSubmissionScreenContent?: InputMaybe<IClaimPointsScreenContentFilter>;
  readonly claimPointsSurveyContent?: InputMaybe<IClaimPointsSurveyScreenContentFilter>;
  readonly claimPointsSurveyScreenContent?: InputMaybe<IClaimPointsScreenContentFilter>;
  readonly claimPointsTooManyRequests?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimPointsTransactionNotFoundContent?: InputMaybe<IClaimPointsFailedSubmissionContentFilter>;
  readonly claimQrCode?: InputMaybe<IClaimQrCodeFilter>;
}

export interface IClaimPointsUiSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly claimOnHistory?: InputMaybe<IClaimOnHistorySorting>;
  readonly claimPointsAlreadyClaimedContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsDailyAmountExceededContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsDuplicateTransactionId?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsExpiredContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsHomeScreenContent?: InputMaybe<IClaimPointsScreenContentSorting>;
  readonly claimPointsInvalidCodeContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsInvalidRestaurantContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsPendingContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsSuccessfulSubmissionScreenContent?: InputMaybe<IClaimPointsScreenContentSorting>;
  readonly claimPointsSurveyContent?: InputMaybe<IClaimPointsSurveyScreenContentSorting>;
  readonly claimPointsSurveyScreenContent?: InputMaybe<IClaimPointsScreenContentSorting>;
  readonly claimPointsTooManyRequests?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimPointsTransactionNotFoundContent?: InputMaybe<IClaimPointsFailedSubmissionContentSorting>;
  readonly claimQrCode?: InputMaybe<IClaimQrCodeSorting>;
}

export interface IClaimQrCode {
  readonly __typename?: 'ClaimQrCode';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleBlockContent>;
  readonly link: Maybe<ILinkWidget>;
  readonly title: Maybe<ILocaleString>;
}

export interface IClaimQrCodeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleBlockContentFilter>;
  readonly link?: InputMaybe<ILinkWidgetFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IClaimQrCodeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleBlockContentSorting>;
  readonly link?: InputMaybe<ILinkWidgetSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ICode {
  readonly __typename?: 'Code';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly code: Maybe<Scalars['String']['output']>;
  readonly filename: Maybe<Scalars['String']['output']>;
  readonly highlightedLines: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>;
  readonly language: Maybe<Scalars['String']['output']>;
}

export interface ICodeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly code?: InputMaybe<IStringFilter>;
  readonly filename?: InputMaybe<IStringFilter>;
  readonly language?: InputMaybe<IStringFilter>;
}

export interface ICodeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly code?: InputMaybe<SortOrder>;
  readonly filename?: InputMaybe<SortOrder>;
  readonly language?: InputMaybe<SortOrder>;
}

export interface IColor {
  readonly __typename?: 'Color';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly alpha: Maybe<Scalars['Float']['output']>;
  readonly hex: Maybe<Scalars['String']['output']>;
  readonly hsl: Maybe<IHslaColor>;
  readonly hsv: Maybe<IHsvaColor>;
  readonly rgb: Maybe<IRgbaColor>;
}

export interface IColorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly alpha?: InputMaybe<IFloatFilter>;
  readonly hex?: InputMaybe<IStringFilter>;
  readonly hsl?: InputMaybe<IHslaColorFilter>;
  readonly hsv?: InputMaybe<IHsvaColorFilter>;
  readonly rgb?: InputMaybe<IRgbaColorFilter>;
}

export interface IColorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly alpha?: InputMaybe<SortOrder>;
  readonly hex?: InputMaybe<SortOrder>;
  readonly hsl?: InputMaybe<IHslaColorSorting>;
  readonly hsv?: InputMaybe<IHsvaColorSorting>;
  readonly rgb?: InputMaybe<IRgbaColorSorting>;
}

export interface IColumn {
  readonly __typename?: 'Column';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly options: Maybe<
    ReadonlyArray<
      Maybe<ICopyrightAndLanguageSelectorOrHighlightedVerticalLinkListWidgetOrHorizontalLinkListWidgetOrImageWidgetOrSocialIconsWithHeaderWidgetOrTextWidgetOrTextWidgetWithUrlOrVerticalLinkListWidgetOrVerticalLinkListWithHeaderWidgetOrWordmarkAndSocialIcons>
    >
  >;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IColumnFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IColumnSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface ICombo extends IDocument {
  readonly __typename?: 'Combo';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly activationDate: Maybe<Scalars['Date']['output']>;
  /** @deprecated This is not used in any international markets */
  readonly bogoBenefits: Maybe<ReadonlyArray<Maybe<IComboOrItem>>>;
  readonly channelExclusions: Maybe<IChannelExclusions>;
  readonly contentOptions: Maybe<ReadonlyArray<Maybe<IContentOption>>>;
  /** @deprecated This has been deprecated */
  readonly deactivationDate: Maybe<Scalars['Date']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly dmbName: Maybe<ILocaleString>;
  /**
   * Indicate if the modifiers for the mainItem and any comboSlots should be dropped down           and displayed after all comboSlots instead of directly under the item/comboSlot they           pertain to
   * @deprecated This has been deprecated
   */
  readonly forceModifiersToBottom: Maybe<Scalars['Boolean']['output']>;
  /**
   * This toggle will no longer impact the Menu Redesign, and will only hide the Main Item         Description in Legacy menu design. To hide a Main Item in Menu Redesign, enable the Is         Dummy Item? boolean on the Main Item document.
   * @deprecated This has been deprecated
   */
  readonly hideMainItemDescription: Maybe<Scalars['Boolean']['output']>;
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly imagesByChannels: Maybe<IImagesByChannels>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** Determines if this Combo can be chosen as a benefit for offers.       Once set to true, this cannot be toggled to false. */
  readonly isOfferBenefit: Maybe<Scalars['Boolean']['output']>;
  /**
   * Show the the amount being ordered as per person.
   * @deprecated This has been deprecated
   */
  readonly labelAsPerPerson: Maybe<Scalars['Boolean']['output']>;
  readonly mainItem: Maybe<IItem>;
  /** Only used for the NCR integration in certain markets. (Eg: NZ) */
  readonly markerItem: Maybe<IMarkerItem>;
  readonly menuObjectSettings: Maybe<IMenuObjectSettings>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IComboSlot>>>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /** @deprecated This has been deprecated */
  readonly prices: Maybe<ReadonlyArray<Maybe<IPricingRegionPrice>>>;
  /**
   * This field is only for UI purposes. Adding a promotion reference here will make possible to show a bonus point badges in menu pages. The validation of this field will not take care of rules evaluation, so before adding any promotion here, please verify the promotion is still valid to avoid a bad UX.
   * @deprecated This has been deprecated
   */
  readonly promotion: Maybe<ISystemwidePromotion>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  /** Show combo in static menu */
  readonly showInStaticMenu: Maybe<Scalars['Boolean']['output']>;
  /**
   * Note: This field will soon be deprecated in favor of new menu redesign
   * @deprecated This has been deprecated
   */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IComboFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly activationDate?: InputMaybe<IDateFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly deactivationDate?: InputMaybe<IDateFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly dmbName?: InputMaybe<ILocaleStringFilter>;
  readonly forceModifiersToBottom?: InputMaybe<IBooleanFilter>;
  readonly hideMainItemDescription?: InputMaybe<IBooleanFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isOfferBenefit?: InputMaybe<IBooleanFilter>;
  readonly labelAsPerPerson?: InputMaybe<IBooleanFilter>;
  readonly mainItem?: InputMaybe<IItemFilter>;
  readonly markerItem?: InputMaybe<IMarkerItemFilter>;
  readonly menuObjectSettings?: InputMaybe<IMenuObjectSettingsFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly promotion?: InputMaybe<ISystemwidePromotionFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly showInStaticMenu?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export type IComboOrExternalLinkOrItemOrOfferOrPickerOrSection =
  | ICombo
  | IExternalLink
  | IItem
  | IOffer
  | IPicker
  | ISection;

export type IComboOrItem = ICombo | IItem;

export type IComboOrItemOrOfferActivationOrOfferDiscountOrOfferItemDiscountOrOfferLoyaltyActionOrPicker =

    | ICombo
    | IItem
    | IOfferActivation
    | IOfferDiscount
    | IOfferItemDiscount
    | IOfferLoyaltyAction
    | IPicker;

export type IComboOrItemOrOfferActivationOrOfferDiscountOrOfferLoyaltyActionOrPicker =
  | ICombo
  | IItem
  | IOfferActivation
  | IOfferDiscount
  | IOfferLoyaltyAction
  | IPicker;

export type IComboOrItemOrOfferActivationOrOfferDiscountOrPickerOrSwapMapping =
  | ICombo
  | IItem
  | IOfferActivation
  | IOfferDiscount
  | IPicker
  | ISwapMapping;

export type IComboOrItemOrOfferDiscountOrPicker = ICombo | IItem | IOfferDiscount | IPicker;

export type IComboOrItemOrOfferOrPickerOrSection = ICombo | IItem | IOffer | IPicker | ISection;

export type IComboOrItemOrPicker = ICombo | IItem | IPicker;

export type IComboOrItemOrPickerOrProductCategory = ICombo | IItem | IPicker | IProductCategory;

export type IComboOrItemOrPickerOrSection = ICombo | IItem | IPicker | ISection;

export type IComboOrItemOrPickerOrSystemwideOffer = ICombo | IItem | IPicker | ISystemwideOffer;

export interface IComboSlot extends IDocument {
  readonly __typename?: 'ComboSlot';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly image: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  readonly maxAmount: Maybe<Scalars['Float']['output']>;
  readonly minAmount: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly optionVisibilitySettings: Maybe<IOptionVisibilitySettings>;
  readonly options: Maybe<ReadonlyArray<Maybe<IComboSlotOption>>>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  readonly respectMaximum: Maybe<Scalars['Boolean']['output']>;
  readonly uiPattern: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IComboSlotFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly maxAmount?: InputMaybe<IFloatFilter>;
  readonly minAmount?: InputMaybe<IFloatFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly optionVisibilitySettings?: InputMaybe<IOptionVisibilitySettingsFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly respectMaximum?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IComboSlotOption {
  readonly __typename?: 'ComboSlotOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly defaultAmount: Maybe<Scalars['Float']['output']>;
  /** This will tell the app that we want to charge for this item. If not turned on all combo slot options are included with the combo(free). */
  readonly isPremium: Maybe<Scalars['Boolean']['output']>;
  readonly maxAmount: Maybe<Scalars['Float']['output']>;
  readonly minAmount: Maybe<Scalars['Float']['output']>;
  readonly option: Maybe<IItemOrPickerOrSection>;
  readonly prices: Maybe<ReadonlyArray<Maybe<IPricingRegionPrice>>>;
}

export interface IComboSlotOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly defaultAmount?: InputMaybe<IFloatFilter>;
  readonly isPremium?: InputMaybe<IBooleanFilter>;
  readonly maxAmount?: InputMaybe<IFloatFilter>;
  readonly minAmount?: InputMaybe<IFloatFilter>;
}

export interface IComboSlotOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly defaultAmount?: InputMaybe<SortOrder>;
  readonly isPremium?: InputMaybe<SortOrder>;
  readonly maxAmount?: InputMaybe<SortOrder>;
  readonly minAmount?: InputMaybe<SortOrder>;
}

export interface IComboSlotSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly maxAmount?: InputMaybe<SortOrder>;
  readonly minAmount?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly optionVisibilitySettings?: InputMaybe<IOptionVisibilitySettingsSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly respectMaximum?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IComboSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly activationDate?: InputMaybe<SortOrder>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly deactivationDate?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly dmbName?: InputMaybe<ILocaleStringSorting>;
  readonly forceModifiersToBottom?: InputMaybe<SortOrder>;
  readonly hideMainItemDescription?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isOfferBenefit?: InputMaybe<SortOrder>;
  readonly labelAsPerPerson?: InputMaybe<SortOrder>;
  readonly menuObjectSettings?: InputMaybe<IMenuObjectSettingsSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly showInStaticMenu?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface ICompletedState {
  readonly __typename?: 'CompletedState';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly header: Maybe<ILocaleString>;
}

export interface ICompletedStateFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
}

export interface ICompletedStateSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
}

export interface IConditionAllergen {
  readonly __typename?: 'ConditionAllergen';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly allergenIdentifier: Maybe<Scalars['String']['output']>;
  readonly comparisonOperator: Maybe<Scalars['String']['output']>;
  /** 3 = Contains; 2 = May Contain; 1 = Cooked in same equipment; 0 = Free From */
  readonly comparisonValue: Maybe<Scalars['Float']['output']>;
}

export interface IConditionAllergenFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly allergenIdentifier?: InputMaybe<IStringFilter>;
  readonly comparisonOperator?: InputMaybe<IStringFilter>;
  readonly comparisonValue?: InputMaybe<IFloatFilter>;
}

export type IConditionAllergenOrConditionItemOneOfOrConditionNutritionOrConditionParentCategory =
  | IConditionAllergen
  | IConditionItemOneOf
  | IConditionNutrition
  | IConditionParentCategory;

export interface IConditionAllergenSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly allergenIdentifier?: InputMaybe<SortOrder>;
  readonly comparisonOperator?: InputMaybe<SortOrder>;
  readonly comparisonValue?: InputMaybe<SortOrder>;
}

export interface IConditionItemOneOf {
  readonly __typename?: 'ConditionItemOneOf';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly items: Maybe<ReadonlyArray<Maybe<IItem>>>;
}

export interface IConditionItemOneOfFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IConditionItemOneOfSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IConditionNutrition {
  readonly __typename?: 'ConditionNutrition';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly comparisonOperator: Maybe<Scalars['String']['output']>;
  readonly comparisonValue: Maybe<Scalars['Float']['output']>;
  readonly nutritionIdentifier: Maybe<Scalars['String']['output']>;
}

export interface IConditionNutritionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly comparisonOperator?: InputMaybe<IStringFilter>;
  readonly comparisonValue?: InputMaybe<IFloatFilter>;
  readonly nutritionIdentifier?: InputMaybe<IStringFilter>;
}

export interface IConditionNutritionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly comparisonOperator?: InputMaybe<SortOrder>;
  readonly comparisonValue?: InputMaybe<SortOrder>;
  readonly nutritionIdentifier?: InputMaybe<SortOrder>;
}

export interface IConditionParentCategory {
  readonly __typename?: 'ConditionParentCategory';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The top level parent category (picker or section) that an item belongs to */
  readonly parentCategory: Maybe<IPickerOrSection>;
}

export interface IConditionParentCategoryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IConditionParentCategorySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IConfigOffer extends IDocument {
  readonly __typename?: 'ConfigOffer';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  /** This incentive will only be displayed to a user if set by personalization */
  readonly configOnly: Maybe<Scalars['Boolean']['output']>;
  readonly daypart: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** When this is set to true this offer will not show in the user's offer list, even if it is added to the Live Offers list in Sanity. This can be used for Upsell Offers, if we don't want to show them in the user's offers list and want it to only be accessible through the Upselling Modal. */
  readonly hideOfferFromOffersList: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly inStoreBenefits: Maybe<ReadonlyArray<Maybe<IComboOrItemOrPicker>>>;
  readonly incentives: Maybe<
    ReadonlyArray<Maybe<IComboOrItemOrOfferActivationOrOfferDiscountOrPickerOrSwapMapping>>
  >;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** On Mechanics, if you set the rule "Loyalty Between-Dates," you can display this offer as upcoming before the start date of the offer, and the offer will be designated with a "Coming Soon" status. This offer will automatically become an “Available” from the point the start date is reached. */
  readonly isUpcomingOffer: Maybe<Scalars['Boolean']['output']>;
  readonly localizedImage: Maybe<ILocaleImages>;
  /** @deprecated This is deprecated */
  readonly lockedOffersPanel: Maybe<ILockedOffersPanel>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly marketPrice: Maybe<IComboOrItem>;
  /**
   * When true, guest will not have option to redeem in-rest
   * @deprecated This is deprecated
   */
  readonly mobileOrderOnly: Maybe<Scalars['Boolean']['output']>;
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly notificationDetails: Maybe<INotificationDetails>;
  /** This represents the price of the offer. Price must be entered in cents. For BOGO offers, the offer price should be entered as 0 */
  readonly offerPrice: Maybe<Scalars['Float']['output']>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /**
   * This represents the PMIX product number
   * @deprecated This is deprecated
   */
  readonly pmixId: Maybe<Scalars['String']['output']>;
  /**
   * Position will be personalized by SSE
   * @deprecated This is deprecated
   */
  readonly position: Maybe<Scalars['Boolean']['output']>;
  readonly pricingDistributionValidation: Maybe<IDataValidationObject>;
  readonly pricingPermutationValidation: Maybe<IDataValidationObject>;
  /** @deprecated This is deprecated */
  readonly promoCodePanel: Maybe<IPromoCodePanel>;
  /** Specify the Order redemption method. */
  readonly redemptionMethod: Maybe<Scalars['String']['output']>;
  /**
   * Controls where/how the user redeems this offer
   * @deprecated This is deprecated
   */
  readonly redemptionType: Maybe<Scalars['String']['output']>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIsStackableOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated>
    >
  >;
  /** This is a static code of the offer. This code will never change. Static codes majorly show when users are signed out and want to redeem an offer in the restaurant or if your market does not have loyalty integration with POS / Kiosk. */
  readonly shortCode: Maybe<Scalars['String']['output']>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
  readonly upsellOptions: Maybe<ReadonlyArray<Maybe<ISystemwideOffer>>>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface IConfigOfferFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly configOnly?: InputMaybe<IBooleanFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly hideOfferFromOffersList?: InputMaybe<IBooleanFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isUpcomingOffer?: InputMaybe<IBooleanFilter>;
  readonly localizedImage?: InputMaybe<ILocaleImagesFilter>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly mobileOrderOnly?: InputMaybe<IBooleanFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleBlockContentFilter>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsFilter>;
  readonly offerPrice?: InputMaybe<IFloatFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly pmixId?: InputMaybe<IStringFilter>;
  readonly position?: InputMaybe<IBooleanFilter>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelFilter>;
  readonly redemptionMethod?: InputMaybe<IStringFilter>;
  readonly redemptionType?: InputMaybe<IStringFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly shortCode?: InputMaybe<IStringFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
}

export type IConfigOfferOrConfigRewardOrRewardOrSystemwideOffer =
  | IConfigOffer
  | IConfigReward
  | IReward
  | ISystemwideOffer;

export interface IConfigOfferSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly configOnly?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly hideOfferFromOffersList?: InputMaybe<SortOrder>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isUpcomingOffer?: InputMaybe<SortOrder>;
  readonly localizedImage?: InputMaybe<ILocaleImagesSorting>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelSorting>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly mobileOrderOnly?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleBlockContentSorting>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsSorting>;
  readonly offerPrice?: InputMaybe<SortOrder>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly pmixId?: InputMaybe<SortOrder>;
  readonly position?: InputMaybe<SortOrder>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelSorting>;
  readonly redemptionMethod?: InputMaybe<SortOrder>;
  readonly redemptionType?: InputMaybe<SortOrder>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly shortCode?: InputMaybe<SortOrder>;
  readonly testOnly?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
}

export interface IConfigQuest extends IDocument {
  readonly __typename?: 'ConfigQuest';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly completedState: Maybe<ICompletedState>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly incentive: Maybe<IQuestIncentive>;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly mustActivateBy: Maybe<Scalars['Date']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly questRules: Maybe<IQuestRules>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<IEmailVerifiedRequiredOrLimitOrLoyaltyBetweenDatesOrRequiresAuthenticationOrUserCreated>
    >
  >;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface IConfigQuestFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly completedState?: InputMaybe<ICompletedStateFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly incentive?: InputMaybe<IQuestIncentiveFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly mustActivateBy?: InputMaybe<IDateFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly questRules?: InputMaybe<IQuestRulesFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface IConfigQuestSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly completedState?: InputMaybe<ICompletedStateSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly incentive?: InputMaybe<IQuestIncentiveSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly mustActivateBy?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly questRules?: InputMaybe<IQuestRulesSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface IConfigRedeemInRestaurant extends IDocument {
  readonly __typename?: 'ConfigRedeemInRestaurant';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly showQrCode: Maybe<Scalars['Boolean']['output']>;
  readonly widgets: Maybe<
    ReadonlyArray<
      Maybe<ILoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyQrAndShortCodeWidgetOrLoyaltyQrCodeWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidget>
    >
  >;
}

export interface IConfigRedeemInRestaurantFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly showQrCode?: InputMaybe<IBooleanFilter>;
}

export interface IConfigRedeemInRestaurantSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly showQrCode?: InputMaybe<SortOrder>;
}

export interface IConfigReward extends IDocument {
  readonly __typename?: 'ConfigReward';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly incentiveNotInMenu: Maybe<Scalars['Boolean']['output']>;
  readonly incentives: Maybe<ReadonlyArray<Maybe<IComboOrItemOrOfferDiscountOrPicker>>>;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  /** When true, guest will not have option to redeem in-rest */
  readonly mobileOrderOnly: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly notificationDetails: Maybe<INotificationDetails>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /**
   * This represents the PMIX product number
   * @deprecated This is deprecated
   */
  readonly pmixId: Maybe<Scalars['String']['output']>;
  /** Specify the Order redemption method. */
  readonly redemptionMethod: Maybe<Scalars['String']['output']>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrLoyaltyPointsOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrRewardPriceOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated>
    >
  >;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface IConfigRewardFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly incentiveNotInMenu?: InputMaybe<IBooleanFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly mobileOrderOnly?: InputMaybe<IBooleanFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly pmixId?: InputMaybe<IStringFilter>;
  readonly redemptionMethod?: InputMaybe<IStringFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
}

export interface IConfigRewardSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly incentiveNotInMenu?: InputMaybe<SortOrder>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly mobileOrderOnly?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly pmixId?: InputMaybe<SortOrder>;
  readonly redemptionMethod?: InputMaybe<SortOrder>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
}

export interface IContentOption {
  readonly __typename?: 'ContentOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<IImage>;
  readonly menuVariantReference: Maybe<IMenu>;
  readonly name: Maybe<ILocaleString>;
}

export interface IContentOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly menuVariantReference?: InputMaybe<IMenuFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IContentOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface ICookieBannerConfig {
  readonly __typename?: 'CookieBannerConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Choose one of the Cookie banners */
  readonly cookieBanner: Maybe<Scalars['String']['output']>;
  /** Please add the script id to load the cookie banner */
  readonly cookieBannerScriptId: Maybe<Scalars['String']['output']>;
  readonly cookieCompliance: Maybe<ICookieComplianceConfig>;
}

export interface ICookieBannerConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cookieBanner?: InputMaybe<IStringFilter>;
  readonly cookieBannerScriptId?: InputMaybe<IStringFilter>;
  readonly cookieCompliance?: InputMaybe<ICookieComplianceConfigFilter>;
}

export interface ICookieBannerConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cookieBanner?: InputMaybe<SortOrder>;
  readonly cookieBannerScriptId?: InputMaybe<SortOrder>;
  readonly cookieCompliance?: InputMaybe<ICookieComplianceConfigSorting>;
}

export interface ICookieComplianceConfig {
  readonly __typename?: 'CookieComplianceConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly consentCategoryBranch: Maybe<Scalars['String']['output']>;
  readonly consentCategoryBraze: Maybe<Scalars['String']['output']>;
  readonly consentCategoryDdRum: Maybe<Scalars['String']['output']>;
  readonly consentCategoryMParticle: Maybe<Scalars['String']['output']>;
  readonly globalConsentState: Maybe<Scalars['String']['output']>;
  readonly scriptId: Maybe<Scalars['String']['output']>;
  readonly scriptUrl: Maybe<Scalars['String']['output']>;
}

export interface ICookieComplianceConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly consentCategoryBranch?: InputMaybe<IStringFilter>;
  readonly consentCategoryBraze?: InputMaybe<IStringFilter>;
  readonly consentCategoryDdRum?: InputMaybe<IStringFilter>;
  readonly consentCategoryMParticle?: InputMaybe<IStringFilter>;
  readonly globalConsentState?: InputMaybe<IStringFilter>;
  readonly scriptId?: InputMaybe<IStringFilter>;
  readonly scriptUrl?: InputMaybe<IStringFilter>;
}

export interface ICookieComplianceConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly consentCategoryBranch?: InputMaybe<SortOrder>;
  readonly consentCategoryBraze?: InputMaybe<SortOrder>;
  readonly consentCategoryDdRum?: InputMaybe<SortOrder>;
  readonly consentCategoryMParticle?: InputMaybe<SortOrder>;
  readonly globalConsentState?: InputMaybe<SortOrder>;
  readonly scriptId?: InputMaybe<SortOrder>;
  readonly scriptUrl?: InputMaybe<SortOrder>;
}

export interface ICoolDown {
  readonly __typename?: 'CoolDown';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Cool down period in seconds */
  readonly minimumElapsedTimeSeconds: Maybe<Scalars['Float']['output']>;
}

export interface ICoolDownFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly minimumElapsedTimeSeconds?: InputMaybe<IFloatFilter>;
}

export interface ICoolDownSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly minimumElapsedTimeSeconds?: InputMaybe<SortOrder>;
}

export interface ICopyrightAndLanguageSelector {
  readonly __typename?: 'CopyrightAndLanguageSelector';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly copyrightText: Maybe<ILocaleString>;
}

export interface ICopyrightAndLanguageSelectorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly copyrightText?: InputMaybe<ILocaleStringFilter>;
}

export type ICopyrightAndLanguageSelectorOrHighlightedVerticalLinkListWidgetOrHorizontalLinkListWidgetOrImageWidgetOrSocialIconsWithHeaderWidgetOrTextWidgetOrTextWidgetWithUrlOrVerticalLinkListWidgetOrVerticalLinkListWithHeaderWidgetOrWordmarkAndSocialIcons =

    | ICopyrightAndLanguageSelector
    | IHighlightedVerticalLinkListWidget
    | IHorizontalLinkListWidget
    | IImageWidget
    | ISocialIconsWithHeaderWidget
    | ITextWidget
    | ITextWidgetWithUrl
    | IVerticalLinkListWidget
    | IVerticalLinkListWithHeaderWidget
    | IWordmarkAndSocialIcons;

export interface ICopyrightAndLanguageSelectorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly copyrightText?: InputMaybe<ILocaleStringSorting>;
}

export interface ICrossDatasetReference {
  readonly __typename?: 'CrossDatasetReference';
  readonly _dataset: Maybe<Scalars['String']['output']>;
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _projectId: Maybe<Scalars['String']['output']>;
  readonly _ref: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly _weak: Maybe<Scalars['Boolean']['output']>;
}

export interface ICrossDatasetReferenceFilter {
  readonly _dataset?: InputMaybe<IStringFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _projectId?: InputMaybe<IStringFilter>;
  readonly _ref?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _weak?: InputMaybe<IBooleanFilter>;
}

export interface ICrossDatasetReferenceSorting {
  readonly _dataset?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _projectId?: InputMaybe<SortOrder>;
  readonly _ref?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _weak?: InputMaybe<SortOrder>;
}

export interface ICss {
  readonly __typename?: 'Css';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly code: Maybe<Scalars['String']['output']>;
  readonly filename: Maybe<Scalars['String']['output']>;
  readonly highlightedLines: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>;
  readonly language: Maybe<Scalars['String']['output']>;
}

export interface ICssFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly code?: InputMaybe<IStringFilter>;
  readonly filename?: InputMaybe<IStringFilter>;
  readonly language?: InputMaybe<IStringFilter>;
}

export interface ICssSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly code?: InputMaybe<SortOrder>;
  readonly filename?: InputMaybe<SortOrder>;
  readonly language?: InputMaybe<SortOrder>;
}

export interface ICustomLink {
  readonly __typename?: 'CustomLink';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<ILocaleUrl>;
  readonly title: Maybe<Scalars['String']['output']>;
}

export interface ICustomLinkFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly title?: InputMaybe<IStringFilter>;
}

export interface ICustomLinkSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly title?: InputMaybe<SortOrder>;
}

export interface IDataDogConfig {
  readonly __typename?: 'DataDogConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly logs: Maybe<ILogsConfig>;
  readonly rum: Maybe<IRumConfig>;
  readonly rumRn: Maybe<IRumConfig>;
}

export interface IDataDogConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly logs?: InputMaybe<ILogsConfigFilter>;
  readonly rum?: InputMaybe<IRumConfigFilter>;
  readonly rumRn?: InputMaybe<IRumConfigFilter>;
}

export interface IDataDogConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly logs?: InputMaybe<ILogsConfigSorting>;
  readonly rum?: InputMaybe<IRumConfigSorting>;
  readonly rumRn?: InputMaybe<IRumConfigSorting>;
}

export interface IDataValidationObject {
  readonly __typename?: 'DataValidationObject';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Date of the last validation run for this document/validation type */
  readonly lastValidated: Maybe<Scalars['String']['output']>;
  /** a URL that will take us to the S3 CSV output (may want to make this a custom component that is a link) */
  readonly validationOutput: Maybe<Scalars['String']['output']>;
  /** test */
  readonly validationStatus: Maybe<Scalars['String']['output']>;
}

export interface IDataValidationObjectFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly lastValidated?: InputMaybe<IStringFilter>;
  readonly validationOutput?: InputMaybe<IStringFilter>;
  readonly validationStatus?: InputMaybe<IStringFilter>;
}

export interface IDataValidationObjectSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly lastValidated?: InputMaybe<SortOrder>;
  readonly validationOutput?: InputMaybe<SortOrder>;
  readonly validationStatus?: InputMaybe<SortOrder>;
}

export interface IDateFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is greater than the given input. */
  readonly gt?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  readonly gte?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  readonly lt?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  readonly lte?: InputMaybe<Scalars['Date']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['Date']['input']>;
}

export interface IDatetimeFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than the given input. */
  readonly gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  readonly gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  readonly lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  readonly lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface IDayOfWeekBand {
  readonly __typename?: 'DayOfWeekBand';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly friday: Maybe<Scalars['Boolean']['output']>;
  readonly monday: Maybe<Scalars['Boolean']['output']>;
  readonly saturday: Maybe<Scalars['Boolean']['output']>;
  readonly sunday: Maybe<Scalars['Boolean']['output']>;
  readonly thursday: Maybe<Scalars['Boolean']['output']>;
  readonly tuesday: Maybe<Scalars['Boolean']['output']>;
  readonly wednesday: Maybe<Scalars['Boolean']['output']>;
}

export interface IDayOfWeekBandFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly friday?: InputMaybe<IBooleanFilter>;
  readonly monday?: InputMaybe<IBooleanFilter>;
  readonly saturday?: InputMaybe<IBooleanFilter>;
  readonly sunday?: InputMaybe<IBooleanFilter>;
  readonly thursday?: InputMaybe<IBooleanFilter>;
  readonly tuesday?: InputMaybe<IBooleanFilter>;
  readonly wednesday?: InputMaybe<IBooleanFilter>;
}

export interface IDayOfWeekBandSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly friday?: InputMaybe<SortOrder>;
  readonly monday?: InputMaybe<SortOrder>;
  readonly saturday?: InputMaybe<SortOrder>;
  readonly sunday?: InputMaybe<SortOrder>;
  readonly thursday?: InputMaybe<SortOrder>;
  readonly tuesday?: InputMaybe<SortOrder>;
  readonly wednesday?: InputMaybe<SortOrder>;
}

export interface IDayPart {
  readonly __typename?: 'DayPart';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly displayName: Maybe<ILocaleString>;
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly icon: Maybe<IImage>;
  readonly key: Maybe<Scalars['String']['output']>;
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly weekDays: Maybe<IWeekDays>;
}

export interface IDayPartFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly displayName?: InputMaybe<ILocaleStringFilter>;
  readonly endTime?: InputMaybe<IStringFilter>;
  readonly icon?: InputMaybe<IImageFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly startTime?: InputMaybe<IStringFilter>;
  readonly weekDays?: InputMaybe<IWeekDaysFilter>;
}

export interface IDayPartSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly displayName?: InputMaybe<ILocaleStringSorting>;
  readonly endTime?: InputMaybe<SortOrder>;
  readonly icon?: InputMaybe<IImageSorting>;
  readonly key?: InputMaybe<SortOrder>;
  readonly startTime?: InputMaybe<SortOrder>;
  readonly weekDays?: InputMaybe<IWeekDaysSorting>;
}

export interface IDayParts {
  readonly __typename?: 'DayParts';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly displayName: Maybe<Scalars['String']['output']>;
  readonly hours: Maybe<IHoursOfOperation>;
  readonly key: Maybe<Scalars['String']['output']>;
}

export interface IDayPartsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly displayName?: InputMaybe<IStringFilter>;
  readonly hours?: InputMaybe<IHoursOfOperationFilter>;
  readonly key?: InputMaybe<IStringFilter>;
}

export interface IDayPartsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly displayName?: InputMaybe<SortOrder>;
  readonly hours?: InputMaybe<IHoursOfOperationSorting>;
  readonly key?: InputMaybe<SortOrder>;
}

export interface IDaypartRule {
  readonly __typename?: 'DaypartRule';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly daypart: Maybe<Scalars['String']['output']>;
}

export interface IDaypartRuleFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly daypart?: InputMaybe<IStringFilter>;
}

export interface IDaypartRuleSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly daypart?: InputMaybe<SortOrder>;
}

export interface IDeliveryVendor {
  readonly __typename?: 'DeliveryVendor';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly storeIdMapping: Maybe<Scalars['String']['output']>;
  readonly vendorName: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryVendorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly storeIdMapping?: InputMaybe<IStringFilter>;
  readonly vendorName?: InputMaybe<IStringFilter>;
}

export interface IDeliveryVendorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly storeIdMapping?: InputMaybe<SortOrder>;
  readonly vendorName?: InputMaybe<SortOrder>;
}

export interface IDialogInformation {
  readonly __typename?: 'DialogInformation';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttonAction: Maybe<ILinkAction>;
  readonly header: Maybe<ILocaleString>;
  readonly subtitle: Maybe<ILocaleString>;
}

export interface IDialogInformationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly buttonAction?: InputMaybe<ILinkActionFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IDialogInformationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly buttonAction?: InputMaybe<ILinkActionSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IDisplayGroup extends IDocument {
  readonly __typename?: 'DisplayGroup';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** If this is enabled, accordion will be auto expanded when navigating to customization screen */
  readonly autoExpandAccordion: Maybe<Scalars['Boolean']['output']>;
  readonly displayRank: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface IDisplayGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly autoExpandAccordion?: InputMaybe<IBooleanFilter>;
  readonly displayRank?: InputMaybe<IFloatFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IDisplayGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly autoExpandAccordion?: InputMaybe<SortOrder>;
  readonly displayRank?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

/** A Sanity document */
export interface IDocument {
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
}

export interface IDocumentFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IDocumentSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IDoubleImageWidget {
  readonly __typename?: 'DoubleImageWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly image1: Maybe<IImageWidget>;
  readonly image2: Maybe<IImageWidget>;
}

export interface IDoubleImageWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly image1?: InputMaybe<IImageWidgetFilter>;
  readonly image2?: InputMaybe<IImageWidgetFilter>;
}

export interface IDoubleImageWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly image1?: InputMaybe<IImageWidgetSorting>;
  readonly image2?: InputMaybe<IImageWidgetSorting>;
}

export interface IDownloadFileWidget {
  readonly __typename?: 'DownloadFileWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<ILocaleString>;
}

export interface IDownloadFileWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly url?: InputMaybe<ILocaleStringFilter>;
}

export interface IDownloadFileWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly url?: InputMaybe<ILocaleStringSorting>;
}

export interface IEarningCalculation extends IDocument {
  readonly __typename?: 'EarningCalculation';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Defines when loyalty points will be earned for Click & Collect orders (Pick Up, Eat In, Table Service, Drive-Thru, Curbside Pickup) */
  readonly clickAndCollect: Maybe<Scalars['String']['output']>;
  /** In seconds: the minimum time between purchases for loyalty to be applied */
  readonly cooldownPeriod: Maybe<Scalars['Float']['output']>;
  /** The basis for how points are earned */
  readonly coreMetric: Maybe<Scalars['String']['output']>;
  /** Defines when loyalty points will be earned for Delivery orders. */
  readonly deliveryOrders: Maybe<Scalars['String']['output']>;
  /** WARNING: this will turn on the Loyalty Tiers feature. */
  readonly enableLoyaltyTiers: Maybe<Scalars['Boolean']['output']>;
  readonly excludeFromLoyaltyPointsCalculation: Maybe<ReadonlyArray<Maybe<IModifierMultiplier>>>;
  /** The number of points all users will be awarded on Loyalty creation */
  readonly initialUserPointBalance: Maybe<Scalars['Float']['output']>;
  readonly loyaltyPointsCalculationUsing: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly loyaltyTiersEntranceBehaviorConfigurations: Maybe<
    ReadonlyArray<Maybe<ILoyaltyTierEntranceBehaviorConfiguration>>
  >;
  /** In cents: the minimum purchase subtotal for loyalty to be applied */
  readonly minSpend: Maybe<Scalars['Float']['output']>;
  /** In minutes: the minimum time between offer redemptions by user */
  readonly offerRedemptionCooldownPeriod: Maybe<Scalars['Float']['output']>;
  readonly offerRedemptionLimits: Maybe<IOfferRedemptionLimits>;
  /** Whether to extend loyalty points expiration to the nearest month, quarter or half year */
  readonly pointsExpirationByIssueDateGroup: Maybe<Scalars['String']['output']>;
  /** Number of days after which loyalty points expire */
  readonly pointsExpirationByIssueDateWindowDays: Maybe<Scalars['Float']['output']>;
  /** Decide whether to expire loyalty points based on when they were accrued or when users last completed an order */
  readonly pointsExpirationType: Maybe<Scalars['String']['output']>;
  /** In days: the minimum number after which, if a user has not been active, their Loyalty points will expire */
  readonly pointsExpiryDays: Maybe<Scalars['Float']['output']>;
  readonly pointsPerDollar: Maybe<Scalars['Float']['output']>;
  /** @deprecated This has been deprecated */
  readonly pointsPerVisit: Maybe<Scalars['Float']['output']>;
  readonly rewardsRedemptionConfig: Maybe<IRewardsRedemptionConfig>;
  readonly transactionEarningConfiguration: Maybe<ITransactionEarningConfiguration>;
}

export interface IEarningCalculationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly clickAndCollect?: InputMaybe<IStringFilter>;
  readonly cooldownPeriod?: InputMaybe<IFloatFilter>;
  readonly coreMetric?: InputMaybe<IStringFilter>;
  readonly deliveryOrders?: InputMaybe<IStringFilter>;
  readonly enableLoyaltyTiers?: InputMaybe<IBooleanFilter>;
  readonly initialUserPointBalance?: InputMaybe<IFloatFilter>;
  readonly minSpend?: InputMaybe<IFloatFilter>;
  readonly offerRedemptionCooldownPeriod?: InputMaybe<IFloatFilter>;
  readonly offerRedemptionLimits?: InputMaybe<IOfferRedemptionLimitsFilter>;
  readonly pointsExpirationByIssueDateGroup?: InputMaybe<IStringFilter>;
  readonly pointsExpirationByIssueDateWindowDays?: InputMaybe<IFloatFilter>;
  readonly pointsExpirationType?: InputMaybe<IStringFilter>;
  readonly pointsExpiryDays?: InputMaybe<IFloatFilter>;
  readonly pointsPerDollar?: InputMaybe<IFloatFilter>;
  readonly pointsPerVisit?: InputMaybe<IFloatFilter>;
  readonly rewardsRedemptionConfig?: InputMaybe<IRewardsRedemptionConfigFilter>;
  readonly transactionEarningConfiguration?: InputMaybe<ITransactionEarningConfigurationFilter>;
}

export interface IEarningCalculationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly clickAndCollect?: InputMaybe<SortOrder>;
  readonly cooldownPeriod?: InputMaybe<SortOrder>;
  readonly coreMetric?: InputMaybe<SortOrder>;
  readonly deliveryOrders?: InputMaybe<SortOrder>;
  readonly enableLoyaltyTiers?: InputMaybe<SortOrder>;
  readonly initialUserPointBalance?: InputMaybe<SortOrder>;
  readonly minSpend?: InputMaybe<SortOrder>;
  readonly offerRedemptionCooldownPeriod?: InputMaybe<SortOrder>;
  readonly offerRedemptionLimits?: InputMaybe<IOfferRedemptionLimitsSorting>;
  readonly pointsExpirationByIssueDateGroup?: InputMaybe<SortOrder>;
  readonly pointsExpirationByIssueDateWindowDays?: InputMaybe<SortOrder>;
  readonly pointsExpirationType?: InputMaybe<SortOrder>;
  readonly pointsExpiryDays?: InputMaybe<SortOrder>;
  readonly pointsPerDollar?: InputMaybe<SortOrder>;
  readonly pointsPerVisit?: InputMaybe<SortOrder>;
  readonly rewardsRedemptionConfig?: InputMaybe<IRewardsRedemptionConfigSorting>;
  readonly transactionEarningConfiguration?: InputMaybe<ITransactionEarningConfigurationSorting>;
}

export interface IEmailTemplates extends IDocument {
  readonly __typename?: 'EmailTemplates';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly emailTemplates: Maybe<Scalars['String']['output']>;
}

export interface IEmailTemplatesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly emailTemplates?: InputMaybe<IStringFilter>;
}

export interface IEmailTemplatesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly emailTemplates?: InputMaybe<SortOrder>;
}

export interface IEmailVerifiedRequired {
  readonly __typename?: 'EmailVerifiedRequired';
  /** @deprecated This rule is deprecated and soon will be removed */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This rule is deprecated and soon will be removed */
  readonly _type: Maybe<Scalars['String']['output']>;
  /**
   * Toggles the rule that is only valid if the user's email is verified. If set to true, the user's email needs to be verified to qualify for the incentive. If set to false, then the user's email does not need to be verified to qualify for the incentive
   * @deprecated This rule is deprecated and soon will be removed
   */
  readonly emailVerifiedRequired: Maybe<Scalars['Boolean']['output']>;
}

export interface IEmailVerifiedRequiredFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly emailVerifiedRequired?: InputMaybe<IBooleanFilter>;
}

export type IEmailVerifiedRequiredOrLimitOrLoyaltyBetweenDatesOrRequiresAuthenticationOrUserCreated =
  IEmailVerifiedRequired | ILimit | ILoyaltyBetweenDates | IRequiresAuthentication | IUserCreated;

export interface IEmailVerifiedRequiredSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly emailVerifiedRequired?: InputMaybe<SortOrder>;
}

export interface IEmptyState extends IDocument {
  readonly __typename?: 'EmptyState';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImageBackground>;
  readonly buttonAction: Maybe<ILinkAction>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly headerText: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  readonly logo: Maybe<ILocaleImages>;
  readonly marketingTileList: Maybe<IMarketingTileList>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly terms: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
}

export interface IEmptyStateFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageBackgroundFilter>;
  readonly buttonAction?: InputMaybe<ILinkActionFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly logo?: InputMaybe<ILocaleImagesFilter>;
  readonly marketingTileList?: InputMaybe<IMarketingTileListFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly terms?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IEmptyStateSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageBackgroundSorting>;
  readonly buttonAction?: InputMaybe<ILinkActionSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly logo?: InputMaybe<ILocaleImagesSorting>;
  readonly marketingTileList?: InputMaybe<IMarketingTileListSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly terms?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IEndOfOffersLoggedIn {
  readonly __typename?: 'EndOfOffersLoggedIn';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly endOfOffersImage: Maybe<ILocaleImage>;
  readonly endOfOffersMessage: Maybe<ILocaleString>;
}

export interface IEndOfOffersLoggedInFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endOfOffersImage?: InputMaybe<ILocaleImageFilter>;
  readonly endOfOffersMessage?: InputMaybe<ILocaleStringFilter>;
}

export interface IEndOfOffersLoggedInSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endOfOffersImage?: InputMaybe<ILocaleImageSorting>;
  readonly endOfOffersMessage?: InputMaybe<ILocaleStringSorting>;
}

export interface IEndOfOffersLoggedOut {
  readonly __typename?: 'EndOfOffersLoggedOut';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly ctaButtonText: Maybe<ILocaleString>;
  readonly link: Maybe<ILocaleUrl>;
  readonly title: Maybe<ILocaleString>;
}

export interface IEndOfOffersLoggedOutFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IEndOfOffersLoggedOutSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IEventReservation {
  readonly __typename?: 'EventReservation';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly allergens: Maybe<IAllergensConfig>;
  readonly birthdayAttendees: Maybe<ReadonlyArray<Maybe<IBirthdayAttendee>>>;
  readonly cateringAttendees: Maybe<ReadonlyArray<Maybe<ICateringAttendee>>>;
  readonly eventType: Maybe<Scalars['String']['output']>;
  readonly reservationTime: Maybe<IEventReservationTime>;
}

export interface IEventReservationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly allergens?: InputMaybe<IAllergensConfigFilter>;
  readonly eventType?: InputMaybe<IStringFilter>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeFilter>;
}

export interface IEventReservationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly allergens?: InputMaybe<IAllergensConfigSorting>;
  readonly eventType?: InputMaybe<SortOrder>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeSorting>;
}

export interface IEventReservationTime {
  readonly __typename?: 'EventReservationTime';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly availableHours: Maybe<ReadonlyArray<Maybe<ITimeRange>>>;
  readonly earliestReservationDay: Maybe<Scalars['Float']['output']>;
  readonly latestReservationDay: Maybe<Scalars['Float']['output']>;
}

export interface IEventReservationTimeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly earliestReservationDay?: InputMaybe<IFloatFilter>;
  readonly latestReservationDay?: InputMaybe<IFloatFilter>;
}

export interface IEventReservationTimeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly earliestReservationDay?: InputMaybe<SortOrder>;
  readonly latestReservationDay?: InputMaybe<SortOrder>;
}

export interface IExternalLink {
  readonly __typename?: 'ExternalLink';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly link: Maybe<ILocaleUrl>;
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleString>;
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface IExternalLinkFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly url?: InputMaybe<IStringFilter>;
}

export interface IExternalLinkSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly url?: InputMaybe<SortOrder>;
}

export interface IExternalOffers extends IDocument {
  readonly __typename?: 'ExternalOffers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Toggles the rule that is only valid if the user is authenticated. If set to true, the user needs to be authenticated to qualify for the incentive. If set to false, then the user does not need to be authenticated to qualify for the incentive. */
  readonly authentificationRequired: Maybe<Scalars['Boolean']['output']>;
  readonly backgroundImage: Maybe<IImage>;
  readonly buttonsConfiguration: Maybe<IExternalOffersButtonsConfiguration>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly externalOfferModal: Maybe<IExternalOffersModalContent>;
  readonly image: Maybe<ILocaleImage>;
  readonly subtitle: Maybe<ILocaleString>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
  readonly tierIncentiveList: Maybe<ReadonlyArray<Maybe<ITierIncentiveList>>>;
  readonly title: Maybe<ILocaleString>;
  readonly unauthenticatedUsersContent: Maybe<IExternalOffersUnauthenticatedUsersContent>;
}

export interface IExternalOffersButtonsConfiguration {
  readonly __typename?: 'ExternalOffersButtonsConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly firstButton: Maybe<IActionButton>;
  readonly secondButton: Maybe<IActionButtonNotRequired>;
}

export interface IExternalOffersButtonsConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly firstButton?: InputMaybe<IActionButtonFilter>;
  readonly secondButton?: InputMaybe<IActionButtonNotRequiredFilter>;
}

export interface IExternalOffersButtonsConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly firstButton?: InputMaybe<IActionButtonSorting>;
  readonly secondButton?: InputMaybe<IActionButtonNotRequiredSorting>;
}

export interface IExternalOffersEmptyStateContent {
  readonly __typename?: 'ExternalOffersEmptyStateContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly emptyStateImageContent: Maybe<IExternalOffersEmptyStateImage>;
  readonly title: Maybe<ILocaleString>;
}

export interface IExternalOffersEmptyStateContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateImageContent?: InputMaybe<IExternalOffersEmptyStateImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IExternalOffersEmptyStateContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateImageContent?: InputMaybe<IExternalOffersEmptyStateImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IExternalOffersEmptyStateImage {
  readonly __typename?: 'ExternalOffersEmptyStateImage';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ctaButtonLink: Maybe<ILocaleString>;
  readonly ctaButtonText: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
}

export interface IExternalOffersEmptyStateImageFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ctaButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
}

export interface IExternalOffersEmptyStateImageSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ctaButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
}

export interface IExternalOffersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly authentificationRequired?: InputMaybe<IBooleanFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly buttonsConfiguration?: InputMaybe<IExternalOffersButtonsConfigurationFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly externalOfferModal?: InputMaybe<IExternalOffersModalContentFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
  readonly unauthenticatedUsersContent?: InputMaybe<IExternalOffersUnauthenticatedUsersContentFilter>;
}

export interface IExternalOffersModalContent {
  readonly __typename?: 'ExternalOffersModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
}

export interface IExternalOffersModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IExternalOffersModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export type IExternalOffersOrSystemwideOffer = IExternalOffers | ISystemwideOffer;

export interface IExternalOffersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly authentificationRequired?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly buttonsConfiguration?: InputMaybe<IExternalOffersButtonsConfigurationSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly externalOfferModal?: InputMaybe<IExternalOffersModalContentSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
  readonly unauthenticatedUsersContent?: InputMaybe<IExternalOffersUnauthenticatedUsersContentSorting>;
}

export interface IExternalOffersUnauthenticatedUsersContent {
  readonly __typename?: 'ExternalOffersUnauthenticatedUsersContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
}

export interface IExternalOffersUnauthenticatedUsersContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IExternalOffersUnauthenticatedUsersContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IFacebookConfig {
  readonly __typename?: 'FacebookConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly socialLogin: Maybe<ISocialLoginFacebookConfig>;
}

export interface IFacebookConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly socialLogin?: InputMaybe<ISocialLoginFacebookConfigFilter>;
}

export interface IFacebookConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly socialLogin?: InputMaybe<ISocialLoginFacebookConfigSorting>;
}

export interface IFanFavorites extends IDocument {
  readonly __typename?: 'FanFavorites';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly fanFavoriteItems: Maybe<ReadonlyArray<Maybe<IComboOrItemOrOfferOrPickerOrSection>>>;
  readonly favoriteSection: Maybe<IFavoriteSection>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IFanFavoritesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly favoriteSection?: InputMaybe<IFavoriteSectionFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IFanFavoritesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly favoriteSection?: InputMaybe<IFavoriteSectionSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IFavoriteSection {
  readonly __typename?: 'FavoriteSection';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly headerText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly orderNowText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly subheaderText: Maybe<ILocaleString>;
}

export interface IFavoriteSectionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
  readonly orderNowText?: InputMaybe<ILocaleStringFilter>;
  readonly subheaderText?: InputMaybe<ILocaleStringFilter>;
}

export interface IFavoriteSectionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
  readonly orderNowText?: InputMaybe<ILocaleStringSorting>;
  readonly subheaderText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureAccount extends IDocument {
  readonly __typename?: 'FeatureAccount';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly emailAndPushOptInSettings: Maybe<
    ReadonlyArray<
      Maybe<IOptInPrivacyPreferencesOrOptInSettingBooleanOrOptInSettingEmailOrOptInSettingEmailAndPushOrOptInSettingEnumOrOptInSettingPushOrOptInSettingSms>
    >
  >;
  /** Choose a minimum age to sign up/account */
  readonly minimumSignUpAge: Maybe<Scalars['Float']['output']>;
}

export interface IFeatureAccountDeletePage extends IDocument {
  readonly __typename?: 'FeatureAccountDeletePage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly cancelButtonText: Maybe<ILocaleString>;
  readonly confirmButtonText: Maybe<ILocaleString>;
  readonly content: Maybe<ILocaleBlockContent>;
  readonly errorText: Maybe<ILocaleString>;
  readonly headerText: Maybe<ILocaleString>;
  readonly oneTrustDeleteUrl: Maybe<Scalars['String']['output']>;
}

export interface IFeatureAccountDeletePageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly cancelButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly confirmButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly content?: InputMaybe<ILocaleBlockContentFilter>;
  readonly errorText?: InputMaybe<ILocaleStringFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
  readonly oneTrustDeleteUrl?: InputMaybe<IStringFilter>;
}

export interface IFeatureAccountDeletePageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly cancelButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly confirmButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly content?: InputMaybe<ILocaleBlockContentSorting>;
  readonly errorText?: InputMaybe<ILocaleStringSorting>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
  readonly oneTrustDeleteUrl?: InputMaybe<SortOrder>;
}

export interface IFeatureAccountFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly minimumSignUpAge?: InputMaybe<IFloatFilter>;
}

export interface IFeatureAccountForm extends IDocument {
  readonly __typename?: 'FeatureAccountForm';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly activeCampaigns: Maybe<ReadonlyArray<Maybe<IFeatureAuthCampaign>>>;
  readonly desktopImage: Maybe<ILocaleImages>;
  /** Choose a minimum age to sign up */
  readonly minimumSignUpAge: Maybe<Scalars['Float']['output']>;
  readonly postalOrZipDisclaimer: Maybe<ILocaleString>;
  readonly privacyPolicyDisclaimers: Maybe<IPrivacyPolicyDisclaimers>;
  readonly signUpTerms: Maybe<ISignUpTerms>;
}

export interface IFeatureAccountFormFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly desktopImage?: InputMaybe<ILocaleImagesFilter>;
  readonly minimumSignUpAge?: InputMaybe<IFloatFilter>;
  readonly postalOrZipDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersFilter>;
  readonly signUpTerms?: InputMaybe<ISignUpTermsFilter>;
}

export interface IFeatureAccountFormSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly desktopImage?: InputMaybe<ILocaleImagesSorting>;
  readonly minimumSignUpAge?: InputMaybe<SortOrder>;
  readonly postalOrZipDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersSorting>;
  readonly signUpTerms?: InputMaybe<ISignUpTermsSorting>;
}

export interface IFeatureAccountRequestInfoPage extends IDocument {
  readonly __typename?: 'FeatureAccountRequestInfoPage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly confirmButtonText: Maybe<ILocaleString>;
  readonly content: Maybe<ILocaleBlockContent>;
  readonly errorText: Maybe<ILocaleString>;
  readonly headerText: Maybe<ILocaleString>;
}

export interface IFeatureAccountRequestInfoPageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly confirmButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly content?: InputMaybe<ILocaleBlockContentFilter>;
  readonly errorText?: InputMaybe<ILocaleStringFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureAccountRequestInfoPageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly confirmButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly content?: InputMaybe<ILocaleBlockContentSorting>;
  readonly errorText?: InputMaybe<ILocaleStringSorting>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureAccountSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly minimumSignUpAge?: InputMaybe<SortOrder>;
}

export interface IFeatureAccountUpdatedAgreements extends IDocument {
  readonly __typename?: 'FeatureAccountUpdatedAgreements';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly marketingCommunicationText: Maybe<ILocaleBlockContent>;
  readonly updateAgreementText: Maybe<ILocaleBlockContent>;
}

export interface IFeatureAccountUpdatedAgreementsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly marketingCommunicationText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly updateAgreementText?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IFeatureAccountUpdatedAgreementsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly marketingCommunicationText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly updateAgreementText?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IFeatureAggregatorRedirectButtons extends IDocument {
  readonly __typename?: 'FeatureAggregatorRedirectButtons';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly aggregatorRedirectButtons: Maybe<ReadonlyArray<Maybe<IAggregatorRedirectButton>>>;
}

export interface IFeatureAggregatorRedirectButtonsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IFeatureAggregatorRedirectButtonsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IFeatureAuthCampaign extends IDocument {
  readonly __typename?: 'FeatureAuthCampaign';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly campaignId: Maybe<Scalars['String']['output']>;
  readonly campaignImage: Maybe<ILocaleImage>;
  readonly campaignImageAltText: Maybe<ILocaleString>;
  readonly campaignText: Maybe<ILocaleBlockContent>;
  readonly campaingBackgroundColor: Maybe<IColor>;
  /** Flag that determines extra fields components visibility */
  readonly shouldDisplayExtraFields: Maybe<Scalars['Boolean']['output']>;
}

export interface IFeatureAuthCampaignFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly campaignId?: InputMaybe<IStringFilter>;
  readonly campaignImage?: InputMaybe<ILocaleImageFilter>;
  readonly campaignImageAltText?: InputMaybe<ILocaleStringFilter>;
  readonly campaignText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly campaingBackgroundColor?: InputMaybe<IColorFilter>;
  readonly shouldDisplayExtraFields?: InputMaybe<IBooleanFilter>;
}

export interface IFeatureAuthCampaignSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly campaignId?: InputMaybe<SortOrder>;
  readonly campaignImage?: InputMaybe<ILocaleImageSorting>;
  readonly campaignImageAltText?: InputMaybe<ILocaleStringSorting>;
  readonly campaignText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly campaingBackgroundColor?: InputMaybe<IColorSorting>;
  readonly shouldDisplayExtraFields?: InputMaybe<SortOrder>;
}

export interface IFeatureBeeperModal extends IDocument {
  readonly __typename?: 'FeatureBeeperModal';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly beeperButtonText: Maybe<ILocaleString>;
  readonly beeperModalBody: Maybe<ILocaleString>;
  readonly beeperModalImage: Maybe<ILocaleImage>;
  readonly beeperModalTitle: Maybe<ILocaleString>;
}

export interface IFeatureBeeperModalFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly beeperButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly beeperModalBody?: InputMaybe<ILocaleStringFilter>;
  readonly beeperModalImage?: InputMaybe<ILocaleImageFilter>;
  readonly beeperModalTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureBeeperModalSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly beeperButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly beeperModalBody?: InputMaybe<ILocaleStringSorting>;
  readonly beeperModalImage?: InputMaybe<ILocaleImageSorting>;
  readonly beeperModalTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureBirthday {
  readonly __typename?: 'FeatureBirthday';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly allergens: Maybe<IAllergensConfig>;
  readonly birthdayAttendees: Maybe<ReadonlyArray<Maybe<IBirthdayAttendee>>>;
  readonly cateringAttendees: Maybe<ReadonlyArray<Maybe<ICateringAttendee>>>;
  readonly eventType: Maybe<Scalars['String']['output']>;
  readonly reservationTime: Maybe<IEventReservationTime>;
}

export interface IFeatureBirthdayFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly allergens?: InputMaybe<IAllergensConfigFilter>;
  readonly eventType?: InputMaybe<IStringFilter>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeFilter>;
}

export type IFeatureBirthdayOrFeatureCatering = IFeatureBirthday | IFeatureCatering;

export interface IFeatureBirthdaySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly allergens?: InputMaybe<IAllergensConfigSorting>;
  readonly eventType?: InputMaybe<SortOrder>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeSorting>;
}

export interface IFeatureCatering {
  readonly __typename?: 'FeatureCatering';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly allergens: Maybe<IAllergensConfig>;
  readonly birthdayAttendees: Maybe<ReadonlyArray<Maybe<IBirthdayAttendee>>>;
  readonly cateringAttendees: Maybe<ReadonlyArray<Maybe<ICateringAttendee>>>;
  readonly eventType: Maybe<Scalars['String']['output']>;
  readonly reservationTime: Maybe<IEventReservationTime>;
}

export interface IFeatureCateringFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly allergens?: InputMaybe<IAllergensConfigFilter>;
  readonly eventType?: InputMaybe<IStringFilter>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeFilter>;
}

export interface IFeatureCateringPage extends IDocument {
  readonly __typename?: 'FeatureCateringPage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly appAuthenticated: Maybe<ICateringPageConfiguration>;
  readonly appUnauthenticated: Maybe<ICateringPageConfiguration>;
  readonly webAuthenticated: Maybe<ICateringPageConfiguration>;
  readonly webUnauthenticated: Maybe<ICateringPageConfiguration>;
}

export interface IFeatureCateringPageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appAuthenticated?: InputMaybe<ICateringPageConfigurationFilter>;
  readonly appUnauthenticated?: InputMaybe<ICateringPageConfigurationFilter>;
  readonly webAuthenticated?: InputMaybe<ICateringPageConfigurationFilter>;
  readonly webUnauthenticated?: InputMaybe<ICateringPageConfigurationFilter>;
}

export interface IFeatureCateringPageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly appAuthenticated?: InputMaybe<ICateringPageConfigurationSorting>;
  readonly appUnauthenticated?: InputMaybe<ICateringPageConfigurationSorting>;
  readonly webAuthenticated?: InputMaybe<ICateringPageConfigurationSorting>;
  readonly webUnauthenticated?: InputMaybe<ICateringPageConfigurationSorting>;
}

export interface IFeatureCateringSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly allergens?: InputMaybe<IAllergensConfigSorting>;
  readonly eventType?: InputMaybe<SortOrder>;
  readonly reservationTime?: InputMaybe<IEventReservationTimeSorting>;
}

export interface IFeatureDisclaimers extends IDocument {
  readonly __typename?: 'FeatureDisclaimers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly calorie: Maybe<ILocaleBlockContent>;
  readonly communicationPreferencesBlock: Maybe<ILocaleBlockContent>;
  readonly copyrightAndTrademark: Maybe<ILocaleString>;
  readonly country: Maybe<ILocaleString>;
  readonly deliveryDisclaimer: Maybe<ILocaleString>;
  readonly deliveryLogo: Maybe<ILocaleImage>;
  readonly images: Maybe<ILocaleString>;
  readonly itemAvailability: Maybe<ILocaleString>;
  readonly offers: Maybe<ILocaleString>;
  readonly phoneNumber: Maybe<ILocaleString>;
  readonly price: Maybe<ILocaleString>;
  readonly reservedRights: Maybe<ILocaleString>;
  readonly signUpDisclaimers: Maybe<ILocaleString>;
  readonly signUpMarketDisclaimers: Maybe<ILocaleString>;
  readonly superUpsell: Maybe<ILocaleBlockContent>;
}

export interface IFeatureDisclaimersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly calorie?: InputMaybe<ILocaleBlockContentFilter>;
  readonly communicationPreferencesBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly copyrightAndTrademark?: InputMaybe<ILocaleStringFilter>;
  readonly country?: InputMaybe<ILocaleStringFilter>;
  readonly deliveryDisclaimer?: InputMaybe<ILocaleStringFilter>;
  readonly deliveryLogo?: InputMaybe<ILocaleImageFilter>;
  readonly images?: InputMaybe<ILocaleStringFilter>;
  readonly itemAvailability?: InputMaybe<ILocaleStringFilter>;
  readonly offers?: InputMaybe<ILocaleStringFilter>;
  readonly phoneNumber?: InputMaybe<ILocaleStringFilter>;
  readonly price?: InputMaybe<ILocaleStringFilter>;
  readonly reservedRights?: InputMaybe<ILocaleStringFilter>;
  readonly signUpDisclaimers?: InputMaybe<ILocaleStringFilter>;
  readonly signUpMarketDisclaimers?: InputMaybe<ILocaleStringFilter>;
  readonly superUpsell?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IFeatureDisclaimersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly calorie?: InputMaybe<ILocaleBlockContentSorting>;
  readonly communicationPreferencesBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly copyrightAndTrademark?: InputMaybe<ILocaleStringSorting>;
  readonly country?: InputMaybe<ILocaleStringSorting>;
  readonly deliveryDisclaimer?: InputMaybe<ILocaleStringSorting>;
  readonly deliveryLogo?: InputMaybe<ILocaleImageSorting>;
  readonly images?: InputMaybe<ILocaleStringSorting>;
  readonly itemAvailability?: InputMaybe<ILocaleStringSorting>;
  readonly offers?: InputMaybe<ILocaleStringSorting>;
  readonly phoneNumber?: InputMaybe<ILocaleStringSorting>;
  readonly price?: InputMaybe<ILocaleStringSorting>;
  readonly reservedRights?: InputMaybe<ILocaleStringSorting>;
  readonly signUpDisclaimers?: InputMaybe<ILocaleStringSorting>;
  readonly signUpMarketDisclaimers?: InputMaybe<ILocaleStringSorting>;
  readonly superUpsell?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IFeatureDonation extends IDocument {
  readonly __typename?: 'FeatureDonation';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly dialogInformation: Maybe<IDialogInformation>;
  readonly donationItemList: Maybe<ReadonlyArray<Maybe<IItem>>>;
  readonly header: Maybe<ILocaleString>;
  readonly logoImage: Maybe<ILocaleImage>;
  readonly subtitle: Maybe<ILocaleString>;
}

export interface IFeatureDonationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly dialogInformation?: InputMaybe<IDialogInformationFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly logoImage?: InputMaybe<ILocaleImageFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureDonationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly dialogInformation?: InputMaybe<IDialogInformationSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly logoImage?: InputMaybe<ILocaleImageSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureEventReservations extends IDocument {
  readonly __typename?: 'FeatureEventReservations';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly enabledEvents: Maybe<ReadonlyArray<Maybe<IFeatureBirthdayOrFeatureCatering>>>;
  readonly privacyPolicyDisclaimers: Maybe<IPrivacyPolicyDisclaimers>;
}

export interface IFeatureEventReservationsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersFilter>;
}

export interface IFeatureEventReservationsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly privacyPolicyDisclaimers?: InputMaybe<IPrivacyPolicyDisclaimersSorting>;
}

export interface IFeatureExternalLinkSection extends IDocument {
  readonly __typename?: 'FeatureExternalLinkSection';
  readonly FeatureExternalLinks: Maybe<ReadonlyArray<Maybe<ICustomLink>>>;
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
}

export interface IFeatureExternalLinkSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IFeatureExternalLinkSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IFeatureFeesAndTaxes extends IDocument {
  readonly __typename?: 'FeatureFeesAndTaxes';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly bagFeeConfigurations: Maybe<IBagFeeConfigurations>;
}

export interface IFeatureFeesAndTaxesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly bagFeeConfigurations?: InputMaybe<IBagFeeConfigurationsFilter>;
}

export interface IFeatureFeesAndTaxesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly bagFeeConfigurations?: InputMaybe<IBagFeeConfigurationsSorting>;
}

export interface IFeatureFoodware extends IDocument {
  readonly __typename?: 'FeatureFoodware';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly items: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This is deprecated */
  readonly title: Maybe<ILocaleString>;
}

export interface IFeatureFoodwareFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureFoodwareSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureFooter extends IDocument {
  readonly __typename?: 'FeatureFooter';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly rows: Maybe<ReadonlyArray<Maybe<IRow>>>;
}

export interface IFeatureFooterFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IFeatureFooterSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IFeatureFooterV2 extends IDocument {
  readonly __typename?: 'FeatureFooterV2';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly copyrightText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly mainLinks: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
  /** @deprecated This is deprecated */
  readonly miscellaneousLinks: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
  /** @deprecated This is deprecated */
  readonly socialMediaData: Maybe<ISocialIconsWidget>;
}

export interface IFeatureFooterV2Filter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly copyrightText?: InputMaybe<ILocaleStringFilter>;
  readonly socialMediaData?: InputMaybe<ISocialIconsWidgetFilter>;
}

export interface IFeatureFooterV2Sorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly copyrightText?: InputMaybe<ILocaleStringSorting>;
  readonly socialMediaData?: InputMaybe<ISocialIconsWidgetSorting>;
}

export interface IFeatureGeolocationMapMarkers extends IDocument {
  readonly __typename?: 'FeatureGeolocationMapMarkers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly driverIcon: Maybe<ILocaleImage>;
}

export interface IFeatureGeolocationMapMarkersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly driverIcon?: InputMaybe<ILocaleImageFilter>;
}

export interface IFeatureGeolocationMapMarkersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly driverIcon?: InputMaybe<ILocaleImageSorting>;
}

export interface IFeatureGeolocationModal extends IDocument {
  readonly __typename?: 'FeatureGeolocationModal';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<ILocaleImage>;
  readonly body: Maybe<ILocaleString>;
  readonly deliveryImage: Maybe<ILocaleImage>;
  readonly header: Maybe<ILocaleString>;
  readonly logo: Maybe<ILocaleImage>;
}

export interface IFeatureGeolocationModalFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<ILocaleImageFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly deliveryImage?: InputMaybe<ILocaleImageFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly logo?: InputMaybe<ILocaleImageFilter>;
}

export interface IFeatureGeolocationModalSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<ILocaleImageSorting>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly deliveryImage?: InputMaybe<ILocaleImageSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly logo?: InputMaybe<ILocaleImageSorting>;
}

export interface IFeatureGeolocationTermsModal extends IDocument {
  readonly __typename?: 'FeatureGeolocationTermsModal';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly border: Maybe<Scalars['Boolean']['output']>;
  readonly fontSize: Maybe<Scalars['Float']['output']>;
  readonly geolocationTermsContent: Maybe<ILocaleBlockContent>;
  readonly geolocationTermsModalTitle: Maybe<Scalars['String']['output']>;
  readonly paddingBottom: Maybe<Scalars['Float']['output']>;
  readonly paddingLeft: Maybe<Scalars['Float']['output']>;
  readonly paddingRight: Maybe<Scalars['Float']['output']>;
  readonly paddingTop: Maybe<Scalars['Float']['output']>;
  readonly radius: Maybe<Scalars['Float']['output']>;
}

export interface IFeatureGeolocationTermsModalFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly border?: InputMaybe<IBooleanFilter>;
  readonly fontSize?: InputMaybe<IFloatFilter>;
  readonly geolocationTermsContent?: InputMaybe<ILocaleBlockContentFilter>;
  readonly geolocationTermsModalTitle?: InputMaybe<IStringFilter>;
  readonly paddingBottom?: InputMaybe<IFloatFilter>;
  readonly paddingLeft?: InputMaybe<IFloatFilter>;
  readonly paddingRight?: InputMaybe<IFloatFilter>;
  readonly paddingTop?: InputMaybe<IFloatFilter>;
  readonly radius?: InputMaybe<IFloatFilter>;
}

export interface IFeatureGeolocationTermsModalSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly border?: InputMaybe<SortOrder>;
  readonly fontSize?: InputMaybe<SortOrder>;
  readonly geolocationTermsContent?: InputMaybe<ILocaleBlockContentSorting>;
  readonly geolocationTermsModalTitle?: InputMaybe<SortOrder>;
  readonly paddingBottom?: InputMaybe<SortOrder>;
  readonly paddingLeft?: InputMaybe<SortOrder>;
  readonly paddingRight?: InputMaybe<SortOrder>;
  readonly paddingTop?: InputMaybe<SortOrder>;
  readonly radius?: InputMaybe<SortOrder>;
}

export interface IFeatureGift extends IDocument {
  readonly __typename?: 'FeatureGift';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly backgroundImage: Maybe<ILocaleImages>;
  /** @deprecated This is deprecated */
  readonly header: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly legalDisclaimer: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly receptionModalImage: Maybe<ILocaleImages>;
  /** @deprecated This is deprecated */
  readonly subHeader: Maybe<ILocaleBlockContent>;
}

export interface IFeatureGiftFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<ILocaleImagesFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly legalDisclaimer?: InputMaybe<ILocaleBlockContentFilter>;
  readonly receptionModalImage?: InputMaybe<ILocaleImagesFilter>;
  readonly subHeader?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IFeatureGiftSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<ILocaleImagesSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly legalDisclaimer?: InputMaybe<ILocaleBlockContentSorting>;
  readonly receptionModalImage?: InputMaybe<ILocaleImagesSorting>;
  readonly subHeader?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IFeatureGuestCheckout extends IDocument {
  readonly __typename?: 'FeatureGuestCheckout';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Allows customers to place orders and make payments without the need to create an account in the app and website, streamlining the ordering process for faster service. */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  /** Time that determines for how long the guest data will be kept in the database according to legal requirements. */
  readonly ttlInYears: Maybe<Scalars['Float']['output']>;
}

export interface IFeatureGuestCheckoutFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
  readonly ttlInYears?: InputMaybe<IFloatFilter>;
}

export interface IFeatureGuestCheckoutSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly documentDescription?: InputMaybe<SortOrder>;
  readonly ttlInYears?: InputMaybe<SortOrder>;
}

export interface IFeatureHomePage extends IDocument {
  readonly __typename?: 'FeatureHomePage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly appAuthenticated: Maybe<IHomePageConfiguration>;
  readonly appUnauthenticated: Maybe<IHomePageConfiguration>;
  readonly name: Maybe<ILocaleString>;
  readonly webAuthenticated: Maybe<IHomePageConfiguration>;
  readonly webUnauthenticated: Maybe<IHomePageConfiguration>;
}

export interface IFeatureHomePageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appAuthenticated?: InputMaybe<IHomePageConfigurationFilter>;
  readonly appUnauthenticated?: InputMaybe<IHomePageConfigurationFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly webAuthenticated?: InputMaybe<IHomePageConfigurationFilter>;
  readonly webUnauthenticated?: InputMaybe<IHomePageConfigurationFilter>;
}

export interface IFeatureHomePageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly appAuthenticated?: InputMaybe<IHomePageConfigurationSorting>;
  readonly appUnauthenticated?: InputMaybe<IHomePageConfigurationSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly webAuthenticated?: InputMaybe<IHomePageConfigurationSorting>;
  readonly webUnauthenticated?: InputMaybe<IHomePageConfigurationSorting>;
}

export interface IFeatureIFrame extends IDocument {
  readonly __typename?: 'FeatureIFrame';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Specify where the iFrame should be available. */
  readonly enableIFrameComponent: Maybe<Scalars['String']['output']>;
  /** If turned off, the iFrame will show for both Authenticated and Unauthenticated users */
  readonly iFrameAvailableForAthenticatedUsers: Maybe<Scalars['Boolean']['output']>;
  readonly iFrameCampaignUrlApp: Maybe<ILocaleString>;
  readonly iFrameCampaignUrlWeb: Maybe<ILocaleString>;
  /** RBI Cognito ID will only be passed if user is authenticated */
  readonly passCognitoIdToIframe: Maybe<Scalars['Boolean']['output']>;
  /** Loyalty ID will only be passed if user is authenticated */
  readonly passLoyaltyIdToIframe: Maybe<Scalars['Boolean']['output']>;
}

export interface IFeatureIFrameFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly enableIFrameComponent?: InputMaybe<IStringFilter>;
  readonly iFrameAvailableForAthenticatedUsers?: InputMaybe<IBooleanFilter>;
  readonly iFrameCampaignUrlApp?: InputMaybe<ILocaleStringFilter>;
  readonly iFrameCampaignUrlWeb?: InputMaybe<ILocaleStringFilter>;
  readonly passCognitoIdToIframe?: InputMaybe<IBooleanFilter>;
  readonly passLoyaltyIdToIframe?: InputMaybe<IBooleanFilter>;
}

export interface IFeatureIFrameSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly enableIFrameComponent?: InputMaybe<SortOrder>;
  readonly iFrameAvailableForAthenticatedUsers?: InputMaybe<SortOrder>;
  readonly iFrameCampaignUrlApp?: InputMaybe<ILocaleStringSorting>;
  readonly iFrameCampaignUrlWeb?: InputMaybe<ILocaleStringSorting>;
  readonly passCognitoIdToIframe?: InputMaybe<SortOrder>;
  readonly passLoyaltyIdToIframe?: InputMaybe<SortOrder>;
}

export interface IFeatureInvitationCode extends IDocument {
  readonly __typename?: 'FeatureInvitationCode';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** WARNING: this will turn on the invitation code on sign up feature for all users in the app */
  readonly enableInvitationCode: Maybe<Scalars['Boolean']['output']>;
  readonly invitationCodeBenefit: Maybe<ReadonlyArray<Maybe<IConfigQuest>>>;
  /** @deprecated This is deprecated */
  readonly invitationCodeIncentive: Maybe<IInvitationCodeIncentive>;
  readonly invitationCodeInformationCards: Maybe<
    ReadonlyArray<Maybe<IInvitationCodeInformationCard>>
  >;
  readonly invitationCodeSignInModal: Maybe<IInvitationCodeSignInModalContent>;
  readonly invitationCodeTitle: Maybe<ILocaleString>;
  readonly invitationCodeUnlocksBenefitModal: Maybe<IInvitationCodeUnlocksBenefitModalContent>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<IEmailVerifiedRequiredOrLimitOrLoyaltyBetweenDatesOrRequiresAuthenticationOrUserCreated>
    >
  >;
  readonly shareMyCodeButtonText: Maybe<ILocaleString>;
  readonly shareMyCodeButtonUrl: Maybe<ILocaleString>;
  readonly termsAndConditions: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface IFeatureInvitationCodeFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly enableInvitationCode?: InputMaybe<IBooleanFilter>;
  readonly invitationCodeIncentive?: InputMaybe<IInvitationCodeIncentiveFilter>;
  readonly invitationCodeSignInModal?: InputMaybe<IInvitationCodeSignInModalContentFilter>;
  readonly invitationCodeTitle?: InputMaybe<ILocaleStringFilter>;
  readonly invitationCodeUnlocksBenefitModal?: InputMaybe<IInvitationCodeUnlocksBenefitModalContentFilter>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly shareMyCodeButtonUrl?: InputMaybe<ILocaleStringFilter>;
  readonly termsAndConditions?: InputMaybe<ILocaleBlockContentFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface IFeatureInvitationCodeSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly enableInvitationCode?: InputMaybe<SortOrder>;
  readonly invitationCodeIncentive?: InputMaybe<IInvitationCodeIncentiveSorting>;
  readonly invitationCodeSignInModal?: InputMaybe<IInvitationCodeSignInModalContentSorting>;
  readonly invitationCodeTitle?: InputMaybe<ILocaleStringSorting>;
  readonly invitationCodeUnlocksBenefitModal?: InputMaybe<IInvitationCodeUnlocksBenefitModalContentSorting>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly shareMyCodeButtonUrl?: InputMaybe<ILocaleStringSorting>;
  readonly termsAndConditions?: InputMaybe<ILocaleBlockContentSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface IFeatureKiosk extends IDocument {
  readonly __typename?: 'FeatureKiosk';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly appData: Maybe<IKioskAppData>;
  /** @deprecated This is deprecated */
  readonly attractLoopImage: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly rewardsMainImage: Maybe<ILocaleImage>;
}

export interface IFeatureKioskDonation extends IDocument {
  readonly __typename?: 'FeatureKioskDonation';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly dialogInformation: Maybe<IDialogInformation>;
  readonly donationItemList: Maybe<ReadonlyArray<Maybe<IItem>>>;
  readonly header: Maybe<ILocaleString>;
  readonly logoImage: Maybe<ILocaleImage>;
  readonly subtitle: Maybe<ILocaleString>;
}

export interface IFeatureKioskDonationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly dialogInformation?: InputMaybe<IDialogInformationFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly logoImage?: InputMaybe<ILocaleImageFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureKioskDonationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly dialogInformation?: InputMaybe<IDialogInformationSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly logoImage?: InputMaybe<ILocaleImageSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureKioskFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appData?: InputMaybe<IKioskAppDataFilter>;
  readonly attractLoopImage?: InputMaybe<ILocaleImageFilter>;
  readonly rewardsMainImage?: InputMaybe<ILocaleImageFilter>;
}

export interface IFeatureKioskHomePage extends IDocument {
  readonly __typename?: 'FeatureKioskHomePage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly kioskAuthenticated: Maybe<IHomePageConfiguration>;
  readonly kioskUnauthenticated: Maybe<IHomePageConfiguration>;
  readonly name: Maybe<ILocaleString>;
}

export interface IFeatureKioskHomePageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly kioskAuthenticated?: InputMaybe<IHomePageConfigurationFilter>;
  readonly kioskUnauthenticated?: InputMaybe<IHomePageConfigurationFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureKioskHomePageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly kioskAuthenticated?: InputMaybe<IHomePageConfigurationSorting>;
  readonly kioskUnauthenticated?: InputMaybe<IHomePageConfigurationSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureKioskMenu extends IDocument {
  readonly __typename?: 'FeatureKioskMenu';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly cartAddOnSections: Maybe<ReadonlyArray<Maybe<IAddOnSection>>>;
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayPart>>>;
  readonly defaultMenu: Maybe<IMenu>;
  readonly menuHeroImage: Maybe<ILocaleImage>;
  readonly menuHeroText: Maybe<ILocaleString>;
  readonly upsellItemsAtCheckoutRestaurant: Maybe<ReadonlyArray<Maybe<IItem>>>;
}

export interface IFeatureKioskMenuFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly defaultMenu?: InputMaybe<IMenuFilter>;
  readonly menuHeroImage?: InputMaybe<ILocaleImageFilter>;
  readonly menuHeroText?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureKioskMenuSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly menuHeroImage?: InputMaybe<ILocaleImageSorting>;
  readonly menuHeroText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureKioskOffers extends IDocument {
  readonly __typename?: 'FeatureKioskOffers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly defaultBackgroundImage: Maybe<IImage>;
  readonly offersPageMarketingTiles: Maybe<ReadonlyArray<Maybe<IOffersPageMarketingTile>>>;
  readonly sortedOffers: Maybe<ReadonlyArray<Maybe<IOffer>>>;
  /** KIOSK: Control display of static promo tile for offers */
  readonly staticPromo: Maybe<Scalars['Boolean']['output']>;
}

export interface IFeatureKioskOffersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly defaultBackgroundImage?: InputMaybe<IImageFilter>;
  readonly staticPromo?: InputMaybe<IBooleanFilter>;
}

export interface IFeatureKioskOffersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly defaultBackgroundImage?: InputMaybe<IImageSorting>;
  readonly staticPromo?: InputMaybe<SortOrder>;
}

export interface IFeatureKioskScreensaver extends IDocument {
  readonly __typename?: 'FeatureKioskScreensaver';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly delay: Maybe<Scalars['Float']['output']>;
  readonly images: Maybe<ReadonlyArray<Maybe<ILocaleImage>>>;
}

export interface IFeatureKioskScreensaverFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly delay?: InputMaybe<IFloatFilter>;
}

export interface IFeatureKioskScreensaverSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly delay?: InputMaybe<SortOrder>;
}

export interface IFeatureKioskSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly attractLoopImage?: InputMaybe<ILocaleImageSorting>;
  readonly rewardsMainImage?: InputMaybe<ILocaleImageSorting>;
}

export interface IFeatureLayout extends IDocument {
  readonly __typename?: 'FeatureLayout';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly appleWalletImage: Maybe<ILocaleImage>;
  readonly defaultHeroImage: Maybe<ILocaleImage>;
  readonly geolocationModalImage: Maybe<ILocaleImage>;
  readonly giftCardImage: Maybe<ILocaleImage>;
  readonly giftCardTextImage: Maybe<ILocaleImage>;
  readonly googlePayImage: Maybe<ILocaleImage>;
  readonly loadingGraphics: Maybe<ILoadingGraphics>;
  /** @deprecated This has been deprecated. */
  readonly reactNativeDesktopWebSplitViewImage: Maybe<ILocaleImage>;
  readonly recentOrderHeroText: Maybe<ILocaleString>;
}

export interface IFeatureLayoutFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appleWalletImage?: InputMaybe<ILocaleImageFilter>;
  readonly defaultHeroImage?: InputMaybe<ILocaleImageFilter>;
  readonly geolocationModalImage?: InputMaybe<ILocaleImageFilter>;
  readonly giftCardImage?: InputMaybe<ILocaleImageFilter>;
  readonly giftCardTextImage?: InputMaybe<ILocaleImageFilter>;
  readonly googlePayImage?: InputMaybe<ILocaleImageFilter>;
  readonly loadingGraphics?: InputMaybe<ILoadingGraphicsFilter>;
  readonly reactNativeDesktopWebSplitViewImage?: InputMaybe<ILocaleImageFilter>;
  readonly recentOrderHeroText?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureLayoutSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly appleWalletImage?: InputMaybe<ILocaleImageSorting>;
  readonly defaultHeroImage?: InputMaybe<ILocaleImageSorting>;
  readonly geolocationModalImage?: InputMaybe<ILocaleImageSorting>;
  readonly giftCardImage?: InputMaybe<ILocaleImageSorting>;
  readonly giftCardTextImage?: InputMaybe<ILocaleImageSorting>;
  readonly googlePayImage?: InputMaybe<ILocaleImageSorting>;
  readonly loadingGraphics?: InputMaybe<ILoadingGraphicsSorting>;
  readonly reactNativeDesktopWebSplitViewImage?: InputMaybe<ILocaleImageSorting>;
  readonly recentOrderHeroText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureLocationPromo extends IDocument {
  readonly __typename?: 'FeatureLocationPromo';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly bottomText: Maybe<ILocaleString>;
  readonly failLocationHeader: Maybe<ILocaleString>;
  readonly failLocationSubtext: Maybe<ILocaleString>;
  readonly legalText: Maybe<ILocaleString>;
  readonly linkToStaticPage: Maybe<ILinkAction>;
  readonly menuHeroText: Maybe<ILocaleString>;
  readonly promoBackgroundImage: Maybe<IImage>;
}

export interface IFeatureLocationPromoFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly bottomText?: InputMaybe<ILocaleStringFilter>;
  readonly failLocationHeader?: InputMaybe<ILocaleStringFilter>;
  readonly failLocationSubtext?: InputMaybe<ILocaleStringFilter>;
  readonly legalText?: InputMaybe<ILocaleStringFilter>;
  readonly linkToStaticPage?: InputMaybe<ILinkActionFilter>;
  readonly menuHeroText?: InputMaybe<ILocaleStringFilter>;
  readonly promoBackgroundImage?: InputMaybe<IImageFilter>;
}

export interface IFeatureLocationPromoSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly bottomText?: InputMaybe<ILocaleStringSorting>;
  readonly failLocationHeader?: InputMaybe<ILocaleStringSorting>;
  readonly failLocationSubtext?: InputMaybe<ILocaleStringSorting>;
  readonly legalText?: InputMaybe<ILocaleStringSorting>;
  readonly linkToStaticPage?: InputMaybe<ILinkActionSorting>;
  readonly menuHeroText?: InputMaybe<ILocaleStringSorting>;
  readonly promoBackgroundImage?: InputMaybe<IImageSorting>;
}

export interface IFeatureLockers extends IDocument {
  readonly __typename?: 'FeatureLockers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly lockersCodeTitle: Maybe<ILocaleBlockContent>;
  readonly lockersDescription: Maybe<ILocaleBlockContent>;
  readonly lockersHowToUseLockersButtonText: Maybe<ILocaleString>;
  readonly lockersModalImage: Maybe<ILocaleImage>;
  readonly lockersPinInstructions: Maybe<ILocaleBlockContent>;
  readonly lockersTitle: Maybe<ILocaleString>;
  readonly lockersViewOrderButtonText: Maybe<ILocaleString>;
}

export interface IFeatureLockersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly lockersCodeTitle?: InputMaybe<ILocaleBlockContentFilter>;
  readonly lockersDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly lockersHowToUseLockersButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly lockersModalImage?: InputMaybe<ILocaleImageFilter>;
  readonly lockersPinInstructions?: InputMaybe<ILocaleBlockContentFilter>;
  readonly lockersTitle?: InputMaybe<ILocaleStringFilter>;
  readonly lockersViewOrderButtonText?: InputMaybe<ILocaleStringFilter>;
}

export interface IFeatureLockersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly lockersCodeTitle?: InputMaybe<ILocaleBlockContentSorting>;
  readonly lockersDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly lockersHowToUseLockersButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly lockersModalImage?: InputMaybe<ILocaleImageSorting>;
  readonly lockersPinInstructions?: InputMaybe<ILocaleBlockContentSorting>;
  readonly lockersTitle?: InputMaybe<ILocaleStringSorting>;
  readonly lockersViewOrderButtonText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureMenu extends IDocument {
  readonly __typename?: 'FeatureMenu';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly additionalDetails: Maybe<IAdditionalDetails>;
  /** @deprecated This is deprecated */
  readonly authAdaptableMenuCardGroup: Maybe<IAdaptableMenuCardGroup>;
  readonly cartAddOnSections: Maybe<ReadonlyArray<Maybe<IAddOnSection>>>;
  /** @deprecated This is deprecated */
  readonly cartAddOnSectionsCatering: Maybe<ReadonlyArray<Maybe<IAddOnSection>>>;
  /** @deprecated This is deprecated */
  readonly cateringMenu: Maybe<IMenu>;
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayPart>>>;
  readonly defaultMenu: Maybe<IMenu>;
  /** @deprecated This is deprecated */
  readonly donationItemsAtCheckout: Maybe<ReadonlyArray<Maybe<IItem>>>;
  /** @deprecated This is deprecated */
  readonly donationTermAndConditionUrl: Maybe<ILocaleUrl>;
  /** Picker images will show the pictures of each children document within a picker when it is selected if enabled. */
  readonly enableDynamicPickerImages: Maybe<Scalars['Boolean']['output']>;
  /**
   * Google Food Ordering Menu. Only supported for BK.
   * @deprecated This is deprecated
   */
  readonly gfoMenu: Maybe<IMenu>;
  /** @deprecated This is deprecated */
  readonly menuHeroImage: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly menuHeroText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly unauthAdaptableMenuCardGroup: Maybe<IAdaptableMenuCardGroup>;
  /** @deprecated This is deprecated */
  readonly upsellItemsAtCheckoutCatering: Maybe<ReadonlyArray<Maybe<IItem>>>;
  readonly upsellItemsAtCheckoutDelivery: Maybe<ReadonlyArray<Maybe<IItem>>>;
  readonly upsellItemsAtCheckoutRestaurant: Maybe<ReadonlyArray<Maybe<IItem>>>;
}

export interface IFeatureMenuFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly additionalDetails?: InputMaybe<IAdditionalDetailsFilter>;
  readonly authAdaptableMenuCardGroup?: InputMaybe<IAdaptableMenuCardGroupFilter>;
  readonly cateringMenu?: InputMaybe<IMenuFilter>;
  readonly defaultMenu?: InputMaybe<IMenuFilter>;
  readonly donationTermAndConditionUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly enableDynamicPickerImages?: InputMaybe<IBooleanFilter>;
  readonly gfoMenu?: InputMaybe<IMenuFilter>;
  readonly menuHeroImage?: InputMaybe<ILocaleImageFilter>;
  readonly menuHeroText?: InputMaybe<ILocaleStringFilter>;
  readonly unauthAdaptableMenuCardGroup?: InputMaybe<IAdaptableMenuCardGroupFilter>;
}

export interface IFeatureMenuSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly additionalDetails?: InputMaybe<IAdditionalDetailsSorting>;
  readonly donationTermAndConditionUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly enableDynamicPickerImages?: InputMaybe<SortOrder>;
  readonly menuHeroImage?: InputMaybe<ILocaleImageSorting>;
  readonly menuHeroText?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeatureMobileAppConfiguration extends IDocument {
  readonly __typename?: 'FeatureMobileAppConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly appVersion: Maybe<IAppVersion>;
}

export interface IFeatureMobileAppConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly appVersion?: InputMaybe<IAppVersionFilter>;
}

export interface IFeatureMobileAppConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IFeatureNavigation extends IDocument {
  readonly __typename?: 'FeatureNavigation';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly aboutMenuEntries: Maybe<INavigationSection>;
  readonly navigationDesktop: Maybe<INavigationDesktop>;
  readonly navigationMobile: Maybe<INavigationMobile>;
  readonly navigationMobileHeaderButton: Maybe<INavigationMobileHeaderButton>;
}

export interface IFeatureNavigationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionFilter>;
  readonly navigationDesktop?: InputMaybe<INavigationDesktopFilter>;
  readonly navigationMobile?: InputMaybe<INavigationMobileFilter>;
  readonly navigationMobileHeaderButton?: InputMaybe<INavigationMobileHeaderButtonFilter>;
}

export interface IFeatureNavigationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionSorting>;
  readonly navigationDesktop?: InputMaybe<INavigationDesktopSorting>;
  readonly navigationMobile?: InputMaybe<INavigationMobileSorting>;
  readonly navigationMobileHeaderButton?: InputMaybe<INavigationMobileHeaderButtonSorting>;
}

export interface IFeatureNutrition extends IDocument {
  readonly __typename?: 'FeatureNutrition';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly nutritionTables: Maybe<ReadonlyArray<Maybe<INutritionTable>>>;
  readonly roundingRules: Maybe<IRoundingRules>;
}

export interface IFeatureNutritionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly roundingRules?: InputMaybe<IRoundingRulesFilter>;
}

export interface IFeatureNutritionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly roundingRules?: InputMaybe<IRoundingRulesSorting>;
}

export interface IFeatureOffers extends IDocument {
  readonly __typename?: 'FeatureOffers';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly defaultBackgroundImage: Maybe<IImage>;
  /** @deprecated This is deprecated */
  readonly enableMovableInk: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly offersBrowsingPanel: Maybe<IOffersBrowsingPanel>;
  /** @deprecated This is deprecated */
  readonly offersDetailsPanel: Maybe<IOffersDetailsPanel>;
  /** @deprecated This is deprecated */
  readonly offersPageMarketingTiles: Maybe<ReadonlyArray<Maybe<IOffersPageMarketingTile>>>;
  /** @deprecated This is deprecated */
  readonly sortedOffers: Maybe<ReadonlyArray<Maybe<IOffer>>>;
  /**
   * KIOSK: Control display of static promo tile for offers
   * @deprecated This is deprecated
   */
  readonly staticPromo: Maybe<Scalars['Boolean']['output']>;
}

export interface IFeatureOffersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly defaultBackgroundImage?: InputMaybe<IImageFilter>;
  readonly enableMovableInk?: InputMaybe<IBooleanFilter>;
  readonly offersBrowsingPanel?: InputMaybe<IOffersBrowsingPanelFilter>;
  readonly offersDetailsPanel?: InputMaybe<IOffersDetailsPanelFilter>;
  readonly staticPromo?: InputMaybe<IBooleanFilter>;
}

export interface IFeatureOffersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly defaultBackgroundImage?: InputMaybe<IImageSorting>;
  readonly enableMovableInk?: InputMaybe<SortOrder>;
  readonly offersBrowsingPanel?: InputMaybe<IOffersBrowsingPanelSorting>;
  readonly offersDetailsPanel?: InputMaybe<IOffersDetailsPanelSorting>;
  readonly staticPromo?: InputMaybe<SortOrder>;
}

export interface IFeatureOnboarding extends IDocument {
  readonly __typename?: 'FeatureOnboarding';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /**
   * Selection will determine what platform to show onboarding on
   * @deprecated This is deprecated
   */
  readonly channel: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly screens: Maybe<ReadonlyArray<Maybe<IImageWithHeaderAndText>>>;
  /**
   * This is the list of rules where we will trigger this onboarding feature
   * @deprecated This is deprecated
   */
  readonly triggerRule: Maybe<Scalars['String']['output']>;
}

export interface IFeatureOnboardingFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly channel?: InputMaybe<IStringFilter>;
  readonly triggerRule?: InputMaybe<IStringFilter>;
}

export interface IFeatureOnboardingSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly channel?: InputMaybe<SortOrder>;
  readonly triggerRule?: InputMaybe<SortOrder>;
}

export interface IFeaturePayback extends IDocument {
  readonly __typename?: 'FeaturePayback';
  /**
   * Date the document was created
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly marketingAndLegalTextsModal: Maybe<IMarketingAndLegalTextsModalContent>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackBKLogoImage: Maybe<ILocaleImage>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackCreateNewAccountButtonText: Maybe<ILocaleString>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackDescription: Maybe<ILocaleBlockContent>;
  /**
   * Name of Section that allows user to view and opt in/out to legal and marketing texts
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackLegalAndMarketingLinkText: Maybe<Scalars['String']['output']>;
  /**
   * Payback legal link URL text that will be shown under QR code
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackLegalLinkURLText: Maybe<Scalars['String']['output']>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackLinkCardButtonText: Maybe<ILocaleString>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackLoginMethod: Maybe<Scalars['String']['output']>;
  /**
   * The location of the login page for Payback (eg: https://www.tst4.pb-nonprod.de/site-partner/login?thirdPartyAuth=lwp&client_id=264&redirect_uri=...)
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackLoginUrl: Maybe<Scalars['String']['output']>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackLogoImage: Maybe<ILocaleImage>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackLogoSmallImage: Maybe<ILocaleImage>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackMainImage: Maybe<ILocaleImage>;
  /**
   * Payback legal Marketing Communications URL that will be shown under QR code
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackMarketingCommunicationsLinkText: Maybe<Scalars['String']['output']>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackNeedHelpButtonText: Maybe<ILocaleString>;
  /**
   * The ID of the partner that Payback expects
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackPartnerID: Maybe<Scalars['String']['output']>;
  /**
   * The URL that Payback will be redirecting back to after login
   * @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany.
   */
  readonly paybackRedirectUrl: Maybe<Scalars['String']['output']>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackRefreshPointsButtonText: Maybe<ILocaleString>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly paybackTitle: Maybe<ILocaleString>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly unlinkPaybackAccountModal: Maybe<IUnlinkPaybackAccountModalContent>;
}

export interface IFeaturePaybackFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly marketingAndLegalTextsModal?: InputMaybe<IMarketingAndLegalTextsModalContentFilter>;
  readonly paybackBKLogoImage?: InputMaybe<ILocaleImageFilter>;
  readonly paybackCreateNewAccountButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly paybackDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly paybackLegalAndMarketingLinkText?: InputMaybe<IStringFilter>;
  readonly paybackLegalLinkURLText?: InputMaybe<IStringFilter>;
  readonly paybackLinkCardButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly paybackLoginMethod?: InputMaybe<IStringFilter>;
  readonly paybackLoginUrl?: InputMaybe<IStringFilter>;
  readonly paybackLogoImage?: InputMaybe<ILocaleImageFilter>;
  readonly paybackLogoSmallImage?: InputMaybe<ILocaleImageFilter>;
  readonly paybackMainImage?: InputMaybe<ILocaleImageFilter>;
  readonly paybackMarketingCommunicationsLinkText?: InputMaybe<IStringFilter>;
  readonly paybackNeedHelpButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly paybackPartnerID?: InputMaybe<IStringFilter>;
  readonly paybackRedirectUrl?: InputMaybe<IStringFilter>;
  readonly paybackRefreshPointsButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly paybackTitle?: InputMaybe<ILocaleStringFilter>;
  readonly unlinkPaybackAccountModal?: InputMaybe<IUnlinkPaybackAccountModalContentFilter>;
}

export interface IFeaturePaybackSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly marketingAndLegalTextsModal?: InputMaybe<IMarketingAndLegalTextsModalContentSorting>;
  readonly paybackBKLogoImage?: InputMaybe<ILocaleImageSorting>;
  readonly paybackCreateNewAccountButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly paybackDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly paybackLegalAndMarketingLinkText?: InputMaybe<SortOrder>;
  readonly paybackLegalLinkURLText?: InputMaybe<SortOrder>;
  readonly paybackLinkCardButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly paybackLoginMethod?: InputMaybe<SortOrder>;
  readonly paybackLoginUrl?: InputMaybe<SortOrder>;
  readonly paybackLogoImage?: InputMaybe<ILocaleImageSorting>;
  readonly paybackLogoSmallImage?: InputMaybe<ILocaleImageSorting>;
  readonly paybackMainImage?: InputMaybe<ILocaleImageSorting>;
  readonly paybackMarketingCommunicationsLinkText?: InputMaybe<SortOrder>;
  readonly paybackNeedHelpButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly paybackPartnerID?: InputMaybe<SortOrder>;
  readonly paybackRedirectUrl?: InputMaybe<SortOrder>;
  readonly paybackRefreshPointsButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly paybackTitle?: InputMaybe<ILocaleStringSorting>;
  readonly unlinkPaybackAccountModal?: InputMaybe<IUnlinkPaybackAccountModalContentSorting>;
}

export interface IFeatureQrCodeRefillDrinks extends IDocument {
  readonly __typename?: 'FeatureQRCodeRefillDrinks';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly items: Maybe<ReadonlyArray<Maybe<IItem>>>;
  readonly refillDrinksServiceMode: Maybe<IRefillDrinksServiceMode>;
  readonly refillDrinksTimers: Maybe<IRefillDrinksTimers>;
  readonly restaurants: Maybe<ReadonlyArray<Maybe<IRestaurant>>>;
}

export interface IFeatureQrCodeRefillDrinksFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly refillDrinksServiceMode?: InputMaybe<IRefillDrinksServiceModeFilter>;
  readonly refillDrinksTimers?: InputMaybe<IRefillDrinksTimersFilter>;
}

export interface IFeatureQrCodeRefillDrinksSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly refillDrinksServiceMode?: InputMaybe<IRefillDrinksServiceModeSorting>;
  readonly refillDrinksTimers?: InputMaybe<IRefillDrinksTimersSorting>;
}

export interface IFeatureQuiz extends IDocument {
  readonly __typename?: 'FeatureQuiz';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly description: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly endDate: Maybe<Scalars['Date']['output']>;
  /** @deprecated This is deprecated */
  readonly introPageBackground: Maybe<IImage>;
  /** @deprecated This is deprecated */
  readonly name: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly question: Maybe<ReadonlyArray<Maybe<IQuestionDetails>>>;
  /** @deprecated This is deprecated */
  readonly questionPageBackground: Maybe<IImage>;
  /** @deprecated This is deprecated */
  readonly questions: Maybe<ReadonlyArray<Maybe<IQuestionDetails>>>;
  /** @deprecated This is deprecated */
  readonly resultPageBackground: Maybe<IImage>;
  /** @deprecated This is deprecated */
  readonly results: Maybe<ReadonlyArray<Maybe<IResultDetails>>>;
  /** @deprecated This is deprecated */
  readonly startDate: Maybe<Scalars['Date']['output']>;
}

export interface IFeatureQuizFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly endDate?: InputMaybe<IDateFilter>;
  readonly introPageBackground?: InputMaybe<IImageFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly questionPageBackground?: InputMaybe<IImageFilter>;
  readonly resultPageBackground?: InputMaybe<IImageFilter>;
  readonly startDate?: InputMaybe<IDateFilter>;
}

export interface IFeatureQuizSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly endDate?: InputMaybe<SortOrder>;
  readonly introPageBackground?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly questionPageBackground?: InputMaybe<IImageSorting>;
  readonly resultPageBackground?: InputMaybe<IImageSorting>;
  readonly startDate?: InputMaybe<SortOrder>;
}

export interface IFeatureSeo extends IDocument {
  readonly __typename?: 'FeatureSeo';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly metaTags: Maybe<IMetaTags>;
  readonly ogTags: Maybe<IOgTags>;
  readonly sitemapExcludedUrls: Maybe<Scalars['String']['output']>;
  readonly sitemapIncludedUrls: Maybe<Scalars['String']['output']>;
}

export interface IFeatureSeoFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly metaTags?: InputMaybe<IMetaTagsFilter>;
  readonly ogTags?: InputMaybe<IOgTagsFilter>;
  readonly sitemapExcludedUrls?: InputMaybe<IStringFilter>;
  readonly sitemapIncludedUrls?: InputMaybe<IStringFilter>;
}

export interface IFeatureSeoSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly metaTags?: InputMaybe<IMetaTagsSorting>;
  readonly ogTags?: InputMaybe<IOgTagsSorting>;
  readonly sitemapExcludedUrls?: InputMaybe<SortOrder>;
  readonly sitemapIncludedUrls?: InputMaybe<SortOrder>;
}

export interface IFeatureStaticMenu extends IDocument {
  readonly __typename?: 'FeatureStaticMenu';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly itemUnavailableModal: Maybe<IItemUnavailableModal>;
}

export interface IFeatureStaticMenuFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly itemUnavailableModal?: InputMaybe<IItemUnavailableModalFilter>;
}

export interface IFeatureStaticMenuSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly itemUnavailableModal?: InputMaybe<IItemUnavailableModalSorting>;
}

export interface IFeaturedReward {
  readonly __typename?: 'FeaturedReward';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly category: Maybe<IRewardCategory>;
  readonly reward: Maybe<IReward>;
}

export interface IFeaturedRewardFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly category?: InputMaybe<IRewardCategoryFilter>;
  readonly reward?: InputMaybe<IRewardFilter>;
}

export interface IFeaturedRewardSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IFeaturedRewardsCarouselWidget {
  readonly __typename?: 'FeaturedRewardsCarouselWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays featured rewards carousel */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly label: Maybe<ILocaleString>;
  readonly rewards: Maybe<ReadonlyArray<Maybe<IReward>>>;
}

export interface IFeaturedRewardsCarouselWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly label?: InputMaybe<ILocaleStringFilter>;
}

export type IFeaturedRewardsCarouselWidgetOrLoyaltyDoubleButtonWidgetOrLoyaltyGreetingWidgetOrLoyaltyHistoryWidgetOrLoyaltyLinkWidgetOrLoyaltyLogoWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyNotificationsWidgetOrLoyaltyPointsAndRewardsWidgetOrLoyaltyPointsMeterWidgetOrLoyaltyQuestsTileWidgetOrLoyaltyQuestsWidgetOrLoyaltyTiersWidgetOrRewardsListWidget =

    | IFeaturedRewardsCarouselWidget
    | ILoyaltyDoubleButtonWidget
    | ILoyaltyGreetingWidget
    | ILoyaltyHistoryWidget
    | ILoyaltyLinkWidget
    | ILoyaltyLogoWidget
    | ILoyaltyMarketingTileGroupWidget
    | ILoyaltyNotificationsWidget
    | ILoyaltyPointsAndRewardsWidget
    | ILoyaltyPointsMeterWidget
    | ILoyaltyQuestsTileWidget
    | ILoyaltyQuestsWidget
    | ILoyaltyTiersWidget
    | IRewardsListWidget;

export interface IFeaturedRewardsCarouselWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly label?: InputMaybe<ILocaleStringSorting>;
}

export interface IFeesAndTaxesServiceModeConfiguration {
  readonly __typename?: 'FeesAndTaxesServiceModeConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** ex: for € 1,00, type 100 */
  readonly value: Maybe<Scalars['Float']['output']>;
}

export interface IFeesAndTaxesServiceModeConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isEnabled?: InputMaybe<IBooleanFilter>;
  readonly value?: InputMaybe<IFloatFilter>;
}

export interface IFeesAndTaxesServiceModeConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isEnabled?: InputMaybe<SortOrder>;
  readonly value?: InputMaybe<SortOrder>;
}

export interface IFile {
  readonly __typename?: 'File';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly asset: Maybe<ISanityFileAsset>;
}

export interface IFileFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly asset?: InputMaybe<ISanityFileAssetFilter>;
}

export interface IFileSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IFilter {
  readonly __typename?: 'Filter';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly conditions: Maybe<
    ReadonlyArray<
      Maybe<IConditionAllergenOrConditionItemOneOfOrConditionNutritionOrConditionParentCategory>
    >
  >;
  readonly description: Maybe<ILocaleString>;
}

export interface IFilterFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
}

export interface IFilterGroup {
  readonly __typename?: 'FilterGroup';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly filters: Maybe<ReadonlyArray<Maybe<IFilter>>>;
}

export interface IFilterGroupFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
}

export interface IFilterGroupSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
}

export interface IFilterSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
}

export interface IFirstDataConfig {
  readonly __typename?: 'FirstDataConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly corporateStoreId: Maybe<Scalars['String']['output']>;
}

export interface IFirstDataConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly corporateStoreId?: InputMaybe<IStringFilter>;
}

export interface IFirstDataConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly corporateStoreId?: InputMaybe<SortOrder>;
}

export interface IFirstOrderOnly {
  readonly __typename?: 'FirstOrderOnly';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly firstUserOrder: Maybe<Scalars['Boolean']['output']>;
}

export interface IFirstOrderOnlyFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly firstUserOrder?: InputMaybe<IBooleanFilter>;
}

export interface IFirstOrderOnlySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly firstUserOrder?: InputMaybe<SortOrder>;
}

export interface IFloatFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than the given input. */
  readonly gt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  readonly gte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  readonly lt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  readonly lte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['Float']['input']>;
}

export interface IFooter extends IDocument {
  readonly __typename?: 'Footer';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly accessibilitystatement: Maybe<ILink>;
  readonly careers: Maybe<ILink>;
  readonly company: Maybe<ILink>;
  readonly contactus: Maybe<ILink>;
  readonly foundation: Maybe<ILink>;
  readonly franchiseopportunities: Maybe<ILink>;
  readonly investorrelations: Maybe<ILink>;
  readonly legal: Maybe<ILink>;
  readonly ourstory: Maybe<ILink>;
  readonly privacypolicy: Maybe<ILink>;
  readonly sitemap: Maybe<ILink>;
  readonly support: Maybe<ILink>;
  readonly termsofuse: Maybe<ILink>;
}

export interface IFooterFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly accessibilitystatement?: InputMaybe<ILinkFilter>;
  readonly careers?: InputMaybe<ILinkFilter>;
  readonly company?: InputMaybe<ILinkFilter>;
  readonly contactus?: InputMaybe<ILinkFilter>;
  readonly foundation?: InputMaybe<ILinkFilter>;
  readonly franchiseopportunities?: InputMaybe<ILinkFilter>;
  readonly investorrelations?: InputMaybe<ILinkFilter>;
  readonly legal?: InputMaybe<ILinkFilter>;
  readonly ourstory?: InputMaybe<ILinkFilter>;
  readonly privacypolicy?: InputMaybe<ILinkFilter>;
  readonly sitemap?: InputMaybe<ILinkFilter>;
  readonly support?: InputMaybe<ILinkFilter>;
  readonly termsofuse?: InputMaybe<ILinkFilter>;
}

export interface IFooterSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly accessibilitystatement?: InputMaybe<ILinkSorting>;
  readonly careers?: InputMaybe<ILinkSorting>;
  readonly company?: InputMaybe<ILinkSorting>;
  readonly contactus?: InputMaybe<ILinkSorting>;
  readonly foundation?: InputMaybe<ILinkSorting>;
  readonly franchiseopportunities?: InputMaybe<ILinkSorting>;
  readonly investorrelations?: InputMaybe<ILinkSorting>;
  readonly legal?: InputMaybe<ILinkSorting>;
  readonly ourstory?: InputMaybe<ILinkSorting>;
  readonly privacypolicy?: InputMaybe<ILinkSorting>;
  readonly sitemap?: InputMaybe<ILinkSorting>;
  readonly support?: InputMaybe<ILinkSorting>;
  readonly termsofuse?: InputMaybe<ILinkSorting>;
}

export interface IForterConfig {
  readonly __typename?: 'ForterConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly siteId: Maybe<Scalars['String']['output']>;
}

export interface IForterConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly siteId?: InputMaybe<IStringFilter>;
}

export interface IForterConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly siteId?: InputMaybe<SortOrder>;
}

export interface IFrontEndConfiguration extends IDocument {
  readonly __typename?: 'FrontEndConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly addressFormat: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly advancedBooking: Maybe<IAdvancedBookingConfig>;
  readonly adyen: Maybe<IAdyenConfig>;
  readonly androidDeepLink: Maybe<Scalars['String']['output']>;
  readonly apiKeys: Maybe<IApiKeysConfig>;
  readonly appStoreDeeplink: Maybe<Scalars['String']['output']>;
  readonly apple: Maybe<IAppleConfig>;
  readonly aws: Maybe<IAwsConfig>;
  readonly bringg: Maybe<IBringgConfig>;
  readonly checkoutDotCom: Maybe<ICheckoutDotComConfig>;
  readonly cookieBanners: Maybe<ICookieBannerConfig>;
  readonly dataDog: Maybe<IDataDogConfig>;
  readonly dateFormat: Maybe<Scalars['String']['output']>;
  readonly facebook: Maybe<IFacebookConfig>;
  readonly firstData: Maybe<IFirstDataConfig>;
  readonly forter: Maybe<IForterConfig>;
  readonly google: Maybe<IGoogleConfig>;
  readonly googleAdManager: Maybe<IGoogleAdManagerConfig>;
  readonly googleTagManager: Maybe<IGoogleTagManagerConfig>;
  readonly iDEAL: Maybe<IIDealConfig>;
  readonly iosDeepLink: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly kountMerchantId: Maybe<Scalars['String']['output']>;
  /** How many items are allowed to be in the cart for any given order. Default will be 9 items. */
  readonly maxCartQuantity: Maybe<Scalars['Float']['output']>;
  readonly orbital: Maybe<IOrbitalConfig>;
  readonly pypestream: Maybe<IPypestreamConfig>;
  readonly redirect: Maybe<ReadonlyArray<Maybe<IInternalRedirectionConfig>>>;
  readonly restaurants: Maybe<IRestaurantConfig>;
  readonly timeFormat: Maybe<Scalars['String']['output']>;
  readonly tokenEx: Maybe<ITokenExConfig>;
  readonly urls: Maybe<IUrlsConfig>;
  readonly vrPayment: Maybe<IVrPaymentConfig>;
  readonly worldpay: Maybe<IWorldpayConfig>;
}

export interface IFrontEndConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly addressFormat?: InputMaybe<IStringFilter>;
  readonly advancedBooking?: InputMaybe<IAdvancedBookingConfigFilter>;
  readonly adyen?: InputMaybe<IAdyenConfigFilter>;
  readonly androidDeepLink?: InputMaybe<IStringFilter>;
  readonly apiKeys?: InputMaybe<IApiKeysConfigFilter>;
  readonly appStoreDeeplink?: InputMaybe<IStringFilter>;
  readonly apple?: InputMaybe<IAppleConfigFilter>;
  readonly aws?: InputMaybe<IAwsConfigFilter>;
  readonly bringg?: InputMaybe<IBringgConfigFilter>;
  readonly checkoutDotCom?: InputMaybe<ICheckoutDotComConfigFilter>;
  readonly cookieBanners?: InputMaybe<ICookieBannerConfigFilter>;
  readonly dataDog?: InputMaybe<IDataDogConfigFilter>;
  readonly dateFormat?: InputMaybe<IStringFilter>;
  readonly facebook?: InputMaybe<IFacebookConfigFilter>;
  readonly firstData?: InputMaybe<IFirstDataConfigFilter>;
  readonly forter?: InputMaybe<IForterConfigFilter>;
  readonly google?: InputMaybe<IGoogleConfigFilter>;
  readonly googleAdManager?: InputMaybe<IGoogleAdManagerConfigFilter>;
  readonly googleTagManager?: InputMaybe<IGoogleTagManagerConfigFilter>;
  readonly iDEAL?: InputMaybe<IIDealConfigFilter>;
  readonly iosDeepLink?: InputMaybe<IStringFilter>;
  readonly kountMerchantId?: InputMaybe<IStringFilter>;
  readonly maxCartQuantity?: InputMaybe<IFloatFilter>;
  readonly orbital?: InputMaybe<IOrbitalConfigFilter>;
  readonly pypestream?: InputMaybe<IPypestreamConfigFilter>;
  readonly restaurants?: InputMaybe<IRestaurantConfigFilter>;
  readonly timeFormat?: InputMaybe<IStringFilter>;
  readonly tokenEx?: InputMaybe<ITokenExConfigFilter>;
  readonly urls?: InputMaybe<IUrlsConfigFilter>;
  readonly vrPayment?: InputMaybe<IVrPaymentConfigFilter>;
  readonly worldpay?: InputMaybe<IWorldpayConfigFilter>;
}

export interface IFrontEndConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly addressFormat?: InputMaybe<SortOrder>;
  readonly advancedBooking?: InputMaybe<IAdvancedBookingConfigSorting>;
  readonly adyen?: InputMaybe<IAdyenConfigSorting>;
  readonly androidDeepLink?: InputMaybe<SortOrder>;
  readonly apiKeys?: InputMaybe<IApiKeysConfigSorting>;
  readonly appStoreDeeplink?: InputMaybe<SortOrder>;
  readonly apple?: InputMaybe<IAppleConfigSorting>;
  readonly aws?: InputMaybe<IAwsConfigSorting>;
  readonly bringg?: InputMaybe<IBringgConfigSorting>;
  readonly checkoutDotCom?: InputMaybe<ICheckoutDotComConfigSorting>;
  readonly cookieBanners?: InputMaybe<ICookieBannerConfigSorting>;
  readonly dataDog?: InputMaybe<IDataDogConfigSorting>;
  readonly dateFormat?: InputMaybe<SortOrder>;
  readonly facebook?: InputMaybe<IFacebookConfigSorting>;
  readonly firstData?: InputMaybe<IFirstDataConfigSorting>;
  readonly forter?: InputMaybe<IForterConfigSorting>;
  readonly google?: InputMaybe<IGoogleConfigSorting>;
  readonly googleAdManager?: InputMaybe<IGoogleAdManagerConfigSorting>;
  readonly googleTagManager?: InputMaybe<IGoogleTagManagerConfigSorting>;
  readonly iDEAL?: InputMaybe<IIDealConfigSorting>;
  readonly iosDeepLink?: InputMaybe<SortOrder>;
  readonly kountMerchantId?: InputMaybe<SortOrder>;
  readonly maxCartQuantity?: InputMaybe<SortOrder>;
  readonly orbital?: InputMaybe<IOrbitalConfigSorting>;
  readonly pypestream?: InputMaybe<IPypestreamConfigSorting>;
  readonly restaurants?: InputMaybe<IRestaurantConfigSorting>;
  readonly timeFormat?: InputMaybe<SortOrder>;
  readonly tokenEx?: InputMaybe<ITokenExConfigSorting>;
  readonly urls?: InputMaybe<IUrlsConfigSorting>;
  readonly vrPayment?: InputMaybe<IVrPaymentConfigSorting>;
  readonly worldpay?: InputMaybe<IWorldpayConfigSorting>;
}

export interface IGeopoint {
  readonly __typename?: 'Geopoint';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly alt: Maybe<Scalars['Float']['output']>;
  readonly lat: Maybe<Scalars['Float']['output']>;
  readonly lng: Maybe<Scalars['Float']['output']>;
}

export interface IGeopointFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly alt?: InputMaybe<IFloatFilter>;
  readonly lat?: InputMaybe<IFloatFilter>;
  readonly lng?: InputMaybe<IFloatFilter>;
}

export interface IGeopointSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly alt?: InputMaybe<SortOrder>;
  readonly lat?: InputMaybe<SortOrder>;
  readonly lng?: InputMaybe<SortOrder>;
}

export interface IGlobalOfferCooldownContent {
  readonly __typename?: 'GlobalOfferCooldownContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly actionButton: Maybe<IActionButton>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly terms: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
}

export interface IGlobalOfferCooldownContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly actionButton?: InputMaybe<IActionButtonFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly terms?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IGlobalOfferCooldownContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly actionButton?: InputMaybe<IActionButtonSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly terms?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IGoogleAdManagerConfig {
  readonly __typename?: 'GoogleAdManagerConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly campaignId: Maybe<Scalars['String']['output']>;
}

export interface IGoogleAdManagerConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly campaignId?: InputMaybe<IStringFilter>;
}

export interface IGoogleAdManagerConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly campaignId?: InputMaybe<SortOrder>;
}

export interface IGoogleConfig {
  readonly __typename?: 'GoogleConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly marketPaymentsGatewayMerchantId: Maybe<Scalars['String']['output']>;
  readonly marketPrepaidPaymentsGatewayMerchantId: Maybe<Scalars['String']['output']>;
  /**
   * This field should only be used when migrating from one PSP to another
   * @deprecated This has been deprecated.
   */
  readonly migrationPaymentsGateway: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly migrationPaymentsGatewayMerchantId: Maybe<IPaymentsGatewayMerchantIdConfig>;
  /**
   * This field should only be used when migrating from one PSP to another
   * @deprecated This has been deprecated.
   */
  readonly migrationPrepaidPaymentsGateway: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly migrationPrepaidPaymentsGatewayMerchantId: Maybe<IPrepaidPaymentsGatewayMerchantIdConfig>;
  readonly paymentsEnvironment: Maybe<Scalars['String']['output']>;
  readonly paymentsGateway: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly paymentsGatewayMerchantId: Maybe<IPaymentsGatewayMerchantIdConfig>;
  readonly paymentsNetworks: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly prepaidPaymentsGateway: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly prepaidPaymentsGatewayMerchantId: Maybe<IPrepaidPaymentsGatewayMerchantIdConfig>;
  readonly socialLogin: Maybe<ISocialLoginGoogleConfig>;
}

export interface IGoogleConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly marketPaymentsGatewayMerchantId?: InputMaybe<IStringFilter>;
  readonly marketPrepaidPaymentsGatewayMerchantId?: InputMaybe<IStringFilter>;
  readonly migrationPaymentsGateway?: InputMaybe<IStringFilter>;
  readonly migrationPaymentsGatewayMerchantId?: InputMaybe<IPaymentsGatewayMerchantIdConfigFilter>;
  readonly migrationPrepaidPaymentsGateway?: InputMaybe<IStringFilter>;
  readonly migrationPrepaidPaymentsGatewayMerchantId?: InputMaybe<IPrepaidPaymentsGatewayMerchantIdConfigFilter>;
  readonly paymentsEnvironment?: InputMaybe<IStringFilter>;
  readonly paymentsGateway?: InputMaybe<IStringFilter>;
  readonly paymentsGatewayMerchantId?: InputMaybe<IPaymentsGatewayMerchantIdConfigFilter>;
  readonly prepaidPaymentsGateway?: InputMaybe<IStringFilter>;
  readonly prepaidPaymentsGatewayMerchantId?: InputMaybe<IPrepaidPaymentsGatewayMerchantIdConfigFilter>;
  readonly socialLogin?: InputMaybe<ISocialLoginGoogleConfigFilter>;
}

export interface IGoogleConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly marketPaymentsGatewayMerchantId?: InputMaybe<SortOrder>;
  readonly marketPrepaidPaymentsGatewayMerchantId?: InputMaybe<SortOrder>;
  readonly migrationPaymentsGateway?: InputMaybe<SortOrder>;
  readonly migrationPaymentsGatewayMerchantId?: InputMaybe<IPaymentsGatewayMerchantIdConfigSorting>;
  readonly migrationPrepaidPaymentsGateway?: InputMaybe<SortOrder>;
  readonly migrationPrepaidPaymentsGatewayMerchantId?: InputMaybe<IPrepaidPaymentsGatewayMerchantIdConfigSorting>;
  readonly paymentsEnvironment?: InputMaybe<SortOrder>;
  readonly paymentsGateway?: InputMaybe<SortOrder>;
  readonly paymentsGatewayMerchantId?: InputMaybe<IPaymentsGatewayMerchantIdConfigSorting>;
  readonly prepaidPaymentsGateway?: InputMaybe<SortOrder>;
  readonly prepaidPaymentsGatewayMerchantId?: InputMaybe<IPrepaidPaymentsGatewayMerchantIdConfigSorting>;
  readonly socialLogin?: InputMaybe<ISocialLoginGoogleConfigSorting>;
}

export interface IGoogleTagManagerConfig {
  readonly __typename?: 'GoogleTagManagerConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly containerId: Maybe<Scalars['String']['output']>;
}

export interface IGoogleTagManagerConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly containerId?: InputMaybe<IStringFilter>;
}

export interface IGoogleTagManagerConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly containerId?: InputMaybe<SortOrder>;
}

export interface IHeaderWidget {
  readonly __typename?: 'HeaderWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly headerImage: Maybe<ILocaleImage>;
  readonly headingContent: Maybe<ILocaleString>;
  readonly taglineContent: Maybe<ILocaleString>;
}

export interface IHeaderWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly headerImage?: InputMaybe<ILocaleImageFilter>;
  readonly headingContent?: InputMaybe<ILocaleStringFilter>;
  readonly taglineContent?: InputMaybe<ILocaleStringFilter>;
}

export interface IHeaderWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly headerImage?: InputMaybe<ILocaleImageSorting>;
  readonly headingContent?: InputMaybe<ILocaleStringSorting>;
  readonly taglineContent?: InputMaybe<ILocaleStringSorting>;
}

export interface IHero extends IDocument {
  readonly __typename?: 'Hero';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly altImageText: Maybe<ILocaleString>;
  /** Turn this on to add and choose a background color for the homepage Hero */
  readonly backgroundColor: Maybe<Scalars['Boolean']['output']>;
  /** Turn this on to split the background color for the homepage Hero */
  readonly backgroundSplit: Maybe<Scalars['Boolean']['output']>;
  /** Select a background color for the homepage Hero */
  readonly colorOption: Maybe<Scalars['String']['output']>;
  readonly complementaryCta: Maybe<ILinkAction>;
  readonly hasTerms: Maybe<Scalars['Boolean']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly primaryCta: Maybe<ILinkAction>;
  /**
   * By checking this, it displays the Hero with reversed colors/darker background colors
   * @deprecated This has been deprecated.
   */
  readonly reversed: Maybe<Scalars['Boolean']['output']>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  /** By checking this, it show the complementary CTA */
  readonly showComplementaryCTA: Maybe<Scalars['Boolean']['output']>;
  readonly subheader: Maybe<ILocaleString>;
  readonly termsButton: Maybe<ILinkAction>;
  readonly termsText: Maybe<ILocaleBlockContent>;
}

export interface IHeroFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly altImageText?: InputMaybe<ILocaleStringFilter>;
  readonly backgroundColor?: InputMaybe<IBooleanFilter>;
  readonly backgroundSplit?: InputMaybe<IBooleanFilter>;
  readonly colorOption?: InputMaybe<IStringFilter>;
  readonly complementaryCta?: InputMaybe<ILinkActionFilter>;
  readonly hasTerms?: InputMaybe<IBooleanFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly primaryCta?: InputMaybe<ILinkActionFilter>;
  readonly reversed?: InputMaybe<IBooleanFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly showComplementaryCTA?: InputMaybe<IBooleanFilter>;
  readonly subheader?: InputMaybe<ILocaleStringFilter>;
  readonly termsButton?: InputMaybe<ILinkActionFilter>;
  readonly termsText?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IHeroSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly altImageText?: InputMaybe<ILocaleStringSorting>;
  readonly backgroundColor?: InputMaybe<SortOrder>;
  readonly backgroundSplit?: InputMaybe<SortOrder>;
  readonly colorOption?: InputMaybe<SortOrder>;
  readonly complementaryCta?: InputMaybe<ILinkActionSorting>;
  readonly hasTerms?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly primaryCta?: InputMaybe<ILinkActionSorting>;
  readonly reversed?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly showComplementaryCTA?: InputMaybe<SortOrder>;
  readonly subheader?: InputMaybe<ILocaleStringSorting>;
  readonly termsButton?: InputMaybe<ILinkActionSorting>;
  readonly termsText?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IHighlightedLinkWidget {
  readonly __typename?: 'HighlightedLinkWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<ILocaleUrl>;
  readonly text: Maybe<ILocaleString>;
}

export interface IHighlightedLinkWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface IHighlightedLinkWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface IHighlightedVerticalLinkListWidget {
  readonly __typename?: 'HighlightedVerticalLinkListWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly highlightedLinks: Maybe<ReadonlyArray<Maybe<IHighlightedLinkWidget>>>;
  readonly subheader: Maybe<ILocaleString>;
}

export interface IHighlightedVerticalLinkListWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly subheader?: InputMaybe<ILocaleStringFilter>;
}

export interface IHighlightedVerticalLinkListWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly subheader?: InputMaybe<ILocaleStringSorting>;
}

export interface IHolidayHours {
  readonly __typename?: 'HolidayHours';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly close: Maybe<Scalars['String']['output']>;
  readonly date: Maybe<Scalars['String']['output']>;
  readonly holiday: Maybe<Scalars['String']['output']>;
  readonly open: Maybe<Scalars['String']['output']>;
}

export interface IHolidayHoursFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly close?: InputMaybe<IStringFilter>;
  readonly date?: InputMaybe<IStringFilter>;
  readonly holiday?: InputMaybe<IStringFilter>;
  readonly open?: InputMaybe<IStringFilter>;
}

export interface IHolidayHoursSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly close?: InputMaybe<SortOrder>;
  readonly date?: InputMaybe<SortOrder>;
  readonly holiday?: InputMaybe<SortOrder>;
  readonly open?: InputMaybe<SortOrder>;
}

export interface IHomeGraphic {
  readonly __typename?: 'HomeGraphic';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly asset: Maybe<ISanityFileAsset>;
  readonly description: Maybe<Scalars['String']['output']>;
}

export interface IHomeGraphicFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly asset?: InputMaybe<ISanityFileAssetFilter>;
  readonly description?: InputMaybe<IStringFilter>;
}

export interface IHomeGraphicSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<SortOrder>;
}

export interface IHomePageConfiguration extends IDocument {
  readonly __typename?: 'HomePageConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly components: Maybe<
    ReadonlyArray<
      Maybe<IAlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrHeroOrLockedOffersOrMainHeroOrMarketingCardGroupOrMarketingTileCarouselOrMarketingTileGroupOrOfferCarouselSectionOrOffersSectionOrOurServicesOrQuestsSectionOrRecentItemsOrRewardsSectionOrRollUpTheRimSectionOrScanForRewardsOrSimpleHomePageOrTrending>
    >
  >;
}

export interface IHomePageConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IHomePageConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IHorizontalLinkListWidget {
  readonly __typename?: 'HorizontalLinkListWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly linkWidgets: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
}

export interface IHorizontalLinkListWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IHorizontalLinkListWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IHoursOfOperation {
  readonly __typename?: 'HoursOfOperation';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly friAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly friClose: Maybe<Scalars['String']['output']>;
  readonly friOpen: Maybe<Scalars['String']['output']>;
  readonly monAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly monClose: Maybe<Scalars['String']['output']>;
  readonly monOpen: Maybe<Scalars['String']['output']>;
  readonly satAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly satClose: Maybe<Scalars['String']['output']>;
  readonly satOpen: Maybe<Scalars['String']['output']>;
  readonly sunAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly sunClose: Maybe<Scalars['String']['output']>;
  readonly sunOpen: Maybe<Scalars['String']['output']>;
  readonly thrAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly thrClose: Maybe<Scalars['String']['output']>;
  readonly thrOpen: Maybe<Scalars['String']['output']>;
  readonly tueAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly tueClose: Maybe<Scalars['String']['output']>;
  readonly tueOpen: Maybe<Scalars['String']['output']>;
  readonly wedAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  readonly wedClose: Maybe<Scalars['String']['output']>;
  readonly wedOpen: Maybe<Scalars['String']['output']>;
}

export interface IHoursOfOperationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly friAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly friClose?: InputMaybe<IStringFilter>;
  readonly friOpen?: InputMaybe<IStringFilter>;
  readonly monAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly monClose?: InputMaybe<IStringFilter>;
  readonly monOpen?: InputMaybe<IStringFilter>;
  readonly satAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly satClose?: InputMaybe<IStringFilter>;
  readonly satOpen?: InputMaybe<IStringFilter>;
  readonly sunAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly sunClose?: InputMaybe<IStringFilter>;
  readonly sunOpen?: InputMaybe<IStringFilter>;
  readonly thrAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly thrClose?: InputMaybe<IStringFilter>;
  readonly thrOpen?: InputMaybe<IStringFilter>;
  readonly tueAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly tueClose?: InputMaybe<IStringFilter>;
  readonly tueOpen?: InputMaybe<IStringFilter>;
  readonly wedAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotFilter>;
  readonly wedClose?: InputMaybe<IStringFilter>;
  readonly wedOpen?: InputMaybe<IStringFilter>;
}

export interface IHoursOfOperationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly friAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly friClose?: InputMaybe<SortOrder>;
  readonly friOpen?: InputMaybe<SortOrder>;
  readonly monAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly monClose?: InputMaybe<SortOrder>;
  readonly monOpen?: InputMaybe<SortOrder>;
  readonly satAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly satClose?: InputMaybe<SortOrder>;
  readonly satOpen?: InputMaybe<SortOrder>;
  readonly sunAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly sunClose?: InputMaybe<SortOrder>;
  readonly sunOpen?: InputMaybe<SortOrder>;
  readonly thrAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly thrClose?: InputMaybe<SortOrder>;
  readonly thrOpen?: InputMaybe<SortOrder>;
  readonly tueAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly tueClose?: InputMaybe<SortOrder>;
  readonly tueOpen?: InputMaybe<SortOrder>;
  readonly wedAdditionalTimeSlot?: InputMaybe<IAdditionalTimeSlotSorting>;
  readonly wedClose?: InputMaybe<SortOrder>;
  readonly wedOpen?: InputMaybe<SortOrder>;
}

export interface IHslaColor {
  readonly __typename?: 'HslaColor';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly a: Maybe<Scalars['Float']['output']>;
  readonly h: Maybe<Scalars['Float']['output']>;
  readonly l: Maybe<Scalars['Float']['output']>;
  readonly s: Maybe<Scalars['Float']['output']>;
}

export interface IHslaColorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly a?: InputMaybe<IFloatFilter>;
  readonly h?: InputMaybe<IFloatFilter>;
  readonly l?: InputMaybe<IFloatFilter>;
  readonly s?: InputMaybe<IFloatFilter>;
}

export interface IHslaColorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly a?: InputMaybe<SortOrder>;
  readonly h?: InputMaybe<SortOrder>;
  readonly l?: InputMaybe<SortOrder>;
  readonly s?: InputMaybe<SortOrder>;
}

export interface IHsvaColor {
  readonly __typename?: 'HsvaColor';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly a: Maybe<Scalars['Float']['output']>;
  readonly h: Maybe<Scalars['Float']['output']>;
  readonly s: Maybe<Scalars['Float']['output']>;
  readonly v: Maybe<Scalars['Float']['output']>;
}

export interface IHsvaColorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly a?: InputMaybe<IFloatFilter>;
  readonly h?: InputMaybe<IFloatFilter>;
  readonly s?: InputMaybe<IFloatFilter>;
  readonly v?: InputMaybe<IFloatFilter>;
}

export interface IHsvaColorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly a?: InputMaybe<SortOrder>;
  readonly h?: InputMaybe<SortOrder>;
  readonly s?: InputMaybe<SortOrder>;
  readonly v?: InputMaybe<SortOrder>;
}

export interface IHtml {
  readonly __typename?: 'Html';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly code: Maybe<Scalars['String']['output']>;
  readonly filename: Maybe<Scalars['String']['output']>;
  readonly highlightedLines: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>;
  readonly language: Maybe<Scalars['String']['output']>;
}

export interface IHtmlFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly code?: InputMaybe<IStringFilter>;
  readonly filename?: InputMaybe<IStringFilter>;
  readonly language?: InputMaybe<IStringFilter>;
}

export interface IHtmlSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly code?: InputMaybe<SortOrder>;
  readonly filename?: InputMaybe<SortOrder>;
  readonly language?: InputMaybe<SortOrder>;
}

export interface IIdFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['ID']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  /** Checks if the value matches the given word/words. */
  readonly matches?: InputMaybe<Scalars['ID']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['ID']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
}

export interface IIDealBanks {
  readonly __typename?: 'IDealBanks';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface IIDealBanksFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly id?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
}

export interface IIDealBanksSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly id?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IIDealConfig {
  readonly __typename?: 'IDealConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly iDealBanks: Maybe<ReadonlyArray<Maybe<IIDealBanks>>>;
}

export interface IIDealConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IIDealConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IImage {
  readonly __typename?: 'Image';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly asset: Maybe<ISanityImageAsset>;
  readonly crop: Maybe<ISanityImageCrop>;
  readonly hotspot: Maybe<ISanityImageHotspot>;
}

export interface IImageBackground {
  readonly __typename?: 'ImageBackground';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<IImage>;
}

export interface IImageBackgroundFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
}

export interface IImageBackgroundSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
}

export interface IImageFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly asset?: InputMaybe<ISanityImageAssetFilter>;
  readonly crop?: InputMaybe<ISanityImageCropFilter>;
  readonly hotspot?: InputMaybe<ISanityImageHotspotFilter>;
}

export interface IImageSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly crop?: InputMaybe<ISanityImageCropSorting>;
  readonly hotspot?: InputMaybe<ISanityImageHotspotSorting>;
}

export interface IImageWidget {
  readonly __typename?: 'ImageWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly attributionLink: Maybe<Scalars['String']['output']>;
  readonly caption: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
}

export interface IImageWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly attributionLink?: InputMaybe<IStringFilter>;
  readonly caption?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
}

export interface IImageWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly attributionLink?: InputMaybe<SortOrder>;
  readonly caption?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
}

export interface IImageWithHeaderAndText extends IDocument {
  readonly __typename?: 'ImageWithHeaderAndText';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly alternativeButtonAction: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly buttonAction: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly secondaryButtonAction: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly textContent: Maybe<ILocaleTypographyBlock>;
  /**
   * This is internal title for viewing purposes
   * @deprecated This is deprecated
   */
  readonly title: Maybe<Scalars['String']['output']>;
}

export interface IImageWithHeaderAndTextFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly alternativeButtonAction?: InputMaybe<ILinkActionFilter>;
  readonly buttonAction?: InputMaybe<ILinkActionFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly secondaryButtonAction?: InputMaybe<ILinkActionFilter>;
  readonly textContent?: InputMaybe<ILocaleTypographyBlockFilter>;
  readonly title?: InputMaybe<IStringFilter>;
}

export interface IImageWithHeaderAndTextSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly alternativeButtonAction?: InputMaybe<ILinkActionSorting>;
  readonly buttonAction?: InputMaybe<ILinkActionSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly secondaryButtonAction?: InputMaybe<ILinkActionSorting>;
  readonly textContent?: InputMaybe<ILocaleTypographyBlockSorting>;
  readonly title?: InputMaybe<SortOrder>;
}

export interface IImages {
  readonly __typename?: 'Images';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly app: Maybe<IImage>;
  readonly imageDescription: Maybe<Scalars['String']['output']>;
  readonly kiosk: Maybe<IImage>;
}

export interface IImagesByChannels {
  readonly __typename?: 'ImagesByChannels';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly imageDelivery: Maybe<IImage>;
  readonly imageKiosk: Maybe<IImage>;
  readonly imageRestaurant: Maybe<IImage>;
}

export interface IImagesByChannelsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly imageDelivery?: InputMaybe<IImageFilter>;
  readonly imageKiosk?: InputMaybe<IImageFilter>;
  readonly imageRestaurant?: InputMaybe<IImageFilter>;
}

export interface IImagesByChannelsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly imageDelivery?: InputMaybe<IImageSorting>;
  readonly imageKiosk?: InputMaybe<IImageSorting>;
  readonly imageRestaurant?: InputMaybe<IImageSorting>;
}

export interface IImagesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly app?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<IStringFilter>;
  readonly kiosk?: InputMaybe<IImageFilter>;
}

export interface IImagesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly app?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<SortOrder>;
  readonly kiosk?: InputMaybe<IImageSorting>;
}

export interface IIncentiveNotAvailableModalContent {
  readonly __typename?: 'IncentiveNotAvailableModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  readonly primaryButton: Maybe<IActionButton>;
  readonly secondaryButton: Maybe<IActionButton>;
  readonly title: Maybe<ILocaleString>;
}

export interface IIncentiveNotAvailableModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly primaryButton?: InputMaybe<IActionButtonFilter>;
  readonly secondaryButton?: InputMaybe<IActionButtonFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IIncentiveNotAvailableModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly primaryButton?: InputMaybe<IActionButtonSorting>;
  readonly secondaryButton?: InputMaybe<IActionButtonSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IIncentiveRequirement {
  readonly __typename?: 'IncentiveRequirement';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly incentiveRequirement: Maybe<ReadonlyArray<Maybe<IIncentiveRequirementEntry>>>;
}

export interface IIncentiveRequirementEntry {
  readonly __typename?: 'IncentiveRequirementEntry';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly incentive: Maybe<IConfigOfferOrConfigRewardOrRewardOrSystemwideOffer>;
  /** Quantity that must be applied */
  readonly quantity: Maybe<Scalars['Float']['output']>;
}

export interface IIncentiveRequirementEntryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly quantity?: InputMaybe<IFloatFilter>;
}

export interface IIncentiveRequirementEntrySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly quantity?: InputMaybe<SortOrder>;
}

export interface IIncentiveRequirementFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IIncentiveRequirementSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IInfoCellsWidget {
  readonly __typename?: 'InfoCellsWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly icon: Maybe<ILocaleImage>;
  readonly infoCells: Maybe<ReadonlyArray<Maybe<ILoyaltyTiersUnauthenticatedCell>>>;
  readonly title: Maybe<ILocaleString>;
}

export interface IInfoCellsWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly icon?: InputMaybe<ILocaleImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IInfoCellsWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly icon?: InputMaybe<ILocaleImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IIntFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is greater than the given input. */
  readonly gt?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  readonly gte?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is lesser than the given input. */
  readonly lt?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  readonly lte?: InputMaybe<Scalars['Int']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['Int']['input']>;
}

export interface IIntegration {
  readonly __typename?: 'Integration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Is the restaurant integration through the Partner API */
  readonly isPartnerRestaurant: Maybe<Scalars['Boolean']['output']>;
  /** Group of Partners to select from */
  readonly partnerGroup: Maybe<IPartnerGroup>;
}

export interface IIntegrationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isPartnerRestaurant?: InputMaybe<IBooleanFilter>;
  readonly partnerGroup?: InputMaybe<IPartnerGroupFilter>;
}

export interface IIntegrationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isPartnerRestaurant?: InputMaybe<SortOrder>;
}

export interface IInternalRedirectionConfig {
  readonly __typename?: 'InternalRedirectionConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Select User type to whom redirection will be applicable */
  readonly authEnv: Maybe<Scalars['String']['output']>;
  /** From where you want to redirect. Ex: / */
  readonly from: Maybe<Scalars['String']['output']>;
  /** Select the Platform where you want this redirection to be applicable */
  readonly platformEnv: Maybe<Scalars['String']['output']>;
  /** Where you want to redirect. Ex: /redeem */
  readonly to: Maybe<Scalars['String']['output']>;
}

export interface IInternalRedirectionConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly authEnv?: InputMaybe<IStringFilter>;
  readonly from?: InputMaybe<IStringFilter>;
  readonly platformEnv?: InputMaybe<IStringFilter>;
  readonly to?: InputMaybe<IStringFilter>;
}

export interface IInternalRedirectionConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly authEnv?: InputMaybe<SortOrder>;
  readonly from?: InputMaybe<SortOrder>;
  readonly platformEnv?: InputMaybe<SortOrder>;
  readonly to?: InputMaybe<SortOrder>;
}

export interface IInvitationCodeIncentive {
  readonly __typename?: 'InvitationCodeIncentive';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly offer: Maybe<IConfigOffer>;
  readonly type: Maybe<Scalars['String']['output']>;
}

export interface IInvitationCodeIncentiveFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly offer?: InputMaybe<IConfigOfferFilter>;
  readonly type?: InputMaybe<IStringFilter>;
}

export interface IInvitationCodeIncentiveSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly type?: InputMaybe<SortOrder>;
}

export interface IInvitationCodeInformationCard {
  readonly __typename?: 'InvitationCodeInformationCard';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cardDescription: Maybe<ILocaleBlockContent>;
  readonly cardImage: Maybe<IImage>;
  readonly cardImageBackgroundColor: Maybe<IColor>;
  readonly cardTitle: Maybe<ILocaleString>;
}

export interface IInvitationCodeInformationCardFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cardDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly cardImage?: InputMaybe<IImageFilter>;
  readonly cardImageBackgroundColor?: InputMaybe<IColorFilter>;
  readonly cardTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IInvitationCodeInformationCardSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cardDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly cardImage?: InputMaybe<IImageSorting>;
  readonly cardImageBackgroundColor?: InputMaybe<IColorSorting>;
  readonly cardTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IInvitationCodeSignInModalContent {
  readonly __typename?: 'InvitationCodeSignInModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly shareMyCodeButtonLink: Maybe<ILocaleString>;
  readonly shareMyCodeButtonText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface IInvitationCodeSignInModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly shareMyCodeButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IInvitationCodeSignInModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly shareMyCodeButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IInvitationCodeUnlocksBenefitModalContent {
  readonly __typename?: 'InvitationCodeUnlocksBenefitModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly shareMyCodeButtonLink: Maybe<ILocaleString>;
  readonly shareMyCodeButtonText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface IInvitationCodeUnlocksBenefitModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly shareMyCodeButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IInvitationCodeUnlocksBenefitModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly shareMyCodeButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly shareMyCodeButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IInvitationCodequestRules {
  readonly __typename?: 'InvitationCodequestRules';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Steps should be completed in sequential order */
  readonly isSequential: Maybe<Scalars['Boolean']['output']>;
  /** Steps have to be completed in different purchases */
  readonly onDistinctPurchases: Maybe<Scalars['Boolean']['output']>;
  readonly steps: Maybe<ReadonlyArray<Maybe<IQuestStep>>>;
}

export interface IInvitationCodequestRulesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isSequential?: InputMaybe<IBooleanFilter>;
  readonly onDistinctPurchases?: InputMaybe<IBooleanFilter>;
}

export interface IInvitationCodequestRulesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isSequential?: InputMaybe<SortOrder>;
  readonly onDistinctPurchases?: InputMaybe<SortOrder>;
}

export interface IIonicConfig {
  readonly __typename?: 'IonicConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ionicAppId: Maybe<Scalars['String']['output']>;
  readonly ionicChannelName: Maybe<Scalars['String']['output']>;
}

export interface IIonicConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ionicAppId?: InputMaybe<IStringFilter>;
  readonly ionicChannelName?: InputMaybe<IStringFilter>;
}

export interface IIonicConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ionicAppId?: InputMaybe<SortOrder>;
  readonly ionicChannelName?: InputMaybe<SortOrder>;
}

export interface IIsStackable {
  readonly __typename?: 'IsStackable';
  /** @deprecated This rule is deprecated and soon will be removed */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This rule is deprecated and soon will be removed */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This rule is deprecated and soon will be removed */
  readonly stackable: Maybe<Scalars['Boolean']['output']>;
}

export interface IIsStackableFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly stackable?: InputMaybe<IBooleanFilter>;
}

export interface IIsStackableSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly stackable?: InputMaybe<SortOrder>;
}

export interface IItem extends IDocument {
  readonly __typename?: 'Item';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly activationDate: Maybe<Scalars['Date']['output']>;
  readonly additionalItemInformation: Maybe<IAdditionalItemInformation>;
  readonly allergens: Maybe<IOpAllergen>;
  readonly channelExclusions: Maybe<IChannelExclusions>;
  readonly contentOptions: Maybe<ReadonlyArray<Maybe<IContentOption>>>;
  /** @deprecated This has been deprecated */
  readonly deactivationDate: Maybe<Scalars['Date']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly dmbName: Maybe<ILocaleString>;
  /** Hide all displays of calories on this item */
  readonly hideCalories: Maybe<Scalars['Boolean']['output']>;
  /** Hide the nutrition legal disclaimer on this item */
  readonly hideNutritionLegalDisclaimer: Maybe<Scalars['Boolean']['output']>;
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly imagesByChannels: Maybe<IImagesByChannels>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** This toggle will be used to determine whether the Main Item should be hidden from           the UI. If true, this item will not appear within the menu summary for Menu Redesign. */
  readonly isDummyItem: Maybe<Scalars['Boolean']['output']>;
  /** Size of an individual item. Used by BK Germany to calculate Price per Filling & Unit */
  readonly itemSize: Maybe<Scalars['Float']['output']>;
  /** Unit of measurement for an item. Used by BK Germany to calculate Price per           Filling & Unit */
  readonly itemUnit: Maybe<Scalars['String']['output']>;
  /**
   * Show the the amount being ordered as per person.
   * @deprecated Field no longer used an soon to be removed.
   */
  readonly labelAsPerPerson: Maybe<Scalars['Boolean']['output']>;
  readonly legalInformation: Maybe<ILocaleBlockContent>;
  readonly menuObjectSettings: Maybe<IMenuObjectSettings>;
  readonly name: Maybe<ILocaleString>;
  readonly nutrition: Maybe<INutrition>;
  readonly nutritionWithModifiers: Maybe<INutrition>;
  readonly operationalItem: Maybe<IOpItem>;
  readonly options: Maybe<ReadonlyArray<Maybe<IItemOption>>>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /** @deprecated This has been deprecated */
  readonly prices: Maybe<ReadonlyArray<Maybe<IPricingRegionPrice>>>;
  readonly productHierarchy: Maybe<IProductHierarchy>;
  /** Product Size shown for compliance purposes (in grams or ml). */
  readonly productSize: Maybe<Scalars['String']['output']>;
  /**
   * This field is only for UI purposes. Adding a promotion reference here will make possible to show a bonus point badges in menu pages. The validation of this field will not take care of rules evaluation, so before adding any promotion here, please verify the promotion is still valid to avoid a bad UX.
   * @deprecated Review with Loyalty Team if necessary.
   */
  readonly promotion: Maybe<ISystemwidePromotion>;
  /** MDM Source Country/Region */
  readonly region: Maybe<Scalars['String']['output']>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  /** @deprecated Field no longer used an soon to be removed. */
  readonly rewardEligible: Maybe<Scalars['Boolean']['output']>;
  /** Show item in static menu */
  readonly showInStaticMenu: Maybe<Scalars['Boolean']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IItemFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly activationDate?: InputMaybe<IDateFilter>;
  readonly additionalItemInformation?: InputMaybe<IAdditionalItemInformationFilter>;
  readonly allergens?: InputMaybe<IOpAllergenFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly deactivationDate?: InputMaybe<IDateFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly dmbName?: InputMaybe<ILocaleStringFilter>;
  readonly hideCalories?: InputMaybe<IBooleanFilter>;
  readonly hideNutritionLegalDisclaimer?: InputMaybe<IBooleanFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isDummyItem?: InputMaybe<IBooleanFilter>;
  readonly itemSize?: InputMaybe<IFloatFilter>;
  readonly itemUnit?: InputMaybe<IStringFilter>;
  readonly labelAsPerPerson?: InputMaybe<IBooleanFilter>;
  readonly legalInformation?: InputMaybe<ILocaleBlockContentFilter>;
  readonly menuObjectSettings?: InputMaybe<IMenuObjectSettingsFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly nutrition?: InputMaybe<INutritionFilter>;
  readonly nutritionWithModifiers?: InputMaybe<INutritionFilter>;
  readonly operationalItem?: InputMaybe<IOpItemFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly productHierarchy?: InputMaybe<IProductHierarchyFilter>;
  readonly productSize?: InputMaybe<IStringFilter>;
  readonly promotion?: InputMaybe<ISystemwidePromotionFilter>;
  readonly region?: InputMaybe<IStringFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly rewardEligible?: InputMaybe<IBooleanFilter>;
  readonly showInStaticMenu?: InputMaybe<IBooleanFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IItemOption {
  readonly __typename?: 'ItemOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Mostly unused */
  readonly activationDate: Maybe<Scalars['DateTime']['output']>;
  /** This value will no longer be used in the menu redesign, the max will determine how many selections a user is able to make */
  readonly allowMultipleSelections: Maybe<Scalars['Boolean']['output']>;
  /** Configure UI styling. Selector: Allow selecting between multiple options, Stepper: Allow using + or - to increase or decrease, Boolean: Yes or No */
  readonly componentStyle: Maybe<Scalars['String']['output']>;
  /** Mostly unused */
  readonly deactivationDate: Maybe<Scalars['DateTime']['output']>;
  readonly displayGroup: Maybe<IDisplayGroup>;
  /** If this value is set to true, the quantity of the modifier will be displayed using the modifier multiplier prefix (Ex: Light Ketchup). If this value is set to false, the quantity of the modifier will be displayed using the modifier multiplier number (Ex: 0.5 Ketchup) */
  readonly displayModifierMultiplierName: Maybe<Scalars['Boolean']['output']>;
  /** Customization options for an item, eg "Cheese" or "Pickles" */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  /** This field indicates that the PLU of the default option should be included when communicating to the POS. */
  readonly injectDefaultSelection: Maybe<Scalars['Boolean']['output']>;
  readonly maxAmount: Maybe<Scalars['Float']['output']>;
  readonly minAmount: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IItemOptionModifier>>>;
  /** MDM Sync. This value determines if the item option is within a single choice modifier group (Ex: Dipping Sauces) */
  readonly singleChoiceOnly: Maybe<Scalars['Boolean']['output']>;
  /** Configure whether you will charge for an item option modifier */
  readonly upsellModifier: Maybe<Scalars['Boolean']['output']>;
}

export interface IItemOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly activationDate?: InputMaybe<IDatetimeFilter>;
  readonly allowMultipleSelections?: InputMaybe<IBooleanFilter>;
  readonly componentStyle?: InputMaybe<IStringFilter>;
  readonly deactivationDate?: InputMaybe<IDatetimeFilter>;
  readonly displayGroup?: InputMaybe<IDisplayGroupFilter>;
  readonly displayModifierMultiplierName?: InputMaybe<IBooleanFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
  readonly injectDefaultSelection?: InputMaybe<IBooleanFilter>;
  readonly maxAmount?: InputMaybe<IFloatFilter>;
  readonly minAmount?: InputMaybe<IFloatFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly singleChoiceOnly?: InputMaybe<IBooleanFilter>;
  readonly upsellModifier?: InputMaybe<IBooleanFilter>;
}

export interface IItemOptionModifier {
  readonly __typename?: 'ItemOptionModifier';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Unused */
  readonly activationDate: Maybe<Scalars['DateTime']['output']>;
  readonly allergen: Maybe<IOpAllergen>;
  readonly channelExclusions: Maybe<IChannelExclusions>;
  /** Unused */
  readonly deactivationDate: Maybe<Scalars['DateTime']['output']>;
  /** MDM Sync. Established whether it's the default modifier selected */
  readonly default: Maybe<Scalars['Boolean']['output']>;
  /** Choices for an item option, eg "No Cheese" or "Extra Pickles" */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  /** MDM Sync */
  readonly modifierMultiplier: Maybe<IModifierMultiplier>;
  readonly name: Maybe<ILocaleString>;
  readonly nutrition: Maybe<INutrition>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  readonly prices: Maybe<ReadonlyArray<Maybe<IPricingRegionPrice>>>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IItemOptionModifierFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly activationDate?: InputMaybe<IDatetimeFilter>;
  readonly allergen?: InputMaybe<IOpAllergenFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly deactivationDate?: InputMaybe<IDatetimeFilter>;
  readonly default?: InputMaybe<IBooleanFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
  readonly modifierMultiplier?: InputMaybe<IModifierMultiplierFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly nutrition?: InputMaybe<INutritionFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IItemOptionModifierSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly activationDate?: InputMaybe<SortOrder>;
  readonly allergen?: InputMaybe<IOpAllergenSorting>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly deactivationDate?: InputMaybe<SortOrder>;
  readonly default?: InputMaybe<SortOrder>;
  readonly documentDescription?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly nutrition?: InputMaybe<INutritionSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IItemOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly activationDate?: InputMaybe<SortOrder>;
  readonly allowMultipleSelections?: InputMaybe<SortOrder>;
  readonly componentStyle?: InputMaybe<SortOrder>;
  readonly deactivationDate?: InputMaybe<SortOrder>;
  readonly displayModifierMultiplierName?: InputMaybe<SortOrder>;
  readonly documentDescription?: InputMaybe<SortOrder>;
  readonly injectDefaultSelection?: InputMaybe<SortOrder>;
  readonly maxAmount?: InputMaybe<SortOrder>;
  readonly minAmount?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly singleChoiceOnly?: InputMaybe<SortOrder>;
  readonly upsellModifier?: InputMaybe<SortOrder>;
}

export type IItemOrNutritionalSection = IItem | INutritionalSection;

export type IItemOrPickerOrSection = IItem | IPicker | ISection;

export interface IItemSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly activationDate?: InputMaybe<SortOrder>;
  readonly additionalItemInformation?: InputMaybe<IAdditionalItemInformationSorting>;
  readonly allergens?: InputMaybe<IOpAllergenSorting>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly deactivationDate?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly dmbName?: InputMaybe<ILocaleStringSorting>;
  readonly hideCalories?: InputMaybe<SortOrder>;
  readonly hideNutritionLegalDisclaimer?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isDummyItem?: InputMaybe<SortOrder>;
  readonly itemSize?: InputMaybe<SortOrder>;
  readonly itemUnit?: InputMaybe<SortOrder>;
  readonly labelAsPerPerson?: InputMaybe<SortOrder>;
  readonly legalInformation?: InputMaybe<ILocaleBlockContentSorting>;
  readonly menuObjectSettings?: InputMaybe<IMenuObjectSettingsSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly nutrition?: InputMaybe<INutritionSorting>;
  readonly nutritionWithModifiers?: InputMaybe<INutritionSorting>;
  readonly operationalItem?: InputMaybe<IOpItemSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly productHierarchy?: InputMaybe<IProductHierarchySorting>;
  readonly productSize?: InputMaybe<SortOrder>;
  readonly region?: InputMaybe<SortOrder>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly rewardEligible?: InputMaybe<SortOrder>;
  readonly showInStaticMenu?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IItemUnavailableModal {
  readonly __typename?: 'ItemUnavailableModal';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly continue: Maybe<ILocaleString>;
  readonly header: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly itemNotAvailableForDelivery: Maybe<ILocaleString>;
  readonly selectNew: Maybe<ILocaleString>;
}

export interface IItemUnavailableModalFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly continue?: InputMaybe<ILocaleStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly itemNotAvailableForDelivery?: InputMaybe<ILocaleStringFilter>;
  readonly selectNew?: InputMaybe<ILocaleStringFilter>;
}

export interface IItemUnavailableModalSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly continue?: InputMaybe<ILocaleStringSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly itemNotAvailableForDelivery?: InputMaybe<ILocaleStringSorting>;
  readonly selectNew?: InputMaybe<ILocaleStringSorting>;
}

export interface IKiosk {
  readonly __typename?: 'Kiosk';
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly serialNumber: Maybe<Scalars['String']['output']>;
}

export interface IKioskAppData extends IDocument {
  readonly __typename?: 'KioskAppData';
  /**
   * Date the document was created
   * @deprecated This document is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This document is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This document is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This document is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This document is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This document is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This document is deprecated */
  readonly blacklistCharacterReplaceRules: Maybe<
    ReadonlyArray<Maybe<IBlacklistCharacterReplaceRule>>
  >;
  /** @deprecated This document is deprecated */
  readonly userNameBlacklist: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
}

export interface IKioskAppDataFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IKioskAppDataSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IKioskConfiguration extends IDocument {
  readonly __typename?: 'KioskConfiguration';
  /**
   * Date the document was created
   * @deprecated This document was re-purposed to be used by the Call Center.
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This document was re-purposed to be used by the Call Center.
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This document was re-purposed to be used by the Call Center. */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This document was re-purposed to be used by the Call Center.
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This document was re-purposed to be used by the Call Center.
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This document was re-purposed to be used by the Call Center.
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This document was re-purposed to be used by the Call Center. */
  readonly dataDog: Maybe<IKioskDataDogConfig>;
  /** @deprecated This document was re-purposed to be used by the Call Center. */
  readonly dataDogRum: Maybe<IKioskDataDogRumConfig>;
}

export interface IKioskConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly dataDog?: InputMaybe<IKioskDataDogConfigFilter>;
  readonly dataDogRum?: InputMaybe<IKioskDataDogRumConfigFilter>;
}

export interface IKioskConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly dataDog?: InputMaybe<IKioskDataDogConfigSorting>;
  readonly dataDogRum?: InputMaybe<IKioskDataDogRumConfigSorting>;
}

export interface IKioskDataDogConfig {
  readonly __typename?: 'KioskDataDogConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly clientToken: Maybe<Scalars['String']['output']>;
  readonly site: Maybe<Scalars['String']['output']>;
}

export interface IKioskDataDogConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly clientToken?: InputMaybe<IStringFilter>;
  readonly site?: InputMaybe<IStringFilter>;
}

export interface IKioskDataDogConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly clientToken?: InputMaybe<SortOrder>;
  readonly site?: InputMaybe<SortOrder>;
}

export interface IKioskDataDogRumConfig {
  readonly __typename?: 'KioskDataDogRumConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly applicationId: Maybe<Scalars['String']['output']>;
  readonly clientToken: Maybe<Scalars['String']['output']>;
  readonly site: Maybe<Scalars['String']['output']>;
}

export interface IKioskDataDogRumConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly applicationId?: InputMaybe<IStringFilter>;
  readonly clientToken?: InputMaybe<IStringFilter>;
  readonly site?: InputMaybe<IStringFilter>;
}

export interface IKioskDataDogRumConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly applicationId?: InputMaybe<SortOrder>;
  readonly clientToken?: InputMaybe<SortOrder>;
  readonly site?: InputMaybe<SortOrder>;
}

export interface IKioskFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly serialNumber?: InputMaybe<IStringFilter>;
}

export interface IKioskSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly serialNumber?: InputMaybe<SortOrder>;
}

export interface ILanguageSelectorWidget {
  readonly __typename?: 'LanguageSelectorWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface ILanguageSelectorWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface ILanguageSelectorWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface ILaunchDarklyNavigationLink {
  readonly __typename?: 'LaunchDarklyNavigationLink';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  readonly launchDarklyFlagDependency: Maybe<ILaunchDarklySelect>;
  readonly navLink: Maybe<ILinkWidget>;
}

export interface ILaunchDarklyNavigationLinkFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
  readonly launchDarklyFlagDependency?: InputMaybe<ILaunchDarklySelectFilter>;
  readonly navLink?: InputMaybe<ILinkWidgetFilter>;
}

export interface ILaunchDarklyNavigationLinkSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly enabled?: InputMaybe<SortOrder>;
  readonly launchDarklyFlagDependency?: InputMaybe<ILaunchDarklySelectSorting>;
  readonly navLink?: InputMaybe<ILinkWidgetSorting>;
}

export interface ILaunchDarklyNavigationTab {
  readonly __typename?: 'LaunchDarklyNavigationTab';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  readonly launchDarklyFlagDependency: Maybe<ILaunchDarklySelect>;
  readonly tabIcon: Maybe<ILocaleImage>;
  readonly tabIconActive: Maybe<ILocaleImage>;
  readonly tabLink: Maybe<ILinkWidget>;
}

export interface ILaunchDarklyNavigationTabFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
  readonly launchDarklyFlagDependency?: InputMaybe<ILaunchDarklySelectFilter>;
  readonly tabIcon?: InputMaybe<ILocaleImageFilter>;
  readonly tabIconActive?: InputMaybe<ILocaleImageFilter>;
  readonly tabLink?: InputMaybe<ILinkWidgetFilter>;
}

export interface ILaunchDarklyNavigationTabSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly enabled?: InputMaybe<SortOrder>;
  readonly launchDarklyFlagDependency?: InputMaybe<ILaunchDarklySelectSorting>;
  readonly tabIcon?: InputMaybe<ILocaleImageSorting>;
  readonly tabIconActive?: InputMaybe<ILocaleImageSorting>;
  readonly tabLink?: InputMaybe<ILinkWidgetSorting>;
}

export interface ILaunchDarklySelect {
  readonly __typename?: 'LaunchDarklySelect';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly launchDarklyFlag: Maybe<Scalars['String']['output']>;
}

export interface ILaunchDarklySelectFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly launchDarklyFlag?: InputMaybe<IStringFilter>;
}

export interface ILaunchDarklySelectSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly launchDarklyFlag?: InputMaybe<SortOrder>;
}

export interface ILimit {
  readonly __typename?: 'Limit';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Maximum redemptions refresh every... */
  readonly interval: Maybe<Scalars['String']['output']>;
  /**
   * Maximum redemptions refresh every...
   * @deprecated This has been deprecated
   */
  readonly limitInterval: Maybe<Scalars['String']['output']>;
  readonly maximumRedemptions: Maybe<Scalars['Float']['output']>;
  /** @deprecated This has been deprecated */
  readonly multiplier: Maybe<Scalars['Float']['output']>;
  /**
   * time format: 24:00:00 UTC
   * @deprecated This has been deprecated
   */
  readonly resetAt: Maybe<Scalars['String']['output']>;
}

export interface ILimitFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly interval?: InputMaybe<IStringFilter>;
  readonly limitInterval?: InputMaybe<IStringFilter>;
  readonly maximumRedemptions?: InputMaybe<IFloatFilter>;
  readonly multiplier?: InputMaybe<IFloatFilter>;
  readonly resetAt?: InputMaybe<IStringFilter>;
}

export interface ILimitSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly interval?: InputMaybe<SortOrder>;
  readonly limitInterval?: InputMaybe<SortOrder>;
  readonly maximumRedemptions?: InputMaybe<SortOrder>;
  readonly multiplier?: InputMaybe<SortOrder>;
  readonly resetAt?: InputMaybe<SortOrder>;
}

export interface ILink {
  readonly __typename?: 'Link';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<Scalars['String']['output']>;
}

export interface ILinkAction {
  readonly __typename?: 'LinkAction';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly actionText: Maybe<ILocaleString>;
  readonly actionUrl: Maybe<ILocaleUrl>;
  /** @deprecated This has been deprecated */
  readonly route: Maybe<Scalars['String']['output']>;
}

export interface ILinkActionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly actionText?: InputMaybe<ILocaleStringFilter>;
  readonly actionUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly route?: InputMaybe<IStringFilter>;
}

export interface ILinkActionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly actionText?: InputMaybe<ILocaleStringSorting>;
  readonly actionUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly route?: InputMaybe<SortOrder>;
}

export interface ILinkCardActivationSuccessModal {
  readonly __typename?: 'LinkCardActivationSuccessModal';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly closeButtonLabel: Maybe<ILocaleString>;
  readonly ctaButtonLabel: Maybe<ILocaleString>;
  readonly description: Maybe<ILocaleString>;
  readonly downloadAppButtonLabel: Maybe<ILocaleString>;
  readonly downloadAppUrl: Maybe<ILocaleUrl>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILinkCardActivationSuccessModalFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly downloadAppButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly downloadAppUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkCardActivationSuccessModalSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly downloadAppButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly downloadAppUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILinkCardDefaultModal {
  readonly __typename?: 'LinkCardDefaultModal';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly closeButtonLabel: Maybe<ILocaleString>;
  readonly content: Maybe<ILocaleString>;
  readonly ctaButtonLabel: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILinkCardDefaultModalFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly content?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkCardDefaultModalSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly content?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILinkCardErrorModal {
  readonly __typename?: 'LinkCardErrorModal';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly closeButtonLabel: Maybe<ILocaleString>;
  readonly ctaButtonLabel: Maybe<ILocaleString>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILinkCardErrorModalFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkCardErrorModalSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly closeButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILinkCardFaqSection {
  readonly __typename?: 'LinkCardFaqSection';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly marketingTileList: Maybe<IMarketingTileList>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILinkCardFaqSectionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly marketingTileList?: InputMaybe<IMarketingTileListFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkCardFaqSectionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly marketingTileList?: InputMaybe<IMarketingTileListSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILinkCardSection {
  readonly __typename?: 'LinkCardSection';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ctaButtonLabel: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILinkCardSectionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkCardSectionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ctaButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILinkFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<IStringFilter>;
}

export interface ILinkPhysicalCardUi extends IDocument {
  readonly __typename?: 'LinkPhysicalCardUi';
  /**
   * Date the document was created
   * @deprecated Physical Cards (Tim Hortons) not being used at INTL.
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated Physical Cards (Tim Hortons) not being used at INTL.
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated Physical Cards (Tim Hortons) not being used at INTL.
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated Physical Cards (Tim Hortons) not being used at INTL.
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated Physical Cards (Tim Hortons) not being used at INTL.
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardActivationSuccessModal: Maybe<ILinkCardActivationSuccessModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardActivationWithRewardSuccessModal: Maybe<ILinkCardActivationSuccessModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardDeactivationModal: Maybe<ILinkCardDefaultModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardErrorModalGeneric: Maybe<ILinkCardErrorModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardErrorModalLinked: Maybe<ILinkCardErrorModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardErrorModalReplace: Maybe<ILinkCardErrorModal>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardFaqSection: Maybe<ILinkCardFaqSection>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardSectionLinkedState: Maybe<ILinkCardSection>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly linkCardSectionUnlinkedState: Maybe<ILinkCardSection>;
}

export interface ILinkPhysicalCardUiFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly linkCardActivationSuccessModal?: InputMaybe<ILinkCardActivationSuccessModalFilter>;
  readonly linkCardActivationWithRewardSuccessModal?: InputMaybe<ILinkCardActivationSuccessModalFilter>;
  readonly linkCardDeactivationModal?: InputMaybe<ILinkCardDefaultModalFilter>;
  readonly linkCardErrorModalGeneric?: InputMaybe<ILinkCardErrorModalFilter>;
  readonly linkCardErrorModalLinked?: InputMaybe<ILinkCardErrorModalFilter>;
  readonly linkCardErrorModalReplace?: InputMaybe<ILinkCardErrorModalFilter>;
  readonly linkCardFaqSection?: InputMaybe<ILinkCardFaqSectionFilter>;
  readonly linkCardSectionLinkedState?: InputMaybe<ILinkCardSectionFilter>;
  readonly linkCardSectionUnlinkedState?: InputMaybe<ILinkCardSectionFilter>;
}

export interface ILinkPhysicalCardUiSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly linkCardActivationSuccessModal?: InputMaybe<ILinkCardActivationSuccessModalSorting>;
  readonly linkCardActivationWithRewardSuccessModal?: InputMaybe<ILinkCardActivationSuccessModalSorting>;
  readonly linkCardDeactivationModal?: InputMaybe<ILinkCardDefaultModalSorting>;
  readonly linkCardErrorModalGeneric?: InputMaybe<ILinkCardErrorModalSorting>;
  readonly linkCardErrorModalLinked?: InputMaybe<ILinkCardErrorModalSorting>;
  readonly linkCardErrorModalReplace?: InputMaybe<ILinkCardErrorModalSorting>;
  readonly linkCardFaqSection?: InputMaybe<ILinkCardFaqSectionSorting>;
  readonly linkCardSectionLinkedState?: InputMaybe<ILinkCardSectionSorting>;
  readonly linkCardSectionUnlinkedState?: InputMaybe<ILinkCardSectionSorting>;
}

export interface ILinkSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<SortOrder>;
}

export interface ILinkWidget {
  readonly __typename?: 'LinkWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<ILocaleUrl>;
  readonly text: Maybe<ILocaleString>;
}

export interface ILinkWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface ILinkWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface IListItem {
  readonly __typename?: 'ListItem';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<Scalars['String']['output']>;
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface IListItemFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<IStringFilter>;
  readonly value?: InputMaybe<IStringFilter>;
}

export interface IListItemSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<SortOrder>;
  readonly value?: InputMaybe<SortOrder>;
}

export interface ILiveMemberships extends IDocument {
  readonly __typename?: 'LiveMemberships';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly liveMemberships: Maybe<ReadonlyArray<Maybe<IMemberships>>>;
}

export interface ILiveMembershipsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface ILiveMembershipsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface ILoader {
  readonly __typename?: 'Loader';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly desktopGraphic: Maybe<IImage>;
  readonly mobileGraphic: Maybe<IImage>;
  readonly text: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoaderFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly desktopGraphic?: InputMaybe<IImageFilter>;
  readonly mobileGraphic?: InputMaybe<IImageFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoaderSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly desktopGraphic?: InputMaybe<IImageSorting>;
  readonly mobileGraphic?: InputMaybe<IImageSorting>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoadingGraphics {
  readonly __typename?: 'LoadingGraphics';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cartInitialLoader: Maybe<ILoader>;
  readonly cartPricingLoader: Maybe<ILoader>;
}

export interface ILoadingGraphicsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cartInitialLoader?: InputMaybe<ILoaderFilter>;
  readonly cartPricingLoader?: InputMaybe<ILoaderFilter>;
}

export interface ILoadingGraphicsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cartInitialLoader?: InputMaybe<ILoaderSorting>;
  readonly cartPricingLoader?: InputMaybe<ILoaderSorting>;
}

export interface ILocaleBlockContent {
  readonly __typename?: 'LocaleBlockContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly arRaw: Maybe<Scalars['JSON']['output']>;
  readonly caRaw: Maybe<Scalars['JSON']['output']>;
  readonly csRaw: Maybe<Scalars['JSON']['output']>;
  readonly deRaw: Maybe<Scalars['JSON']['output']>;
  readonly enRaw: Maybe<Scalars['JSON']['output']>;
  readonly esRaw: Maybe<Scalars['JSON']['output']>;
  readonly euRaw: Maybe<Scalars['JSON']['output']>;
  readonly frRaw: Maybe<Scalars['JSON']['output']>;
  readonly glRaw: Maybe<Scalars['JSON']['output']>;
  readonly itRaw: Maybe<Scalars['JSON']['output']>;
  readonly koRaw: Maybe<Scalars['JSON']['output']>;
  readonly msRaw: Maybe<Scalars['JSON']['output']>;
  readonly nlRaw: Maybe<Scalars['JSON']['output']>;
  readonly plRaw: Maybe<Scalars['JSON']['output']>;
  readonly ptRaw: Maybe<Scalars['JSON']['output']>;
  readonly roRaw: Maybe<Scalars['JSON']['output']>;
  readonly taRaw: Maybe<Scalars['JSON']['output']>;
  readonly thRaw: Maybe<Scalars['JSON']['output']>;
  readonly tlRaw: Maybe<Scalars['JSON']['output']>;
  readonly ukRaw: Maybe<Scalars['JSON']['output']>;
  readonly zhRaw: Maybe<Scalars['JSON']['output']>;
}

export interface ILocaleBlockContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ILocaleBlockContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ILocaleBlockTextWidget {
  readonly __typename?: 'LocaleBlockTextWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly blockTextInversion: Maybe<Scalars['Boolean']['output']>;
  readonly localeBlockTextContent: Maybe<ILocaleBlockContent>;
}

export interface ILocaleBlockTextWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly blockTextInversion?: InputMaybe<IBooleanFilter>;
  readonly localeBlockTextContent?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface ILocaleBlockTextWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly blockTextInversion?: InputMaybe<SortOrder>;
  readonly localeBlockTextContent?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface ILocaleFile {
  readonly __typename?: 'LocaleFile';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<IFile>;
  readonly ca: Maybe<IFile>;
  readonly cs: Maybe<IFile>;
  readonly de: Maybe<IFile>;
  readonly en: Maybe<IFile>;
  readonly es: Maybe<IFile>;
  readonly eu: Maybe<IFile>;
  readonly fr: Maybe<IFile>;
  readonly gl: Maybe<IFile>;
  readonly it: Maybe<IFile>;
  readonly ko: Maybe<IFile>;
  readonly ms: Maybe<IFile>;
  readonly nl: Maybe<IFile>;
  readonly pl: Maybe<IFile>;
  readonly pt: Maybe<IFile>;
  readonly ro: Maybe<IFile>;
  readonly ta: Maybe<IFile>;
  readonly th: Maybe<IFile>;
  readonly tl: Maybe<IFile>;
  readonly uk: Maybe<IFile>;
  readonly zh: Maybe<IFile>;
}

export interface ILocaleFileFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IFileFilter>;
  readonly ca?: InputMaybe<IFileFilter>;
  readonly cs?: InputMaybe<IFileFilter>;
  readonly de?: InputMaybe<IFileFilter>;
  readonly en?: InputMaybe<IFileFilter>;
  readonly es?: InputMaybe<IFileFilter>;
  readonly eu?: InputMaybe<IFileFilter>;
  readonly fr?: InputMaybe<IFileFilter>;
  readonly gl?: InputMaybe<IFileFilter>;
  readonly it?: InputMaybe<IFileFilter>;
  readonly ko?: InputMaybe<IFileFilter>;
  readonly ms?: InputMaybe<IFileFilter>;
  readonly nl?: InputMaybe<IFileFilter>;
  readonly pl?: InputMaybe<IFileFilter>;
  readonly pt?: InputMaybe<IFileFilter>;
  readonly ro?: InputMaybe<IFileFilter>;
  readonly ta?: InputMaybe<IFileFilter>;
  readonly th?: InputMaybe<IFileFilter>;
  readonly tl?: InputMaybe<IFileFilter>;
  readonly uk?: InputMaybe<IFileFilter>;
  readonly zh?: InputMaybe<IFileFilter>;
}

export interface ILocaleFileSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<IFileSorting>;
  readonly ca?: InputMaybe<IFileSorting>;
  readonly cs?: InputMaybe<IFileSorting>;
  readonly de?: InputMaybe<IFileSorting>;
  readonly en?: InputMaybe<IFileSorting>;
  readonly es?: InputMaybe<IFileSorting>;
  readonly eu?: InputMaybe<IFileSorting>;
  readonly fr?: InputMaybe<IFileSorting>;
  readonly gl?: InputMaybe<IFileSorting>;
  readonly it?: InputMaybe<IFileSorting>;
  readonly ko?: InputMaybe<IFileSorting>;
  readonly ms?: InputMaybe<IFileSorting>;
  readonly nl?: InputMaybe<IFileSorting>;
  readonly pl?: InputMaybe<IFileSorting>;
  readonly pt?: InputMaybe<IFileSorting>;
  readonly ro?: InputMaybe<IFileSorting>;
  readonly ta?: InputMaybe<IFileSorting>;
  readonly th?: InputMaybe<IFileSorting>;
  readonly tl?: InputMaybe<IFileSorting>;
  readonly uk?: InputMaybe<IFileSorting>;
  readonly zh?: InputMaybe<IFileSorting>;
}

export interface ILocaleImage {
  readonly __typename?: 'LocaleImage';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<IImage>;
  readonly ca: Maybe<IImage>;
  readonly cs: Maybe<IImage>;
  readonly de: Maybe<IImage>;
  readonly en: Maybe<IImage>;
  readonly es: Maybe<IImage>;
  readonly eu: Maybe<IImage>;
  readonly fr: Maybe<IImage>;
  readonly gl: Maybe<IImage>;
  readonly it: Maybe<IImage>;
  readonly ko: Maybe<IImage>;
  readonly ms: Maybe<IImage>;
  readonly nl: Maybe<IImage>;
  readonly pl: Maybe<IImage>;
  readonly pt: Maybe<IImage>;
  readonly ro: Maybe<IImage>;
  readonly ta: Maybe<IImage>;
  readonly th: Maybe<IImage>;
  readonly tl: Maybe<IImage>;
  readonly uk: Maybe<IImage>;
  readonly zh: Maybe<IImage>;
}

export interface ILocaleImageFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IImageFilter>;
  readonly ca?: InputMaybe<IImageFilter>;
  readonly cs?: InputMaybe<IImageFilter>;
  readonly de?: InputMaybe<IImageFilter>;
  readonly en?: InputMaybe<IImageFilter>;
  readonly es?: InputMaybe<IImageFilter>;
  readonly eu?: InputMaybe<IImageFilter>;
  readonly fr?: InputMaybe<IImageFilter>;
  readonly gl?: InputMaybe<IImageFilter>;
  readonly it?: InputMaybe<IImageFilter>;
  readonly ko?: InputMaybe<IImageFilter>;
  readonly ms?: InputMaybe<IImageFilter>;
  readonly nl?: InputMaybe<IImageFilter>;
  readonly pl?: InputMaybe<IImageFilter>;
  readonly pt?: InputMaybe<IImageFilter>;
  readonly ro?: InputMaybe<IImageFilter>;
  readonly ta?: InputMaybe<IImageFilter>;
  readonly th?: InputMaybe<IImageFilter>;
  readonly tl?: InputMaybe<IImageFilter>;
  readonly uk?: InputMaybe<IImageFilter>;
  readonly zh?: InputMaybe<IImageFilter>;
}

export interface ILocaleImageSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<IImageSorting>;
  readonly ca?: InputMaybe<IImageSorting>;
  readonly cs?: InputMaybe<IImageSorting>;
  readonly de?: InputMaybe<IImageSorting>;
  readonly en?: InputMaybe<IImageSorting>;
  readonly es?: InputMaybe<IImageSorting>;
  readonly eu?: InputMaybe<IImageSorting>;
  readonly fr?: InputMaybe<IImageSorting>;
  readonly gl?: InputMaybe<IImageSorting>;
  readonly it?: InputMaybe<IImageSorting>;
  readonly ko?: InputMaybe<IImageSorting>;
  readonly ms?: InputMaybe<IImageSorting>;
  readonly nl?: InputMaybe<IImageSorting>;
  readonly pl?: InputMaybe<IImageSorting>;
  readonly pt?: InputMaybe<IImageSorting>;
  readonly ro?: InputMaybe<IImageSorting>;
  readonly ta?: InputMaybe<IImageSorting>;
  readonly th?: InputMaybe<IImageSorting>;
  readonly tl?: InputMaybe<IImageSorting>;
  readonly uk?: InputMaybe<IImageSorting>;
  readonly zh?: InputMaybe<IImageSorting>;
}

export interface ILocaleImages {
  readonly __typename?: 'LocaleImages';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<IImages>;
  readonly ca: Maybe<IImages>;
  readonly cs: Maybe<IImages>;
  readonly de: Maybe<IImages>;
  readonly en: Maybe<IImages>;
  readonly es: Maybe<IImages>;
  readonly eu: Maybe<IImages>;
  readonly fr: Maybe<IImages>;
  readonly gl: Maybe<IImages>;
  readonly it: Maybe<IImages>;
  readonly ko: Maybe<IImages>;
  readonly ms: Maybe<IImages>;
  readonly nl: Maybe<IImages>;
  readonly pl: Maybe<IImages>;
  readonly pt: Maybe<IImages>;
  readonly ro: Maybe<IImages>;
  readonly ta: Maybe<IImages>;
  readonly th: Maybe<IImages>;
  readonly tl: Maybe<IImages>;
  readonly uk: Maybe<IImages>;
  readonly zh: Maybe<IImages>;
}

export interface ILocaleImagesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IImagesFilter>;
  readonly ca?: InputMaybe<IImagesFilter>;
  readonly cs?: InputMaybe<IImagesFilter>;
  readonly de?: InputMaybe<IImagesFilter>;
  readonly en?: InputMaybe<IImagesFilter>;
  readonly es?: InputMaybe<IImagesFilter>;
  readonly eu?: InputMaybe<IImagesFilter>;
  readonly fr?: InputMaybe<IImagesFilter>;
  readonly gl?: InputMaybe<IImagesFilter>;
  readonly it?: InputMaybe<IImagesFilter>;
  readonly ko?: InputMaybe<IImagesFilter>;
  readonly ms?: InputMaybe<IImagesFilter>;
  readonly nl?: InputMaybe<IImagesFilter>;
  readonly pl?: InputMaybe<IImagesFilter>;
  readonly pt?: InputMaybe<IImagesFilter>;
  readonly ro?: InputMaybe<IImagesFilter>;
  readonly ta?: InputMaybe<IImagesFilter>;
  readonly th?: InputMaybe<IImagesFilter>;
  readonly tl?: InputMaybe<IImagesFilter>;
  readonly uk?: InputMaybe<IImagesFilter>;
  readonly zh?: InputMaybe<IImagesFilter>;
}

export interface ILocaleImagesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<IImagesSorting>;
  readonly ca?: InputMaybe<IImagesSorting>;
  readonly cs?: InputMaybe<IImagesSorting>;
  readonly de?: InputMaybe<IImagesSorting>;
  readonly en?: InputMaybe<IImagesSorting>;
  readonly es?: InputMaybe<IImagesSorting>;
  readonly eu?: InputMaybe<IImagesSorting>;
  readonly fr?: InputMaybe<IImagesSorting>;
  readonly gl?: InputMaybe<IImagesSorting>;
  readonly it?: InputMaybe<IImagesSorting>;
  readonly ko?: InputMaybe<IImagesSorting>;
  readonly ms?: InputMaybe<IImagesSorting>;
  readonly nl?: InputMaybe<IImagesSorting>;
  readonly pl?: InputMaybe<IImagesSorting>;
  readonly pt?: InputMaybe<IImagesSorting>;
  readonly ro?: InputMaybe<IImagesSorting>;
  readonly ta?: InputMaybe<IImagesSorting>;
  readonly th?: InputMaybe<IImagesSorting>;
  readonly tl?: InputMaybe<IImagesSorting>;
  readonly uk?: InputMaybe<IImagesSorting>;
  readonly zh?: InputMaybe<IImagesSorting>;
}

export interface ILocaleSlug {
  readonly __typename?: 'LocaleSlug';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<ISlug>;
  readonly ca: Maybe<ISlug>;
  readonly cs: Maybe<ISlug>;
  readonly de: Maybe<ISlug>;
  readonly en: Maybe<ISlug>;
  readonly es: Maybe<ISlug>;
  readonly eu: Maybe<ISlug>;
  readonly fr: Maybe<ISlug>;
  readonly gl: Maybe<ISlug>;
  readonly it: Maybe<ISlug>;
  readonly ko: Maybe<ISlug>;
  readonly ms: Maybe<ISlug>;
  readonly nl: Maybe<ISlug>;
  readonly pl: Maybe<ISlug>;
  readonly pt: Maybe<ISlug>;
  readonly ro: Maybe<ISlug>;
  readonly ta: Maybe<ISlug>;
  readonly th: Maybe<ISlug>;
  readonly tl: Maybe<ISlug>;
  readonly uk: Maybe<ISlug>;
  readonly zh: Maybe<ISlug>;
}

export interface ILocaleSlugFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<ISlugFilter>;
  readonly ca?: InputMaybe<ISlugFilter>;
  readonly cs?: InputMaybe<ISlugFilter>;
  readonly de?: InputMaybe<ISlugFilter>;
  readonly en?: InputMaybe<ISlugFilter>;
  readonly es?: InputMaybe<ISlugFilter>;
  readonly eu?: InputMaybe<ISlugFilter>;
  readonly fr?: InputMaybe<ISlugFilter>;
  readonly gl?: InputMaybe<ISlugFilter>;
  readonly it?: InputMaybe<ISlugFilter>;
  readonly ko?: InputMaybe<ISlugFilter>;
  readonly ms?: InputMaybe<ISlugFilter>;
  readonly nl?: InputMaybe<ISlugFilter>;
  readonly pl?: InputMaybe<ISlugFilter>;
  readonly pt?: InputMaybe<ISlugFilter>;
  readonly ro?: InputMaybe<ISlugFilter>;
  readonly ta?: InputMaybe<ISlugFilter>;
  readonly th?: InputMaybe<ISlugFilter>;
  readonly tl?: InputMaybe<ISlugFilter>;
  readonly uk?: InputMaybe<ISlugFilter>;
  readonly zh?: InputMaybe<ISlugFilter>;
}

export interface ILocaleSlugSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<ISlugSorting>;
  readonly ca?: InputMaybe<ISlugSorting>;
  readonly cs?: InputMaybe<ISlugSorting>;
  readonly de?: InputMaybe<ISlugSorting>;
  readonly en?: InputMaybe<ISlugSorting>;
  readonly es?: InputMaybe<ISlugSorting>;
  readonly eu?: InputMaybe<ISlugSorting>;
  readonly fr?: InputMaybe<ISlugSorting>;
  readonly gl?: InputMaybe<ISlugSorting>;
  readonly it?: InputMaybe<ISlugSorting>;
  readonly ko?: InputMaybe<ISlugSorting>;
  readonly ms?: InputMaybe<ISlugSorting>;
  readonly nl?: InputMaybe<ISlugSorting>;
  readonly pl?: InputMaybe<ISlugSorting>;
  readonly pt?: InputMaybe<ISlugSorting>;
  readonly ro?: InputMaybe<ISlugSorting>;
  readonly ta?: InputMaybe<ISlugSorting>;
  readonly th?: InputMaybe<ISlugSorting>;
  readonly tl?: InputMaybe<ISlugSorting>;
  readonly uk?: InputMaybe<ISlugSorting>;
  readonly zh?: InputMaybe<ISlugSorting>;
}

export interface ILocaleSmartBlockContent {
  readonly __typename?: 'LocaleSmartBlockContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly arRaw: Maybe<Scalars['JSON']['output']>;
  readonly caRaw: Maybe<Scalars['JSON']['output']>;
  readonly csRaw: Maybe<Scalars['JSON']['output']>;
  readonly deRaw: Maybe<Scalars['JSON']['output']>;
  readonly enRaw: Maybe<Scalars['JSON']['output']>;
  readonly esRaw: Maybe<Scalars['JSON']['output']>;
  readonly euRaw: Maybe<Scalars['JSON']['output']>;
  readonly frRaw: Maybe<Scalars['JSON']['output']>;
  readonly glRaw: Maybe<Scalars['JSON']['output']>;
  readonly itRaw: Maybe<Scalars['JSON']['output']>;
  readonly koRaw: Maybe<Scalars['JSON']['output']>;
  readonly msRaw: Maybe<Scalars['JSON']['output']>;
  readonly nlRaw: Maybe<Scalars['JSON']['output']>;
  readonly plRaw: Maybe<Scalars['JSON']['output']>;
  readonly ptRaw: Maybe<Scalars['JSON']['output']>;
  readonly roRaw: Maybe<Scalars['JSON']['output']>;
  readonly taRaw: Maybe<Scalars['JSON']['output']>;
  readonly thRaw: Maybe<Scalars['JSON']['output']>;
  readonly tlRaw: Maybe<Scalars['JSON']['output']>;
  readonly ukRaw: Maybe<Scalars['JSON']['output']>;
  readonly zhRaw: Maybe<Scalars['JSON']['output']>;
}

export interface ILocaleSmartBlockContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ILocaleSmartBlockContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ILocaleSmartTextField {
  readonly __typename?: 'LocaleSmartTextField';
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ar: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ca: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly cs: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly de: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly en: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly es: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly eu: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly fr: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly gl: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly it: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ko: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ms: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly nl: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly pl: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly pt: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ro: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly ta: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly th: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly tl: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly uk: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly zh: Maybe<Scalars['String']['output']>;
}

export interface ILocaleSmartTextFieldFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IStringFilter>;
  readonly ca?: InputMaybe<IStringFilter>;
  readonly cs?: InputMaybe<IStringFilter>;
  readonly de?: InputMaybe<IStringFilter>;
  readonly en?: InputMaybe<IStringFilter>;
  readonly es?: InputMaybe<IStringFilter>;
  readonly eu?: InputMaybe<IStringFilter>;
  readonly fr?: InputMaybe<IStringFilter>;
  readonly gl?: InputMaybe<IStringFilter>;
  readonly it?: InputMaybe<IStringFilter>;
  readonly ko?: InputMaybe<IStringFilter>;
  readonly ms?: InputMaybe<IStringFilter>;
  readonly nl?: InputMaybe<IStringFilter>;
  readonly pl?: InputMaybe<IStringFilter>;
  readonly pt?: InputMaybe<IStringFilter>;
  readonly ro?: InputMaybe<IStringFilter>;
  readonly ta?: InputMaybe<IStringFilter>;
  readonly th?: InputMaybe<IStringFilter>;
  readonly tl?: InputMaybe<IStringFilter>;
  readonly uk?: InputMaybe<IStringFilter>;
  readonly zh?: InputMaybe<IStringFilter>;
}

export interface ILocaleSmartTextFieldSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<SortOrder>;
  readonly ca?: InputMaybe<SortOrder>;
  readonly cs?: InputMaybe<SortOrder>;
  readonly de?: InputMaybe<SortOrder>;
  readonly en?: InputMaybe<SortOrder>;
  readonly es?: InputMaybe<SortOrder>;
  readonly eu?: InputMaybe<SortOrder>;
  readonly fr?: InputMaybe<SortOrder>;
  readonly gl?: InputMaybe<SortOrder>;
  readonly it?: InputMaybe<SortOrder>;
  readonly ko?: InputMaybe<SortOrder>;
  readonly ms?: InputMaybe<SortOrder>;
  readonly nl?: InputMaybe<SortOrder>;
  readonly pl?: InputMaybe<SortOrder>;
  readonly pt?: InputMaybe<SortOrder>;
  readonly ro?: InputMaybe<SortOrder>;
  readonly ta?: InputMaybe<SortOrder>;
  readonly th?: InputMaybe<SortOrder>;
  readonly tl?: InputMaybe<SortOrder>;
  readonly uk?: InputMaybe<SortOrder>;
  readonly zh?: InputMaybe<SortOrder>;
}

export interface ILocaleString {
  readonly __typename?: 'LocaleString';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<Scalars['String']['output']>;
  readonly ca: Maybe<Scalars['String']['output']>;
  readonly cs: Maybe<Scalars['String']['output']>;
  readonly de: Maybe<Scalars['String']['output']>;
  readonly en: Maybe<Scalars['String']['output']>;
  readonly es: Maybe<Scalars['String']['output']>;
  readonly eu: Maybe<Scalars['String']['output']>;
  readonly fr: Maybe<Scalars['String']['output']>;
  readonly gl: Maybe<Scalars['String']['output']>;
  readonly it: Maybe<Scalars['String']['output']>;
  readonly ko: Maybe<Scalars['String']['output']>;
  readonly ms: Maybe<Scalars['String']['output']>;
  readonly nl: Maybe<Scalars['String']['output']>;
  readonly pl: Maybe<Scalars['String']['output']>;
  readonly pt: Maybe<Scalars['String']['output']>;
  readonly ro: Maybe<Scalars['String']['output']>;
  readonly ta: Maybe<Scalars['String']['output']>;
  readonly th: Maybe<Scalars['String']['output']>;
  readonly tl: Maybe<Scalars['String']['output']>;
  readonly uk: Maybe<Scalars['String']['output']>;
  readonly zh: Maybe<Scalars['String']['output']>;
}

export interface ILocaleStringFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IStringFilter>;
  readonly ca?: InputMaybe<IStringFilter>;
  readonly cs?: InputMaybe<IStringFilter>;
  readonly de?: InputMaybe<IStringFilter>;
  readonly en?: InputMaybe<IStringFilter>;
  readonly es?: InputMaybe<IStringFilter>;
  readonly eu?: InputMaybe<IStringFilter>;
  readonly fr?: InputMaybe<IStringFilter>;
  readonly gl?: InputMaybe<IStringFilter>;
  readonly it?: InputMaybe<IStringFilter>;
  readonly ko?: InputMaybe<IStringFilter>;
  readonly ms?: InputMaybe<IStringFilter>;
  readonly nl?: InputMaybe<IStringFilter>;
  readonly pl?: InputMaybe<IStringFilter>;
  readonly pt?: InputMaybe<IStringFilter>;
  readonly ro?: InputMaybe<IStringFilter>;
  readonly ta?: InputMaybe<IStringFilter>;
  readonly th?: InputMaybe<IStringFilter>;
  readonly tl?: InputMaybe<IStringFilter>;
  readonly uk?: InputMaybe<IStringFilter>;
  readonly zh?: InputMaybe<IStringFilter>;
}

export interface ILocaleStringSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<SortOrder>;
  readonly ca?: InputMaybe<SortOrder>;
  readonly cs?: InputMaybe<SortOrder>;
  readonly de?: InputMaybe<SortOrder>;
  readonly en?: InputMaybe<SortOrder>;
  readonly es?: InputMaybe<SortOrder>;
  readonly eu?: InputMaybe<SortOrder>;
  readonly fr?: InputMaybe<SortOrder>;
  readonly gl?: InputMaybe<SortOrder>;
  readonly it?: InputMaybe<SortOrder>;
  readonly ko?: InputMaybe<SortOrder>;
  readonly ms?: InputMaybe<SortOrder>;
  readonly nl?: InputMaybe<SortOrder>;
  readonly pl?: InputMaybe<SortOrder>;
  readonly pt?: InputMaybe<SortOrder>;
  readonly ro?: InputMaybe<SortOrder>;
  readonly ta?: InputMaybe<SortOrder>;
  readonly th?: InputMaybe<SortOrder>;
  readonly tl?: InputMaybe<SortOrder>;
  readonly uk?: InputMaybe<SortOrder>;
  readonly zh?: InputMaybe<SortOrder>;
}

export interface ILocaleTypographyBlock {
  readonly __typename?: 'LocaleTypographyBlock';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly arRaw: Maybe<Scalars['JSON']['output']>;
  readonly caRaw: Maybe<Scalars['JSON']['output']>;
  readonly csRaw: Maybe<Scalars['JSON']['output']>;
  readonly deRaw: Maybe<Scalars['JSON']['output']>;
  readonly enRaw: Maybe<Scalars['JSON']['output']>;
  readonly esRaw: Maybe<Scalars['JSON']['output']>;
  readonly euRaw: Maybe<Scalars['JSON']['output']>;
  readonly frRaw: Maybe<Scalars['JSON']['output']>;
  readonly glRaw: Maybe<Scalars['JSON']['output']>;
  readonly itRaw: Maybe<Scalars['JSON']['output']>;
  readonly koRaw: Maybe<Scalars['JSON']['output']>;
  readonly msRaw: Maybe<Scalars['JSON']['output']>;
  readonly nlRaw: Maybe<Scalars['JSON']['output']>;
  readonly plRaw: Maybe<Scalars['JSON']['output']>;
  readonly ptRaw: Maybe<Scalars['JSON']['output']>;
  readonly roRaw: Maybe<Scalars['JSON']['output']>;
  readonly taRaw: Maybe<Scalars['JSON']['output']>;
  readonly thRaw: Maybe<Scalars['JSON']['output']>;
  readonly tlRaw: Maybe<Scalars['JSON']['output']>;
  readonly ukRaw: Maybe<Scalars['JSON']['output']>;
  readonly zhRaw: Maybe<Scalars['JSON']['output']>;
}

export interface ILocaleTypographyBlockFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ILocaleTypographyBlockSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ILocaleUrl {
  readonly __typename?: 'LocaleUrl';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ar: Maybe<Scalars['String']['output']>;
  readonly ca: Maybe<Scalars['String']['output']>;
  readonly cs: Maybe<Scalars['String']['output']>;
  readonly de: Maybe<Scalars['String']['output']>;
  readonly en: Maybe<Scalars['String']['output']>;
  readonly es: Maybe<Scalars['String']['output']>;
  readonly eu: Maybe<Scalars['String']['output']>;
  readonly fr: Maybe<Scalars['String']['output']>;
  readonly gl: Maybe<Scalars['String']['output']>;
  readonly it: Maybe<Scalars['String']['output']>;
  readonly ko: Maybe<Scalars['String']['output']>;
  readonly ms: Maybe<Scalars['String']['output']>;
  readonly nl: Maybe<Scalars['String']['output']>;
  readonly pl: Maybe<Scalars['String']['output']>;
  readonly pt: Maybe<Scalars['String']['output']>;
  readonly ro: Maybe<Scalars['String']['output']>;
  readonly ta: Maybe<Scalars['String']['output']>;
  readonly th: Maybe<Scalars['String']['output']>;
  readonly tl: Maybe<Scalars['String']['output']>;
  readonly uk: Maybe<Scalars['String']['output']>;
  readonly zh: Maybe<Scalars['String']['output']>;
}

export interface ILocaleUrlFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ar?: InputMaybe<IStringFilter>;
  readonly ca?: InputMaybe<IStringFilter>;
  readonly cs?: InputMaybe<IStringFilter>;
  readonly de?: InputMaybe<IStringFilter>;
  readonly en?: InputMaybe<IStringFilter>;
  readonly es?: InputMaybe<IStringFilter>;
  readonly eu?: InputMaybe<IStringFilter>;
  readonly fr?: InputMaybe<IStringFilter>;
  readonly gl?: InputMaybe<IStringFilter>;
  readonly it?: InputMaybe<IStringFilter>;
  readonly ko?: InputMaybe<IStringFilter>;
  readonly ms?: InputMaybe<IStringFilter>;
  readonly nl?: InputMaybe<IStringFilter>;
  readonly pl?: InputMaybe<IStringFilter>;
  readonly pt?: InputMaybe<IStringFilter>;
  readonly ro?: InputMaybe<IStringFilter>;
  readonly ta?: InputMaybe<IStringFilter>;
  readonly th?: InputMaybe<IStringFilter>;
  readonly tl?: InputMaybe<IStringFilter>;
  readonly uk?: InputMaybe<IStringFilter>;
  readonly zh?: InputMaybe<IStringFilter>;
}

export interface ILocaleUrlSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ar?: InputMaybe<SortOrder>;
  readonly ca?: InputMaybe<SortOrder>;
  readonly cs?: InputMaybe<SortOrder>;
  readonly de?: InputMaybe<SortOrder>;
  readonly en?: InputMaybe<SortOrder>;
  readonly es?: InputMaybe<SortOrder>;
  readonly eu?: InputMaybe<SortOrder>;
  readonly fr?: InputMaybe<SortOrder>;
  readonly gl?: InputMaybe<SortOrder>;
  readonly it?: InputMaybe<SortOrder>;
  readonly ko?: InputMaybe<SortOrder>;
  readonly ms?: InputMaybe<SortOrder>;
  readonly nl?: InputMaybe<SortOrder>;
  readonly pl?: InputMaybe<SortOrder>;
  readonly pt?: InputMaybe<SortOrder>;
  readonly ro?: InputMaybe<SortOrder>;
  readonly ta?: InputMaybe<SortOrder>;
  readonly th?: InputMaybe<SortOrder>;
  readonly tl?: InputMaybe<SortOrder>;
  readonly uk?: InputMaybe<SortOrder>;
  readonly zh?: InputMaybe<SortOrder>;
}

export interface ILockedOffers extends IDocument {
  readonly __typename?: 'LockedOffers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface ILockedOffersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface ILockedOffersPanel {
  readonly __typename?: 'LockedOffersPanel';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly completedChallengeDescription: Maybe<ILocaleBlockContent>;
  readonly completedChallengeHeader: Maybe<ILocaleBlockContent>;
}

export interface ILockedOffersPanelFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly completedChallengeDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly completedChallengeHeader?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface ILockedOffersPanelSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly completedChallengeDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly completedChallengeHeader?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface ILockedOffersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface ILogsConfig {
  readonly __typename?: 'LogsConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly clientToken: Maybe<Scalars['String']['output']>;
  readonly dataCenter: Maybe<Scalars['String']['output']>;
}

export interface ILogsConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly clientToken?: InputMaybe<IStringFilter>;
  readonly dataCenter?: InputMaybe<IStringFilter>;
}

export interface ILogsConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly clientToken?: InputMaybe<SortOrder>;
  readonly dataCenter?: InputMaybe<SortOrder>;
}

export interface ILoyaltyBannerWidget {
  readonly __typename?: 'LoyaltyBannerWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  readonly unauthenticatedDesktopBackgroundImage: Maybe<ILocaleImage>;
  readonly unauthenticatedMobileBackgroundImage: Maybe<ILocaleImage>;
}

export interface ILoyaltyBannerWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
  readonly unauthenticatedDesktopBackgroundImage?: InputMaybe<ILocaleImageFilter>;
  readonly unauthenticatedMobileBackgroundImage?: InputMaybe<ILocaleImageFilter>;
}

export interface ILoyaltyBannerWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly enabled?: InputMaybe<SortOrder>;
  readonly unauthenticatedDesktopBackgroundImage?: InputMaybe<ILocaleImageSorting>;
  readonly unauthenticatedMobileBackgroundImage?: InputMaybe<ILocaleImageSorting>;
}

export interface ILoyaltyBasicCardWidget {
  readonly __typename?: 'LoyaltyBasicCardWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly linkedCard: Maybe<IMarketingTileBasic>;
  readonly unlinkedCard: Maybe<IMarketingTileBasic>;
}

export interface ILoyaltyBasicCardWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly linkedCard?: InputMaybe<IMarketingTileBasicFilter>;
  readonly unlinkedCard?: InputMaybe<IMarketingTileBasicFilter>;
}

export type ILoyaltyBasicCardWidgetOrLoyaltyButtonArrayWidgetOrLoyaltyButtonWidgetOrLoyaltyCartWidgetOrLoyaltyImageWidgetOrLoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyPaymentMethodWidgetOrLoyaltyQrAndShortCodeWidgetOrLoyaltyQrCodeLoyaltyIdWidgetOrLoyaltyQrCodeWidgetOrLoyaltyQrScanButtonWidgetOrLoyaltyQrScanHeaderWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidgetOrLoyaltyTitleWidget =

    | ILoyaltyBasicCardWidget
    | ILoyaltyButtonArrayWidget
    | ILoyaltyButtonWidget
    | ILoyaltyCartWidget
    | ILoyaltyImageWidget
    | ILoyaltyLegacyShortCodeWidget
    | ILoyaltyMarketingTileGroupWidget
    | ILoyaltyPaymentMethodWidget
    | ILoyaltyQrAndShortCodeWidget
    | ILoyaltyQrCodeLoyaltyIdWidget
    | ILoyaltyQrCodeWidget
    | ILoyaltyQrScanButtonWidget
    | ILoyaltyQrScanHeaderWidget
    | ILoyaltyShortCodeWidget
    | ILoyaltyTextWidget
    | ILoyaltyTitleWidget;

export interface ILoyaltyBasicCardWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
}

export interface ILoyaltyBetweenDates {
  readonly __typename?: 'LoyaltyBetweenDates';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Ends on... (Time will be set to 23:59:59 and reflect based on user's local time) */
  readonly endDate: Maybe<Scalars['Date']['output']>;
  /** Starts on... (Time will be set to 00:00:00 and reflect based on user’s local time) */
  readonly startDate: Maybe<Scalars['Date']['output']>;
}

export interface ILoyaltyBetweenDatesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endDate?: InputMaybe<IDateFilter>;
  readonly startDate?: InputMaybe<IDateFilter>;
}

export interface ILoyaltyBetweenDatesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endDate?: InputMaybe<SortOrder>;
  readonly startDate?: InputMaybe<SortOrder>;
}

export interface ILoyaltyBetweenTimes {
  readonly __typename?: 'LoyaltyBetweenTimes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Time will be converted from your local time to UTC */
  readonly endTime: Maybe<Scalars['DateTime']['output']>;
  /** Time will be converted from your local time to UTC */
  readonly startTime: Maybe<Scalars['DateTime']['output']>;
}

export interface ILoyaltyBetweenTimesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endTime?: InputMaybe<IDatetimeFilter>;
  readonly startTime?: InputMaybe<IDatetimeFilter>;
}

export interface ILoyaltyBetweenTimesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endTime?: InputMaybe<SortOrder>;
  readonly startTime?: InputMaybe<SortOrder>;
}

export interface ILoyaltyButtonArrayWidget {
  readonly __typename?: 'LoyaltyButtonArrayWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttons: Maybe<ReadonlyArray<Maybe<ILoyaltyButtonLinkTextWidget>>>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyButtonArrayWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyButtonArrayWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
}

export interface ILoyaltyButtonLinkTextWidget {
  readonly __typename?: 'LoyaltyButtonLinkTextWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<ILinkWidget>;
  readonly type: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyButtonLinkTextWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<ILinkWidgetFilter>;
  readonly type?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyButtonLinkTextWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILinkWidgetSorting>;
  readonly type?: InputMaybe<SortOrder>;
}

export interface ILoyaltyButtonWidget {
  readonly __typename?: 'LoyaltyButtonWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly link: Maybe<ILinkWidget>;
}

export interface ILoyaltyButtonWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly link?: InputMaybe<ILinkWidgetFilter>;
}

export interface ILoyaltyButtonWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILinkWidgetSorting>;
}

export interface ILoyaltyCtaButtonAuthenticated {
  readonly __typename?: 'LoyaltyCTAButtonAuthenticated';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyBannerHomepageAuthenticatedLabel: Maybe<ILocaleString>;
  readonly loyaltyBannerHomepageAuthenticatedLink: Maybe<ILocaleString>;
}

export interface ILoyaltyCtaButtonAuthenticatedFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyBannerHomepageAuthenticatedLabel?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyBannerHomepageAuthenticatedLink?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyCtaButtonAuthenticatedSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyBannerHomepageAuthenticatedLabel?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyBannerHomepageAuthenticatedLink?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyCtaButtonRewards {
  readonly __typename?: 'LoyaltyCTAButtonRewards';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyBannerRewardsLabel: Maybe<ILocaleString>;
  readonly loyaltyBannerRewardsLink: Maybe<ILocaleString>;
}

export interface ILoyaltyCtaButtonRewardsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyBannerRewardsLabel?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyBannerRewardsLink?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyCtaButtonRewardsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyBannerRewardsLabel?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyBannerRewardsLink?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyCtaButtonUnauthenticated {
  readonly __typename?: 'LoyaltyCTAButtonUnauthenticated';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyBannerHomepageUnauthenticatedLabel: Maybe<ILocaleString>;
  readonly loyaltyBannerHomepageUnauthenticatedLink: Maybe<ILocaleString>;
}

export interface ILoyaltyCtaButtonUnauthenticatedFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyBannerHomepageUnauthenticatedLabel?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyBannerHomepageUnauthenticatedLink?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyCtaButtonUnauthenticatedSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyBannerHomepageUnauthenticatedLabel?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyBannerHomepageUnauthenticatedLink?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyCtaButtons {
  readonly __typename?: 'LoyaltyCTAButtons';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyBannerHomepageAuthenticatedButton: Maybe<ILoyaltyCtaButtonAuthenticated>;
  readonly loyaltyBannerHomepageUnauthenticatedButton: Maybe<ILoyaltyCtaButtonUnauthenticated>;
  readonly loyaltyBannerRewardsButton: Maybe<ILoyaltyCtaButtonRewards>;
}

export interface ILoyaltyCtaButtonsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyBannerHomepageAuthenticatedButton?: InputMaybe<ILoyaltyCtaButtonAuthenticatedFilter>;
  readonly loyaltyBannerHomepageUnauthenticatedButton?: InputMaybe<ILoyaltyCtaButtonUnauthenticatedFilter>;
  readonly loyaltyBannerRewardsButton?: InputMaybe<ILoyaltyCtaButtonRewardsFilter>;
}

export interface ILoyaltyCtaButtonsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyBannerHomepageAuthenticatedButton?: InputMaybe<ILoyaltyCtaButtonAuthenticatedSorting>;
  readonly loyaltyBannerHomepageUnauthenticatedButton?: InputMaybe<ILoyaltyCtaButtonUnauthenticatedSorting>;
  readonly loyaltyBannerRewardsButton?: InputMaybe<ILoyaltyCtaButtonRewardsSorting>;
}

export interface ILoyaltyCartWidget {
  readonly __typename?: 'LoyaltyCartWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  /** Marketing Tile to show in case the In Store cart is empty */
  readonly loyaltyEmptyCartTileWidget: Maybe<IMarketingTileBasic>;
}

export interface ILoyaltyCartWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly loyaltyEmptyCartTileWidget?: InputMaybe<IMarketingTileBasicFilter>;
}

export interface ILoyaltyCartWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
}

export interface ILoyaltyContent extends IDocument {
  readonly __typename?: 'LoyaltyContent';
  /**
   * Date the document was created
   * @deprecated This has been deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This has been deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This has been deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This has been deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This has been deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This has been deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly addCardHeaderText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly addCardImage: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly addCardSkipText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly addCardTextBlock: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly birthdayLegalText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsIconFour: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsIconOne: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsIconThree: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsIconTwo: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsInstructionsFour: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsInstructionsOne: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsInstructionsThree: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsInstructionsTwo: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsPageHeroImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsPageHeroImageMobile: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsPageHeroImageWeb: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly detailsSetupPageSubtext: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsSetupPageText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly detailsTabName: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly historyPageSubtext: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly historyPageText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly historyTabName: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly lostCardSectionBlockText: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly lostCardSectionTitle: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly loyaltyCardSectionText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly loyaltyCardSectionTitle: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly loyaltyProgramInstructionsBlockText: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly mainHeroLoyaltyImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly mainHeroLoyaltySubtext: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly mainHeroLoyaltyText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly name: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly newPointsDisclaimerTextBlock: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly newPointsImage: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly newPointsTextBlockBottom: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly newPointsTextBlockTop: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly onboardingBackgroundImage: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly onboardingButtonLink: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly onboardingButtonText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly onboardingContentCardBackgroundImage: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly onboardingImage: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly onboardingTextBlock: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly pointsUpdateText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly registerCardImage: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly registerCardSectionBlockText: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly registerCardSectionTitle: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly rewardSetupPageBlockText: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly rewardSetupPageSubtext: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly rewardSetupPageText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly rewardSetupTabName: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly rewardsUnavailableBlockText: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly seeMoreOptionsText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly tierCardTextBlock: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly tierHeaderText: Maybe<ILocaleString>;
}

export interface ILoyaltyContentFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly addCardHeaderText?: InputMaybe<ILocaleStringFilter>;
  readonly addCardImage?: InputMaybe<ILocaleImagesFilter>;
  readonly addCardSkipText?: InputMaybe<ILocaleStringFilter>;
  readonly addCardTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly birthdayLegalText?: InputMaybe<ILocaleStringFilter>;
  readonly detailsIconFour?: InputMaybe<ILocaleImageFilter>;
  readonly detailsIconOne?: InputMaybe<ILocaleImageFilter>;
  readonly detailsIconThree?: InputMaybe<ILocaleImageFilter>;
  readonly detailsIconTwo?: InputMaybe<ILocaleImageFilter>;
  readonly detailsInstructionsFour?: InputMaybe<ILocaleStringFilter>;
  readonly detailsInstructionsOne?: InputMaybe<ILocaleStringFilter>;
  readonly detailsInstructionsThree?: InputMaybe<ILocaleStringFilter>;
  readonly detailsInstructionsTwo?: InputMaybe<ILocaleStringFilter>;
  readonly detailsPageHeroImage?: InputMaybe<ILocaleImageFilter>;
  readonly detailsPageHeroImageMobile?: InputMaybe<ILocaleImageFilter>;
  readonly detailsPageHeroImageWeb?: InputMaybe<ILocaleImageFilter>;
  readonly detailsSetupPageSubtext?: InputMaybe<ILocaleStringFilter>;
  readonly detailsSetupPageText?: InputMaybe<ILocaleStringFilter>;
  readonly detailsTabName?: InputMaybe<ILocaleStringFilter>;
  readonly historyPageSubtext?: InputMaybe<ILocaleStringFilter>;
  readonly historyPageText?: InputMaybe<ILocaleStringFilter>;
  readonly historyTabName?: InputMaybe<ILocaleStringFilter>;
  readonly lostCardSectionBlockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly lostCardSectionTitle?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyCardSectionText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyCardSectionTitle?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyProgramInstructionsBlockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly mainHeroLoyaltyImage?: InputMaybe<ILocaleImageFilter>;
  readonly mainHeroLoyaltySubtext?: InputMaybe<ILocaleStringFilter>;
  readonly mainHeroLoyaltyText?: InputMaybe<ILocaleStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly newPointsDisclaimerTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly newPointsImage?: InputMaybe<ILocaleImagesFilter>;
  readonly newPointsTextBlockBottom?: InputMaybe<ILocaleBlockContentFilter>;
  readonly newPointsTextBlockTop?: InputMaybe<ILocaleBlockContentFilter>;
  readonly onboardingBackgroundImage?: InputMaybe<ILocaleImagesFilter>;
  readonly onboardingButtonLink?: InputMaybe<IStringFilter>;
  readonly onboardingButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly onboardingContentCardBackgroundImage?: InputMaybe<ILocaleImagesFilter>;
  readonly onboardingImage?: InputMaybe<ILocaleImagesFilter>;
  readonly onboardingTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly pointsUpdateText?: InputMaybe<ILocaleStringFilter>;
  readonly registerCardImage?: InputMaybe<ILocaleImageFilter>;
  readonly registerCardSectionBlockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly registerCardSectionTitle?: InputMaybe<ILocaleStringFilter>;
  readonly rewardSetupPageBlockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly rewardSetupPageSubtext?: InputMaybe<ILocaleStringFilter>;
  readonly rewardSetupPageText?: InputMaybe<ILocaleStringFilter>;
  readonly rewardSetupTabName?: InputMaybe<ILocaleStringFilter>;
  readonly rewardsUnavailableBlockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly seeMoreOptionsText?: InputMaybe<ILocaleStringFilter>;
  readonly tierCardTextBlock?: InputMaybe<ILocaleBlockContentFilter>;
  readonly tierHeaderText?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyContentSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly addCardHeaderText?: InputMaybe<ILocaleStringSorting>;
  readonly addCardImage?: InputMaybe<ILocaleImagesSorting>;
  readonly addCardSkipText?: InputMaybe<ILocaleStringSorting>;
  readonly addCardTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly birthdayLegalText?: InputMaybe<ILocaleStringSorting>;
  readonly detailsIconFour?: InputMaybe<ILocaleImageSorting>;
  readonly detailsIconOne?: InputMaybe<ILocaleImageSorting>;
  readonly detailsIconThree?: InputMaybe<ILocaleImageSorting>;
  readonly detailsIconTwo?: InputMaybe<ILocaleImageSorting>;
  readonly detailsInstructionsFour?: InputMaybe<ILocaleStringSorting>;
  readonly detailsInstructionsOne?: InputMaybe<ILocaleStringSorting>;
  readonly detailsInstructionsThree?: InputMaybe<ILocaleStringSorting>;
  readonly detailsInstructionsTwo?: InputMaybe<ILocaleStringSorting>;
  readonly detailsPageHeroImage?: InputMaybe<ILocaleImageSorting>;
  readonly detailsPageHeroImageMobile?: InputMaybe<ILocaleImageSorting>;
  readonly detailsPageHeroImageWeb?: InputMaybe<ILocaleImageSorting>;
  readonly detailsSetupPageSubtext?: InputMaybe<ILocaleStringSorting>;
  readonly detailsSetupPageText?: InputMaybe<ILocaleStringSorting>;
  readonly detailsTabName?: InputMaybe<ILocaleStringSorting>;
  readonly historyPageSubtext?: InputMaybe<ILocaleStringSorting>;
  readonly historyPageText?: InputMaybe<ILocaleStringSorting>;
  readonly historyTabName?: InputMaybe<ILocaleStringSorting>;
  readonly lostCardSectionBlockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly lostCardSectionTitle?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyCardSectionText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyCardSectionTitle?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyProgramInstructionsBlockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly mainHeroLoyaltyImage?: InputMaybe<ILocaleImageSorting>;
  readonly mainHeroLoyaltySubtext?: InputMaybe<ILocaleStringSorting>;
  readonly mainHeroLoyaltyText?: InputMaybe<ILocaleStringSorting>;
  readonly name?: InputMaybe<SortOrder>;
  readonly newPointsDisclaimerTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly newPointsImage?: InputMaybe<ILocaleImagesSorting>;
  readonly newPointsTextBlockBottom?: InputMaybe<ILocaleBlockContentSorting>;
  readonly newPointsTextBlockTop?: InputMaybe<ILocaleBlockContentSorting>;
  readonly onboardingBackgroundImage?: InputMaybe<ILocaleImagesSorting>;
  readonly onboardingButtonLink?: InputMaybe<SortOrder>;
  readonly onboardingButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly onboardingContentCardBackgroundImage?: InputMaybe<ILocaleImagesSorting>;
  readonly onboardingImage?: InputMaybe<ILocaleImagesSorting>;
  readonly onboardingTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly pointsUpdateText?: InputMaybe<ILocaleStringSorting>;
  readonly registerCardImage?: InputMaybe<ILocaleImageSorting>;
  readonly registerCardSectionBlockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly registerCardSectionTitle?: InputMaybe<ILocaleStringSorting>;
  readonly rewardSetupPageBlockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly rewardSetupPageSubtext?: InputMaybe<ILocaleStringSorting>;
  readonly rewardSetupPageText?: InputMaybe<ILocaleStringSorting>;
  readonly rewardSetupTabName?: InputMaybe<ILocaleStringSorting>;
  readonly rewardsUnavailableBlockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly seeMoreOptionsText?: InputMaybe<ILocaleStringSorting>;
  readonly tierCardTextBlock?: InputMaybe<ILocaleBlockContentSorting>;
  readonly tierHeaderText?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyDashboard {
  readonly __typename?: 'LoyaltyDashboard';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly widgets: Maybe<
    ReadonlyArray<
      Maybe<IFeaturedRewardsCarouselWidgetOrLoyaltyDoubleButtonWidgetOrLoyaltyGreetingWidgetOrLoyaltyHistoryWidgetOrLoyaltyLinkWidgetOrLoyaltyLogoWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyNotificationsWidgetOrLoyaltyPointsAndRewardsWidgetOrLoyaltyPointsMeterWidgetOrLoyaltyQuestsTileWidgetOrLoyaltyQuestsWidgetOrLoyaltyTiersWidgetOrRewardsListWidget>
    >
  >;
}

export interface ILoyaltyDashboardFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyDashboardSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ILoyaltyDoubleButtonWidget {
  readonly __typename?: 'LoyaltyDoubleButtonWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Double Button Widget */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly leftButtonPath: Maybe<ILocaleString>;
  readonly leftButtonText: Maybe<ILocaleString>;
  readonly rightButtonPath: Maybe<ILocaleString>;
  readonly rightButtonText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyDoubleButtonWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly leftButtonPath?: InputMaybe<ILocaleStringFilter>;
  readonly leftButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly rightButtonPath?: InputMaybe<ILocaleStringFilter>;
  readonly rightButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyDoubleButtonWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly leftButtonPath?: InputMaybe<ILocaleStringSorting>;
  readonly leftButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly rightButtonPath?: InputMaybe<ILocaleStringSorting>;
  readonly rightButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyGreetingWidget {
  readonly __typename?: 'LoyaltyGreetingWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Greeting widget i.e. "Good Afternoon, John". */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyGreetingWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyGreetingWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyHistoryWidget {
  readonly __typename?: 'LoyaltyHistoryWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays loyalty transaction history. */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyHistoryWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyHistoryWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyImageWidget {
  readonly __typename?: 'LoyaltyImageWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<ILocaleImage>;
}

export interface ILoyaltyImageWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
}

export interface ILoyaltyImageWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
}

export interface ILoyaltyInRestaurantRedemption {
  readonly __typename?: 'LoyaltyInRestaurantRedemption';
  /** @deprecated This has been deprecated. Replaced by `configRedeemInRestaurantWidgets` */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. Replaced by `configRedeemInRestaurantWidgets` */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. Replaced by `configRedeemInRestaurantWidgets` */
  readonly bottomWidgets: Maybe<
    ReadonlyArray<
      Maybe<ILoyaltyBasicCardWidgetOrLoyaltyButtonArrayWidgetOrLoyaltyButtonWidgetOrLoyaltyCartWidgetOrLoyaltyImageWidgetOrLoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyPaymentMethodWidgetOrLoyaltyQrAndShortCodeWidgetOrLoyaltyQrCodeLoyaltyIdWidgetOrLoyaltyQrCodeWidgetOrLoyaltyQrScanButtonWidgetOrLoyaltyQrScanHeaderWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidgetOrLoyaltyTitleWidget>
    >
  >;
  /** @deprecated This has been deprecated. Replaced by `configRedeemInRestaurantWidgets` */
  readonly topWidgets: Maybe<
    ReadonlyArray<
      Maybe<ILoyaltyBasicCardWidgetOrLoyaltyButtonArrayWidgetOrLoyaltyButtonWidgetOrLoyaltyCartWidgetOrLoyaltyImageWidgetOrLoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyPaymentMethodWidgetOrLoyaltyQrAndShortCodeWidgetOrLoyaltyQrCodeLoyaltyIdWidgetOrLoyaltyQrCodeWidgetOrLoyaltyQrScanButtonWidgetOrLoyaltyQrScanHeaderWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidgetOrLoyaltyTitleWidget>
    >
  >;
}

export interface ILoyaltyInRestaurantRedemptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyInRestaurantRedemptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ILoyaltyLegacyShortCodeWidget {
  readonly __typename?: 'LoyaltyLegacyShortCodeWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly instructions: Maybe<ILocaleString>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyLegacyShortCodeWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly instructions?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export type ILoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyQrAndShortCodeWidgetOrLoyaltyQrCodeWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidget =

    | ILoyaltyLegacyShortCodeWidget
    | ILoyaltyMarketingTileGroupWidget
    | ILoyaltyQrAndShortCodeWidget
    | ILoyaltyQrCodeWidget
    | ILoyaltyShortCodeWidget
    | ILoyaltyTextWidget;

export interface ILoyaltyLegacyShortCodeWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly instructions?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyLinkWidget {
  readonly __typename?: 'LoyaltyLinkWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly links: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyLinkWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyLinkWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyLiveExternalOffers extends IDocument {
  readonly __typename?: 'LoyaltyLiveExternalOffers';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly externalOffersEmptyState: Maybe<IExternalOffersEmptyStateContent>;
  readonly liveExternalOffers: Maybe<ReadonlyArray<Maybe<IExternalOffers>>>;
}

export interface ILoyaltyLiveExternalOffersFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly externalOffersEmptyState?: InputMaybe<IExternalOffersEmptyStateContentFilter>;
}

export interface ILoyaltyLiveExternalOffersSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly externalOffersEmptyState?: InputMaybe<IExternalOffersEmptyStateContentSorting>;
}

export interface ILoyaltyLogoWidget {
  readonly __typename?: 'LoyaltyLogoWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyLogoWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyLogoWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
}

export interface ILoyaltyMarketingTileGroupWidget {
  readonly __typename?: 'LoyaltyMarketingTileGroupWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Marketing Tile Group widget */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly marketingTileGroup: Maybe<IMarketingTileGroup>;
  readonly marketingTileWidgetDisclaimer: Maybe<ILocaleBlockContent>;
  readonly marketingTileWidgetTitle: Maybe<ILocaleString>;
}

export interface ILoyaltyMarketingTileGroupWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly marketingTileGroup?: InputMaybe<IMarketingTileGroupFilter>;
  readonly marketingTileWidgetDisclaimer?: InputMaybe<ILocaleBlockContentFilter>;
  readonly marketingTileWidgetTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyMarketingTileGroupWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly marketingTileWidgetDisclaimer?: InputMaybe<ILocaleBlockContentSorting>;
  readonly marketingTileWidgetTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyNotAuthenticated {
  readonly __typename?: 'LoyaltyNotAuthenticated';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltySignupButtonText: Maybe<ILocaleString>;
  readonly loyaltySignupCells: Maybe<ReadonlyArray<Maybe<ILoyaltyNotAuthenticatedCell>>>;
  readonly loyaltySignupLegalText: Maybe<ILocaleString>;
  readonly loyaltySignupTitle: Maybe<ILocaleString>;
}

export interface ILoyaltyNotAuthenticatedCell {
  readonly __typename?: 'LoyaltyNotAuthenticatedCell';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltySignupCellDescription: Maybe<ILocaleString>;
  readonly loyaltySignupCellImage: Maybe<ILocaleImage>;
  readonly loyaltySignupCellTitle: Maybe<ILocaleString>;
}

export interface ILoyaltyNotAuthenticatedCellFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltySignupCellDescription?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltySignupCellImage?: InputMaybe<ILocaleImageFilter>;
  readonly loyaltySignupCellTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyNotAuthenticatedCellSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltySignupCellDescription?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltySignupCellImage?: InputMaybe<ILocaleImageSorting>;
  readonly loyaltySignupCellTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyNotAuthenticatedFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltySignupButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltySignupLegalText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltySignupTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyNotAuthenticatedSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltySignupButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltySignupLegalText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltySignupTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyNotAvailableAtDeliveryMode {
  readonly __typename?: 'LoyaltyNotAvailableAtDeliveryMode';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly firstButtonText: Maybe<ILocaleString>;
  readonly firstRedirectPath: Maybe<ILocaleUrl>;
  readonly image: Maybe<ILocaleImages>;
  /** @deprecated This has been deprecated */
  readonly needHelpBlockContent: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly needHelpSectionTitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyNotAvailableAtDeliveryModeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly firstButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly firstRedirectPath?: InputMaybe<ILocaleUrlFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly needHelpBlockContent?: InputMaybe<ILocaleBlockContentFilter>;
  readonly needHelpSectionTitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyNotAvailableAtDeliveryModeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly firstButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly firstRedirectPath?: InputMaybe<ILocaleUrlSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly needHelpBlockContent?: InputMaybe<ILocaleBlockContentSorting>;
  readonly needHelpSectionTitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyNotAvailableAtThisRestaurant {
  readonly __typename?: 'LoyaltyNotAvailableAtThisRestaurant';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly firstButtonText: Maybe<ILocaleString>;
  readonly firstRedirectPath: Maybe<ILocaleUrl>;
  readonly image: Maybe<ILocaleImages>;
  readonly secondButtonText: Maybe<ILocaleString>;
  readonly secondRedirectPath: Maybe<ILocaleUrl>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyNotAvailableAtThisRestaurantFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly firstButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly firstRedirectPath?: InputMaybe<ILocaleUrlFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly secondButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly secondRedirectPath?: InputMaybe<ILocaleUrlFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyNotAvailableAtThisRestaurantSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly firstButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly firstRedirectPath?: InputMaybe<ILocaleUrlSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly secondButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly secondRedirectPath?: InputMaybe<ILocaleUrlSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyNotificationsWidget {
  readonly __typename?: 'LoyaltyNotificationsWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays a loyalty notifications widget */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly notifications: Maybe<
    ReadonlyArray<Maybe<IRewardOrSystemwideOfferOrSystemwidePromotion>>
  >;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyNotificationsWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyNotificationsWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyOffersPageMarketingTile {
  readonly __typename?: 'LoyaltyOffersPageMarketingTile';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** (select "custom" from the display position above to use this field). The number chosen will correspond to its position in the offers pannel. 2 is the second slot at the top, higher numbers means lower position. */
  readonly customPosition: Maybe<Scalars['Float']['output']>;
  /** Determines the position in the offers panel that the marketing tile will appear. */
  readonly displayPosition: Maybe<Scalars['String']['output']>;
  readonly marketingTileReference: Maybe<IMarketingTile>;
}

export interface ILoyaltyOffersPageMarketingTileFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly customPosition?: InputMaybe<IFloatFilter>;
  readonly displayPosition?: InputMaybe<IStringFilter>;
  readonly marketingTileReference?: InputMaybe<IMarketingTileFilter>;
}

export interface ILoyaltyOffersPageMarketingTileSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly customPosition?: InputMaybe<SortOrder>;
  readonly displayPosition?: InputMaybe<SortOrder>;
}

export interface ILoyaltyOffersUi extends IDocument {
  readonly __typename?: 'LoyaltyOffersUI';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** When users are signed out, offers that are only available for signed in users will show as greyed out with a lock */
  readonly enableGreyedOutOffers: Maybe<Scalars['Boolean']['output']>;
  /** When true, a timer will be shown in the offer, according the hours defined in the Loyalty Between-Dates rule */
  readonly enableOfferBadges: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This has been deprecated */
  readonly endOfOffersLoggedIn: Maybe<IEndOfOffersLoggedIn>;
  /** @deprecated This has been deprecated */
  readonly endOfOffersLoggedOut: Maybe<IEndOfOffersLoggedOut>;
  readonly globalOfferCooldownContent: Maybe<IGlobalOfferCooldownContent>;
  readonly liveConfigOffers: Maybe<ReadonlyArray<Maybe<IConfigOffer>>>;
  readonly loyaltyOffersPageMarketingTiles: Maybe<
    ReadonlyArray<Maybe<ILoyaltyOffersPageMarketingTile>>
  >;
  /** @deprecated This has been deprecated */
  readonly offerNotAvailableModalContent: Maybe<IIncentiveNotAvailableModalContent>;
  /**
   * The templateId selected below will be the template Id associated with all redeemed offers using Promo Codes
   * @deprecated This has been deprecated
   */
  readonly offerPromotionTemplate: Maybe<IOfferTemplate>;
  readonly offersEmptyStates: Maybe<IOffersEmptyStates>;
  /** @deprecated This has been deprecated */
  readonly rewardNotAvailableModalContent: Maybe<IIncentiveNotAvailableModalContent>;
  readonly sortedSystemwideOffers: Maybe<ReadonlyArray<Maybe<ISystemwideOffer>>>;
}

export interface ILoyaltyOffersUiFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly enableGreyedOutOffers?: InputMaybe<IBooleanFilter>;
  readonly enableOfferBadges?: InputMaybe<IBooleanFilter>;
  readonly endOfOffersLoggedIn?: InputMaybe<IEndOfOffersLoggedInFilter>;
  readonly endOfOffersLoggedOut?: InputMaybe<IEndOfOffersLoggedOutFilter>;
  readonly globalOfferCooldownContent?: InputMaybe<IGlobalOfferCooldownContentFilter>;
  readonly offerNotAvailableModalContent?: InputMaybe<IIncentiveNotAvailableModalContentFilter>;
  readonly offerPromotionTemplate?: InputMaybe<IOfferTemplateFilter>;
  readonly offersEmptyStates?: InputMaybe<IOffersEmptyStatesFilter>;
  readonly rewardNotAvailableModalContent?: InputMaybe<IIncentiveNotAvailableModalContentFilter>;
}

export interface ILoyaltyOffersUiSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly enableGreyedOutOffers?: InputMaybe<SortOrder>;
  readonly enableOfferBadges?: InputMaybe<SortOrder>;
  readonly endOfOffersLoggedIn?: InputMaybe<IEndOfOffersLoggedInSorting>;
  readonly endOfOffersLoggedOut?: InputMaybe<IEndOfOffersLoggedOutSorting>;
  readonly globalOfferCooldownContent?: InputMaybe<IGlobalOfferCooldownContentSorting>;
  readonly offerNotAvailableModalContent?: InputMaybe<IIncentiveNotAvailableModalContentSorting>;
  readonly offersEmptyStates?: InputMaybe<IOffersEmptyStatesSorting>;
  readonly rewardNotAvailableModalContent?: InputMaybe<IIncentiveNotAvailableModalContentSorting>;
}

export interface ILoyaltyPaymentMethodWidget {
  readonly __typename?: 'LoyaltyPaymentMethodWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyPaymentMethodWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyPaymentMethodWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyPoints {
  readonly __typename?: 'LoyaltyPoints';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly points: Maybe<Scalars['Float']['output']>;
}

export interface ILoyaltyPointsAndRewardsWidget {
  readonly __typename?: 'LoyaltyPointsAndRewardsWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays the loyalty points and rewards carousel. */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyPointsAndRewardsWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyPointsAndRewardsWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyPointsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly points?: InputMaybe<IFloatFilter>;
}

export interface ILoyaltyPointsMeterWidget {
  readonly __typename?: 'LoyaltyPointsMeterWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays a loyalty points meter */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly ctaButton: Maybe<IActionButton>;
  readonly imageBackground: Maybe<IImageBackground>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyPointsMeterWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly ctaButton?: InputMaybe<IActionButtonFilter>;
  readonly imageBackground?: InputMaybe<IImageBackgroundFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyPointsMeterWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly ctaButton?: InputMaybe<IActionButtonSorting>;
  readonly imageBackground?: InputMaybe<IImageBackgroundSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyPointsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly points?: InputMaybe<SortOrder>;
}

export interface ILoyaltyQrAndShortCodeModalContent {
  readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly buttonCtaLabel: Maybe<ILocaleString>;
  readonly details: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<IImage>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrAndShortCodeModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly buttonCtaLabel?: InputMaybe<ILocaleStringFilter>;
  readonly details?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrAndShortCodeModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly buttonCtaLabel?: InputMaybe<ILocaleStringSorting>;
  readonly details?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQrAndShortCodeWidget {
  readonly __typename?: 'LoyaltyQRAndShortCodeWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly qrAndShortCodeErrorModalContent: Maybe<ILoyaltyQrAndShortCodeModalContent>;
  readonly qrAndShortCodeHowToEarnModalContent: Maybe<ILoyaltyQrAndShortCodeModalContent>;
  /** @deprecated This is no longer used. */
  readonly qrAndShortCodeSuccessModalContent: Maybe<ILoyaltyQrAndShortCodeModalContent>;
  readonly shortCodeAlwaysDisplayGenerateButton: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is no longer used. */
  readonly shortCodeExpirationButtonCtaLabel: Maybe<ILocaleString>;
  /** @deprecated This is no longer used. */
  readonly shortCodeExpirationTitle: Maybe<ILocaleString>;
  readonly shortCodeText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrAndShortCodeWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly qrAndShortCodeErrorModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentFilter>;
  readonly qrAndShortCodeHowToEarnModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentFilter>;
  readonly qrAndShortCodeSuccessModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentFilter>;
  readonly shortCodeAlwaysDisplayGenerateButton?: InputMaybe<IBooleanFilter>;
  readonly shortCodeExpirationButtonCtaLabel?: InputMaybe<ILocaleStringFilter>;
  readonly shortCodeExpirationTitle?: InputMaybe<ILocaleStringFilter>;
  readonly shortCodeText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrAndShortCodeWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly qrAndShortCodeErrorModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentSorting>;
  readonly qrAndShortCodeHowToEarnModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentSorting>;
  readonly qrAndShortCodeSuccessModalContent?: InputMaybe<ILoyaltyQrAndShortCodeModalContentSorting>;
  readonly shortCodeAlwaysDisplayGenerateButton?: InputMaybe<SortOrder>;
  readonly shortCodeExpirationButtonCtaLabel?: InputMaybe<ILocaleStringSorting>;
  readonly shortCodeExpirationTitle?: InputMaybe<ILocaleStringSorting>;
  readonly shortCodeText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQrCodeLoyaltyIdWidget {
  readonly __typename?: 'LoyaltyQRCodeLoyaltyIdWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrCodeLoyaltyIdWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrCodeLoyaltyIdWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQrCodeWidget {
  readonly __typename?: 'LoyaltyQRCodeWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly expirationMessage: Maybe<ILocaleString>;
  /** @deprecated This is no longer used. */
  readonly phoneNumberTextMessage: Maybe<ILocaleString>;
  /** @deprecated This is no longer used. */
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrCodeWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly expirationMessage?: InputMaybe<ILocaleStringFilter>;
  readonly phoneNumberTextMessage?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrCodeWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly expirationMessage?: InputMaybe<ILocaleStringSorting>;
  readonly phoneNumberTextMessage?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQrScanButtonWidget {
  readonly __typename?: 'LoyaltyQRScanButtonWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly expirationMessage: Maybe<ILocaleString>;
  readonly link: Maybe<ILinkWidget>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrScanButtonWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly expirationMessage?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILinkWidgetFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrScanButtonWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly expirationMessage?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILinkWidgetSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQrScanHeaderWidget {
  readonly __typename?: 'LoyaltyQRScanHeaderWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly expirationMessage: Maybe<ILocaleString>;
  readonly link: Maybe<ILinkWidget>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQrScanHeaderWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly expirationMessage?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILinkWidgetFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQrScanHeaderWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly expirationMessage?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILinkWidgetSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQuestsTileWidget {
  readonly __typename?: 'LoyaltyQuestsTileWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Challenges Tile widget */
  readonly componentKey: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyQuestsTileWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyQuestsTileWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
}

export interface ILoyaltyQuestsUi extends IDocument {
  readonly __typename?: 'LoyaltyQuestsUI';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly bonusPointsIncentiveDescription: Maybe<ILocaleString>;
  readonly completedAndRedeemedBonusPointsDescription: Maybe<ILocaleString>;
  readonly completedAndRedeemedDescription: Maybe<ILocaleString>;
  readonly completedButton: Maybe<ILocaleString>;
  readonly completedDescription: Maybe<ILocaleString>;
  readonly enrolledButton: Maybe<ILocaleString>;
  readonly genericStepImage: Maybe<ILocaleImage>;
  readonly genericStepText: Maybe<ILocaleString>;
  readonly notEnrolledButton: Maybe<ILocaleString>;
  readonly sortedSystemwideQuests: Maybe<ReadonlyArray<Maybe<ISystemwideQuest>>>;
}

export interface ILoyaltyQuestsUiFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly bonusPointsIncentiveDescription?: InputMaybe<ILocaleStringFilter>;
  readonly completedAndRedeemedBonusPointsDescription?: InputMaybe<ILocaleStringFilter>;
  readonly completedAndRedeemedDescription?: InputMaybe<ILocaleStringFilter>;
  readonly completedButton?: InputMaybe<ILocaleStringFilter>;
  readonly completedDescription?: InputMaybe<ILocaleStringFilter>;
  readonly enrolledButton?: InputMaybe<ILocaleStringFilter>;
  readonly genericStepImage?: InputMaybe<ILocaleImageFilter>;
  readonly genericStepText?: InputMaybe<ILocaleStringFilter>;
  readonly notEnrolledButton?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQuestsUiSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly bonusPointsIncentiveDescription?: InputMaybe<ILocaleStringSorting>;
  readonly completedAndRedeemedBonusPointsDescription?: InputMaybe<ILocaleStringSorting>;
  readonly completedAndRedeemedDescription?: InputMaybe<ILocaleStringSorting>;
  readonly completedButton?: InputMaybe<ILocaleStringSorting>;
  readonly completedDescription?: InputMaybe<ILocaleStringSorting>;
  readonly enrolledButton?: InputMaybe<ILocaleStringSorting>;
  readonly genericStepImage?: InputMaybe<ILocaleImageSorting>;
  readonly genericStepText?: InputMaybe<ILocaleStringSorting>;
  readonly notEnrolledButton?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyQuestsWidget {
  readonly __typename?: 'LoyaltyQuestsWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Quests widget */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyQuestsWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyQuestsWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyReceiptVariants {
  readonly __typename?: 'LoyaltyReceiptVariants';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly claimed: Maybe<ILoyaltyTransactionReceipt>;
  readonly mobile: Maybe<ILoyaltyTransactionReceipt>;
  readonly unclaimed: Maybe<ILoyaltyTransactionReceipt>;
}

export interface ILoyaltyReceiptVariantsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly claimed?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
  readonly mobile?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
  readonly unclaimed?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
}

export interface ILoyaltyReceiptVariantsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly claimed?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
  readonly mobile?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
  readonly unclaimed?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
}

export interface ILoyaltyReceipts extends IDocument {
  readonly __typename?: 'LoyaltyReceipts';
  readonly CARROLS: Maybe<ILoyaltyReceiptVariants>;
  readonly NCR: Maybe<ILoyaltyReceiptVariants>;
  readonly PARTNER: Maybe<ILoyaltyReceiptVariants>;
  readonly QDI: Maybe<ILoyaltyReceiptVariants>;
  readonly QST: Maybe<ILoyaltyReceiptVariants>;
  readonly RPOS: Maybe<ILoyaltyReceiptVariants>;
  readonly SICOM: Maybe<ILoyaltyReceiptVariants>;
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly claimed: Maybe<ILoyaltyTransactionReceipt>;
  readonly mobile: Maybe<ILoyaltyTransactionReceipt>;
  readonly unclaimed: Maybe<ILoyaltyTransactionReceipt>;
}

export interface ILoyaltyReceiptsFilter {
  readonly CARROLS?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly NCR?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly PARTNER?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly QDI?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly QST?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly RPOS?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  readonly SICOM?: InputMaybe<ILoyaltyReceiptVariantsFilter>;
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly claimed?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
  readonly mobile?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
  readonly unclaimed?: InputMaybe<ILoyaltyTransactionReceiptFilter>;
}

export interface ILoyaltyReceiptsSorting {
  readonly CARROLS?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly NCR?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly PARTNER?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly QDI?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly QST?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly RPOS?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly SICOM?: InputMaybe<ILoyaltyReceiptVariantsSorting>;
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly claimed?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
  readonly mobile?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
  readonly unclaimed?: InputMaybe<ILoyaltyTransactionReceiptSorting>;
}

export interface ILoyaltySanityQueries extends IDocument {
  readonly __typename?: 'LoyaltySanityQueries';
  /**
   * Date the document was created
   * @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead.
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead.
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead. */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead.
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead.
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead.
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead. */
  readonly loyaltySanityQueryList: Maybe<ReadonlyArray<Maybe<ILoyaltySanityQuery>>>;
}

export interface ILoyaltySanityQueriesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface ILoyaltySanityQueriesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface ILoyaltySanityQuery {
  readonly __typename?: 'LoyaltySanityQuery';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly alias: Maybe<Scalars['String']['output']>;
  readonly query: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltySanityQueryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly alias?: InputMaybe<IStringFilter>;
  readonly query?: InputMaybe<IStringFilter>;
}

export interface ILoyaltySanityQuerySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly alias?: InputMaybe<SortOrder>;
  readonly query?: InputMaybe<SortOrder>;
}

export interface ILoyaltyShortCodeWidget {
  readonly __typename?: 'LoyaltyShortCodeWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly alternativeTitle: Maybe<ILocaleString>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly defaultCode: Maybe<ILocaleString>;
  readonly subtitle: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyShortCodeWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly alternativeTitle?: InputMaybe<ILocaleStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly defaultCode?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyShortCodeWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly alternativeTitle?: InputMaybe<ILocaleStringSorting>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly defaultCode?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTab {
  readonly __typename?: 'LoyaltyTab';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly active: Maybe<Scalars['Boolean']['output']>;
  readonly link: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyTabFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly active?: InputMaybe<IBooleanFilter>;
  readonly link?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyTabSelectorWidget {
  readonly __typename?: 'LoyaltyTabSelectorWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  readonly tabs: Maybe<ReadonlyArray<Maybe<ILink>>>;
}

export interface ILoyaltyTabSelectorWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
}

export interface ILoyaltyTabSelectorWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly enabled?: InputMaybe<SortOrder>;
}

export interface ILoyaltyTabSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly active?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<SortOrder>;
}

export interface ILoyaltyTextWidget {
  readonly __typename?: 'LoyaltyTextWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<ILocaleString>;
}

export interface ILoyaltyTextWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTextWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTierEntranceBehaviorConfiguration {
  readonly __typename?: 'LoyaltyTierEntranceBehaviorConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyTierKey: Maybe<Scalars['String']['output']>;
  readonly loyaltyTierName: Maybe<ILocaleString>;
  readonly pointsNeeded: Maybe<Scalars['Float']['output']>;
  readonly pointsNeededToMaintain: Maybe<Scalars['Float']['output']>;
  readonly pointsToEnterCalculationTimeInterval: Maybe<Scalars['Float']['output']>;
  /** Day(s) or Month(s). */
  readonly pointsToEnterCalculationTimeUnit: Maybe<Scalars['String']['output']>;
  readonly pointsToMaintainCalculationTimeInterval: Maybe<Scalars['Float']['output']>;
  /** Day(s) or Month(s). */
  readonly pointsToMaintainCalculationTimeUnit: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyTierEntranceBehaviorConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyTierKey?: InputMaybe<IStringFilter>;
  readonly loyaltyTierName?: InputMaybe<ILocaleStringFilter>;
  readonly pointsNeeded?: InputMaybe<IFloatFilter>;
  readonly pointsNeededToMaintain?: InputMaybe<IFloatFilter>;
  readonly pointsToEnterCalculationTimeInterval?: InputMaybe<IFloatFilter>;
  readonly pointsToEnterCalculationTimeUnit?: InputMaybe<IStringFilter>;
  readonly pointsToMaintainCalculationTimeInterval?: InputMaybe<IFloatFilter>;
  readonly pointsToMaintainCalculationTimeUnit?: InputMaybe<IStringFilter>;
}

export interface ILoyaltyTierEntranceBehaviorConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyTierKey?: InputMaybe<SortOrder>;
  readonly loyaltyTierName?: InputMaybe<ILocaleStringSorting>;
  readonly pointsNeeded?: InputMaybe<SortOrder>;
  readonly pointsNeededToMaintain?: InputMaybe<SortOrder>;
  readonly pointsToEnterCalculationTimeInterval?: InputMaybe<SortOrder>;
  readonly pointsToEnterCalculationTimeUnit?: InputMaybe<SortOrder>;
  readonly pointsToMaintainCalculationTimeInterval?: InputMaybe<SortOrder>;
  readonly pointsToMaintainCalculationTimeUnit?: InputMaybe<SortOrder>;
}

export interface ILoyaltyTiersContent {
  readonly __typename?: 'LoyaltyTiersContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyTiersHowItWorksButtonLink: Maybe<ILocaleString>;
  readonly loyaltyTiersHowItWorksButtonText: Maybe<ILocaleString>;
  readonly loyaltyTiersOrderMoreButtonLink: Maybe<ILocaleString>;
  readonly loyaltyTiersOrderMoreButtonText: Maybe<ILocaleString>;
  readonly loyaltyTiersRewardsButtonLink: Maybe<ILocaleString>;
  readonly loyaltyTiersRewardsButtonText: Maybe<ILocaleString>;
  readonly loyaltyTiersRewardsHistoryButtonLink: Maybe<ILocaleString>;
  readonly loyaltyTiersRewardsHistoryButtonText: Maybe<ILocaleString>;
  readonly loyaltyTiersUIConfig: Maybe<ReadonlyArray<Maybe<ILoyaltyTiersUiConfigCell>>>;
  readonly loyaltyTiersUnauthenticatedCells: Maybe<
    ReadonlyArray<Maybe<ILoyaltyTiersUnauthenticatedCell>>
  >;
}

export interface ILoyaltyTiersContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyTiersHowItWorksButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersHowItWorksButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersOrderMoreButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersOrderMoreButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersRewardsButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersRewardsButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersRewardsHistoryButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersRewardsHistoryButtonText?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTiersContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyTiersHowItWorksButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersHowItWorksButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersOrderMoreButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersOrderMoreButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersRewardsButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersRewardsButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersRewardsHistoryButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersRewardsHistoryButtonText?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTiersUiConfigCell {
  readonly __typename?: 'LoyaltyTiersUIConfigCell';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyTiersCarouselRewards: Maybe<ReadonlyArray<Maybe<IReward>>>;
  readonly loyaltyTiersModalDescription: Maybe<ILocaleBlockContent>;
  readonly loyaltyTiersModalImage: Maybe<IImage>;
  readonly loyaltyTiersModalMainBtnLabel: Maybe<ILocaleString>;
  readonly loyaltyTiersModalMainBtnLink: Maybe<ILocaleString>;
  readonly loyaltyTiersModalTitle: Maybe<ILocaleString>;
  /** As defined on the backend (eg. Tier_1, Tier_2, etc.) */
  readonly loyaltyTiersUIConfigID: Maybe<Scalars['String']['output']>;
  readonly loyaltyTiersUIConfigIcon: Maybe<ILocaleImage>;
  readonly loyaltyTiersUIConfigName: Maybe<ILocaleString>;
}

export interface ILoyaltyTiersUiConfigCellFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyTiersModalDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly loyaltyTiersModalImage?: InputMaybe<IImageFilter>;
  readonly loyaltyTiersModalMainBtnLabel?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersModalMainBtnLink?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersModalTitle?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersUIConfigID?: InputMaybe<IStringFilter>;
  readonly loyaltyTiersUIConfigIcon?: InputMaybe<ILocaleImageFilter>;
  readonly loyaltyTiersUIConfigName?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTiersUiConfigCellSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyTiersModalDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly loyaltyTiersModalImage?: InputMaybe<IImageSorting>;
  readonly loyaltyTiersModalMainBtnLabel?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersModalMainBtnLink?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersModalTitle?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersUIConfigID?: InputMaybe<SortOrder>;
  readonly loyaltyTiersUIConfigIcon?: InputMaybe<ILocaleImageSorting>;
  readonly loyaltyTiersUIConfigName?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTiersUnauthenticatedCell {
  readonly __typename?: 'LoyaltyTiersUnauthenticatedCell';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly loyaltyTiersSignupCellDescription: Maybe<ILocaleString>;
  readonly loyaltyTiersSignupCellImage: Maybe<ILocaleImage>;
  readonly loyaltyTiersSignupCellTitle: Maybe<ILocaleString>;
}

export interface ILoyaltyTiersUnauthenticatedCellFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly loyaltyTiersSignupCellDescription?: InputMaybe<ILocaleStringFilter>;
  readonly loyaltyTiersSignupCellImage?: InputMaybe<ILocaleImageFilter>;
  readonly loyaltyTiersSignupCellTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTiersUnauthenticatedCellSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly loyaltyTiersSignupCellDescription?: InputMaybe<ILocaleStringSorting>;
  readonly loyaltyTiersSignupCellImage?: InputMaybe<ILocaleImageSorting>;
  readonly loyaltyTiersSignupCellTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTiersWidget {
  readonly __typename?: 'LoyaltyTiersWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly sortByPointValue: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyTiersWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly sortByPointValue?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTiersWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly sortByPointValue?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTitleWidget {
  readonly __typename?: 'LoyaltyTitleWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface ILoyaltyTitleWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTitleWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyTransactionReceipt {
  readonly __typename?: 'LoyaltyTransactionReceipt';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly footer: Maybe<ILocaleString>;
  readonly guestDisplay: Maybe<ILocaleString>;
  readonly header: Maybe<ILocaleString>;
  readonly identifierPrefix: Maybe<ILocaleString>;
  readonly marketing: Maybe<ILocaleString>;
  readonly operatorDisplay: Maybe<ILocaleString>;
  readonly pointsEarnedPrefix: Maybe<ILocaleString>;
  readonly pointsRedeemedPrefix: Maybe<ILocaleString>;
  readonly qrCode: Maybe<ILocaleString>;
  readonly totalPointsPrefix: Maybe<ILocaleString>;
}

export interface ILoyaltyTransactionReceiptFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly footer?: InputMaybe<ILocaleStringFilter>;
  readonly guestDisplay?: InputMaybe<ILocaleStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly identifierPrefix?: InputMaybe<ILocaleStringFilter>;
  readonly marketing?: InputMaybe<ILocaleStringFilter>;
  readonly operatorDisplay?: InputMaybe<ILocaleStringFilter>;
  readonly pointsEarnedPrefix?: InputMaybe<ILocaleStringFilter>;
  readonly pointsRedeemedPrefix?: InputMaybe<ILocaleStringFilter>;
  readonly qrCode?: InputMaybe<ILocaleStringFilter>;
  readonly totalPointsPrefix?: InputMaybe<ILocaleStringFilter>;
}

export interface ILoyaltyTransactionReceiptSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly footer?: InputMaybe<ILocaleStringSorting>;
  readonly guestDisplay?: InputMaybe<ILocaleStringSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly identifierPrefix?: InputMaybe<ILocaleStringSorting>;
  readonly marketing?: InputMaybe<ILocaleStringSorting>;
  readonly operatorDisplay?: InputMaybe<ILocaleStringSorting>;
  readonly pointsEarnedPrefix?: InputMaybe<ILocaleStringSorting>;
  readonly pointsRedeemedPrefix?: InputMaybe<ILocaleStringSorting>;
  readonly qrCode?: InputMaybe<ILocaleStringSorting>;
  readonly totalPointsPrefix?: InputMaybe<ILocaleStringSorting>;
}

export interface ILoyaltyUi extends IDocument {
  readonly __typename?: 'LoyaltyUI';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly featuredRewards: Maybe<IRewardsSection>;
  readonly imageBackground: Maybe<IImageBackground>;
  readonly loyaltyCTAButtons: Maybe<ILoyaltyCtaButtons>;
  /** @deprecated This has been deprecated */
  readonly loyaltyDashboard: Maybe<ILoyaltyDashboard>;
  /** @deprecated This has been deprecated */
  readonly loyaltyInRestaurantRedemption: Maybe<ILoyaltyInRestaurantRedemption>;
  readonly loyaltyNotAuthenticated: Maybe<ILoyaltyNotAuthenticated>;
  /** @deprecated This has been deprecated */
  readonly loyaltyNotAvailableAtDeliveryMode: Maybe<ILoyaltyNotAvailableAtDeliveryMode>;
  readonly loyaltyNotAvailableAtThisRestaurant: Maybe<ILoyaltyNotAvailableAtThisRestaurant>;
  readonly loyaltyTiersContent: Maybe<ILoyaltyTiersContent>;
  readonly noRewardPoints: Maybe<IEmptyState>;
  readonly noRewardsAvailable: Maybe<IEmptyState>;
  readonly notEnrolledState: Maybe<IEmptyState>;
  readonly notEnrolledStateMobile: Maybe<IEmptyState>;
  /** @deprecated (ILO-1776) Even if a market lacks a loyalty program, we still need a loyalty ID and backend loyalty enabled for offers to work, so this opt-in modal won't be shown to users. */
  readonly optInModal: Maybe<IOptInModalContent>;
  /** @deprecated (ILO-1776) Even if a market lacks a loyalty program, we still need a loyalty ID and backend loyalty enabled for offers to work, so this opt-in modal won't be shown to users. */
  readonly optInModalError: Maybe<IOptInModalContent>;
  /** @deprecated (ILO-1776) Even if a market lacks a loyalty program, we still need a loyalty ID and backend loyalty enabled for offers to work, so this opt-in modal won't be shown to users. */
  readonly optInModalLoading: Maybe<IOptInModalLoading>;
  readonly overrideShortCodeModalContent: Maybe<IOverrideShortCodeModalContent>;
  readonly rewardsLearnMoreIcon: Maybe<ILocaleImage>;
  /**
   * Display rewards list format.
   * @deprecated Carousel Widget will be the default for all. This field will soon be removed.
   */
  readonly rewardsListWidgetType: Maybe<Scalars['String']['output']>;
  readonly rewardsLogo: Maybe<ILocaleImage>;
  readonly rewardsLogoInverted: Maybe<ILocaleImage>;
  readonly rewardsProgressBarIcon: Maybe<ILocaleImage>;
  readonly shortCodeConfirmationModalContent: Maybe<IShortCodeConfirmationModalContent>;
  /** @deprecated This has been deprecated */
  readonly surpriseAndDelightModalContent: Maybe<ISurpriseAndDelightModalContent>;
  /** @deprecated This has been deprecated */
  readonly swapModalContent: Maybe<ISwapModalContent>;
}

export interface ILoyaltyUiFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly featuredRewards?: InputMaybe<IRewardsSectionFilter>;
  readonly imageBackground?: InputMaybe<IImageBackgroundFilter>;
  readonly loyaltyCTAButtons?: InputMaybe<ILoyaltyCtaButtonsFilter>;
  readonly loyaltyDashboard?: InputMaybe<ILoyaltyDashboardFilter>;
  readonly loyaltyInRestaurantRedemption?: InputMaybe<ILoyaltyInRestaurantRedemptionFilter>;
  readonly loyaltyNotAuthenticated?: InputMaybe<ILoyaltyNotAuthenticatedFilter>;
  readonly loyaltyNotAvailableAtDeliveryMode?: InputMaybe<ILoyaltyNotAvailableAtDeliveryModeFilter>;
  readonly loyaltyNotAvailableAtThisRestaurant?: InputMaybe<ILoyaltyNotAvailableAtThisRestaurantFilter>;
  readonly loyaltyTiersContent?: InputMaybe<ILoyaltyTiersContentFilter>;
  readonly noRewardPoints?: InputMaybe<IEmptyStateFilter>;
  readonly noRewardsAvailable?: InputMaybe<IEmptyStateFilter>;
  readonly notEnrolledState?: InputMaybe<IEmptyStateFilter>;
  readonly notEnrolledStateMobile?: InputMaybe<IEmptyStateFilter>;
  readonly optInModal?: InputMaybe<IOptInModalContentFilter>;
  readonly optInModalError?: InputMaybe<IOptInModalContentFilter>;
  readonly optInModalLoading?: InputMaybe<IOptInModalLoadingFilter>;
  readonly overrideShortCodeModalContent?: InputMaybe<IOverrideShortCodeModalContentFilter>;
  readonly rewardsLearnMoreIcon?: InputMaybe<ILocaleImageFilter>;
  readonly rewardsListWidgetType?: InputMaybe<IStringFilter>;
  readonly rewardsLogo?: InputMaybe<ILocaleImageFilter>;
  readonly rewardsLogoInverted?: InputMaybe<ILocaleImageFilter>;
  readonly rewardsProgressBarIcon?: InputMaybe<ILocaleImageFilter>;
  readonly shortCodeConfirmationModalContent?: InputMaybe<IShortCodeConfirmationModalContentFilter>;
  readonly surpriseAndDelightModalContent?: InputMaybe<ISurpriseAndDelightModalContentFilter>;
  readonly swapModalContent?: InputMaybe<ISwapModalContentFilter>;
}

export interface ILoyaltyUiSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly imageBackground?: InputMaybe<IImageBackgroundSorting>;
  readonly loyaltyCTAButtons?: InputMaybe<ILoyaltyCtaButtonsSorting>;
  readonly loyaltyDashboard?: InputMaybe<ILoyaltyDashboardSorting>;
  readonly loyaltyInRestaurantRedemption?: InputMaybe<ILoyaltyInRestaurantRedemptionSorting>;
  readonly loyaltyNotAuthenticated?: InputMaybe<ILoyaltyNotAuthenticatedSorting>;
  readonly loyaltyNotAvailableAtDeliveryMode?: InputMaybe<ILoyaltyNotAvailableAtDeliveryModeSorting>;
  readonly loyaltyNotAvailableAtThisRestaurant?: InputMaybe<ILoyaltyNotAvailableAtThisRestaurantSorting>;
  readonly loyaltyTiersContent?: InputMaybe<ILoyaltyTiersContentSorting>;
  readonly optInModal?: InputMaybe<IOptInModalContentSorting>;
  readonly optInModalError?: InputMaybe<IOptInModalContentSorting>;
  readonly optInModalLoading?: InputMaybe<IOptInModalLoadingSorting>;
  readonly overrideShortCodeModalContent?: InputMaybe<IOverrideShortCodeModalContentSorting>;
  readonly rewardsLearnMoreIcon?: InputMaybe<ILocaleImageSorting>;
  readonly rewardsListWidgetType?: InputMaybe<SortOrder>;
  readonly rewardsLogo?: InputMaybe<ILocaleImageSorting>;
  readonly rewardsLogoInverted?: InputMaybe<ILocaleImageSorting>;
  readonly rewardsProgressBarIcon?: InputMaybe<ILocaleImageSorting>;
  readonly shortCodeConfirmationModalContent?: InputMaybe<IShortCodeConfirmationModalContentSorting>;
  readonly surpriseAndDelightModalContent?: InputMaybe<ISurpriseAndDelightModalContentSorting>;
  readonly swapModalContent?: InputMaybe<ISwapModalContentSorting>;
}

export interface IMainHero extends IDocument {
  readonly __typename?: 'MainHero';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly action: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly actionLeft: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly actionLeftIcon: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly actionRight: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly actionRightIcon: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly kioskImage: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly loyaltyAction: Maybe<ILinkAction>;
  /** @deprecated This is deprecated */
  readonly mainText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly maskCards: Maybe<ReadonlyArray<Maybe<ISimpleCard>>>;
  /** @deprecated This is deprecated */
  readonly mobileImage: Maybe<ILocaleImage>;
  /**
   * Used only as an organizational aid. Not displayed in the app.
   * @deprecated This is deprecated
   */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly subText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly superText: Maybe<ILocaleString>;
  /**
   * Color for all hero content text. White and black align with the brand color scheme equivalent
   * @deprecated This is deprecated
   */
  readonly textColor: Maybe<Scalars['String']['output']>;
}

export interface IMainHeroFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly action?: InputMaybe<ILinkActionFilter>;
  readonly actionLeft?: InputMaybe<ILinkActionFilter>;
  readonly actionLeftIcon?: InputMaybe<ILocaleImageFilter>;
  readonly actionRight?: InputMaybe<ILinkActionFilter>;
  readonly actionRightIcon?: InputMaybe<ILocaleImageFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly kioskImage?: InputMaybe<ILocaleImageFilter>;
  readonly loyaltyAction?: InputMaybe<ILinkActionFilter>;
  readonly mainText?: InputMaybe<ILocaleStringFilter>;
  readonly mobileImage?: InputMaybe<ILocaleImageFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly subText?: InputMaybe<ILocaleStringFilter>;
  readonly superText?: InputMaybe<ILocaleStringFilter>;
  readonly textColor?: InputMaybe<IStringFilter>;
}

export interface IMainHeroSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly action?: InputMaybe<ILinkActionSorting>;
  readonly actionLeft?: InputMaybe<ILinkActionSorting>;
  readonly actionLeftIcon?: InputMaybe<ILocaleImageSorting>;
  readonly actionRight?: InputMaybe<ILinkActionSorting>;
  readonly actionRightIcon?: InputMaybe<ILocaleImageSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly kioskImage?: InputMaybe<ILocaleImageSorting>;
  readonly loyaltyAction?: InputMaybe<ILinkActionSorting>;
  readonly mainText?: InputMaybe<ILocaleStringSorting>;
  readonly mobileImage?: InputMaybe<ILocaleImageSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly subText?: InputMaybe<ILocaleStringSorting>;
  readonly superText?: InputMaybe<ILocaleStringSorting>;
  readonly textColor?: InputMaybe<SortOrder>;
}

export interface IMarkerItem extends IDocument {
  readonly __typename?: 'MarkerItem';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly name: Maybe<ILocaleString>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface IMarkerItemFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
}

export interface IMarkerItemSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
}

export interface IMarketingAndLegalTextsModalContent {
  readonly __typename?: 'MarketingAndLegalTextsModalContent';
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly description: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly paybackBKLogoImage: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly title: Maybe<ILocaleString>;
}

export interface IMarketingAndLegalTextsModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly paybackBKLogoImage?: InputMaybe<ILocaleImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IMarketingAndLegalTextsModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly paybackBKLogoImage?: InputMaybe<ILocaleImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IMarketingCard extends IDocument {
  readonly __typename?: 'MarketingCard';
  /** BK Pay Marketing Tile will be visible to the audience selected below: */
  readonly BKPayAudience: Maybe<Scalars['String']['output']>;
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundColor: Maybe<IColor>;
  /** (Additional targeting options applicable to BK Pay related Marketing Tiles) */
  readonly bkSpecificTargeting: Maybe<Scalars['Boolean']['output']>;
  readonly buttonBackgroundColor: Maybe<IColor>;
  readonly buttonTextColor: Maybe<IColor>;
  readonly callToAction: Maybe<ILinkAction>;
  readonly daysOfWeek: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly description: Maybe<ILocaleString>;
  /** Use custom text and background colors. Please ensure that the colors meet the guidelines for contrast accessibility (https://accessible-colors.com/) */
  readonly hasCustomColors: Maybe<Scalars['Boolean']['output']>;
  readonly hasTerms: Maybe<Scalars['Boolean']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  readonly termsButton: Maybe<ILinkAction>;
  readonly termsText: Maybe<ILocaleBlockContent>;
  readonly textColor: Maybe<IColor>;
  readonly timeRanges: Maybe<ReadonlyArray<Maybe<ITimeRange>>>;
}

export interface IMarketingCardFilter {
  readonly BKPayAudience?: InputMaybe<IStringFilter>;
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundColor?: InputMaybe<IColorFilter>;
  readonly bkSpecificTargeting?: InputMaybe<IBooleanFilter>;
  readonly buttonBackgroundColor?: InputMaybe<IColorFilter>;
  readonly buttonTextColor?: InputMaybe<IColorFilter>;
  readonly callToAction?: InputMaybe<ILinkActionFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly hasCustomColors?: InputMaybe<IBooleanFilter>;
  readonly hasTerms?: InputMaybe<IBooleanFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly termsButton?: InputMaybe<ILinkActionFilter>;
  readonly termsText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly textColor?: InputMaybe<IColorFilter>;
}

export interface IMarketingCardGroup extends IDocument {
  readonly __typename?: 'MarketingCardGroup';
  readonly Cards: Maybe<ReadonlyArray<Maybe<IMarketingCard>>>;
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IMarketingCardGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IMarketingCardGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IMarketingCardSorting {
  readonly BKPayAudience?: InputMaybe<SortOrder>;
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundColor?: InputMaybe<IColorSorting>;
  readonly bkSpecificTargeting?: InputMaybe<SortOrder>;
  readonly buttonBackgroundColor?: InputMaybe<IColorSorting>;
  readonly buttonTextColor?: InputMaybe<IColorSorting>;
  readonly callToAction?: InputMaybe<ILinkActionSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly hasCustomColors?: InputMaybe<SortOrder>;
  readonly hasTerms?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly termsButton?: InputMaybe<ILinkActionSorting>;
  readonly termsText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly textColor?: InputMaybe<IColorSorting>;
}

export interface IMarketingTile extends IDocument {
  readonly __typename?: 'MarketingTile';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<ILocaleImages>;
  readonly buttonAction: Maybe<ILinkAction>;
  readonly buttonAlignment: Maybe<Scalars['String']['output']>;
  /** Should the Marketing Tile have a locked aspect ratio? If you are unsure, leave checked. If checked, the background image will not be cropped, and the aspect ratio of the Background Image will be maintained. Image will be maintained on both Desktop and Mobile. If this Marketing Tile is part of a  Marketing Tile pair, then you must ensure that both background images have the same aspect ratio, e.g. 2:1 If not checked, the desktop version will have a max aspect ratio of 4:1. And aspect ratio of ~1:1 on mobile devices  */
  readonly lockedAspectRatio: Maybe<Scalars['Boolean']['output']>;
  readonly ruleSet: Maybe<IMarketingTileRuleSet>;
  readonly termsButton: Maybe<ILinkAction>;
  readonly termsText: Maybe<ILocaleBlockContent>;
  readonly textAlignment: Maybe<Scalars['String']['output']>;
  readonly textBlock: Maybe<ILocaleTypographyBlock>;
  /** Change the color of the text to make it more readable with a darker background */
  readonly textColor: Maybe<Scalars['String']['output']>;
}

export interface IMarketingTileBasic extends IDocument {
  readonly __typename?: 'MarketingTileBasic';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly link: Maybe<ILocaleUrl>;
  readonly linkLabel: Maybe<ILocaleString>;
  readonly marketingBasicTileDescription: Maybe<ILocaleTypographyBlock>;
  readonly marketingBasicTileImage: Maybe<ILocaleImages>;
  readonly marketingBasicTileTitle: Maybe<ILocaleString>;
}

export interface IMarketingTileBasicFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly linkLabel?: InputMaybe<ILocaleStringFilter>;
  readonly marketingBasicTileDescription?: InputMaybe<ILocaleTypographyBlockFilter>;
  readonly marketingBasicTileImage?: InputMaybe<ILocaleImagesFilter>;
  readonly marketingBasicTileTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IMarketingTileBasicSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly linkLabel?: InputMaybe<ILocaleStringSorting>;
  readonly marketingBasicTileDescription?: InputMaybe<ILocaleTypographyBlockSorting>;
  readonly marketingBasicTileImage?: InputMaybe<ILocaleImagesSorting>;
  readonly marketingBasicTileTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IMarketingTileCarousel extends IDocument {
  readonly __typename?: 'MarketingTileCarousel';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly header: Maybe<ILocaleString>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly tiles: Maybe<ReadonlyArray<Maybe<IMarketingTileBasic>>>;
}

export interface IMarketingTileCarouselFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IMarketingTileCarouselSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IMarketingTileFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<ILocaleImagesFilter>;
  readonly buttonAction?: InputMaybe<ILinkActionFilter>;
  readonly buttonAlignment?: InputMaybe<IStringFilter>;
  readonly lockedAspectRatio?: InputMaybe<IBooleanFilter>;
  readonly ruleSet?: InputMaybe<IMarketingTileRuleSetFilter>;
  readonly termsButton?: InputMaybe<ILinkActionFilter>;
  readonly termsText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly textAlignment?: InputMaybe<IStringFilter>;
  readonly textBlock?: InputMaybe<ILocaleTypographyBlockFilter>;
  readonly textColor?: InputMaybe<IStringFilter>;
}

export interface IMarketingTileGroup extends IDocument {
  readonly __typename?: 'MarketingTileGroup';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly tiles: Maybe<
    ReadonlyArray<
      Maybe<IMarketingTileOrMarketingTileBasicOrMarketingTileListOrMarketingTilePairOrMarketingTileQuad>
    >
  >;
}

export interface IMarketingTileGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IMarketingTileGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IMarketingTileList {
  readonly __typename?: 'MarketingTileList';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly tiles: Maybe<ReadonlyArray<Maybe<IMarketingTileBasic>>>;
}

export interface IMarketingTileListFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IMarketingTileListSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export type IMarketingTileOrMarketingTileBasic = IMarketingTile | IMarketingTileBasic;

export type IMarketingTileOrMarketingTileBasicOrMarketingTileListOrMarketingTilePairOrMarketingTileQuad =

    | IMarketingTile
    | IMarketingTileBasic
    | IMarketingTileList
    | IMarketingTilePair
    | IMarketingTileQuad;

export interface IMarketingTilePair {
  readonly __typename?: 'MarketingTilePair';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly left: Maybe<IMarketingTile>;
  readonly right: Maybe<IMarketingTile>;
}

export interface IMarketingTilePairFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly left?: InputMaybe<IMarketingTileFilter>;
  readonly right?: InputMaybe<IMarketingTileFilter>;
}

export interface IMarketingTilePairSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IMarketingTileQuad {
  readonly __typename?: 'MarketingTileQuad';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bottomLeftTile: Maybe<IMarketingTileBasic>;
  readonly bottomRightTile: Maybe<IMarketingTileBasic>;
  readonly topLeftTile: Maybe<IMarketingTileBasic>;
  readonly topRightTile: Maybe<IMarketingTileBasic>;
}

export interface IMarketingTileQuadFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bottomLeftTile?: InputMaybe<IMarketingTileBasicFilter>;
  readonly bottomRightTile?: InputMaybe<IMarketingTileBasicFilter>;
  readonly topLeftTile?: InputMaybe<IMarketingTileBasicFilter>;
  readonly topRightTile?: InputMaybe<IMarketingTileBasicFilter>;
}

export interface IMarketingTileQuadSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IMarketingTileRuleSet {
  readonly __typename?: 'MarketingTileRuleSet';
  /** BK Pay Marketing Tile will be visible to the audience selected below: */
  readonly BKPayAudience: Maybe<Scalars['String']['output']>;
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly betweenTimes: Maybe<IBetweenTimes>;
  /** (Additional targeting options applicable to BK Pay related Marketing Tiles) */
  readonly bkSpecificTargeting: Maybe<Scalars['Boolean']['output']>;
  readonly dayOfWeek: Maybe<IDayOfWeekBand>;
}

export interface IMarketingTileRuleSetFilter {
  readonly BKPayAudience?: InputMaybe<IStringFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly betweenTimes?: InputMaybe<IBetweenTimesFilter>;
  readonly bkSpecificTargeting?: InputMaybe<IBooleanFilter>;
  readonly dayOfWeek?: InputMaybe<IDayOfWeekBandFilter>;
}

export interface IMarketingTileRuleSetSorting {
  readonly BKPayAudience?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly betweenTimes?: InputMaybe<IBetweenTimesSorting>;
  readonly bkSpecificTargeting?: InputMaybe<SortOrder>;
  readonly dayOfWeek?: InputMaybe<IDayOfWeekBandSorting>;
}

export interface IMarketingTileSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<ILocaleImagesSorting>;
  readonly buttonAction?: InputMaybe<ILinkActionSorting>;
  readonly buttonAlignment?: InputMaybe<SortOrder>;
  readonly lockedAspectRatio?: InputMaybe<SortOrder>;
  readonly ruleSet?: InputMaybe<IMarketingTileRuleSetSorting>;
  readonly termsButton?: InputMaybe<ILinkActionSorting>;
  readonly termsText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly textAlignment?: InputMaybe<SortOrder>;
  readonly textBlock?: InputMaybe<ILocaleTypographyBlockSorting>;
  readonly textColor?: InputMaybe<SortOrder>;
}

export interface IMediaTag extends IDocument {
  readonly __typename?: 'MediaTag';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly name: Maybe<ISlug>;
}

export interface IMediaTagFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<ISlugFilter>;
}

export interface IMediaTagSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ISlugSorting>;
}

export interface IMembershipInternalPageConfiguration {
  readonly __typename?: 'MembershipInternalPageConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface IMembershipInternalPageConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IMembershipInternalPageConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IMemberships extends IDocument {
  readonly __typename?: 'Memberships';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly membershipBenefit: Maybe<IMembershipsBenefit>;
  readonly membershipIntegrationTypeContent: Maybe<IMembershipsIntegrationTypeContent>;
  /** This name refers to the internal membership name in the app. Please reach out to your respective CSM to make changes to this field. */
  readonly membershipInternalName: Maybe<Scalars['String']['output']>;
  readonly membershipInternalPageConfiguration: Maybe<IMembershipInternalPageConfiguration>;
  readonly membershipModalsContent: Maybe<IMembershipsModalsContent>;
  /** Configure Membership name that shows in the app */
  readonly membershipName: Maybe<Scalars['String']['output']>;
  readonly membershipPageConfiguration: Maybe<IMembershipsPageStyleContent>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface IMembershipsBenefit {
  readonly __typename?: 'MembershipsBenefit';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly offer: Maybe<IConfigOffer>;
}

export interface IMembershipsBenefitFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly offer?: InputMaybe<IConfigOfferFilter>;
}

export interface IMembershipsBenefitSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IMembershipsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly membershipBenefit?: InputMaybe<IMembershipsBenefitFilter>;
  readonly membershipIntegrationTypeContent?: InputMaybe<IMembershipsIntegrationTypeContentFilter>;
  readonly membershipInternalName?: InputMaybe<IStringFilter>;
  readonly membershipInternalPageConfiguration?: InputMaybe<IMembershipInternalPageConfigurationFilter>;
  readonly membershipModalsContent?: InputMaybe<IMembershipsModalsContentFilter>;
  readonly membershipName?: InputMaybe<IStringFilter>;
  readonly membershipPageConfiguration?: InputMaybe<IMembershipsPageStyleContentFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface IMembershipsInformationWidgets {
  readonly __typename?: 'MembershipsInformationWidgets';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly widgets: Maybe<ReadonlyArray<Maybe<IMembershipsWidgetContent>>>;
}

export interface IMembershipsInformationWidgetsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IMembershipsInformationWidgetsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IMembershipsIntegrationTypeContent {
  readonly __typename?: 'MembershipsIntegrationTypeContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly externalIntegrationPath: Maybe<ILocaleString>;
  readonly externalIntegrationText: Maybe<ILocaleString>;
  readonly membershipIntegrationType: Maybe<Scalars['String']['output']>;
}

export interface IMembershipsIntegrationTypeContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly externalIntegrationPath?: InputMaybe<ILocaleStringFilter>;
  readonly externalIntegrationText?: InputMaybe<ILocaleStringFilter>;
  readonly membershipIntegrationType?: InputMaybe<IStringFilter>;
}

export interface IMembershipsIntegrationTypeContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly externalIntegrationPath?: InputMaybe<ILocaleStringSorting>;
  readonly externalIntegrationText?: InputMaybe<ILocaleStringSorting>;
  readonly membershipIntegrationType?: InputMaybe<SortOrder>;
}

export interface IMembershipsModalsContent {
  readonly __typename?: 'MembershipsModalsContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<IImage>;
  readonly membershipAccountLinkFailure: Maybe<IMembershipsModalsContentDetails>;
  readonly membershipActivationSuccess: Maybe<IMembershipsModalsContentDetails>;
  readonly membershipBenefitActivationPending: Maybe<IMembershipsModalsContentDetails>;
  readonly membershipCardRemoval: Maybe<IMembershipsModalsContentDetails>;
  readonly membershipExpired: Maybe<IMembershipsModalsContentDetails>;
  readonly membershipOfferAssignmentFailure: Maybe<IMembershipsModalsContentDetails>;
}

export interface IMembershipsModalsContentDetails {
  readonly __typename?: 'MembershipsModalsContentDetails';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly firstButtonPath: Maybe<ILocaleString>;
  readonly firstButtonText: Maybe<ILocaleString>;
  readonly secondButtonPath: Maybe<ILocaleString>;
  readonly secondButtonText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface IMembershipsModalsContentDetailsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly firstButtonPath?: InputMaybe<ILocaleStringFilter>;
  readonly firstButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly secondButtonPath?: InputMaybe<ILocaleStringFilter>;
  readonly secondButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IMembershipsModalsContentDetailsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly firstButtonPath?: InputMaybe<ILocaleStringSorting>;
  readonly firstButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly secondButtonPath?: InputMaybe<ILocaleStringSorting>;
  readonly secondButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IMembershipsModalsContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly membershipAccountLinkFailure?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
  readonly membershipActivationSuccess?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
  readonly membershipBenefitActivationPending?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
  readonly membershipCardRemoval?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
  readonly membershipExpired?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
  readonly membershipOfferAssignmentFailure?: InputMaybe<IMembershipsModalsContentDetailsFilter>;
}

export interface IMembershipsModalsContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly membershipAccountLinkFailure?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
  readonly membershipActivationSuccess?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
  readonly membershipBenefitActivationPending?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
  readonly membershipCardRemoval?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
  readonly membershipExpired?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
  readonly membershipOfferAssignmentFailure?: InputMaybe<IMembershipsModalsContentDetailsSorting>;
}

export interface IMembershipsPageStyle {
  readonly __typename?: 'MembershipsPageStyle';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<IImage>;
}

export interface IMembershipsPageStyleContent {
  readonly __typename?: 'MembershipsPageStyleContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly actionButtonLink: Maybe<ILocaleString>;
  readonly actionButtonText: Maybe<ILocaleString>;
  readonly informationWidgets: Maybe<IMembershipsInformationWidgets>;
  readonly membershipPageStyle: Maybe<IMembershipsPageStyle>;
}

export interface IMembershipsPageStyleContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly actionButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly actionButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly informationWidgets?: InputMaybe<IMembershipsInformationWidgetsFilter>;
  readonly membershipPageStyle?: InputMaybe<IMembershipsPageStyleFilter>;
}

export interface IMembershipsPageStyleContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly actionButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly actionButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly informationWidgets?: InputMaybe<IMembershipsInformationWidgetsSorting>;
  readonly membershipPageStyle?: InputMaybe<IMembershipsPageStyleSorting>;
}

export interface IMembershipsPageStyleFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
}

export interface IMembershipsPageStyleSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
}

export interface IMembershipsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly membershipBenefit?: InputMaybe<IMembershipsBenefitSorting>;
  readonly membershipIntegrationTypeContent?: InputMaybe<IMembershipsIntegrationTypeContentSorting>;
  readonly membershipInternalName?: InputMaybe<SortOrder>;
  readonly membershipInternalPageConfiguration?: InputMaybe<IMembershipInternalPageConfigurationSorting>;
  readonly membershipModalsContent?: InputMaybe<IMembershipsModalsContentSorting>;
  readonly membershipName?: InputMaybe<SortOrder>;
  readonly membershipPageConfiguration?: InputMaybe<IMembershipsPageStyleContentSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface IMembershipsWidgetContent {
  readonly __typename?: 'MembershipsWidgetContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
  readonly widgetBackgroundColor: Maybe<IColor>;
  readonly widgetImage: Maybe<ILocaleImage>;
}

export interface IMembershipsWidgetContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
  readonly widgetBackgroundColor?: InputMaybe<IColorFilter>;
  readonly widgetImage?: InputMaybe<ILocaleImageFilter>;
}

export interface IMembershipsWidgetContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
  readonly widgetBackgroundColor?: InputMaybe<IColorSorting>;
  readonly widgetImage?: InputMaybe<ILocaleImageSorting>;
}

export interface IMenu extends IDocument {
  readonly __typename?: 'Menu';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly headerSuperText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly headerText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly image: Maybe<IImage>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IComboOrItemOrPickerOrSection>>>;
  /** @deprecated This has been deprecated */
  readonly pickerBackgroundImage: Maybe<IImage>;
}

export interface IMenuFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly headerSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pickerBackgroundImage?: InputMaybe<IImageFilter>;
}

export interface IMenuObjectSettings {
  readonly __typename?: 'MenuObjectSettings';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Maximum quantity allowed in a single order for this menu object. Leave unset to fallback to the the global limit. */
  readonly limitPerOrder: Maybe<Scalars['Float']['output']>;
}

export interface IMenuObjectSettingsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly limitPerOrder?: InputMaybe<IFloatFilter>;
}

export interface IMenuObjectSettingsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly limitPerOrder?: InputMaybe<SortOrder>;
}

export interface IMenuSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly headerSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pickerBackgroundImage?: InputMaybe<IImageSorting>;
}

export interface IMetaTags {
  readonly __typename?: 'MetaTags';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly keywords: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface IMetaTagsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly keywords?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IMetaTagsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly keywords?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IModifier extends IDocument {
  readonly __typename?: 'Modifier';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly name: Maybe<ILocaleString>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /** MDM Source Country/Region */
  readonly region: Maybe<Scalars['String']['output']>;
  readonly specificationName: Maybe<Scalars['String']['output']>;
  readonly specificationNumber: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IModifierFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly region?: InputMaybe<IStringFilter>;
  readonly specificationName?: InputMaybe<IStringFilter>;
  readonly specificationNumber?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IModifierMultiplier extends IDocument {
  readonly __typename?: 'ModifierMultiplier';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly channelExclusions: Maybe<IChannelExclusions>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  readonly modifier: Maybe<IModifier>;
  readonly multiplier: Maybe<Scalars['Float']['output']>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  readonly prefix: Maybe<ILocaleString>;
  /** MDM Source Country/Region */
  readonly region: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated This has been deprecated */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IModifierMultiplierFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly modifier?: InputMaybe<IModifierFilter>;
  readonly multiplier?: InputMaybe<IFloatFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly prefix?: InputMaybe<ILocaleStringFilter>;
  readonly region?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IModifierMultiplierSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly multiplier?: InputMaybe<SortOrder>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly prefix?: InputMaybe<ILocaleStringSorting>;
  readonly region?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IModifierSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly region?: InputMaybe<SortOrder>;
  readonly specificationName?: InputMaybe<SortOrder>;
  readonly specificationNumber?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IMultiWidget {
  readonly __typename?: 'MultiWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Select which widget you would like to render at this position. */
  readonly widget: Maybe<Scalars['String']['output']>;
}

export interface IMultiWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly widget?: InputMaybe<IStringFilter>;
}

export interface IMultiWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly widget?: InputMaybe<SortOrder>;
}

export interface INavigationDesktop {
  readonly __typename?: 'NavigationDesktop';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly aboutMenuEntries: Maybe<INavigationSection>;
  readonly navigationLinks: Maybe<ReadonlyArray<Maybe<INavigationDesktopLink>>>;
  readonly navigationLinksWithServiceModeOrStoreSelected: Maybe<
    ReadonlyArray<Maybe<INavigationDesktopLink>>
  >;
}

export interface INavigationDesktopFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionFilter>;
}

export interface INavigationDesktopLink {
  readonly __typename?: 'NavigationDesktopLink';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly hideLinkBasedOnLdFlag: Maybe<ILaunchDarklySelect>;
  readonly launchDarklyAlternateNavigationLink: Maybe<ILaunchDarklyNavigationLink>;
  readonly navLink: Maybe<ILinkWidget>;
}

export interface INavigationDesktopLinkFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly hideLinkBasedOnLdFlag?: InputMaybe<ILaunchDarklySelectFilter>;
  readonly launchDarklyAlternateNavigationLink?: InputMaybe<ILaunchDarklyNavigationLinkFilter>;
  readonly navLink?: InputMaybe<ILinkWidgetFilter>;
}

export interface INavigationDesktopLinkSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly hideLinkBasedOnLdFlag?: InputMaybe<ILaunchDarklySelectSorting>;
  readonly launchDarklyAlternateNavigationLink?: InputMaybe<ILaunchDarklyNavigationLinkSorting>;
  readonly navLink?: InputMaybe<ILinkWidgetSorting>;
}

export interface INavigationDesktopSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionSorting>;
}

export interface INavigationMobile {
  readonly __typename?: 'NavigationMobile';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly aboutMenuEntries: Maybe<INavigationSection>;
  readonly navigationTabs: Maybe<ReadonlyArray<Maybe<INavigationMobileTab>>>;
  readonly navigationTabsWithServiceModeSelected: Maybe<ReadonlyArray<Maybe<INavigationMobileTab>>>;
}

export interface INavigationMobileFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionFilter>;
}

export interface INavigationMobileHeaderButton {
  readonly __typename?: 'NavigationMobileHeaderButton';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly icon: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly iconActive: Maybe<ILocaleImage>;
  readonly link: Maybe<ILocaleUrl>;
}

export interface INavigationMobileHeaderButtonFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly icon?: InputMaybe<ILocaleImageFilter>;
  readonly iconActive?: InputMaybe<ILocaleImageFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
}

export interface INavigationMobileHeaderButtonSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly icon?: InputMaybe<ILocaleImageSorting>;
  readonly iconActive?: InputMaybe<ILocaleImageSorting>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
}

export interface INavigationMobileSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly aboutMenuEntries?: InputMaybe<INavigationSectionSorting>;
}

export interface INavigationMobileTab {
  readonly __typename?: 'NavigationMobileTab';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly hideLinkBasedOnLdFlag: Maybe<ILaunchDarklySelect>;
  readonly hideOnDesktop: Maybe<Scalars['Boolean']['output']>;
  readonly launchDarklyAlternateMobileTab: Maybe<ILaunchDarklyNavigationTab>;
  readonly tabIcon: Maybe<ILocaleImage>;
  readonly tabIconActive: Maybe<ILocaleImage>;
  readonly tabLink: Maybe<ILinkWidget>;
}

export interface INavigationMobileTabFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly hideLinkBasedOnLdFlag?: InputMaybe<ILaunchDarklySelectFilter>;
  readonly hideOnDesktop?: InputMaybe<IBooleanFilter>;
  readonly launchDarklyAlternateMobileTab?: InputMaybe<ILaunchDarklyNavigationTabFilter>;
  readonly tabIcon?: InputMaybe<ILocaleImageFilter>;
  readonly tabIconActive?: InputMaybe<ILocaleImageFilter>;
  readonly tabLink?: InputMaybe<ILinkWidgetFilter>;
}

export interface INavigationMobileTabSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly hideLinkBasedOnLdFlag?: InputMaybe<ILaunchDarklySelectSorting>;
  readonly hideOnDesktop?: InputMaybe<SortOrder>;
  readonly launchDarklyAlternateMobileTab?: InputMaybe<ILaunchDarklyNavigationTabSorting>;
  readonly tabIcon?: InputMaybe<ILocaleImageSorting>;
  readonly tabIconActive?: InputMaybe<ILocaleImageSorting>;
  readonly tabLink?: InputMaybe<ILinkWidgetSorting>;
}

export interface INavigationSection extends IDocument {
  readonly __typename?: 'NavigationSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly name: Maybe<ILocaleString>;
  readonly pages: Maybe<ReadonlyArray<Maybe<INavigationSectionOrStaticPage>>>;
}

export interface INavigationSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export type INavigationSectionOrStaticPage = INavigationSection | IStaticPage;

export interface INavigationSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface INotificationDetails {
  readonly __typename?: 'NotificationDetails';
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly description: Maybe<ILocaleBlockContent>;
  /**
   * When true, this notification will be enabled
   * @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working.
   */
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly path: Maybe<ILocaleString>;
  /** @deprecated Loyalty Team has to validate if Loyalty Notification Widget is working. */
  readonly title: Maybe<ILocaleString>;
}

export interface INotificationDetailsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly path?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface INotificationDetailsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly enabled?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly path?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface INutrition {
  readonly __typename?: 'Nutrition';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Unit: (Cal) */
  readonly calories: Maybe<Scalars['Float']['output']>;
  /** Unit: (Cal) */
  readonly caloriesPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly carbohydrates: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly carbohydratesPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (mg) */
  readonly cholesterol: Maybe<Scalars['Float']['output']>;
  /** Unit: (KJ) */
  readonly energyKJ: Maybe<Scalars['Float']['output']>;
  /** Unit: (KJ) */
  readonly energyKJPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly fat: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly fatPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly fiber: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly proteins: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly proteinsPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly salt: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly saltPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly saturatedFat: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly saturatedFatPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (mg) */
  readonly sodium: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly sugar: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly sugarPer100: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly transFat: Maybe<Scalars['Float']['output']>;
  /** Unit: (g) */
  readonly weight: Maybe<Scalars['Float']['output']>;
}

export interface INutritionExplorerWidget {
  readonly __typename?: 'NutritionExplorerWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly categoryWhitelist: Maybe<ReadonlyArray<Maybe<IPickerOrSection>>>;
  readonly madLibFilterGroup: Maybe<IFilterGroup>;
  readonly menu: Maybe<IMenu>;
  readonly modalFilterGroups: Maybe<ReadonlyArray<Maybe<IFilterGroup>>>;
  readonly moreOptionsButtonText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated. */
  readonly tastySwaps: Maybe<ITastySwaps>;
  readonly viewMenuButtonText: Maybe<ILocaleString>;
}

export interface INutritionExplorerWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly madLibFilterGroup?: InputMaybe<IFilterGroupFilter>;
  readonly menu?: InputMaybe<IMenuFilter>;
  readonly moreOptionsButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly tastySwaps?: InputMaybe<ITastySwapsFilter>;
  readonly viewMenuButtonText?: InputMaybe<ILocaleStringFilter>;
}

export interface INutritionExplorerWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly madLibFilterGroup?: InputMaybe<IFilterGroupSorting>;
  readonly moreOptionsButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly tastySwaps?: InputMaybe<ITastySwapsSorting>;
  readonly viewMenuButtonText?: InputMaybe<ILocaleStringSorting>;
}

export interface INutritionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly calories?: InputMaybe<IFloatFilter>;
  readonly caloriesPer100?: InputMaybe<IFloatFilter>;
  readonly carbohydrates?: InputMaybe<IFloatFilter>;
  readonly carbohydratesPer100?: InputMaybe<IFloatFilter>;
  readonly cholesterol?: InputMaybe<IFloatFilter>;
  readonly energyKJ?: InputMaybe<IFloatFilter>;
  readonly energyKJPer100?: InputMaybe<IFloatFilter>;
  readonly fat?: InputMaybe<IFloatFilter>;
  readonly fatPer100?: InputMaybe<IFloatFilter>;
  readonly fiber?: InputMaybe<IFloatFilter>;
  readonly proteins?: InputMaybe<IFloatFilter>;
  readonly proteinsPer100?: InputMaybe<IFloatFilter>;
  readonly salt?: InputMaybe<IFloatFilter>;
  readonly saltPer100?: InputMaybe<IFloatFilter>;
  readonly saturatedFat?: InputMaybe<IFloatFilter>;
  readonly saturatedFatPer100?: InputMaybe<IFloatFilter>;
  readonly sodium?: InputMaybe<IFloatFilter>;
  readonly sugar?: InputMaybe<IFloatFilter>;
  readonly sugarPer100?: InputMaybe<IFloatFilter>;
  readonly transFat?: InputMaybe<IFloatFilter>;
  readonly weight?: InputMaybe<IFloatFilter>;
}

export interface INutritionInfoWidget {
  readonly __typename?: 'NutritionInfoWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly nutritionalSection: Maybe<INutritionalSection>;
}

export interface INutritionInfoWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly nutritionalSection?: InputMaybe<INutritionalSectionFilter>;
}

export interface INutritionInfoWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly nutritionalSection?: InputMaybe<INutritionalSectionSorting>;
}

export interface INutritionItem {
  readonly __typename?: 'NutritionItem';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly additionalItems: Maybe<ReadonlyArray<Maybe<INutritionItem>>>;
  readonly name: Maybe<ILocaleString>;
  /** The key to the corresponding row in the nutrition data per item, e.g. calories or caloriesPer100 */
  readonly nutritionKey: Maybe<Scalars['String']['output']>;
  /** When enabled, the unit for this nutrition item won't be displayed next to the value. To display the unit, it should be manually added to the nutrition item name, e.g., 'Fett (g)'. */
  readonly omitUnitFromValue: Maybe<Scalars['Boolean']['output']>;
  readonly unit: Maybe<ILocaleString>;
}

export interface INutritionItemFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly nutritionKey?: InputMaybe<IStringFilter>;
  readonly omitUnitFromValue?: InputMaybe<IBooleanFilter>;
  readonly unit?: InputMaybe<ILocaleStringFilter>;
}

export interface INutritionItemGroup {
  readonly __typename?: 'NutritionItemGroup';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly lineItems: Maybe<ReadonlyArray<Maybe<INutritionItem>>>;
  readonly mainItem: Maybe<INutritionItem>;
}

export interface INutritionItemGroupFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly mainItem?: InputMaybe<INutritionItemFilter>;
}

export interface INutritionItemGroupSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly mainItem?: InputMaybe<INutritionItemSorting>;
}

export type INutritionItemOrNutritionItemGroup = INutritionItem | INutritionItemGroup;

export interface INutritionItemSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly nutritionKey?: InputMaybe<SortOrder>;
  readonly omitUnitFromValue?: InputMaybe<SortOrder>;
  readonly unit?: InputMaybe<ILocaleStringSorting>;
}

export interface INutritionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly calories?: InputMaybe<SortOrder>;
  readonly caloriesPer100?: InputMaybe<SortOrder>;
  readonly carbohydrates?: InputMaybe<SortOrder>;
  readonly carbohydratesPer100?: InputMaybe<SortOrder>;
  readonly cholesterol?: InputMaybe<SortOrder>;
  readonly energyKJ?: InputMaybe<SortOrder>;
  readonly energyKJPer100?: InputMaybe<SortOrder>;
  readonly fat?: InputMaybe<SortOrder>;
  readonly fatPer100?: InputMaybe<SortOrder>;
  readonly fiber?: InputMaybe<SortOrder>;
  readonly proteins?: InputMaybe<SortOrder>;
  readonly proteinsPer100?: InputMaybe<SortOrder>;
  readonly salt?: InputMaybe<SortOrder>;
  readonly saltPer100?: InputMaybe<SortOrder>;
  readonly saturatedFat?: InputMaybe<SortOrder>;
  readonly saturatedFatPer100?: InputMaybe<SortOrder>;
  readonly sodium?: InputMaybe<SortOrder>;
  readonly sugar?: InputMaybe<SortOrder>;
  readonly sugarPer100?: InputMaybe<SortOrder>;
  readonly transFat?: InputMaybe<SortOrder>;
  readonly weight?: InputMaybe<SortOrder>;
}

export interface INutritionTable {
  readonly __typename?: 'NutritionTable';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly items: Maybe<ReadonlyArray<Maybe<INutritionItemOrNutritionItemGroup>>>;
  readonly title: Maybe<ILocaleString>;
}

export interface INutritionTableFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface INutritionTableSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface INutritionalSection extends IDocument {
  readonly __typename?: 'NutritionalSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly name: Maybe<ILocaleString>;
  readonly products: Maybe<ReadonlyArray<Maybe<IItemOrNutritionalSection>>>;
}

export interface INutritionalSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface INutritionalSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IOffer extends IDocument {
  readonly __typename?: 'Offer';
  /**
   * Date the document was created
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly activationDate: Maybe<Scalars['Date']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly backgroundImage: Maybe<IImage>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly channelExclusions: Maybe<IChannelExclusions>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly daypart: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly deactivationDate: Maybe<Scalars['Date']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly dmbName: Maybe<ILocaleString>;
  /**
   * Configure an offer which will be available for guests
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  /**
   * This value will be used only for support coupons to be displayed in the support tool
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly forSupport: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly headerSuperText: Maybe<ILocaleString>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly images: Maybe<IImages>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly incentives: Maybe<
    ReadonlyArray<
      Maybe<IComboOrItemOrOfferActivationOrOfferDiscountOrOfferItemDiscountOrOfferLoyaltyActionOrPicker>
    >
  >;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly localizedImage: Maybe<ILocaleImages>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly lockedOffersPanel: Maybe<ILockedOffersPanel>;
  /**
   * If offer should display the market price of an item (ie: BOGO) link the menu item here
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly marketPrice: Maybe<IComboOrItem>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  /**
   * This offer must be assigned to individual users
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly mustBeAssigned: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly name: Maybe<ILocaleBlockContent>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly offerCTA: Maybe<ILinkAction>;
  /**
   * This is a field relevant for NZ and will be used for offers that require discount amount, input amount in cents. If you are not NZ do not use it
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly offerDiscount: Maybe<Scalars['Float']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly offerPrice: Maybe<Scalars['Float']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly offerTags: Maybe<ReadonlyArray<Maybe<ITag>>>;
  /**
   * This is the benefit of the offer, eg the combo or item you will receive for redeeming the offer.
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly option: Maybe<IComboOrItemOrOfferActivationOrOfferDiscountOrOfferLoyaltyActionOrPicker>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly percentageDiscount: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly pluConfigs: Maybe<IPluConfigs>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly prices: Maybe<ReadonlyArray<Maybe<IPricingRegionPrice>>>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly pricingDistributionValidation: Maybe<IDataValidationObject>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly pricingPermutationValidation: Maybe<IDataValidationObject>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly promoCodePanel: Maybe<IPromoCodePanel>;
  /**
   * Specify an order redemption method (this option overrides the "For Support?" option above).
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly redemptionMethod: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly requirements: Maybe<IRequirements>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IAllowRedemptionWithoutLoginOrAndOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrOrOrOrderHistoryOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions>
    >
  >;
  /**
   * Configure the code used for in restaurant redemption.
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly shortCode: Maybe<Scalars['String']['output']>;
  /**
   * Configure UI pattern to show on the frontend.
   * @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers
   */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly upsellOptions: Maybe<ReadonlyArray<Maybe<IOffer>>>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly vendorConfigs: Maybe<IVendorConfigs>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly vendorPlus: Maybe<IVendorPlus>;
}

export interface IOfferActivation extends IDocument {
  readonly __typename?: 'OfferActivation';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly fakeField: Maybe<Scalars['Boolean']['output']>;
}

export interface IOfferActivationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly fakeField?: InputMaybe<IBooleanFilter>;
}

export interface IOfferActivationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly fakeField?: InputMaybe<SortOrder>;
}

export interface IOfferCampaign extends IDocument {
  readonly __typename?: 'OfferCampaign';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** A Name for the campaign */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly sortedSystemwideOffers: Maybe<ReadonlyArray<Maybe<ISystemwideOffer>>>;
}

export interface IOfferCampaignFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<IStringFilter>;
}

export interface IOfferCampaignSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IOfferCarouselSection extends IDocument {
  readonly __typename?: 'OfferCarouselSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly offerCarouselList: Maybe<ReadonlyArray<Maybe<IExternalOffersOrSystemwideOffer>>>;
  readonly offerCarouselTitle: Maybe<ILocaleString>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IOfferCarouselSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly offerCarouselTitle?: InputMaybe<ILocaleStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IOfferCarouselSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly offerCarouselTitle?: InputMaybe<ILocaleStringSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IOfferDiscount extends IDocument {
  readonly __typename?: 'OfferDiscount';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Optional: Choose a specific product on which to apply this discount. */
  readonly discountProduct: Maybe<IComboOrItem>;
  readonly discountType: Maybe<Scalars['String']['output']>;
  readonly discountValue: Maybe<Scalars['Float']['output']>;
}

export interface IOfferDiscountFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly discountType?: InputMaybe<IStringFilter>;
  readonly discountValue?: InputMaybe<IFloatFilter>;
}

export interface IOfferDiscountSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly discountType?: InputMaybe<SortOrder>;
  readonly discountValue?: InputMaybe<SortOrder>;
}

export interface IOfferFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly activationDate?: InputMaybe<IDateFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly deactivationDate?: InputMaybe<IDateFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly dmbName?: InputMaybe<ILocaleStringFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
  readonly forSupport?: InputMaybe<IBooleanFilter>;
  readonly headerSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly localizedImage?: InputMaybe<ILocaleImagesFilter>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly mustBeAssigned?: InputMaybe<IBooleanFilter>;
  readonly name?: InputMaybe<ILocaleBlockContentFilter>;
  readonly offerCTA?: InputMaybe<ILinkActionFilter>;
  readonly offerDiscount?: InputMaybe<IFloatFilter>;
  readonly offerPrice?: InputMaybe<IFloatFilter>;
  readonly percentageDiscount?: InputMaybe<IBooleanFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelFilter>;
  readonly redemptionMethod?: InputMaybe<IStringFilter>;
  readonly requirements?: InputMaybe<IRequirementsFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly shortCode?: InputMaybe<IStringFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
  readonly vendorPlus?: InputMaybe<IVendorPlusFilter>;
}

export interface IOfferItemDiscount extends IDocument {
  readonly __typename?: 'OfferItemDiscount';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly discountType: Maybe<Scalars['String']['output']>;
  /** The value of the discount to be applied. Dollar values (fixed, flat) are represented as dollars (e.g. 3.53 => $3.53) while percent is represented as integers (25 => 25%). */
  readonly discountValue: Maybe<Scalars['Float']['output']>;
  readonly option: Maybe<IComboOrItemOrPicker>;
}

export interface IOfferItemDiscountFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly discountType?: InputMaybe<IStringFilter>;
  readonly discountValue?: InputMaybe<IFloatFilter>;
}

export interface IOfferItemDiscountSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly discountType?: InputMaybe<SortOrder>;
  readonly discountValue?: InputMaybe<SortOrder>;
}

export interface IOfferLoyaltyAction extends IDocument {
  readonly __typename?: 'OfferLoyaltyAction';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly loyaltyType: Maybe<Scalars['String']['output']>;
  /** Must be an integer. Can be negative (e.g. redeeming a coffee that costs 70 points). */
  readonly loyaltyValue: Maybe<Scalars['Float']['output']>;
}

export interface IOfferLoyaltyActionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly loyaltyType?: InputMaybe<IStringFilter>;
  readonly loyaltyValue?: InputMaybe<IFloatFilter>;
}

export interface IOfferLoyaltyActionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly loyaltyType?: InputMaybe<SortOrder>;
  readonly loyaltyValue?: InputMaybe<SortOrder>;
}

export interface IOfferRedemptionLimits {
  readonly __typename?: 'OfferRedemptionLimits';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** This represents the maximum number of times the same offer can be added. */
  readonly sameOfferLimit: Maybe<Scalars['Float']['output']>;
  /** This represents the number of stackable standard systemwide or config offers allowed per transaction. Standard offers do not include swap or surprise offers. Default is one. */
  readonly standardOffersLimit: Maybe<Scalars['Float']['output']>;
}

export interface IOfferRedemptionLimitsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly sameOfferLimit?: InputMaybe<IFloatFilter>;
  readonly standardOffersLimit?: InputMaybe<IFloatFilter>;
}

export interface IOfferRedemptionLimitsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly sameOfferLimit?: InputMaybe<SortOrder>;
  readonly standardOffersLimit?: InputMaybe<SortOrder>;
}

export interface IOfferRequirementCode {
  readonly __typename?: 'OfferRequirementCode';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Code to redeem in store */
  readonly shortCode: Maybe<Scalars['String']['output']>;
}

export interface IOfferRequirementCodeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly shortCode?: InputMaybe<IStringFilter>;
}

export interface IOfferRequirementCodeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly shortCode?: InputMaybe<SortOrder>;
}

export interface IOfferRequirementPlatform {
  readonly __typename?: 'OfferRequirementPlatform';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly platform: Maybe<Scalars['String']['output']>;
}

export interface IOfferRequirementPlatformFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly platform?: InputMaybe<IStringFilter>;
}

export interface IOfferRequirementPlatformSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly platform?: InputMaybe<SortOrder>;
}

export interface IOfferRequirementPurchase {
  readonly __typename?: 'OfferRequirementPurchase';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Required purchase in order to redeem */
  readonly option: Maybe<IComboOrItem>;
}

export interface IOfferRequirementPurchaseFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IOfferRequirementPurchaseSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IOfferRequirementUser {
  readonly __typename?: 'OfferRequirementUser';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
}

export interface IOfferRequirementUserFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly status?: InputMaybe<IStringFilter>;
}

export interface IOfferRequirementUserSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly status?: InputMaybe<SortOrder>;
}

export interface IOfferSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly activationDate?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly deactivationDate?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly dmbName?: InputMaybe<ILocaleStringSorting>;
  readonly documentDescription?: InputMaybe<SortOrder>;
  readonly forSupport?: InputMaybe<SortOrder>;
  readonly headerSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly localizedImage?: InputMaybe<ILocaleImagesSorting>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelSorting>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly mustBeAssigned?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleBlockContentSorting>;
  readonly offerCTA?: InputMaybe<ILinkActionSorting>;
  readonly offerDiscount?: InputMaybe<SortOrder>;
  readonly offerPrice?: InputMaybe<SortOrder>;
  readonly percentageDiscount?: InputMaybe<SortOrder>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelSorting>;
  readonly redemptionMethod?: InputMaybe<SortOrder>;
  readonly requirements?: InputMaybe<IRequirementsSorting>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly shortCode?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
  readonly vendorPlus?: InputMaybe<IVendorPlusSorting>;
}

export interface IOfferTemplate extends IDocument {
  readonly __typename?: 'OfferTemplate';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  /** Specify the Config Offer to use with this template */
  readonly configIncentive: Maybe<IConfigOffer>;
  readonly description: Maybe<ILocaleSmartBlockContent>;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  readonly localizedImage: Maybe<ILocaleImages>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleSmartBlockContent>;
  readonly requiredRules: Maybe<IRequiredRules>;
  /** Determines where this template can be used */
  readonly templateType: Maybe<Scalars['String']['output']>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface IOfferTemplateFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly configIncentive?: InputMaybe<IConfigOfferFilter>;
  readonly description?: InputMaybe<ILocaleSmartBlockContentFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly localizedImage?: InputMaybe<ILocaleImagesFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleSmartBlockContentFilter>;
  readonly requiredRules?: InputMaybe<IRequiredRulesFilter>;
  readonly templateType?: InputMaybe<IStringFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface IOfferTemplateSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly description?: InputMaybe<ILocaleSmartBlockContentSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly localizedImage?: InputMaybe<ILocaleImagesSorting>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleSmartBlockContentSorting>;
  readonly requiredRules?: InputMaybe<IRequiredRulesSorting>;
  readonly templateType?: InputMaybe<SortOrder>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface IOffersBrowsingPanel {
  readonly __typename?: 'OffersBrowsingPanel';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly emptyStateBody: Maybe<ILocaleString>;
  readonly emptyStateImage: Maybe<ILocaleImage>;
  readonly emptyStateTitle: Maybe<ILocaleString>;
  readonly endOfOffersImage: Maybe<ILocaleImage>;
  readonly endOfOffersMessage: Maybe<ILocaleString>;
  readonly offersSelectRestaurantCta: Maybe<IOffersSelectRestaurantCta>;
}

export interface IOffersBrowsingPanelFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly emptyStateBody?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateImage?: InputMaybe<ILocaleImageFilter>;
  readonly emptyStateTitle?: InputMaybe<ILocaleStringFilter>;
  readonly endOfOffersImage?: InputMaybe<ILocaleImageFilter>;
  readonly endOfOffersMessage?: InputMaybe<ILocaleStringFilter>;
  readonly offersSelectRestaurantCta?: InputMaybe<IOffersSelectRestaurantCtaFilter>;
}

export interface IOffersBrowsingPanelSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly emptyStateBody?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateImage?: InputMaybe<ILocaleImageSorting>;
  readonly emptyStateTitle?: InputMaybe<ILocaleStringSorting>;
  readonly endOfOffersImage?: InputMaybe<ILocaleImageSorting>;
  readonly endOfOffersMessage?: InputMaybe<ILocaleStringSorting>;
  readonly offersSelectRestaurantCta?: InputMaybe<IOffersSelectRestaurantCtaSorting>;
}

export interface IOffersDetailsPanel {
  readonly __typename?: 'OffersDetailsPanel';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly emptyStateAction: Maybe<ILinkAction>;
  readonly emptyStateBody: Maybe<ILocaleString>;
  readonly emptyStateImage: Maybe<ILocaleImage>;
  readonly emptyStateTitle: Maybe<ILocaleString>;
  readonly missingOfferAction: Maybe<ILinkAction>;
  readonly missingOfferBody: Maybe<ILocaleString>;
  readonly missingOfferImage: Maybe<ILocaleImage>;
  readonly missingOfferTitle: Maybe<ILocaleString>;
}

export interface IOffersDetailsPanelFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly emptyStateAction?: InputMaybe<ILinkActionFilter>;
  readonly emptyStateBody?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateImage?: InputMaybe<ILocaleImageFilter>;
  readonly emptyStateTitle?: InputMaybe<ILocaleStringFilter>;
  readonly missingOfferAction?: InputMaybe<ILinkActionFilter>;
  readonly missingOfferBody?: InputMaybe<ILocaleStringFilter>;
  readonly missingOfferImage?: InputMaybe<ILocaleImageFilter>;
  readonly missingOfferTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IOffersDetailsPanelSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly emptyStateAction?: InputMaybe<ILinkActionSorting>;
  readonly emptyStateBody?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateImage?: InputMaybe<ILocaleImageSorting>;
  readonly emptyStateTitle?: InputMaybe<ILocaleStringSorting>;
  readonly missingOfferAction?: InputMaybe<ILinkActionSorting>;
  readonly missingOfferBody?: InputMaybe<ILocaleStringSorting>;
  readonly missingOfferImage?: InputMaybe<ILocaleImageSorting>;
  readonly missingOfferTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IOffersEmptyStates {
  readonly __typename?: 'OffersEmptyStates';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ctaButtonEmptyStateFavoriteOffersLink: Maybe<ILocaleString>;
  readonly ctaButtonEmptyStateFavoriteOffersText: Maybe<ILocaleString>;
  readonly ctaButtonLink: Maybe<ILocaleString>;
  readonly ctaButtonText: Maybe<ILocaleString>;
  readonly emptyStateFavoriteOffersDescription: Maybe<ILocaleString>;
  readonly emptyStateFavoriteOffersImage: Maybe<ILocaleImage>;
  readonly emptyStateFavoriteOffersTitle: Maybe<ILocaleString>;
  readonly emptyStateOffersDescription: Maybe<ILocaleString>;
  readonly emptyStateOffersImage: Maybe<ILocaleImage>;
  readonly emptyStateOffersTitle: Maybe<ILocaleString>;
}

export interface IOffersEmptyStatesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ctaButtonEmptyStateFavoriteOffersLink?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonEmptyStateFavoriteOffersText?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonLink?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateFavoriteOffersDescription?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateFavoriteOffersImage?: InputMaybe<ILocaleImageFilter>;
  readonly emptyStateFavoriteOffersTitle?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateOffersDescription?: InputMaybe<ILocaleStringFilter>;
  readonly emptyStateOffersImage?: InputMaybe<ILocaleImageFilter>;
  readonly emptyStateOffersTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IOffersEmptyStatesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ctaButtonEmptyStateFavoriteOffersLink?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonEmptyStateFavoriteOffersText?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonLink?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateFavoriteOffersDescription?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateFavoriteOffersImage?: InputMaybe<ILocaleImageSorting>;
  readonly emptyStateFavoriteOffersTitle?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateOffersDescription?: InputMaybe<ILocaleStringSorting>;
  readonly emptyStateOffersImage?: InputMaybe<ILocaleImageSorting>;
  readonly emptyStateOffersTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IOffersPageMarketingTile {
  readonly __typename?: 'OffersPageMarketingTile';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** (select "custom" from the display position above to use this field). The number chosen will correspond to its position in the offers pannel. 2 is the second slot at the top, higher numbers means lower position. */
  readonly customPosition: Maybe<Scalars['Float']['output']>;
  /** Determines the position in the offers panel that the marketing tile will appear. */
  readonly displayPosition: Maybe<Scalars['String']['output']>;
  readonly marketingTileReference: Maybe<IMarketingTile>;
}

export interface IOffersPageMarketingTileFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly customPosition?: InputMaybe<IFloatFilter>;
  readonly displayPosition?: InputMaybe<IStringFilter>;
  readonly marketingTileReference?: InputMaybe<IMarketingTileFilter>;
}

export interface IOffersPageMarketingTileSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly customPosition?: InputMaybe<SortOrder>;
  readonly displayPosition?: InputMaybe<SortOrder>;
}

export interface IOffersSection extends IDocument {
  readonly __typename?: 'OffersSection';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /**
   * Used only as an organizational aid. Not displayed in the app.
   * @deprecated This is deprecated
   */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IOffersSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IOffersSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IOffersSelectRestaurantCta {
  readonly __typename?: 'OffersSelectRestaurantCta';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly ctaButtonText: Maybe<ILocaleString>;
  readonly link: Maybe<ILocaleUrl>;
  /** Minimum number of national offers before CTA card hides those that are available. */
  readonly minimumNumberOfOffers: Maybe<Scalars['Float']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface IOffersSelectRestaurantCtaFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly link?: InputMaybe<ILocaleUrlFilter>;
  readonly minimumNumberOfOffers?: InputMaybe<IFloatFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IOffersSelectRestaurantCtaSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly ctaButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly link?: InputMaybe<ILocaleUrlSorting>;
  readonly minimumNumberOfOffers?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IOgTags {
  readonly __typename?: 'OgTags';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly imageUrl: Maybe<ILocaleUrl>;
  readonly title: Maybe<ILocaleString>;
  readonly url: Maybe<ILocaleUrl>;
}

export interface IOgTagsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly imageUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
  readonly url?: InputMaybe<ILocaleUrlFilter>;
}

export interface IOgTagsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly imageUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
  readonly url?: InputMaybe<ILocaleUrlSorting>;
}

export interface IOneTrustConfig {
  readonly __typename?: 'OneTrustConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cookieCompliance: Maybe<ICookieComplianceConfig>;
  readonly deleteAccountUrl: Maybe<Scalars['String']['output']>;
}

export interface IOneTrustConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cookieCompliance?: InputMaybe<ICookieComplianceConfigFilter>;
  readonly deleteAccountUrl?: InputMaybe<IStringFilter>;
}

export interface IOneTrustConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cookieCompliance?: InputMaybe<ICookieComplianceConfigSorting>;
  readonly deleteAccountUrl?: InputMaybe<SortOrder>;
}

export interface IOpAllergen {
  readonly __typename?: 'OpAllergen';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly celery: Maybe<Scalars['Float']['output']>;
  readonly eggs: Maybe<Scalars['Float']['output']>;
  readonly fish: Maybe<Scalars['Float']['output']>;
  readonly gluten: Maybe<Scalars['Float']['output']>;
  readonly lupin: Maybe<Scalars['Float']['output']>;
  readonly milk: Maybe<Scalars['Float']['output']>;
  readonly mustard: Maybe<Scalars['Float']['output']>;
  readonly peanuts: Maybe<Scalars['Float']['output']>;
  readonly sesame: Maybe<Scalars['Float']['output']>;
  readonly shellfish: Maybe<Scalars['Float']['output']>;
  readonly soy: Maybe<Scalars['Float']['output']>;
  readonly sulphurDioxide: Maybe<Scalars['Float']['output']>;
  readonly treeNuts: Maybe<Scalars['Float']['output']>;
  readonly wheat: Maybe<Scalars['Float']['output']>;
}

export interface IOpAllergenFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly celery?: InputMaybe<IFloatFilter>;
  readonly eggs?: InputMaybe<IFloatFilter>;
  readonly fish?: InputMaybe<IFloatFilter>;
  readonly gluten?: InputMaybe<IFloatFilter>;
  readonly lupin?: InputMaybe<IFloatFilter>;
  readonly milk?: InputMaybe<IFloatFilter>;
  readonly mustard?: InputMaybe<IFloatFilter>;
  readonly peanuts?: InputMaybe<IFloatFilter>;
  readonly sesame?: InputMaybe<IFloatFilter>;
  readonly shellfish?: InputMaybe<IFloatFilter>;
  readonly soy?: InputMaybe<IFloatFilter>;
  readonly sulphurDioxide?: InputMaybe<IFloatFilter>;
  readonly treeNuts?: InputMaybe<IFloatFilter>;
  readonly wheat?: InputMaybe<IFloatFilter>;
}

export interface IOpAllergenSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly celery?: InputMaybe<SortOrder>;
  readonly eggs?: InputMaybe<SortOrder>;
  readonly fish?: InputMaybe<SortOrder>;
  readonly gluten?: InputMaybe<SortOrder>;
  readonly lupin?: InputMaybe<SortOrder>;
  readonly milk?: InputMaybe<SortOrder>;
  readonly mustard?: InputMaybe<SortOrder>;
  readonly peanuts?: InputMaybe<SortOrder>;
  readonly sesame?: InputMaybe<SortOrder>;
  readonly shellfish?: InputMaybe<SortOrder>;
  readonly soy?: InputMaybe<SortOrder>;
  readonly sulphurDioxide?: InputMaybe<SortOrder>;
  readonly treeNuts?: InputMaybe<SortOrder>;
  readonly wheat?: InputMaybe<SortOrder>;
}

export interface IOpItem {
  readonly __typename?: 'OpItem';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly daypart: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly launchDate: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly product: Maybe<Scalars['String']['output']>;
  readonly productNumber: Maybe<Scalars['Float']['output']>;
}

export interface IOpItemFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly launchDate?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly product?: InputMaybe<IStringFilter>;
  readonly productNumber?: InputMaybe<IFloatFilter>;
}

export interface IOpItemSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly launchDate?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly product?: InputMaybe<SortOrder>;
  readonly productNumber?: InputMaybe<SortOrder>;
}

export interface IOpPricingRegion extends IDocument {
  readonly __typename?: 'OpPricingRegion';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly restaurants: Maybe<ReadonlyArray<Maybe<IRestaurant>>>;
}

export interface IOpPricingRegionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<IStringFilter>;
}

export interface IOpPricingRegionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IOptInModalContent {
  readonly __typename?: 'OptInModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly blockText: Maybe<ILocaleBlockContent>;
  readonly buttonText: Maybe<ILocaleString>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
  readonly title: Maybe<ILocaleString>;
}

export interface IOptInModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly blockText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly buttonText?: InputMaybe<ILocaleStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IOptInModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly blockText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly buttonText?: InputMaybe<ILocaleStringSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInModalLoading {
  readonly __typename?: 'OptInModalLoading';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface IOptInModalLoadingFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IOptInModalLoadingSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInPrivacyPreferences {
  readonly __typename?: 'OptInPrivacyPreferences';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly key: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly view: Maybe<ILocaleString>;
}

export interface IOptInPrivacyPreferencesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly view?: InputMaybe<ILocaleStringFilter>;
}

export type IOptInPrivacyPreferencesOrOptInSettingBooleanOrOptInSettingEmailOrOptInSettingEmailAndPushOrOptInSettingEnumOrOptInSettingPushOrOptInSettingSms =

    | IOptInPrivacyPreferences
    | IOptInSettingBoolean
    | IOptInSettingEmail
    | IOptInSettingEmailAndPush
    | IOptInSettingEnum
    | IOptInSettingPush
    | IOptInSettingSms;

export interface IOptInPrivacyPreferencesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly key?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly view?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInSettingBoolean {
  readonly __typename?: 'OptInSettingBoolean';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly key: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface IOptInSettingBooleanFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export type IOptInSettingBooleanOrOptInSettingEmailAndPushOrOptInSettingEnum =
  | IOptInSettingBoolean
  | IOptInSettingEmailAndPush
  | IOptInSettingEnum;

export interface IOptInSettingBooleanSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly key?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInSettingEmail {
  readonly __typename?: 'OptInSettingEmail';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly emailKey: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface IOptInSettingEmailAndPush {
  readonly __typename?: 'OptInSettingEmailAndPush';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly emailKey: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly pushKey: Maybe<Scalars['String']['output']>;
  /** On true, the FE will display only one tickBox for manage Email & Push values */
  readonly singleTickBox: Maybe<Scalars['Boolean']['output']>;
}

export interface IOptInSettingEmailAndPushFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly emailKey?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pushKey?: InputMaybe<IStringFilter>;
  readonly singleTickBox?: InputMaybe<IBooleanFilter>;
}

export interface IOptInSettingEmailAndPushSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly emailKey?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pushKey?: InputMaybe<SortOrder>;
  readonly singleTickBox?: InputMaybe<SortOrder>;
}

export interface IOptInSettingEmailFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly emailKey?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IOptInSettingEmailSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly emailKey?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInSettingEnum {
  readonly __typename?: 'OptInSettingEnum';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly enumStrings: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** This is the id the backend will use to save the users value as */
  readonly key: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface IOptInSettingEnumFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly key?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IOptInSettingEnumSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly key?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IOptInSettingPush {
  readonly __typename?: 'OptInSettingPush';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly name: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly pushKey: Maybe<Scalars['String']['output']>;
}

export interface IOptInSettingPushFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly pushKey?: InputMaybe<IStringFilter>;
}

export interface IOptInSettingPushSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly pushKey?: InputMaybe<SortOrder>;
}

export interface IOptInSettingSms {
  readonly __typename?: 'OptInSettingSms';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly name: Maybe<ILocaleString>;
  /** This is the id the backend will use to save the users value as */
  readonly smsKey: Maybe<Scalars['String']['output']>;
}

export interface IOptInSettingSmsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly smsKey?: InputMaybe<IStringFilter>;
}

export interface IOptInSettingSmsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly smsKey?: InputMaybe<SortOrder>;
}

export interface IOptionVisibilitySettings {
  readonly __typename?: 'OptionVisibilitySettings';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly toggleButtonTextClosed: Maybe<ILocaleString>;
  readonly toggleButtonTextOpen: Maybe<ILocaleString>;
  /** The number of options to show by default. Remaining options will only show after a button click. Leave empty to show all options. */
  readonly visibleOptions: Maybe<Scalars['Float']['output']>;
}

export interface IOptionVisibilitySettingsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly toggleButtonTextClosed?: InputMaybe<ILocaleStringFilter>;
  readonly toggleButtonTextOpen?: InputMaybe<ILocaleStringFilter>;
  readonly visibleOptions?: InputMaybe<IFloatFilter>;
}

export interface IOptionVisibilitySettingsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly toggleButtonTextClosed?: InputMaybe<ILocaleStringSorting>;
  readonly toggleButtonTextOpen?: InputMaybe<ILocaleStringSorting>;
  readonly visibleOptions?: InputMaybe<SortOrder>;
}

export interface IOr {
  readonly __typename?: 'Or';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IAllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions>
    >
  >;
}

export interface IOrFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IOrSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IOrbitalConfig {
  readonly __typename?: 'OrbitalConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly baseUrl: Maybe<Scalars['String']['output']>;
  readonly mode: Maybe<Scalars['String']['output']>;
  readonly pieFormat: Maybe<Scalars['String']['output']>;
  readonly subscriberId: Maybe<Scalars['String']['output']>;
}

export interface IOrbitalConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly baseUrl?: InputMaybe<IStringFilter>;
  readonly mode?: InputMaybe<IStringFilter>;
  readonly pieFormat?: InputMaybe<IStringFilter>;
  readonly subscriberId?: InputMaybe<IStringFilter>;
}

export interface IOrbitalConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly baseUrl?: InputMaybe<SortOrder>;
  readonly mode?: InputMaybe<SortOrder>;
  readonly pieFormat?: InputMaybe<SortOrder>;
  readonly subscriberId?: InputMaybe<SortOrder>;
}

export interface IOrderHistory {
  readonly __typename?: 'OrderHistory';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Query orders from (including) this date... */
  readonly from: Maybe<Scalars['Date']['output']>;
  /** User must perform each step in the order specified via the Rule Set */
  readonly isSequential: Maybe<Scalars['Boolean']['output']>;
  /** Look back in user history n seconds */
  readonly lastNSeconds: Maybe<Scalars['Float']['output']>;
  /** Indicates what time range to use */
  readonly rangeType: Maybe<Scalars['String']['output']>;
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IAndOrBetweenTimesOrCartContainsPluOrCartPropertyOrDayOfWeekBandOrOrOrPaymentCardTypeOrPurchase>
    >
  >;
  /** Until (including) this date */
  readonly to: Maybe<Scalars['Date']['output']>;
}

export interface IOrderHistoryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly from?: InputMaybe<IDateFilter>;
  readonly isSequential?: InputMaybe<IBooleanFilter>;
  readonly lastNSeconds?: InputMaybe<IFloatFilter>;
  readonly rangeType?: InputMaybe<IStringFilter>;
  readonly to?: InputMaybe<IDateFilter>;
}

export interface IOrderHistorySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly from?: InputMaybe<SortOrder>;
  readonly isSequential?: InputMaybe<SortOrder>;
  readonly lastNSeconds?: InputMaybe<SortOrder>;
  readonly rangeType?: InputMaybe<SortOrder>;
  readonly to?: InputMaybe<SortOrder>;
}

export interface IOurServices extends IDocument {
  readonly __typename?: 'OurServices';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly ourServicesHeroImageLocale: Maybe<ILocaleImage>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly servicesHeaderTextLocale: Maybe<ILocaleString>;
  readonly servicesHeroTextLocale: Maybe<ILocaleString>;
}

export interface IOurServicesFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly ourServicesHeroImageLocale?: InputMaybe<ILocaleImageFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly servicesHeaderTextLocale?: InputMaybe<ILocaleStringFilter>;
  readonly servicesHeroTextLocale?: InputMaybe<ILocaleStringFilter>;
}

export interface IOurServicesSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly ourServicesHeroImageLocale?: InputMaybe<ILocaleImageSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly servicesHeaderTextLocale?: InputMaybe<ILocaleStringSorting>;
  readonly servicesHeroTextLocale?: InputMaybe<ILocaleStringSorting>;
}

export interface IOverrideHoursInput {
  readonly __typename?: 'OverrideHoursInput';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly startTime: Maybe<Scalars['String']['output']>;
}

export interface IOverrideHoursInputFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endTime?: InputMaybe<IStringFilter>;
  readonly startTime?: InputMaybe<IStringFilter>;
}

export interface IOverrideHoursInputSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endTime?: InputMaybe<SortOrder>;
  readonly startTime?: InputMaybe<SortOrder>;
}

export interface IOverrideHoursWrapper {
  readonly __typename?: 'OverrideHoursWrapper';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly timeslots: Maybe<ReadonlyArray<Maybe<IOverrideHoursInput>>>;
}

export interface IOverrideHoursWrapperFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IOverrideHoursWrapperSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IOverrideShortCodeModalContent {
  readonly __typename?: 'OverrideShortCodeModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly confirmButton: Maybe<ILocaleString>;
  readonly discardButton: Maybe<ILocaleString>;
  readonly header: Maybe<ILocaleString>;
}

export interface IOverrideShortCodeModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly confirmButton?: InputMaybe<ILocaleStringFilter>;
  readonly discardButton?: InputMaybe<ILocaleStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
}

export interface IOverrideShortCodeModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly confirmButton?: InputMaybe<ILocaleStringSorting>;
  readonly discardButton?: InputMaybe<ILocaleStringSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
}

export interface IParentChildPlu {
  readonly __typename?: 'ParentChildPlu';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly childPlu: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
}

export interface IParentChildPluFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly childPlu?: InputMaybe<IStringFilter>;
  readonly plu?: InputMaybe<IStringFilter>;
}

export interface IParentChildPluSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly childPlu?: InputMaybe<SortOrder>;
  readonly plu?: InputMaybe<SortOrder>;
}

export interface IPartnerGroup extends IDocument {
  readonly __typename?: 'PartnerGroup';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Partner Group name */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly posIntegration: Maybe<IPosIntegration>;
}

export interface IPartnerGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly posIntegration?: InputMaybe<IPosIntegrationFilter>;
}

export interface IPartnerGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IPaymentCardType {
  readonly __typename?: 'PaymentCardType';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** What card/method is the guest using to pay for the order */
  readonly cardType: Maybe<Scalars['String']['output']>;
}

export interface IPaymentCardTypeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cardType?: InputMaybe<IStringFilter>;
}

export interface IPaymentCardTypeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cardType?: InputMaybe<SortOrder>;
}

export interface IPaymentMethod {
  readonly __typename?: 'PaymentMethod';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** What payment method is the guest using to pay for the order */
  readonly paymentMethod: Maybe<Scalars['String']['output']>;
}

export interface IPaymentMethodConfiguration {
  readonly __typename?: 'PaymentMethodConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isOnlinePayment: Maybe<Scalars['Boolean']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['Boolean']['output']>;
}

export interface IPaymentMethodConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isOnlinePayment?: InputMaybe<IBooleanFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly paymentMethodBrand?: InputMaybe<IStringFilter>;
  readonly state?: InputMaybe<IBooleanFilter>;
}

export interface IPaymentMethodConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isOnlinePayment?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly paymentMethodBrand?: InputMaybe<SortOrder>;
  readonly state?: InputMaybe<SortOrder>;
}

export interface IPaymentMethodDeletionModalText {
  readonly __typename?: 'PaymentMethodDeletionModalText';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly paymentMethodDeletionModalTextBody: Maybe<ILocaleBlockContent>;
  readonly paymentMethodDeletionModalTextHeader: Maybe<ILocaleString>;
}

export interface IPaymentMethodDeletionModalTextFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly paymentMethodDeletionModalTextBody?: InputMaybe<ILocaleBlockContentFilter>;
  readonly paymentMethodDeletionModalTextHeader?: InputMaybe<ILocaleStringFilter>;
}

export interface IPaymentMethodDeletionModalTextSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly paymentMethodDeletionModalTextBody?: InputMaybe<ILocaleBlockContentSorting>;
  readonly paymentMethodDeletionModalTextHeader?: InputMaybe<ILocaleStringSorting>;
}

export interface IPaymentMethodFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly paymentMethod?: InputMaybe<IStringFilter>;
}

export interface IPaymentMethodSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly paymentMethod?: InputMaybe<SortOrder>;
}

export interface IPaymentMethods extends IDocument {
  readonly __typename?: 'PaymentMethods';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** This page allows users to manage payment methods for the marketplace through the Digital Ops Portal. Payment methods listed here can be activated or deactivated in DOP. Please note that to create and configure new payment methods to display on the White Label platform must be done directly in the front-end solution. */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  readonly paymentsList: Maybe<ReadonlyArray<Maybe<IAddPaymentMethod>>>;
}

export interface IPaymentMethodsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
}

export interface IPaymentMethodsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly documentDescription?: InputMaybe<SortOrder>;
}

export interface IPaymentsGatewayMerchantIdConfig {
  readonly __typename?: 'PaymentsGatewayMerchantIdConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ca: Maybe<Scalars['String']['output']>;
  readonly ch: Maybe<Scalars['String']['output']>;
  readonly sa: Maybe<Scalars['String']['output']>;
  readonly us: Maybe<Scalars['String']['output']>;
}

export interface IPaymentsGatewayMerchantIdConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ca?: InputMaybe<IStringFilter>;
  readonly ch?: InputMaybe<IStringFilter>;
  readonly sa?: InputMaybe<IStringFilter>;
  readonly us?: InputMaybe<IStringFilter>;
}

export interface IPaymentsGatewayMerchantIdConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ca?: InputMaybe<SortOrder>;
  readonly ch?: InputMaybe<SortOrder>;
  readonly sa?: InputMaybe<SortOrder>;
  readonly us?: InputMaybe<SortOrder>;
}

export interface IPhysicalPaymentMethodsLimitations {
  readonly __typename?: 'PhysicalPaymentMethodsLimitations';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly deliveryOrderAmountLimit: Maybe<Scalars['Float']['output']>;
  readonly deliveryOrderAmountLimitEnabled: Maybe<Scalars['Boolean']['output']>;
  readonly deliveryOrderRepeatedFailureLimitation: Maybe<Scalars['Boolean']['output']>;
  readonly firstDeliveryOrder: Maybe<Scalars['Float']['output']>;
  readonly firstDeliveryOrderEnabled: Maybe<Scalars['Boolean']['output']>;
}

export interface IPhysicalPaymentMethodsLimitationsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly deliveryOrderAmountLimit?: InputMaybe<IFloatFilter>;
  readonly deliveryOrderAmountLimitEnabled?: InputMaybe<IBooleanFilter>;
  readonly deliveryOrderRepeatedFailureLimitation?: InputMaybe<IBooleanFilter>;
  readonly firstDeliveryOrder?: InputMaybe<IFloatFilter>;
  readonly firstDeliveryOrderEnabled?: InputMaybe<IBooleanFilter>;
}

export interface IPhysicalPaymentMethodsLimitationsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly deliveryOrderAmountLimit?: InputMaybe<SortOrder>;
  readonly deliveryOrderAmountLimitEnabled?: InputMaybe<SortOrder>;
  readonly deliveryOrderRepeatedFailureLimitation?: InputMaybe<SortOrder>;
  readonly firstDeliveryOrder?: InputMaybe<SortOrder>;
  readonly firstDeliveryOrderEnabled?: InputMaybe<SortOrder>;
}

export interface IPicker extends IDocument {
  readonly __typename?: 'Picker';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly channelExclusions: Maybe<IChannelExclusions>;
  readonly contentOptions: Maybe<ReadonlyArray<Maybe<IContentOption>>>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly dmbName: Maybe<ILocaleString>;
  /** Hide all displays of calories on this item */
  readonly hideCalories: Maybe<Scalars['Boolean']['output']>;
  /** Hide the nutrition legal disclaimer on this item */
  readonly hideNutritionLegalDisclaimer: Maybe<Scalars['Boolean']['output']>;
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly imagesByChannels: Maybe<IImagesByChannels>;
  /**
   * Option to be auto-injected when customer redeems offer in-store whose benefit is         the picker, but does not choose an option.
   * @deprecated This has been deprecated
   */
  readonly inStoreDefaultOption: Maybe<IComboOrItem>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** Determines if this Picker can be chosen as a benefit for offers.       Once set to true, this cannot be toggled to false. */
  readonly isOfferBenefit: Maybe<Scalars['Boolean']['output']>;
  /**
   * Is a Real Meal Picker?
   * @deprecated This has been deprecated
   */
  readonly isRealMeal: Maybe<Scalars['Boolean']['output']>;
  /** Used to render Wings sauce selections for more than 6pc on the menu wizard.         If true, the UI will render the sauces incrementable by 6 under the picker aspect group.         Otherwise, they will render as true combo slot options in the order summary. */
  readonly isWings: Maybe<Scalars['Boolean']['output']>;
  readonly legalInformation: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IPickerOption>>>;
  readonly pickerAspectItemOptionMappings: Maybe<ReadonlyArray<Maybe<IPickerAspectItemOptions>>>;
  readonly pickerAspects: Maybe<ReadonlyArray<Maybe<IPickerAspect>>>;
  readonly pickerDefaults: Maybe<ReadonlyArray<Maybe<IPickerItemMapping>>>;
  readonly quickConfigs: Maybe<ReadonlyArray<Maybe<IQuickConfig>>>;
  /** Show picker in static menu */
  readonly showInStaticMenu: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This field will soon be deprecated in favor of new menu redesign. */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
}

export interface IPickerAspect extends IDocument {
  readonly __typename?: 'PickerAspect';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly internalName: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly pickerAspectOptions: Maybe<ReadonlyArray<Maybe<IPickerAspectValue>>>;
  /** Note: This field will soon be deprecated in favor of new menu redesign using only radios */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
}

export interface IPickerAspectFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
}

export interface IPickerAspectItemOptions {
  readonly __typename?: 'PickerAspectItemOptions';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly options: Maybe<ReadonlyArray<Maybe<IListItem>>>;
  readonly pickerAspect: Maybe<IPickerAspect>;
}

export interface IPickerAspectItemOptionsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly pickerAspect?: InputMaybe<IPickerAspectFilter>;
}

export interface IPickerAspectItemOptionsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IPickerAspectSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly uiPattern?: InputMaybe<SortOrder>;
}

export interface IPickerAspectValue {
  readonly __typename?: 'PickerAspectValue';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  /** If true, do not add the picker aspect name above to the item preview */
  readonly hideNameInItemPreview: Maybe<Scalars['Boolean']['output']>;
  /** A stable identifier used to refer to this value in the backend. */
  readonly identifier: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly name: Maybe<ILocaleString>;
  /** Show aspect in static menu */
  readonly showInStaticMenu: Maybe<Scalars['Boolean']['output']>;
}

export interface IPickerAspectValueFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly hideNameInItemPreview?: InputMaybe<IBooleanFilter>;
  readonly identifier?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly showInStaticMenu?: InputMaybe<IBooleanFilter>;
}

export interface IPickerAspectValueSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly hideNameInItemPreview?: InputMaybe<SortOrder>;
  readonly identifier?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly showInStaticMenu?: InputMaybe<SortOrder>;
}

export interface IPickerFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly dmbName?: InputMaybe<ILocaleStringFilter>;
  readonly hideCalories?: InputMaybe<IBooleanFilter>;
  readonly hideNutritionLegalDisclaimer?: InputMaybe<IBooleanFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isOfferBenefit?: InputMaybe<IBooleanFilter>;
  readonly isRealMeal?: InputMaybe<IBooleanFilter>;
  readonly isWings?: InputMaybe<IBooleanFilter>;
  readonly legalInformation?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly showInStaticMenu?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
}

export interface IPickerItemMapping {
  readonly __typename?: 'PickerItemMapping';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly pickerAspect: Maybe<IPickerAspect>;
  readonly pickerAspectValueIdentifier: Maybe<Scalars['String']['output']>;
}

export interface IPickerItemMappingFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly pickerAspect?: InputMaybe<IPickerAspectFilter>;
  readonly pickerAspectValueIdentifier?: InputMaybe<IStringFilter>;
}

export interface IPickerItemMappingSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly pickerAspectValueIdentifier?: InputMaybe<SortOrder>;
}

export interface IPickerOption {
  readonly __typename?: 'PickerOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly default: Maybe<Scalars['Boolean']['output']>;
  readonly option: Maybe<IComboOrItem>;
  readonly pickerItemMappings: Maybe<ReadonlyArray<Maybe<IPickerItemMapping>>>;
}

export interface IPickerOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly default?: InputMaybe<IBooleanFilter>;
}

export interface IPickerOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly default?: InputMaybe<SortOrder>;
}

export type IPickerOrSection = IPicker | ISection;

export interface IPickerSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly dmbName?: InputMaybe<ILocaleStringSorting>;
  readonly hideCalories?: InputMaybe<SortOrder>;
  readonly hideNutritionLegalDisclaimer?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly imagesByChannels?: InputMaybe<IImagesByChannelsSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isOfferBenefit?: InputMaybe<SortOrder>;
  readonly isRealMeal?: InputMaybe<SortOrder>;
  readonly isWings?: InputMaybe<SortOrder>;
  readonly legalInformation?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly showInStaticMenu?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
}

export interface IPlaceholderField extends IDocument {
  readonly __typename?: 'PlaceholderField';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Defines whether the field should check its availability */
  readonly alwaysAvailable: Maybe<Scalars['Boolean']['output']>;
  readonly applicableTo: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** The placeholder id to identify the replacement */
  readonly fieldId: Maybe<Scalars['String']['output']>;
  /** The formatter to use for rendering this field */
  readonly formatter: Maybe<Scalars['String']['output']>;
  readonly path: Maybe<Scalars['String']['output']>;
  /** The required field for this field to be available if alwaysAvailable is false */
  readonly requiredField: Maybe<Scalars['String']['output']>;
}

export interface IPlaceholderFieldFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly alwaysAvailable?: InputMaybe<IBooleanFilter>;
  readonly fieldId?: InputMaybe<IStringFilter>;
  readonly formatter?: InputMaybe<IStringFilter>;
  readonly path?: InputMaybe<IStringFilter>;
  readonly requiredField?: InputMaybe<IStringFilter>;
}

export interface IPlaceholderFieldSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly alwaysAvailable?: InputMaybe<SortOrder>;
  readonly fieldId?: InputMaybe<SortOrder>;
  readonly formatter?: InputMaybe<SortOrder>;
  readonly path?: InputMaybe<SortOrder>;
  readonly requiredField?: InputMaybe<SortOrder>;
}

export interface IPlu {
  readonly __typename?: 'Plu';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
  /** This field is only used in Tim Horton's because their PLUs need to have two separate values. */
  readonly qualifier: Maybe<Scalars['String']['output']>;
  readonly quantity: Maybe<Scalars['Float']['output']>;
}

export interface IPluConfig {
  readonly __typename?: 'PluConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly posIntegration: Maybe<IPosIntegration>;
  readonly serviceMode: Maybe<Scalars['String']['output']>;
  readonly vendorConfig: Maybe<IVendorConfig>;
}

export interface IPluConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly posIntegration?: InputMaybe<IPosIntegrationFilter>;
  readonly serviceMode?: InputMaybe<IStringFilter>;
  readonly vendorConfig?: InputMaybe<IVendorConfigFilter>;
}

export interface IPluConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly serviceMode?: InputMaybe<SortOrder>;
  readonly vendorConfig?: InputMaybe<IVendorConfigSorting>;
}

export interface IPluConfigs {
  readonly __typename?: 'PluConfigs';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly brink: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly carrols: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly hdx: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly ncr: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly oheics: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly partner: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly productNumber: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly qdi: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly qst: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly rpos: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly sicom: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly tablet: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly toshibaLoyalty: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
  readonly xenial: Maybe<ReadonlyArray<Maybe<IPluConfig>>>;
}

export interface IPluConfigsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IPluConfigsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IPluData {
  readonly __typename?: 'PluData';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly available: Maybe<Scalars['Boolean']['output']>;
  readonly pluNumber: Maybe<Scalars['Float']['output']>;
  readonly price: Maybe<Scalars['Float']['output']>;
}

export interface IPluDataFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly available?: InputMaybe<IBooleanFilter>;
  readonly pluNumber?: InputMaybe<IFloatFilter>;
  readonly price?: InputMaybe<IFloatFilter>;
}

export interface IPluDataSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly available?: InputMaybe<SortOrder>;
  readonly pluNumber?: InputMaybe<SortOrder>;
  readonly price?: InputMaybe<SortOrder>;
}

export interface IPluFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly plu?: InputMaybe<IStringFilter>;
  readonly qualifier?: InputMaybe<IStringFilter>;
  readonly quantity?: InputMaybe<IFloatFilter>;
}

export interface IPluSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly plu?: InputMaybe<SortOrder>;
  readonly qualifier?: InputMaybe<SortOrder>;
  readonly quantity?: InputMaybe<SortOrder>;
}

export interface IPos {
  readonly __typename?: 'Pos';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly vendor: Maybe<Scalars['String']['output']>;
  readonly version: Maybe<Scalars['String']['output']>;
}

export interface IPosConfiguration extends IDocument {
  readonly __typename?: 'PosConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** This is a field relevant for NZ and will be used to enable offers discount injection */
  readonly offerDiscountReferencePlu: Maybe<Scalars['String']['output']>;
  readonly posVendors: Maybe<ReadonlyArray<Maybe<IPosVendorConfigOption>>>;
}

export interface IPosConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly offerDiscountReferencePlu?: InputMaybe<IStringFilter>;
}

export interface IPosConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly offerDiscountReferencePlu?: InputMaybe<SortOrder>;
}

export interface IPosFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly vendor?: InputMaybe<IStringFilter>;
  readonly version?: InputMaybe<IStringFilter>;
}

export interface IPosIntegration extends IDocument {
  readonly __typename?: 'PosIntegration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly integrationId: Maybe<Scalars['String']['output']>;
  /** This will determine which PosType strategy is going to be used for Loyalty purposes. Two Partner POS Integrations cannot share the same strategy. */
  readonly loyaltyPosType: Maybe<Scalars['String']['output']>;
  /** POS Integration name */
  readonly name: Maybe<Scalars['String']['output']>;
}

export interface IPosIntegrationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly integrationId?: InputMaybe<IStringFilter>;
  readonly loyaltyPosType?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
}

export interface IPosIntegrationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly integrationId?: InputMaybe<SortOrder>;
  readonly loyaltyPosType?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IPosSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly vendor?: InputMaybe<SortOrder>;
  readonly version?: InputMaybe<SortOrder>;
}

export interface IPosValidationThreshold {
  readonly __typename?: 'PosValidationThreshold';
  readonly CARROLS_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly CARROLS_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly HDX_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly NCR_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly NCR_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly OHEICS_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly OHEICS_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly PARTNER_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly PARTNER_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly PRODUCTNUMBER_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly PRODUCTNUMBER_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly QDI_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly QDI_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly QST_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly QST_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly RPOS_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly RPOS_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly SICOM_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly SICOM_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly SIMPLYDELIVERY_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly SIMPLYDELIVERY_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly TABLET_DELIVERY: Maybe<Scalars['Float']['output']>;
  readonly TABLET_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly TOSHIBALOYALTY_PICKUP: Maybe<Scalars['Float']['output']>;
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
}

export interface IPosValidationThresholdFilter {
  readonly CARROLS_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly CARROLS_PICKUP?: InputMaybe<IFloatFilter>;
  readonly HDX_PICKUP?: InputMaybe<IFloatFilter>;
  readonly NCR_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly NCR_PICKUP?: InputMaybe<IFloatFilter>;
  readonly OHEICS_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly OHEICS_PICKUP?: InputMaybe<IFloatFilter>;
  readonly PARTNER_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly PARTNER_PICKUP?: InputMaybe<IFloatFilter>;
  readonly PRODUCTNUMBER_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly PRODUCTNUMBER_PICKUP?: InputMaybe<IFloatFilter>;
  readonly QDI_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly QDI_PICKUP?: InputMaybe<IFloatFilter>;
  readonly QST_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly QST_PICKUP?: InputMaybe<IFloatFilter>;
  readonly RPOS_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly RPOS_PICKUP?: InputMaybe<IFloatFilter>;
  readonly SICOM_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly SICOM_PICKUP?: InputMaybe<IFloatFilter>;
  readonly SIMPLYDELIVERY_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly SIMPLYDELIVERY_PICKUP?: InputMaybe<IFloatFilter>;
  readonly TABLET_DELIVERY?: InputMaybe<IFloatFilter>;
  readonly TABLET_PICKUP?: InputMaybe<IFloatFilter>;
  readonly TOSHIBALOYALTY_PICKUP?: InputMaybe<IFloatFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IPosValidationThresholdSorting {
  readonly CARROLS_DELIVERY?: InputMaybe<SortOrder>;
  readonly CARROLS_PICKUP?: InputMaybe<SortOrder>;
  readonly HDX_PICKUP?: InputMaybe<SortOrder>;
  readonly NCR_DELIVERY?: InputMaybe<SortOrder>;
  readonly NCR_PICKUP?: InputMaybe<SortOrder>;
  readonly OHEICS_DELIVERY?: InputMaybe<SortOrder>;
  readonly OHEICS_PICKUP?: InputMaybe<SortOrder>;
  readonly PARTNER_DELIVERY?: InputMaybe<SortOrder>;
  readonly PARTNER_PICKUP?: InputMaybe<SortOrder>;
  readonly PRODUCTNUMBER_DELIVERY?: InputMaybe<SortOrder>;
  readonly PRODUCTNUMBER_PICKUP?: InputMaybe<SortOrder>;
  readonly QDI_DELIVERY?: InputMaybe<SortOrder>;
  readonly QDI_PICKUP?: InputMaybe<SortOrder>;
  readonly QST_DELIVERY?: InputMaybe<SortOrder>;
  readonly QST_PICKUP?: InputMaybe<SortOrder>;
  readonly RPOS_DELIVERY?: InputMaybe<SortOrder>;
  readonly RPOS_PICKUP?: InputMaybe<SortOrder>;
  readonly SICOM_DELIVERY?: InputMaybe<SortOrder>;
  readonly SICOM_PICKUP?: InputMaybe<SortOrder>;
  readonly SIMPLYDELIVERY_DELIVERY?: InputMaybe<SortOrder>;
  readonly SIMPLYDELIVERY_PICKUP?: InputMaybe<SortOrder>;
  readonly TABLET_DELIVERY?: InputMaybe<SortOrder>;
  readonly TABLET_PICKUP?: InputMaybe<SortOrder>;
  readonly TOSHIBALOYALTY_PICKUP?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IPosVendorConfigOption {
  readonly __typename?: 'PosVendorConfigOption';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly posVendor: Maybe<Scalars['String']['output']>;
}

export interface IPosVendorConfigOptionFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly posVendor?: InputMaybe<IStringFilter>;
}

export interface IPosVendorConfigOptionSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly posVendor?: InputMaybe<SortOrder>;
}

export interface IPrepaidPaymentsGatewayMerchantIdConfig {
  readonly __typename?: 'PrepaidPaymentsGatewayMerchantIdConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly ca: Maybe<Scalars['String']['output']>;
  readonly ch: Maybe<Scalars['String']['output']>;
  readonly us: Maybe<Scalars['String']['output']>;
}

export interface IPrepaidPaymentsGatewayMerchantIdConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly ca?: InputMaybe<IStringFilter>;
  readonly ch?: InputMaybe<IStringFilter>;
  readonly us?: InputMaybe<IStringFilter>;
}

export interface IPrepaidPaymentsGatewayMerchantIdConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly ca?: InputMaybe<SortOrder>;
  readonly ch?: InputMaybe<SortOrder>;
  readonly us?: InputMaybe<SortOrder>;
}

export interface IPreviewEntry {
  readonly __typename?: 'PreviewEntry';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bodyText: Maybe<ILocaleString>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly linkText: Maybe<ILocaleString>;
  readonly linkURL: Maybe<Scalars['String']['output']>;
  readonly localizedLinkURL: Maybe<ILocaleString>;
  readonly previewImage: Maybe<ILocaleImage>;
  readonly titleText: Maybe<ILocaleString>;
}

export interface IPreviewEntryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bodyText?: InputMaybe<ILocaleStringFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly linkText?: InputMaybe<ILocaleStringFilter>;
  readonly linkURL?: InputMaybe<IStringFilter>;
  readonly localizedLinkURL?: InputMaybe<ILocaleStringFilter>;
  readonly previewImage?: InputMaybe<ILocaleImageFilter>;
  readonly titleText?: InputMaybe<ILocaleStringFilter>;
}

export interface IPreviewEntrySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bodyText?: InputMaybe<ILocaleStringSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly linkText?: InputMaybe<ILocaleStringSorting>;
  readonly linkURL?: InputMaybe<SortOrder>;
  readonly localizedLinkURL?: InputMaybe<ILocaleStringSorting>;
  readonly previewImage?: InputMaybe<ILocaleImageSorting>;
  readonly titleText?: InputMaybe<ILocaleStringSorting>;
}

export interface IPreviewWidget {
  readonly __typename?: 'PreviewWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly previewContent: Maybe<ReadonlyArray<Maybe<IPreviewEntry>>>;
}

export interface IPreviewWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IPreviewWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IPriceOverrideHours {
  readonly __typename?: 'PriceOverrideHours';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly fridayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly mondayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly saturdayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly sundayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly thursdayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly tuesdayOverrideHours: Maybe<IOverrideHoursWrapper>;
  readonly wednesdayOverrideHours: Maybe<IOverrideHoursWrapper>;
}

export interface IPriceOverrideHoursFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly fridayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly mondayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly saturdayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly sundayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly thursdayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly tuesdayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
  readonly wednesdayOverrideHours?: InputMaybe<IOverrideHoursWrapperFilter>;
}

export interface IPriceOverrideHoursSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly fridayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly mondayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly saturdayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly sundayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly thursdayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly tuesdayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
  readonly wednesdayOverrideHours?: InputMaybe<IOverrideHoursWrapperSorting>;
}

export interface IPricingRegionPrice {
  readonly __typename?: 'PricingRegionPrice';
  /** @deprecated This has been deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly opPricingRegion: Maybe<IOpPricingRegion>;
  /** @deprecated This has been deprecated */
  readonly price: Maybe<Scalars['Float']['output']>;
}

export interface IPricingRegionPriceFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly opPricingRegion?: InputMaybe<IOpPricingRegionFilter>;
  readonly price?: InputMaybe<IFloatFilter>;
}

export interface IPricingRegionPriceSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly price?: InputMaybe<SortOrder>;
}

export interface IPrivacyPolicyDisclaimers {
  readonly __typename?: 'PrivacyPolicyDisclaimers';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly privacyPolicyDisclaimersCells: Maybe<
    ReadonlyArray<Maybe<IPrivacyPolicyDisclaimersCell>>
  >;
}

export interface IPrivacyPolicyDisclaimersCell {
  readonly __typename?: 'PrivacyPolicyDisclaimersCell';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly border: Maybe<Scalars['Boolean']['output']>;
  readonly disclaimerContent: Maybe<ILocaleBlockContent>;
  readonly disclaimerDescription: Maybe<Scalars['String']['output']>;
  readonly disclaimerTitle: Maybe<Scalars['String']['output']>;
  readonly fontSize: Maybe<Scalars['Float']['output']>;
  readonly paddingBottom: Maybe<Scalars['Float']['output']>;
  readonly paddingLeft: Maybe<Scalars['Float']['output']>;
  readonly paddingRight: Maybe<Scalars['Float']['output']>;
  readonly paddingTop: Maybe<Scalars['Float']['output']>;
  readonly radius: Maybe<Scalars['Float']['output']>;
}

export interface IPrivacyPolicyDisclaimersCellFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly border?: InputMaybe<IBooleanFilter>;
  readonly disclaimerContent?: InputMaybe<ILocaleBlockContentFilter>;
  readonly disclaimerDescription?: InputMaybe<IStringFilter>;
  readonly disclaimerTitle?: InputMaybe<IStringFilter>;
  readonly fontSize?: InputMaybe<IFloatFilter>;
  readonly paddingBottom?: InputMaybe<IFloatFilter>;
  readonly paddingLeft?: InputMaybe<IFloatFilter>;
  readonly paddingRight?: InputMaybe<IFloatFilter>;
  readonly paddingTop?: InputMaybe<IFloatFilter>;
  readonly radius?: InputMaybe<IFloatFilter>;
}

export interface IPrivacyPolicyDisclaimersCellSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly border?: InputMaybe<SortOrder>;
  readonly disclaimerContent?: InputMaybe<ILocaleBlockContentSorting>;
  readonly disclaimerDescription?: InputMaybe<SortOrder>;
  readonly disclaimerTitle?: InputMaybe<SortOrder>;
  readonly fontSize?: InputMaybe<SortOrder>;
  readonly paddingBottom?: InputMaybe<SortOrder>;
  readonly paddingLeft?: InputMaybe<SortOrder>;
  readonly paddingRight?: InputMaybe<SortOrder>;
  readonly paddingTop?: InputMaybe<SortOrder>;
  readonly radius?: InputMaybe<SortOrder>;
}

export interface IPrivacyPolicyDisclaimersFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IPrivacyPolicyDisclaimersSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IProductCategory extends IDocument {
  readonly __typename?: 'ProductCategory';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly hierarchyLevel: Maybe<Scalars['String']['output']>;
  readonly image: Maybe<IImage>;
  readonly name: Maybe<ILocaleString>;
}

export interface IProductCategoryFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly hierarchyLevel?: InputMaybe<IStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IProductCategorySorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly hierarchyLevel?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IProductHierarchy {
  readonly __typename?: 'ProductHierarchy';
  readonly L1: Maybe<Scalars['String']['output']>;
  readonly L2: Maybe<Scalars['String']['output']>;
  readonly L3: Maybe<Scalars['String']['output']>;
  readonly L4: Maybe<Scalars['String']['output']>;
  readonly L5: Maybe<Scalars['String']['output']>;
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
}

export interface IProductHierarchyFilter {
  readonly L1?: InputMaybe<IStringFilter>;
  readonly L2?: InputMaybe<IStringFilter>;
  readonly L3?: InputMaybe<IStringFilter>;
  readonly L4?: InputMaybe<IStringFilter>;
  readonly L5?: InputMaybe<IStringFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IProductHierarchySorting {
  readonly L1?: InputMaybe<SortOrder>;
  readonly L2?: InputMaybe<SortOrder>;
  readonly L3?: InputMaybe<SortOrder>;
  readonly L4?: InputMaybe<SortOrder>;
  readonly L5?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IPromoCodePanel {
  readonly __typename?: 'PromoCodePanel';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly promoCodeDescription: Maybe<ILocaleBlockContent>;
  readonly promoCodeLabel: Maybe<ILocaleBlockContent>;
  /** Promo code external link  */
  readonly promoCodeLink: Maybe<Scalars['String']['output']>;
}

export interface IPromoCodePanelFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly promoCodeDescription?: InputMaybe<ILocaleBlockContentFilter>;
  readonly promoCodeLabel?: InputMaybe<ILocaleBlockContentFilter>;
  readonly promoCodeLink?: InputMaybe<IStringFilter>;
}

export interface IPromoCodePanelSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly promoCodeDescription?: InputMaybe<ILocaleBlockContentSorting>;
  readonly promoCodeLabel?: InputMaybe<ILocaleBlockContentSorting>;
  readonly promoCodeLink?: InputMaybe<SortOrder>;
}

export interface IPurchase {
  readonly __typename?: 'Purchase';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isPicker: Maybe<Scalars['Boolean']['output']>;
  readonly option: Maybe<IComboOrItemOrPickerOrProductCategory>;
  /** Quantity that must be in the cart */
  readonly quantity: Maybe<Scalars['Float']['output']>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
}

export interface IPurchaseFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isPicker?: InputMaybe<IBooleanFilter>;
  readonly quantity?: InputMaybe<IFloatFilter>;
  readonly sanityId?: InputMaybe<IStringFilter>;
}

export interface IPurchaseSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isPicker?: InputMaybe<SortOrder>;
  readonly quantity?: InputMaybe<SortOrder>;
  readonly sanityId?: InputMaybe<SortOrder>;
}

export interface IPypestreamConfig {
  readonly __typename?: 'PypestreamConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly appId: Maybe<Scalars['String']['output']>;
  readonly scriptUrl: Maybe<Scalars['String']['output']>;
}

export interface IPypestreamConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly appId?: InputMaybe<IStringFilter>;
  readonly scriptUrl?: InputMaybe<IStringFilter>;
}

export interface IPypestreamConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly appId?: InputMaybe<SortOrder>;
  readonly scriptUrl?: InputMaybe<SortOrder>;
}

export interface IQuestIncentive {
  readonly __typename?: 'QuestIncentive';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bonusPoints: Maybe<Scalars['Float']['output']>;
  readonly offer: Maybe<IConfigOffer>;
  readonly reward: Maybe<IConfigReward>;
  readonly type: Maybe<Scalars['String']['output']>;
}

export interface IQuestIncentiveFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bonusPoints?: InputMaybe<IFloatFilter>;
  readonly offer?: InputMaybe<IConfigOfferFilter>;
  readonly reward?: InputMaybe<IConfigRewardFilter>;
  readonly type?: InputMaybe<IStringFilter>;
}

export interface IQuestIncentiveSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bonusPoints?: InputMaybe<SortOrder>;
  readonly type?: InputMaybe<SortOrder>;
}

export interface IQuestRules {
  readonly __typename?: 'QuestRules';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Steps should be completed in sequential order */
  readonly isSequential: Maybe<Scalars['Boolean']['output']>;
  /** Steps have to be completed in different purchases */
  readonly onDistinctPurchases: Maybe<Scalars['Boolean']['output']>;
  readonly steps: Maybe<ReadonlyArray<Maybe<IQuestStep>>>;
}

export interface IQuestRulesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isSequential?: InputMaybe<IBooleanFilter>;
  readonly onDistinctPurchases?: InputMaybe<IBooleanFilter>;
}

export interface IQuestRulesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isSequential?: InputMaybe<SortOrder>;
  readonly onDistinctPurchases?: InputMaybe<SortOrder>;
}

export interface IQuestStep {
  readonly __typename?: 'QuestStep';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<ICartRequirementOrCartRequirementExclusionOrDayOfWeekBandOrEmailVerifiedRequiredOrIncentiveRequirementOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrRequiresAuthenticationOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrUserAttributes>
    >
  >;
}

export interface IQuestStepFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
}

export interface IQuestStepSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
}

export interface IQuestTemplate extends IDocument {
  readonly __typename?: 'QuestTemplate';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  /** Specify the Config Challenge to use with this template */
  readonly configIncentive: Maybe<IConfigQuest>;
  readonly description: Maybe<ILocaleSmartBlockContent>;
  readonly localizedImage: Maybe<ILocaleImages>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleSmartBlockContent>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface IQuestTemplateFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly configIncentive?: InputMaybe<IConfigQuestFilter>;
  readonly description?: InputMaybe<ILocaleSmartBlockContentFilter>;
  readonly localizedImage?: InputMaybe<ILocaleImagesFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleSmartBlockContentFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface IQuestTemplateSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly description?: InputMaybe<ILocaleSmartBlockContentSorting>;
  readonly localizedImage?: InputMaybe<ILocaleImagesSorting>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleSmartBlockContentSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface IQuestionDetails extends IDocument {
  readonly __typename?: 'QuestionDetails';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly countdownTimer: Maybe<Scalars['Float']['output']>;
  /** @deprecated This is deprecated */
  readonly responses: Maybe<ReadonlyArray<Maybe<IResponseDetails>>>;
  /** @deprecated This is deprecated */
  readonly text: Maybe<ILocaleString>;
}

export interface IQuestionDetailsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly countdownTimer?: InputMaybe<IFloatFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface IQuestionDetailsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly countdownTimer?: InputMaybe<SortOrder>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface IQuestsSection extends IDocument {
  readonly __typename?: 'QuestsSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IQuestsSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IQuestsSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IQuickConfig {
  readonly __typename?: 'QuickConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly rules: Maybe<ReadonlyArray<Maybe<IQuickConfigRule>>>;
}

export interface IQuickConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IQuickConfigRule {
  readonly __typename?: 'QuickConfigRule';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly itemOptions: Maybe<ReadonlyArray<Maybe<IListItem>>>;
  readonly modifier: Maybe<IListItem>;
}

export interface IQuickConfigRuleFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly modifier?: InputMaybe<IListItemFilter>;
}

export interface IQuickConfigRuleSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly modifier?: InputMaybe<IListItemSorting>;
}

export interface IQuickConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IQuoteWidget {
  readonly __typename?: 'QuoteWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly attributionImage: Maybe<IImage>;
  readonly attributionName: Maybe<Scalars['String']['output']>;
  readonly attributionTitle: Maybe<ILocaleString>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly quoteText: Maybe<ILocaleString>;
}

export interface IQuoteWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly attributionImage?: InputMaybe<IImageFilter>;
  readonly attributionName?: InputMaybe<IStringFilter>;
  readonly attributionTitle?: InputMaybe<ILocaleStringFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly quoteText?: InputMaybe<ILocaleStringFilter>;
}

export interface IQuoteWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly attributionImage?: InputMaybe<IImageSorting>;
  readonly attributionName?: InputMaybe<SortOrder>;
  readonly attributionTitle?: InputMaybe<ILocaleStringSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly quoteText?: InputMaybe<ILocaleStringSorting>;
}

export interface IRecentItems extends IDocument {
  readonly __typename?: 'RecentItems';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IRecentItemsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IRecentItemsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IRefillDrinksServiceMode {
  readonly __typename?: 'RefillDrinksServiceMode';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly cateringDeliveryServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly cateringPickUpServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly curbsideServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly deliveryServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly dineInServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly driveThruServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly pickUpServiceMode: Maybe<Scalars['Boolean']['output']>;
  readonly tableServiceMode: Maybe<Scalars['Boolean']['output']>;
}

export interface IRefillDrinksServiceModeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cateringDeliveryServiceMode?: InputMaybe<IBooleanFilter>;
  readonly cateringPickUpServiceMode?: InputMaybe<IBooleanFilter>;
  readonly curbsideServiceMode?: InputMaybe<IBooleanFilter>;
  readonly deliveryServiceMode?: InputMaybe<IBooleanFilter>;
  readonly dineInServiceMode?: InputMaybe<IBooleanFilter>;
  readonly driveThruServiceMode?: InputMaybe<IBooleanFilter>;
  readonly pickUpServiceMode?: InputMaybe<IBooleanFilter>;
  readonly tableServiceMode?: InputMaybe<IBooleanFilter>;
}

export interface IRefillDrinksServiceModeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cateringDeliveryServiceMode?: InputMaybe<SortOrder>;
  readonly cateringPickUpServiceMode?: InputMaybe<SortOrder>;
  readonly curbsideServiceMode?: InputMaybe<SortOrder>;
  readonly deliveryServiceMode?: InputMaybe<SortOrder>;
  readonly dineInServiceMode?: InputMaybe<SortOrder>;
  readonly driveThruServiceMode?: InputMaybe<SortOrder>;
  readonly pickUpServiceMode?: InputMaybe<SortOrder>;
  readonly tableServiceMode?: InputMaybe<SortOrder>;
}

export interface IRefillDrinksTimers {
  readonly __typename?: 'RefillDrinksTimers';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Enter the duration, in minutes, that the QR code will be available on the homepage. */
  readonly timeBottomSheet: Maybe<Scalars['Float']['output']>;
  /** Enter the duration, in minutes, that the QR code will be available on the recent orders page. */
  readonly timeRecentOrder: Maybe<Scalars['Float']['output']>;
  /** Define the time in minutes that will execute the function that validates the presentation time of the QR Code. */
  readonly visibilityControlQrCode: Maybe<Scalars['Float']['output']>;
}

export interface IRefillDrinksTimersFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly timeBottomSheet?: InputMaybe<IFloatFilter>;
  readonly timeRecentOrder?: InputMaybe<IFloatFilter>;
  readonly visibilityControlQrCode?: InputMaybe<IFloatFilter>;
}

export interface IRefillDrinksTimersSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly timeBottomSheet?: InputMaybe<SortOrder>;
  readonly timeRecentOrder?: InputMaybe<SortOrder>;
  readonly visibilityControlQrCode?: InputMaybe<SortOrder>;
}

export interface IRemediationReason {
  readonly __typename?: 'RemediationReason';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The text that will be displayed */
  readonly reasonLabel: Maybe<Scalars['String']['output']>;
  readonly reasonSlug: Maybe<ISlug>;
  readonly reasonValue: Maybe<Scalars['String']['output']>;
}

export interface IRemediationReasonFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly reasonLabel?: InputMaybe<IStringFilter>;
  readonly reasonSlug?: InputMaybe<ISlugFilter>;
  readonly reasonValue?: InputMaybe<IStringFilter>;
}

export interface IRemediationReasonSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly reasonLabel?: InputMaybe<SortOrder>;
  readonly reasonSlug?: InputMaybe<ISlugSorting>;
  readonly reasonValue?: InputMaybe<SortOrder>;
}

export interface IRemediationReasons extends IDocument {
  readonly __typename?: 'RemediationReasons';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly reasons: Maybe<ReadonlyArray<Maybe<IRemediationReason>>>;
}

export interface IRemediationReasonsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
}

export interface IRemediationReasonsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface IRequiredRules {
  readonly __typename?: 'RequiredRules';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly authenticationRequired: Maybe<Scalars['Boolean']['output']>;
  readonly cartLimit: Maybe<Scalars['Boolean']['output']>;
  readonly cartRequirement: Maybe<Scalars['Boolean']['output']>;
  readonly cartRequirementExclusion: Maybe<Scalars['Boolean']['output']>;
  readonly cooldown: Maybe<Scalars['Boolean']['output']>;
  readonly dateBand: Maybe<Scalars['Boolean']['output']>;
  readonly dayOfTheWeek: Maybe<Scalars['Boolean']['output']>;
  readonly emailVerificationRequired: Maybe<Scalars['Boolean']['output']>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly loyaltyTierRequirement: Maybe<Scalars['Boolean']['output']>;
  readonly maxRedemption: Maybe<Scalars['Boolean']['output']>;
  readonly minimumSpend: Maybe<Scalars['Boolean']['output']>;
  readonly paymentMethod: Maybe<Scalars['Boolean']['output']>;
  readonly restaurantGroups: Maybe<Scalars['Boolean']['output']>;
  readonly serviceModes: Maybe<Scalars['Boolean']['output']>;
  readonly stackable: Maybe<Scalars['Boolean']['output']>;
  readonly timeBand: Maybe<Scalars['Boolean']['output']>;
  readonly totalMinimumSpend: Maybe<Scalars['Boolean']['output']>;
  readonly transactionCount: Maybe<Scalars['Boolean']['output']>;
  readonly userBirthday: Maybe<Scalars['Boolean']['output']>;
  readonly userEnrollment: Maybe<Scalars['Boolean']['output']>;
}

export interface IRequiredRulesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly authenticationRequired?: InputMaybe<IBooleanFilter>;
  readonly cartLimit?: InputMaybe<IBooleanFilter>;
  readonly cartRequirement?: InputMaybe<IBooleanFilter>;
  readonly cartRequirementExclusion?: InputMaybe<IBooleanFilter>;
  readonly cooldown?: InputMaybe<IBooleanFilter>;
  readonly dateBand?: InputMaybe<IBooleanFilter>;
  readonly dayOfTheWeek?: InputMaybe<IBooleanFilter>;
  readonly emailVerificationRequired?: InputMaybe<IBooleanFilter>;
  readonly firstOrder?: InputMaybe<IBooleanFilter>;
  readonly loyaltyTierRequirement?: InputMaybe<IBooleanFilter>;
  readonly maxRedemption?: InputMaybe<IBooleanFilter>;
  readonly minimumSpend?: InputMaybe<IBooleanFilter>;
  readonly paymentMethod?: InputMaybe<IBooleanFilter>;
  readonly restaurantGroups?: InputMaybe<IBooleanFilter>;
  readonly serviceModes?: InputMaybe<IBooleanFilter>;
  readonly stackable?: InputMaybe<IBooleanFilter>;
  readonly timeBand?: InputMaybe<IBooleanFilter>;
  readonly totalMinimumSpend?: InputMaybe<IBooleanFilter>;
  readonly transactionCount?: InputMaybe<IBooleanFilter>;
  readonly userBirthday?: InputMaybe<IBooleanFilter>;
  readonly userEnrollment?: InputMaybe<IBooleanFilter>;
}

export interface IRequiredRulesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly authenticationRequired?: InputMaybe<SortOrder>;
  readonly cartLimit?: InputMaybe<SortOrder>;
  readonly cartRequirement?: InputMaybe<SortOrder>;
  readonly cartRequirementExclusion?: InputMaybe<SortOrder>;
  readonly cooldown?: InputMaybe<SortOrder>;
  readonly dateBand?: InputMaybe<SortOrder>;
  readonly dayOfTheWeek?: InputMaybe<SortOrder>;
  readonly emailVerificationRequired?: InputMaybe<SortOrder>;
  readonly firstOrder?: InputMaybe<SortOrder>;
  readonly loyaltyTierRequirement?: InputMaybe<SortOrder>;
  readonly maxRedemption?: InputMaybe<SortOrder>;
  readonly minimumSpend?: InputMaybe<SortOrder>;
  readonly paymentMethod?: InputMaybe<SortOrder>;
  readonly restaurantGroups?: InputMaybe<SortOrder>;
  readonly serviceModes?: InputMaybe<SortOrder>;
  readonly stackable?: InputMaybe<SortOrder>;
  readonly timeBand?: InputMaybe<SortOrder>;
  readonly totalMinimumSpend?: InputMaybe<SortOrder>;
  readonly transactionCount?: InputMaybe<SortOrder>;
  readonly userBirthday?: InputMaybe<SortOrder>;
  readonly userEnrollment?: InputMaybe<SortOrder>;
}

export interface IRequirements {
  readonly __typename?: 'Requirements';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly platform: Maybe<Scalars['String']['output']>;
  readonly purchase: Maybe<IComboOrItemOrPickerOrSection>;
  readonly shortCode: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
}

export interface IRequirementsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly platform?: InputMaybe<IStringFilter>;
  readonly shortCode?: InputMaybe<IStringFilter>;
  readonly status?: InputMaybe<IStringFilter>;
}

export interface IRequirementsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly platform?: InputMaybe<SortOrder>;
  readonly shortCode?: InputMaybe<SortOrder>;
  readonly status?: InputMaybe<SortOrder>;
}

export interface IRequiresAssignment {
  readonly __typename?: 'RequiresAssignment';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly requiresAssignment: Maybe<Scalars['String']['output']>;
}

export interface IRequiresAssignmentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly requiresAssignment?: InputMaybe<IStringFilter>;
}

export interface IRequiresAssignmentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly requiresAssignment?: InputMaybe<SortOrder>;
}

export interface IRequiresAuthentication {
  readonly __typename?: 'RequiresAuthentication';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Toggles the rule that is only valid if the user is authenticated. If set to true, the user needs to be authenticated to qualify for the incentive. If set to false, then the user does not need to be authenticated to qualify for the incentive */
  readonly requiresAuthentication: Maybe<Scalars['Boolean']['output']>;
}

export interface IRequiresAuthenticationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly requiresAuthentication?: InputMaybe<IBooleanFilter>;
}

export interface IRequiresAuthenticationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly requiresAuthentication?: InputMaybe<SortOrder>;
}

export interface IResponseDetails extends IDocument {
  readonly __typename?: 'ResponseDetails';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly content: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly resultsValuesDetails: Maybe<ReadonlyArray<Maybe<IResultValueDetails>>>;
  /** @deprecated This is deprecated */
  readonly text: Maybe<ILocaleString>;
}

export interface IResponseDetailsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly content?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface IResponseDetailsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly content?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface IRestaurant extends IDocument {
  readonly __typename?: 'Restaurant';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly adi: Maybe<Scalars['String']['output']>;
  readonly amenities: Maybe<ReadonlyArray<Maybe<IRestaurantAmenity>>>;
  readonly cateringHolidayHours: Maybe<ReadonlyArray<Maybe<IHolidayHours>>>;
  readonly cateringHours: Maybe<IHoursOfOperation>;
  /**
   * Tim Hortons, BK CA and PLK CA only
   * @deprecated This has been deprecated
   */
  readonly chaseMerchantId: Maybe<Scalars['String']['output']>;
  /** Processing channel for Checkout.com PSP */
  readonly checkoutDotComProcessingChannelId: Maybe<Scalars['String']['output']>;
  readonly curbsideHolidayHours: Maybe<ReadonlyArray<Maybe<IHolidayHours>>>;
  readonly curbsideHours: Maybe<IHoursOfOperation>;
  readonly customerFacingAddress: Maybe<ILocaleString>;
  /** Transacting ID for cybersource PSP */
  readonly cybersourceTransactingId: Maybe<Scalars['String']['output']>;
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayParts>>>;
  readonly deliveryConfiguration: Maybe<ReadonlyArray<Maybe<IDeliveryVendor>>>;
  readonly deliveryHolidayHours: Maybe<ReadonlyArray<Maybe<IHolidayHours>>>;
  readonly deliveryHours: Maybe<IHoursOfOperation>;
  readonly deliveryOrderAmountLimit: Maybe<IPhysicalPaymentMethodsLimitations>;
  readonly deliveryProviders: Maybe<ReadonlyArray<Maybe<IRestaurantDeliveryProvider>>>;
  readonly diningRoomHolidayHours: Maybe<ReadonlyArray<Maybe<IHolidayHours>>>;
  readonly diningRoomHours: Maybe<IHoursOfOperation>;
  /** Restaurant level configuration. Determines restaurant details like amenities and in app functionality like restaurant availability. */
  readonly documentDescription: Maybe<Scalars['String']['output']>;
  /** MDM Sync */
  readonly drinkStationType: Maybe<Scalars['String']['output']>;
  readonly driveThruHolidayHours: Maybe<ReadonlyArray<Maybe<IHolidayHours>>>;
  readonly driveThruHours: Maybe<IHoursOfOperation>;
  /** MDM Sync */
  readonly driveThruLaneType: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Determines whether a store will appear in the selected environment. Note that "dev" refers to all testing environments (dev, staging) and prop refers to all upper environments (qa, prod). So a restaurant marked as "dev" will not appear in our QA environment. */
  readonly environment: Maybe<Scalars['String']['output']>;
  /** BK PR only */
  readonly evertecMerchantId: Maybe<Scalars['String']['output']>;
  /** PLK KR only */
  readonly firstpayMerchantId: Maybe<Scalars['String']['output']>;
  /** MDM Sync. In Adyen, this is used to help construct the merchant account ID */
  readonly franchiseGroupId: Maybe<Scalars['Float']['output']>;
  /** MDM Sync. */
  readonly franchiseGroupName: Maybe<Scalars['String']['output']>;
  /** MDM Sync */
  readonly frontCounterClosed: Maybe<Scalars['Boolean']['output']>;
  readonly fzAddress: Maybe<Scalars['String']['output']>;
  readonly fzAuthorizedRepresentative: Maybe<Scalars['String']['output']>;
  readonly fzCity: Maybe<Scalars['String']['output']>;
  readonly fzEmail: Maybe<Scalars['String']['output']>;
  readonly fzHrb: Maybe<Scalars['String']['output']>;
  readonly fzRegistryCourt: Maybe<Scalars['String']['output']>;
  readonly fzTelephone: Maybe<Scalars['String']['output']>;
  readonly fzUstNr: Maybe<Scalars['String']['output']>;
  readonly fzZipCode: Maybe<Scalars['String']['output']>;
  readonly fzeeGroup: Maybe<Scalars['String']['output']>;
  readonly glovoChannel: Maybe<Scalars['Boolean']['output']>;
  /**
   * Determines whether a store is available in Google Food Ordering
   * @deprecated This has been deprecated
   */
  readonly googleOrderingStatus: Maybe<Scalars['String']['output']>;
  /** MDM Sync */
  readonly hasBreakfast: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly hasBurgersForBreakfast: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "catering" service mode is available. */
  readonly hasCatering: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "curbside" service mode is availabile. */
  readonly hasCurbside: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly hasDMBSuggestiveSelling: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "delivery" service mode is availabile. */
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "dine in" service mode is availabile. */
  readonly hasDineIn: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "drive thru" service mode is availabile. */
  readonly hasDriveThru: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Used by restaurants with their own delivery drivers. Our Bringg delivery integration can be configured on a per store basis to required this field to be true */
  readonly hasInternalDeliveryFleet: Maybe<Scalars['Boolean']['output']>;
  /**
   * Determines if restaurant has opted in to display a late night menu
   * @deprecated This has been deprecated
   */
  readonly hasLateNightMenu: Maybe<Scalars['Boolean']['output']>;
  /** Enable Late Night pricing on Digital Ops Portal. */
  readonly hasLateNightPricing: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines whether loyalty is available */
  readonly hasLoyalty: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. One of the fields required to be "true" for the ordering button to be enabled on the restaurant in the store locator. LaunchDarkly flag "enableOrdering" must also be on for ordering button to be enabled. */
  readonly hasMobileOrdering: Maybe<Scalars['Boolean']['output']>;
  /**
   * MDM Sync
   * @deprecated This has been deprecated
   */
  readonly hasModifiedTakeout: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly hasParking: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly hasPlayground: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines whether "table" service mode is available */
  readonly hasTableService: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. Determines where "takeout" service mode is availabile. */
  readonly hasTakeOut: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly hasWifi: Maybe<Scalars['Boolean']['output']>;
  /** disable the order button */
  readonly hideClickAndCollectOrdering: Maybe<Scalars['Boolean']['output']>;
  readonly hideInEnvironments: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly inStorePosChannel: Maybe<Scalars['Boolean']['output']>;
  readonly integration: Maybe<IIntegration>;
  /** MDM Sync: populated by internalRestaurantName */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** Dark Kitchen restaurants can ONLY be ordered from for delivery and are hidden from the store selector. */
  readonly isDarkKitchen: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly isHalal: Maybe<Scalars['Boolean']['output']>;
  readonly justEatChannel: Maybe<Scalars['Boolean']['output']>;
  readonly kioskChannel: Maybe<Scalars['Boolean']['output']>;
  readonly landmark: Maybe<ILocaleString>;
  readonly lastModifiedTime: Maybe<Scalars['DateTime']['output']>;
  readonly lateNightHours: Maybe<IPriceOverrideHours>;
  /** MDM Sync */
  readonly latitude: Maybe<Scalars['Float']['output']>;
  /** MDM Sync */
  readonly longitude: Maybe<Scalars['Float']['output']>;
  /** Determines whether ordering is available in the selected environment. "dev" is only available in lower environments (dev & staging). "live" will only appear in upper environments (qa & prod). Alpha and beta are no longer used, as we now use LaunchDarkly. */
  readonly mobileOrderingStatus: Maybe<Scalars['String']['output']>;
  /** MDM Sync: Populated by restaurant field "address1" */
  readonly name: Maybe<Scalars['String']['output']>;
  /** MDM Sync */
  readonly nbrDriveThruWindows: Maybe<Scalars['Float']['output']>;
  /** MDM Sync: Populated by restaurant field "number" */
  readonly number: Maybe<Scalars['String']['output']>;
  /** MDM Sync. */
  readonly ownership: Maybe<Scalars['String']['output']>;
  readonly parkingType: Maybe<Scalars['String']['output']>;
  /** BK/PLK ES and BK PT */
  readonly paycometMerchantId: Maybe<Scalars['String']['output']>;
  /** BK/PLK ES and BK PT */
  readonly paycometTerminal: Maybe<Scalars['String']['output']>;
  /** BK & PLK NZ only */
  readonly paymarkMerchantId: Maybe<Scalars['Float']['output']>;
  readonly paymentMethods: Maybe<ReadonlyArray<Maybe<IPaymentMethodConfiguration>>>;
  /** MDM Sync */
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly physicalAddress: Maybe<IAddress>;
  /** MDM Sync */
  readonly playgroundType: Maybe<Scalars['String']['output']>;
  readonly pos: Maybe<IPos>;
  readonly posRestaurantId: Maybe<Scalars['String']['output']>;
  readonly restaurantImage: Maybe<IImage>;
  /** @deprecated This has been deprecated */
  readonly restaurantKioskData: Maybe<IRestaurantKioskData>;
  /** @deprecated This has been deprecated */
  readonly restaurantPosData: Maybe<IRestaurantPosData>;
  /** @deprecated This has been deprecated */
  readonly servicesHasDriveThru: Maybe<Scalars['Boolean']['output']>;
  /** Display/Hide the Offers button for restaurant in the Store Locator screen */
  readonly showStoreLocatorOffersButton: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync. **Restaurant Availability**: Restaurants will only appear in the store locator if the status is "open" */
  readonly status: Maybe<Scalars['String']['output']>;
  /** MDM Sync. Restaurant timezone. */
  readonly timezone: Maybe<Scalars['String']['output']>;
  readonly uberEatsChannel: Maybe<Scalars['Boolean']['output']>;
  /** MDM Sync */
  readonly vatNumber: Maybe<Scalars['String']['output']>;
  /** MDM Sync. BK DE only */
  readonly vrPaymentMerchantId: Maybe<Scalars['String']['output']>;
  readonly waitTimeParameters: Maybe<IWaitTimeParameters>;
  readonly whitelabelAppChannel: Maybe<Scalars['Boolean']['output']>;
  /**
   * FHS US only
   * @deprecated This has been deprecated
   */
  readonly worldpayMerchantId: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantAmenity extends IDocument {
  readonly __typename?: 'RestaurantAmenity';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly icon: Maybe<IImage>;
  readonly name: Maybe<ILocaleString>;
}

export interface IRestaurantAmenityFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly icon?: InputMaybe<IImageFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface IRestaurantAmenitySorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly icon?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface IRestaurantConfig {
  readonly __typename?: 'RestaurantConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly displayEnv: Maybe<Scalars['String']['output']>;
  readonly validMobileOrderingEnvs: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
}

export interface IRestaurantConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly displayEnv?: InputMaybe<IStringFilter>;
}

export interface IRestaurantConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly displayEnv?: InputMaybe<SortOrder>;
}

export interface IRestaurantDeliveryProvider extends IDocument {
  readonly __typename?: 'RestaurantDeliveryProvider';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly icon: Maybe<IImage>;
  readonly name: Maybe<ILocaleString>;
  /** The MDM defined ID (this is pulled directly from MDM and should not be changed manually). */
  readonly providerId: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantDeliveryProviderFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly icon?: InputMaybe<IImageFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly providerId?: InputMaybe<IStringFilter>;
}

export interface IRestaurantDeliveryProviderSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly icon?: InputMaybe<IImageSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly providerId?: InputMaybe<SortOrder>;
}

export interface IRestaurantFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly adi?: InputMaybe<IStringFilter>;
  readonly cateringHours?: InputMaybe<IHoursOfOperationFilter>;
  readonly chaseMerchantId?: InputMaybe<IStringFilter>;
  readonly checkoutDotComProcessingChannelId?: InputMaybe<IStringFilter>;
  readonly curbsideHours?: InputMaybe<IHoursOfOperationFilter>;
  readonly customerFacingAddress?: InputMaybe<ILocaleStringFilter>;
  readonly cybersourceTransactingId?: InputMaybe<IStringFilter>;
  readonly deliveryHours?: InputMaybe<IHoursOfOperationFilter>;
  readonly deliveryOrderAmountLimit?: InputMaybe<IPhysicalPaymentMethodsLimitationsFilter>;
  readonly diningRoomHours?: InputMaybe<IHoursOfOperationFilter>;
  readonly documentDescription?: InputMaybe<IStringFilter>;
  readonly drinkStationType?: InputMaybe<IStringFilter>;
  readonly driveThruHours?: InputMaybe<IHoursOfOperationFilter>;
  readonly driveThruLaneType?: InputMaybe<IStringFilter>;
  readonly environment?: InputMaybe<IStringFilter>;
  readonly evertecMerchantId?: InputMaybe<IStringFilter>;
  readonly firstpayMerchantId?: InputMaybe<IStringFilter>;
  readonly franchiseGroupId?: InputMaybe<IFloatFilter>;
  readonly franchiseGroupName?: InputMaybe<IStringFilter>;
  readonly frontCounterClosed?: InputMaybe<IBooleanFilter>;
  readonly fzAddress?: InputMaybe<IStringFilter>;
  readonly fzAuthorizedRepresentative?: InputMaybe<IStringFilter>;
  readonly fzCity?: InputMaybe<IStringFilter>;
  readonly fzEmail?: InputMaybe<IStringFilter>;
  readonly fzHrb?: InputMaybe<IStringFilter>;
  readonly fzRegistryCourt?: InputMaybe<IStringFilter>;
  readonly fzTelephone?: InputMaybe<IStringFilter>;
  readonly fzUstNr?: InputMaybe<IStringFilter>;
  readonly fzZipCode?: InputMaybe<IStringFilter>;
  readonly fzeeGroup?: InputMaybe<IStringFilter>;
  readonly glovoChannel?: InputMaybe<IBooleanFilter>;
  readonly googleOrderingStatus?: InputMaybe<IStringFilter>;
  readonly hasBreakfast?: InputMaybe<IBooleanFilter>;
  readonly hasBurgersForBreakfast?: InputMaybe<IBooleanFilter>;
  readonly hasCatering?: InputMaybe<IBooleanFilter>;
  readonly hasCurbside?: InputMaybe<IBooleanFilter>;
  readonly hasDMBSuggestiveSelling?: InputMaybe<IBooleanFilter>;
  readonly hasDelivery?: InputMaybe<IBooleanFilter>;
  readonly hasDineIn?: InputMaybe<IBooleanFilter>;
  readonly hasDriveThru?: InputMaybe<IBooleanFilter>;
  readonly hasInternalDeliveryFleet?: InputMaybe<IBooleanFilter>;
  readonly hasLateNightMenu?: InputMaybe<IBooleanFilter>;
  readonly hasLateNightPricing?: InputMaybe<IBooleanFilter>;
  readonly hasLoyalty?: InputMaybe<IBooleanFilter>;
  readonly hasMobileOrdering?: InputMaybe<IBooleanFilter>;
  readonly hasModifiedTakeout?: InputMaybe<IBooleanFilter>;
  readonly hasParking?: InputMaybe<IBooleanFilter>;
  readonly hasPlayground?: InputMaybe<IBooleanFilter>;
  readonly hasTableService?: InputMaybe<IBooleanFilter>;
  readonly hasTakeOut?: InputMaybe<IBooleanFilter>;
  readonly hasWifi?: InputMaybe<IBooleanFilter>;
  readonly hideClickAndCollectOrdering?: InputMaybe<IBooleanFilter>;
  readonly inStorePosChannel?: InputMaybe<IBooleanFilter>;
  readonly integration?: InputMaybe<IIntegrationFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isDarkKitchen?: InputMaybe<IBooleanFilter>;
  readonly isHalal?: InputMaybe<IBooleanFilter>;
  readonly justEatChannel?: InputMaybe<IBooleanFilter>;
  readonly kioskChannel?: InputMaybe<IBooleanFilter>;
  readonly landmark?: InputMaybe<ILocaleStringFilter>;
  readonly lastModifiedTime?: InputMaybe<IDatetimeFilter>;
  readonly lateNightHours?: InputMaybe<IPriceOverrideHoursFilter>;
  readonly latitude?: InputMaybe<IFloatFilter>;
  readonly longitude?: InputMaybe<IFloatFilter>;
  readonly mobileOrderingStatus?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly nbrDriveThruWindows?: InputMaybe<IFloatFilter>;
  readonly number?: InputMaybe<IStringFilter>;
  readonly ownership?: InputMaybe<IStringFilter>;
  readonly parkingType?: InputMaybe<IStringFilter>;
  readonly paycometMerchantId?: InputMaybe<IStringFilter>;
  readonly paycometTerminal?: InputMaybe<IStringFilter>;
  readonly paymarkMerchantId?: InputMaybe<IFloatFilter>;
  readonly phoneNumber?: InputMaybe<IStringFilter>;
  readonly physicalAddress?: InputMaybe<IAddressFilter>;
  readonly playgroundType?: InputMaybe<IStringFilter>;
  readonly pos?: InputMaybe<IPosFilter>;
  readonly posRestaurantId?: InputMaybe<IStringFilter>;
  readonly restaurantImage?: InputMaybe<IImageFilter>;
  readonly restaurantKioskData?: InputMaybe<IRestaurantKioskDataFilter>;
  readonly restaurantPosData?: InputMaybe<IRestaurantPosDataFilter>;
  readonly servicesHasDriveThru?: InputMaybe<IBooleanFilter>;
  readonly showStoreLocatorOffersButton?: InputMaybe<IBooleanFilter>;
  readonly status?: InputMaybe<IStringFilter>;
  readonly timezone?: InputMaybe<IStringFilter>;
  readonly uberEatsChannel?: InputMaybe<IBooleanFilter>;
  readonly vatNumber?: InputMaybe<IStringFilter>;
  readonly vrPaymentMerchantId?: InputMaybe<IStringFilter>;
  readonly waitTimeParameters?: InputMaybe<IWaitTimeParametersFilter>;
  readonly whitelabelAppChannel?: InputMaybe<IBooleanFilter>;
  readonly worldpayMerchantId?: InputMaybe<IStringFilter>;
}

export interface IRestaurantGroup extends IDocument {
  readonly __typename?: 'RestaurantGroup';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /**
   * Show offers based on geolocation
   * @deprecated This is deprecated
   */
  readonly geolocation: Maybe<Scalars['Boolean']['output']>;
  /** This is used by the frontend team to query for a specific Restaurant Group.         This needs to be unique and constant. */
  readonly groupId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly restaurants: Maybe<ReadonlyArray<Maybe<IRestaurant>>>;
}

export interface IRestaurantGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly geolocation?: InputMaybe<IBooleanFilter>;
  readonly groupId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
}

export interface IRestaurantGroupRestrictions {
  readonly __typename?: 'RestaurantGroupRestrictions';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Defines if the offer should be included or excluded from the restaurants in the Restaurant Group Restriction List */
  readonly inclusive: Maybe<Scalars['Boolean']['output']>;
  readonly restaurantGroupRestrictionList: Maybe<ReadonlyArray<Maybe<IRestaurantGroup>>>;
}

export interface IRestaurantGroupRestrictionsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly inclusive?: InputMaybe<IBooleanFilter>;
}

export interface IRestaurantGroupRestrictionsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly inclusive?: InputMaybe<SortOrder>;
}

export interface IRestaurantGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly geolocation?: InputMaybe<SortOrder>;
  readonly groupId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
}

export interface IRestaurantKioskData extends IDocument {
  readonly __typename?: 'RestaurantKioskData';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly kiosks: Maybe<ReadonlyArray<Maybe<IKiosk>>>;
  /** @deprecated This is deprecated */
  readonly restaurantNumber: Maybe<Scalars['Float']['output']>;
}

export interface IRestaurantKioskDataFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly restaurantNumber?: InputMaybe<IFloatFilter>;
}

export interface IRestaurantKioskDataSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly restaurantNumber?: InputMaybe<SortOrder>;
}

export interface IRestaurantPosData extends IDocument {
  readonly __typename?: 'RestaurantPosData';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly base64DeliveryPLUs: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly base64PLUs: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly deliveryPLUs: Maybe<Scalars['String']['output']>;
  /**
   * deprecated
   * @deprecated This has been deprecated
   */
  readonly disableChickenSandwich: Maybe<Scalars['Boolean']['output']>;
  /**
   * deprecated
   * @deprecated This has been deprecated
   */
  readonly heartbeatOverride: Maybe<Scalars['String']['output']>;
  /**
   * deprecated
   * @deprecated This has been deprecated
   */
  readonly heartbeatStatus: Maybe<Scalars['Boolean']['output']>;
  /**
   * deprecated
   * @deprecated This has been deprecated
   */
  readonly lastHeartbeatTimestamp: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Field marked as deprecated due to <a>https://rbictg.atlassian.net/browse/MENU-140</a> */
  readonly plus: Maybe<Scalars['String']['output']>;
  readonly restaurant: Maybe<IRestaurant>;
  /**
   * Deprecated since restaurant numbers are strings. Unclear if this field was being used.
   * @deprecated This has been deprecated
   */
  readonly restaurantNumber: Maybe<Scalars['Float']['output']>;
}

export interface IRestaurantPosDataFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly base64DeliveryPLUs?: InputMaybe<IStringFilter>;
  readonly base64PLUs?: InputMaybe<IStringFilter>;
  readonly deliveryPLUs?: InputMaybe<IStringFilter>;
  readonly disableChickenSandwich?: InputMaybe<IBooleanFilter>;
  readonly heartbeatOverride?: InputMaybe<IStringFilter>;
  readonly heartbeatStatus?: InputMaybe<IBooleanFilter>;
  readonly lastHeartbeatTimestamp?: InputMaybe<IDatetimeFilter>;
  readonly plus?: InputMaybe<IStringFilter>;
  readonly restaurant?: InputMaybe<IRestaurantFilter>;
  readonly restaurantNumber?: InputMaybe<IFloatFilter>;
}

export interface IRestaurantPosDataSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly base64DeliveryPLUs?: InputMaybe<SortOrder>;
  readonly base64PLUs?: InputMaybe<SortOrder>;
  readonly deliveryPLUs?: InputMaybe<SortOrder>;
  readonly disableChickenSandwich?: InputMaybe<SortOrder>;
  readonly heartbeatOverride?: InputMaybe<SortOrder>;
  readonly heartbeatStatus?: InputMaybe<SortOrder>;
  readonly lastHeartbeatTimestamp?: InputMaybe<SortOrder>;
  readonly plus?: InputMaybe<SortOrder>;
  readonly restaurantNumber?: InputMaybe<SortOrder>;
}

export interface IRestaurantSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly adi?: InputMaybe<SortOrder>;
  readonly cateringHours?: InputMaybe<IHoursOfOperationSorting>;
  readonly chaseMerchantId?: InputMaybe<SortOrder>;
  readonly checkoutDotComProcessingChannelId?: InputMaybe<SortOrder>;
  readonly curbsideHours?: InputMaybe<IHoursOfOperationSorting>;
  readonly customerFacingAddress?: InputMaybe<ILocaleStringSorting>;
  readonly cybersourceTransactingId?: InputMaybe<SortOrder>;
  readonly deliveryHours?: InputMaybe<IHoursOfOperationSorting>;
  readonly deliveryOrderAmountLimit?: InputMaybe<IPhysicalPaymentMethodsLimitationsSorting>;
  readonly diningRoomHours?: InputMaybe<IHoursOfOperationSorting>;
  readonly documentDescription?: InputMaybe<SortOrder>;
  readonly drinkStationType?: InputMaybe<SortOrder>;
  readonly driveThruHours?: InputMaybe<IHoursOfOperationSorting>;
  readonly driveThruLaneType?: InputMaybe<SortOrder>;
  readonly environment?: InputMaybe<SortOrder>;
  readonly evertecMerchantId?: InputMaybe<SortOrder>;
  readonly firstpayMerchantId?: InputMaybe<SortOrder>;
  readonly franchiseGroupId?: InputMaybe<SortOrder>;
  readonly franchiseGroupName?: InputMaybe<SortOrder>;
  readonly frontCounterClosed?: InputMaybe<SortOrder>;
  readonly fzAddress?: InputMaybe<SortOrder>;
  readonly fzAuthorizedRepresentative?: InputMaybe<SortOrder>;
  readonly fzCity?: InputMaybe<SortOrder>;
  readonly fzEmail?: InputMaybe<SortOrder>;
  readonly fzHrb?: InputMaybe<SortOrder>;
  readonly fzRegistryCourt?: InputMaybe<SortOrder>;
  readonly fzTelephone?: InputMaybe<SortOrder>;
  readonly fzUstNr?: InputMaybe<SortOrder>;
  readonly fzZipCode?: InputMaybe<SortOrder>;
  readonly fzeeGroup?: InputMaybe<SortOrder>;
  readonly glovoChannel?: InputMaybe<SortOrder>;
  readonly googleOrderingStatus?: InputMaybe<SortOrder>;
  readonly hasBreakfast?: InputMaybe<SortOrder>;
  readonly hasBurgersForBreakfast?: InputMaybe<SortOrder>;
  readonly hasCatering?: InputMaybe<SortOrder>;
  readonly hasCurbside?: InputMaybe<SortOrder>;
  readonly hasDMBSuggestiveSelling?: InputMaybe<SortOrder>;
  readonly hasDelivery?: InputMaybe<SortOrder>;
  readonly hasDineIn?: InputMaybe<SortOrder>;
  readonly hasDriveThru?: InputMaybe<SortOrder>;
  readonly hasInternalDeliveryFleet?: InputMaybe<SortOrder>;
  readonly hasLateNightMenu?: InputMaybe<SortOrder>;
  readonly hasLateNightPricing?: InputMaybe<SortOrder>;
  readonly hasLoyalty?: InputMaybe<SortOrder>;
  readonly hasMobileOrdering?: InputMaybe<SortOrder>;
  readonly hasModifiedTakeout?: InputMaybe<SortOrder>;
  readonly hasParking?: InputMaybe<SortOrder>;
  readonly hasPlayground?: InputMaybe<SortOrder>;
  readonly hasTableService?: InputMaybe<SortOrder>;
  readonly hasTakeOut?: InputMaybe<SortOrder>;
  readonly hasWifi?: InputMaybe<SortOrder>;
  readonly hideClickAndCollectOrdering?: InputMaybe<SortOrder>;
  readonly inStorePosChannel?: InputMaybe<SortOrder>;
  readonly integration?: InputMaybe<IIntegrationSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isDarkKitchen?: InputMaybe<SortOrder>;
  readonly isHalal?: InputMaybe<SortOrder>;
  readonly justEatChannel?: InputMaybe<SortOrder>;
  readonly kioskChannel?: InputMaybe<SortOrder>;
  readonly landmark?: InputMaybe<ILocaleStringSorting>;
  readonly lastModifiedTime?: InputMaybe<SortOrder>;
  readonly lateNightHours?: InputMaybe<IPriceOverrideHoursSorting>;
  readonly latitude?: InputMaybe<SortOrder>;
  readonly longitude?: InputMaybe<SortOrder>;
  readonly mobileOrderingStatus?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly nbrDriveThruWindows?: InputMaybe<SortOrder>;
  readonly number?: InputMaybe<SortOrder>;
  readonly ownership?: InputMaybe<SortOrder>;
  readonly parkingType?: InputMaybe<SortOrder>;
  readonly paycometMerchantId?: InputMaybe<SortOrder>;
  readonly paycometTerminal?: InputMaybe<SortOrder>;
  readonly paymarkMerchantId?: InputMaybe<SortOrder>;
  readonly phoneNumber?: InputMaybe<SortOrder>;
  readonly physicalAddress?: InputMaybe<IAddressSorting>;
  readonly playgroundType?: InputMaybe<SortOrder>;
  readonly pos?: InputMaybe<IPosSorting>;
  readonly posRestaurantId?: InputMaybe<SortOrder>;
  readonly restaurantImage?: InputMaybe<IImageSorting>;
  readonly servicesHasDriveThru?: InputMaybe<SortOrder>;
  readonly showStoreLocatorOffersButton?: InputMaybe<SortOrder>;
  readonly status?: InputMaybe<SortOrder>;
  readonly timezone?: InputMaybe<SortOrder>;
  readonly uberEatsChannel?: InputMaybe<SortOrder>;
  readonly vatNumber?: InputMaybe<SortOrder>;
  readonly vrPaymentMerchantId?: InputMaybe<SortOrder>;
  readonly waitTimeParameters?: InputMaybe<IWaitTimeParametersSorting>;
  readonly whitelabelAppChannel?: InputMaybe<SortOrder>;
  readonly worldpayMerchantId?: InputMaybe<SortOrder>;
}

export interface IResultDetails extends IDocument {
  readonly __typename?: 'ResultDetails';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly description: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated This is deprecated */
  readonly offer: Maybe<IOffer>;
  /** @deprecated This is deprecated */
  readonly prefix: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly text: Maybe<ILocaleString>;
}

export interface IResultDetailsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly offer?: InputMaybe<IOfferFilter>;
  readonly prefix?: InputMaybe<ILocaleStringFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface IResultDetailsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly prefix?: InputMaybe<ILocaleStringSorting>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface IResultValueDetails {
  readonly __typename?: 'ResultValueDetails';
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly result: Maybe<IResultDetails>;
  /** @deprecated This is deprecated */
  readonly value: Maybe<Scalars['Float']['output']>;
}

export interface IResultValueDetailsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly result?: InputMaybe<IResultDetailsFilter>;
  readonly value?: InputMaybe<IFloatFilter>;
}

export interface IResultValueDetailsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly value?: InputMaybe<SortOrder>;
}

export interface IReward extends IDocument {
  readonly __typename?: 'Reward';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly incentiveNotInMenu: Maybe<Scalars['Boolean']['output']>;
  readonly incentives: Maybe<ReadonlyArray<Maybe<IComboOrItemOrOfferDiscountOrPicker>>>;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  /** When true, guest will not have option to redeem in-rest */
  readonly mobileOrderOnly: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly notificationDetails: Maybe<INotificationDetails>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /**
   * This represents the PMIX product number
   * @deprecated This is deprecated
   */
  readonly pmixId: Maybe<Scalars['String']['output']>;
  /** Specify the Order redemption method. */
  readonly redemptionMethod: Maybe<Scalars['String']['output']>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  readonly ruleSet: Maybe<
    ReadonlyArray<
      Maybe<IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrLoyaltyPointsOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrRewardPriceOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated>
    >
  >;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface IRewardCategory extends IDocument {
  readonly __typename?: 'RewardCategory';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly image: Maybe<ILocaleImage>;
  /** @deprecated This has been deprecated */
  readonly imageDescription: Maybe<ILocaleString>;
  readonly label: Maybe<ILocaleString>;
  readonly rewards: Maybe<ReadonlyArray<Maybe<IReward>>>;
  readonly validations: Maybe<ReadonlyArray<Maybe<IValidationLoyaltyPoints>>>;
}

export interface IRewardCategoryFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly label?: InputMaybe<ILocaleStringFilter>;
}

export interface IRewardCategorySorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly label?: InputMaybe<ILocaleStringSorting>;
}

export interface IRewardFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly incentiveNotInMenu?: InputMaybe<IBooleanFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly mobileOrderOnly?: InputMaybe<IBooleanFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly pmixId?: InputMaybe<IStringFilter>;
  readonly redemptionMethod?: InputMaybe<IStringFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
}

export interface IRewardList extends IDocument {
  readonly __typename?: 'RewardList';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly rewardCategories: Maybe<ReadonlyArray<Maybe<IRewardCategory>>>;
  /** If this reward list is tied to a specific Loyalty Tier you need to define it here as it is on the backend (eg. Tier_1, Tier_2, etc.) */
  readonly rewardListLoyaltyTier: Maybe<Scalars['String']['output']>;
}

export interface IRewardListFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly rewardListLoyaltyTier?: InputMaybe<IStringFilter>;
}

export interface IRewardListSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly rewardListLoyaltyTier?: InputMaybe<SortOrder>;
}

export type IRewardOrSystemwideOfferOrSystemwidePromotion =
  | IReward
  | ISystemwideOffer
  | ISystemwidePromotion;

export interface IRewardPrice {
  readonly __typename?: 'RewardPrice';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Price needs to be set in cents. */
  readonly price: Maybe<Scalars['Float']['output']>;
}

export interface IRewardPriceFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly price?: InputMaybe<IFloatFilter>;
}

export interface IRewardPriceSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly price?: InputMaybe<SortOrder>;
}

export interface IRewardSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly incentiveNotInMenu?: InputMaybe<SortOrder>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly mobileOrderOnly?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsSorting>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly pmixId?: InputMaybe<SortOrder>;
  readonly redemptionMethod?: InputMaybe<SortOrder>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
}

export interface IRewardsCarouselWidget {
  readonly __typename?: 'RewardsCarouselWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly rewardCarouselCategories: Maybe<ReadonlyArray<Maybe<IRewardCategory>>>;
  readonly rewardsCarouselDescription: Maybe<ILocaleString>;
  readonly rewardsCarouselTitle: Maybe<ILocaleString>;
}

export interface IRewardsCarouselWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly rewardsCarouselDescription?: InputMaybe<ILocaleStringFilter>;
  readonly rewardsCarouselTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface IRewardsCarouselWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly rewardsCarouselDescription?: InputMaybe<ILocaleStringSorting>;
  readonly rewardsCarouselTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface IRewardsListWidget {
  readonly __typename?: 'RewardsListWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Displays rewards list */
  readonly componentKey: Maybe<Scalars['String']['output']>;
  readonly title: Maybe<ILocaleString>;
}

export interface IRewardsListWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly componentKey?: InputMaybe<IStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface IRewardsListWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly componentKey?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface IRewardsRedemptionConfig {
  readonly __typename?: 'RewardsRedemptionConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Controls the number of rewards that can be used in a single order. Default is 1. */
  readonly standardRewardsLimit: Maybe<Scalars['Float']['output']>;
}

export interface IRewardsRedemptionConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly standardRewardsLimit?: InputMaybe<IFloatFilter>;
}

export interface IRewardsRedemptionConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly standardRewardsLimit?: InputMaybe<SortOrder>;
}

export interface IRewardsSection extends IDocument {
  readonly __typename?: 'RewardsSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated. */
  readonly rewards: Maybe<ReadonlyArray<Maybe<IFeaturedReward>>>;
  readonly rewardsList: Maybe<ReadonlyArray<Maybe<IFeaturedReward>>>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IRewardsSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IRewardsSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IRgbaColor {
  readonly __typename?: 'RgbaColor';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly a: Maybe<Scalars['Float']['output']>;
  readonly b: Maybe<Scalars['Float']['output']>;
  readonly g: Maybe<Scalars['Float']['output']>;
  readonly r: Maybe<Scalars['Float']['output']>;
}

export interface IRgbaColorFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly a?: InputMaybe<IFloatFilter>;
  readonly b?: InputMaybe<IFloatFilter>;
  readonly g?: InputMaybe<IFloatFilter>;
  readonly r?: InputMaybe<IFloatFilter>;
}

export interface IRgbaColorSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly a?: InputMaybe<SortOrder>;
  readonly b?: InputMaybe<SortOrder>;
  readonly g?: InputMaybe<SortOrder>;
  readonly r?: InputMaybe<SortOrder>;
}

export interface IRollUpTheRimSection extends IDocument {
  readonly __typename?: 'RollUpTheRimSection';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IRollUpTheRimSectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IRollUpTheRimSectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IRootQuery {
  readonly __typename?: 'RootQuery';
  readonly AdaptableMenuCard: Maybe<IAdaptableMenuCard>;
  readonly AdaptableMenuCardGroup: Maybe<IAdaptableMenuCardGroup>;
  readonly AddOnSection: Maybe<IAddOnSection>;
  readonly AdminToolReleaseNotes: Maybe<IAdminToolReleaseNotes>;
  readonly Alert: Maybe<IAlert>;
  /** @deprecated This has been deprecated */
  readonly AppData: Maybe<IAppData>;
  readonly AppDownload: Maybe<IAppDownload>;
  readonly AppVersion: Maybe<IAppVersion>;
  readonly BirthdayAndCatering: Maybe<IBirthdayAndCatering>;
  readonly BrazeContentCards: Maybe<IBrazeContentCards>;
  readonly BuildConfiguration: Maybe<IBuildConfiguration>;
  /** @deprecated This has been deprecated */
  readonly CartTotalCountdown: Maybe<ICartTotalCountdown>;
  readonly CateringPageConfiguration: Maybe<ICateringPageConfiguration>;
  readonly ChannelsConfiguration: Maybe<IChannelsConfiguration>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly ClaimPointsUI: Maybe<IClaimPointsUi>;
  readonly Combo: Maybe<ICombo>;
  readonly ComboSlot: Maybe<IComboSlot>;
  readonly ConfigOffer: Maybe<IConfigOffer>;
  readonly ConfigQuest: Maybe<IConfigQuest>;
  readonly ConfigRedeemInRestaurant: Maybe<IConfigRedeemInRestaurant>;
  readonly ConfigReward: Maybe<IConfigReward>;
  readonly DisplayGroup: Maybe<IDisplayGroup>;
  readonly Document: Maybe<IDocument>;
  readonly EarningCalculation: Maybe<IEarningCalculation>;
  readonly EmailTemplates: Maybe<IEmailTemplates>;
  readonly EmptyState: Maybe<IEmptyState>;
  readonly ExternalOffers: Maybe<IExternalOffers>;
  readonly FanFavorites: Maybe<IFanFavorites>;
  readonly FeatureAccount: Maybe<IFeatureAccount>;
  readonly FeatureAccountDeletePage: Maybe<IFeatureAccountDeletePage>;
  readonly FeatureAccountForm: Maybe<IFeatureAccountForm>;
  readonly FeatureAccountRequestInfoPage: Maybe<IFeatureAccountRequestInfoPage>;
  readonly FeatureAccountUpdatedAgreements: Maybe<IFeatureAccountUpdatedAgreements>;
  readonly FeatureAggregatorRedirectButtons: Maybe<IFeatureAggregatorRedirectButtons>;
  readonly FeatureAuthCampaign: Maybe<IFeatureAuthCampaign>;
  readonly FeatureBeeperModal: Maybe<IFeatureBeeperModal>;
  readonly FeatureCateringPage: Maybe<IFeatureCateringPage>;
  readonly FeatureDisclaimers: Maybe<IFeatureDisclaimers>;
  readonly FeatureDonation: Maybe<IFeatureDonation>;
  readonly FeatureEventReservations: Maybe<IFeatureEventReservations>;
  readonly FeatureExternalLinkSection: Maybe<IFeatureExternalLinkSection>;
  readonly FeatureFeesAndTaxes: Maybe<IFeatureFeesAndTaxes>;
  /** @deprecated This is deprecated */
  readonly FeatureFoodware: Maybe<IFeatureFoodware>;
  readonly FeatureFooter: Maybe<IFeatureFooter>;
  /** @deprecated This is deprecated */
  readonly FeatureFooterV2: Maybe<IFeatureFooterV2>;
  readonly FeatureGeolocationMapMarkers: Maybe<IFeatureGeolocationMapMarkers>;
  readonly FeatureGeolocationModal: Maybe<IFeatureGeolocationModal>;
  readonly FeatureGeolocationTermsModal: Maybe<IFeatureGeolocationTermsModal>;
  /** @deprecated This is deprecated */
  readonly FeatureGift: Maybe<IFeatureGift>;
  readonly FeatureGuestCheckout: Maybe<IFeatureGuestCheckout>;
  readonly FeatureHomePage: Maybe<IFeatureHomePage>;
  readonly FeatureIFrame: Maybe<IFeatureIFrame>;
  readonly FeatureInvitationCode: Maybe<IFeatureInvitationCode>;
  /** @deprecated This is deprecated */
  readonly FeatureKiosk: Maybe<IFeatureKiosk>;
  readonly FeatureKioskDonation: Maybe<IFeatureKioskDonation>;
  readonly FeatureKioskHomePage: Maybe<IFeatureKioskHomePage>;
  readonly FeatureKioskMenu: Maybe<IFeatureKioskMenu>;
  readonly FeatureKioskOffers: Maybe<IFeatureKioskOffers>;
  readonly FeatureKioskScreensaver: Maybe<IFeatureKioskScreensaver>;
  readonly FeatureLayout: Maybe<IFeatureLayout>;
  readonly FeatureLocationPromo: Maybe<IFeatureLocationPromo>;
  readonly FeatureLockers: Maybe<IFeatureLockers>;
  readonly FeatureMenu: Maybe<IFeatureMenu>;
  readonly FeatureMobileAppConfiguration: Maybe<IFeatureMobileAppConfiguration>;
  readonly FeatureNavigation: Maybe<IFeatureNavigation>;
  readonly FeatureNutrition: Maybe<IFeatureNutrition>;
  /** @deprecated This is deprecated */
  readonly FeatureOffers: Maybe<IFeatureOffers>;
  /** @deprecated This is deprecated */
  readonly FeatureOnboarding: Maybe<IFeatureOnboarding>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly FeaturePayback: Maybe<IFeaturePayback>;
  readonly FeatureQRCodeRefillDrinks: Maybe<IFeatureQrCodeRefillDrinks>;
  /** @deprecated This is deprecated */
  readonly FeatureQuiz: Maybe<IFeatureQuiz>;
  readonly FeatureSeo: Maybe<IFeatureSeo>;
  readonly FeatureStaticMenu: Maybe<IFeatureStaticMenu>;
  readonly Footer: Maybe<IFooter>;
  readonly FrontEndConfiguration: Maybe<IFrontEndConfiguration>;
  readonly Hero: Maybe<IHero>;
  readonly HomePageConfiguration: Maybe<IHomePageConfiguration>;
  /** @deprecated This is deprecated */
  readonly ImageWithHeaderAndText: Maybe<IImageWithHeaderAndText>;
  readonly Item: Maybe<IItem>;
  /** @deprecated This document is deprecated */
  readonly KioskAppData: Maybe<IKioskAppData>;
  /** @deprecated This document was re-purposed to be used by the Call Center. */
  readonly KioskConfiguration: Maybe<IKioskConfiguration>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly LinkPhysicalCardUi: Maybe<ILinkPhysicalCardUi>;
  readonly LiveMemberships: Maybe<ILiveMemberships>;
  readonly LockedOffers: Maybe<ILockedOffers>;
  /** @deprecated This has been deprecated */
  readonly LoyaltyContent: Maybe<ILoyaltyContent>;
  readonly LoyaltyLiveExternalOffers: Maybe<ILoyaltyLiveExternalOffers>;
  readonly LoyaltyOffersUI: Maybe<ILoyaltyOffersUi>;
  readonly LoyaltyQuestsUI: Maybe<ILoyaltyQuestsUi>;
  readonly LoyaltyReceipts: Maybe<ILoyaltyReceipts>;
  /** @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead. */
  readonly LoyaltySanityQueries: Maybe<ILoyaltySanityQueries>;
  readonly LoyaltyUI: Maybe<ILoyaltyUi>;
  /** @deprecated This is deprecated */
  readonly MainHero: Maybe<IMainHero>;
  readonly MarkerItem: Maybe<IMarkerItem>;
  readonly MarketingCard: Maybe<IMarketingCard>;
  readonly MarketingCardGroup: Maybe<IMarketingCardGroup>;
  readonly MarketingTile: Maybe<IMarketingTile>;
  readonly MarketingTileBasic: Maybe<IMarketingTileBasic>;
  readonly MarketingTileCarousel: Maybe<IMarketingTileCarousel>;
  readonly MarketingTileGroup: Maybe<IMarketingTileGroup>;
  readonly MediaTag: Maybe<IMediaTag>;
  readonly Memberships: Maybe<IMemberships>;
  readonly Menu: Maybe<IMenu>;
  readonly Modifier: Maybe<IModifier>;
  readonly ModifierMultiplier: Maybe<IModifierMultiplier>;
  readonly NavigationSection: Maybe<INavigationSection>;
  readonly NutritionalSection: Maybe<INutritionalSection>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly Offer: Maybe<IOffer>;
  /** @deprecated This is deprecated */
  readonly OfferActivation: Maybe<IOfferActivation>;
  readonly OfferCampaign: Maybe<IOfferCampaign>;
  readonly OfferCarouselSection: Maybe<IOfferCarouselSection>;
  readonly OfferDiscount: Maybe<IOfferDiscount>;
  readonly OfferItemDiscount: Maybe<IOfferItemDiscount>;
  readonly OfferLoyaltyAction: Maybe<IOfferLoyaltyAction>;
  readonly OfferTemplate: Maybe<IOfferTemplate>;
  /** @deprecated This is deprecated */
  readonly OffersSection: Maybe<IOffersSection>;
  readonly OpPricingRegion: Maybe<IOpPricingRegion>;
  readonly OurServices: Maybe<IOurServices>;
  readonly PartnerGroup: Maybe<IPartnerGroup>;
  readonly PaymentMethods: Maybe<IPaymentMethods>;
  readonly Picker: Maybe<IPicker>;
  readonly PickerAspect: Maybe<IPickerAspect>;
  readonly PlaceholderField: Maybe<IPlaceholderField>;
  readonly PosConfiguration: Maybe<IPosConfiguration>;
  readonly PosIntegration: Maybe<IPosIntegration>;
  readonly ProductCategory: Maybe<IProductCategory>;
  readonly QuestTemplate: Maybe<IQuestTemplate>;
  /** @deprecated This is deprecated */
  readonly QuestionDetails: Maybe<IQuestionDetails>;
  readonly QuestsSection: Maybe<IQuestsSection>;
  readonly RecentItems: Maybe<IRecentItems>;
  readonly RemediationReasons: Maybe<IRemediationReasons>;
  /** @deprecated This is deprecated */
  readonly ResponseDetails: Maybe<IResponseDetails>;
  readonly Restaurant: Maybe<IRestaurant>;
  readonly RestaurantAmenity: Maybe<IRestaurantAmenity>;
  readonly RestaurantDeliveryProvider: Maybe<IRestaurantDeliveryProvider>;
  readonly RestaurantGroup: Maybe<IRestaurantGroup>;
  /** @deprecated This is deprecated */
  readonly RestaurantKioskData: Maybe<IRestaurantKioskData>;
  readonly RestaurantPosData: Maybe<IRestaurantPosData>;
  /** @deprecated This is deprecated */
  readonly ResultDetails: Maybe<IResultDetails>;
  readonly Reward: Maybe<IReward>;
  readonly RewardCategory: Maybe<IRewardCategory>;
  readonly RewardList: Maybe<IRewardList>;
  readonly RewardsSection: Maybe<IRewardsSection>;
  readonly RollUpTheRimSection: Maybe<IRollUpTheRimSection>;
  readonly SanityFileAsset: Maybe<ISanityFileAsset>;
  readonly SanityImageAsset: Maybe<ISanityImageAsset>;
  readonly SanityValidationConfiguration: Maybe<ISanityValidationConfiguration>;
  readonly ScanForRewards: Maybe<IScanForRewards>;
  readonly Section: Maybe<ISection>;
  readonly SimpleHomePage: Maybe<ISimpleHomePage>;
  readonly StaticPage: Maybe<IStaticPage>;
  readonly SupportCategoryGroup: Maybe<ISupportCategoryGroup>;
  readonly SupportData: Maybe<ISupportData>;
  readonly SupportFaq: Maybe<ISupportFaq>;
  readonly SupportSubcategory: Maybe<ISupportSubcategory>;
  /** @deprecated This is deprecated */
  readonly SwapMapping: Maybe<ISwapMapping>;
  readonly SystemwideOffer: Maybe<ISystemwideOffer>;
  readonly SystemwidePromotion: Maybe<ISystemwidePromotion>;
  readonly SystemwideQuest: Maybe<ISystemwideQuest>;
  readonly Tag: Maybe<ITag>;
  readonly Trending: Maybe<ITrending>;
  readonly allAdaptableMenuCard: ReadonlyArray<IAdaptableMenuCard>;
  readonly allAdaptableMenuCardGroup: ReadonlyArray<IAdaptableMenuCardGroup>;
  readonly allAddOnSection: ReadonlyArray<IAddOnSection>;
  readonly allAdminToolReleaseNotes: ReadonlyArray<IAdminToolReleaseNotes>;
  readonly allAlert: ReadonlyArray<IAlert>;
  /** @deprecated This has been deprecated */
  readonly allAppData: ReadonlyArray<IAppData>;
  readonly allAppDownload: ReadonlyArray<IAppDownload>;
  readonly allAppVersion: ReadonlyArray<IAppVersion>;
  readonly allBirthdayAndCatering: ReadonlyArray<IBirthdayAndCatering>;
  readonly allBrazeContentCards: ReadonlyArray<IBrazeContentCards>;
  readonly allBuildConfiguration: ReadonlyArray<IBuildConfiguration>;
  /** @deprecated This has been deprecated */
  readonly allCartTotalCountdown: ReadonlyArray<ICartTotalCountdown>;
  readonly allCateringPageConfiguration: ReadonlyArray<ICateringPageConfiguration>;
  readonly allChannelsConfiguration: ReadonlyArray<IChannelsConfiguration>;
  /** @deprecated Claim Points (SMG Codes) not being used at INTL */
  readonly allClaimPointsUI: ReadonlyArray<IClaimPointsUi>;
  readonly allCombo: ReadonlyArray<ICombo>;
  readonly allComboSlot: ReadonlyArray<IComboSlot>;
  readonly allConfigOffer: ReadonlyArray<IConfigOffer>;
  readonly allConfigQuest: ReadonlyArray<IConfigQuest>;
  readonly allConfigRedeemInRestaurant: ReadonlyArray<IConfigRedeemInRestaurant>;
  readonly allConfigReward: ReadonlyArray<IConfigReward>;
  readonly allDisplayGroup: ReadonlyArray<IDisplayGroup>;
  readonly allDocument: ReadonlyArray<IDocument>;
  readonly allEarningCalculation: ReadonlyArray<IEarningCalculation>;
  readonly allEmailTemplates: ReadonlyArray<IEmailTemplates>;
  readonly allEmptyState: ReadonlyArray<IEmptyState>;
  readonly allExternalOffers: ReadonlyArray<IExternalOffers>;
  readonly allFanFavorites: ReadonlyArray<IFanFavorites>;
  readonly allFeatureAccount: ReadonlyArray<IFeatureAccount>;
  readonly allFeatureAccountDeletePage: ReadonlyArray<IFeatureAccountDeletePage>;
  readonly allFeatureAccountForm: ReadonlyArray<IFeatureAccountForm>;
  readonly allFeatureAccountRequestInfoPage: ReadonlyArray<IFeatureAccountRequestInfoPage>;
  readonly allFeatureAccountUpdatedAgreements: ReadonlyArray<IFeatureAccountUpdatedAgreements>;
  readonly allFeatureAggregatorRedirectButtons: ReadonlyArray<IFeatureAggregatorRedirectButtons>;
  readonly allFeatureAuthCampaign: ReadonlyArray<IFeatureAuthCampaign>;
  readonly allFeatureBeeperModal: ReadonlyArray<IFeatureBeeperModal>;
  readonly allFeatureCateringPage: ReadonlyArray<IFeatureCateringPage>;
  readonly allFeatureDisclaimers: ReadonlyArray<IFeatureDisclaimers>;
  readonly allFeatureDonation: ReadonlyArray<IFeatureDonation>;
  readonly allFeatureEventReservations: ReadonlyArray<IFeatureEventReservations>;
  readonly allFeatureExternalLinkSection: ReadonlyArray<IFeatureExternalLinkSection>;
  readonly allFeatureFeesAndTaxes: ReadonlyArray<IFeatureFeesAndTaxes>;
  /** @deprecated This is deprecated */
  readonly allFeatureFoodware: ReadonlyArray<IFeatureFoodware>;
  readonly allFeatureFooter: ReadonlyArray<IFeatureFooter>;
  /** @deprecated This is deprecated */
  readonly allFeatureFooterV2: ReadonlyArray<IFeatureFooterV2>;
  readonly allFeatureGeolocationMapMarkers: ReadonlyArray<IFeatureGeolocationMapMarkers>;
  readonly allFeatureGeolocationModal: ReadonlyArray<IFeatureGeolocationModal>;
  readonly allFeatureGeolocationTermsModal: ReadonlyArray<IFeatureGeolocationTermsModal>;
  /** @deprecated This is deprecated */
  readonly allFeatureGift: ReadonlyArray<IFeatureGift>;
  readonly allFeatureGuestCheckout: ReadonlyArray<IFeatureGuestCheckout>;
  readonly allFeatureHomePage: ReadonlyArray<IFeatureHomePage>;
  readonly allFeatureIFrame: ReadonlyArray<IFeatureIFrame>;
  readonly allFeatureInvitationCode: ReadonlyArray<IFeatureInvitationCode>;
  /** @deprecated This is deprecated */
  readonly allFeatureKiosk: ReadonlyArray<IFeatureKiosk>;
  readonly allFeatureKioskDonation: ReadonlyArray<IFeatureKioskDonation>;
  readonly allFeatureKioskHomePage: ReadonlyArray<IFeatureKioskHomePage>;
  readonly allFeatureKioskMenu: ReadonlyArray<IFeatureKioskMenu>;
  readonly allFeatureKioskOffers: ReadonlyArray<IFeatureKioskOffers>;
  readonly allFeatureKioskScreensaver: ReadonlyArray<IFeatureKioskScreensaver>;
  readonly allFeatureLayout: ReadonlyArray<IFeatureLayout>;
  readonly allFeatureLocationPromo: ReadonlyArray<IFeatureLocationPromo>;
  readonly allFeatureLockers: ReadonlyArray<IFeatureLockers>;
  readonly allFeatureMenu: ReadonlyArray<IFeatureMenu>;
  readonly allFeatureMobileAppConfiguration: ReadonlyArray<IFeatureMobileAppConfiguration>;
  readonly allFeatureNavigation: ReadonlyArray<IFeatureNavigation>;
  readonly allFeatureNutrition: ReadonlyArray<IFeatureNutrition>;
  /** @deprecated This is deprecated */
  readonly allFeatureOffers: ReadonlyArray<IFeatureOffers>;
  /** @deprecated This is deprecated */
  readonly allFeatureOnboarding: ReadonlyArray<IFeatureOnboarding>;
  /** @deprecated Payback integration was never finalized due to legal constraints. As an alternative, we implemented the RBI Loyalty platform for Burger King in Germany. */
  readonly allFeaturePayback: ReadonlyArray<IFeaturePayback>;
  readonly allFeatureQRCodeRefillDrinks: ReadonlyArray<IFeatureQrCodeRefillDrinks>;
  /** @deprecated This is deprecated */
  readonly allFeatureQuiz: ReadonlyArray<IFeatureQuiz>;
  readonly allFeatureSeo: ReadonlyArray<IFeatureSeo>;
  readonly allFeatureStaticMenu: ReadonlyArray<IFeatureStaticMenu>;
  readonly allFooter: ReadonlyArray<IFooter>;
  readonly allFrontEndConfiguration: ReadonlyArray<IFrontEndConfiguration>;
  readonly allHero: ReadonlyArray<IHero>;
  readonly allHomePageConfiguration: ReadonlyArray<IHomePageConfiguration>;
  /** @deprecated This is deprecated */
  readonly allImageWithHeaderAndText: ReadonlyArray<IImageWithHeaderAndText>;
  readonly allItem: ReadonlyArray<IItem>;
  /** @deprecated This document is deprecated */
  readonly allKioskAppData: ReadonlyArray<IKioskAppData>;
  /** @deprecated This document was re-purposed to be used by the Call Center. */
  readonly allKioskConfiguration: ReadonlyArray<IKioskConfiguration>;
  /** @deprecated Physical Cards (Tim Hortons) not being used at INTL. */
  readonly allLinkPhysicalCardUi: ReadonlyArray<ILinkPhysicalCardUi>;
  readonly allLiveMemberships: ReadonlyArray<ILiveMemberships>;
  readonly allLockedOffers: ReadonlyArray<ILockedOffers>;
  /** @deprecated This has been deprecated */
  readonly allLoyaltyContent: ReadonlyArray<ILoyaltyContent>;
  readonly allLoyaltyLiveExternalOffers: ReadonlyArray<ILoyaltyLiveExternalOffers>;
  readonly allLoyaltyOffersUI: ReadonlyArray<ILoyaltyOffersUi>;
  readonly allLoyaltyQuestsUI: ReadonlyArray<ILoyaltyQuestsUi>;
  readonly allLoyaltyReceipts: ReadonlyArray<ILoyaltyReceipts>;
  /** @deprecated Third-party Loyalty Queries never got implemented. Discourage their use and use GQL instead. */
  readonly allLoyaltySanityQueries: ReadonlyArray<ILoyaltySanityQueries>;
  readonly allLoyaltyUI: ReadonlyArray<ILoyaltyUi>;
  /** @deprecated This is deprecated */
  readonly allMainHero: ReadonlyArray<IMainHero>;
  readonly allMarkerItem: ReadonlyArray<IMarkerItem>;
  readonly allMarketingCard: ReadonlyArray<IMarketingCard>;
  readonly allMarketingCardGroup: ReadonlyArray<IMarketingCardGroup>;
  readonly allMarketingTile: ReadonlyArray<IMarketingTile>;
  readonly allMarketingTileBasic: ReadonlyArray<IMarketingTileBasic>;
  readonly allMarketingTileCarousel: ReadonlyArray<IMarketingTileCarousel>;
  readonly allMarketingTileGroup: ReadonlyArray<IMarketingTileGroup>;
  readonly allMediaTag: ReadonlyArray<IMediaTag>;
  readonly allMemberships: ReadonlyArray<IMemberships>;
  readonly allMenu: ReadonlyArray<IMenu>;
  readonly allModifier: ReadonlyArray<IModifier>;
  readonly allModifierMultiplier: ReadonlyArray<IModifierMultiplier>;
  readonly allNavigationSection: ReadonlyArray<INavigationSection>;
  readonly allNutritionalSection: ReadonlyArray<INutritionalSection>;
  /** @deprecated CBA Offers were deprecated. Please use the new Loyalty Offers */
  readonly allOffer: ReadonlyArray<IOffer>;
  /** @deprecated This is deprecated */
  readonly allOfferActivation: ReadonlyArray<IOfferActivation>;
  readonly allOfferCampaign: ReadonlyArray<IOfferCampaign>;
  readonly allOfferCarouselSection: ReadonlyArray<IOfferCarouselSection>;
  readonly allOfferDiscount: ReadonlyArray<IOfferDiscount>;
  readonly allOfferItemDiscount: ReadonlyArray<IOfferItemDiscount>;
  readonly allOfferLoyaltyAction: ReadonlyArray<IOfferLoyaltyAction>;
  readonly allOfferTemplate: ReadonlyArray<IOfferTemplate>;
  /** @deprecated This is deprecated */
  readonly allOffersSection: ReadonlyArray<IOffersSection>;
  readonly allOpPricingRegion: ReadonlyArray<IOpPricingRegion>;
  readonly allOurServices: ReadonlyArray<IOurServices>;
  readonly allPartnerGroup: ReadonlyArray<IPartnerGroup>;
  readonly allPaymentMethods: ReadonlyArray<IPaymentMethods>;
  readonly allPicker: ReadonlyArray<IPicker>;
  readonly allPickerAspect: ReadonlyArray<IPickerAspect>;
  readonly allPlaceholderField: ReadonlyArray<IPlaceholderField>;
  readonly allPosConfiguration: ReadonlyArray<IPosConfiguration>;
  readonly allPosIntegration: ReadonlyArray<IPosIntegration>;
  readonly allProductCategory: ReadonlyArray<IProductCategory>;
  readonly allQuestTemplate: ReadonlyArray<IQuestTemplate>;
  /** @deprecated This is deprecated */
  readonly allQuestionDetails: ReadonlyArray<IQuestionDetails>;
  readonly allQuestsSection: ReadonlyArray<IQuestsSection>;
  readonly allRecentItems: ReadonlyArray<IRecentItems>;
  readonly allRemediationReasons: ReadonlyArray<IRemediationReasons>;
  /** @deprecated This is deprecated */
  readonly allResponseDetails: ReadonlyArray<IResponseDetails>;
  readonly allRestaurant: ReadonlyArray<IRestaurant>;
  readonly allRestaurantAmenity: ReadonlyArray<IRestaurantAmenity>;
  readonly allRestaurantDeliveryProvider: ReadonlyArray<IRestaurantDeliveryProvider>;
  readonly allRestaurantGroup: ReadonlyArray<IRestaurantGroup>;
  /** @deprecated This is deprecated */
  readonly allRestaurantKioskData: ReadonlyArray<IRestaurantKioskData>;
  readonly allRestaurantPosData: ReadonlyArray<IRestaurantPosData>;
  /** @deprecated This is deprecated */
  readonly allResultDetails: ReadonlyArray<IResultDetails>;
  readonly allReward: ReadonlyArray<IReward>;
  readonly allRewardCategory: ReadonlyArray<IRewardCategory>;
  readonly allRewardList: ReadonlyArray<IRewardList>;
  readonly allRewardsSection: ReadonlyArray<IRewardsSection>;
  readonly allRollUpTheRimSection: ReadonlyArray<IRollUpTheRimSection>;
  readonly allSanityFileAsset: ReadonlyArray<ISanityFileAsset>;
  readonly allSanityImageAsset: ReadonlyArray<ISanityImageAsset>;
  readonly allSanityValidationConfiguration: ReadonlyArray<ISanityValidationConfiguration>;
  readonly allScanForRewards: ReadonlyArray<IScanForRewards>;
  readonly allSection: ReadonlyArray<ISection>;
  readonly allSimpleHomePage: ReadonlyArray<ISimpleHomePage>;
  readonly allStaticPage: ReadonlyArray<IStaticPage>;
  readonly allSupportCategoryGroup: ReadonlyArray<ISupportCategoryGroup>;
  readonly allSupportData: ReadonlyArray<ISupportData>;
  readonly allSupportFaq: ReadonlyArray<ISupportFaq>;
  readonly allSupportSubcategory: ReadonlyArray<ISupportSubcategory>;
  /** @deprecated This is deprecated */
  readonly allSwapMapping: ReadonlyArray<ISwapMapping>;
  readonly allSystemwideOffer: ReadonlyArray<ISystemwideOffer>;
  readonly allSystemwidePromotion: ReadonlyArray<ISystemwidePromotion>;
  readonly allSystemwideQuest: ReadonlyArray<ISystemwideQuest>;
  readonly allTag: ReadonlyArray<ITag>;
  readonly allTrending: ReadonlyArray<ITrending>;
}

export interface IRootQueryAdaptableMenuCardArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAdaptableMenuCardGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAddOnSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAdminToolReleaseNotesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAlertArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAppDataArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAppDownloadArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAppVersionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryBirthdayAndCateringArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryBrazeContentCardsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryBuildConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryCartTotalCountdownArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryCateringPageConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryChannelsConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryClaimPointsUiArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryComboArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryComboSlotArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryConfigOfferArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryConfigQuestArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryConfigRedeemInRestaurantArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryConfigRewardArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryDisplayGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryDocumentArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryEarningCalculationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryEmailTemplatesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryEmptyStateArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryExternalOffersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFanFavoritesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAccountArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAccountDeletePageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAccountFormArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAccountRequestInfoPageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAccountUpdatedAgreementsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAggregatorRedirectButtonsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureAuthCampaignArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureBeeperModalArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureCateringPageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureDisclaimersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureDonationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureEventReservationsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureExternalLinkSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureFeesAndTaxesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureFoodwareArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureFooterArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureFooterV2Args {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureGeolocationMapMarkersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureGeolocationModalArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureGeolocationTermsModalArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureGiftArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureGuestCheckoutArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureHomePageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureIFrameArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureInvitationCodeArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskDonationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskHomePageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskMenuArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskOffersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureKioskScreensaverArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureLayoutArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureLocationPromoArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureLockersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureMenuArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureMobileAppConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureNavigationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureNutritionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureOffersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureOnboardingArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeaturePaybackArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureQrCodeRefillDrinksArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureQuizArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureSeoArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFeatureStaticMenuArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFooterArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryFrontEndConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryHeroArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryHomePageConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryImageWithHeaderAndTextArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryItemArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryKioskAppDataArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryKioskConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLinkPhysicalCardUiArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLiveMembershipsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLockedOffersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyContentArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyLiveExternalOffersArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyOffersUiArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyQuestsUiArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyReceiptsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltySanityQueriesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryLoyaltyUiArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMainHeroArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarkerItemArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingCardArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingCardGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingTileArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingTileBasicArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingTileCarouselArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMarketingTileGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMediaTagArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMembershipsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryMenuArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryModifierArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryModifierMultiplierArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryNavigationSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryNutritionalSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferActivationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferCampaignArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferCarouselSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferDiscountArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferItemDiscountArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferLoyaltyActionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOfferTemplateArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOffersSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOpPricingRegionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryOurServicesArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPartnerGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPaymentMethodsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPickerArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPickerAspectArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPlaceholderFieldArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPosConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryPosIntegrationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryProductCategoryArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryQuestTemplateArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryQuestionDetailsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryQuestsSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRecentItemsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRemediationReasonsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryResponseDetailsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantAmenityArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantDeliveryProviderArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantKioskDataArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRestaurantPosDataArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryResultDetailsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRewardArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRewardCategoryArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRewardListArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRewardsSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryRollUpTheRimSectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySanityFileAssetArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySanityImageAssetArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySanityValidationConfigurationArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryScanForRewardsArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySectionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySimpleHomePageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryStaticPageArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySupportCategoryGroupArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySupportDataArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySupportFaqArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySupportSubcategoryArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySwapMappingArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySystemwideOfferArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySystemwidePromotionArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQuerySystemwideQuestArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryTagArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryTrendingArgs {
  id: Scalars['ID']['input'];
}

export interface IRootQueryAllAdaptableMenuCardArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAdaptableMenuCardSorting>>;
  where?: InputMaybe<IAdaptableMenuCardFilter>;
}

export interface IRootQueryAllAdaptableMenuCardGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAdaptableMenuCardGroupSorting>>;
  where?: InputMaybe<IAdaptableMenuCardGroupFilter>;
}

export interface IRootQueryAllAddOnSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAddOnSectionSorting>>;
  where?: InputMaybe<IAddOnSectionFilter>;
}

export interface IRootQueryAllAdminToolReleaseNotesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAdminToolReleaseNotesSorting>>;
  where?: InputMaybe<IAdminToolReleaseNotesFilter>;
}

export interface IRootQueryAllAlertArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAlertSorting>>;
  where?: InputMaybe<IAlertFilter>;
}

export interface IRootQueryAllAppDataArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAppDataSorting>>;
  where?: InputMaybe<IAppDataFilter>;
}

export interface IRootQueryAllAppDownloadArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAppDownloadSorting>>;
  where?: InputMaybe<IAppDownloadFilter>;
}

export interface IRootQueryAllAppVersionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IAppVersionSorting>>;
  where?: InputMaybe<IAppVersionFilter>;
}

export interface IRootQueryAllBirthdayAndCateringArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IBirthdayAndCateringSorting>>;
  where?: InputMaybe<IBirthdayAndCateringFilter>;
}

export interface IRootQueryAllBrazeContentCardsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IBrazeContentCardsSorting>>;
  where?: InputMaybe<IBrazeContentCardsFilter>;
}

export interface IRootQueryAllBuildConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IBuildConfigurationSorting>>;
  where?: InputMaybe<IBuildConfigurationFilter>;
}

export interface IRootQueryAllCartTotalCountdownArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ICartTotalCountdownSorting>>;
  where?: InputMaybe<ICartTotalCountdownFilter>;
}

export interface IRootQueryAllCateringPageConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ICateringPageConfigurationSorting>>;
  where?: InputMaybe<ICateringPageConfigurationFilter>;
}

export interface IRootQueryAllChannelsConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IChannelsConfigurationSorting>>;
  where?: InputMaybe<IChannelsConfigurationFilter>;
}

export interface IRootQueryAllClaimPointsUiArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IClaimPointsUiSorting>>;
  where?: InputMaybe<IClaimPointsUiFilter>;
}

export interface IRootQueryAllComboArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IComboSorting>>;
  where?: InputMaybe<IComboFilter>;
}

export interface IRootQueryAllComboSlotArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IComboSlotSorting>>;
  where?: InputMaybe<IComboSlotFilter>;
}

export interface IRootQueryAllConfigOfferArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IConfigOfferSorting>>;
  where?: InputMaybe<IConfigOfferFilter>;
}

export interface IRootQueryAllConfigQuestArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IConfigQuestSorting>>;
  where?: InputMaybe<IConfigQuestFilter>;
}

export interface IRootQueryAllConfigRedeemInRestaurantArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IConfigRedeemInRestaurantSorting>>;
  where?: InputMaybe<IConfigRedeemInRestaurantFilter>;
}

export interface IRootQueryAllConfigRewardArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IConfigRewardSorting>>;
  where?: InputMaybe<IConfigRewardFilter>;
}

export interface IRootQueryAllDisplayGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IDisplayGroupSorting>>;
  where?: InputMaybe<IDisplayGroupFilter>;
}

export interface IRootQueryAllDocumentArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IDocumentSorting>>;
  where?: InputMaybe<IDocumentFilter>;
}

export interface IRootQueryAllEarningCalculationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IEarningCalculationSorting>>;
  where?: InputMaybe<IEarningCalculationFilter>;
}

export interface IRootQueryAllEmailTemplatesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IEmailTemplatesSorting>>;
  where?: InputMaybe<IEmailTemplatesFilter>;
}

export interface IRootQueryAllEmptyStateArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IEmptyStateSorting>>;
  where?: InputMaybe<IEmptyStateFilter>;
}

export interface IRootQueryAllExternalOffersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IExternalOffersSorting>>;
  where?: InputMaybe<IExternalOffersFilter>;
}

export interface IRootQueryAllFanFavoritesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFanFavoritesSorting>>;
  where?: InputMaybe<IFanFavoritesFilter>;
}

export interface IRootQueryAllFeatureAccountArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAccountSorting>>;
  where?: InputMaybe<IFeatureAccountFilter>;
}

export interface IRootQueryAllFeatureAccountDeletePageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAccountDeletePageSorting>>;
  where?: InputMaybe<IFeatureAccountDeletePageFilter>;
}

export interface IRootQueryAllFeatureAccountFormArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAccountFormSorting>>;
  where?: InputMaybe<IFeatureAccountFormFilter>;
}

export interface IRootQueryAllFeatureAccountRequestInfoPageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAccountRequestInfoPageSorting>>;
  where?: InputMaybe<IFeatureAccountRequestInfoPageFilter>;
}

export interface IRootQueryAllFeatureAccountUpdatedAgreementsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAccountUpdatedAgreementsSorting>>;
  where?: InputMaybe<IFeatureAccountUpdatedAgreementsFilter>;
}

export interface IRootQueryAllFeatureAggregatorRedirectButtonsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAggregatorRedirectButtonsSorting>>;
  where?: InputMaybe<IFeatureAggregatorRedirectButtonsFilter>;
}

export interface IRootQueryAllFeatureAuthCampaignArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureAuthCampaignSorting>>;
  where?: InputMaybe<IFeatureAuthCampaignFilter>;
}

export interface IRootQueryAllFeatureBeeperModalArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureBeeperModalSorting>>;
  where?: InputMaybe<IFeatureBeeperModalFilter>;
}

export interface IRootQueryAllFeatureCateringPageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureCateringPageSorting>>;
  where?: InputMaybe<IFeatureCateringPageFilter>;
}

export interface IRootQueryAllFeatureDisclaimersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureDisclaimersSorting>>;
  where?: InputMaybe<IFeatureDisclaimersFilter>;
}

export interface IRootQueryAllFeatureDonationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureDonationSorting>>;
  where?: InputMaybe<IFeatureDonationFilter>;
}

export interface IRootQueryAllFeatureEventReservationsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureEventReservationsSorting>>;
  where?: InputMaybe<IFeatureEventReservationsFilter>;
}

export interface IRootQueryAllFeatureExternalLinkSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureExternalLinkSectionSorting>>;
  where?: InputMaybe<IFeatureExternalLinkSectionFilter>;
}

export interface IRootQueryAllFeatureFeesAndTaxesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureFeesAndTaxesSorting>>;
  where?: InputMaybe<IFeatureFeesAndTaxesFilter>;
}

export interface IRootQueryAllFeatureFoodwareArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureFoodwareSorting>>;
  where?: InputMaybe<IFeatureFoodwareFilter>;
}

export interface IRootQueryAllFeatureFooterArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureFooterSorting>>;
  where?: InputMaybe<IFeatureFooterFilter>;
}

export interface IRootQueryAllFeatureFooterV2Args {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureFooterV2Sorting>>;
  where?: InputMaybe<IFeatureFooterV2Filter>;
}

export interface IRootQueryAllFeatureGeolocationMapMarkersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureGeolocationMapMarkersSorting>>;
  where?: InputMaybe<IFeatureGeolocationMapMarkersFilter>;
}

export interface IRootQueryAllFeatureGeolocationModalArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureGeolocationModalSorting>>;
  where?: InputMaybe<IFeatureGeolocationModalFilter>;
}

export interface IRootQueryAllFeatureGeolocationTermsModalArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureGeolocationTermsModalSorting>>;
  where?: InputMaybe<IFeatureGeolocationTermsModalFilter>;
}

export interface IRootQueryAllFeatureGiftArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureGiftSorting>>;
  where?: InputMaybe<IFeatureGiftFilter>;
}

export interface IRootQueryAllFeatureGuestCheckoutArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureGuestCheckoutSorting>>;
  where?: InputMaybe<IFeatureGuestCheckoutFilter>;
}

export interface IRootQueryAllFeatureHomePageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureHomePageSorting>>;
  where?: InputMaybe<IFeatureHomePageFilter>;
}

export interface IRootQueryAllFeatureIFrameArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureIFrameSorting>>;
  where?: InputMaybe<IFeatureIFrameFilter>;
}

export interface IRootQueryAllFeatureInvitationCodeArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureInvitationCodeSorting>>;
  where?: InputMaybe<IFeatureInvitationCodeFilter>;
}

export interface IRootQueryAllFeatureKioskArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskSorting>>;
  where?: InputMaybe<IFeatureKioskFilter>;
}

export interface IRootQueryAllFeatureKioskDonationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskDonationSorting>>;
  where?: InputMaybe<IFeatureKioskDonationFilter>;
}

export interface IRootQueryAllFeatureKioskHomePageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskHomePageSorting>>;
  where?: InputMaybe<IFeatureKioskHomePageFilter>;
}

export interface IRootQueryAllFeatureKioskMenuArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskMenuSorting>>;
  where?: InputMaybe<IFeatureKioskMenuFilter>;
}

export interface IRootQueryAllFeatureKioskOffersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskOffersSorting>>;
  where?: InputMaybe<IFeatureKioskOffersFilter>;
}

export interface IRootQueryAllFeatureKioskScreensaverArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureKioskScreensaverSorting>>;
  where?: InputMaybe<IFeatureKioskScreensaverFilter>;
}

export interface IRootQueryAllFeatureLayoutArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureLayoutSorting>>;
  where?: InputMaybe<IFeatureLayoutFilter>;
}

export interface IRootQueryAllFeatureLocationPromoArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureLocationPromoSorting>>;
  where?: InputMaybe<IFeatureLocationPromoFilter>;
}

export interface IRootQueryAllFeatureLockersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureLockersSorting>>;
  where?: InputMaybe<IFeatureLockersFilter>;
}

export interface IRootQueryAllFeatureMenuArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureMenuSorting>>;
  where?: InputMaybe<IFeatureMenuFilter>;
}

export interface IRootQueryAllFeatureMobileAppConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureMobileAppConfigurationSorting>>;
  where?: InputMaybe<IFeatureMobileAppConfigurationFilter>;
}

export interface IRootQueryAllFeatureNavigationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureNavigationSorting>>;
  where?: InputMaybe<IFeatureNavigationFilter>;
}

export interface IRootQueryAllFeatureNutritionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureNutritionSorting>>;
  where?: InputMaybe<IFeatureNutritionFilter>;
}

export interface IRootQueryAllFeatureOffersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureOffersSorting>>;
  where?: InputMaybe<IFeatureOffersFilter>;
}

export interface IRootQueryAllFeatureOnboardingArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureOnboardingSorting>>;
  where?: InputMaybe<IFeatureOnboardingFilter>;
}

export interface IRootQueryAllFeaturePaybackArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeaturePaybackSorting>>;
  where?: InputMaybe<IFeaturePaybackFilter>;
}

export interface IRootQueryAllFeatureQrCodeRefillDrinksArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureQrCodeRefillDrinksSorting>>;
  where?: InputMaybe<IFeatureQrCodeRefillDrinksFilter>;
}

export interface IRootQueryAllFeatureQuizArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureQuizSorting>>;
  where?: InputMaybe<IFeatureQuizFilter>;
}

export interface IRootQueryAllFeatureSeoArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureSeoSorting>>;
  where?: InputMaybe<IFeatureSeoFilter>;
}

export interface IRootQueryAllFeatureStaticMenuArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFeatureStaticMenuSorting>>;
  where?: InputMaybe<IFeatureStaticMenuFilter>;
}

export interface IRootQueryAllFooterArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFooterSorting>>;
  where?: InputMaybe<IFooterFilter>;
}

export interface IRootQueryAllFrontEndConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IFrontEndConfigurationSorting>>;
  where?: InputMaybe<IFrontEndConfigurationFilter>;
}

export interface IRootQueryAllHeroArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IHeroSorting>>;
  where?: InputMaybe<IHeroFilter>;
}

export interface IRootQueryAllHomePageConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IHomePageConfigurationSorting>>;
  where?: InputMaybe<IHomePageConfigurationFilter>;
}

export interface IRootQueryAllImageWithHeaderAndTextArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IImageWithHeaderAndTextSorting>>;
  where?: InputMaybe<IImageWithHeaderAndTextFilter>;
}

export interface IRootQueryAllItemArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IItemSorting>>;
  where?: InputMaybe<IItemFilter>;
}

export interface IRootQueryAllKioskAppDataArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IKioskAppDataSorting>>;
  where?: InputMaybe<IKioskAppDataFilter>;
}

export interface IRootQueryAllKioskConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IKioskConfigurationSorting>>;
  where?: InputMaybe<IKioskConfigurationFilter>;
}

export interface IRootQueryAllLinkPhysicalCardUiArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILinkPhysicalCardUiSorting>>;
  where?: InputMaybe<ILinkPhysicalCardUiFilter>;
}

export interface IRootQueryAllLiveMembershipsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILiveMembershipsSorting>>;
  where?: InputMaybe<ILiveMembershipsFilter>;
}

export interface IRootQueryAllLockedOffersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILockedOffersSorting>>;
  where?: InputMaybe<ILockedOffersFilter>;
}

export interface IRootQueryAllLoyaltyContentArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyContentSorting>>;
  where?: InputMaybe<ILoyaltyContentFilter>;
}

export interface IRootQueryAllLoyaltyLiveExternalOffersArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyLiveExternalOffersSorting>>;
  where?: InputMaybe<ILoyaltyLiveExternalOffersFilter>;
}

export interface IRootQueryAllLoyaltyOffersUiArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyOffersUiSorting>>;
  where?: InputMaybe<ILoyaltyOffersUiFilter>;
}

export interface IRootQueryAllLoyaltyQuestsUiArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyQuestsUiSorting>>;
  where?: InputMaybe<ILoyaltyQuestsUiFilter>;
}

export interface IRootQueryAllLoyaltyReceiptsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyReceiptsSorting>>;
  where?: InputMaybe<ILoyaltyReceiptsFilter>;
}

export interface IRootQueryAllLoyaltySanityQueriesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltySanityQueriesSorting>>;
  where?: InputMaybe<ILoyaltySanityQueriesFilter>;
}

export interface IRootQueryAllLoyaltyUiArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ILoyaltyUiSorting>>;
  where?: InputMaybe<ILoyaltyUiFilter>;
}

export interface IRootQueryAllMainHeroArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMainHeroSorting>>;
  where?: InputMaybe<IMainHeroFilter>;
}

export interface IRootQueryAllMarkerItemArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarkerItemSorting>>;
  where?: InputMaybe<IMarkerItemFilter>;
}

export interface IRootQueryAllMarketingCardArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingCardSorting>>;
  where?: InputMaybe<IMarketingCardFilter>;
}

export interface IRootQueryAllMarketingCardGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingCardGroupSorting>>;
  where?: InputMaybe<IMarketingCardGroupFilter>;
}

export interface IRootQueryAllMarketingTileArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingTileSorting>>;
  where?: InputMaybe<IMarketingTileFilter>;
}

export interface IRootQueryAllMarketingTileBasicArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingTileBasicSorting>>;
  where?: InputMaybe<IMarketingTileBasicFilter>;
}

export interface IRootQueryAllMarketingTileCarouselArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingTileCarouselSorting>>;
  where?: InputMaybe<IMarketingTileCarouselFilter>;
}

export interface IRootQueryAllMarketingTileGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMarketingTileGroupSorting>>;
  where?: InputMaybe<IMarketingTileGroupFilter>;
}

export interface IRootQueryAllMediaTagArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMediaTagSorting>>;
  where?: InputMaybe<IMediaTagFilter>;
}

export interface IRootQueryAllMembershipsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMembershipsSorting>>;
  where?: InputMaybe<IMembershipsFilter>;
}

export interface IRootQueryAllMenuArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IMenuSorting>>;
  where?: InputMaybe<IMenuFilter>;
}

export interface IRootQueryAllModifierArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IModifierSorting>>;
  where?: InputMaybe<IModifierFilter>;
}

export interface IRootQueryAllModifierMultiplierArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IModifierMultiplierSorting>>;
  where?: InputMaybe<IModifierMultiplierFilter>;
}

export interface IRootQueryAllNavigationSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<INavigationSectionSorting>>;
  where?: InputMaybe<INavigationSectionFilter>;
}

export interface IRootQueryAllNutritionalSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<INutritionalSectionSorting>>;
  where?: InputMaybe<INutritionalSectionFilter>;
}

export interface IRootQueryAllOfferArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferSorting>>;
  where?: InputMaybe<IOfferFilter>;
}

export interface IRootQueryAllOfferActivationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferActivationSorting>>;
  where?: InputMaybe<IOfferActivationFilter>;
}

export interface IRootQueryAllOfferCampaignArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferCampaignSorting>>;
  where?: InputMaybe<IOfferCampaignFilter>;
}

export interface IRootQueryAllOfferCarouselSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferCarouselSectionSorting>>;
  where?: InputMaybe<IOfferCarouselSectionFilter>;
}

export interface IRootQueryAllOfferDiscountArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferDiscountSorting>>;
  where?: InputMaybe<IOfferDiscountFilter>;
}

export interface IRootQueryAllOfferItemDiscountArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferItemDiscountSorting>>;
  where?: InputMaybe<IOfferItemDiscountFilter>;
}

export interface IRootQueryAllOfferLoyaltyActionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferLoyaltyActionSorting>>;
  where?: InputMaybe<IOfferLoyaltyActionFilter>;
}

export interface IRootQueryAllOfferTemplateArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOfferTemplateSorting>>;
  where?: InputMaybe<IOfferTemplateFilter>;
}

export interface IRootQueryAllOffersSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOffersSectionSorting>>;
  where?: InputMaybe<IOffersSectionFilter>;
}

export interface IRootQueryAllOpPricingRegionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOpPricingRegionSorting>>;
  where?: InputMaybe<IOpPricingRegionFilter>;
}

export interface IRootQueryAllOurServicesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IOurServicesSorting>>;
  where?: InputMaybe<IOurServicesFilter>;
}

export interface IRootQueryAllPartnerGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPartnerGroupSorting>>;
  where?: InputMaybe<IPartnerGroupFilter>;
}

export interface IRootQueryAllPaymentMethodsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPaymentMethodsSorting>>;
  where?: InputMaybe<IPaymentMethodsFilter>;
}

export interface IRootQueryAllPickerArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPickerSorting>>;
  where?: InputMaybe<IPickerFilter>;
}

export interface IRootQueryAllPickerAspectArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPickerAspectSorting>>;
  where?: InputMaybe<IPickerAspectFilter>;
}

export interface IRootQueryAllPlaceholderFieldArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPlaceholderFieldSorting>>;
  where?: InputMaybe<IPlaceholderFieldFilter>;
}

export interface IRootQueryAllPosConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPosConfigurationSorting>>;
  where?: InputMaybe<IPosConfigurationFilter>;
}

export interface IRootQueryAllPosIntegrationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IPosIntegrationSorting>>;
  where?: InputMaybe<IPosIntegrationFilter>;
}

export interface IRootQueryAllProductCategoryArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IProductCategorySorting>>;
  where?: InputMaybe<IProductCategoryFilter>;
}

export interface IRootQueryAllQuestTemplateArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IQuestTemplateSorting>>;
  where?: InputMaybe<IQuestTemplateFilter>;
}

export interface IRootQueryAllQuestionDetailsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IQuestionDetailsSorting>>;
  where?: InputMaybe<IQuestionDetailsFilter>;
}

export interface IRootQueryAllQuestsSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IQuestsSectionSorting>>;
  where?: InputMaybe<IQuestsSectionFilter>;
}

export interface IRootQueryAllRecentItemsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRecentItemsSorting>>;
  where?: InputMaybe<IRecentItemsFilter>;
}

export interface IRootQueryAllRemediationReasonsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRemediationReasonsSorting>>;
  where?: InputMaybe<IRemediationReasonsFilter>;
}

export interface IRootQueryAllResponseDetailsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IResponseDetailsSorting>>;
  where?: InputMaybe<IResponseDetailsFilter>;
}

export interface IRootQueryAllRestaurantArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantSorting>>;
  where?: InputMaybe<IRestaurantFilter>;
}

export interface IRootQueryAllRestaurantAmenityArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantAmenitySorting>>;
  where?: InputMaybe<IRestaurantAmenityFilter>;
}

export interface IRootQueryAllRestaurantDeliveryProviderArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantDeliveryProviderSorting>>;
  where?: InputMaybe<IRestaurantDeliveryProviderFilter>;
}

export interface IRootQueryAllRestaurantGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantGroupSorting>>;
  where?: InputMaybe<IRestaurantGroupFilter>;
}

export interface IRootQueryAllRestaurantKioskDataArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantKioskDataSorting>>;
  where?: InputMaybe<IRestaurantKioskDataFilter>;
}

export interface IRootQueryAllRestaurantPosDataArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRestaurantPosDataSorting>>;
  where?: InputMaybe<IRestaurantPosDataFilter>;
}

export interface IRootQueryAllResultDetailsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IResultDetailsSorting>>;
  where?: InputMaybe<IResultDetailsFilter>;
}

export interface IRootQueryAllRewardArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRewardSorting>>;
  where?: InputMaybe<IRewardFilter>;
}

export interface IRootQueryAllRewardCategoryArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRewardCategorySorting>>;
  where?: InputMaybe<IRewardCategoryFilter>;
}

export interface IRootQueryAllRewardListArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRewardListSorting>>;
  where?: InputMaybe<IRewardListFilter>;
}

export interface IRootQueryAllRewardsSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRewardsSectionSorting>>;
  where?: InputMaybe<IRewardsSectionFilter>;
}

export interface IRootQueryAllRollUpTheRimSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IRollUpTheRimSectionSorting>>;
  where?: InputMaybe<IRollUpTheRimSectionFilter>;
}

export interface IRootQueryAllSanityFileAssetArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISanityFileAssetSorting>>;
  where?: InputMaybe<ISanityFileAssetFilter>;
}

export interface IRootQueryAllSanityImageAssetArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISanityImageAssetSorting>>;
  where?: InputMaybe<ISanityImageAssetFilter>;
}

export interface IRootQueryAllSanityValidationConfigurationArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISanityValidationConfigurationSorting>>;
  where?: InputMaybe<ISanityValidationConfigurationFilter>;
}

export interface IRootQueryAllScanForRewardsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IScanForRewardsSorting>>;
  where?: InputMaybe<IScanForRewardsFilter>;
}

export interface IRootQueryAllSectionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISectionSorting>>;
  where?: InputMaybe<ISectionFilter>;
}

export interface IRootQueryAllSimpleHomePageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISimpleHomePageSorting>>;
  where?: InputMaybe<ISimpleHomePageFilter>;
}

export interface IRootQueryAllStaticPageArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<IStaticPageSorting>>;
  where?: InputMaybe<IStaticPageFilter>;
}

export interface IRootQueryAllSupportCategoryGroupArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISupportCategoryGroupSorting>>;
  where?: InputMaybe<ISupportCategoryGroupFilter>;
}

export interface IRootQueryAllSupportDataArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISupportDataSorting>>;
  where?: InputMaybe<ISupportDataFilter>;
}

export interface IRootQueryAllSupportFaqArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISupportFaqSorting>>;
  where?: InputMaybe<ISupportFaqFilter>;
}

export interface IRootQueryAllSupportSubcategoryArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISupportSubcategorySorting>>;
  where?: InputMaybe<ISupportSubcategoryFilter>;
}

export interface IRootQueryAllSwapMappingArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISwapMappingSorting>>;
  where?: InputMaybe<ISwapMappingFilter>;
}

export interface IRootQueryAllSystemwideOfferArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISystemwideOfferSorting>>;
  where?: InputMaybe<ISystemwideOfferFilter>;
}

export interface IRootQueryAllSystemwidePromotionArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISystemwidePromotionSorting>>;
  where?: InputMaybe<ISystemwidePromotionFilter>;
}

export interface IRootQueryAllSystemwideQuestArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ISystemwideQuestSorting>>;
  where?: InputMaybe<ISystemwideQuestFilter>;
}

export interface IRootQueryAllTagArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ITagSorting>>;
  where?: InputMaybe<ITagFilter>;
}

export interface IRootQueryAllTrendingArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReadonlyArray<ITrendingSorting>>;
  where?: InputMaybe<ITrendingFilter>;
}

export interface IRoundingRules {
  readonly __typename?: 'RoundingRules';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The number of decimal places that will be shown in the app, e.g. 2.01 is two decimal places */
  readonly decimalPlaces: Maybe<Scalars['Float']['output']>;
  /** If true, enables rounding rules within the application */
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  /** The type of rounding that will be used on each nutrition value. Applied to the decimal place as specified in preceding rule. */
  readonly roundingType: Maybe<Scalars['String']['output']>;
}

export interface IRoundingRulesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly decimalPlaces?: InputMaybe<IFloatFilter>;
  readonly enabled?: InputMaybe<IBooleanFilter>;
  readonly roundingType?: InputMaybe<IStringFilter>;
}

export interface IRoundingRulesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly decimalPlaces?: InputMaybe<SortOrder>;
  readonly enabled?: InputMaybe<SortOrder>;
  readonly roundingType?: InputMaybe<SortOrder>;
}

export interface IRow {
  readonly __typename?: 'Row';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly columns: Maybe<ReadonlyArray<Maybe<IColumn>>>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IRowFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IRowSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IRumConfig {
  readonly __typename?: 'RumConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly applicationId: Maybe<Scalars['String']['output']>;
  readonly clientToken: Maybe<Scalars['String']['output']>;
  readonly dataCenter: Maybe<Scalars['String']['output']>;
}

export interface IRumConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly applicationId?: InputMaybe<IStringFilter>;
  readonly clientToken?: InputMaybe<IStringFilter>;
  readonly dataCenter?: InputMaybe<IStringFilter>;
}

export interface IRumConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly applicationId?: InputMaybe<SortOrder>;
  readonly clientToken?: InputMaybe<SortOrder>;
  readonly dataCenter?: InputMaybe<SortOrder>;
}

export interface ISanityAssetSourceData {
  readonly __typename?: 'SanityAssetSourceData';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  readonly id: Maybe<Scalars['String']['output']>;
  /** A canonical name for the source this asset is originating from */
  readonly name: Maybe<Scalars['String']['output']>;
  /** A URL to find more information about this asset in the originating source */
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface ISanityAssetSourceDataFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly id?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly url?: InputMaybe<IStringFilter>;
}

export interface ISanityAssetSourceDataSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly id?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly url?: InputMaybe<SortOrder>;
}

export interface ISanityFileAsset extends IDocument {
  readonly __typename?: 'SanityFileAsset';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly altText: Maybe<Scalars['String']['output']>;
  readonly assetId: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly extension: Maybe<Scalars['String']['output']>;
  readonly label: Maybe<Scalars['String']['output']>;
  readonly mimeType: Maybe<Scalars['String']['output']>;
  readonly originalFilename: Maybe<Scalars['String']['output']>;
  readonly path: Maybe<Scalars['String']['output']>;
  readonly sha1hash: Maybe<Scalars['String']['output']>;
  readonly size: Maybe<Scalars['Float']['output']>;
  readonly source: Maybe<ISanityAssetSourceData>;
  readonly title: Maybe<Scalars['String']['output']>;
  readonly uploadId: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface ISanityFileAssetFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly altText?: InputMaybe<IStringFilter>;
  readonly assetId?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<IStringFilter>;
  readonly extension?: InputMaybe<IStringFilter>;
  readonly label?: InputMaybe<IStringFilter>;
  readonly mimeType?: InputMaybe<IStringFilter>;
  readonly originalFilename?: InputMaybe<IStringFilter>;
  readonly path?: InputMaybe<IStringFilter>;
  readonly sha1hash?: InputMaybe<IStringFilter>;
  readonly size?: InputMaybe<IFloatFilter>;
  readonly source?: InputMaybe<ISanityAssetSourceDataFilter>;
  readonly title?: InputMaybe<IStringFilter>;
  readonly uploadId?: InputMaybe<IStringFilter>;
  readonly url?: InputMaybe<IStringFilter>;
}

export interface ISanityFileAssetSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly altText?: InputMaybe<SortOrder>;
  readonly assetId?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<SortOrder>;
  readonly extension?: InputMaybe<SortOrder>;
  readonly label?: InputMaybe<SortOrder>;
  readonly mimeType?: InputMaybe<SortOrder>;
  readonly originalFilename?: InputMaybe<SortOrder>;
  readonly path?: InputMaybe<SortOrder>;
  readonly sha1hash?: InputMaybe<SortOrder>;
  readonly size?: InputMaybe<SortOrder>;
  readonly source?: InputMaybe<ISanityAssetSourceDataSorting>;
  readonly title?: InputMaybe<SortOrder>;
  readonly uploadId?: InputMaybe<SortOrder>;
  readonly url?: InputMaybe<SortOrder>;
}

export interface ISanityImageAsset extends IDocument {
  readonly __typename?: 'SanityImageAsset';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly altText: Maybe<Scalars['String']['output']>;
  readonly assetId: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly extension: Maybe<Scalars['String']['output']>;
  readonly label: Maybe<Scalars['String']['output']>;
  readonly metadata: Maybe<ISanityImageMetadata>;
  readonly mimeType: Maybe<Scalars['String']['output']>;
  readonly originalFilename: Maybe<Scalars['String']['output']>;
  readonly path: Maybe<Scalars['String']['output']>;
  readonly sha1hash: Maybe<Scalars['String']['output']>;
  readonly size: Maybe<Scalars['Float']['output']>;
  readonly source: Maybe<ISanityAssetSourceData>;
  readonly title: Maybe<Scalars['String']['output']>;
  readonly uploadId: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
}

export interface ISanityImageAssetFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly altText?: InputMaybe<IStringFilter>;
  readonly assetId?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<IStringFilter>;
  readonly extension?: InputMaybe<IStringFilter>;
  readonly label?: InputMaybe<IStringFilter>;
  readonly metadata?: InputMaybe<ISanityImageMetadataFilter>;
  readonly mimeType?: InputMaybe<IStringFilter>;
  readonly originalFilename?: InputMaybe<IStringFilter>;
  readonly path?: InputMaybe<IStringFilter>;
  readonly sha1hash?: InputMaybe<IStringFilter>;
  readonly size?: InputMaybe<IFloatFilter>;
  readonly source?: InputMaybe<ISanityAssetSourceDataFilter>;
  readonly title?: InputMaybe<IStringFilter>;
  readonly uploadId?: InputMaybe<IStringFilter>;
  readonly url?: InputMaybe<IStringFilter>;
}

export interface ISanityImageAssetSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly altText?: InputMaybe<SortOrder>;
  readonly assetId?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<SortOrder>;
  readonly extension?: InputMaybe<SortOrder>;
  readonly label?: InputMaybe<SortOrder>;
  readonly metadata?: InputMaybe<ISanityImageMetadataSorting>;
  readonly mimeType?: InputMaybe<SortOrder>;
  readonly originalFilename?: InputMaybe<SortOrder>;
  readonly path?: InputMaybe<SortOrder>;
  readonly sha1hash?: InputMaybe<SortOrder>;
  readonly size?: InputMaybe<SortOrder>;
  readonly source?: InputMaybe<ISanityAssetSourceDataSorting>;
  readonly title?: InputMaybe<SortOrder>;
  readonly uploadId?: InputMaybe<SortOrder>;
  readonly url?: InputMaybe<SortOrder>;
}

export interface ISanityImageCrop {
  readonly __typename?: 'SanityImageCrop';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly bottom: Maybe<Scalars['Float']['output']>;
  readonly left: Maybe<Scalars['Float']['output']>;
  readonly right: Maybe<Scalars['Float']['output']>;
  readonly top: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageCropFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly bottom?: InputMaybe<IFloatFilter>;
  readonly left?: InputMaybe<IFloatFilter>;
  readonly right?: InputMaybe<IFloatFilter>;
  readonly top?: InputMaybe<IFloatFilter>;
}

export interface ISanityImageCropSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly bottom?: InputMaybe<SortOrder>;
  readonly left?: InputMaybe<SortOrder>;
  readonly right?: InputMaybe<SortOrder>;
  readonly top?: InputMaybe<SortOrder>;
}

export interface ISanityImageDimensions {
  readonly __typename?: 'SanityImageDimensions';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly aspectRatio: Maybe<Scalars['Float']['output']>;
  readonly height: Maybe<Scalars['Float']['output']>;
  readonly width: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageDimensionsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly aspectRatio?: InputMaybe<IFloatFilter>;
  readonly height?: InputMaybe<IFloatFilter>;
  readonly width?: InputMaybe<IFloatFilter>;
}

export interface ISanityImageDimensionsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly aspectRatio?: InputMaybe<SortOrder>;
  readonly height?: InputMaybe<SortOrder>;
  readonly width?: InputMaybe<SortOrder>;
}

export interface ISanityImageHotspot {
  readonly __typename?: 'SanityImageHotspot';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly height: Maybe<Scalars['Float']['output']>;
  readonly width: Maybe<Scalars['Float']['output']>;
  readonly x: Maybe<Scalars['Float']['output']>;
  readonly y: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageHotspotFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly height?: InputMaybe<IFloatFilter>;
  readonly width?: InputMaybe<IFloatFilter>;
  readonly x?: InputMaybe<IFloatFilter>;
  readonly y?: InputMaybe<IFloatFilter>;
}

export interface ISanityImageHotspotSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly height?: InputMaybe<SortOrder>;
  readonly width?: InputMaybe<SortOrder>;
  readonly x?: InputMaybe<SortOrder>;
  readonly y?: InputMaybe<SortOrder>;
}

export interface ISanityImageMetadata {
  readonly __typename?: 'SanityImageMetadata';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly blurHash: Maybe<Scalars['String']['output']>;
  readonly dimensions: Maybe<ISanityImageDimensions>;
  readonly hasAlpha: Maybe<Scalars['Boolean']['output']>;
  readonly isOpaque: Maybe<Scalars['Boolean']['output']>;
  readonly location: Maybe<IGeopoint>;
  readonly lqip: Maybe<Scalars['String']['output']>;
  readonly palette: Maybe<ISanityImagePalette>;
}

export interface ISanityImageMetadataFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly blurHash?: InputMaybe<IStringFilter>;
  readonly dimensions?: InputMaybe<ISanityImageDimensionsFilter>;
  readonly hasAlpha?: InputMaybe<IBooleanFilter>;
  readonly isOpaque?: InputMaybe<IBooleanFilter>;
  readonly location?: InputMaybe<IGeopointFilter>;
  readonly lqip?: InputMaybe<IStringFilter>;
  readonly palette?: InputMaybe<ISanityImagePaletteFilter>;
}

export interface ISanityImageMetadataSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly blurHash?: InputMaybe<SortOrder>;
  readonly dimensions?: InputMaybe<ISanityImageDimensionsSorting>;
  readonly hasAlpha?: InputMaybe<SortOrder>;
  readonly isOpaque?: InputMaybe<SortOrder>;
  readonly location?: InputMaybe<IGeopointSorting>;
  readonly lqip?: InputMaybe<SortOrder>;
  readonly palette?: InputMaybe<ISanityImagePaletteSorting>;
}

export interface ISanityImagePalette {
  readonly __typename?: 'SanityImagePalette';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly darkMuted: Maybe<ISanityImagePaletteSwatch>;
  readonly darkVibrant: Maybe<ISanityImagePaletteSwatch>;
  readonly dominant: Maybe<ISanityImagePaletteSwatch>;
  readonly lightMuted: Maybe<ISanityImagePaletteSwatch>;
  readonly lightVibrant: Maybe<ISanityImagePaletteSwatch>;
  readonly muted: Maybe<ISanityImagePaletteSwatch>;
  readonly vibrant: Maybe<ISanityImagePaletteSwatch>;
}

export interface ISanityImagePaletteFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly darkMuted?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly darkVibrant?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly dominant?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly lightMuted?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly lightVibrant?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly muted?: InputMaybe<ISanityImagePaletteSwatchFilter>;
  readonly vibrant?: InputMaybe<ISanityImagePaletteSwatchFilter>;
}

export interface ISanityImagePaletteSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly darkMuted?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly darkVibrant?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly dominant?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly lightMuted?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly lightVibrant?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly muted?: InputMaybe<ISanityImagePaletteSwatchSorting>;
  readonly vibrant?: InputMaybe<ISanityImagePaletteSwatchSorting>;
}

export interface ISanityImagePaletteSwatch {
  readonly __typename?: 'SanityImagePaletteSwatch';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly background: Maybe<Scalars['String']['output']>;
  readonly foreground: Maybe<Scalars['String']['output']>;
  readonly population: Maybe<Scalars['Float']['output']>;
  readonly title: Maybe<Scalars['String']['output']>;
}

export interface ISanityImagePaletteSwatchFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly background?: InputMaybe<IStringFilter>;
  readonly foreground?: InputMaybe<IStringFilter>;
  readonly population?: InputMaybe<IFloatFilter>;
  readonly title?: InputMaybe<IStringFilter>;
}

export interface ISanityImagePaletteSwatchSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly background?: InputMaybe<SortOrder>;
  readonly foreground?: InputMaybe<SortOrder>;
  readonly population?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<SortOrder>;
}

export interface ISanityValidationConfiguration extends IDocument {
  readonly __typename?: 'SanityValidationConfiguration';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly clearValidationsButton: Maybe<Scalars['String']['output']>;
  readonly pricingDistributionValidation: Maybe<IPosValidationThreshold>;
  readonly restaurantAvailabilityValidation: Maybe<IPosValidationThreshold>;
}

export interface ISanityValidationConfigurationFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly clearValidationsButton?: InputMaybe<IStringFilter>;
  readonly pricingDistributionValidation?: InputMaybe<IPosValidationThresholdFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IPosValidationThresholdFilter>;
}

export interface ISanityValidationConfigurationSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly clearValidationsButton?: InputMaybe<SortOrder>;
  readonly pricingDistributionValidation?: InputMaybe<IPosValidationThresholdSorting>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IPosValidationThresholdSorting>;
}

export interface ISanityDocumentFilter {
  /** All documents that are drafts. */
  readonly is_draft?: InputMaybe<Scalars['Boolean']['input']>;
  /** All documents referencing the given document ID. */
  readonly references?: InputMaybe<Scalars['ID']['input']>;
}

export interface IScanForRewards extends IDocument {
  readonly __typename?: 'ScanForRewards';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface IScanForRewardsFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface IScanForRewardsSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface ISection extends IDocument {
  readonly __typename?: 'Section';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly carouselImage: Maybe<IImage>;
  readonly carouselImageDescription: Maybe<ILocaleString>;
  readonly channelExclusions: Maybe<IChannelExclusions>;
  readonly contentOptions: Maybe<ReadonlyArray<Maybe<IContentOption>>>;
  readonly daypart: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** @deprecated This has been deprecated */
  readonly description: Maybe<ILocaleBlockContent>;
  /** @deprecated This has been deprecated */
  readonly dmbName: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly headerSuperText: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly headerText: Maybe<ILocaleString>;
  /** Use this toggle to hide this section from main menu UI */
  readonly hiddenFromMainMenu: Maybe<Scalars['Boolean']['output']>;
  readonly image: Maybe<IImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated */
  readonly images: Maybe<IImages>;
  readonly internalName: Maybe<Scalars['String']['output']>;
  /**
   * Is Burgers for Breakfast Section
   * @deprecated This has been deprecated
   */
  readonly isBurgersForBreakfastSection: Maybe<Scalars['Boolean']['output']>;
  /**
   * Is a Real Meal Section?
   * @deprecated This has been deprecated
   */
  readonly isRealMeal: Maybe<Scalars['Boolean']['output']>;
  readonly kioskImage: Maybe<IImage>;
  readonly kioskImageDescription: Maybe<ILocaleString>;
  readonly name: Maybe<ILocaleString>;
  readonly options: Maybe<ReadonlyArray<Maybe<IComboOrItemOrOfferOrPickerOrSection>>>;
  /**
   * This field is only for UI purposes. Adding a promotion reference here will make possible to show a bonus point badges in menu pages. The validation of this field will not take care of rules evaluation, so before adding any promotion here, please verify the promotion is still valid to avoid a bad UX.
   * @deprecated This has been deprecated
   */
  readonly promotion: Maybe<ISystemwidePromotion>;
  readonly redirectLink: Maybe<ILocaleUrl>;
  /** Show section in static menu */
  readonly showInStaticMenu: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This has been deprecated */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
}

export interface ISectionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly carouselImage?: InputMaybe<IImageFilter>;
  readonly carouselImageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly dmbName?: InputMaybe<ILocaleStringFilter>;
  readonly headerSuperText?: InputMaybe<ILocaleStringFilter>;
  readonly headerText?: InputMaybe<ILocaleStringFilter>;
  readonly hiddenFromMainMenu?: InputMaybe<IBooleanFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly images?: InputMaybe<IImagesFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isBurgersForBreakfastSection?: InputMaybe<IBooleanFilter>;
  readonly isRealMeal?: InputMaybe<IBooleanFilter>;
  readonly kioskImage?: InputMaybe<IImageFilter>;
  readonly kioskImageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly promotion?: InputMaybe<ISystemwidePromotionFilter>;
  readonly redirectLink?: InputMaybe<ILocaleUrlFilter>;
  readonly showInStaticMenu?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
}

export interface ISectionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly carouselImage?: InputMaybe<IImageSorting>;
  readonly carouselImageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly channelExclusions?: InputMaybe<IChannelExclusionsSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly dmbName?: InputMaybe<ILocaleStringSorting>;
  readonly headerSuperText?: InputMaybe<ILocaleStringSorting>;
  readonly headerText?: InputMaybe<ILocaleStringSorting>;
  readonly hiddenFromMainMenu?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly images?: InputMaybe<IImagesSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isBurgersForBreakfastSection?: InputMaybe<SortOrder>;
  readonly isRealMeal?: InputMaybe<SortOrder>;
  readonly kioskImage?: InputMaybe<IImageSorting>;
  readonly kioskImageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly redirectLink?: InputMaybe<ILocaleUrlSorting>;
  readonly showInStaticMenu?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
}

export interface IServiceModeRestrictions {
  readonly __typename?: 'ServiceModeRestrictions';
  readonly CATERING_DELIVERY: Maybe<Scalars['Boolean']['output']>;
  readonly CATERING_PICKUP: Maybe<Scalars['Boolean']['output']>;
  readonly CURBSIDE: Maybe<Scalars['Boolean']['output']>;
  readonly DELIVERY: Maybe<Scalars['Boolean']['output']>;
  readonly DRIVE_THRU: Maybe<Scalars['Boolean']['output']>;
  readonly EAT_IN: Maybe<Scalars['Boolean']['output']>;
  readonly TABLE_SERVICE: Maybe<Scalars['Boolean']['output']>;
  readonly TAKEOUT: Maybe<Scalars['Boolean']['output']>;
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
}

export interface IServiceModeRestrictionsFilter {
  readonly CATERING_DELIVERY?: InputMaybe<IBooleanFilter>;
  readonly CATERING_PICKUP?: InputMaybe<IBooleanFilter>;
  readonly CURBSIDE?: InputMaybe<IBooleanFilter>;
  readonly DELIVERY?: InputMaybe<IBooleanFilter>;
  readonly DRIVE_THRU?: InputMaybe<IBooleanFilter>;
  readonly EAT_IN?: InputMaybe<IBooleanFilter>;
  readonly TABLE_SERVICE?: InputMaybe<IBooleanFilter>;
  readonly TAKEOUT?: InputMaybe<IBooleanFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IServiceModeRestrictionsSorting {
  readonly CATERING_DELIVERY?: InputMaybe<SortOrder>;
  readonly CATERING_PICKUP?: InputMaybe<SortOrder>;
  readonly CURBSIDE?: InputMaybe<SortOrder>;
  readonly DELIVERY?: InputMaybe<SortOrder>;
  readonly DRIVE_THRU?: InputMaybe<SortOrder>;
  readonly EAT_IN?: InputMaybe<SortOrder>;
  readonly TABLE_SERVICE?: InputMaybe<SortOrder>;
  readonly TAKEOUT?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IShortCodeConfirmationModalContent {
  readonly __typename?: 'ShortCodeConfirmationModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly actionButton: Maybe<IActionButton>;
  readonly body: Maybe<ILocaleString>;
  readonly dismissButtonText: Maybe<ILocaleString>;
  readonly header: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImages>;
}

export interface IShortCodeConfirmationModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly actionButton?: InputMaybe<IActionButtonFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly dismissButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImagesFilter>;
}

export interface IShortCodeConfirmationModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly actionButton?: InputMaybe<IActionButtonSorting>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly dismissButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImagesSorting>;
}

export interface ISignUpTerms {
  readonly __typename?: 'SignUpTerms';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly signUpTermsCells: Maybe<ReadonlyArray<Maybe<ISignUpTermsCell>>>;
}

export interface ISignUpTermsCell {
  readonly __typename?: 'SignUpTermsCell';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly signUpTermsCellDescription: Maybe<Scalars['String']['output']>;
  readonly signUpTermsCellDisplayedText: Maybe<ILocaleString>;
  readonly signUpTermsCellIsRequired: Maybe<Scalars['Boolean']['output']>;
  readonly signUpTermsCellTitle: Maybe<Scalars['String']['output']>;
  readonly signUpTermsCellUrl: Maybe<ILocaleString>;
}

export interface ISignUpTermsCellFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly signUpTermsCellDescription?: InputMaybe<IStringFilter>;
  readonly signUpTermsCellDisplayedText?: InputMaybe<ILocaleStringFilter>;
  readonly signUpTermsCellIsRequired?: InputMaybe<IBooleanFilter>;
  readonly signUpTermsCellTitle?: InputMaybe<IStringFilter>;
  readonly signUpTermsCellUrl?: InputMaybe<ILocaleStringFilter>;
}

export interface ISignUpTermsCellSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly signUpTermsCellDescription?: InputMaybe<SortOrder>;
  readonly signUpTermsCellDisplayedText?: InputMaybe<ILocaleStringSorting>;
  readonly signUpTermsCellIsRequired?: InputMaybe<SortOrder>;
  readonly signUpTermsCellTitle?: InputMaybe<SortOrder>;
  readonly signUpTermsCellUrl?: InputMaybe<ILocaleStringSorting>;
}

export interface ISignUpTermsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface ISignUpTermsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface ISimpleCard {
  readonly __typename?: 'SimpleCard';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly image: Maybe<IImage>;
  readonly title: Maybe<ILocaleString>;
}

export interface ISimpleCardFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ISimpleCardSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ISimpleHomePage extends IDocument {
  readonly __typename?: 'SimpleHomePage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly altImageText: Maybe<ILocaleString>;
  readonly desktopImage: Maybe<ILocaleImage>;
  readonly header: Maybe<ILocaleString>;
  readonly mobileImage: Maybe<ILocaleImage>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface ISimpleHomePageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly altImageText?: InputMaybe<ILocaleStringFilter>;
  readonly desktopImage?: InputMaybe<ILocaleImageFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly mobileImage?: InputMaybe<ILocaleImageFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface ISimpleHomePageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly altImageText?: InputMaybe<ILocaleStringSorting>;
  readonly desktopImage?: InputMaybe<ILocaleImageSorting>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly mobileImage?: InputMaybe<ILocaleImageSorting>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface ISizeBasedPlu {
  readonly __typename?: 'SizeBasedPlu';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly comboPlu: Maybe<Scalars['String']['output']>;
  /** Only used for BK, to distinguish between different combo sizes. */
  readonly comboSize: Maybe<Scalars['String']['output']>;
}

export interface ISizeBasedPluFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly comboPlu?: InputMaybe<IStringFilter>;
  readonly comboSize?: InputMaybe<IStringFilter>;
}

export interface ISizeBasedPluSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly comboPlu?: InputMaybe<SortOrder>;
  readonly comboSize?: InputMaybe<SortOrder>;
}

export interface ISlug {
  readonly __typename?: 'Slug';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly current: Maybe<Scalars['String']['output']>;
  readonly source: Maybe<Scalars['String']['output']>;
}

export interface ISlugFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly current?: InputMaybe<IStringFilter>;
  readonly source?: InputMaybe<IStringFilter>;
}

export interface ISlugSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly current?: InputMaybe<SortOrder>;
  readonly source?: InputMaybe<SortOrder>;
}

export interface ISocialIconsWidget {
  readonly __typename?: 'SocialIconsWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly facebookUrl: Maybe<ILocaleUrl>;
  readonly instagramUrl: Maybe<ILocaleUrl>;
  readonly linkedinUrl: Maybe<ILocaleUrl>;
  readonly snapchatUrl: Maybe<ILocaleUrl>;
  readonly tiktokUrl: Maybe<ILocaleUrl>;
  readonly twitterUrl: Maybe<ILocaleUrl>;
  readonly youtubeUrl: Maybe<ILocaleUrl>;
}

export interface ISocialIconsWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly facebookUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly instagramUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly linkedinUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly snapchatUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly tiktokUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly twitterUrl?: InputMaybe<ILocaleUrlFilter>;
  readonly youtubeUrl?: InputMaybe<ILocaleUrlFilter>;
}

export interface ISocialIconsWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly facebookUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly instagramUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly linkedinUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly snapchatUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly tiktokUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly twitterUrl?: InputMaybe<ILocaleUrlSorting>;
  readonly youtubeUrl?: InputMaybe<ILocaleUrlSorting>;
}

export interface ISocialIconsWithHeaderWidget {
  readonly __typename?: 'SocialIconsWithHeaderWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly socialIconsWidget: Maybe<ISocialIconsWidget>;
}

export interface ISocialIconsWithHeaderWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
  readonly socialIconsWidget?: InputMaybe<ISocialIconsWidgetFilter>;
}

export interface ISocialIconsWithHeaderWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
  readonly socialIconsWidget?: InputMaybe<ISocialIconsWidgetSorting>;
}

export interface ISocialLoginAppleConfig {
  readonly __typename?: 'SocialLoginAppleConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The developer’s client identifier, as provided by WWDR. */
  readonly clientId: Maybe<Scalars['String']['output']>;
  /** The redirect URIs are the endpoints to which the authentication server can send responses. */
  readonly redirectURI: Maybe<Scalars['String']['output']>;
}

export interface ISocialLoginAppleConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly clientId?: InputMaybe<IStringFilter>;
  readonly redirectURI?: InputMaybe<IStringFilter>;
}

export interface ISocialLoginAppleConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly clientId?: InputMaybe<SortOrder>;
  readonly redirectURI?: InputMaybe<SortOrder>;
}

export interface ISocialLoginFacebookConfig {
  readonly __typename?: 'SocialLoginFacebookConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Unique number that identifies your app */
  readonly appId: Maybe<Scalars['String']['output']>;
  /** The redirect URIs are the endpoints to which the authentication server can send responses. */
  readonly redirectURI: Maybe<Scalars['String']['output']>;
}

export interface ISocialLoginFacebookConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly appId?: InputMaybe<IStringFilter>;
  readonly redirectURI?: InputMaybe<IStringFilter>;
}

export interface ISocialLoginFacebookConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly appId?: InputMaybe<SortOrder>;
  readonly redirectURI?: InputMaybe<SortOrder>;
}

export interface ISocialLoginGoogleConfig {
  readonly __typename?: 'SocialLoginGoogleConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Unique number that identifies your app */
  readonly clientId: Maybe<Scalars['String']['output']>;
  /** The redirect URIs are the endpoints to which the authentication server can send responses. */
  readonly redirectURI: Maybe<Scalars['String']['output']>;
}

export interface ISocialLoginGoogleConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly clientId?: InputMaybe<IStringFilter>;
  readonly redirectURI?: InputMaybe<IStringFilter>;
}

export interface ISocialLoginGoogleConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly clientId?: InputMaybe<SortOrder>;
  readonly redirectURI?: InputMaybe<SortOrder>;
}

export enum SortOrder {
  /** Sorts on the value in ascending order. */
  ASC = 'ASC',
  /** Sorts on the value in descending order. */
  DESC = 'DESC',
}

export interface ISpan {
  readonly __typename?: 'Span';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly marks: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly text: Maybe<Scalars['String']['output']>;
}

export interface IStaticPage extends IDocument {
  readonly __typename?: 'StaticPage';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated. */
  readonly displayIndex: Maybe<Scalars['Float']['output']>;
  /** @deprecated This has been deprecated. */
  readonly image: Maybe<IImage>;
  readonly includedInSitemap: Maybe<Scalars['Boolean']['output']>;
  readonly localePath: Maybe<ILocaleSlug>;
  readonly localeTitle: Maybe<ILocaleString>;
  readonly metaTags: Maybe<IMetaTags>;
  readonly ogTags: Maybe<IOgTags>;
  readonly pageCSS: Maybe<ICss>;
  readonly pageHtml: Maybe<IHtml>;
  /** @deprecated This has been deprecated. */
  readonly pageVariablesRaw: Maybe<Scalars['JSON']['output']>;
  /** @deprecated This has been deprecated. */
  readonly parent: Maybe<IStaticPage>;
  readonly path: Maybe<ISlug>;
  readonly redirectUrl: Maybe<ILocaleString>;
  readonly requiredUserAcceptance: Maybe<Scalars['Boolean']['output']>;
  readonly title: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly vendorId: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly vendorRedirectPath: Maybe<ILocaleString>;
  readonly widgets: Maybe<
    ReadonlyArray<
      Maybe<IAccordionWidgetOrAnchorLinksWidgetOrAnchorWidgetOrCallToActionWidgetOrDoubleImageWidgetOrDownloadFileWidgetOrHeaderWidgetOrImageWidgetOrInfoCellsWidgetOrLocaleBlockTextWidgetOrLoyaltyBannerWidgetOrLoyaltyTabSelectorWidgetOrMultiWidgetOrNutritionExplorerWidgetOrNutritionInfoWidgetOrPreviewWidgetOrQuoteWidgetOrRewardsCarouselWidgetOrVideoWidgetOrWebViewWidgetOrYoutubeWidget>
    >
  >;
}

export interface IStaticPageFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly displayIndex?: InputMaybe<IFloatFilter>;
  readonly image?: InputMaybe<IImageFilter>;
  readonly includedInSitemap?: InputMaybe<IBooleanFilter>;
  readonly localePath?: InputMaybe<ILocaleSlugFilter>;
  readonly localeTitle?: InputMaybe<ILocaleStringFilter>;
  readonly metaTags?: InputMaybe<IMetaTagsFilter>;
  readonly ogTags?: InputMaybe<IOgTagsFilter>;
  readonly pageCSS?: InputMaybe<ICssFilter>;
  readonly pageHtml?: InputMaybe<IHtmlFilter>;
  readonly parent?: InputMaybe<IStaticPageFilter>;
  readonly path?: InputMaybe<ISlugFilter>;
  readonly redirectUrl?: InputMaybe<ILocaleStringFilter>;
  readonly requiredUserAcceptance?: InputMaybe<IBooleanFilter>;
  readonly title?: InputMaybe<IStringFilter>;
  readonly vendorId?: InputMaybe<IStringFilter>;
  readonly vendorRedirectPath?: InputMaybe<ILocaleStringFilter>;
}

export interface IStaticPageSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly displayIndex?: InputMaybe<SortOrder>;
  readonly image?: InputMaybe<IImageSorting>;
  readonly includedInSitemap?: InputMaybe<SortOrder>;
  readonly localePath?: InputMaybe<ILocaleSlugSorting>;
  readonly localeTitle?: InputMaybe<ILocaleStringSorting>;
  readonly metaTags?: InputMaybe<IMetaTagsSorting>;
  readonly ogTags?: InputMaybe<IOgTagsSorting>;
  readonly pageCSS?: InputMaybe<ICssSorting>;
  readonly pageHtml?: InputMaybe<IHtmlSorting>;
  readonly path?: InputMaybe<ISlugSorting>;
  readonly redirectUrl?: InputMaybe<ILocaleStringSorting>;
  readonly requiredUserAcceptance?: InputMaybe<SortOrder>;
  readonly title?: InputMaybe<SortOrder>;
  readonly vendorId?: InputMaybe<SortOrder>;
  readonly vendorRedirectPath?: InputMaybe<ILocaleStringSorting>;
}

export interface IStoreLocatorContent {
  readonly __typename?: 'StoreLocatorContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated. */
  readonly findStoresCard: Maybe<ITextCard>;
  /** @deprecated This has been deprecated. */
  readonly noStoresCard: Maybe<ITextCard>;
  readonly searchThisAreaButtonLabel: Maybe<ILocaleString>;
  /** @deprecated This has been deprecated. */
  readonly tabsContent: Maybe<ITabsContent>;
  readonly tabsStoreLocatorContent: Maybe<ITabsStoreLocatorContent>;
}

export interface IStoreLocatorContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly findStoresCard?: InputMaybe<ITextCardFilter>;
  readonly noStoresCard?: InputMaybe<ITextCardFilter>;
  readonly searchThisAreaButtonLabel?: InputMaybe<ILocaleStringFilter>;
  readonly tabsContent?: InputMaybe<ITabsContentFilter>;
  readonly tabsStoreLocatorContent?: InputMaybe<ITabsStoreLocatorContentFilter>;
}

export interface IStoreLocatorContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly findStoresCard?: InputMaybe<ITextCardSorting>;
  readonly noStoresCard?: InputMaybe<ITextCardSorting>;
  readonly searchThisAreaButtonLabel?: InputMaybe<ILocaleStringSorting>;
  readonly tabsContent?: InputMaybe<ITabsContentSorting>;
  readonly tabsStoreLocatorContent?: InputMaybe<ITabsStoreLocatorContentSorting>;
}

export interface IStringFilter {
  /** Checks if the value is equal to the given input. */
  readonly eq?: InputMaybe<Scalars['String']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** Checks if the value is defined. */
  readonly is_defined?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value matches the given word/words. */
  readonly matches?: InputMaybe<Scalars['String']['input']>;
  /** Checks if the value is not equal to the given input. */
  readonly neq?: InputMaybe<Scalars['String']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface ISubtotalSpend {
  readonly __typename?: 'SubtotalSpend';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Minimum subtotal spend in cents in the cart */
  readonly minimumSpend: Maybe<Scalars['Float']['output']>;
}

export interface ISubtotalSpendFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly minimumSpend?: InputMaybe<IFloatFilter>;
}

export interface ISubtotalSpendSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly minimumSpend?: InputMaybe<SortOrder>;
}

export interface ISupportCategory {
  readonly __typename?: 'SupportCategory';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly directTicketProcessingPercentage: Maybe<Scalars['Float']['output']>;
  readonly knowledgeForceIssueId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface ISupportCategoryFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly directTicketProcessingPercentage?: InputMaybe<IFloatFilter>;
  readonly knowledgeForceIssueId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface ISupportCategoryGroup extends IDocument {
  readonly __typename?: 'SupportCategoryGroup';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /**
   * Internal category name.
   * @deprecated This has been deprecated
   */
  readonly categoryId: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly guestTicketingMiddlewareRedirect: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly showRestaurantSelector: Maybe<Scalars['Boolean']['output']>;
  readonly supportSubcategories: Maybe<ReadonlyArray<Maybe<ISupportSubcategory>>>;
  readonly suppotCategoryFaqs: Maybe<ReadonlyArray<Maybe<ISupportFaq>>>;
  /**
   *
   *         Specify the maximum time threshold, in minutes, for sending the ticket to the Store Partner. If the time threshold is exceeded, the ticket will be automatically forwarded to the General Partner.
   *         If you leave this field empty, there will be no time threshold, and all tickets will always be directed to the Store Partner.
   *         (Leave empty if there's no General Partner configured).
   *
   */
  readonly ticketTimeThreshold: Maybe<Scalars['Float']['output']>;
  /**
   *
   *       We currently offer two ticket types that can be customized to be directed to different support partners. "General" tickets are not associated with a specific order, while "Store" tickets are linked to a particular order. Please select the routing preference for tickets in this category. Leave empty for legacy integration.
   *
   */
  readonly ticketType: Maybe<Scalars['String']['output']>;
}

export interface ISupportCategoryGroupFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly categoryId?: InputMaybe<IStringFilter>;
  readonly guestTicketingMiddlewareRedirect?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly showRestaurantSelector?: InputMaybe<IBooleanFilter>;
  readonly ticketTimeThreshold?: InputMaybe<IFloatFilter>;
  readonly ticketType?: InputMaybe<IStringFilter>;
}

export interface ISupportCategoryGroupSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly categoryId?: InputMaybe<SortOrder>;
  readonly guestTicketingMiddlewareRedirect?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly showRestaurantSelector?: InputMaybe<SortOrder>;
  readonly ticketTimeThreshold?: InputMaybe<SortOrder>;
  readonly ticketType?: InputMaybe<SortOrder>;
}

export interface ISupportCategorySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly directTicketProcessingPercentage?: InputMaybe<SortOrder>;
  readonly knowledgeForceIssueId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface ISupportCategorySubTypes {
  readonly __typename?: 'SupportCategorySubTypes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly directTicketProcessingPercentage: Maybe<Scalars['Float']['output']>;
  readonly knowledgeForceIssueId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
}

export interface ISupportCategorySubTypesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly directTicketProcessingPercentage?: InputMaybe<IFloatFilter>;
  readonly knowledgeForceIssueId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface ISupportCategorySubTypesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly directTicketProcessingPercentage?: InputMaybe<SortOrder>;
  readonly knowledgeForceIssueId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface ISupportCategoryTypes {
  readonly __typename?: 'SupportCategoryTypes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly supportCategorySubTypes: Maybe<ReadonlyArray<Maybe<ISupportCategorySubTypes>>>;
}

export interface ISupportCategoryTypesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
}

export interface ISupportCategoryTypesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
}

export interface ISupportData extends IDocument {
  readonly __typename?: 'SupportData';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This has been deprecated */
  readonly categories: Maybe<ReadonlyArray<Maybe<ISupportCategory>>>;
  readonly deliveryMissingItemsSubcategory: Maybe<ISupportSubcategory>;
  readonly deliveryNeverArriveSubcategory: Maybe<ISupportSubcategory>;
  /** A support category group for customers who need general help with an order they've placed. */
  readonly orderingIssuesCategoryGroup: Maybe<ISupportCategoryGroup>;
  /** @deprecated This has been deprecated */
  readonly supportCategories: Maybe<ReadonlyArray<Maybe<ISupportCategoryTypes>>>;
  readonly supportCategoryGroups: Maybe<ReadonlyArray<Maybe<ISupportCategoryGroup>>>;
  readonly supportDataFaqs: Maybe<ReadonlyArray<Maybe<ISupportFaq>>>;
  /** @deprecated This has been deprecated */
  readonly supportFaqs: Maybe<ReadonlyArray<Maybe<ISupportFaqs>>>;
  readonly supportPolicyCover: Maybe<ReadonlyArray<Maybe<IPrivacyPolicyDisclaimersCell>>>;
  /** Choose the subcategory used by customers to request date of birth changes. This subcategory will only be visible to signed-in customers (signed-out customers can't make this request). */
  readonly updateDateOfBirthSubcategory: Maybe<ISupportSubcategory>;
}

export interface ISupportDataFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly deliveryMissingItemsSubcategory?: InputMaybe<ISupportSubcategoryFilter>;
  readonly deliveryNeverArriveSubcategory?: InputMaybe<ISupportSubcategoryFilter>;
  readonly orderingIssuesCategoryGroup?: InputMaybe<ISupportCategoryGroupFilter>;
  readonly updateDateOfBirthSubcategory?: InputMaybe<ISupportSubcategoryFilter>;
}

export interface ISupportDataSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
}

export interface ISupportFaq extends IDocument {
  readonly __typename?: 'SupportFaq';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly supportFaqContent: Maybe<ILocaleBlockContent>;
  /** Name of the mParticle event emitted when expanded for the first time. */
  readonly supportFaqMParticleEvent: Maybe<Scalars['String']['output']>;
  readonly supportFaqTitle: Maybe<ILocaleString>;
}

export interface ISupportFaqFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly supportFaqContent?: InputMaybe<ILocaleBlockContentFilter>;
  readonly supportFaqMParticleEvent?: InputMaybe<IStringFilter>;
  readonly supportFaqTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ISupportFaqSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly supportFaqContent?: InputMaybe<ILocaleBlockContentSorting>;
  readonly supportFaqMParticleEvent?: InputMaybe<SortOrder>;
  readonly supportFaqTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ISupportFaqs {
  readonly __typename?: 'SupportFaqs';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly accordionWidget: Maybe<IAccordionWidget>;
  readonly key: Maybe<Scalars['String']['output']>;
}

export interface ISupportFaqsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly accordionWidget?: InputMaybe<IAccordionWidgetFilter>;
  readonly key?: InputMaybe<IStringFilter>;
}

export interface ISupportFaqsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly accordionWidget?: InputMaybe<IAccordionWidgetSorting>;
  readonly key?: InputMaybe<SortOrder>;
}

export interface ISupportSubcategory extends IDocument {
  readonly __typename?: 'SupportSubcategory';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly directTicketProcessingPercentage: Maybe<Scalars['Float']['output']>;
  readonly knowledgeForceIssueId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<ILocaleString>;
  /**
   * FAQ linked to this support subcategory
   * @deprecated This has been deprecated
   */
  readonly supportSubcategoryFaq: Maybe<ISupportFaq>;
  readonly supportSubcategoryFaqs: Maybe<ReadonlyArray<Maybe<ISupportFaq>>>;
  /** When selected, this subcategory will forward the customer to recent orders selection. */
  readonly supportSubcategoryForwardToOrders: Maybe<Scalars['Boolean']['output']>;
  /** When selected, this subcategory will provide the user with a dropdown menu to specify the service mode of their order, but only when there is no order with a service mode available. */
  readonly supportSubcategoryServiceModeSelector: Maybe<Scalars['Boolean']['output']>;
  /** When selected, support form submissions in this subcategory will be sent to External Partners rather than Zendesk. */
  readonly supportSubcategorySubmitToExternalPartners: Maybe<Scalars['Boolean']['output']>;
  /** When selected, support form submissions in this subcategory will be sent to Sutherland rather than Zendesk. This is intended for guest support issues. */
  readonly supportSubcategorySubmitToSutherland: Maybe<Scalars['Boolean']['output']>;
}

export interface ISupportSubcategoryFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly directTicketProcessingPercentage?: InputMaybe<IFloatFilter>;
  readonly knowledgeForceIssueId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly supportSubcategoryFaq?: InputMaybe<ISupportFaqFilter>;
  readonly supportSubcategoryForwardToOrders?: InputMaybe<IBooleanFilter>;
  readonly supportSubcategoryServiceModeSelector?: InputMaybe<IBooleanFilter>;
  readonly supportSubcategorySubmitToExternalPartners?: InputMaybe<IBooleanFilter>;
  readonly supportSubcategorySubmitToSutherland?: InputMaybe<IBooleanFilter>;
}

export interface ISupportSubcategorySorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly directTicketProcessingPercentage?: InputMaybe<SortOrder>;
  readonly knowledgeForceIssueId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly supportSubcategoryForwardToOrders?: InputMaybe<SortOrder>;
  readonly supportSubcategoryServiceModeSelector?: InputMaybe<SortOrder>;
  readonly supportSubcategorySubmitToExternalPartners?: InputMaybe<SortOrder>;
  readonly supportSubcategorySubmitToSutherland?: InputMaybe<SortOrder>;
}

export interface ISurpriseAndDelightModalContent {
  readonly __typename?: 'SurpriseAndDelightModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly backgroundImage: Maybe<ILocaleImage>;
  readonly buttonText: Maybe<ILocaleString>;
  readonly description: Maybe<ILocaleString>;
  readonly noThanksText: Maybe<ILocaleString>;
  readonly prompt: Maybe<ILocaleString>;
  readonly termsText: Maybe<ILocaleBlockContent>;
  readonly title: Maybe<ILocaleString>;
}

export interface ISurpriseAndDelightModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly backgroundImage?: InputMaybe<ILocaleImageFilter>;
  readonly buttonText?: InputMaybe<ILocaleStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly noThanksText?: InputMaybe<ILocaleStringFilter>;
  readonly prompt?: InputMaybe<ILocaleStringFilter>;
  readonly termsText?: InputMaybe<ILocaleBlockContentFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ISurpriseAndDelightModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<ILocaleImageSorting>;
  readonly buttonText?: InputMaybe<ILocaleStringSorting>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly noThanksText?: InputMaybe<ILocaleStringSorting>;
  readonly prompt?: InputMaybe<ILocaleStringSorting>;
  readonly termsText?: InputMaybe<ILocaleBlockContentSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ISwap {
  readonly __typename?: 'Swap';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** If this item is added to cart... */
  readonly initialItem: Maybe<IComboOrItem>;
  /** Type of swap */
  readonly swapType: Maybe<Scalars['String']['output']>;
  /** User can swap to this instead */
  readonly swappedItem: Maybe<IComboOrItem>;
}

export interface ISwapFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly swapType?: InputMaybe<IStringFilter>;
}

export interface ISwapMapping extends IDocument {
  readonly __typename?: 'SwapMapping';
  /**
   * Date the document was created
   * @deprecated This is deprecated
   */
  readonly _createdAt: Scalars['DateTime']['output'];
  /**
   * Document ID
   * @deprecated This is deprecated
   */
  readonly _id: Scalars['ID']['output'];
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /**
   * Current document revision
   * @deprecated This is deprecated
   */
  readonly _rev: Scalars['String']['output'];
  /**
   * Document type
   * @deprecated This is deprecated
   */
  readonly _type: Scalars['String']['output'];
  /**
   * Date the document was last modified
   * @deprecated This is deprecated
   */
  readonly _updatedAt: Scalars['DateTime']['output'];
  /** @deprecated This is deprecated */
  readonly eligibleSwaps: Maybe<ReadonlyArray<Maybe<ISwap>>>;
  /**
   * Only used internally
   * @deprecated This is deprecated
   */
  readonly internalName: Maybe<Scalars['String']['output']>;
}

export interface ISwapMappingFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
}

export interface ISwapMappingSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly internalName?: InputMaybe<SortOrder>;
}

export interface ISwapModalContent {
  readonly __typename?: 'SwapModalContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<ILocaleString>;
  readonly itemSelectionHeader: Maybe<ILocaleString>;
  readonly noThanksButtonText: Maybe<ILocaleString>;
  readonly swapButtonText: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ISwapModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly description?: InputMaybe<ILocaleStringFilter>;
  readonly itemSelectionHeader?: InputMaybe<ILocaleStringFilter>;
  readonly noThanksButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly swapButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ISwapModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleStringSorting>;
  readonly itemSelectionHeader?: InputMaybe<ILocaleStringSorting>;
  readonly noThanksButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly swapButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ISwapSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly swapType?: InputMaybe<SortOrder>;
}

export interface ISystemwideOffer extends IDocument {
  readonly __typename?: 'SystemwideOffer';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly backgroundImage: Maybe<IImage>;
  /** This incentive will only be displayed to a user if set by personalization */
  readonly configOnly: Maybe<Scalars['Boolean']['output']>;
  readonly daypart: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly description: Maybe<ILocaleBlockContent>;
  /** When this is set to true this offer will not show in the user's offer list, even if it is added to the Live Offers list in Sanity. This can be used for Upsell Offers, if we don't want to show them in the user's offers list and want it to only be accessible through the Upselling Modal. */
  readonly hideOfferFromOffersList: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly inStoreBenefits: Maybe<ReadonlyArray<Maybe<IComboOrItemOrPicker>>>;
  readonly incentives: Maybe<
    ReadonlyArray<Maybe<IComboOrItemOrOfferActivationOrOfferDiscountOrPickerOrSwapMapping>>
  >;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** On Mechanics, if you set the rule "Loyalty Between-Dates," you can display this offer as upcoming before the start date of the offer, and the offer will be designated with a "Coming Soon" status. This offer will automatically become an “Available” from the point the start date is reached. */
  readonly isUpcomingOffer: Maybe<Scalars['Boolean']['output']>;
  readonly localizedImage: Maybe<ILocaleImages>;
  /** @deprecated This is deprecated */
  readonly lockedOffersPanel: Maybe<ILockedOffersPanel>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly marketPrice: Maybe<IComboOrItem>;
  /**
   * When true, guest will not have option to redeem in-rest
   * @deprecated This is deprecated
   */
  readonly mobileOrderOnly: Maybe<Scalars['Boolean']['output']>;
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly name: Maybe<ILocaleBlockContent>;
  /** @deprecated This is deprecated */
  readonly notificationDetails: Maybe<INotificationDetails>;
  /** This represents the price of the offer. Price must be entered in cents. For BOGO offers, the offer price should be entered as 0 */
  readonly offerPrice: Maybe<Scalars['Float']['output']>;
  readonly pluConfigs: Maybe<IPluConfigs>;
  /**
   * This represents the PMIX product number
   * @deprecated This is deprecated
   */
  readonly pmixId: Maybe<Scalars['String']['output']>;
  /**
   * Position will be personalized by SSE
   * @deprecated This is deprecated
   */
  readonly position: Maybe<Scalars['Boolean']['output']>;
  readonly pricingDistributionValidation: Maybe<IDataValidationObject>;
  readonly pricingPermutationValidation: Maybe<IDataValidationObject>;
  /** @deprecated This is deprecated */
  readonly promoCodePanel: Maybe<IPromoCodePanel>;
  /** Specify the Order redemption method. */
  readonly redemptionMethod: Maybe<Scalars['String']['output']>;
  /**
   * Controls where/how the user redeems this offer
   * @deprecated This is deprecated
   */
  readonly redemptionType: Maybe<Scalars['String']['output']>;
  readonly restaurantAvailabilityValidation: Maybe<IDataValidationObject>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIsStackableOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserAttributesOrUserCreated>
    >
  >;
  /** This is a static code of the offer. This code will never change. Static codes majorly show when users are signed out and want to redeem an offer in the restaurant or if your market does not have loyalty integration with POS / Kiosk. */
  readonly shortCode: Maybe<Scalars['String']['output']>;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This is deprecated */
  readonly uiPattern: Maybe<Scalars['String']['output']>;
  readonly upsellOptions: Maybe<ReadonlyArray<Maybe<ISystemwideOffer>>>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface ISystemwideOfferFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly backgroundImage?: InputMaybe<IImageFilter>;
  readonly configOnly?: InputMaybe<IBooleanFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly hideOfferFromOffersList?: InputMaybe<IBooleanFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly isUpcomingOffer?: InputMaybe<IBooleanFilter>;
  readonly localizedImage?: InputMaybe<ILocaleImagesFilter>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly mobileOrderOnly?: InputMaybe<IBooleanFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly name?: InputMaybe<ILocaleBlockContentFilter>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsFilter>;
  readonly offerPrice?: InputMaybe<IFloatFilter>;
  readonly pluConfigs?: InputMaybe<IPluConfigsFilter>;
  readonly pmixId?: InputMaybe<IStringFilter>;
  readonly position?: InputMaybe<IBooleanFilter>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelFilter>;
  readonly redemptionMethod?: InputMaybe<IStringFilter>;
  readonly redemptionType?: InputMaybe<IStringFilter>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectFilter>;
  readonly shortCode?: InputMaybe<IStringFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
  readonly uiPattern?: InputMaybe<IStringFilter>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsFilter>;
}

export interface ISystemwideOfferSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly backgroundImage?: InputMaybe<IImageSorting>;
  readonly configOnly?: InputMaybe<SortOrder>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly hideOfferFromOffersList?: InputMaybe<SortOrder>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly isUpcomingOffer?: InputMaybe<SortOrder>;
  readonly localizedImage?: InputMaybe<ILocaleImagesSorting>;
  readonly lockedOffersPanel?: InputMaybe<ILockedOffersPanelSorting>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly mobileOrderOnly?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly name?: InputMaybe<ILocaleBlockContentSorting>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsSorting>;
  readonly offerPrice?: InputMaybe<SortOrder>;
  readonly pluConfigs?: InputMaybe<IPluConfigsSorting>;
  readonly pmixId?: InputMaybe<SortOrder>;
  readonly position?: InputMaybe<SortOrder>;
  readonly pricingDistributionValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly pricingPermutationValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly promoCodePanel?: InputMaybe<IPromoCodePanelSorting>;
  readonly redemptionMethod?: InputMaybe<SortOrder>;
  readonly redemptionType?: InputMaybe<SortOrder>;
  readonly restaurantAvailabilityValidation?: InputMaybe<IDataValidationObjectSorting>;
  readonly shortCode?: InputMaybe<SortOrder>;
  readonly testOnly?: InputMaybe<SortOrder>;
  readonly uiPattern?: InputMaybe<SortOrder>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsSorting>;
}

export interface ISystemwidePromotion extends IDocument {
  readonly __typename?: 'SystemwidePromotion';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly bonusPoints: Maybe<Scalars['Float']['output']>;
  /** Deprecated - Please use the between-dates rule to add an end date */
  readonly endDate: Maybe<Scalars['DateTime']['output']>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  /** This is an internal name only */
  readonly name: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly notificationDetails: Maybe<INotificationDetails>;
  readonly pointsMultiplier: Maybe<Scalars['Float']['output']>;
  readonly promoMessage: Maybe<ILocaleString>;
  /** Deprecated - Please use the between-dates rule to add a start date */
  readonly startDate: Maybe<Scalars['DateTime']['output']>;
  readonly systemwidePromotion: Maybe<
    ReadonlyArray<
      Maybe<IBirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIncentiveRequirementOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTierListOrTotalSpendOrTransactionCountOrUserCreated>
    >
  >;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface ISystemwidePromotionFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly bonusPoints?: InputMaybe<IFloatFilter>;
  readonly endDate?: InputMaybe<IDatetimeFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsFilter>;
  readonly pointsMultiplier?: InputMaybe<IFloatFilter>;
  readonly promoMessage?: InputMaybe<ILocaleStringFilter>;
  readonly startDate?: InputMaybe<IDatetimeFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface ISystemwidePromotionSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly bonusPoints?: InputMaybe<SortOrder>;
  readonly endDate?: InputMaybe<SortOrder>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly notificationDetails?: InputMaybe<INotificationDetailsSorting>;
  readonly pointsMultiplier?: InputMaybe<SortOrder>;
  readonly promoMessage?: InputMaybe<ILocaleStringSorting>;
  readonly startDate?: InputMaybe<SortOrder>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface ISystemwideQuest extends IDocument {
  readonly __typename?: 'SystemwideQuest';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly completedState: Maybe<ICompletedState>;
  readonly description: Maybe<ILocaleBlockContent>;
  readonly howToRedeem: Maybe<ILocaleBlockContent>;
  readonly image: Maybe<ILocaleImage>;
  readonly imageDescription: Maybe<ILocaleString>;
  readonly incentive: Maybe<IQuestIncentive>;
  /** The name that will be displayed in Sanity - it will help you to quickly identify, search and organize your incentives. (e.g., 4150_Offer_Combo) */
  readonly internalName: Maybe<Scalars['String']['output']>;
  /** The unique loyalty engine ID of the incentive. It is populated only after the offer is successfully published. */
  readonly loyaltyEngineId: Maybe<Scalars['String']['output']>;
  readonly moreInfo: Maybe<ILocaleBlockContent>;
  readonly mustActivateBy: Maybe<Scalars['Date']['output']>;
  readonly name: Maybe<ILocaleString>;
  readonly questRules: Maybe<IQuestRules>;
  readonly rules: Maybe<
    ReadonlyArray<
      Maybe<IEmailVerifiedRequiredOrLimitOrLoyaltyBetweenDatesOrRequiresAuthenticationOrUserCreated>
    >
  >;
  /** If this toggle is turned on the incentive will only be available in lower environment (Dev, Staging and QA). Even if the incentive is accidentally pushed to Production, it will not show up for users in the App. */
  readonly testOnly: Maybe<Scalars['Boolean']['output']>;
}

export interface ISystemwideQuestFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly completedState?: InputMaybe<ICompletedStateFilter>;
  readonly description?: InputMaybe<ILocaleBlockContentFilter>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly imageDescription?: InputMaybe<ILocaleStringFilter>;
  readonly incentive?: InputMaybe<IQuestIncentiveFilter>;
  readonly internalName?: InputMaybe<IStringFilter>;
  readonly loyaltyEngineId?: InputMaybe<IStringFilter>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentFilter>;
  readonly mustActivateBy?: InputMaybe<IDateFilter>;
  readonly name?: InputMaybe<ILocaleStringFilter>;
  readonly questRules?: InputMaybe<IQuestRulesFilter>;
  readonly testOnly?: InputMaybe<IBooleanFilter>;
}

export interface ISystemwideQuestSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly completedState?: InputMaybe<ICompletedStateSorting>;
  readonly description?: InputMaybe<ILocaleBlockContentSorting>;
  readonly howToRedeem?: InputMaybe<ILocaleBlockContentSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly imageDescription?: InputMaybe<ILocaleStringSorting>;
  readonly incentive?: InputMaybe<IQuestIncentiveSorting>;
  readonly internalName?: InputMaybe<SortOrder>;
  readonly loyaltyEngineId?: InputMaybe<SortOrder>;
  readonly moreInfo?: InputMaybe<ILocaleBlockContentSorting>;
  readonly mustActivateBy?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<ILocaleStringSorting>;
  readonly questRules?: InputMaybe<IQuestRulesSorting>;
  readonly testOnly?: InputMaybe<SortOrder>;
}

export interface ITabDetailContent {
  readonly __typename?: 'TabDetailContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly image: Maybe<ILocaleImage>;
  readonly title: Maybe<ILocaleString>;
}

export interface ITabDetailContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly image?: InputMaybe<ILocaleImageFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ITabDetailContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly image?: InputMaybe<ILocaleImageSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ITabsContent {
  readonly __typename?: 'TabsContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly favoritesTab: Maybe<ITitleAndBody>;
  readonly nearbyTab: Maybe<ITitleAndBody>;
  readonly recentTab: Maybe<ITitleAndBody>;
}

export interface ITabsContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly favoritesTab?: InputMaybe<ITitleAndBodyFilter>;
  readonly nearbyTab?: InputMaybe<ITitleAndBodyFilter>;
  readonly recentTab?: InputMaybe<ITitleAndBodyFilter>;
}

export interface ITabsContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly favoritesTab?: InputMaybe<ITitleAndBodySorting>;
  readonly nearbyTab?: InputMaybe<ITitleAndBodySorting>;
  readonly recentTab?: InputMaybe<ITitleAndBodySorting>;
}

export interface ITabsStoreLocatorContent {
  readonly __typename?: 'TabsStoreLocatorContent';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly favoritesTab: Maybe<ITabDetailContent>;
  readonly nearbyTab: Maybe<ITabDetailContent>;
  readonly recentTab: Maybe<ITabDetailContent>;
}

export interface ITabsStoreLocatorContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly favoritesTab?: InputMaybe<ITabDetailContentFilter>;
  readonly nearbyTab?: InputMaybe<ITabDetailContentFilter>;
  readonly recentTab?: InputMaybe<ITabDetailContentFilter>;
}

export interface ITabsStoreLocatorContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly favoritesTab?: InputMaybe<ITabDetailContentSorting>;
  readonly nearbyTab?: InputMaybe<ITabDetailContentSorting>;
  readonly recentTab?: InputMaybe<ITabDetailContentSorting>;
}

export interface ITag extends IDocument {
  readonly __typename?: 'Tag';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface ITagFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly value?: InputMaybe<IStringFilter>;
}

export interface ITagSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly value?: InputMaybe<SortOrder>;
}

export interface ITastySwap {
  readonly __typename?: 'TastySwap';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The product the user is looking at */
  readonly selectedProduct: Maybe<IComboOrItem>;
  /** The product we propose instead */
  readonly suggestedProduct: Maybe<IComboOrItem>;
  readonly swapTitle: Maybe<ILocaleString>;
}

export interface ITastySwapFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly swapTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ITastySwapSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly swapTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ITastySwaps {
  readonly __typename?: 'TastySwaps';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly mainSubtitle: Maybe<ILocaleString>;
  readonly mainTitle: Maybe<ILocaleString>;
  readonly modalSubtitle: Maybe<ILocaleString>;
  readonly modalTitle: Maybe<ILocaleString>;
  readonly swaps: Maybe<ReadonlyArray<Maybe<ITastySwap>>>;
}

export interface ITastySwapsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly mainSubtitle?: InputMaybe<ILocaleStringFilter>;
  readonly mainTitle?: InputMaybe<ILocaleStringFilter>;
  readonly modalSubtitle?: InputMaybe<ILocaleStringFilter>;
  readonly modalTitle?: InputMaybe<ILocaleStringFilter>;
}

export interface ITastySwapsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly mainSubtitle?: InputMaybe<ILocaleStringSorting>;
  readonly mainTitle?: InputMaybe<ILocaleStringSorting>;
  readonly modalSubtitle?: InputMaybe<ILocaleStringSorting>;
  readonly modalTitle?: InputMaybe<ILocaleStringSorting>;
}

export interface ITextCard {
  readonly __typename?: 'TextCard';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly heading: Maybe<ILocaleString>;
  readonly superHeading: Maybe<ILocaleString>;
}

export interface ITextCardFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly heading?: InputMaybe<ILocaleStringFilter>;
  readonly superHeading?: InputMaybe<ILocaleStringFilter>;
}

export interface ITextCardSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly heading?: InputMaybe<ILocaleStringSorting>;
  readonly superHeading?: InputMaybe<ILocaleStringSorting>;
}

export interface ITextWidget {
  readonly __typename?: 'TextWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<ILocaleString>;
}

export interface ITextWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
}

export interface ITextWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
}

export interface ITextWidgetWithUrl {
  readonly __typename?: 'TextWidgetWithUrl';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<ILocaleString>;
  readonly textUrl: Maybe<ILocaleString>;
  readonly url: Maybe<ILocaleString>;
}

export interface ITextWidgetWithUrlFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly text?: InputMaybe<ILocaleStringFilter>;
  readonly textUrl?: InputMaybe<ILocaleStringFilter>;
  readonly url?: InputMaybe<ILocaleStringFilter>;
}

export interface ITextWidgetWithUrlSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly text?: InputMaybe<ILocaleStringSorting>;
  readonly textUrl?: InputMaybe<ILocaleStringSorting>;
  readonly url?: InputMaybe<ILocaleStringSorting>;
}

export interface ITierIncentiveList {
  readonly __typename?: 'TierIncentiveList';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Offer will be available for the selected tier. */
  readonly tierName: Maybe<Scalars['String']['output']>;
}

export interface ITierIncentiveListFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly tierName?: InputMaybe<IStringFilter>;
}

export interface ITierIncentiveListSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly tierName?: InputMaybe<SortOrder>;
}

export interface ITierList {
  readonly __typename?: 'TierList';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** The rule checks if the user reached a certain loyalty tier. */
  readonly tierList: Maybe<Scalars['String']['output']>;
}

export interface ITierListFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly tierList?: InputMaybe<IStringFilter>;
}

export interface ITierListSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly tierList?: InputMaybe<SortOrder>;
}

export interface ITimeRange {
  readonly __typename?: 'TimeRange';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly startTime: Maybe<Scalars['String']['output']>;
}

export interface ITimeRangeFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endTime?: InputMaybe<IStringFilter>;
  readonly startTime?: InputMaybe<IStringFilter>;
}

export interface ITimeRangeSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endTime?: InputMaybe<SortOrder>;
  readonly startTime?: InputMaybe<SortOrder>;
}

export interface ITitleAndBody {
  readonly __typename?: 'TitleAndBody';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly body: Maybe<ILocaleString>;
  readonly title: Maybe<ILocaleString>;
}

export interface ITitleAndBodyFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly body?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
}

export interface ITitleAndBodySorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly body?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
}

export interface ITokenExConfig {
  readonly __typename?: 'TokenExConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly publicKey: Maybe<Scalars['String']['output']>;
}

export interface ITokenExConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly publicKey?: InputMaybe<IStringFilter>;
}

export interface ITokenExConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly publicKey?: InputMaybe<SortOrder>;
}

export interface ITotalSpend {
  readonly __typename?: 'TotalSpend';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Minimum total spend in cents in the cart */
  readonly totalMinimumSpend: Maybe<Scalars['Float']['output']>;
}

export interface ITotalSpendFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly totalMinimumSpend?: InputMaybe<IFloatFilter>;
}

export interface ITotalSpendSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly totalMinimumSpend?: InputMaybe<SortOrder>;
}

export interface ITransactionCount {
  readonly __typename?: 'TransactionCount';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Every number of transactions */
  readonly everyNumberOfTransaction: Maybe<Scalars['Float']['output']>;
  /** transactions from */
  readonly from: Maybe<Scalars['Float']['output']>;
  /** When the transaction count rule will trigger */
  readonly schedule: Maybe<Scalars['String']['output']>;
  /** transactions up to */
  readonly to: Maybe<Scalars['Float']['output']>;
  /** Minimum of transactions required */
  readonly transactionNumber: Maybe<Scalars['Float']['output']>;
}

export interface ITransactionCountFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly everyNumberOfTransaction?: InputMaybe<IFloatFilter>;
  readonly from?: InputMaybe<IFloatFilter>;
  readonly schedule?: InputMaybe<IStringFilter>;
  readonly to?: InputMaybe<IFloatFilter>;
  readonly transactionNumber?: InputMaybe<IFloatFilter>;
}

export interface ITransactionCountSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly everyNumberOfTransaction?: InputMaybe<SortOrder>;
  readonly from?: InputMaybe<SortOrder>;
  readonly schedule?: InputMaybe<SortOrder>;
  readonly to?: InputMaybe<SortOrder>;
  readonly transactionNumber?: InputMaybe<SortOrder>;
}

export interface ITransactionEarningConfiguration {
  readonly __typename?: 'TransactionEarningConfiguration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** If this amount is reached in a year, then users can still place order but they will no longer earn loyalty points with orders. The year is calculated from Jan 1 - Dec 31 calendar days */
  readonly maxPointsLimitPerYear: Maybe<Scalars['Float']['output']>;
  /** The daily maximum number of transactions that would yield points for a given user */
  readonly maxTransactionLimit: Maybe<Scalars['Float']['output']>;
}

export interface ITransactionEarningConfigurationFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly maxPointsLimitPerYear?: InputMaybe<IFloatFilter>;
  readonly maxTransactionLimit?: InputMaybe<IFloatFilter>;
}

export interface ITransactionEarningConfigurationSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly maxPointsLimitPerYear?: InputMaybe<SortOrder>;
  readonly maxTransactionLimit?: InputMaybe<SortOrder>;
}

export interface ITrending extends IDocument {
  readonly __typename?: 'Trending';
  /** Date the document was created */
  readonly _createdAt: Scalars['DateTime']['output'];
  /** Document ID */
  readonly _id: Scalars['ID']['output'];
  readonly _key: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  readonly _rev: Scalars['String']['output'];
  /** Document type */
  readonly _type: Scalars['String']['output'];
  /** Date the document was last modified */
  readonly _updatedAt: Scalars['DateTime']['output'];
  readonly promotions: Maybe<
    ReadonlyArray<Maybe<IComboOrExternalLinkOrItemOrOfferOrPickerOrSection>>
  >;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
}

export interface ITrendingFilter {
  /** Apply filters on document level */
  readonly _?: InputMaybe<ISanityDocumentFilter>;
  readonly _createdAt?: InputMaybe<IDatetimeFilter>;
  readonly _id?: InputMaybe<IIdFilter>;
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _rev?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly _updatedAt?: InputMaybe<IDatetimeFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
}

export interface ITrendingSorting {
  readonly _createdAt?: InputMaybe<SortOrder>;
  readonly _id?: InputMaybe<SortOrder>;
  readonly _key?: InputMaybe<SortOrder>;
  readonly _rev?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly _updatedAt?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
}

export interface IUnlinkPaybackAccountModalContent {
  readonly __typename?: 'UnlinkPaybackAccountModalContent';
  /** @deprecated This is deprecated */
  readonly _key: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** @deprecated This is deprecated */
  readonly cancelButtonText: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly subtitle: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly title: Maybe<ILocaleString>;
  /** @deprecated This is deprecated */
  readonly unlinkAccountText: Maybe<ILocaleString>;
}

export interface IUnlinkPaybackAccountModalContentFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly cancelButtonText?: InputMaybe<ILocaleStringFilter>;
  readonly subtitle?: InputMaybe<ILocaleStringFilter>;
  readonly title?: InputMaybe<ILocaleStringFilter>;
  readonly unlinkAccountText?: InputMaybe<ILocaleStringFilter>;
}

export interface IUnlinkPaybackAccountModalContentSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly cancelButtonText?: InputMaybe<ILocaleStringSorting>;
  readonly subtitle?: InputMaybe<ILocaleStringSorting>;
  readonly title?: InputMaybe<ILocaleStringSorting>;
  readonly unlinkAccountText?: InputMaybe<ILocaleStringSorting>;
}

export interface IUrlsConfig {
  readonly __typename?: 'UrlsConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly braze: Maybe<Scalars['String']['output']>;
  readonly firstData: Maybe<Scalars['String']['output']>;
  readonly firstDataTh: Maybe<Scalars['String']['output']>;
  readonly kount: Maybe<Scalars['String']['output']>;
  readonly thDigital: Maybe<Scalars['String']['output']>;
  readonly tokenEx: Maybe<Scalars['String']['output']>;
  /** Main site URL without ending slash ("/"), eg "https://www.bk.com". Used for opening external links (Simplex SEO) */
  readonly webBaseUrl: Maybe<Scalars['String']['output']>;
}

export interface IUrlsConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly braze?: InputMaybe<IStringFilter>;
  readonly firstData?: InputMaybe<IStringFilter>;
  readonly firstDataTh?: InputMaybe<IStringFilter>;
  readonly kount?: InputMaybe<IStringFilter>;
  readonly thDigital?: InputMaybe<IStringFilter>;
  readonly tokenEx?: InputMaybe<IStringFilter>;
  readonly webBaseUrl?: InputMaybe<IStringFilter>;
}

export interface IUrlsConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly braze?: InputMaybe<SortOrder>;
  readonly firstData?: InputMaybe<SortOrder>;
  readonly firstDataTh?: InputMaybe<SortOrder>;
  readonly kount?: InputMaybe<SortOrder>;
  readonly thDigital?: InputMaybe<SortOrder>;
  readonly tokenEx?: InputMaybe<SortOrder>;
  readonly webBaseUrl?: InputMaybe<SortOrder>;
}

export interface IUserAttributes {
  readonly __typename?: 'UserAttributes';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly userAttributesItem: Maybe<ReadonlyArray<Maybe<IUserAttributesItem>>>;
}

export interface IUserAttributesFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IUserAttributesItem {
  readonly __typename?: 'UserAttributesItem';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Select the user attribute you would like to filter for */
  readonly attributeItem: Maybe<Scalars['String']['output']>;
  readonly useAttributeBatteryLevelFilter: Maybe<Scalars['String']['output']>;
  /** If this condition is fulfilled then the offer or reward will show in the app for that user */
  readonly useAttributeBatteryLevelValue: Maybe<Scalars['Float']['output']>;
  /** If the condition matches this value, the offer or reward will show in the app for that user. */
  readonly userAttributeBooleanValue: Maybe<Scalars['String']['output']>;
  readonly userAttributeStringFilter: Maybe<Scalars['String']['output']>;
  /** If this condition is fulfilled then the offer or reward will show in the app for that user */
  readonly userAttributeStringValue: Maybe<Scalars['String']['output']>;
}

export interface IUserAttributesItemFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly attributeItem?: InputMaybe<IStringFilter>;
  readonly useAttributeBatteryLevelFilter?: InputMaybe<IStringFilter>;
  readonly useAttributeBatteryLevelValue?: InputMaybe<IFloatFilter>;
  readonly userAttributeBooleanValue?: InputMaybe<IStringFilter>;
  readonly userAttributeStringFilter?: InputMaybe<IStringFilter>;
  readonly userAttributeStringValue?: InputMaybe<IStringFilter>;
}

export interface IUserAttributesItemSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly attributeItem?: InputMaybe<SortOrder>;
  readonly useAttributeBatteryLevelFilter?: InputMaybe<SortOrder>;
  readonly useAttributeBatteryLevelValue?: InputMaybe<SortOrder>;
  readonly userAttributeBooleanValue?: InputMaybe<SortOrder>;
  readonly userAttributeStringFilter?: InputMaybe<SortOrder>;
  readonly userAttributeStringValue?: InputMaybe<SortOrder>;
}

export interface IUserAttributesSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IUserCreated {
  readonly __typename?: 'UserCreated';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Must be created before/on... */
  readonly endDate: Maybe<Scalars['Date']['output']>;
  /** Must be created after/on... */
  readonly startDate: Maybe<Scalars['Date']['output']>;
}

export interface IUserCreatedFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly endDate?: InputMaybe<IDateFilter>;
  readonly startDate?: InputMaybe<IDateFilter>;
}

export interface IUserCreatedSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly endDate?: InputMaybe<SortOrder>;
  readonly startDate?: InputMaybe<SortOrder>;
}

export interface IValidationLoyaltyPoints {
  readonly __typename?: 'ValidationLoyaltyPoints';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly maxPoints: Maybe<Scalars['Float']['output']>;
  readonly minPoints: Maybe<Scalars['Float']['output']>;
}

export interface IValidationLoyaltyPointsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly maxPoints?: InputMaybe<IFloatFilter>;
  readonly minPoints?: InputMaybe<IFloatFilter>;
}

export interface IValidationLoyaltyPointsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly maxPoints?: InputMaybe<SortOrder>;
  readonly minPoints?: InputMaybe<SortOrder>;
}

export interface IVendorConfig {
  readonly __typename?: 'VendorConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Constant is for a single value PLU */
  readonly constantPlu: Maybe<Scalars['String']['output']>;
  /** Discount plu is an extension of the constant plu type for pos vendors that may use both depending on the entry point */
  readonly discountPlu: Maybe<Scalars['String']['output']>;
  /** @deprecated This has been deprecated */
  readonly multiConstantPlus: Maybe<ReadonlyArray<Maybe<IPlu>>>;
  /** This is a field relevant for NZ and will be used for offers that require discount amount, input amount in cents. If you are not NZ do not use it */
  readonly offerDiscount: Maybe<IOfferDiscount>;
  /** @deprecated This has been deprecated */
  readonly parentChildPlu: Maybe<IParentChildPlu>;
  /** If provided this vendorConfig will be moved under the cartEntry with Sanity ID equal to parentSanityId for injection. */
  readonly parentSanityId: Maybe<Scalars['String']['output']>;
  /** This indicates the type of PLU the Vendor needs. Ignore means that this is just for UI and not used by POS. See below for other types. */
  readonly pluType: Maybe<Scalars['String']['output']>;
  /** This field indicates how many levels up the PLU belongs. */
  readonly pullUpLevels: Maybe<Scalars['Float']['output']>;
  /** @deprecated This has been deprecated */
  readonly quantityBasedPlu: Maybe<ReadonlyArray<Maybe<IPlu>>>;
  readonly sizeBasedPlu: Maybe<ISizeBasedPlu>;
}

export interface IVendorConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly constantPlu?: InputMaybe<IStringFilter>;
  readonly discountPlu?: InputMaybe<IStringFilter>;
  readonly offerDiscount?: InputMaybe<IOfferDiscountFilter>;
  readonly parentChildPlu?: InputMaybe<IParentChildPluFilter>;
  readonly parentSanityId?: InputMaybe<IStringFilter>;
  readonly pluType?: InputMaybe<IStringFilter>;
  readonly pullUpLevels?: InputMaybe<IFloatFilter>;
  readonly sizeBasedPlu?: InputMaybe<ISizeBasedPluFilter>;
}

export interface IVendorConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly constantPlu?: InputMaybe<SortOrder>;
  readonly discountPlu?: InputMaybe<SortOrder>;
  readonly parentChildPlu?: InputMaybe<IParentChildPluSorting>;
  readonly parentSanityId?: InputMaybe<SortOrder>;
  readonly pluType?: InputMaybe<SortOrder>;
  readonly pullUpLevels?: InputMaybe<SortOrder>;
  readonly sizeBasedPlu?: InputMaybe<ISizeBasedPluSorting>;
}

export interface IVendorConfigs {
  readonly __typename?: 'VendorConfigs';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly brink: Maybe<IVendorConfig>;
  readonly brinkDelivery: Maybe<IVendorConfig>;
  readonly carrols: Maybe<IVendorConfig>;
  readonly carrolsDelivery: Maybe<IVendorConfig>;
  readonly hdx: Maybe<IVendorConfig>;
  readonly ncr: Maybe<IVendorConfig>;
  readonly ncrDelivery: Maybe<IVendorConfig>;
  readonly oheics: Maybe<IVendorConfig>;
  readonly oheicsDelivery: Maybe<IVendorConfig>;
  readonly partner: Maybe<IVendorConfig>;
  readonly partnerDelivery: Maybe<IVendorConfig>;
  readonly productNumber: Maybe<IVendorConfig>;
  readonly productNumberDelivery: Maybe<IVendorConfig>;
  readonly qdi: Maybe<IVendorConfig>;
  readonly qdiDelivery: Maybe<IVendorConfig>;
  readonly qst: Maybe<IVendorConfig>;
  readonly qstDelivery: Maybe<IVendorConfig>;
  readonly rpos: Maybe<IVendorConfig>;
  readonly rposDelivery: Maybe<IVendorConfig>;
  readonly sicom: Maybe<IVendorConfig>;
  readonly sicomDelivery: Maybe<IVendorConfig>;
  readonly simplyDelivery: Maybe<IVendorConfig>;
  readonly simplyDeliveryDelivery: Maybe<IVendorConfig>;
  readonly tablet: Maybe<IVendorConfig>;
  readonly tabletDelivery: Maybe<IVendorConfig>;
  readonly toshibaLoyalty: Maybe<IVendorConfig>;
  readonly xenial: Maybe<IVendorConfig>;
  readonly xenialDelivery: Maybe<IVendorConfig>;
}

export interface IVendorConfigsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly brink?: InputMaybe<IVendorConfigFilter>;
  readonly brinkDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly carrols?: InputMaybe<IVendorConfigFilter>;
  readonly carrolsDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly hdx?: InputMaybe<IVendorConfigFilter>;
  readonly ncr?: InputMaybe<IVendorConfigFilter>;
  readonly ncrDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly oheics?: InputMaybe<IVendorConfigFilter>;
  readonly oheicsDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly partner?: InputMaybe<IVendorConfigFilter>;
  readonly partnerDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly productNumber?: InputMaybe<IVendorConfigFilter>;
  readonly productNumberDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly qdi?: InputMaybe<IVendorConfigFilter>;
  readonly qdiDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly qst?: InputMaybe<IVendorConfigFilter>;
  readonly qstDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly rpos?: InputMaybe<IVendorConfigFilter>;
  readonly rposDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly sicom?: InputMaybe<IVendorConfigFilter>;
  readonly sicomDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly simplyDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly simplyDeliveryDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly tablet?: InputMaybe<IVendorConfigFilter>;
  readonly tabletDelivery?: InputMaybe<IVendorConfigFilter>;
  readonly toshibaLoyalty?: InputMaybe<IVendorConfigFilter>;
  readonly xenial?: InputMaybe<IVendorConfigFilter>;
  readonly xenialDelivery?: InputMaybe<IVendorConfigFilter>;
}

export interface IVendorConfigsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly brink?: InputMaybe<IVendorConfigSorting>;
  readonly brinkDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly carrols?: InputMaybe<IVendorConfigSorting>;
  readonly carrolsDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly hdx?: InputMaybe<IVendorConfigSorting>;
  readonly ncr?: InputMaybe<IVendorConfigSorting>;
  readonly ncrDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly oheics?: InputMaybe<IVendorConfigSorting>;
  readonly oheicsDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly partner?: InputMaybe<IVendorConfigSorting>;
  readonly partnerDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly productNumber?: InputMaybe<IVendorConfigSorting>;
  readonly productNumberDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly qdi?: InputMaybe<IVendorConfigSorting>;
  readonly qdiDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly qst?: InputMaybe<IVendorConfigSorting>;
  readonly qstDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly rpos?: InputMaybe<IVendorConfigSorting>;
  readonly rposDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly sicom?: InputMaybe<IVendorConfigSorting>;
  readonly sicomDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly simplyDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly simplyDeliveryDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly tablet?: InputMaybe<IVendorConfigSorting>;
  readonly tabletDelivery?: InputMaybe<IVendorConfigSorting>;
  readonly toshibaLoyalty?: InputMaybe<IVendorConfigSorting>;
  readonly xenial?: InputMaybe<IVendorConfigSorting>;
  readonly xenialDelivery?: InputMaybe<IVendorConfigSorting>;
}

export interface IVendorPlus {
  readonly __typename?: 'VendorPlus';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly carrols: Maybe<Scalars['String']['output']>;
  readonly carrolsDelivery: Maybe<Scalars['String']['output']>;
  readonly ncr: Maybe<Scalars['String']['output']>;
  readonly ncrDelivery: Maybe<Scalars['String']['output']>;
  readonly oheics: Maybe<Scalars['String']['output']>;
  readonly oheicsDelivery: Maybe<Scalars['String']['output']>;
  readonly partner: Maybe<Scalars['String']['output']>;
  readonly partnerDelivery: Maybe<Scalars['String']['output']>;
  /** TH Product Number used for Connector */
  readonly productNumber: Maybe<Scalars['String']['output']>;
  /** TH Product Number Delivery used for Connector */
  readonly productNumberDelivery: Maybe<Scalars['String']['output']>;
  readonly qst: Maybe<Scalars['String']['output']>;
  readonly sicom: Maybe<Scalars['String']['output']>;
  readonly sicomDelivery: Maybe<Scalars['String']['output']>;
}

export interface IVendorPlusFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly carrols?: InputMaybe<IStringFilter>;
  readonly carrolsDelivery?: InputMaybe<IStringFilter>;
  readonly ncr?: InputMaybe<IStringFilter>;
  readonly ncrDelivery?: InputMaybe<IStringFilter>;
  readonly oheics?: InputMaybe<IStringFilter>;
  readonly oheicsDelivery?: InputMaybe<IStringFilter>;
  readonly partner?: InputMaybe<IStringFilter>;
  readonly partnerDelivery?: InputMaybe<IStringFilter>;
  readonly productNumber?: InputMaybe<IStringFilter>;
  readonly productNumberDelivery?: InputMaybe<IStringFilter>;
  readonly qst?: InputMaybe<IStringFilter>;
  readonly sicom?: InputMaybe<IStringFilter>;
  readonly sicomDelivery?: InputMaybe<IStringFilter>;
}

export interface IVendorPlusSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly carrols?: InputMaybe<SortOrder>;
  readonly carrolsDelivery?: InputMaybe<SortOrder>;
  readonly ncr?: InputMaybe<SortOrder>;
  readonly ncrDelivery?: InputMaybe<SortOrder>;
  readonly oheics?: InputMaybe<SortOrder>;
  readonly oheicsDelivery?: InputMaybe<SortOrder>;
  readonly partner?: InputMaybe<SortOrder>;
  readonly partnerDelivery?: InputMaybe<SortOrder>;
  readonly productNumber?: InputMaybe<SortOrder>;
  readonly productNumberDelivery?: InputMaybe<SortOrder>;
  readonly qst?: InputMaybe<SortOrder>;
  readonly sicom?: InputMaybe<SortOrder>;
  readonly sicomDelivery?: InputMaybe<SortOrder>;
}

export interface IVerticalLinkListWidget {
  readonly __typename?: 'VerticalLinkListWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly linkWidgets: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
}

export interface IVerticalLinkListWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
}

export interface IVerticalLinkListWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
}

export interface IVerticalLinkListWithHeaderWidget {
  readonly __typename?: 'VerticalLinkListWithHeaderWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly header: Maybe<ILocaleString>;
  readonly linkWidgets: Maybe<ReadonlyArray<Maybe<ILinkWidget>>>;
}

export interface IVerticalLinkListWithHeaderWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly header?: InputMaybe<ILocaleStringFilter>;
}

export interface IVerticalLinkListWithHeaderWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly header?: InputMaybe<ILocaleStringSorting>;
}

export interface IVideoWidget {
  readonly __typename?: 'VideoWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly attributionLink: Maybe<Scalars['String']['output']>;
  readonly caption: Maybe<ILocaleString>;
  readonly video: Maybe<ILocaleFile>;
}

export interface IVideoWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly attributionLink?: InputMaybe<IStringFilter>;
  readonly caption?: InputMaybe<ILocaleStringFilter>;
  readonly video?: InputMaybe<ILocaleFileFilter>;
}

export interface IVideoWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly attributionLink?: InputMaybe<SortOrder>;
  readonly caption?: InputMaybe<ILocaleStringSorting>;
  readonly video?: InputMaybe<ILocaleFileSorting>;
}

export interface IVrPaymentConfig {
  readonly __typename?: 'VrPaymentConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly braintreeTokenizationKey: Maybe<Scalars['String']['output']>;
}

export interface IVrPaymentConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly braintreeTokenizationKey?: InputMaybe<IStringFilter>;
}

export interface IVrPaymentConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly braintreeTokenizationKey?: InputMaybe<SortOrder>;
}

export interface IWaitTimeParameters {
  readonly __typename?: 'WaitTimeParameters';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The estimated time one order takes (in seconds). */
  readonly kitchenCapacity: Maybe<Scalars['Float']['output']>;
  /**
   *
   *         Every time the Wait Time Service attempts to process a new order, the order is either
   *         committed directly to the POS (adding to the Time to Clear Kitchen) or queued.
   *         If the current Time to Clear Kitchen + Processing Time of the new order is above the limit set here,
   *         then the service starts queueing up the order.
   *
   */
  readonly kitchenLimit: Maybe<Scalars['Float']['output']>;
}

export interface IWaitTimeParametersFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly isEnabled?: InputMaybe<IBooleanFilter>;
  readonly kitchenCapacity?: InputMaybe<IFloatFilter>;
  readonly kitchenLimit?: InputMaybe<IFloatFilter>;
}

export interface IWaitTimeParametersSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly isEnabled?: InputMaybe<SortOrder>;
  readonly kitchenCapacity?: InputMaybe<SortOrder>;
  readonly kitchenLimit?: InputMaybe<SortOrder>;
}

export interface IWebViewWidget {
  readonly __typename?: 'WebViewWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** This field is required only for iframes that required interaction */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<ILocaleString>;
}

export interface IWebViewWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly name?: InputMaybe<IStringFilter>;
  readonly url?: InputMaybe<ILocaleStringFilter>;
}

export interface IWebViewWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly name?: InputMaybe<SortOrder>;
  readonly url?: InputMaybe<ILocaleStringSorting>;
}

export interface IWeekDays {
  readonly __typename?: 'WeekDays';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly friday: Maybe<Scalars['Boolean']['output']>;
  readonly monday: Maybe<Scalars['Boolean']['output']>;
  readonly saturday: Maybe<Scalars['Boolean']['output']>;
  readonly sunday: Maybe<Scalars['Boolean']['output']>;
  readonly thursday: Maybe<Scalars['Boolean']['output']>;
  readonly tuesday: Maybe<Scalars['Boolean']['output']>;
  readonly wednesday: Maybe<Scalars['Boolean']['output']>;
}

export interface IWeekDaysFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly friday?: InputMaybe<IBooleanFilter>;
  readonly monday?: InputMaybe<IBooleanFilter>;
  readonly saturday?: InputMaybe<IBooleanFilter>;
  readonly sunday?: InputMaybe<IBooleanFilter>;
  readonly thursday?: InputMaybe<IBooleanFilter>;
  readonly tuesday?: InputMaybe<IBooleanFilter>;
  readonly wednesday?: InputMaybe<IBooleanFilter>;
}

export interface IWeekDaysSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly friday?: InputMaybe<SortOrder>;
  readonly monday?: InputMaybe<SortOrder>;
  readonly saturday?: InputMaybe<SortOrder>;
  readonly sunday?: InputMaybe<SortOrder>;
  readonly thursday?: InputMaybe<SortOrder>;
  readonly tuesday?: InputMaybe<SortOrder>;
  readonly wednesday?: InputMaybe<SortOrder>;
}

export interface IWordmarkAndSocialIcons {
  readonly __typename?: 'WordmarkAndSocialIcons';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly socialIconsWidget: Maybe<ISocialIconsWidget>;
}

export interface IWordmarkAndSocialIconsFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly socialIconsWidget?: InputMaybe<ISocialIconsWidgetFilter>;
}

export interface IWordmarkAndSocialIconsSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly socialIconsWidget?: InputMaybe<ISocialIconsWidgetSorting>;
}

export interface IWorldpayConfig {
  readonly __typename?: 'WorldpayConfig';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly apiMerchantIdentifier: Maybe<Scalars['String']['output']>;
  readonly baseUrl: Maybe<Scalars['String']['output']>;
  readonly eProtectUrl: Maybe<Scalars['String']['output']>;
  readonly paypageId: Maybe<Scalars['String']['output']>;
  readonly profileId: Maybe<Scalars['String']['output']>;
  readonly sharedKey: Maybe<Scalars['String']['output']>;
}

export interface IWorldpayConfigFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly apiMerchantIdentifier?: InputMaybe<IStringFilter>;
  readonly baseUrl?: InputMaybe<IStringFilter>;
  readonly eProtectUrl?: InputMaybe<IStringFilter>;
  readonly paypageId?: InputMaybe<IStringFilter>;
  readonly profileId?: InputMaybe<IStringFilter>;
  readonly sharedKey?: InputMaybe<IStringFilter>;
}

export interface IWorldpayConfigSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly apiMerchantIdentifier?: InputMaybe<SortOrder>;
  readonly baseUrl?: InputMaybe<SortOrder>;
  readonly eProtectUrl?: InputMaybe<SortOrder>;
  readonly paypageId?: InputMaybe<SortOrder>;
  readonly profileId?: InputMaybe<SortOrder>;
  readonly sharedKey?: InputMaybe<SortOrder>;
}

export interface IYoutubeWidget {
  readonly __typename?: 'YoutubeWidget';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Used only as an organizational aid. Not displayed in the app. */
  readonly sanityInternalName: Maybe<Scalars['String']['output']>;
  readonly videoAutoplay: Maybe<Scalars['Boolean']['output']>;
  readonly videoId: Maybe<Scalars['String']['output']>;
}

export interface IYoutubeWidgetFilter {
  readonly _key?: InputMaybe<IStringFilter>;
  readonly _type?: InputMaybe<IStringFilter>;
  readonly sanityInternalName?: InputMaybe<IStringFilter>;
  readonly videoAutoplay?: InputMaybe<IBooleanFilter>;
  readonly videoId?: InputMaybe<IStringFilter>;
}

export interface IYoutubeWidgetSorting {
  readonly _key?: InputMaybe<SortOrder>;
  readonly _type?: InputMaybe<SortOrder>;
  readonly sanityInternalName?: InputMaybe<SortOrder>;
  readonly videoAutoplay?: InputMaybe<SortOrder>;
  readonly videoId?: InputMaybe<SortOrder>;
}

export type IAllFeatureIdsQueryVariables = Exact<{ [key: string]: never }>;

export type IAllFeatureIdsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allFeatureAccountForm: ReadonlyArray<{
    readonly __typename?: 'FeatureAccountForm';
    readonly _id: string;
  }>;
  readonly allFeatureGeolocationMapMarkers: ReadonlyArray<{
    readonly __typename?: 'FeatureGeolocationMapMarkers';
    readonly _id: string;
  }>;
  readonly allFeatureGeolocationModal: ReadonlyArray<{
    readonly __typename?: 'FeatureGeolocationModal';
    readonly _id: string;
  }>;
  readonly allFeatureHomePage: ReadonlyArray<{
    readonly __typename?: 'FeatureHomePage';
    readonly _id: string;
  }>;
  readonly allFeatureOffers: ReadonlyArray<{
    readonly __typename?: 'FeatureOffers';
    readonly _id: string;
  }>;
  readonly allFeatureFooter: ReadonlyArray<{
    readonly __typename?: 'FeatureFooter';
    readonly _id: string;
  }>;
  readonly allFeatureNavigation: ReadonlyArray<{
    readonly __typename?: 'FeatureNavigation';
    readonly _id: string;
  }>;
  readonly allFeatureAccountDeletePage: ReadonlyArray<{
    readonly __typename?: 'FeatureAccountDeletePage';
    readonly _id: string;
  }>;
  readonly allFeatureAccountRequestInfoPage: ReadonlyArray<{
    readonly __typename?: 'FeatureAccountRequestInfoPage';
    readonly _id: string;
  }>;
  readonly allSupportData: ReadonlyArray<{
    readonly __typename?: 'SupportData';
    readonly _id: string;
  }>;
  readonly allFeatureStaticMenu: ReadonlyArray<{
    readonly __typename?: 'FeatureStaticMenu';
    readonly _id: string;
  }>;
  readonly allFeatureMenu: ReadonlyArray<{
    readonly __typename?: 'FeatureMenu';
    readonly _id: string;
  }>;
  readonly allFeatureLayout: ReadonlyArray<{
    readonly __typename?: 'FeatureLayout';
    readonly _id: string;
  }>;
  readonly allFeatureDisclaimers: ReadonlyArray<{
    readonly __typename?: 'FeatureDisclaimers';
    readonly _id: string;
  }>;
  readonly allFeatureExternalLinkSection: ReadonlyArray<{
    readonly __typename?: 'FeatureExternalLinkSection';
    readonly _id: string;
  }>;
  readonly allFeatureAccount: ReadonlyArray<{
    readonly __typename?: 'FeatureAccount';
    readonly _id: string;
  }>;
  readonly allFeatureCateringPage: ReadonlyArray<{
    readonly __typename?: 'FeatureCateringPage';
    readonly _id: string;
  }>;
  readonly allLoyaltyUI: ReadonlyArray<{ readonly __typename?: 'LoyaltyUI'; readonly _id: string }>;
  readonly allFeatureNutrition: ReadonlyArray<{
    readonly __typename?: 'FeatureNutrition';
    readonly _id: string;
  }>;
  readonly allRewardList: ReadonlyArray<{
    readonly __typename?: 'RewardList';
    readonly _id: string;
  }>;
  readonly allClaimPointsUI: ReadonlyArray<{
    readonly __typename?: 'ClaimPointsUI';
    readonly _id: string;
  }>;
  readonly allFeatureOnboarding: ReadonlyArray<{
    readonly __typename?: 'FeatureOnboarding';
    readonly _id: string;
  }>;
  readonly allFeatureLockers: ReadonlyArray<{
    readonly __typename?: 'FeatureLockers';
    readonly _id: string;
  }>;
  readonly allFeatureBeeperModal: ReadonlyArray<{
    readonly __typename?: 'FeatureBeeperModal';
    readonly _id: string;
  }>;
  readonly allLinkPhysicalCardUi: ReadonlyArray<{
    readonly __typename?: 'LinkPhysicalCardUi';
    readonly _id: string;
  }>;
  readonly allLiveMemberships: ReadonlyArray<{
    readonly __typename?: 'LiveMemberships';
    readonly _id: string;
  }>;
  readonly allLoyaltyLiveExternalOffers: ReadonlyArray<{
    readonly __typename?: 'LoyaltyLiveExternalOffers';
    readonly _id: string;
  }>;
  readonly allFeatureInvitationCode: ReadonlyArray<{
    readonly __typename?: 'FeatureInvitationCode';
    readonly _id: string;
  }>;
  readonly allFeatureAccountUpdatedAgreements: ReadonlyArray<{
    readonly __typename?: 'FeatureAccountUpdatedAgreements';
    readonly _id: string;
  }>;
  readonly allFeatureDonation: ReadonlyArray<{
    readonly __typename?: 'FeatureDonation';
    readonly _id: string;
  }>;
  readonly allFeatureIFrame: ReadonlyArray<{
    readonly __typename?: 'FeatureIFrame';
    readonly _id: string;
  }>;
  readonly allFeatureQRCodeRefillDrinks: ReadonlyArray<{
    readonly __typename?: 'FeatureQRCodeRefillDrinks';
    readonly _id: string;
  }>;
  readonly allFeatureAggregatorRedirectButtons: ReadonlyArray<{
    readonly __typename?: 'FeatureAggregatorRedirectButtons';
    readonly _id: string;
  }>;
  readonly allFeatureGeolocationTermsModal: ReadonlyArray<{
    readonly __typename?: 'FeatureGeolocationTermsModal';
    readonly _id: string;
  }>;
  readonly allConfigRedeemInRestaurant: ReadonlyArray<{
    readonly __typename?: 'ConfigRedeemInRestaurant';
    readonly _id: string;
  }>;
  readonly allBirthdayAndCatering: ReadonlyArray<{
    readonly __typename?: 'BirthdayAndCatering';
    readonly _id: string;
  }>;
  readonly allFeatureFeesAndTaxes: ReadonlyArray<{
    readonly __typename?: 'FeatureFeesAndTaxes';
    readonly _id: string;
  }>;
  readonly allFeatureEventReservations: ReadonlyArray<{
    readonly __typename?: 'FeatureEventReservations';
    readonly _id: string;
  }>;
};

export type IAllItemsQueryVariables = Exact<{
  where?: InputMaybe<IItemFilter>;
}>;

export type IAllItemsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allItem: ReadonlyArray<{
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly itemSize: number | null;
    readonly itemUnit: string | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  }>;
};

export type IGetComboQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetComboQuery = {
  readonly __typename?: 'RootQuery';
  readonly Combo: {
    readonly __typename?: 'Combo';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly hideMainItemDescription: boolean | null;
    readonly forceModifiersToBottom: boolean | null;
    readonly isOfferBenefit: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly mainItem: {
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null;
    readonly markerItem: {
      readonly __typename?: 'MarkerItem';
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlot';
      readonly _type: string;
      readonly _id: string;
      readonly uiPattern: string | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly respectMaximum: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly optionVisibilitySettings: {
        readonly __typename?: 'OptionVisibilitySettings';
        readonly visibleOptions: number | null;
        readonly toggleButtonTextClosed: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly toggleButtonTextOpen: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ComboSlotOption';
        readonly _key: string | null;
        readonly minAmount: number | null;
        readonly defaultAmount: number | null;
        readonly isPremium: boolean | null;
        readonly option:
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            }
          | {
              readonly __typename?: 'Picker';
              readonly _type: string;
              readonly uiPattern: string | null;
              readonly pickerAspects: ReadonlyArray<{
                readonly __typename?: 'PickerAspect';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly pickerAspectOptions: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectValue';
                  readonly identifier: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null> | null;
              } | null> | null;
            }
          | { readonly __typename?: 'Section' }
          | null;
      } | null> | null;
    } | null> | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
  } | null;
};

export type IGetComboWithVendorConfigQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetComboWithVendorConfigQuery = {
  readonly __typename?: 'RootQuery';
  readonly Combo: {
    readonly __typename?: 'Combo';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly hideMainItemDescription: boolean | null;
    readonly forceModifiersToBottom: boolean | null;
    readonly isOfferBenefit: boolean | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly mainItem: {
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null;
    readonly markerItem: {
      readonly __typename?: 'MarkerItem';
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlot';
      readonly _type: string;
      readonly _id: string;
      readonly uiPattern: string | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly respectMaximum: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly optionVisibilitySettings: {
        readonly __typename?: 'OptionVisibilitySettings';
        readonly visibleOptions: number | null;
        readonly toggleButtonTextClosed: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly toggleButtonTextOpen: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ComboSlotOption';
        readonly _key: string | null;
        readonly minAmount: number | null;
        readonly defaultAmount: number | null;
        readonly isPremium: boolean | null;
        readonly option:
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            }
          | {
              readonly __typename?: 'Picker';
              readonly _type: string;
              readonly uiPattern: string | null;
              readonly pickerAspects: ReadonlyArray<{
                readonly __typename?: 'PickerAspect';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly pickerAspectOptions: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectValue';
                  readonly identifier: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null> | null;
              } | null> | null;
            }
          | { readonly __typename?: 'Section' }
          | null;
      } | null> | null;
    } | null> | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
  } | null;
};

export type IGetComboAvailabilityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetComboAvailabilityQuery = {
  readonly __typename?: 'RootQuery';
  readonly Combo: {
    readonly __typename?: 'Combo';
    readonly showInStaticMenu: boolean | null;
    readonly mainItem: {
      readonly __typename?: 'Item';
      readonly showInStaticMenu: boolean | null;
      readonly hideCalories: boolean | null;
      readonly hideNutritionLegalDisclaimer: boolean | null;
      readonly itemSize: number | null;
      readonly itemUnit: string | null;
      readonly operationalItem: {
        readonly __typename?: 'OpItem';
        readonly daypart: ReadonlyArray<string | null> | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly injectDefaultSelection: boolean | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly default: boolean | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlot';
      readonly minAmount: number | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ComboSlotOption';
        readonly maxAmount: number | null;
        readonly option:
          | {
              readonly __typename?: 'Item';
              readonly showInStaticMenu: boolean | null;
              readonly hideCalories: boolean | null;
              readonly hideNutritionLegalDisclaimer: boolean | null;
              readonly itemSize: number | null;
              readonly itemUnit: string | null;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly injectDefaultSelection: boolean | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null> | null;
            }
          | { readonly __typename?: 'Picker' }
          | { readonly __typename?: 'Section' }
          | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type IFeatureAccountDeletePageQueryVariables = Exact<{
  featureAccountDeletePageId: Scalars['ID']['input'];
}>;

export type IFeatureAccountDeletePageQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAccountDeletePage: {
    readonly __typename?: 'FeatureAccountDeletePage';
    readonly oneTrustDeleteUrl: string | null;
    readonly headerText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly content: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly confirmButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly cancelButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly errorText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IFeatureAccountFormQueryVariables = Exact<{
  featureAccountFormId: Scalars['ID']['input'];
}>;

export type IFeatureAccountFormQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAccountForm: {
    readonly __typename?: 'FeatureAccountForm';
    readonly minimumSignUpAge: number | null;
    readonly postalOrZipDisclaimer: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly desktopImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly activeCampaigns: ReadonlyArray<{
      readonly __typename?: 'FeatureAuthCampaign';
      readonly campaignId: string | null;
      readonly shouldDisplayExtraFields: boolean | null;
      readonly campaignImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly campaignImageAltText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly campaingBackgroundColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly campaignText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly signUpTerms: {
      readonly __typename?: 'SignUpTerms';
      readonly signUpTermsCells: ReadonlyArray<{
        readonly __typename?: 'SignUpTermsCell';
        readonly signUpTermsCellIsRequired: boolean | null;
        readonly signUpTermsCellDisplayedText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly signUpTermsCellUrl: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly privacyPolicyDisclaimers: {
      readonly __typename?: 'PrivacyPolicyDisclaimers';
      readonly privacyPolicyDisclaimersCells: ReadonlyArray<{
        readonly __typename?: 'PrivacyPolicyDisclaimersCell';
        readonly disclaimerTitle: string | null;
        readonly disclaimerDescription: string | null;
        readonly border: boolean | null;
        readonly fontSize: number | null;
        readonly paddingTop: number | null;
        readonly paddingRight: number | null;
        readonly paddingBottom: number | null;
        readonly paddingLeft: number | null;
        readonly radius: number | null;
        readonly disclaimerContent: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IFeatureAccountRequestInfoPageQueryVariables = Exact<{
  featureAccountRequestInfoPageId: Scalars['ID']['input'];
}>;

export type IFeatureAccountRequestInfoPageQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAccountRequestInfoPage: {
    readonly __typename?: 'FeatureAccountRequestInfoPage';
    readonly headerText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly content: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly confirmButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly errorText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IFeatureAccountUpdatedAgreementsQueryVariables = Exact<{
  featureAccountUpdatedAgreementsId: Scalars['ID']['input'];
}>;

export type IFeatureAccountUpdatedAgreementsQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAccountUpdatedAgreements: {
    readonly __typename?: 'FeatureAccountUpdatedAgreements';
    readonly updateAgreementText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly marketingCommunicationText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
  } | null;
};

export type IFeatureAccountQueryVariables = Exact<{
  featureAccountId: Scalars['ID']['input'];
}>;

export type IFeatureAccountQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAccount: {
    readonly __typename?: 'FeatureAccount';
    readonly _id: string;
    readonly emailAndPushOptInSettings: ReadonlyArray<
      | {
          readonly __typename?: 'OptInPrivacyPreferences';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly key: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly view: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'OptInSettingBoolean' }
      | {
          readonly __typename?: 'OptInSettingEmail';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly emailKey: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'OptInSettingEmailAndPush';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly pushKey: string | null;
          readonly emailKey: string | null;
          readonly singleTickBox: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'OptInSettingEnum' }
      | {
          readonly __typename?: 'OptInSettingPush';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly pushKey: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'OptInSettingSms';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly smsKey: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type IFeatureAggregatorRedirectButtonsQueryVariables = Exact<{
  featureAggregatorRedirectButtonsID: Scalars['ID']['input'];
}>;

export type IFeatureAggregatorRedirectButtonsQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureAggregatorRedirectButtons: {
    readonly __typename?: 'FeatureAggregatorRedirectButtons';
    readonly _id: string;
    readonly aggregatorRedirectButtons: ReadonlyArray<{
      readonly __typename?: 'AggregatorRedirectButton';
      readonly isActive: boolean | null;
      readonly name: string | null;
      readonly url: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureBeeperQueryVariables = Exact<{
  featureBeeperId: Scalars['ID']['input'];
}>;

export type IFeatureBeeperQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureBeeperModal: {
    readonly __typename?: 'FeatureBeeperModal';
    readonly beeperModalTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly beeperModalImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly beeperModalBody: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly beeperButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IFeatureBirthdayCateringQueryVariables = Exact<{
  featureBirthdayCateringConfigId: Scalars['ID']['input'];
}>;

export type IFeatureBirthdayCateringQuery = {
  readonly __typename?: 'RootQuery';
  readonly BirthdayAndCatering: {
    readonly __typename?: 'BirthdayAndCatering';
    readonly maximumDaysBetweenRequestAndEvent: number | null;
    readonly minimumKids: number | null;
    readonly advancedBooking: {
      readonly __typename?: 'AdvancedBookingConfig';
      readonly catering: number | null;
      readonly birthday: number | null;
    } | null;
    readonly availableHours: ReadonlyArray<{
      readonly __typename?: 'TimeRange';
      readonly startTime: string | null;
      readonly endTime: string | null;
    } | null> | null;
    readonly privacyPolicyDisclaimers: {
      readonly __typename?: 'PrivacyPolicyDisclaimers';
      readonly privacyPolicyDisclaimersCells: ReadonlyArray<{
        readonly __typename?: 'PrivacyPolicyDisclaimersCell';
        readonly disclaimerTitle: string | null;
        readonly disclaimerDescription: string | null;
        readonly border: boolean | null;
        readonly fontSize: number | null;
        readonly paddingTop: number | null;
        readonly paddingRight: number | null;
        readonly paddingBottom: number | null;
        readonly paddingLeft: number | null;
        readonly radius: number | null;
        readonly disclaimerContent: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IFeatureCateringPageQueryVariables = Exact<{
  featureCateringPageId: Scalars['ID']['input'];
}>;

export type IFeatureCateringPageQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureCateringPage: {
    readonly __typename?: 'FeatureCateringPage';
    readonly appUnauthenticated: {
      readonly __typename?: 'CateringPageConfiguration';
      readonly components: ReadonlyArray<
        | {
            readonly __typename?: 'Alert';
            readonly _id: string;
            readonly heading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly body: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'AppDownload';
            readonly _id: string;
            readonly details: {
              readonly __typename?: 'AppDownloadSection';
              readonly iOSDownloadLink: string | null;
              readonly androidDownloadLink: string | null;
              readonly huaweiDownloadLink: string | null;
              readonly backgroundImage: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly primaryImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly headerSuperText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly copy: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly textDecorationLeft: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly textDecorationRight: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly submitButtonText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly iOSDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly androidDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly huaweiDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'BrazeContentCards';
            readonly _id: string;
            readonly contentCardsHeader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly contentCardsSubheader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'FanFavorites';
            readonly _id: string;
            readonly fanFavoriteItems: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer' }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
            readonly favoriteSection: {
              readonly __typename?: 'FavoriteSection';
              readonly headerText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'LockedOffers'; readonly _id: string }
        | {
            readonly __typename?: 'MainHero';
            readonly _id: string;
            readonly textColor: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mobileImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly superText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly mainText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly action: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRight: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRightIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly actionLeft: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionLeftIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly maskCards: ReadonlyArray<{
              readonly __typename?: 'SimpleCard';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly loyaltyAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'MarketingTileGroup';
            readonly _id: string;
            readonly tiles: ReadonlyArray<
              | {
                  readonly __typename?: 'MarketingTile';
                  readonly _id: string;
                  readonly textAlignment: string | null;
                  readonly buttonAlignment: string | null;
                  readonly textColor: string | null;
                  readonly lockedAspectRatio: boolean | null;
                  readonly backgroundImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly buttonAction: {
                    readonly __typename?: 'LinkAction';
                    readonly route: string | null;
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly textBlock: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsText: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsButton: {
                    readonly __typename?: 'LinkAction';
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly ruleSet: {
                    readonly __typename?: 'MarketingTileRuleSet';
                    readonly BKPayAudience: string | null;
                    readonly bkSpecificTargeting: boolean | null;
                    readonly dayOfWeek: {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                    } | null;
                    readonly betweenTimes: {
                      readonly __typename?: 'BetweenTimes';
                      readonly startTime: string | null;
                      readonly endTime: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileBasic';
                  readonly _id: string;
                  readonly marketingBasicTileDescription: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly marketingBasicTileImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly marketingBasicTileTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                  readonly linkLabel: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'MarketingTileList';
                  readonly _type: string | null;
                  readonly tiles: ReadonlyArray<{
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'MarketingTilePair';
                  readonly left: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly right: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileQuad';
                  readonly topLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly topRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          }
        | { readonly __typename?: 'OffersSection'; readonly _id: string }
        | {
            readonly __typename?: 'OurServices';
            readonly _id: string;
            readonly servicesHeaderTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly servicesHeroTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly ourServicesHeroImageLocale: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'RecentItems'; readonly _id: string }
        | {
            readonly __typename?: 'Trending';
            readonly _id: string;
            readonly promotions: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'ExternalLink';
                  readonly _key: string | null;
                  readonly url: string | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'LocaleImage';
                    readonly locale: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | null
      > | null;
    } | null;
    readonly appAuthenticated: {
      readonly __typename?: 'CateringPageConfiguration';
      readonly components: ReadonlyArray<
        | {
            readonly __typename?: 'Alert';
            readonly _id: string;
            readonly heading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly body: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'AppDownload';
            readonly _id: string;
            readonly details: {
              readonly __typename?: 'AppDownloadSection';
              readonly iOSDownloadLink: string | null;
              readonly androidDownloadLink: string | null;
              readonly huaweiDownloadLink: string | null;
              readonly backgroundImage: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly primaryImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly headerSuperText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly copy: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly textDecorationLeft: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly textDecorationRight: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly submitButtonText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly iOSDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly androidDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly huaweiDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'BrazeContentCards';
            readonly _id: string;
            readonly contentCardsHeader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly contentCardsSubheader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'FanFavorites';
            readonly _id: string;
            readonly fanFavoriteItems: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer' }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
            readonly favoriteSection: {
              readonly __typename?: 'FavoriteSection';
              readonly headerText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'LockedOffers'; readonly _id: string }
        | {
            readonly __typename?: 'MainHero';
            readonly _id: string;
            readonly textColor: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mobileImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly superText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly mainText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly action: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRight: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRightIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly actionLeft: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionLeftIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly maskCards: ReadonlyArray<{
              readonly __typename?: 'SimpleCard';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly loyaltyAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'MarketingTileGroup';
            readonly _id: string;
            readonly tiles: ReadonlyArray<
              | {
                  readonly __typename?: 'MarketingTile';
                  readonly _id: string;
                  readonly textAlignment: string | null;
                  readonly buttonAlignment: string | null;
                  readonly textColor: string | null;
                  readonly lockedAspectRatio: boolean | null;
                  readonly backgroundImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly buttonAction: {
                    readonly __typename?: 'LinkAction';
                    readonly route: string | null;
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly textBlock: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsText: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsButton: {
                    readonly __typename?: 'LinkAction';
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly ruleSet: {
                    readonly __typename?: 'MarketingTileRuleSet';
                    readonly BKPayAudience: string | null;
                    readonly bkSpecificTargeting: boolean | null;
                    readonly dayOfWeek: {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                    } | null;
                    readonly betweenTimes: {
                      readonly __typename?: 'BetweenTimes';
                      readonly startTime: string | null;
                      readonly endTime: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileBasic';
                  readonly _id: string;
                  readonly marketingBasicTileDescription: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly marketingBasicTileImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly marketingBasicTileTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                  readonly linkLabel: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'MarketingTileList';
                  readonly _type: string | null;
                  readonly tiles: ReadonlyArray<{
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'MarketingTilePair';
                  readonly left: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly right: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileQuad';
                  readonly topLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly topRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          }
        | { readonly __typename?: 'OffersSection'; readonly _id: string }
        | {
            readonly __typename?: 'OurServices';
            readonly _id: string;
            readonly servicesHeaderTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly servicesHeroTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly ourServicesHeroImageLocale: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'RecentItems'; readonly _id: string }
        | {
            readonly __typename?: 'Trending';
            readonly _id: string;
            readonly promotions: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'ExternalLink';
                  readonly _key: string | null;
                  readonly url: string | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'LocaleImage';
                    readonly locale: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | null
      > | null;
    } | null;
    readonly webUnauthenticated: {
      readonly __typename?: 'CateringPageConfiguration';
      readonly components: ReadonlyArray<
        | {
            readonly __typename?: 'Alert';
            readonly _id: string;
            readonly heading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly body: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'AppDownload';
            readonly _id: string;
            readonly details: {
              readonly __typename?: 'AppDownloadSection';
              readonly iOSDownloadLink: string | null;
              readonly androidDownloadLink: string | null;
              readonly huaweiDownloadLink: string | null;
              readonly backgroundImage: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly primaryImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly headerSuperText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly copy: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly textDecorationLeft: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly textDecorationRight: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly submitButtonText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly iOSDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly androidDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly huaweiDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'BrazeContentCards';
            readonly _id: string;
            readonly contentCardsHeader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly contentCardsSubheader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'FanFavorites';
            readonly _id: string;
            readonly fanFavoriteItems: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer' }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
            readonly favoriteSection: {
              readonly __typename?: 'FavoriteSection';
              readonly headerText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'LockedOffers'; readonly _id: string }
        | {
            readonly __typename?: 'MainHero';
            readonly _id: string;
            readonly textColor: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mobileImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly superText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly mainText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly action: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRight: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRightIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly actionLeft: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionLeftIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly maskCards: ReadonlyArray<{
              readonly __typename?: 'SimpleCard';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly loyaltyAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'MarketingTileGroup';
            readonly _id: string;
            readonly tiles: ReadonlyArray<
              | {
                  readonly __typename?: 'MarketingTile';
                  readonly _id: string;
                  readonly textAlignment: string | null;
                  readonly buttonAlignment: string | null;
                  readonly textColor: string | null;
                  readonly lockedAspectRatio: boolean | null;
                  readonly backgroundImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly buttonAction: {
                    readonly __typename?: 'LinkAction';
                    readonly route: string | null;
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly textBlock: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsText: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsButton: {
                    readonly __typename?: 'LinkAction';
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly ruleSet: {
                    readonly __typename?: 'MarketingTileRuleSet';
                    readonly BKPayAudience: string | null;
                    readonly bkSpecificTargeting: boolean | null;
                    readonly dayOfWeek: {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                    } | null;
                    readonly betweenTimes: {
                      readonly __typename?: 'BetweenTimes';
                      readonly startTime: string | null;
                      readonly endTime: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileBasic';
                  readonly _id: string;
                  readonly marketingBasicTileDescription: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly marketingBasicTileImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly marketingBasicTileTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                  readonly linkLabel: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'MarketingTileList';
                  readonly _type: string | null;
                  readonly tiles: ReadonlyArray<{
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'MarketingTilePair';
                  readonly left: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly right: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileQuad';
                  readonly topLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly topRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          }
        | { readonly __typename?: 'OffersSection'; readonly _id: string }
        | {
            readonly __typename?: 'OurServices';
            readonly _id: string;
            readonly servicesHeaderTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly servicesHeroTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly ourServicesHeroImageLocale: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'RecentItems'; readonly _id: string }
        | {
            readonly __typename?: 'Trending';
            readonly _id: string;
            readonly promotions: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'ExternalLink';
                  readonly _key: string | null;
                  readonly url: string | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'LocaleImage';
                    readonly locale: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | null
      > | null;
    } | null;
    readonly webAuthenticated: {
      readonly __typename?: 'CateringPageConfiguration';
      readonly components: ReadonlyArray<
        | {
            readonly __typename?: 'Alert';
            readonly _id: string;
            readonly heading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly body: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly learnMoreUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'AppDownload';
            readonly _id: string;
            readonly details: {
              readonly __typename?: 'AppDownloadSection';
              readonly iOSDownloadLink: string | null;
              readonly androidDownloadLink: string | null;
              readonly huaweiDownloadLink: string | null;
              readonly backgroundImage: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly primaryImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly headerSuperText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly copy: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly textDecorationLeft: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly textDecorationRight: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly submitButtonText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly iOSDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly androidDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly huaweiDownloadImage: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'BrazeContentCards';
            readonly _id: string;
            readonly contentCardsHeader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly contentCardsSubheader: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'FanFavorites';
            readonly _id: string;
            readonly fanFavoriteItems: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer' }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
            readonly favoriteSection: {
              readonly __typename?: 'FavoriteSection';
              readonly headerText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'LockedOffers'; readonly _id: string }
        | {
            readonly __typename?: 'MainHero';
            readonly _id: string;
            readonly textColor: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mobileImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly superText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly mainText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly action: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRight: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionRightIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly actionLeft: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly actionLeftIcon: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly maskCards: ReadonlyArray<{
              readonly __typename?: 'SimpleCard';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly loyaltyAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'MarketingTileGroup';
            readonly _id: string;
            readonly tiles: ReadonlyArray<
              | {
                  readonly __typename?: 'MarketingTile';
                  readonly _id: string;
                  readonly textAlignment: string | null;
                  readonly buttonAlignment: string | null;
                  readonly textColor: string | null;
                  readonly lockedAspectRatio: boolean | null;
                  readonly backgroundImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly buttonAction: {
                    readonly __typename?: 'LinkAction';
                    readonly route: string | null;
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly textBlock: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsText: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsButton: {
                    readonly __typename?: 'LinkAction';
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly ruleSet: {
                    readonly __typename?: 'MarketingTileRuleSet';
                    readonly BKPayAudience: string | null;
                    readonly bkSpecificTargeting: boolean | null;
                    readonly dayOfWeek: {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                    } | null;
                    readonly betweenTimes: {
                      readonly __typename?: 'BetweenTimes';
                      readonly startTime: string | null;
                      readonly endTime: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileBasic';
                  readonly _id: string;
                  readonly marketingBasicTileDescription: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly marketingBasicTileImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly marketingBasicTileTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                  readonly linkLabel: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'MarketingTileList';
                  readonly _type: string | null;
                  readonly tiles: ReadonlyArray<{
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'MarketingTilePair';
                  readonly left: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly right: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileQuad';
                  readonly topLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly topRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          }
        | { readonly __typename?: 'OffersSection'; readonly _id: string }
        | {
            readonly __typename?: 'OurServices';
            readonly _id: string;
            readonly servicesHeaderTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly servicesHeroTextLocale: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly ourServicesHeroImageLocale: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'RecentItems'; readonly _id: string }
        | {
            readonly __typename?: 'Trending';
            readonly _id: string;
            readonly promotions: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'ExternalLink';
                  readonly _key: string | null;
                  readonly url: string | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'LocaleImage';
                    readonly locale: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyClaimPointsContentQueryVariables = Exact<{
  featureClaimPointsUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyClaimPointsContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly ClaimPointsUI: {
    readonly __typename?: 'ClaimPointsUI';
    readonly _id: string;
    readonly claimPointsHomeScreenContent: {
      readonly __typename?: 'ClaimPointsScreenContent';
      readonly rewardsIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly secondImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly instructionsTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly topButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly topButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimQrCode: {
      readonly __typename?: 'ClaimQrCode';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly body: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly link: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
    } | null;
    readonly claimPointsSurveyContent: {
      readonly __typename?: 'ClaimPointsSurveyScreenContent';
      readonly expirationTimeReceipts: number | null;
      readonly rewardsIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly secondImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly instructionsTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly expirationDateInstructionsTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly inputBoxExpirationDatePlaceholder: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly topButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly topButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsSuccessfulSubmissionScreenContent: {
      readonly __typename?: 'ClaimPointsScreenContent';
      readonly rewardsIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly secondImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly instructionsTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly topButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly topButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly bottomButtonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimOnHistory: {
      readonly __typename?: 'ClaimOnHistory';
      readonly linkText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null;
    readonly claimPointsPendingContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsExpiredContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsInvalidCodeContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsAlreadyClaimedContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsTransactionNotFoundContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsInvalidRestaurantContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsDailyAmountExceededContent: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsDuplicateTransactionId: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly claimPointsTooManyRequests: {
      readonly __typename?: 'ClaimPointsFailedSubmissionContent';
      readonly screenTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly failureTextBlock: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureDisclaimersQueryVariables = Exact<{
  featureDisclaimersId: Scalars['ID']['input'];
}>;

export type IFeatureDisclaimersQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureDisclaimers: {
    readonly __typename?: 'FeatureDisclaimers';
    readonly _id: string;
    readonly superUpsell: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly calorie: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly price: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly itemAvailability: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly images: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly phoneNumber: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly country: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly offers: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly copyrightAndTrademark: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly reservedRights: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly communicationPreferencesBlock: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly signUpDisclaimers: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly signUpMarketDisclaimers: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly deliveryDisclaimer: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly deliveryLogo: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureDonationQueryVariables = Exact<{
  featureDonationId: Scalars['ID']['input'];
}>;

export type IFeatureDonationQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureDonation: {
    readonly __typename?: 'FeatureDonation';
    readonly _id: string;
    readonly donationItemList: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null> | null;
    readonly logoImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly subtitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly dialogInformation: {
      readonly __typename?: 'DialogInformation';
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureEventReservationsQueryVariables = Exact<{
  featureEventReservationsId: Scalars['ID']['input'];
}>;

export type IFeatureEventReservationsQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureEventReservations: {
    readonly __typename?: 'FeatureEventReservations';
    readonly enabledEvents: ReadonlyArray<
      | {
          readonly __typename?: 'FeatureBirthday';
          readonly eventType: string | null;
          readonly birthdayAttendees: ReadonlyArray<{
            readonly __typename?: 'BirthdayAttendee';
            readonly minimum: number | null;
            readonly maximum: number | null;
            readonly key: string | null;
          } | null> | null;
          readonly allergens: {
            readonly __typename?: 'AllergensConfig';
            readonly minimum: number | null;
            readonly maximum: number | null;
          } | null;
          readonly reservationTime: {
            readonly __typename?: 'EventReservationTime';
            readonly earliestReservationDay: number | null;
            readonly latestReservationDay: number | null;
            readonly availableHours: ReadonlyArray<{
              readonly __typename?: 'TimeRange';
              readonly startTime: string | null;
              readonly endTime: string | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'FeatureCatering';
          readonly eventType: string | null;
          readonly cateringAttendees: ReadonlyArray<{
            readonly __typename?: 'CateringAttendee';
            readonly minimum: number | null;
            readonly maximum: number | null;
            readonly key: string | null;
          } | null> | null;
          readonly allergens: {
            readonly __typename?: 'AllergensConfig';
            readonly minimum: number | null;
            readonly maximum: number | null;
          } | null;
          readonly reservationTime: {
            readonly __typename?: 'EventReservationTime';
            readonly earliestReservationDay: number | null;
            readonly latestReservationDay: number | null;
            readonly availableHours: ReadonlyArray<{
              readonly __typename?: 'TimeRange';
              readonly startTime: string | null;
              readonly endTime: string | null;
            } | null> | null;
          } | null;
        }
      | null
    > | null;
    readonly privacyPolicyDisclaimers: {
      readonly __typename?: 'PrivacyPolicyDisclaimers';
      readonly privacyPolicyDisclaimersCells: ReadonlyArray<{
        readonly __typename?: 'PrivacyPolicyDisclaimersCell';
        readonly disclaimerTitle: string | null;
        readonly disclaimerDescription: string | null;
        readonly border: boolean | null;
        readonly fontSize: number | null;
        readonly paddingTop: number | null;
        readonly paddingRight: number | null;
        readonly paddingBottom: number | null;
        readonly paddingLeft: number | null;
        readonly radius: number | null;
        readonly disclaimerContent: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IFeatureExternalLinkSectionQueryVariables = Exact<{
  featureExternalLinkId: Scalars['ID']['input'];
}>;

export type IFeatureExternalLinkSectionQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureExternalLinkSection: {
    readonly __typename?: 'FeatureExternalLinkSection';
    readonly _id: string;
    readonly FeatureExternalLinks: ReadonlyArray<{
      readonly __typename?: 'CustomLink';
      readonly title: string | null;
      readonly link: {
        readonly __typename?: 'LocaleUrl';
        readonly localeUrl: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureFeesAndTaxesQueryVariables = Exact<{
  featureFeesAndTaxesId: Scalars['ID']['input'];
}>;

export type IFeatureFeesAndTaxesQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureFeesAndTaxes: {
    readonly __typename?: 'FeatureFeesAndTaxes';
    readonly _id: string;
    readonly bagFeeConfigurations: {
      readonly __typename?: 'BagFeeConfigurations';
      readonly bagTaxExplainer: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureFooterQueryVariables = Exact<{
  featureFooterId: Scalars['ID']['input'];
}>;

export type IFeatureFooterQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureFooter: {
    readonly __typename?: 'FeatureFooter';
    readonly rows: ReadonlyArray<{
      readonly __typename?: 'Row';
      readonly _key: string | null;
      readonly columns: ReadonlyArray<{
        readonly __typename?: 'Column';
        readonly _key: string | null;
        readonly options: ReadonlyArray<
          | {
              readonly __typename?: 'CopyrightAndLanguageSelector';
              readonly _type: string | null;
              readonly copyrightText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'HighlightedVerticalLinkListWidget';
              readonly _type: string | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly subheader: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly highlightedLinks: ReadonlyArray<{
                readonly __typename?: 'HighlightedLinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'HorizontalLinkListWidget';
              readonly _type: string | null;
              readonly linkWidgets: ReadonlyArray<{
                readonly __typename?: 'LinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'ImageWidget';
              readonly _type: string | null;
              readonly attributionLink: string | null;
              readonly image: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'SocialIconsWithHeaderWidget';
              readonly _type: string | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly socialIconsWidget: {
                readonly __typename?: 'SocialIconsWidget';
                readonly _type: string | null;
                readonly instagramUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly facebookUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly youtubeUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly twitterUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly tiktokUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkedinUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly snapchatUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
            }
          | {
              readonly __typename?: 'TextWidget';
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'TextWidgetWithUrl';
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly textUrl: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly url: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'VerticalLinkListWidget';
              readonly _type: string | null;
              readonly linkWidgets: ReadonlyArray<{
                readonly __typename?: 'LinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'VerticalLinkListWithHeaderWidget';
              readonly _type: string | null;
              readonly header: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly linkWidgets: ReadonlyArray<{
                readonly __typename?: 'LinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'WordmarkAndSocialIcons';
              readonly _type: string | null;
              readonly socialIconsWidget: {
                readonly __typename?: 'SocialIconsWidget';
                readonly _type: string | null;
                readonly instagramUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly facebookUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly youtubeUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly twitterUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly tiktokUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkedinUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly snapchatUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
            }
          | null
        > | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type IFeatureGeolocationMapMarkersQueryVariables = Exact<{
  featureGeolocationMapMarkersId: Scalars['ID']['input'];
}>;

export type IFeatureGeolocationMapMarkersQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureGeolocationMapMarkers: {
    readonly __typename?: 'FeatureGeolocationMapMarkers';
    readonly _id: string;
    readonly driverIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureGeolocationModalQueryVariables = Exact<{
  featureGeolocationModalId: Scalars['ID']['input'];
}>;

export type IFeatureGeolocationModalQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureGeolocationModal: {
    readonly __typename?: 'FeatureGeolocationModal';
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null;
};

export type IFeatureWebHomePageQueryVariables = Exact<{
  featureHomePageId: Scalars['ID']['input'];
}>;

export type IFeatureWebHomePageQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureHomePage: {
    readonly __typename?: 'FeatureHomePage';
    readonly unauthenticated: {
      readonly __typename?: 'HomePageConfiguration';
      readonly components: ReadonlyArray<
        | { readonly __typename: 'Alert'; readonly _id: string }
        | { readonly __typename: 'AppDownload'; readonly _id: string }
        | { readonly __typename: 'BrazeContentCards'; readonly _id: string }
        | { readonly __typename: 'FanFavorites'; readonly _id: string }
        | { readonly __typename: 'Hero'; readonly _id: string }
        | { readonly __typename?: 'LockedOffers' }
        | { readonly __typename: 'MainHero'; readonly _id: string }
        | { readonly __typename: 'MarketingCardGroup'; readonly _id: string }
        | { readonly __typename: 'MarketingTileCarousel'; readonly _id: string }
        | { readonly __typename: 'MarketingTileGroup'; readonly _id: string }
        | { readonly __typename: 'OfferCarouselSection'; readonly _id: string }
        | { readonly __typename: 'OffersSection'; readonly _id: string }
        | { readonly __typename: 'OurServices'; readonly _id: string }
        | { readonly __typename?: 'QuestsSection' }
        | { readonly __typename: 'RecentItems'; readonly _id: string }
        | { readonly __typename: 'RewardsSection'; readonly _id: string }
        | { readonly __typename?: 'RollUpTheRimSection' }
        | { readonly __typename?: 'ScanForRewards' }
        | { readonly __typename?: 'SimpleHomePage' }
        | { readonly __typename: 'Trending'; readonly _id: string }
        | null
      > | null;
    } | null;
    readonly authenticated: {
      readonly __typename?: 'HomePageConfiguration';
      readonly components: ReadonlyArray<
        | { readonly __typename: 'Alert'; readonly _id: string }
        | { readonly __typename: 'AppDownload'; readonly _id: string }
        | { readonly __typename: 'BrazeContentCards'; readonly _id: string }
        | { readonly __typename: 'FanFavorites'; readonly _id: string }
        | { readonly __typename: 'Hero'; readonly _id: string }
        | { readonly __typename?: 'LockedOffers' }
        | { readonly __typename: 'MainHero'; readonly _id: string }
        | { readonly __typename: 'MarketingCardGroup'; readonly _id: string }
        | { readonly __typename: 'MarketingTileCarousel'; readonly _id: string }
        | { readonly __typename: 'MarketingTileGroup'; readonly _id: string }
        | { readonly __typename: 'OfferCarouselSection'; readonly _id: string }
        | { readonly __typename: 'OffersSection'; readonly _id: string }
        | { readonly __typename: 'OurServices'; readonly _id: string }
        | { readonly __typename?: 'QuestsSection' }
        | { readonly __typename: 'RecentItems'; readonly _id: string }
        | { readonly __typename: 'RewardsSection'; readonly _id: string }
        | { readonly __typename?: 'RollUpTheRimSection' }
        | { readonly __typename?: 'ScanForRewards' }
        | { readonly __typename?: 'SimpleHomePage' }
        | { readonly __typename: 'Trending'; readonly _id: string }
        | null
      > | null;
    } | null;
  } | null;
};

export type IFeatureAppHomePageQueryVariables = Exact<{
  featureHomePageId: Scalars['ID']['input'];
}>;

export type IFeatureAppHomePageQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureHomePage: {
    readonly __typename?: 'FeatureHomePage';
    readonly unauthenticated: {
      readonly __typename?: 'HomePageConfiguration';
      readonly components: ReadonlyArray<
        | { readonly __typename: 'Alert'; readonly _id: string }
        | { readonly __typename: 'AppDownload'; readonly _id: string }
        | { readonly __typename: 'BrazeContentCards'; readonly _id: string }
        | { readonly __typename: 'FanFavorites'; readonly _id: string }
        | { readonly __typename: 'Hero'; readonly _id: string }
        | { readonly __typename?: 'LockedOffers' }
        | { readonly __typename: 'MainHero'; readonly _id: string }
        | { readonly __typename: 'MarketingCardGroup'; readonly _id: string }
        | { readonly __typename: 'MarketingTileCarousel'; readonly _id: string }
        | { readonly __typename: 'MarketingTileGroup'; readonly _id: string }
        | { readonly __typename: 'OfferCarouselSection'; readonly _id: string }
        | { readonly __typename: 'OffersSection'; readonly _id: string }
        | { readonly __typename: 'OurServices'; readonly _id: string }
        | { readonly __typename?: 'QuestsSection' }
        | { readonly __typename: 'RecentItems'; readonly _id: string }
        | { readonly __typename: 'RewardsSection'; readonly _id: string }
        | { readonly __typename?: 'RollUpTheRimSection' }
        | { readonly __typename?: 'ScanForRewards' }
        | { readonly __typename?: 'SimpleHomePage' }
        | { readonly __typename: 'Trending'; readonly _id: string }
        | null
      > | null;
    } | null;
    readonly authenticated: {
      readonly __typename?: 'HomePageConfiguration';
      readonly components: ReadonlyArray<
        | { readonly __typename: 'Alert'; readonly _id: string }
        | { readonly __typename: 'AppDownload'; readonly _id: string }
        | { readonly __typename: 'BrazeContentCards'; readonly _id: string }
        | { readonly __typename: 'FanFavorites'; readonly _id: string }
        | { readonly __typename: 'Hero'; readonly _id: string }
        | { readonly __typename?: 'LockedOffers' }
        | { readonly __typename: 'MainHero'; readonly _id: string }
        | { readonly __typename: 'MarketingCardGroup'; readonly _id: string }
        | { readonly __typename: 'MarketingTileCarousel'; readonly _id: string }
        | { readonly __typename: 'MarketingTileGroup'; readonly _id: string }
        | { readonly __typename: 'OfferCarouselSection'; readonly _id: string }
        | { readonly __typename: 'OffersSection'; readonly _id: string }
        | { readonly __typename: 'OurServices'; readonly _id: string }
        | { readonly __typename?: 'QuestsSection' }
        | { readonly __typename: 'RecentItems'; readonly _id: string }
        | { readonly __typename: 'RewardsSection'; readonly _id: string }
        | { readonly __typename?: 'RollUpTheRimSection' }
        | { readonly __typename?: 'ScanForRewards' }
        | { readonly __typename?: 'SimpleHomePage' }
        | { readonly __typename: 'Trending'; readonly _id: string }
        | null
      > | null;
    } | null;
  } | null;
};

export type IMainHeroQueryVariables = Exact<{
  mainHeroId: Scalars['ID']['input'];
}>;

export type IMainHeroQuery = {
  readonly __typename?: 'RootQuery';
  readonly MainHero: {
    readonly __typename?: 'MainHero';
    readonly _id: string;
    readonly textColor: string | null;
    readonly image: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly mobileImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly superText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly mainText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly subText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly action: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly actionRight: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly actionRightIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly actionLeft: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly actionLeftIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly maskCards: ReadonlyArray<{
      readonly __typename?: 'SimpleCard';
      readonly _key: string | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly loyaltyAction: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IHeroQueryVariables = Exact<{
  heroId: Scalars['ID']['input'];
}>;

export type IHeroQuery = {
  readonly __typename?: 'RootQuery';
  readonly Hero: {
    readonly __typename?: 'Hero';
    readonly _id: string;
    readonly showComplementaryCTA: boolean | null;
    readonly reversed: boolean | null;
    readonly hasTerms: boolean | null;
    readonly backgroundColor: boolean | null;
    readonly colorOption: string | null;
    readonly backgroundSplit: boolean | null;
    readonly image: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly altImageText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly subheader: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly primaryCta: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly complementaryCta: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly termsText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly termsButton: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IBrazeContentCardsQueryVariables = Exact<{
  brazeContentCardsId: Scalars['ID']['input'];
}>;

export type IBrazeContentCardsQuery = {
  readonly __typename?: 'RootQuery';
  readonly BrazeContentCards: {
    readonly __typename?: 'BrazeContentCards';
    readonly _id: string;
    readonly contentCardsHeader: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly contentCardsSubheader: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IAlertQueryVariables = Exact<{
  alertId: Scalars['ID']['input'];
}>;

export type IAlertQuery = {
  readonly __typename?: 'RootQuery';
  readonly Alert: {
    readonly __typename?: 'Alert';
    readonly _id: string;
    readonly heading: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly learnMoreText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly learnMoreUrl: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IMarketingCardGroupsQueryVariables = Exact<{
  where?: InputMaybe<IMarketingCardGroupFilter>;
}>;

export type IMarketingCardGroupsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allMarketingCardGroup: ReadonlyArray<{
    readonly __typename?: 'MarketingCardGroup';
    readonly _id: string;
    readonly Cards: ReadonlyArray<{
      readonly __typename?: 'MarketingCard';
      readonly _id: string;
      readonly hasCustomColors: boolean | null;
      readonly hasTerms: boolean | null;
      readonly BKPayAudience: string | null;
      readonly bkSpecificTargeting: boolean | null;
      readonly daysOfWeek: ReadonlyArray<string | null> | null;
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly callToAction: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly textColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly backgroundColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly buttonTextColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly buttonBackgroundColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly termsText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly termsButton: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly timeRanges: ReadonlyArray<{
        readonly __typename?: 'TimeRange';
        readonly startTime: string | null;
        readonly endTime: string | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type IMarketingTileCarouselQueryVariables = Exact<{
  marketingTileCarouselId: Scalars['ID']['input'];
}>;

export type IMarketingTileCarouselQuery = {
  readonly __typename?: 'RootQuery';
  readonly MarketingTileCarousel: {
    readonly __typename?: 'MarketingTileCarousel';
    readonly _id: string;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly tiles: ReadonlyArray<{
      readonly __typename?: 'MarketingTileBasic';
      readonly _id: string;
      readonly marketingBasicTileDescription: {
        readonly __typename?: 'LocaleTypographyBlock';
        readonly localeRaw: any | null;
      } | null;
      readonly marketingBasicTileImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly marketingBasicTileTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      readonly linkLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IMarketingTileGroupsQueryVariables = Exact<{
  where?: InputMaybe<IMarketingTileGroupFilter>;
}>;

export type IMarketingTileGroupsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allMarketingTileGroup: ReadonlyArray<{
    readonly __typename?: 'MarketingTileGroup';
    readonly _id: string;
    readonly tiles: ReadonlyArray<
      | {
          readonly __typename?: 'MarketingTile';
          readonly _id: string;
          readonly textAlignment: string | null;
          readonly buttonAlignment: string | null;
          readonly textColor: string | null;
          readonly lockedAspectRatio: boolean | null;
          readonly backgroundImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly buttonAction: {
            readonly __typename?: 'LinkAction';
            readonly route: string | null;
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly textBlock: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly termsText: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly termsButton: {
            readonly __typename?: 'LinkAction';
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly ruleSet: {
            readonly __typename?: 'MarketingTileRuleSet';
            readonly BKPayAudience: string | null;
            readonly bkSpecificTargeting: boolean | null;
            readonly dayOfWeek: {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
            } | null;
            readonly betweenTimes: {
              readonly __typename?: 'BetweenTimes';
              readonly startTime: string | null;
              readonly endTime: string | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename: 'MarketingTileList';
          readonly _type: string | null;
          readonly tiles: ReadonlyArray<{
            readonly __typename?: 'MarketingTileBasic';
            readonly _id: string;
            readonly marketingBasicTileDescription: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingBasicTileImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly marketingBasicTileTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly linkLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'MarketingTilePair';
          readonly left: {
            readonly __typename?: 'MarketingTile';
            readonly _id: string;
            readonly textAlignment: string | null;
            readonly buttonAlignment: string | null;
            readonly textColor: string | null;
            readonly lockedAspectRatio: boolean | null;
            readonly backgroundImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly buttonAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly textBlock: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly termsText: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly termsButton: {
              readonly __typename?: 'LinkAction';
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly ruleSet: {
              readonly __typename?: 'MarketingTileRuleSet';
              readonly BKPayAudience: string | null;
              readonly bkSpecificTargeting: boolean | null;
              readonly dayOfWeek: {
                readonly __typename?: 'DayOfWeekBand';
                readonly monday: boolean | null;
                readonly tuesday: boolean | null;
                readonly wednesday: boolean | null;
                readonly thursday: boolean | null;
                readonly friday: boolean | null;
                readonly saturday: boolean | null;
                readonly sunday: boolean | null;
              } | null;
              readonly betweenTimes: {
                readonly __typename?: 'BetweenTimes';
                readonly startTime: string | null;
                readonly endTime: string | null;
              } | null;
            } | null;
          } | null;
          readonly right: {
            readonly __typename?: 'MarketingTile';
            readonly _id: string;
            readonly textAlignment: string | null;
            readonly buttonAlignment: string | null;
            readonly textColor: string | null;
            readonly lockedAspectRatio: boolean | null;
            readonly backgroundImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly buttonAction: {
              readonly __typename?: 'LinkAction';
              readonly route: string | null;
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly textBlock: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly termsText: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly termsButton: {
              readonly __typename?: 'LinkAction';
              readonly actionText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly actionUrl: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly ruleSet: {
              readonly __typename?: 'MarketingTileRuleSet';
              readonly BKPayAudience: string | null;
              readonly bkSpecificTargeting: boolean | null;
              readonly dayOfWeek: {
                readonly __typename?: 'DayOfWeekBand';
                readonly monday: boolean | null;
                readonly tuesday: boolean | null;
                readonly wednesday: boolean | null;
                readonly thursday: boolean | null;
                readonly friday: boolean | null;
                readonly saturday: boolean | null;
                readonly sunday: boolean | null;
              } | null;
              readonly betweenTimes: {
                readonly __typename?: 'BetweenTimes';
                readonly startTime: string | null;
                readonly endTime: string | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'MarketingTileQuad';
          readonly topLeftTile: {
            readonly __typename?: 'MarketingTileBasic';
            readonly _id: string;
            readonly marketingBasicTileDescription: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingBasicTileImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly marketingBasicTileTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly linkLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly topRightTile: {
            readonly __typename?: 'MarketingTileBasic';
            readonly _id: string;
            readonly marketingBasicTileDescription: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingBasicTileImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly marketingBasicTileTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly linkLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly bottomLeftTile: {
            readonly __typename?: 'MarketingTileBasic';
            readonly _id: string;
            readonly marketingBasicTileDescription: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingBasicTileImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly marketingBasicTileTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly linkLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly bottomRightTile: {
            readonly __typename?: 'MarketingTileBasic';
            readonly _id: string;
            readonly marketingBasicTileDescription: {
              readonly __typename?: 'LocaleTypographyBlock';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingBasicTileImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly marketingBasicTileTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly linkLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        }
      | null
    > | null;
  }>;
};

export type ITrendingQueryVariables = Exact<{
  trendingId: Scalars['ID']['input'];
}>;

export type ITrendingQuery = {
  readonly __typename?: 'RootQuery';
  readonly Trending: {
    readonly __typename?: 'Trending';
    readonly _id: string;
    readonly promotions: ReadonlyArray<
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'ExternalLink';
          readonly _key: string | null;
          readonly url: string | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
      | {
          readonly __typename?: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'Section';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type IFanFavoritesQueryVariables = Exact<{
  fanFavoritesId: Scalars['ID']['input'];
}>;

export type IFanFavoritesQuery = {
  readonly __typename?: 'RootQuery';
  readonly FanFavorites: {
    readonly __typename?: 'FanFavorites';
    readonly _id: string;
    readonly fanFavoriteItems: ReadonlyArray<
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'Offer' }
      | {
          readonly __typename?: 'Picker';
          readonly _id: string;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'Section';
          readonly _id: string;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
    readonly favoriteSection: {
      readonly __typename?: 'FavoriteSection';
      readonly headerText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IOurServicesQueryVariables = Exact<{
  ourServicesId: Scalars['ID']['input'];
}>;

export type IOurServicesQuery = {
  readonly __typename?: 'RootQuery';
  readonly OurServices: {
    readonly __typename?: 'OurServices';
    readonly _id: string;
    readonly servicesHeaderTextLocale: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly servicesHeroTextLocale: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly ourServicesHeroImageLocale: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IAppDownloadQueryVariables = Exact<{
  appDownloadId: Scalars['ID']['input'];
}>;

export type IAppDownloadQuery = {
  readonly __typename?: 'RootQuery';
  readonly AppDownload: {
    readonly __typename?: 'AppDownload';
    readonly _id: string;
    readonly details: {
      readonly __typename?: 'AppDownloadSection';
      readonly iOSDownloadLink: string | null;
      readonly androidDownloadLink: string | null;
      readonly huaweiDownloadLink: string | null;
      readonly backgroundImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly primaryImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly headerSuperText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly copy: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly textDecorationLeft: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly textDecorationRight: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly submitButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly iOSDownloadImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly androidDownloadImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly huaweiDownloadImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IRewardsSectionQueryVariables = Exact<{
  rewardsSectionId: Scalars['ID']['input'];
}>;

export type IRewardsSectionQuery = {
  readonly __typename?: 'RootQuery';
  readonly RewardsSection: {
    readonly __typename?: 'RewardsSection';
    readonly _id: string;
    readonly rewardsList: ReadonlyArray<{
      readonly __typename?: 'FeaturedReward';
      readonly reward: {
        readonly __typename?: 'Reward';
        readonly _id: string;
        readonly testOnly: boolean | null;
        readonly loyaltyEngineId: string | null;
        readonly incentiveNotInMenu: boolean | null;
        readonly mobileOrderOnly: boolean | null;
        readonly redemptionMethod: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
        readonly ruleSet: ReadonlyArray<
          | { readonly __typename?: 'BirthdayMultiplier' }
          | { readonly __typename?: 'CartLimit' }
          | { readonly __typename?: 'CartRequirement' }
          | { readonly __typename?: 'CartRequirementExclusion' }
          | { readonly __typename?: 'CoolDown' }
          | { readonly __typename?: 'DayOfWeekBand' }
          | { readonly __typename?: 'EmailVerifiedRequired' }
          | { readonly __typename?: 'FirstOrderOnly' }
          | { readonly __typename?: 'Limit' }
          | { readonly __typename?: 'LoyaltyBetweenDates' }
          | { readonly __typename?: 'LoyaltyBetweenTimes' }
          | { readonly __typename?: 'LoyaltyPoints' }
          | { readonly __typename?: 'PaymentMethod' }
          | { readonly __typename?: 'RequiresAuthentication' }
          | { readonly __typename?: 'RestaurantGroupRestrictions' }
          | {
              readonly __typename?: 'RewardPrice';
              readonly price: number | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'ServiceModeRestrictions' }
          | { readonly __typename?: 'SubtotalSpend' }
          | { readonly __typename?: 'TierList' }
          | { readonly __typename?: 'TotalSpend' }
          | { readonly __typename?: 'TransactionCount' }
          | { readonly __typename?: 'UserAttributes' }
          | { readonly __typename?: 'UserCreated' }
          | null
        > | null;
        readonly moreInfo: {
          readonly __typename?: 'LocaleBlockContent';
          readonly enRaw: any | null;
        } | null;
        readonly howToRedeem: {
          readonly __typename?: 'LocaleBlockContent';
          readonly enRaw: any | null;
        } | null;
        readonly backgroundImage: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly incentives: ReadonlyArray<
          | {
              readonly __typename: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename: 'OfferDiscount';
              readonly _id: string;
              readonly _type: string;
              readonly discountValue: number | null;
              readonly discountType: string | null;
              readonly discountProduct:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | null;
            }
          | {
              readonly __typename: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly option:
                  | {
                      readonly __typename: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | null
        > | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
      readonly category: {
        readonly __typename?: 'RewardCategory';
        readonly label: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IOfferCarouselSectionQueryVariables = Exact<{
  offerCarouselId: Scalars['ID']['input'];
}>;

export type IOfferCarouselSectionQuery = {
  readonly __typename?: 'RootQuery';
  readonly OfferCarouselSection: {
    readonly __typename?: 'OfferCarouselSection';
    readonly _id: string;
    readonly offerCarouselTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly offerCarouselList: ReadonlyArray<
      | {
          readonly __typename?: 'ExternalOffers';
          readonly _type: string;
          readonly _id: string;
          readonly testOnly: boolean | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'SystemwideOffer';
          readonly _type: string;
          readonly _id: string;
          readonly testOnly: boolean | null;
          readonly loyaltyEngineId: string | null;
          readonly name: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly moreInfo: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly localizedImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly rules: ReadonlyArray<
            | { readonly __typename?: 'BirthdayMultiplier' }
            | { readonly __typename?: 'CartLimit' }
            | { readonly __typename?: 'CartRequirement' }
            | { readonly __typename?: 'CartRequirementExclusion' }
            | { readonly __typename?: 'CoolDown' }
            | { readonly __typename?: 'DayOfWeekBand' }
            | { readonly __typename?: 'EmailVerifiedRequired' }
            | { readonly __typename?: 'FirstOrderOnly' }
            | { readonly __typename?: 'IsStackable' }
            | { readonly __typename?: 'Limit' }
            | { readonly __typename?: 'LoyaltyBetweenDates' }
            | { readonly __typename?: 'LoyaltyBetweenTimes' }
            | { readonly __typename?: 'PaymentMethod' }
            | { readonly __typename?: 'RequiresAuthentication' }
            | { readonly __typename?: 'RestaurantGroupRestrictions' }
            | { readonly __typename?: 'ServiceModeRestrictions' }
            | { readonly __typename?: 'SubtotalSpend' }
            | { readonly __typename?: 'TierList' }
            | { readonly __typename?: 'TotalSpend' }
            | { readonly __typename?: 'TransactionCount' }
            | {
                readonly __typename?: 'UserAttributes';
                readonly userAttributesItem: ReadonlyArray<{
                  readonly __typename?: 'UserAttributesItem';
                  readonly attributeItem: string | null;
                  readonly useAttributeBatteryLevelFilter: string | null;
                  readonly useAttributeBatteryLevelValue: number | null;
                  readonly userAttributeBooleanValue: string | null;
                  readonly userAttributeStringFilter: string | null;
                  readonly userAttributeStringValue: string | null;
                } | null> | null;
              }
            | { readonly __typename?: 'UserCreated' }
            | null
          > | null;
        }
      | null
    > | null;
  } | null;
};

export type IFeatureIFrameQueryVariables = Exact<{
  featureIFrameId: Scalars['ID']['input'];
}>;

export type IFeatureIFrameQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureIFrame: {
    readonly __typename?: 'FeatureIFrame';
    readonly iFrameAvailableForAthenticatedUsers: boolean | null;
    readonly passLoyaltyIdToIframe: boolean | null;
    readonly passCognitoIdToIframe: boolean | null;
    readonly enableIFrameComponent: string | null;
    readonly iFrameCampaignUrlWeb: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly iFrameCampaignUrlApp: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IFeatureInvitationCodeQueryVariables = Exact<{
  featureInvitationCodeId: Scalars['ID']['input'];
}>;

export type IFeatureInvitationCodeQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureInvitationCode: {
    readonly __typename?: 'FeatureInvitationCode';
    readonly enableInvitationCode: boolean | null;
    readonly invitationCodeTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly shareMyCodeButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly shareMyCodeButtonUrl: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly termsAndConditions: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly invitationCodeUnlocksBenefitModal: {
      readonly __typename?: 'InvitationCodeUnlocksBenefitModalContent';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly shareMyCodeButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly shareMyCodeButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly invitationCodeSignInModal: {
      readonly __typename?: 'InvitationCodeSignInModalContent';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly shareMyCodeButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly shareMyCodeButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly invitationCodeInformationCards: ReadonlyArray<{
      readonly __typename?: 'InvitationCodeInformationCard';
      readonly cardImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly cardImageBackgroundColor: {
        readonly __typename?: 'Color';
        readonly alpha: number | null;
        readonly hex: string | null;
        readonly hsl: {
          readonly __typename?: 'HslaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly l: number | null;
          readonly s: number | null;
        } | null;
        readonly hsv: {
          readonly __typename?: 'HsvaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly h: number | null;
          readonly s: number | null;
          readonly v: number | null;
        } | null;
        readonly rgb: {
          readonly __typename?: 'RgbaColor';
          readonly _type: string | null;
          readonly a: number | null;
          readonly b: number | null;
          readonly g: number | null;
          readonly r: number | null;
        } | null;
      } | null;
      readonly cardTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly cardDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null> | null;
    readonly invitationCodeBenefit: ReadonlyArray<{
      readonly __typename?: 'ConfigQuest';
      readonly loyaltyEngineId: string | null;
      readonly internalName: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly howToRedeem: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly moreInfo: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly incentive: {
        readonly __typename?: 'QuestIncentive';
        readonly bonusPoints: number | null;
        readonly type: string | null;
        readonly offer: {
          readonly __typename?: 'ConfigOffer';
          readonly _id: string;
          readonly _type: string;
          readonly testOnly: boolean | null;
          readonly loyaltyEngineId: string | null;
          readonly redemptionMethod: string | null;
          readonly shortCode: string | null;
          readonly mobileOrderOnly: boolean | null;
          readonly daypart: ReadonlyArray<string | null> | null;
          readonly redemptionType: string | null;
          readonly offerPrice: number | null;
          readonly uiPattern: string | null;
          readonly isUpcomingOffer: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly moreInfo: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly howToRedeem: {
            readonly __typename?: 'LocaleBlockContent';
            readonly enRaw: any | null;
          } | null;
          readonly backgroundImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly upsellOptions: ReadonlyArray<{
            readonly __typename?: 'SystemwideOffer';
            readonly _id: string;
            readonly loyaltyEngineId: string | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly localizedImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null> | null;
          readonly marketPrice:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
          readonly localizedImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly lockedOffersPanel: {
            readonly __typename?: 'LockedOffersPanel';
            readonly completedChallengeHeader: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly completedChallengeDescription: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null;
          readonly promoCodePanel: {
            readonly __typename?: 'PromoCodePanel';
            readonly promoCodeLink: string | null;
            readonly promoCodeDescription: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly promoCodeLabel: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null;
          readonly incentives: ReadonlyArray<
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly isOfferBenefit: boolean | null;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'OfferActivation';
                readonly _id: string;
                readonly _type: string;
              }
            | {
                readonly __typename: 'OfferDiscount';
                readonly _id: string;
                readonly _type: string;
                readonly discountValue: number | null;
                readonly discountType: string | null;
                readonly discountProduct:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              }
            | {
                readonly __typename: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly isOfferBenefit: boolean | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'PickerOption';
                  readonly option:
                    | {
                        readonly __typename: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | {
                        readonly __typename: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | null;
                } | null> | null;
              }
            | { readonly __typename: 'SwapMapping'; readonly _type: string }
            | null
          > | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly rules: ReadonlyArray<
            | { readonly __typename?: 'BirthdayMultiplier' }
            | { readonly __typename?: 'CartLimit' }
            | { readonly __typename?: 'CartRequirement' }
            | { readonly __typename?: 'CartRequirementExclusion' }
            | { readonly __typename?: 'CoolDown' }
            | { readonly __typename?: 'DayOfWeekBand' }
            | { readonly __typename?: 'EmailVerifiedRequired' }
            | { readonly __typename?: 'FirstOrderOnly' }
            | { readonly __typename?: 'IsStackable' }
            | { readonly __typename?: 'Limit' }
            | {
                readonly __typename?: 'LoyaltyBetweenDates';
                readonly startDate: any | null;
                readonly endDate: any | null;
              }
            | { readonly __typename?: 'LoyaltyBetweenTimes' }
            | { readonly __typename?: 'PaymentMethod' }
            | {
                readonly __typename?: 'RequiresAuthentication';
                readonly requiresAuthentication: boolean | null;
              }
            | { readonly __typename?: 'RestaurantGroupRestrictions' }
            | { readonly __typename?: 'ServiceModeRestrictions' }
            | { readonly __typename?: 'SubtotalSpend' }
            | { readonly __typename?: 'TierList' }
            | { readonly __typename?: 'TotalSpend' }
            | { readonly __typename?: 'TransactionCount' }
            | {
                readonly __typename?: 'UserAttributes';
                readonly userAttributesItem: ReadonlyArray<{
                  readonly __typename?: 'UserAttributesItem';
                  readonly attributeItem: string | null;
                  readonly useAttributeBatteryLevelFilter: string | null;
                  readonly useAttributeBatteryLevelValue: number | null;
                  readonly userAttributeBooleanValue: string | null;
                  readonly userAttributeStringFilter: string | null;
                  readonly userAttributeStringValue: string | null;
                } | null> | null;
              }
            | { readonly __typename?: 'UserCreated' }
            | null
          > | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureLayoutQueryVariables = Exact<{
  featureLayoutId: Scalars['ID']['input'];
}>;

export type IFeatureLayoutQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureLayout: {
    readonly __typename?: 'FeatureLayout';
    readonly _id: string;
    readonly defaultHeroImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly recentOrderHeroText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly loadingGraphics: {
      readonly __typename?: 'LoadingGraphics';
      readonly cartInitialLoader: {
        readonly __typename?: 'Loader';
        readonly desktopGraphic: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
        readonly mobileGraphic: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
    readonly geolocationModalImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly appleWalletImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly googlePayImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly giftCardImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly giftCardTextImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyLinkPhysicalCardContentQueryVariables = Exact<{
  featureLinkPhysicalCardUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyLinkPhysicalCardContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly LinkPhysicalCardUi: {
    readonly __typename?: 'LinkPhysicalCardUi';
    readonly _id: string;
    readonly linkCardSectionLinkedState: {
      readonly __typename?: 'LinkCardSection';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardSectionUnlinkedState: {
      readonly __typename?: 'LinkCardSection';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardFaqSection: {
      readonly __typename?: 'LinkCardFaqSection';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly marketingTileList: {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly linkCardActivationSuccessModal: {
      readonly __typename?: 'LinkCardActivationSuccessModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly downloadAppButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly downloadAppUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardActivationWithRewardSuccessModal: {
      readonly __typename?: 'LinkCardActivationSuccessModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly downloadAppButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly downloadAppUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardErrorModalGeneric: {
      readonly __typename?: 'LinkCardErrorModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardErrorModalLinked: {
      readonly __typename?: 'LinkCardErrorModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardErrorModalReplace: {
      readonly __typename?: 'LinkCardErrorModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly linkCardDeactivationModal: {
      readonly __typename?: 'LinkCardDefaultModal';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly content: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly closeButtonLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLocationTermsQueryVariables = Exact<{
  featureLocationTermsId: Scalars['ID']['input'];
}>;

export type IFeatureLocationTermsQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureGeolocationTermsModal: {
    readonly __typename?: 'FeatureGeolocationTermsModal';
    readonly _id: string;
    readonly geolocationTermsModalTitle: string | null;
    readonly geolocationTermsContent: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
  } | null;
};

export type IFeatureLockersQueryVariables = Exact<{
  featureLockersId: Scalars['ID']['input'];
}>;

export type IFeatureLockersQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureLockers: {
    readonly __typename?: 'FeatureLockers';
    readonly lockersPinInstructions: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly lockersTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly lockersModalImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly lockersDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly lockersCodeTitle: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly lockersViewOrderButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly lockersHowToUseLockersButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyBackgroundQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyBackgroundQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly imageBackground: {
      readonly __typename?: 'ImageBackground';
      readonly image: {
        readonly __typename?: 'Image';
        readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyDashboardQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyDashboardQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyDashboard: {
      readonly __typename?: 'LoyaltyDashboard';
      readonly widgets: ReadonlyArray<
        | { readonly __typename: 'FeaturedRewardsCarouselWidget' }
        | {
            readonly __typename: 'LoyaltyDoubleButtonWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly leftButtonText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly leftButtonPath: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly rightButtonText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly rightButtonPath: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename: 'LoyaltyGreetingWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
          }
        | {
            readonly __typename: 'LoyaltyHistoryWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
          }
        | {
            readonly __typename: 'LoyaltyLinkWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly links: ReadonlyArray<{
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null> | null;
          }
        | {
            readonly __typename: 'LoyaltyLogoWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
          }
        | {
            readonly __typename: 'LoyaltyMarketingTileGroupWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly disclaimer: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly marketingTileGroup: {
              readonly __typename?: 'MarketingTileGroup';
              readonly _id: string;
              readonly tiles: ReadonlyArray<
                | {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'MarketingTileList';
                    readonly _type: string | null;
                    readonly tiles: ReadonlyArray<{
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'MarketingTilePair';
                    readonly left: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly right: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileQuad';
                    readonly topLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly topRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  }
                | null
              > | null;
            } | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename: 'LoyaltyNotificationsWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly notifications: ReadonlyArray<
              | {
                  readonly __typename?: 'Reward';
                  readonly loyaltyEngineId: string | null;
                  readonly notificationDetails: {
                    readonly __typename?: 'NotificationDetails';
                    readonly enabled: boolean | null;
                    readonly title: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'LocaleImage';
                      readonly locale: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly path: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'SystemwideOffer';
                  readonly loyaltyEngineId: string | null;
                  readonly redemptionType: string | null;
                  readonly notificationDetails: {
                    readonly __typename?: 'NotificationDetails';
                    readonly enabled: boolean | null;
                    readonly title: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'LocaleImage';
                      readonly locale: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly path: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'SystemwidePromotion';
                  readonly loyaltyEngineId: string | null;
                  readonly notificationDetails: {
                    readonly __typename?: 'NotificationDetails';
                    readonly enabled: boolean | null;
                    readonly title: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'LocaleImage';
                      readonly locale: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly path: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          }
        | {
            readonly __typename: 'LoyaltyPointsAndRewardsWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
          }
        | {
            readonly __typename: 'LoyaltyPointsMeterWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly imageBackground: {
              readonly __typename?: 'ImageBackground';
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly ctaButton: {
              readonly __typename?: 'ActionButton';
              readonly buttonPath: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly buttonText: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename: 'LoyaltyQuestsTileWidget' }
        | {
            readonly __typename: 'LoyaltyQuestsWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename: 'LoyaltyTiersWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly sortByPointValue: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename: 'RewardsListWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type IFeatureEarningCalculationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureEarningCalculationQuery = {
  readonly __typename?: 'RootQuery';
  readonly EarningCalculation: {
    readonly __typename?: 'EarningCalculation';
    readonly _id: string;
    readonly offerRedemptionCooldownPeriod: number | null;
    readonly enableLoyaltyTiers: boolean | null;
    readonly offerRedemptionLimits: {
      readonly __typename?: 'OfferRedemptionLimits';
      readonly sameOfferLimit: number | null;
      readonly standardOffersLimit: number | null;
    } | null;
    readonly rewardsRedemptionConfig: {
      readonly __typename?: 'RewardsRedemptionConfig';
      readonly standardRewardsLimit: number | null;
    } | null;
    readonly loyaltyTiersEntranceBehaviorConfigurations: ReadonlyArray<{
      readonly __typename?: 'LoyaltyTierEntranceBehaviorConfiguration';
      readonly loyaltyTierKey: string | null;
      readonly pointsNeeded: number | null;
      readonly pointsNeededToMaintain: number | null;
      readonly pointsToEnterCalculationTimeInterval: number | null;
      readonly pointsToEnterCalculationTimeUnit: string | null;
      readonly pointsToMaintainCalculationTimeInterval: number | null;
      readonly pointsToMaintainCalculationTimeUnit: string | null;
    } | null> | null;
  } | null;
};

export type IAllLoyaltyExternalOffersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IAllLoyaltyExternalOffersQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyLiveExternalOffers: {
    readonly __typename?: 'LoyaltyLiveExternalOffers';
    readonly _id: string;
    readonly liveExternalOffers: ReadonlyArray<{
      readonly __typename?: 'ExternalOffers';
      readonly _id: string;
      readonly testOnly: boolean | null;
      readonly authentificationRequired: boolean | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly buttonsConfiguration: {
        readonly __typename?: 'ExternalOffersButtonsConfiguration';
        readonly firstButton: {
          readonly __typename?: 'ActionButton';
          readonly buttonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly buttonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly secondButton: {
          readonly __typename?: 'ActionButtonNotRequired';
          readonly buttonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly buttonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
      readonly unauthenticatedUsersContent: {
        readonly __typename?: 'ExternalOffersUnauthenticatedUsersContent';
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly externalOfferModal: {
        readonly __typename?: 'ExternalOffersModalContent';
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly tierIncentiveList: ReadonlyArray<{
        readonly __typename?: 'TierIncentiveList';
        readonly tierName: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ILoyaltyExternalOffersEmptyStatesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyExternalOffersEmptyStatesQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyLiveExternalOffers: {
    readonly __typename?: 'LoyaltyLiveExternalOffers';
    readonly _id: string;
    readonly externalOffersEmptyState: {
      readonly __typename?: 'ExternalOffersEmptyStateContent';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateImageContent: {
        readonly __typename?: 'ExternalOffersEmptyStateImage';
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly ctaButtonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly ctaButtonLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyFeaturedRewardsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyFeaturedRewardsQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly featuredRewards: {
      readonly __typename?: 'RewardsSection';
      readonly _id: string;
      readonly rewardsList: ReadonlyArray<{
        readonly __typename?: 'FeaturedReward';
        readonly reward: {
          readonly __typename?: 'Reward';
          readonly _id: string;
          readonly testOnly: boolean | null;
          readonly loyaltyEngineId: string | null;
          readonly incentiveNotInMenu: boolean | null;
          readonly mobileOrderOnly: boolean | null;
          readonly redemptionMethod: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly locale: any | null;
          } | null;
          readonly ruleSet: ReadonlyArray<
            | { readonly __typename?: 'BirthdayMultiplier' }
            | { readonly __typename?: 'CartLimit' }
            | { readonly __typename?: 'CartRequirement' }
            | { readonly __typename?: 'CartRequirementExclusion' }
            | { readonly __typename?: 'CoolDown' }
            | { readonly __typename?: 'DayOfWeekBand' }
            | { readonly __typename?: 'EmailVerifiedRequired' }
            | { readonly __typename?: 'FirstOrderOnly' }
            | { readonly __typename?: 'Limit' }
            | { readonly __typename?: 'LoyaltyBetweenDates' }
            | { readonly __typename?: 'LoyaltyBetweenTimes' }
            | { readonly __typename?: 'LoyaltyPoints' }
            | { readonly __typename?: 'PaymentMethod' }
            | { readonly __typename?: 'RequiresAuthentication' }
            | { readonly __typename?: 'RestaurantGroupRestrictions' }
            | {
                readonly __typename?: 'RewardPrice';
                readonly price: number | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'ServiceModeRestrictions' }
            | { readonly __typename?: 'SubtotalSpend' }
            | { readonly __typename?: 'TierList' }
            | { readonly __typename?: 'TotalSpend' }
            | { readonly __typename?: 'TransactionCount' }
            | { readonly __typename?: 'UserAttributes' }
            | { readonly __typename?: 'UserCreated' }
            | null
          > | null;
          readonly moreInfo: {
            readonly __typename?: 'LocaleBlockContent';
            readonly enRaw: any | null;
          } | null;
          readonly howToRedeem: {
            readonly __typename?: 'LocaleBlockContent';
            readonly enRaw: any | null;
          } | null;
          readonly backgroundImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly incentives: ReadonlyArray<
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'OfferDiscount';
                readonly _id: string;
                readonly _type: string;
                readonly discountValue: number | null;
                readonly discountType: string | null;
                readonly discountProduct:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              }
            | {
                readonly __typename: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'PickerOption';
                  readonly option:
                    | {
                        readonly __typename: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | {
                        readonly __typename: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | null;
                } | null> | null;
              }
            | null
          > | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly category: {
          readonly __typename?: 'RewardCategory';
          readonly label: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyInRestaurantRedemptionQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyInRestaurantRedemptionQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyInRestaurantRedemption: {
      readonly __typename?: 'LoyaltyInRestaurantRedemption';
      readonly topWidgets: ReadonlyArray<
        | {
            readonly __typename?: 'LoyaltyBasicCardWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly linkedCard: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly unlinkedCard: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyButtonArrayWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly buttons: ReadonlyArray<{
              readonly __typename?: 'LoyaltyButtonLinkTextWidget';
              readonly _key: string | null;
              readonly componentKey: string | null;
              readonly type: string | null;
              readonly link: {
                readonly __typename?: 'LinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
            } | null> | null;
          }
        | {
            readonly __typename?: 'LoyaltyButtonWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyCartWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly loyaltyEmptyCartTileWidget: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyImageWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyLegacyShortCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly instructions: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyMarketingTileGroupWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly marketingTileGroup: {
              readonly __typename?: 'MarketingTileGroup';
              readonly _id: string;
              readonly tiles: ReadonlyArray<
                | {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'MarketingTileList';
                    readonly _type: string | null;
                    readonly tiles: ReadonlyArray<{
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'MarketingTilePair';
                    readonly left: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly right: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileQuad';
                    readonly topLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly topRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  }
                | null
              > | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyPaymentMethodWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyQRAndShortCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly shortCodeAlwaysDisplayGenerateButton: boolean | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly shortCodeText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly qrAndShortCodeHowToEarnModalContent: {
              readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly details: {
                readonly __typename?: 'LocaleBlockContent';
                readonly locale: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly buttonCtaLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly qrAndShortCodeErrorModalContent: {
              readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
              readonly _key: string | null;
              readonly title: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly details: {
                readonly __typename?: 'LocaleBlockContent';
                readonly locale: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly buttonCtaLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | { readonly __typename?: 'LoyaltyQRCodeLoyaltyIdWidget' }
        | {
            readonly __typename?: 'LoyaltyQRCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyQRScanButtonWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyQRScanHeaderWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyShortCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly defaultCode: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly alternativeTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyTextWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyTitleWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null
      > | null;
      readonly bottomWidgets: ReadonlyArray<
        | {
            readonly __typename?: 'LoyaltyBasicCardWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly linkedCard: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly unlinkedCard: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyButtonArrayWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly buttons: ReadonlyArray<{
              readonly __typename?: 'LoyaltyButtonLinkTextWidget';
              readonly _key: string | null;
              readonly componentKey: string | null;
              readonly type: string | null;
              readonly link: {
                readonly __typename?: 'LinkWidget';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly text: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
            } | null> | null;
          }
        | {
            readonly __typename?: 'LoyaltyButtonWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyCartWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly loyaltyEmptyCartTileWidget: {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyImageWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyLegacyShortCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly instructions: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyMarketingTileGroupWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly marketingTileGroup: {
              readonly __typename?: 'MarketingTileGroup';
              readonly _id: string;
              readonly tiles: ReadonlyArray<
                | {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'MarketingTileList';
                    readonly _type: string | null;
                    readonly tiles: ReadonlyArray<{
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'MarketingTilePair';
                    readonly left: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly right: {
                      readonly __typename?: 'MarketingTile';
                      readonly _id: string;
                      readonly textAlignment: string | null;
                      readonly buttonAlignment: string | null;
                      readonly textColor: string | null;
                      readonly lockedAspectRatio: boolean | null;
                      readonly backgroundImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly buttonAction: {
                        readonly __typename?: 'LinkAction';
                        readonly route: string | null;
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly textBlock: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsText: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly termsButton: {
                        readonly __typename?: 'LinkAction';
                        readonly actionText: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly actionUrl: {
                          readonly __typename?: 'LocaleUrl';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly ruleSet: {
                        readonly __typename?: 'MarketingTileRuleSet';
                        readonly BKPayAudience: string | null;
                        readonly bkSpecificTargeting: boolean | null;
                        readonly dayOfWeek: {
                          readonly __typename?: 'DayOfWeekBand';
                          readonly monday: boolean | null;
                          readonly tuesday: boolean | null;
                          readonly wednesday: boolean | null;
                          readonly thursday: boolean | null;
                          readonly friday: boolean | null;
                          readonly saturday: boolean | null;
                          readonly sunday: boolean | null;
                        } | null;
                        readonly betweenTimes: {
                          readonly __typename?: 'BetweenTimes';
                          readonly startTime: string | null;
                          readonly endTime: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'MarketingTileQuad';
                    readonly topLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly topRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomLeftTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly bottomRightTile: {
                      readonly __typename?: 'MarketingTileBasic';
                      readonly _id: string;
                      readonly marketingBasicTileDescription: {
                        readonly __typename?: 'LocaleTypographyBlock';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly marketingBasicTileImage: {
                        readonly __typename?: 'LocaleImages';
                        readonly locale: {
                          readonly __typename?: 'Images';
                          readonly imageDescription: string | null;
                          readonly app: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly marketingBasicTileTitle: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly link: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                      readonly linkLabel: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  }
                | null
              > | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyPaymentMethodWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | { readonly __typename?: 'LoyaltyQRAndShortCodeWidget' }
        | { readonly __typename?: 'LoyaltyQRCodeLoyaltyIdWidget' }
        | {
            readonly __typename?: 'LoyaltyQRCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyQRScanButtonWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyQRScanHeaderWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expirationMessage: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LinkWidget';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly text: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyShortCodeWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly defaultCode: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly alternativeTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subtitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyTextWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'LoyaltyTitleWidget';
            readonly _key: string | null;
            readonly componentKey: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyUiNoRewardQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyUiNoRewardQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly noRewardPoints: {
      readonly __typename?: 'EmptyState';
      readonly _id: string;
      readonly headerText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly logo: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'ImageBackground';
        readonly image: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
      } | null;
      readonly marketingTileList: {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
      readonly buttonAction: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly terms: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null;
    readonly noRewardsAvailable: {
      readonly __typename?: 'EmptyState';
      readonly _id: string;
      readonly headerText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly logo: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'ImageBackground';
        readonly image: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
      } | null;
      readonly marketingTileList: {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
      readonly buttonAction: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly terms: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyNotAuthenticatedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyNotAuthenticatedQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyNotAuthenticated: {
      readonly __typename?: 'LoyaltyNotAuthenticated';
      readonly loyaltySignupTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltySignupCells: ReadonlyArray<{
        readonly __typename?: 'LoyaltyNotAuthenticatedCell';
        readonly loyaltySignupCellImage: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly loyaltySignupCellTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltySignupCellDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
      readonly loyaltySignupButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltySignupLegalText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyNotAvailableAtDeliveryModeQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyNotAvailableAtDeliveryMode: {
      readonly __typename?: 'LoyaltyNotAvailableAtDeliveryMode';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstRedirectPath: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyNotAvailableAtThisRestaurantQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyNotAvailableAtThisRestaurant: {
      readonly __typename?: 'LoyaltyNotAvailableAtThisRestaurant';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstRedirectPath: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondRedirectPath: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyUiNotEnrolledQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyUiNotEnrolledQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly notEnrolledState: {
      readonly __typename?: 'EmptyState';
      readonly _id: string;
      readonly headerText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly logo: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'ImageBackground';
        readonly image: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
      } | null;
      readonly marketingTileList: {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
      readonly buttonAction: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly terms: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null;
    readonly notEnrolledStateMobile: {
      readonly __typename?: 'EmptyState';
      readonly _id: string;
      readonly headerText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly logo: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'ImageBackground';
        readonly image: {
          readonly __typename?: 'Image';
          readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
        } | null;
      } | null;
      readonly marketingTileList: {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly subtitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
      readonly buttonAction: {
        readonly __typename?: 'LinkAction';
        readonly route: string | null;
        readonly actionText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionUrl: {
          readonly __typename?: 'LocaleUrl';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly terms: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyOffersUiIncentiveNotAvailableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiIncentiveNotAvailableQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly offerNotAvailableModalContent: {
      readonly __typename?: 'IncentiveNotAvailableModalContent';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly primaryButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly secondaryButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
    readonly rewardNotAvailableModalContent: {
      readonly __typename?: 'IncentiveNotAvailableModalContent';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly primaryButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly secondaryButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureSortedLoyaltyOffersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IFeatureSortedLoyaltyOffersQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly sortedSystemwideOffers: ReadonlyArray<{
      readonly __typename?: 'SystemwideOffer';
      readonly _id: string;
      readonly _type: string;
      readonly testOnly: boolean | null;
      readonly loyaltyEngineId: string | null;
      readonly shortCode: string | null;
      readonly mobileOrderOnly: boolean | null;
      readonly redemptionMethod: string | null;
      readonly daypart: ReadonlyArray<string | null> | null;
      readonly redemptionType: string | null;
      readonly offerPrice: number | null;
      readonly uiPattern: string | null;
      readonly isUpcomingOffer: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly moreInfo: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly howToRedeem: {
        readonly __typename?: 'LocaleBlockContent';
        readonly enRaw: any | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly upsellOptions: ReadonlyArray<{
        readonly __typename?: 'SystemwideOffer';
        readonly _id: string;
        readonly loyaltyEngineId: string | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly localizedImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null> | null;
      readonly marketPrice:
        | {
            readonly __typename?: 'Combo';
            readonly _id: string;
            readonly _type: string;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly lockedOffersPanel: {
        readonly __typename?: 'LockedOffersPanel';
        readonly completedChallengeHeader: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly completedChallengeDescription: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly promoCodePanel: {
        readonly __typename?: 'PromoCodePanel';
        readonly promoCodeLink: string | null;
        readonly promoCodeDescription: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly promoCodeLabel: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly incentives: ReadonlyArray<
        | {
            readonly __typename: 'Combo';
            readonly _id: string;
            readonly _type: string;
            readonly isOfferBenefit: boolean | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
        | {
            readonly __typename: 'OfferDiscount';
            readonly _id: string;
            readonly _type: string;
            readonly discountValue: number | null;
            readonly discountType: string | null;
            readonly discountProduct:
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          }
        | {
            readonly __typename: 'Picker';
            readonly _id: string;
            readonly _type: string;
            readonly isOfferBenefit: boolean | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'PickerOption';
              readonly option:
                | {
                    readonly __typename: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | null;
            } | null> | null;
          }
        | { readonly __typename: 'SwapMapping'; readonly _type: string }
        | null
      > | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly rules: ReadonlyArray<
        | { readonly __typename?: 'BirthdayMultiplier' }
        | { readonly __typename?: 'CartLimit' }
        | { readonly __typename?: 'CartRequirement' }
        | { readonly __typename?: 'CartRequirementExclusion' }
        | { readonly __typename?: 'CoolDown' }
        | { readonly __typename?: 'DayOfWeekBand' }
        | { readonly __typename?: 'EmailVerifiedRequired' }
        | { readonly __typename?: 'FirstOrderOnly' }
        | { readonly __typename?: 'IsStackable' }
        | { readonly __typename?: 'Limit' }
        | {
            readonly __typename?: 'LoyaltyBetweenDates';
            readonly startDate: any | null;
            readonly endDate: any | null;
          }
        | { readonly __typename?: 'LoyaltyBetweenTimes' }
        | { readonly __typename?: 'PaymentMethod' }
        | {
            readonly __typename?: 'RequiresAuthentication';
            readonly requiresAuthentication: boolean | null;
          }
        | { readonly __typename?: 'RestaurantGroupRestrictions' }
        | { readonly __typename?: 'ServiceModeRestrictions' }
        | { readonly __typename?: 'SubtotalSpend' }
        | { readonly __typename?: 'TierList' }
        | { readonly __typename?: 'TotalSpend' }
        | { readonly __typename?: 'TransactionCount' }
        | {
            readonly __typename?: 'UserAttributes';
            readonly userAttributesItem: ReadonlyArray<{
              readonly __typename?: 'UserAttributesItem';
              readonly attributeItem: string | null;
              readonly useAttributeBatteryLevelFilter: string | null;
              readonly useAttributeBatteryLevelValue: number | null;
              readonly userAttributeBooleanValue: string | null;
              readonly userAttributeStringFilter: string | null;
              readonly userAttributeStringValue: string | null;
            } | null> | null;
          }
        | { readonly __typename?: 'UserCreated' }
        | null
      > | null;
    } | null> | null;
  } | null;
};

export type ILoyaltyOffersUiLoggedInQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiLoggedInQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly endOfOffersLoggedIn: {
      readonly __typename?: 'EndOfOffersLoggedIn';
      readonly endOfOffersMessage: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly endOfOffersImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyOffersUiLoggedOutQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiLoggedOutQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly endOfOffersLoggedOut: {
      readonly __typename?: 'EndOfOffersLoggedOut';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly body: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
  } | null;
};

export type ILoyaltyOffersUiCooldownContentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiCooldownContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly globalOfferCooldownContent: {
      readonly __typename?: 'GlobalOfferCooldownContent';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly localeRaw: string | null;
      } | null;
      readonly actionButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly terms: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyOffersUiEnableOfferBadgesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiEnableOfferBadgesQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly enableOfferBadges: boolean | null;
  } | null;
};

export type ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiEnableGreyedOutOffersQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly enableGreyedOutOffers: boolean | null;
  } | null;
};

export type ILoyaltyOffersUiOfferMarketingTilesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersUiOfferMarketingTilesQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly loyaltyOffersPageMarketingTiles: ReadonlyArray<{
      readonly __typename?: 'LoyaltyOffersPageMarketingTile';
      readonly displayPosition: string | null;
      readonly customPosition: number | null;
      readonly marketingTileReference: {
        readonly __typename?: 'MarketingTile';
        readonly _id: string;
        readonly textAlignment: string | null;
        readonly buttonAlignment: string | null;
        readonly textColor: string | null;
        readonly lockedAspectRatio: boolean | null;
        readonly backgroundImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly buttonAction: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly textBlock: {
          readonly __typename?: 'LocaleTypographyBlock';
          readonly localeRaw: any | null;
        } | null;
        readonly termsText: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly termsButton: {
          readonly __typename?: 'LinkAction';
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly ruleSet: {
          readonly __typename?: 'MarketingTileRuleSet';
          readonly BKPayAudience: string | null;
          readonly bkSpecificTargeting: boolean | null;
          readonly dayOfWeek: {
            readonly __typename?: 'DayOfWeekBand';
            readonly monday: boolean | null;
            readonly tuesday: boolean | null;
            readonly wednesday: boolean | null;
            readonly thursday: boolean | null;
            readonly friday: boolean | null;
            readonly saturday: boolean | null;
            readonly sunday: boolean | null;
          } | null;
          readonly betweenTimes: {
            readonly __typename?: 'BetweenTimes';
            readonly startTime: string | null;
            readonly endTime: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ILoyaltyOffersEmptyStatesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ILoyaltyOffersEmptyStatesQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyOffersUI: {
    readonly __typename?: 'LoyaltyOffersUI';
    readonly _id: string;
    readonly offersEmptyStates: {
      readonly __typename?: 'OffersEmptyStates';
      readonly emptyStateOffersTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateOffersDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateOffersImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly ctaButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateFavoriteOffersTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateFavoriteOffersDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly emptyStateFavoriteOffersImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly ctaButtonEmptyStateFavoriteOffersText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly ctaButtonEmptyStateFavoriteOffersLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyOffersQueryVariables = Exact<{ [key: string]: never }>;

export type IFeatureLoyaltyOffersQuery = {
  readonly __typename?: 'RootQuery';
  readonly allSystemwideOffer: ReadonlyArray<{
    readonly __typename?: 'SystemwideOffer';
    readonly _id: string;
    readonly _type: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly shortCode: string | null;
    readonly mobileOrderOnly: boolean | null;
    readonly redemptionMethod: string | null;
    readonly daypart: ReadonlyArray<string | null> | null;
    readonly redemptionType: string | null;
    readonly offerPrice: number | null;
    readonly uiPattern: string | null;
    readonly isUpcomingOffer: boolean | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly upsellOptions: ReadonlyArray<{
      readonly __typename?: 'SystemwideOffer';
      readonly _id: string;
      readonly loyaltyEngineId: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly marketPrice:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly lockedOffersPanel: {
      readonly __typename?: 'LockedOffersPanel';
      readonly completedChallengeHeader: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly completedChallengeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly promoCodePanel: {
      readonly __typename?: 'PromoCodePanel';
      readonly promoCodeLink: string | null;
      readonly promoCodeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly promoCodeLabel: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | { readonly __typename: 'SwapMapping'; readonly _type: string }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly rules: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'IsStackable' }
      | { readonly __typename?: 'Limit' }
      | {
          readonly __typename?: 'LoyaltyBetweenDates';
          readonly startDate: any | null;
          readonly endDate: any | null;
        }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'PaymentMethod' }
      | {
          readonly __typename?: 'RequiresAuthentication';
          readonly requiresAuthentication: boolean | null;
        }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | {
          readonly __typename?: 'UserAttributes';
          readonly userAttributesItem: ReadonlyArray<{
            readonly __typename?: 'UserAttributesItem';
            readonly attributeItem: string | null;
            readonly useAttributeBatteryLevelFilter: string | null;
            readonly useAttributeBatteryLevelValue: number | null;
            readonly userAttributeBooleanValue: string | null;
            readonly userAttributeStringFilter: string | null;
            readonly userAttributeStringValue: string | null;
          } | null> | null;
        }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
  }>;
};

export type ILoyaltySystemwideOffersByEngineIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ILoyaltySystemwideOffersByEngineIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly allSystemwideOffer: ReadonlyArray<{
    readonly __typename?: 'SystemwideOffer';
    readonly _id: string;
    readonly _type: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly shortCode: string | null;
    readonly mobileOrderOnly: boolean | null;
    readonly redemptionMethod: string | null;
    readonly daypart: ReadonlyArray<string | null> | null;
    readonly redemptionType: string | null;
    readonly offerPrice: number | null;
    readonly uiPattern: string | null;
    readonly isUpcomingOffer: boolean | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly upsellOptions: ReadonlyArray<{
      readonly __typename?: 'SystemwideOffer';
      readonly _id: string;
      readonly loyaltyEngineId: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly marketPrice:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly lockedOffersPanel: {
      readonly __typename?: 'LockedOffersPanel';
      readonly completedChallengeHeader: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly completedChallengeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly promoCodePanel: {
      readonly __typename?: 'PromoCodePanel';
      readonly promoCodeLink: string | null;
      readonly promoCodeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly promoCodeLabel: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | { readonly __typename: 'SwapMapping'; readonly _type: string }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly rules: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'IsStackable' }
      | { readonly __typename?: 'Limit' }
      | {
          readonly __typename?: 'LoyaltyBetweenDates';
          readonly startDate: any | null;
          readonly endDate: any | null;
        }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'PaymentMethod' }
      | {
          readonly __typename?: 'RequiresAuthentication';
          readonly requiresAuthentication: boolean | null;
        }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | {
          readonly __typename?: 'UserAttributes';
          readonly userAttributesItem: ReadonlyArray<{
            readonly __typename?: 'UserAttributesItem';
            readonly attributeItem: string | null;
            readonly useAttributeBatteryLevelFilter: string | null;
            readonly useAttributeBatteryLevelValue: number | null;
            readonly userAttributeBooleanValue: string | null;
            readonly userAttributeStringFilter: string | null;
            readonly userAttributeStringValue: string | null;
          } | null> | null;
        }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
  }>;
};

export type ILoyaltySystemwideOffersByIdsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type ILoyaltySystemwideOffersByIdsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allSystemwideOffer: ReadonlyArray<{
    readonly __typename?: 'SystemwideOffer';
    readonly _id: string;
    readonly _type: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly shortCode: string | null;
    readonly mobileOrderOnly: boolean | null;
    readonly redemptionMethod: string | null;
    readonly daypart: ReadonlyArray<string | null> | null;
    readonly redemptionType: string | null;
    readonly offerPrice: number | null;
    readonly uiPattern: string | null;
    readonly isUpcomingOffer: boolean | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly upsellOptions: ReadonlyArray<{
      readonly __typename?: 'SystemwideOffer';
      readonly _id: string;
      readonly loyaltyEngineId: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly marketPrice:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly lockedOffersPanel: {
      readonly __typename?: 'LockedOffersPanel';
      readonly completedChallengeHeader: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly completedChallengeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly promoCodePanel: {
      readonly __typename?: 'PromoCodePanel';
      readonly promoCodeLink: string | null;
      readonly promoCodeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly promoCodeLabel: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | { readonly __typename: 'SwapMapping'; readonly _type: string }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly rules: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'IsStackable' }
      | { readonly __typename?: 'Limit' }
      | {
          readonly __typename?: 'LoyaltyBetweenDates';
          readonly startDate: any | null;
          readonly endDate: any | null;
        }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'PaymentMethod' }
      | {
          readonly __typename?: 'RequiresAuthentication';
          readonly requiresAuthentication: boolean | null;
        }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | {
          readonly __typename?: 'UserAttributes';
          readonly userAttributesItem: ReadonlyArray<{
            readonly __typename?: 'UserAttributesItem';
            readonly attributeItem: string | null;
            readonly useAttributeBatteryLevelFilter: string | null;
            readonly useAttributeBatteryLevelValue: number | null;
            readonly userAttributeBooleanValue: string | null;
            readonly userAttributeStringFilter: string | null;
            readonly userAttributeStringValue: string | null;
          } | null> | null;
        }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
  }>;
};

export type ILoyaltyOfferConfigsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ILoyaltyOfferConfigsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allConfigOffer: ReadonlyArray<{
    readonly __typename?: 'ConfigOffer';
    readonly _id: string;
    readonly _type: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly redemptionMethod: string | null;
    readonly shortCode: string | null;
    readonly mobileOrderOnly: boolean | null;
    readonly daypart: ReadonlyArray<string | null> | null;
    readonly redemptionType: string | null;
    readonly offerPrice: number | null;
    readonly uiPattern: string | null;
    readonly isUpcomingOffer: boolean | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly upsellOptions: ReadonlyArray<{
      readonly __typename?: 'SystemwideOffer';
      readonly _id: string;
      readonly loyaltyEngineId: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly marketPrice:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly lockedOffersPanel: {
      readonly __typename?: 'LockedOffersPanel';
      readonly completedChallengeHeader: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly completedChallengeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly promoCodePanel: {
      readonly __typename?: 'PromoCodePanel';
      readonly promoCodeLink: string | null;
      readonly promoCodeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly promoCodeLabel: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly isOfferBenefit: boolean | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | { readonly __typename: 'SwapMapping'; readonly _type: string }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly rules: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'IsStackable' }
      | { readonly __typename?: 'Limit' }
      | {
          readonly __typename?: 'LoyaltyBetweenDates';
          readonly startDate: any | null;
          readonly endDate: any | null;
        }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'PaymentMethod' }
      | {
          readonly __typename?: 'RequiresAuthentication';
          readonly requiresAuthentication: boolean | null;
        }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | {
          readonly __typename?: 'UserAttributes';
          readonly userAttributesItem: ReadonlyArray<{
            readonly __typename?: 'UserAttributesItem';
            readonly attributeItem: string | null;
            readonly useAttributeBatteryLevelFilter: string | null;
            readonly useAttributeBatteryLevelValue: number | null;
            readonly userAttributeBooleanValue: string | null;
            readonly userAttributeStringFilter: string | null;
            readonly userAttributeStringValue: string | null;
          } | null> | null;
        }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
  }>;
};

export type ILoyaltyOfferTemplatesQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ILoyaltyOfferTemplatesQuery = {
  readonly __typename?: 'RootQuery';
  readonly allOfferTemplate: ReadonlyArray<{
    readonly __typename?: 'OfferTemplate';
    readonly _id: string;
    readonly _type: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly name: {
      readonly __typename?: 'LocaleSmartBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleSmartBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  }>;
};

export type IFeatureLoyaltyUiOptInModalQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyUiOptInModalQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly optInModal: {
      readonly __typename?: 'OptInModalContent';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly blockText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly optInModalError: {
      readonly __typename?: 'OptInModalContent';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly blockText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly optInModalLoading: {
      readonly __typename?: 'OptInModalLoading';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables = Exact<{
  featureLoyaltyRewardListCategoriesId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyRewardListCategoriesOnlyQuery = {
  readonly __typename?: 'RootQuery';
  readonly RewardList: {
    readonly __typename?: 'RewardList';
    readonly _id: string;
    readonly rewardCategories: ReadonlyArray<{
      readonly __typename?: 'RewardCategory';
      readonly _id: string;
      readonly label: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureLoyaltyRewardListCategoriesQueryVariables = Exact<{
  featureLoyaltyRewardListCategoriesId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyRewardListCategoriesQuery = {
  readonly __typename?: 'RootQuery';
  readonly RewardList: {
    readonly __typename?: 'RewardList';
    readonly _id: string;
    readonly rewardCategories: ReadonlyArray<{
      readonly __typename?: 'RewardCategory';
      readonly label: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly rewards: ReadonlyArray<{
        readonly __typename?: 'Reward';
        readonly _id: string;
        readonly loyaltyEngineId: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type IFeatureLoyaltyShortCodeConfirmationModalQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyShortCodeConfirmationModalQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly shortCodeConfirmationModalContent: {
      readonly __typename?: 'ShortCodeConfirmationModalContent';
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly body: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionButton: {
        readonly __typename?: 'ActionButton';
        readonly buttonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly buttonPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly dismissButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyOverrideShortCodeModalContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly overrideShortCodeModalContent: {
      readonly __typename?: 'OverrideShortCodeModalContent';
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly body: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly confirmButton: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly discardButton: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltySurpriseModalQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltySurpriseModalQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly surpriseAndDelightModalContent: {
      readonly __typename?: 'SurpriseAndDelightModalContent';
      readonly backgroundImage: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly buttonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly noThanksText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly prompt: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly termsText: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyPointsFragment = {
  readonly __typename?: 'LoyaltyPoints';
  readonly points: number | null;
  readonly ruleSetType: string | null;
};

export type ITierListFragment = {
  readonly __typename?: 'TierList';
  readonly tierList: string | null;
  readonly ruleSetType: string | null;
};

export type IFeatureLoyaltyTiersContentQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyTiersContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly loyaltyTiersContent: {
      readonly __typename?: 'LoyaltyTiersContent';
      readonly loyaltyTiersUnauthenticatedCells: ReadonlyArray<{
        readonly __typename?: 'LoyaltyTiersUnauthenticatedCell';
        readonly loyaltyTiersSignupCellImage: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly loyaltyTiersSignupCellTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyTiersSignupCellDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
      readonly loyaltyTiersHowItWorksButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersHowItWorksButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersRewardsButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersRewardsButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersRewardsHistoryButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersRewardsHistoryButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersOrderMoreButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersOrderMoreButtonLink: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly loyaltyTiersUIConfig: ReadonlyArray<{
        readonly __typename?: 'LoyaltyTiersUIConfigCell';
        readonly loyaltyTiersUIConfigID: string | null;
        readonly loyaltyTiersUIConfigName: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyTiersUIConfigIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly loyaltyTiersModalImage: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly loyaltyTiersModalTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyTiersModalDescription: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly loyaltyTiersModalMainBtnLabel: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyTiersModalMainBtnLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyTiersCarouselRewards: ReadonlyArray<{
          readonly __typename?: 'Reward';
          readonly _id: string;
          readonly loyaltyEngineId: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly locale: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly ruleSet: ReadonlyArray<
            | { readonly __typename?: 'BirthdayMultiplier' }
            | { readonly __typename?: 'CartLimit' }
            | { readonly __typename?: 'CartRequirement' }
            | { readonly __typename?: 'CartRequirementExclusion' }
            | { readonly __typename?: 'CoolDown' }
            | { readonly __typename?: 'DayOfWeekBand' }
            | { readonly __typename?: 'EmailVerifiedRequired' }
            | { readonly __typename?: 'FirstOrderOnly' }
            | { readonly __typename?: 'Limit' }
            | { readonly __typename?: 'LoyaltyBetweenDates' }
            | { readonly __typename?: 'LoyaltyBetweenTimes' }
            | {
                readonly __typename?: 'LoyaltyPoints';
                readonly points: number | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'PaymentMethod' }
            | { readonly __typename?: 'RequiresAuthentication' }
            | { readonly __typename?: 'RestaurantGroupRestrictions' }
            | { readonly __typename?: 'RewardPrice' }
            | { readonly __typename?: 'ServiceModeRestrictions' }
            | { readonly __typename?: 'SubtotalSpend' }
            | {
                readonly __typename?: 'TierList';
                readonly tierList: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'TotalSpend' }
            | { readonly __typename?: 'TransactionCount' }
            | { readonly __typename?: 'UserAttributes' }
            | { readonly __typename?: 'UserCreated' }
            | null
          > | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IFeatureLoyaltyRewardListsQueryVariables = Exact<{
  where?: InputMaybe<IRewardListFilter>;
}>;

export type IFeatureLoyaltyRewardListsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allRewardList: ReadonlyArray<{
    readonly __typename?: 'RewardList';
    readonly _id: string;
    readonly rewardListLoyaltyTier: string | null;
    readonly rewardCategories: ReadonlyArray<{
      readonly __typename?: 'RewardCategory';
      readonly label: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly rewards: ReadonlyArray<{
        readonly __typename?: 'Reward';
        readonly _id: string;
        readonly loyaltyEngineId: string | null;
      } | null> | null;
    } | null> | null;
  }>;
};

export type IFeatureLoyaltyUiQueryVariables = Exact<{
  featureLoyaltyUIId: Scalars['ID']['input'];
}>;

export type IFeatureLoyaltyUiQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyUI: {
    readonly __typename?: 'LoyaltyUI';
    readonly _id: string;
    readonly rewardsLogo: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly rewardsLogoInverted: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly loyaltyCTAButtons: {
      readonly __typename?: 'LoyaltyCTAButtons';
      readonly loyaltyBannerHomepageAuthenticatedButton: {
        readonly __typename?: 'LoyaltyCTAButtonAuthenticated';
        readonly loyaltyBannerHomepageAuthenticatedLabel: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyBannerHomepageAuthenticatedLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly loyaltyBannerHomepageUnauthenticatedButton: {
        readonly __typename?: 'LoyaltyCTAButtonUnauthenticated';
        readonly loyaltyBannerHomepageUnauthenticatedLabel: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyBannerHomepageUnauthenticatedLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly loyaltyBannerRewardsButton: {
        readonly __typename?: 'LoyaltyCTAButtonRewards';
        readonly loyaltyBannerRewardsLabel: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly loyaltyBannerRewardsLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
    readonly rewardsLearnMoreIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly rewardsProgressBarIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IFeatureMembershipsQueryVariables = Exact<{
  featureMembershipsId: Scalars['ID']['input'];
}>;

export type IFeatureMembershipsQuery = {
  readonly __typename?: 'RootQuery';
  readonly LiveMemberships: {
    readonly __typename?: 'LiveMemberships';
    readonly _id: string;
    readonly liveMemberships: ReadonlyArray<{
      readonly __typename?: 'Memberships';
      readonly _id: string;
      readonly testOnly: boolean | null;
      readonly membershipInternalName: string | null;
      readonly membershipName: string | null;
      readonly membershipBenefit: {
        readonly __typename?: 'MembershipsBenefit';
        readonly offer: {
          readonly __typename?: 'ConfigOffer';
          readonly _id: string;
          readonly _type: string;
          readonly testOnly: boolean | null;
          readonly loyaltyEngineId: string | null;
          readonly redemptionMethod: string | null;
          readonly shortCode: string | null;
          readonly mobileOrderOnly: boolean | null;
          readonly daypart: ReadonlyArray<string | null> | null;
          readonly redemptionType: string | null;
          readonly offerPrice: number | null;
          readonly uiPattern: string | null;
          readonly isUpcomingOffer: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly moreInfo: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly howToRedeem: {
            readonly __typename?: 'LocaleBlockContent';
            readonly enRaw: any | null;
          } | null;
          readonly backgroundImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly upsellOptions: ReadonlyArray<{
            readonly __typename?: 'SystemwideOffer';
            readonly _id: string;
            readonly loyaltyEngineId: string | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly localizedImage: {
              readonly __typename?: 'LocaleImages';
              readonly locale: {
                readonly __typename?: 'Images';
                readonly imageDescription: string | null;
                readonly app: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null> | null;
          readonly marketPrice:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
          readonly localizedImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly lockedOffersPanel: {
            readonly __typename?: 'LockedOffersPanel';
            readonly completedChallengeHeader: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly completedChallengeDescription: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null;
          readonly promoCodePanel: {
            readonly __typename?: 'PromoCodePanel';
            readonly promoCodeLink: string | null;
            readonly promoCodeDescription: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly promoCodeLabel: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
          } | null;
          readonly incentives: ReadonlyArray<
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly isOfferBenefit: boolean | null;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'OfferActivation';
                readonly _id: string;
                readonly _type: string;
              }
            | {
                readonly __typename: 'OfferDiscount';
                readonly _id: string;
                readonly _type: string;
                readonly discountValue: number | null;
                readonly discountType: string | null;
                readonly discountProduct:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              }
            | {
                readonly __typename: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly isOfferBenefit: boolean | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'PickerOption';
                  readonly option:
                    | {
                        readonly __typename: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | {
                        readonly __typename: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | null;
                } | null> | null;
              }
            | { readonly __typename: 'SwapMapping'; readonly _type: string }
            | null
          > | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly rules: ReadonlyArray<
            | { readonly __typename?: 'BirthdayMultiplier' }
            | { readonly __typename?: 'CartLimit' }
            | { readonly __typename?: 'CartRequirement' }
            | { readonly __typename?: 'CartRequirementExclusion' }
            | { readonly __typename?: 'CoolDown' }
            | { readonly __typename?: 'DayOfWeekBand' }
            | { readonly __typename?: 'EmailVerifiedRequired' }
            | { readonly __typename?: 'FirstOrderOnly' }
            | { readonly __typename?: 'IsStackable' }
            | { readonly __typename?: 'Limit' }
            | {
                readonly __typename?: 'LoyaltyBetweenDates';
                readonly startDate: any | null;
                readonly endDate: any | null;
              }
            | { readonly __typename?: 'LoyaltyBetweenTimes' }
            | { readonly __typename?: 'PaymentMethod' }
            | {
                readonly __typename?: 'RequiresAuthentication';
                readonly requiresAuthentication: boolean | null;
              }
            | { readonly __typename?: 'RestaurantGroupRestrictions' }
            | { readonly __typename?: 'ServiceModeRestrictions' }
            | { readonly __typename?: 'SubtotalSpend' }
            | { readonly __typename?: 'TierList' }
            | { readonly __typename?: 'TotalSpend' }
            | { readonly __typename?: 'TransactionCount' }
            | {
                readonly __typename?: 'UserAttributes';
                readonly userAttributesItem: ReadonlyArray<{
                  readonly __typename?: 'UserAttributesItem';
                  readonly attributeItem: string | null;
                  readonly useAttributeBatteryLevelFilter: string | null;
                  readonly useAttributeBatteryLevelValue: number | null;
                  readonly userAttributeBooleanValue: string | null;
                  readonly userAttributeStringFilter: string | null;
                  readonly userAttributeStringValue: string | null;
                } | null> | null;
              }
            | { readonly __typename?: 'UserCreated' }
            | null
          > | null;
        } | null;
      } | null;
      readonly membershipModalsContent: {
        readonly __typename?: 'MembershipsModalsContent';
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly membershipActivationSuccess: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly membershipCardRemoval: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly membershipAccountLinkFailure: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly membershipBenefitActivationPending: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly membershipExpired: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly membershipOfferAssignmentFailure: {
          readonly __typename?: 'MembershipsModalsContentDetails';
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly firstButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly firstButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonPath: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly secondButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
      readonly membershipIntegrationTypeContent: {
        readonly __typename?: 'MembershipsIntegrationTypeContent';
        readonly membershipIntegrationType: string | null;
        readonly externalIntegrationPath: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly externalIntegrationText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly membershipPageConfiguration: {
        readonly __typename?: 'MembershipsPageStyleContent';
        readonly informationWidgets: {
          readonly __typename?: 'MembershipsInformationWidgets';
          readonly widgets: ReadonlyArray<{
            readonly __typename?: 'MembershipsWidgetContent';
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly locale: any | null;
            } | null;
            readonly widgetImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly widgetBackgroundColor: {
              readonly __typename?: 'Color';
              readonly alpha: number | null;
              readonly hex: string | null;
              readonly hsl: {
                readonly __typename?: 'HslaColor';
                readonly _type: string | null;
                readonly a: number | null;
                readonly h: number | null;
                readonly l: number | null;
                readonly s: number | null;
              } | null;
              readonly hsv: {
                readonly __typename?: 'HsvaColor';
                readonly _type: string | null;
                readonly a: number | null;
                readonly h: number | null;
                readonly s: number | null;
                readonly v: number | null;
              } | null;
              readonly rgb: {
                readonly __typename?: 'RgbaColor';
                readonly _type: string | null;
                readonly a: number | null;
                readonly b: number | null;
                readonly g: number | null;
                readonly r: number | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly membershipPageStyle: {
          readonly __typename?: 'MembershipsPageStyle';
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly actionButtonText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly actionButtonLink: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly membershipInternalPageConfiguration: {
        readonly __typename?: 'MembershipInternalPageConfiguration';
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureMenuQueryVariables = Exact<{
  featureMenuId: Scalars['ID']['input'];
}>;

export type IFeatureMenuQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureMenu: {
    readonly __typename?: 'FeatureMenu';
    readonly _id: string;
    readonly enableDynamicPickerImages: boolean | null;
    readonly additionalDetails: {
      readonly __typename?: 'AdditionalDetails';
      readonly additionalDetailsList: ReadonlyArray<{
        readonly __typename?: 'AdditionalDetailsList';
        readonly key: string | null;
        readonly regex: string | null;
        readonly required: boolean | null;
        readonly isPii: boolean | null;
        readonly validationError: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly menuHeroImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly menuHeroText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly dayParts: ReadonlyArray<{
      readonly __typename?: 'DayPart';
      readonly key: string | null;
      readonly endTime: string | null;
      readonly startTime: string | null;
      readonly weekDays: {
        readonly __typename?: 'WeekDays';
        readonly monday: boolean | null;
        readonly tuesday: boolean | null;
        readonly wednesday: boolean | null;
        readonly thursday: boolean | null;
        readonly friday: boolean | null;
        readonly saturday: boolean | null;
        readonly sunday: boolean | null;
      } | null;
      readonly displayName: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly icon: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly defaultMenu: { readonly __typename?: 'Menu'; readonly _id: string } | null;
    readonly cateringMenu: { readonly __typename?: 'Menu'; readonly _id: string } | null;
    readonly donationTermAndConditionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly donationItemsAtCheckout: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null> | null;
    readonly upsellItemsAtCheckoutRestaurant: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null> | null;
    readonly upsellItemsAtCheckoutDelivery: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null> | null;
    readonly upsellItemsAtCheckoutCatering: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null> | null;
    readonly cartAddOnSections: ReadonlyArray<{
      readonly __typename?: 'AddOnSection';
      readonly _id: string;
      readonly _key: string | null;
      readonly maxAmount: number | null;
      readonly showSectionItemsOnCart: boolean | null;
      readonly enableAddonAsFreeItem: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'AddOnSectionOption';
        readonly _key: string | null;
        readonly maxAmount: number | null;
        readonly option: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly showInStaticMenu: boolean | null;
          readonly hideCalories: boolean | null;
          readonly hideNutritionLegalDisclaimer: boolean | null;
          readonly itemSize: number | null;
          readonly itemUnit: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly freeOption: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly showInStaticMenu: boolean | null;
          readonly hideCalories: boolean | null;
          readonly hideNutritionLegalDisclaimer: boolean | null;
          readonly itemSize: number | null;
          readonly itemUnit: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly itemsAcceptingFreeOffers: ReadonlyArray<{
          readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
          readonly _key: string | null;
          readonly maxFreeQuantity: number | null;
          readonly itemAcceptingFreeOffer:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | null;
        } | null> | null;
        readonly addOnSectionFreeModalInfos: {
          readonly __typename?: 'AddOnSectionFreeModalFieldset';
          readonly showFreeAddOnModal: boolean | null;
          readonly image: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly primaryButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null> | null;
      readonly sectionItemsServiceModes: {
        readonly __typename?: 'AddOnSectionServiceModes';
        readonly pickUpServiceMode: boolean | null;
        readonly driveThruServiceMode: boolean | null;
        readonly curbsideServiceMode: boolean | null;
        readonly dineInServiceMode: boolean | null;
        readonly tableServiceMode: boolean | null;
        readonly deliveryServiceMode: boolean | null;
        readonly cateringDeliveryServiceMode: boolean | null;
        readonly cateringPickUpServiceMode: boolean | null;
      } | null;
    } | null> | null;
    readonly cartAddOnSectionsCatering: ReadonlyArray<{
      readonly __typename?: 'AddOnSection';
      readonly _id: string;
      readonly _key: string | null;
      readonly maxAmount: number | null;
      readonly showSectionItemsOnCart: boolean | null;
      readonly enableAddonAsFreeItem: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'AddOnSectionOption';
        readonly _key: string | null;
        readonly maxAmount: number | null;
        readonly option: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly showInStaticMenu: boolean | null;
          readonly hideCalories: boolean | null;
          readonly hideNutritionLegalDisclaimer: boolean | null;
          readonly itemSize: number | null;
          readonly itemUnit: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly freeOption: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly showInStaticMenu: boolean | null;
          readonly hideCalories: boolean | null;
          readonly hideNutritionLegalDisclaimer: boolean | null;
          readonly itemSize: number | null;
          readonly itemUnit: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly itemsAcceptingFreeOffers: ReadonlyArray<{
          readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
          readonly _key: string | null;
          readonly maxFreeQuantity: number | null;
          readonly itemAcceptingFreeOffer:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | null;
        } | null> | null;
        readonly addOnSectionFreeModalInfos: {
          readonly __typename?: 'AddOnSectionFreeModalFieldset';
          readonly showFreeAddOnModal: boolean | null;
          readonly image: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly primaryButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null> | null;
      readonly sectionItemsServiceModes: {
        readonly __typename?: 'AddOnSectionServiceModes';
        readonly pickUpServiceMode: boolean | null;
        readonly driveThruServiceMode: boolean | null;
        readonly curbsideServiceMode: boolean | null;
        readonly dineInServiceMode: boolean | null;
        readonly tableServiceMode: boolean | null;
        readonly deliveryServiceMode: boolean | null;
        readonly cateringDeliveryServiceMode: boolean | null;
        readonly cateringPickUpServiceMode: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IFeatureNavigationQueryVariables = Exact<{
  featureNavigationId: Scalars['ID']['input'];
}>;

export type IFeatureNavigationQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureNavigation: {
    readonly __typename?: 'FeatureNavigation';
    readonly aboutMenuEntries: {
      readonly __typename?: 'NavigationSection';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pages: ReadonlyArray<
        | {
            readonly __typename?: 'NavigationSection';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly pages: ReadonlyArray<
              | { readonly __typename?: 'NavigationSection' }
              | {
                  readonly __typename?: 'StaticPage';
                  readonly title: string | null;
                  readonly localeTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly path: {
                    readonly __typename?: 'Slug';
                    readonly current: string | null;
                  } | null;
                  readonly localePath: {
                    readonly __typename?: 'LocaleSlug';
                    readonly locale: {
                      readonly __typename?: 'Slug';
                      readonly current: string | null;
                    } | null;
                  } | null;
                  readonly redirectUrl: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | {
            readonly __typename?: 'StaticPage';
            readonly title: string | null;
            readonly localeTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
            readonly localePath: {
              readonly __typename?: 'LocaleSlug';
              readonly locale: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
            } | null;
            readonly redirectUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null
      > | null;
    } | null;
    readonly navigationDesktop: {
      readonly __typename?: 'NavigationDesktop';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly navigationLinks: ReadonlyArray<{
        readonly __typename?: 'NavigationDesktopLink';
        readonly _key: string | null;
        readonly hideLinkBasedOnLdFlag: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly navLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly launchDarklyAlternateNavigationLink: {
          readonly __typename?: 'LaunchDarklyNavigationLink';
          readonly enabled: boolean | null;
          readonly launchDarklyFlagDependency: {
            readonly __typename?: 'LaunchDarklySelect';
            readonly launchDarklyFlag: string | null;
          } | null;
          readonly navLink: {
            readonly __typename?: 'LinkWidget';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly navigationLinksWithServiceModeOrStoreSelected: ReadonlyArray<{
        readonly __typename?: 'NavigationDesktopLink';
        readonly _key: string | null;
        readonly hideLinkBasedOnLdFlag: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly navLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly launchDarklyAlternateNavigationLink: {
          readonly __typename?: 'LaunchDarklyNavigationLink';
          readonly enabled: boolean | null;
          readonly launchDarklyFlagDependency: {
            readonly __typename?: 'LaunchDarklySelect';
            readonly launchDarklyFlag: string | null;
          } | null;
          readonly navLink: {
            readonly __typename?: 'LinkWidget';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly aboutMenuEntries: {
        readonly __typename?: 'NavigationSection';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly pages: ReadonlyArray<
          | {
              readonly __typename?: 'NavigationSection';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly pages: ReadonlyArray<
                | { readonly __typename?: 'NavigationSection' }
                | {
                    readonly __typename?: 'StaticPage';
                    readonly title: string | null;
                    readonly localeTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly path: {
                      readonly __typename?: 'Slug';
                      readonly current: string | null;
                    } | null;
                    readonly localePath: {
                      readonly __typename?: 'LocaleSlug';
                      readonly locale: {
                        readonly __typename?: 'Slug';
                        readonly current: string | null;
                      } | null;
                    } | null;
                    readonly redirectUrl: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | {
              readonly __typename?: 'StaticPage';
              readonly title: string | null;
              readonly localeTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly path: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
              readonly localePath: {
                readonly __typename?: 'LocaleSlug';
                readonly locale: {
                  readonly __typename?: 'Slug';
                  readonly current: string | null;
                } | null;
              } | null;
              readonly redirectUrl: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    readonly navigationMobile: {
      readonly __typename?: 'NavigationMobile';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly navigationTabs: ReadonlyArray<{
        readonly __typename?: 'NavigationMobileTab';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly hideLinkBasedOnLdFlag: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly tabIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabIconActive: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly launchDarklyAlternateMobileTab: {
          readonly __typename?: 'LaunchDarklyNavigationTab';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly enabled: boolean | null;
          readonly launchDarklyFlagDependency: {
            readonly __typename?: 'LaunchDarklySelect';
            readonly launchDarklyFlag: string | null;
          } | null;
          readonly tabIcon: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly tabIconActive: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly tabLink: {
            readonly __typename?: 'LinkWidget';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly navigationTabsWithServiceModeSelected: ReadonlyArray<{
        readonly __typename?: 'NavigationMobileTab';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly hideLinkBasedOnLdFlag: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly tabIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabIconActive: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly launchDarklyAlternateMobileTab: {
          readonly __typename?: 'LaunchDarklyNavigationTab';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly enabled: boolean | null;
          readonly launchDarklyFlagDependency: {
            readonly __typename?: 'LaunchDarklySelect';
            readonly launchDarklyFlag: string | null;
          } | null;
          readonly tabIcon: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly tabIconActive: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly tabLink: {
            readonly __typename?: 'LinkWidget';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly text: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly aboutMenuEntries: {
        readonly __typename?: 'NavigationSection';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly pages: ReadonlyArray<
          | {
              readonly __typename?: 'NavigationSection';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly pages: ReadonlyArray<
                | { readonly __typename?: 'NavigationSection' }
                | {
                    readonly __typename?: 'StaticPage';
                    readonly title: string | null;
                    readonly localeTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly path: {
                      readonly __typename?: 'Slug';
                      readonly current: string | null;
                    } | null;
                    readonly localePath: {
                      readonly __typename?: 'LocaleSlug';
                      readonly locale: {
                        readonly __typename?: 'Slug';
                        readonly current: string | null;
                      } | null;
                    } | null;
                    readonly redirectUrl: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | {
              readonly __typename?: 'StaticPage';
              readonly title: string | null;
              readonly localeTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly path: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
              readonly localePath: {
                readonly __typename?: 'LocaleSlug';
                readonly locale: {
                  readonly __typename?: 'Slug';
                  readonly current: string | null;
                } | null;
              } | null;
              readonly redirectUrl: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | null
        > | null;
      } | null;
    } | null;
    readonly navigationMobileHeaderButton: {
      readonly __typename?: 'NavigationMobileHeaderButton';
      readonly _key: string | null;
      readonly icon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly iconActive: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
  } | null;
};

export type IFeatureNutritionQueryVariables = Exact<{
  featureNutritionId: Scalars['ID']['input'];
}>;

export type IFeatureNutritionQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureNutrition: {
    readonly __typename?: 'FeatureNutrition';
    readonly _id: string;
    readonly nutritionTables: ReadonlyArray<{
      readonly __typename?: 'NutritionTable';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly items: ReadonlyArray<
        | {
            readonly __typename?: 'NutritionItem';
            readonly _type: string | null;
            readonly nutritionKey: string | null;
            readonly omitUnitFromValue: boolean | null;
            readonly additionalItems: ReadonlyArray<{
              readonly __typename?: 'NutritionItem';
              readonly nutritionKey: string | null;
              readonly omitUnitFromValue: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly unit: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null> | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly unit: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'NutritionItemGroup';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly mainItem: {
              readonly __typename?: 'NutritionItem';
              readonly _type: string | null;
              readonly nutritionKey: string | null;
              readonly omitUnitFromValue: boolean | null;
              readonly additionalItems: ReadonlyArray<{
                readonly __typename?: 'NutritionItem';
                readonly nutritionKey: string | null;
                readonly omitUnitFromValue: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly unit: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly unit: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly lineItems: ReadonlyArray<{
              readonly __typename?: 'NutritionItem';
              readonly _type: string | null;
              readonly nutritionKey: string | null;
              readonly omitUnitFromValue: boolean | null;
              readonly additionalItems: ReadonlyArray<{
                readonly __typename?: 'NutritionItem';
                readonly nutritionKey: string | null;
                readonly omitUnitFromValue: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly unit: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly unit: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null> | null;
          }
        | null
      > | null;
    } | null> | null;
    readonly roundingRules: {
      readonly __typename?: 'RoundingRules';
      readonly enabled: boolean | null;
      readonly decimalPlaces: number | null;
      readonly roundingType: string | null;
    } | null;
  } | null;
};

export type IFeatureQrCodeRefillDrinksQueryVariables = Exact<{
  featureQRCodeRefillDrinksId: Scalars['ID']['input'];
}>;

export type IFeatureQrCodeRefillDrinksQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureQRCodeRefillDrinks: {
    readonly __typename?: 'FeatureQRCodeRefillDrinks';
    readonly _id: string;
    readonly refillDrinksServiceMode: {
      readonly __typename?: 'RefillDrinksServiceMode';
      readonly cateringDeliveryServiceMode: boolean | null;
      readonly cateringPickUpServiceMode: boolean | null;
      readonly curbsideServiceMode: boolean | null;
      readonly deliveryServiceMode: boolean | null;
      readonly dineInServiceMode: boolean | null;
      readonly driveThruServiceMode: boolean | null;
      readonly pickUpServiceMode: boolean | null;
      readonly tableServiceMode: boolean | null;
    } | null;
    readonly refillDrinksTimers: {
      readonly __typename?: 'RefillDrinksTimers';
      readonly timeBottomSheet: number | null;
      readonly timeRecentOrder: number | null;
    } | null;
    readonly restaurants: ReadonlyArray<{
      readonly __typename?: 'Restaurant';
      readonly _id: string;
    } | null> | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Item';
      readonly _id: string;
    } | null> | null;
  } | null;
};

export type IFeatureStaticMenuQueryVariables = Exact<{
  featureStaticMenuId: Scalars['ID']['input'];
}>;

export type IFeatureStaticMenuQuery = {
  readonly __typename?: 'RootQuery';
  readonly FeatureStaticMenu: {
    readonly __typename?: 'FeatureStaticMenu';
    readonly itemUnavailableModal: {
      readonly __typename?: 'ItemUnavailableModal';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly header: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly body: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly continue: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly selectNew: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly itemNotAvailableForDelivery: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type INavigationSectionFragment = {
  readonly __typename?: 'NavigationSection';
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly pages: ReadonlyArray<
    | {
        readonly __typename?: 'NavigationSection';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly pages: ReadonlyArray<
          | { readonly __typename?: 'NavigationSection' }
          | {
              readonly __typename?: 'StaticPage';
              readonly title: string | null;
              readonly localeTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly path: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
              readonly localePath: {
                readonly __typename?: 'LocaleSlug';
                readonly locale: {
                  readonly __typename?: 'Slug';
                  readonly current: string | null;
                } | null;
              } | null;
              readonly redirectUrl: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'StaticPage';
        readonly title: string | null;
        readonly localeTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
        readonly localePath: {
          readonly __typename?: 'LocaleSlug';
          readonly locale: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
        } | null;
        readonly redirectUrl: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | null
  > | null;
};

export type IActiveCampaignFragment = {
  readonly __typename?: 'FeatureAuthCampaign';
  readonly campaignId: string | null;
  readonly shouldDisplayExtraFields: boolean | null;
  readonly campaignImage: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly campaignImageAltText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly campaingBackgroundColor: {
    readonly __typename?: 'Color';
    readonly alpha: number | null;
    readonly hex: string | null;
    readonly hsl: {
      readonly __typename?: 'HslaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly l: number | null;
      readonly s: number | null;
    } | null;
    readonly hsv: {
      readonly __typename?: 'HsvaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly s: number | null;
      readonly v: number | null;
    } | null;
    readonly rgb: {
      readonly __typename?: 'RgbaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly b: number | null;
      readonly g: number | null;
      readonly r: number | null;
    } | null;
  } | null;
  readonly campaignText: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
};

export type IAddOnSectionFreeModalInfosFragment = {
  readonly __typename?: 'AddOnSectionFreeModalFieldset';
  readonly showFreeAddOnModal: boolean | null;
  readonly image: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly primaryButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IAddOnSectionItemAccepetingFreeFragment = {
  readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
  readonly _key: string | null;
  readonly maxFreeQuantity: number | null;
  readonly itemAcceptingFreeOffer:
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | null;
};

export type IAddOnSectionOptionFragment = {
  readonly __typename?: 'AddOnSectionOption';
  readonly _key: string | null;
  readonly maxAmount: number | null;
  readonly option: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly itemSize: number | null;
    readonly itemUnit: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly freeOption: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly itemSize: number | null;
    readonly itemUnit: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly itemsAcceptingFreeOffers: ReadonlyArray<{
    readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
    readonly _key: string | null;
    readonly maxFreeQuantity: number | null;
    readonly itemAcceptingFreeOffer:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null;
  } | null> | null;
  readonly addOnSectionFreeModalInfos: {
    readonly __typename?: 'AddOnSectionFreeModalFieldset';
    readonly showFreeAddOnModal: boolean | null;
    readonly image: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly primaryButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IAddOnSectionServiceModesFragment = {
  readonly __typename?: 'AddOnSectionServiceModes';
  readonly pickUpServiceMode: boolean | null;
  readonly driveThruServiceMode: boolean | null;
  readonly curbsideServiceMode: boolean | null;
  readonly dineInServiceMode: boolean | null;
  readonly tableServiceMode: boolean | null;
  readonly deliveryServiceMode: boolean | null;
  readonly cateringDeliveryServiceMode: boolean | null;
  readonly cateringPickUpServiceMode: boolean | null;
};

export type IAddOnSectionFragment = {
  readonly __typename?: 'AddOnSection';
  readonly _id: string;
  readonly _key: string | null;
  readonly maxAmount: number | null;
  readonly showSectionItemsOnCart: boolean | null;
  readonly enableAddonAsFreeItem: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'AddOnSectionOption';
    readonly _key: string | null;
    readonly maxAmount: number | null;
    readonly option: {
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly showInStaticMenu: boolean | null;
      readonly hideCalories: boolean | null;
      readonly hideNutritionLegalDisclaimer: boolean | null;
      readonly itemSize: number | null;
      readonly itemUnit: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
      readonly operationalItem: {
        readonly __typename?: 'OpItem';
        readonly daypart: ReadonlyArray<string | null> | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly freeOption: {
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly showInStaticMenu: boolean | null;
      readonly hideCalories: boolean | null;
      readonly hideNutritionLegalDisclaimer: boolean | null;
      readonly itemSize: number | null;
      readonly itemUnit: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
      readonly operationalItem: {
        readonly __typename?: 'OpItem';
        readonly daypart: ReadonlyArray<string | null> | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly itemsAcceptingFreeOffers: ReadonlyArray<{
      readonly __typename?: 'AddOnSectionItemAcceptingFreeOffer';
      readonly _key: string | null;
      readonly maxFreeQuantity: number | null;
      readonly itemAcceptingFreeOffer:
        | {
            readonly __typename?: 'Combo';
            readonly _id: string;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null;
    } | null> | null;
    readonly addOnSectionFreeModalInfos: {
      readonly __typename?: 'AddOnSectionFreeModalFieldset';
      readonly showFreeAddOnModal: boolean | null;
      readonly image: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly primaryButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly sectionItemsServiceModes: {
    readonly __typename?: 'AddOnSectionServiceModes';
    readonly pickUpServiceMode: boolean | null;
    readonly driveThruServiceMode: boolean | null;
    readonly curbsideServiceMode: boolean | null;
    readonly dineInServiceMode: boolean | null;
    readonly tableServiceMode: boolean | null;
    readonly deliveryServiceMode: boolean | null;
    readonly cateringDeliveryServiceMode: boolean | null;
    readonly cateringPickUpServiceMode: boolean | null;
  } | null;
};

export type IAdditionalItemInformationFragment = {
  readonly __typename?: 'AdditionalItemInformation';
  readonly sourcesOfGluten: string | null;
  readonly ingredients: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly additives: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly producerDetails: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IAggregatorRedirectButtonFragment = {
  readonly __typename?: 'AggregatorRedirectButton';
  readonly isActive: boolean | null;
  readonly name: string | null;
  readonly url: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type IAlertFrangmentFragment = {
  readonly __typename?: 'Alert';
  readonly _id: string;
  readonly heading: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly learnMoreText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly learnMoreUrl: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IAllergensFragment = {
  readonly __typename?: 'OpAllergen';
  readonly milk: number | null;
  readonly eggs: number | null;
  readonly fish: number | null;
  readonly peanuts: number | null;
  readonly shellfish: number | null;
  readonly treeNuts: number | null;
  readonly soy: number | null;
  readonly wheat: number | null;
  readonly mustard: number | null;
  readonly sesame: number | null;
  readonly celery: number | null;
  readonly lupin: number | null;
  readonly gluten: number | null;
  readonly sulphurDioxide: number | null;
};

export type IAppDownloadFragment = {
  readonly __typename?: 'AppDownload';
  readonly _id: string;
  readonly details: {
    readonly __typename?: 'AppDownloadSection';
    readonly iOSDownloadLink: string | null;
    readonly androidDownloadLink: string | null;
    readonly huaweiDownloadLink: string | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly primaryImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly headerSuperText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly copy: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly textDecorationLeft: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly textDecorationRight: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly submitButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly iOSDownloadImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly androidDownloadImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly huaweiDownloadImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IBrazeContentCardsFragment = {
  readonly __typename?: 'BrazeContentCards';
  readonly _id: string;
  readonly contentCardsHeader: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly contentCardsSubheader: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IColorFragment = {
  readonly __typename?: 'Color';
  readonly alpha: number | null;
  readonly hex: string | null;
  readonly hsl: {
    readonly __typename?: 'HslaColor';
    readonly _type: string | null;
    readonly a: number | null;
    readonly h: number | null;
    readonly l: number | null;
    readonly s: number | null;
  } | null;
  readonly hsv: {
    readonly __typename?: 'HsvaColor';
    readonly _type: string | null;
    readonly a: number | null;
    readonly h: number | null;
    readonly s: number | null;
    readonly v: number | null;
  } | null;
  readonly rgb: {
    readonly __typename?: 'RgbaColor';
    readonly _type: string | null;
    readonly a: number | null;
    readonly b: number | null;
    readonly g: number | null;
    readonly r: number | null;
  } | null;
};

export type IComboAvailabilityFragment = {
  readonly __typename?: 'Combo';
  readonly showInStaticMenu: boolean | null;
  readonly mainItem: {
    readonly __typename?: 'Item';
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly itemSize: number | null;
    readonly itemUnit: string | null;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly injectDefaultSelection: boolean | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly default: boolean | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ComboSlot';
    readonly minAmount: number | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlotOption';
      readonly maxAmount: number | null;
      readonly option:
        | {
            readonly __typename?: 'Item';
            readonly showInStaticMenu: boolean | null;
            readonly hideCalories: boolean | null;
            readonly hideNutritionLegalDisclaimer: boolean | null;
            readonly itemSize: number | null;
            readonly itemUnit: string | null;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly injectDefaultSelection: boolean | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null> | null;
          }
        | { readonly __typename?: 'Picker' }
        | { readonly __typename?: 'Section' }
        | null;
    } | null> | null;
  } | null> | null;
};

export type IComboFragment = {
  readonly __typename?: 'Combo';
  readonly _id: string;
  readonly _type: string;
  readonly uiPattern: string | null;
  readonly hideMainItemDescription: boolean | null;
  readonly forceModifiersToBottom: boolean | null;
  readonly isOfferBenefit: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly imagesByChannels: {
    readonly __typename?: 'ImagesByChannels';
    readonly imageRestaurant: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDelivery: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly mainItem: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
  readonly markerItem: {
    readonly __typename?: 'MarkerItem';
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ComboSlot';
    readonly _type: string;
    readonly _id: string;
    readonly uiPattern: string | null;
    readonly minAmount: number | null;
    readonly maxAmount: number | null;
    readonly respectMaximum: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly optionVisibilitySettings: {
      readonly __typename?: 'OptionVisibilitySettings';
      readonly visibleOptions: number | null;
      readonly toggleButtonTextClosed: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly toggleButtonTextOpen: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlotOption';
      readonly _key: string | null;
      readonly minAmount: number | null;
      readonly defaultAmount: number | null;
      readonly isPremium: boolean | null;
      readonly option:
        | {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          }
        | {
            readonly __typename?: 'Picker';
            readonly _type: string;
            readonly uiPattern: string | null;
            readonly pickerAspects: ReadonlyArray<{
              readonly __typename?: 'PickerAspect';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly pickerAspectOptions: ReadonlyArray<{
                readonly __typename?: 'PickerAspectValue';
                readonly identifier: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            } | null> | null;
          }
        | { readonly __typename?: 'Section' }
        | null;
    } | null> | null;
  } | null> | null;
  readonly menuObjectSettings: {
    readonly __typename?: 'MenuObjectSettings';
    readonly limitPerOrder: number | null;
  } | null;
};

export type IConfigOfferFragment = {
  readonly __typename?: 'ConfigOffer';
  readonly _id: string;
  readonly _type: string;
  readonly testOnly: boolean | null;
  readonly loyaltyEngineId: string | null;
  readonly redemptionMethod: string | null;
  readonly shortCode: string | null;
  readonly mobileOrderOnly: boolean | null;
  readonly daypart: ReadonlyArray<string | null> | null;
  readonly redemptionType: string | null;
  readonly offerPrice: number | null;
  readonly uiPattern: string | null;
  readonly isUpcomingOffer: boolean | null;
  readonly name: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly moreInfo: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly howToRedeem: {
    readonly __typename?: 'LocaleBlockContent';
    readonly enRaw: any | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly upsellOptions: ReadonlyArray<{
    readonly __typename?: 'SystemwideOffer';
    readonly _id: string;
    readonly loyaltyEngineId: string | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null> | null;
  readonly marketPrice:
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | null;
  readonly localizedImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly lockedOffersPanel: {
    readonly __typename?: 'LockedOffersPanel';
    readonly completedChallengeHeader: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly completedChallengeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly promoCodePanel: {
    readonly __typename?: 'PromoCodePanel';
    readonly promoCodeLink: string | null;
    readonly promoCodeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly promoCodeLabel: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly incentives: ReadonlyArray<
    | {
        readonly __typename: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly isOfferBenefit: boolean | null;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly operationalItem: {
          readonly __typename?: 'OpItem';
          readonly daypart: ReadonlyArray<string | null> | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
    | {
        readonly __typename: 'OfferDiscount';
        readonly _id: string;
        readonly _type: string;
        readonly discountValue: number | null;
        readonly discountType: string | null;
        readonly discountProduct:
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | null;
      }
    | {
        readonly __typename: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly isOfferBenefit: boolean | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly option:
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | { readonly __typename: 'SwapMapping'; readonly _type: string }
    | null
  > | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly rules: ReadonlyArray<
    | { readonly __typename?: 'BirthdayMultiplier' }
    | { readonly __typename?: 'CartLimit' }
    | { readonly __typename?: 'CartRequirement' }
    | { readonly __typename?: 'CartRequirementExclusion' }
    | { readonly __typename?: 'CoolDown' }
    | { readonly __typename?: 'DayOfWeekBand' }
    | { readonly __typename?: 'EmailVerifiedRequired' }
    | { readonly __typename?: 'FirstOrderOnly' }
    | { readonly __typename?: 'IsStackable' }
    | { readonly __typename?: 'Limit' }
    | {
        readonly __typename?: 'LoyaltyBetweenDates';
        readonly startDate: any | null;
        readonly endDate: any | null;
      }
    | { readonly __typename?: 'LoyaltyBetweenTimes' }
    | { readonly __typename?: 'PaymentMethod' }
    | {
        readonly __typename?: 'RequiresAuthentication';
        readonly requiresAuthentication: boolean | null;
      }
    | { readonly __typename?: 'RestaurantGroupRestrictions' }
    | { readonly __typename?: 'ServiceModeRestrictions' }
    | { readonly __typename?: 'SubtotalSpend' }
    | { readonly __typename?: 'TierList' }
    | { readonly __typename?: 'TotalSpend' }
    | { readonly __typename?: 'TransactionCount' }
    | {
        readonly __typename?: 'UserAttributes';
        readonly userAttributesItem: ReadonlyArray<{
          readonly __typename?: 'UserAttributesItem';
          readonly attributeItem: string | null;
          readonly useAttributeBatteryLevelFilter: string | null;
          readonly useAttributeBatteryLevelValue: number | null;
          readonly userAttributeBooleanValue: string | null;
          readonly userAttributeStringFilter: string | null;
          readonly userAttributeStringValue: string | null;
        } | null> | null;
      }
    | { readonly __typename?: 'UserCreated' }
    | null
  > | null;
};

export type IEmptyStateFragment = {
  readonly __typename?: 'EmptyState';
  readonly _id: string;
  readonly headerText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly logo: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'ImageBackground';
    readonly image: {
      readonly __typename?: 'Image';
      readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
    } | null;
  } | null;
  readonly marketingTileList: {
    readonly __typename: 'MarketingTileList';
    readonly _type: string | null;
    readonly tiles: ReadonlyArray<{
      readonly __typename?: 'MarketingTileBasic';
      readonly _id: string;
      readonly marketingBasicTileDescription: {
        readonly __typename?: 'LocaleTypographyBlock';
        readonly localeRaw: any | null;
      } | null;
      readonly marketingBasicTileImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly marketingBasicTileTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      readonly linkLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly locale: any | null;
  } | null;
  readonly buttonAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly terms: {
    readonly __typename?: 'LocaleBlockContent';
    readonly locale: any | null;
  } | null;
};

export type IFanFavoritesFragment = {
  readonly __typename?: 'FanFavorites';
  readonly _id: string;
  readonly fanFavoriteItems: ReadonlyArray<
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | { readonly __typename?: 'Offer' }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'Section';
        readonly _id: string;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | null
  > | null;
  readonly favoriteSection: {
    readonly __typename?: 'FavoriteSection';
    readonly headerText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type ICateringPageConfigurationFragment = {
  readonly __typename?: 'CateringPageConfiguration';
  readonly components: ReadonlyArray<
    | {
        readonly __typename?: 'Alert';
        readonly _id: string;
        readonly heading: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly body: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly learnMoreText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly learnMoreUrl: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'AppDownload';
        readonly _id: string;
        readonly details: {
          readonly __typename?: 'AppDownloadSection';
          readonly iOSDownloadLink: string | null;
          readonly androidDownloadLink: string | null;
          readonly huaweiDownloadLink: string | null;
          readonly backgroundImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly primaryImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly headerSuperText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly header: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly copy: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly textDecorationLeft: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly textDecorationRight: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly submitButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly iOSDownloadImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly androidDownloadImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly huaweiDownloadImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'BrazeContentCards';
        readonly _id: string;
        readonly contentCardsHeader: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly contentCardsSubheader: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'FanFavorites';
        readonly _id: string;
        readonly fanFavoriteItems: ReadonlyArray<
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | { readonly __typename?: 'Offer' }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'Section';
              readonly _id: string;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | null
        > | null;
        readonly favoriteSection: {
          readonly __typename?: 'FavoriteSection';
          readonly headerText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      }
    | { readonly __typename?: 'LockedOffers'; readonly _id: string }
    | {
        readonly __typename?: 'MainHero';
        readonly _id: string;
        readonly textColor: string | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly mobileImage: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly superText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly mainText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly subText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly action: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly actionRight: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly actionRightIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly actionLeft: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly actionLeftIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly maskCards: ReadonlyArray<{
          readonly __typename?: 'SimpleCard';
          readonly _key: string | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly loyaltyAction: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'MarketingTileGroup';
        readonly _id: string;
        readonly tiles: ReadonlyArray<
          | {
              readonly __typename?: 'MarketingTile';
              readonly _id: string;
              readonly textAlignment: string | null;
              readonly buttonAlignment: string | null;
              readonly textColor: string | null;
              readonly lockedAspectRatio: boolean | null;
              readonly backgroundImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly buttonAction: {
                readonly __typename?: 'LinkAction';
                readonly route: string | null;
                readonly actionText: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly actionUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly textBlock: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly termsText: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly termsButton: {
                readonly __typename?: 'LinkAction';
                readonly actionText: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly actionUrl: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly ruleSet: {
                readonly __typename?: 'MarketingTileRuleSet';
                readonly BKPayAudience: string | null;
                readonly bkSpecificTargeting: boolean | null;
                readonly dayOfWeek: {
                  readonly __typename?: 'DayOfWeekBand';
                  readonly monday: boolean | null;
                  readonly tuesday: boolean | null;
                  readonly wednesday: boolean | null;
                  readonly thursday: boolean | null;
                  readonly friday: boolean | null;
                  readonly saturday: boolean | null;
                  readonly sunday: boolean | null;
                } | null;
                readonly betweenTimes: {
                  readonly __typename?: 'BetweenTimes';
                  readonly startTime: string | null;
                  readonly endTime: string | null;
                } | null;
              } | null;
            }
          | {
              readonly __typename?: 'MarketingTileBasic';
              readonly _id: string;
              readonly marketingBasicTileDescription: {
                readonly __typename?: 'LocaleTypographyBlock';
                readonly localeRaw: any | null;
              } | null;
              readonly marketingBasicTileImage: {
                readonly __typename?: 'LocaleImages';
                readonly locale: {
                  readonly __typename?: 'Images';
                  readonly imageDescription: string | null;
                  readonly app: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly marketingBasicTileTitle: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
              readonly linkLabel: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename: 'MarketingTileList';
              readonly _type: string | null;
              readonly tiles: ReadonlyArray<{
                readonly __typename?: 'MarketingTileBasic';
                readonly _id: string;
                readonly marketingBasicTileDescription: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly marketingBasicTileImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly marketingBasicTileTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkLabel: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'MarketingTilePair';
              readonly left: {
                readonly __typename?: 'MarketingTile';
                readonly _id: string;
                readonly textAlignment: string | null;
                readonly buttonAlignment: string | null;
                readonly textColor: string | null;
                readonly lockedAspectRatio: boolean | null;
                readonly backgroundImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly buttonAction: {
                  readonly __typename?: 'LinkAction';
                  readonly route: string | null;
                  readonly actionText: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly actionUrl: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly textBlock: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly termsText: {
                  readonly __typename?: 'LocaleBlockContent';
                  readonly localeRaw: any | null;
                } | null;
                readonly termsButton: {
                  readonly __typename?: 'LinkAction';
                  readonly actionText: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly actionUrl: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly ruleSet: {
                  readonly __typename?: 'MarketingTileRuleSet';
                  readonly BKPayAudience: string | null;
                  readonly bkSpecificTargeting: boolean | null;
                  readonly dayOfWeek: {
                    readonly __typename?: 'DayOfWeekBand';
                    readonly monday: boolean | null;
                    readonly tuesday: boolean | null;
                    readonly wednesday: boolean | null;
                    readonly thursday: boolean | null;
                    readonly friday: boolean | null;
                    readonly saturday: boolean | null;
                    readonly sunday: boolean | null;
                  } | null;
                  readonly betweenTimes: {
                    readonly __typename?: 'BetweenTimes';
                    readonly startTime: string | null;
                    readonly endTime: string | null;
                  } | null;
                } | null;
              } | null;
              readonly right: {
                readonly __typename?: 'MarketingTile';
                readonly _id: string;
                readonly textAlignment: string | null;
                readonly buttonAlignment: string | null;
                readonly textColor: string | null;
                readonly lockedAspectRatio: boolean | null;
                readonly backgroundImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly buttonAction: {
                  readonly __typename?: 'LinkAction';
                  readonly route: string | null;
                  readonly actionText: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly actionUrl: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly textBlock: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly termsText: {
                  readonly __typename?: 'LocaleBlockContent';
                  readonly localeRaw: any | null;
                } | null;
                readonly termsButton: {
                  readonly __typename?: 'LinkAction';
                  readonly actionText: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly actionUrl: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly ruleSet: {
                  readonly __typename?: 'MarketingTileRuleSet';
                  readonly BKPayAudience: string | null;
                  readonly bkSpecificTargeting: boolean | null;
                  readonly dayOfWeek: {
                    readonly __typename?: 'DayOfWeekBand';
                    readonly monday: boolean | null;
                    readonly tuesday: boolean | null;
                    readonly wednesday: boolean | null;
                    readonly thursday: boolean | null;
                    readonly friday: boolean | null;
                    readonly saturday: boolean | null;
                    readonly sunday: boolean | null;
                  } | null;
                  readonly betweenTimes: {
                    readonly __typename?: 'BetweenTimes';
                    readonly startTime: string | null;
                    readonly endTime: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              readonly __typename?: 'MarketingTileQuad';
              readonly topLeftTile: {
                readonly __typename?: 'MarketingTileBasic';
                readonly _id: string;
                readonly marketingBasicTileDescription: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly marketingBasicTileImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly marketingBasicTileTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkLabel: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly topRightTile: {
                readonly __typename?: 'MarketingTileBasic';
                readonly _id: string;
                readonly marketingBasicTileDescription: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly marketingBasicTileImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly marketingBasicTileTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkLabel: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly bottomLeftTile: {
                readonly __typename?: 'MarketingTileBasic';
                readonly _id: string;
                readonly marketingBasicTileDescription: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly marketingBasicTileImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly marketingBasicTileTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkLabel: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly bottomRightTile: {
                readonly __typename?: 'MarketingTileBasic';
                readonly _id: string;
                readonly marketingBasicTileDescription: {
                  readonly __typename?: 'LocaleTypographyBlock';
                  readonly localeRaw: any | null;
                } | null;
                readonly marketingBasicTileImage: {
                  readonly __typename?: 'LocaleImages';
                  readonly locale: {
                    readonly __typename?: 'Images';
                    readonly imageDescription: string | null;
                    readonly app: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
                readonly marketingBasicTileTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly link: {
                  readonly __typename?: 'LocaleUrl';
                  readonly locale: string | null;
                } | null;
                readonly linkLabel: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
            }
          | null
        > | null;
      }
    | { readonly __typename?: 'OffersSection'; readonly _id: string }
    | {
        readonly __typename?: 'OurServices';
        readonly _id: string;
        readonly servicesHeaderTextLocale: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly servicesHeroTextLocale: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly ourServicesHeroImageLocale: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | { readonly __typename?: 'RecentItems'; readonly _id: string }
    | {
        readonly __typename?: 'Trending';
        readonly _id: string;
        readonly promotions: ReadonlyArray<
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'ExternalLink';
              readonly _key: string | null;
              readonly url: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly link: {
                readonly __typename?: 'LocaleUrl';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | {
              readonly __typename?: 'Section';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            }
          | null
        > | null;
      }
    | null
  > | null;
};

export type IHomePageConfigurationFragment = {
  readonly __typename?: 'HomePageConfiguration';
  readonly components: ReadonlyArray<
    | { readonly __typename: 'Alert'; readonly _id: string }
    | { readonly __typename: 'AppDownload'; readonly _id: string }
    | { readonly __typename: 'BrazeContentCards'; readonly _id: string }
    | { readonly __typename: 'FanFavorites'; readonly _id: string }
    | { readonly __typename: 'Hero'; readonly _id: string }
    | { readonly __typename?: 'LockedOffers' }
    | { readonly __typename: 'MainHero'; readonly _id: string }
    | { readonly __typename: 'MarketingCardGroup'; readonly _id: string }
    | { readonly __typename: 'MarketingTileCarousel'; readonly _id: string }
    | { readonly __typename: 'MarketingTileGroup'; readonly _id: string }
    | { readonly __typename: 'OfferCarouselSection'; readonly _id: string }
    | { readonly __typename: 'OffersSection'; readonly _id: string }
    | { readonly __typename: 'OurServices'; readonly _id: string }
    | { readonly __typename?: 'QuestsSection' }
    | { readonly __typename: 'RecentItems'; readonly _id: string }
    | { readonly __typename: 'RewardsSection'; readonly _id: string }
    | { readonly __typename?: 'RollUpTheRimSection' }
    | { readonly __typename?: 'ScanForRewards' }
    | { readonly __typename?: 'SimpleHomePage' }
    | { readonly __typename: 'Trending'; readonly _id: string }
    | null
  > | null;
};

export type INavigationDesktopFragment = {
  readonly __typename?: 'FeatureNavigation';
  readonly navigationDesktop: {
    readonly __typename?: 'NavigationDesktop';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly navigationLinks: ReadonlyArray<{
      readonly __typename?: 'NavigationDesktopLink';
      readonly _key: string | null;
      readonly hideLinkBasedOnLdFlag: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly navLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
      readonly launchDarklyAlternateNavigationLink: {
        readonly __typename?: 'LaunchDarklyNavigationLink';
        readonly enabled: boolean | null;
        readonly launchDarklyFlagDependency: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly navLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly navigationLinksWithServiceModeOrStoreSelected: ReadonlyArray<{
      readonly __typename?: 'NavigationDesktopLink';
      readonly _key: string | null;
      readonly hideLinkBasedOnLdFlag: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly navLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
      readonly launchDarklyAlternateNavigationLink: {
        readonly __typename?: 'LaunchDarklyNavigationLink';
        readonly enabled: boolean | null;
        readonly launchDarklyFlagDependency: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly navLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly aboutMenuEntries: {
      readonly __typename?: 'NavigationSection';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pages: ReadonlyArray<
        | {
            readonly __typename?: 'NavigationSection';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly pages: ReadonlyArray<
              | { readonly __typename?: 'NavigationSection' }
              | {
                  readonly __typename?: 'StaticPage';
                  readonly title: string | null;
                  readonly localeTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly path: {
                    readonly __typename?: 'Slug';
                    readonly current: string | null;
                  } | null;
                  readonly localePath: {
                    readonly __typename?: 'LocaleSlug';
                    readonly locale: {
                      readonly __typename?: 'Slug';
                      readonly current: string | null;
                    } | null;
                  } | null;
                  readonly redirectUrl: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | {
            readonly __typename?: 'StaticPage';
            readonly title: string | null;
            readonly localeTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
            readonly localePath: {
              readonly __typename?: 'LocaleSlug';
              readonly locale: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
            } | null;
            readonly redirectUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type INavigationLinksFragment = {
  readonly __typename?: 'NavigationDesktop';
  readonly navigationLinks: ReadonlyArray<{
    readonly __typename?: 'NavigationDesktopLink';
    readonly _key: string | null;
    readonly hideLinkBasedOnLdFlag: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly navLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
    readonly launchDarklyAlternateNavigationLink: {
      readonly __typename?: 'LaunchDarklyNavigationLink';
      readonly enabled: boolean | null;
      readonly launchDarklyFlagDependency: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly navLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
    } | null;
  } | null> | null;
  readonly navigationLinksWithServiceModeOrStoreSelected: ReadonlyArray<{
    readonly __typename?: 'NavigationDesktopLink';
    readonly _key: string | null;
    readonly hideLinkBasedOnLdFlag: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly navLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
    readonly launchDarklyAlternateNavigationLink: {
      readonly __typename?: 'LaunchDarklyNavigationLink';
      readonly enabled: boolean | null;
      readonly launchDarklyFlagDependency: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly navLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IAboutMenuEntriesDesktopFragment = {
  readonly __typename?: 'NavigationDesktop';
  readonly aboutMenuEntries: {
    readonly __typename?: 'NavigationSection';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pages: ReadonlyArray<
      | {
          readonly __typename?: 'NavigationSection';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly pages: ReadonlyArray<
            | { readonly __typename?: 'NavigationSection' }
            | {
                readonly __typename?: 'StaticPage';
                readonly title: string | null;
                readonly localeTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly path: {
                  readonly __typename?: 'Slug';
                  readonly current: string | null;
                } | null;
                readonly localePath: {
                  readonly __typename?: 'LocaleSlug';
                  readonly locale: {
                    readonly __typename?: 'Slug';
                    readonly current: string | null;
                  } | null;
                } | null;
                readonly redirectUrl: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'StaticPage';
          readonly title: string | null;
          readonly localeTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
          readonly localePath: {
            readonly __typename?: 'LocaleSlug';
            readonly locale: {
              readonly __typename?: 'Slug';
              readonly current: string | null;
            } | null;
          } | null;
          readonly redirectUrl: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type INavigationLinkFragment = {
  readonly __typename?: 'NavigationDesktopLink';
  readonly _key: string | null;
  readonly hideLinkBasedOnLdFlag: {
    readonly __typename?: 'LaunchDarklySelect';
    readonly launchDarklyFlag: string | null;
  } | null;
  readonly navLink: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
  readonly launchDarklyAlternateNavigationLink: {
    readonly __typename?: 'LaunchDarklyNavigationLink';
    readonly enabled: boolean | null;
    readonly launchDarklyFlagDependency: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly navLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
  } | null;
};

export type INavigationMobileFragment = {
  readonly __typename?: 'FeatureNavigation';
  readonly navigationMobile: {
    readonly __typename?: 'NavigationMobile';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly navigationTabs: ReadonlyArray<{
      readonly __typename?: 'NavigationMobileTab';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly hideLinkBasedOnLdFlag: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly tabIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabIconActive: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
      readonly launchDarklyAlternateMobileTab: {
        readonly __typename?: 'LaunchDarklyNavigationTab';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly enabled: boolean | null;
        readonly launchDarklyFlagDependency: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly tabIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabIconActive: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly navigationTabsWithServiceModeSelected: ReadonlyArray<{
      readonly __typename?: 'NavigationMobileTab';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly hideLinkBasedOnLdFlag: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly tabIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabIconActive: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
      readonly launchDarklyAlternateMobileTab: {
        readonly __typename?: 'LaunchDarklyNavigationTab';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly enabled: boolean | null;
        readonly launchDarklyFlagDependency: {
          readonly __typename?: 'LaunchDarklySelect';
          readonly launchDarklyFlag: string | null;
        } | null;
        readonly tabIcon: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabIconActive: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly tabLink: {
          readonly __typename?: 'LinkWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly aboutMenuEntries: {
      readonly __typename?: 'NavigationSection';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pages: ReadonlyArray<
        | {
            readonly __typename?: 'NavigationSection';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly pages: ReadonlyArray<
              | { readonly __typename?: 'NavigationSection' }
              | {
                  readonly __typename?: 'StaticPage';
                  readonly title: string | null;
                  readonly localeTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly path: {
                    readonly __typename?: 'Slug';
                    readonly current: string | null;
                  } | null;
                  readonly localePath: {
                    readonly __typename?: 'LocaleSlug';
                    readonly locale: {
                      readonly __typename?: 'Slug';
                      readonly current: string | null;
                    } | null;
                  } | null;
                  readonly redirectUrl: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | null
            > | null;
          }
        | {
            readonly __typename?: 'StaticPage';
            readonly title: string | null;
            readonly localeTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
            readonly localePath: {
              readonly __typename?: 'LocaleSlug';
              readonly locale: {
                readonly __typename?: 'Slug';
                readonly current: string | null;
              } | null;
            } | null;
            readonly redirectUrl: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export type INavigationTabsFragment = {
  readonly __typename?: 'NavigationMobile';
  readonly navigationTabs: ReadonlyArray<{
    readonly __typename?: 'NavigationMobileTab';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly hideLinkBasedOnLdFlag: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly tabIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabIconActive: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
    readonly launchDarklyAlternateMobileTab: {
      readonly __typename?: 'LaunchDarklyNavigationTab';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly enabled: boolean | null;
      readonly launchDarklyFlagDependency: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly tabIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabIconActive: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
    } | null;
  } | null> | null;
  readonly navigationTabsWithServiceModeSelected: ReadonlyArray<{
    readonly __typename?: 'NavigationMobileTab';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly hideLinkBasedOnLdFlag: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly tabIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabIconActive: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
    readonly launchDarklyAlternateMobileTab: {
      readonly __typename?: 'LaunchDarklyNavigationTab';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly enabled: boolean | null;
      readonly launchDarklyFlagDependency: {
        readonly __typename?: 'LaunchDarklySelect';
        readonly launchDarklyFlag: string | null;
      } | null;
      readonly tabIcon: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabIconActive: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly tabLink: {
        readonly __typename?: 'LinkWidget';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly text: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IAboutMenuEntriesMobileFragment = {
  readonly __typename?: 'NavigationMobile';
  readonly aboutMenuEntries: {
    readonly __typename?: 'NavigationSection';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pages: ReadonlyArray<
      | {
          readonly __typename?: 'NavigationSection';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly pages: ReadonlyArray<
            | { readonly __typename?: 'NavigationSection' }
            | {
                readonly __typename?: 'StaticPage';
                readonly title: string | null;
                readonly localeTitle: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly path: {
                  readonly __typename?: 'Slug';
                  readonly current: string | null;
                } | null;
                readonly localePath: {
                  readonly __typename?: 'LocaleSlug';
                  readonly locale: {
                    readonly __typename?: 'Slug';
                    readonly current: string | null;
                  } | null;
                } | null;
                readonly redirectUrl: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'StaticPage';
          readonly title: string | null;
          readonly localeTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly path: { readonly __typename?: 'Slug'; readonly current: string | null } | null;
          readonly localePath: {
            readonly __typename?: 'LocaleSlug';
            readonly locale: {
              readonly __typename?: 'Slug';
              readonly current: string | null;
            } | null;
          } | null;
          readonly redirectUrl: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type INavigationTabFragment = {
  readonly __typename?: 'NavigationMobileTab';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly hideLinkBasedOnLdFlag: {
    readonly __typename?: 'LaunchDarklySelect';
    readonly launchDarklyFlag: string | null;
  } | null;
  readonly tabIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly tabIconActive: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly tabLink: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
  readonly launchDarklyAlternateMobileTab: {
    readonly __typename?: 'LaunchDarklyNavigationTab';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly enabled: boolean | null;
    readonly launchDarklyFlagDependency: {
      readonly __typename?: 'LaunchDarklySelect';
      readonly launchDarklyFlag: string | null;
    } | null;
    readonly tabIcon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabIconActive: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly tabLink: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
  } | null;
};

export type INavigationButtonFragment = {
  readonly __typename?: 'FeatureNavigation';
  readonly navigationMobileHeaderButton: {
    readonly __typename?: 'NavigationMobileHeaderButton';
    readonly _key: string | null;
    readonly icon: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly iconActive: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type INutritionItemFragment = {
  readonly __typename?: 'NutritionItem';
  readonly _type: string | null;
  readonly nutritionKey: string | null;
  readonly omitUnitFromValue: boolean | null;
  readonly additionalItems: ReadonlyArray<{
    readonly __typename?: 'NutritionItem';
    readonly nutritionKey: string | null;
    readonly omitUnitFromValue: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly unit: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null> | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly unit: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type INutritionItemGroupFragment = {
  readonly __typename?: 'NutritionItemGroup';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly mainItem: {
    readonly __typename?: 'NutritionItem';
    readonly _type: string | null;
    readonly nutritionKey: string | null;
    readonly omitUnitFromValue: boolean | null;
    readonly additionalItems: ReadonlyArray<{
      readonly __typename?: 'NutritionItem';
      readonly nutritionKey: string | null;
      readonly omitUnitFromValue: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly unit: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly unit: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null;
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'NutritionItem';
    readonly _type: string | null;
    readonly nutritionKey: string | null;
    readonly omitUnitFromValue: boolean | null;
    readonly additionalItems: ReadonlyArray<{
      readonly __typename?: 'NutritionItem';
      readonly nutritionKey: string | null;
      readonly omitUnitFromValue: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly unit: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly unit: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null> | null;
};

export type INutritionTableFragment = {
  readonly __typename?: 'NutritionTable';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly items: ReadonlyArray<
    | {
        readonly __typename?: 'NutritionItem';
        readonly _type: string | null;
        readonly nutritionKey: string | null;
        readonly omitUnitFromValue: boolean | null;
        readonly additionalItems: ReadonlyArray<{
          readonly __typename?: 'NutritionItem';
          readonly nutritionKey: string | null;
          readonly omitUnitFromValue: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly unit: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly unit: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'NutritionItemGroup';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly mainItem: {
          readonly __typename?: 'NutritionItem';
          readonly _type: string | null;
          readonly nutritionKey: string | null;
          readonly omitUnitFromValue: boolean | null;
          readonly additionalItems: ReadonlyArray<{
            readonly __typename?: 'NutritionItem';
            readonly nutritionKey: string | null;
            readonly omitUnitFromValue: boolean | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly unit: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null> | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly unit: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly lineItems: ReadonlyArray<{
          readonly __typename?: 'NutritionItem';
          readonly _type: string | null;
          readonly nutritionKey: string | null;
          readonly omitUnitFromValue: boolean | null;
          readonly additionalItems: ReadonlyArray<{
            readonly __typename?: 'NutritionItem';
            readonly nutritionKey: string | null;
            readonly omitUnitFromValue: boolean | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly unit: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null> | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly unit: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      }
    | null
  > | null;
};

export type IRoundingRulesFragment = {
  readonly __typename?: 'RoundingRules';
  readonly enabled: boolean | null;
  readonly decimalPlaces: number | null;
  readonly roundingType: string | null;
};

export type IOffersPageMarketingTileFragment = {
  readonly __typename?: 'OffersPageMarketingTile';
  readonly displayPosition: string | null;
  readonly customPosition: number | null;
  readonly marketingTileReference: {
    readonly __typename?: 'MarketingTile';
    readonly _id: string;
    readonly textAlignment: string | null;
    readonly buttonAlignment: string | null;
    readonly textColor: string | null;
    readonly lockedAspectRatio: boolean | null;
    readonly backgroundImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly buttonAction: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly textBlock: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly termsText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly termsButton: {
      readonly __typename?: 'LinkAction';
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly ruleSet: {
      readonly __typename?: 'MarketingTileRuleSet';
      readonly BKPayAudience: string | null;
      readonly bkSpecificTargeting: boolean | null;
      readonly dayOfWeek: {
        readonly __typename?: 'DayOfWeekBand';
        readonly monday: boolean | null;
        readonly tuesday: boolean | null;
        readonly wednesday: boolean | null;
        readonly thursday: boolean | null;
        readonly friday: boolean | null;
        readonly saturday: boolean | null;
        readonly sunday: boolean | null;
      } | null;
      readonly betweenTimes: {
        readonly __typename?: 'BetweenTimes';
        readonly startTime: string | null;
        readonly endTime: string | null;
      } | null;
    } | null;
  } | null;
};

export type IItemUnavailableModalFragment = {
  readonly __typename?: 'FeatureStaticMenu';
  readonly itemUnavailableModal: {
    readonly __typename?: 'ItemUnavailableModal';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly continue: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly selectNew: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly itemNotAvailableForDelivery: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly image: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ICopyrightAndLanguageSelectorFragment = {
  readonly __typename?: 'CopyrightAndLanguageSelector';
  readonly _type: string | null;
  readonly copyrightText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IHighlightedLinkWidgetFragment = {
  readonly __typename?: 'HighlightedLinkWidget';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
};

export type IHighlightedVerticalLinkListWidgetFragment = {
  readonly __typename?: 'HighlightedVerticalLinkListWidget';
  readonly _type: string | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subheader: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly highlightedLinks: ReadonlyArray<{
    readonly __typename?: 'HighlightedLinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null> | null;
};

export type IHorizontalLinkListWidgetFragment = {
  readonly __typename?: 'HorizontalLinkListWidget';
  readonly _type: string | null;
  readonly linkWidgets: ReadonlyArray<{
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null> | null;
};

export type IImageWidgetFragment = {
  readonly __typename?: 'ImageWidget';
  readonly _type: string | null;
  readonly attributionLink: string | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ISocialIconsWithHeaderWidgetFragment = {
  readonly __typename?: 'SocialIconsWithHeaderWidget';
  readonly _type: string | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly socialIconsWidget: {
    readonly __typename?: 'SocialIconsWidget';
    readonly _type: string | null;
    readonly instagramUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly facebookUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly youtubeUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly twitterUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly tiktokUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly linkedinUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly snapchatUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type ISocialIconsWidgetFragment = {
  readonly __typename?: 'SocialIconsWidget';
  readonly _type: string | null;
  readonly instagramUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
  readonly facebookUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
  readonly youtubeUrl: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  readonly twitterUrl: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  readonly tiktokUrl: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  readonly linkedinUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
  readonly snapchatUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
};

export type ITextWidgetFragment = {
  readonly __typename?: 'TextWidget';
  readonly _type: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type ITextWidgetWithUrlFragment = {
  readonly __typename?: 'TextWidgetWithUrl';
  readonly _type: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly textUrl: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly url: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type IVerticalLinkListWithHeaderWidgetFragment = {
  readonly __typename?: 'VerticalLinkListWithHeaderWidget';
  readonly _type: string | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly linkWidgets: ReadonlyArray<{
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null> | null;
};

export type IVerticalLinkListWidgetFragment = {
  readonly __typename?: 'VerticalLinkListWidget';
  readonly _type: string | null;
  readonly linkWidgets: ReadonlyArray<{
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null> | null;
};

export type IWordmarkAndSocialIconsFragment = {
  readonly __typename?: 'WordmarkAndSocialIcons';
  readonly _type: string | null;
  readonly socialIconsWidget: {
    readonly __typename?: 'SocialIconsWidget';
    readonly _type: string | null;
    readonly instagramUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly facebookUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly youtubeUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly twitterUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly tiktokUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly linkedinUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly snapchatUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IHeroMaskCardFragment = {
  readonly __typename?: 'SimpleCard';
  readonly _key: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type IHeroContentFragment = {
  readonly __typename?: 'AppData';
  readonly _id: string;
  readonly heroTextColor: string | null;
  readonly mainHeroImageAuthenticatedLocale: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly mainHeroImageLocale: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly mainHeroMobileImageLocale: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly heroTextAuthenticatedLocale: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroTextLocale: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroActionAuthenticated: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly heroAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly heroActionLeft: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly heroActionRight: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly heroSuperTextAuthenticated: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroSuperText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroSubTextAuthenticated: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroSubText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroMaskCards: ReadonlyArray<{
    readonly __typename?: 'SimpleCard';
    readonly _key: string | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  readonly loyaltyHeroAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly loyaltyHeroSuperText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly loyaltyHeroSubText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly heroActionLeftIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly heroActionRightIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IHeroFragment = {
  readonly __typename?: 'Hero';
  readonly _id: string;
  readonly showComplementaryCTA: boolean | null;
  readonly reversed: boolean | null;
  readonly hasTerms: boolean | null;
  readonly backgroundColor: boolean | null;
  readonly colorOption: string | null;
  readonly backgroundSplit: boolean | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly altImageText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subheader: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly primaryCta: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly complementaryCta: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly termsText: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly termsButton: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IRefFragment = {
  readonly __typename?: 'Image';
  readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
};

export type IImageFragment = {
  readonly __typename?: 'Image';
  readonly hotspot: {
    readonly __typename?: 'SanityImageHotspot';
    readonly x: number | null;
    readonly y: number | null;
    readonly height: number | null;
    readonly width: number | null;
  } | null;
  readonly crop: {
    readonly __typename?: 'SanityImageCrop';
    readonly top: number | null;
    readonly bottom: number | null;
    readonly left: number | null;
    readonly right: number | null;
  } | null;
  readonly asset: {
    readonly __typename?: 'SanityImageAsset';
    readonly _id: string;
    readonly metadata: {
      readonly __typename?: 'SanityImageMetadata';
      readonly lqip: string | null;
    } | null;
  } | null;
};

export type IImagesFragment = {
  readonly __typename?: 'Images';
  readonly imageDescription: string | null;
  readonly app: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type IImagesByChannelsFragment = {
  readonly __typename?: 'ImagesByChannels';
  readonly imageRestaurant: {
    readonly __typename?: 'Image';
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly imageDelivery: {
    readonly __typename?: 'Image';
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type IItemAvailabilityFragment = {
  readonly __typename?: 'Item';
  readonly showInStaticMenu: boolean | null;
  readonly hideCalories: boolean | null;
  readonly hideNutritionLegalDisclaimer: boolean | null;
  readonly itemSize: number | null;
  readonly itemUnit: string | null;
  readonly operationalItem: {
    readonly __typename?: 'OpItem';
    readonly daypart: ReadonlyArray<string | null> | null;
  } | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ItemOption';
    readonly injectDefaultSelection: boolean | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOptionModifier';
      readonly default: boolean | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly modifierMultiplier: {
        readonly __typename?: 'ModifierMultiplier';
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifier: {
          readonly __typename?: 'Modifier';
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type IItemOptionAvailabilityFragment = {
  readonly __typename?: 'ItemOption';
  readonly injectDefaultSelection: boolean | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ItemOptionModifier';
    readonly default: boolean | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly modifierMultiplier: {
      readonly __typename?: 'ModifierMultiplier';
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly modifier: {
        readonly __typename?: 'Modifier';
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IItemOptionFragment = {
  readonly __typename?: 'ItemOption';
  readonly componentStyle: string | null;
  readonly upsellModifier: boolean | null;
  readonly allowMultipleSelections: boolean | null;
  readonly displayModifierMultiplierName: boolean | null;
  readonly injectDefaultSelection: boolean | null;
  readonly singleChoiceOnly: boolean | null;
  readonly minAmount: number | null;
  readonly maxAmount: number | null;
  readonly _key: string | null;
  readonly type: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly displayGroup: {
    readonly __typename?: 'DisplayGroup';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ItemOptionModifier';
    readonly _key: string | null;
    readonly default: boolean | null;
    readonly type: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly modifierMultiplier: {
      readonly __typename?: 'ModifierMultiplier';
      readonly multiplier: number | null;
      readonly prefix: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly modifier: {
        readonly __typename?: 'Modifier';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
  } | null> | null;
};

export type IItemFragment = {
  readonly __typename?: 'Item';
  readonly _id: string;
  readonly _type: string;
  readonly rewardEligible: boolean | null;
  readonly isDummyItem: boolean | null;
  readonly labelAsPerPerson: boolean | null;
  readonly productSize: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly legalInformation: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly imagesByChannels: {
    readonly __typename?: 'ImagesByChannels';
    readonly imageRestaurant: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDelivery: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly nutrition: {
    readonly __typename?: 'Nutrition';
    readonly calories: number | null;
    readonly caloriesPer100: number | null;
    readonly carbohydrates: number | null;
    readonly carbohydratesPer100: number | null;
    readonly cholesterol: number | null;
    readonly energyKJ: number | null;
    readonly energyKJPer100: number | null;
    readonly fat: number | null;
    readonly fatPer100: number | null;
    readonly fiber: number | null;
    readonly proteins: number | null;
    readonly proteinsPer100: number | null;
    readonly salt: number | null;
    readonly saltPer100: number | null;
    readonly saturatedFat: number | null;
    readonly saturatedFatPer100: number | null;
    readonly sodium: number | null;
    readonly sugar: number | null;
    readonly sugarPer100: number | null;
    readonly transFat: number | null;
    readonly weight: number | null;
  } | null;
  readonly additionalItemInformation: {
    readonly __typename?: 'AdditionalItemInformation';
    readonly sourcesOfGluten: string | null;
    readonly ingredients: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly additives: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly producerDetails: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly nutritionWithModifiers: {
    readonly __typename?: 'Nutrition';
    readonly calories: number | null;
    readonly caloriesPer100: number | null;
    readonly carbohydrates: number | null;
    readonly carbohydratesPer100: number | null;
    readonly cholesterol: number | null;
    readonly energyKJ: number | null;
    readonly energyKJPer100: number | null;
    readonly fat: number | null;
    readonly fatPer100: number | null;
    readonly fiber: number | null;
    readonly proteins: number | null;
    readonly proteinsPer100: number | null;
    readonly salt: number | null;
    readonly saltPer100: number | null;
    readonly saturatedFat: number | null;
    readonly saturatedFatPer100: number | null;
    readonly sodium: number | null;
    readonly sugar: number | null;
    readonly sugarPer100: number | null;
    readonly transFat: number | null;
    readonly weight: number | null;
  } | null;
  readonly allergens: {
    readonly __typename?: 'OpAllergen';
    readonly milk: number | null;
    readonly eggs: number | null;
    readonly fish: number | null;
    readonly peanuts: number | null;
    readonly shellfish: number | null;
    readonly treeNuts: number | null;
    readonly soy: number | null;
    readonly wheat: number | null;
    readonly mustard: number | null;
    readonly sesame: number | null;
    readonly celery: number | null;
    readonly lupin: number | null;
    readonly gluten: number | null;
    readonly sulphurDioxide: number | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ItemOption';
    readonly componentStyle: string | null;
    readonly upsellModifier: boolean | null;
    readonly allowMultipleSelections: boolean | null;
    readonly displayModifierMultiplierName: boolean | null;
    readonly injectDefaultSelection: boolean | null;
    readonly singleChoiceOnly: boolean | null;
    readonly minAmount: number | null;
    readonly maxAmount: number | null;
    readonly _key: string | null;
    readonly type: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly displayGroup: {
      readonly __typename?: 'DisplayGroup';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOptionModifier';
      readonly _key: string | null;
      readonly default: boolean | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly modifierMultiplier: {
        readonly __typename?: 'ModifierMultiplier';
        readonly multiplier: number | null;
        readonly prefix: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly modifier: {
          readonly __typename?: 'Modifier';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
  readonly productHierarchy: {
    readonly __typename?: 'ProductHierarchy';
    readonly L1: string | null;
    readonly L2: string | null;
    readonly L3: string | null;
    readonly L4: string | null;
    readonly L5: string | null;
  } | null;
  readonly menuObjectSettings: {
    readonly __typename?: 'MenuObjectSettings';
    readonly limitPerOrder: number | null;
  } | null;
  readonly channelExclusions: {
    readonly __typename?: 'ChannelExclusions';
    readonly delivery: boolean | null;
    readonly pickup: boolean | null;
  } | null;
};

export type ILinkWidgetFragment = {
  readonly __typename?: 'LinkWidget';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
};

export type ILoadingGraphicsFragment = {
  readonly __typename?: 'LoadingGraphics';
  readonly cartInitialLoader: {
    readonly __typename?: 'Loader';
    readonly desktopGraphic: {
      readonly __typename?: 'Image';
      readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
    } | null;
    readonly mobileGraphic: {
      readonly __typename?: 'Image';
      readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
    } | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null;
};

export type ILocaleImageFragment = {
  readonly __typename?: 'LocaleImage';
  readonly locale: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type ILocaleStringFragment = {
  readonly __typename?: 'LocaleString';
  readonly locale: string | null;
};

export type ILocaleVideoFileFragment = {
  readonly __typename?: 'LocaleFile';
  readonly locale: {
    readonly __typename?: 'File';
    readonly asset: {
      readonly __typename?: 'SanityFileAsset';
      readonly assetId: string | null;
      readonly path: string | null;
      readonly url: string | null;
    } | null;
  } | null;
};

export type IClaimPointsScreenContentFragment = {
  readonly __typename?: 'ClaimPointsScreenContent';
  readonly rewardsIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly secondImage: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly screenTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly instructionsTextBlock: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly topButtonTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly topButtonUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
  readonly bottomButtonTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly bottomButtonUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
};

export type IClaimPointsSurveyContentFragment = {
  readonly __typename?: 'ClaimPointsSurveyScreenContent';
  readonly expirationTimeReceipts: number | null;
  readonly rewardsIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly secondImage: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly screenTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly instructionsTextBlock: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly expirationDateInstructionsTextBlock: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly inputBoxExpirationDatePlaceholder: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly topButtonTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly topButtonUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
  readonly bottomButtonTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly bottomButtonUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
};

export type IClaimPointsQrCodeContentFragment = {
  readonly __typename?: 'ClaimQrCode';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly body: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly link: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type IClaimPointsFailedSubmissionContentFragment = {
  readonly __typename?: 'ClaimPointsFailedSubmissionContent';
  readonly screenTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly failureTextBlock: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly buttonTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly buttonUrl: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
};

export type IExternalOffersFragment = {
  readonly __typename?: 'ExternalOffers';
  readonly _id: string;
  readonly testOnly: boolean | null;
  readonly authentificationRequired: boolean | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly buttonsConfiguration: {
    readonly __typename?: 'ExternalOffersButtonsConfiguration';
    readonly firstButton: {
      readonly __typename?: 'ActionButton';
      readonly buttonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly secondButton: {
      readonly __typename?: 'ActionButtonNotRequired';
      readonly buttonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly buttonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
  readonly unauthenticatedUsersContent: {
    readonly __typename?: 'ExternalOffersUnauthenticatedUsersContent';
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly externalOfferModal: {
    readonly __typename?: 'ExternalOffersModalContent';
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly tierIncentiveList: ReadonlyArray<{
    readonly __typename?: 'TierIncentiveList';
    readonly tierName: string | null;
  } | null> | null;
};

export type ILinkPhysicalCardSectionFragment = {
  readonly __typename?: 'LinkCardSection';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly ctaButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILinkPhysicalCardFaqFragment = {
  readonly __typename?: 'LinkCardFaqSection';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly marketingTileList: {
    readonly __typename: 'MarketingTileList';
    readonly _type: string | null;
    readonly tiles: ReadonlyArray<{
      readonly __typename?: 'MarketingTileBasic';
      readonly _id: string;
      readonly marketingBasicTileDescription: {
        readonly __typename?: 'LocaleTypographyBlock';
        readonly localeRaw: any | null;
      } | null;
      readonly marketingBasicTileImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly marketingBasicTileTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      readonly linkLabel: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ILinkCardActivationSuccessModalFragment = {
  readonly __typename?: 'LinkCardActivationSuccessModal';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly ctaButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly closeButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly downloadAppButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly downloadAppUrl: {
    readonly __typename?: 'LocaleUrl';
    readonly locale: string | null;
  } | null;
};

export type ILinkCardErrorModalFragment = {
  readonly __typename?: 'LinkCardErrorModal';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly ctaButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly closeButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILinkCardDefaultModalFragment = {
  readonly __typename?: 'LinkCardDefaultModal';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly content: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly ctaButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly closeButtonLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyOfferTemplateFragment = {
  readonly __typename?: 'OfferTemplate';
  readonly _id: string;
  readonly _type: string;
  readonly testOnly: boolean | null;
  readonly loyaltyEngineId: string | null;
  readonly name: {
    readonly __typename?: 'LocaleSmartBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleSmartBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly localizedImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyOffersPageMarketingTileFragment = {
  readonly __typename?: 'LoyaltyOffersPageMarketingTile';
  readonly displayPosition: string | null;
  readonly customPosition: number | null;
  readonly marketingTileReference: {
    readonly __typename?: 'MarketingTile';
    readonly _id: string;
    readonly textAlignment: string | null;
    readonly buttonAlignment: string | null;
    readonly textColor: string | null;
    readonly lockedAspectRatio: boolean | null;
    readonly backgroundImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly buttonAction: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly textBlock: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly termsText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly termsButton: {
      readonly __typename?: 'LinkAction';
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly ruleSet: {
      readonly __typename?: 'MarketingTileRuleSet';
      readonly BKPayAudience: string | null;
      readonly bkSpecificTargeting: boolean | null;
      readonly dayOfWeek: {
        readonly __typename?: 'DayOfWeekBand';
        readonly monday: boolean | null;
        readonly tuesday: boolean | null;
        readonly wednesday: boolean | null;
        readonly thursday: boolean | null;
        readonly friday: boolean | null;
        readonly saturday: boolean | null;
        readonly sunday: boolean | null;
      } | null;
      readonly betweenTimes: {
        readonly __typename?: 'BetweenTimes';
        readonly startTime: string | null;
        readonly endTime: string | null;
      } | null;
    } | null;
  } | null;
};

export type IOptInModalFragment = {
  readonly __typename?: 'OptInModalContent';
  readonly image: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly buttonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly blockText: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
};

export type IShortCodeConfirmationModalFragment = {
  readonly __typename?: 'ShortCodeConfirmationModalContent';
  readonly image: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly actionButton: {
    readonly __typename?: 'ActionButton';
    readonly buttonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly buttonPath: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly dismissButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyButtonWidgetFragment = {
  readonly __typename?: 'LoyaltyButtonWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly link: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type ILoyaltyImageWidgetFragment = {
  readonly __typename?: 'LoyaltyImageWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ILoyaltyTextWidgetFragment = {
  readonly __typename?: 'LoyaltyTextWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type ILoyaltyTitleWidgetFragment = {
  readonly __typename?: 'LoyaltyTitleWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type ILoyaltyPaymentMethodWidgetFragment = {
  readonly __typename?: 'LoyaltyPaymentMethodWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type ILoyaltyQrScanButtonWidgetFragment = {
  readonly __typename?: 'LoyaltyQRScanButtonWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly expirationMessage: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly link: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type ILoyaltyQrScanHeaderWidgetFragment = {
  readonly __typename?: 'LoyaltyQRScanHeaderWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly expirationMessage: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly link: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type ILoyaltyCartWidgetFragment = {
  readonly __typename?: 'LoyaltyCartWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly loyaltyEmptyCartTileWidget: {
    readonly __typename?: 'MarketingTileBasic';
    readonly _id: string;
    readonly marketingBasicTileDescription: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly marketingBasicTileImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly marketingBasicTileTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    readonly linkLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type ILoyaltyShortCodeWidgetFragment = {
  readonly __typename?: 'LoyaltyShortCodeWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly defaultCode: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly alternativeTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyLegacyShortCodeWidgetFragment = {
  readonly __typename?: 'LoyaltyLegacyShortCodeWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly instructions: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyQrCodeWidgetFragment = {
  readonly __typename?: 'LoyaltyQRCodeWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly subtitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly expirationMessage: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyBasicCardWidgetFragment = {
  readonly __typename?: 'LoyaltyBasicCardWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly linkedCard: {
    readonly __typename?: 'MarketingTileBasic';
    readonly _id: string;
    readonly marketingBasicTileDescription: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly marketingBasicTileImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly marketingBasicTileTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    readonly linkLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly unlinkedCard: {
    readonly __typename?: 'MarketingTileBasic';
    readonly _id: string;
    readonly marketingBasicTileDescription: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly marketingBasicTileImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly marketingBasicTileTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    readonly linkLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type ILoyaltyButtonLinkTextWidgetFragment = {
  readonly __typename?: 'LoyaltyButtonLinkTextWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly type: string | null;
  readonly link: {
    readonly __typename?: 'LinkWidget';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly text: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type ILoyaltyButtonArrayWidgetFragment = {
  readonly __typename?: 'LoyaltyButtonArrayWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly buttons: ReadonlyArray<{
    readonly __typename?: 'LoyaltyButtonLinkTextWidget';
    readonly _key: string | null;
    readonly componentKey: string | null;
    readonly type: string | null;
    readonly link: {
      readonly __typename?: 'LinkWidget';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly text: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    } | null;
  } | null> | null;
};

export type ILoyaltyQrAndShortCodeModalContentFragment = {
  readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
  readonly _key: string | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly details: {
    readonly __typename?: 'LocaleBlockContent';
    readonly locale: any | null;
  } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly buttonCtaLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type ILoyaltyQrAndShortCodeWidgetFragment = {
  readonly __typename?: 'LoyaltyQRAndShortCodeWidget';
  readonly _key: string | null;
  readonly componentKey: string | null;
  readonly shortCodeAlwaysDisplayGenerateButton: boolean | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly shortCodeText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly qrAndShortCodeHowToEarnModalContent: {
    readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
    readonly _key: string | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly details: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly buttonCtaLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly qrAndShortCodeErrorModalContent: {
    readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
    readonly _key: string | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly details: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly buttonCtaLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IMainHeroFragment = {
  readonly __typename?: 'MainHero';
  readonly _id: string;
  readonly textColor: string | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly mobileImage: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly superText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly mainText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly subText: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly action: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly actionRight: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly actionRightIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly actionLeft: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly actionLeftIcon: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly maskCards: ReadonlyArray<{
    readonly __typename?: 'SimpleCard';
    readonly _key: string | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  readonly loyaltyAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IMarketingCardGroupFragment = {
  readonly __typename?: 'MarketingCardGroup';
  readonly _id: string;
  readonly Cards: ReadonlyArray<{
    readonly __typename?: 'MarketingCard';
    readonly _id: string;
    readonly hasCustomColors: boolean | null;
    readonly hasTerms: boolean | null;
    readonly BKPayAudience: string | null;
    readonly bkSpecificTargeting: boolean | null;
    readonly daysOfWeek: ReadonlyArray<string | null> | null;
    readonly header: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly image: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly callToAction: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly textColor: {
      readonly __typename?: 'Color';
      readonly alpha: number | null;
      readonly hex: string | null;
      readonly hsl: {
        readonly __typename?: 'HslaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly l: number | null;
        readonly s: number | null;
      } | null;
      readonly hsv: {
        readonly __typename?: 'HsvaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly s: number | null;
        readonly v: number | null;
      } | null;
      readonly rgb: {
        readonly __typename?: 'RgbaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly b: number | null;
        readonly g: number | null;
        readonly r: number | null;
      } | null;
    } | null;
    readonly backgroundColor: {
      readonly __typename?: 'Color';
      readonly alpha: number | null;
      readonly hex: string | null;
      readonly hsl: {
        readonly __typename?: 'HslaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly l: number | null;
        readonly s: number | null;
      } | null;
      readonly hsv: {
        readonly __typename?: 'HsvaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly s: number | null;
        readonly v: number | null;
      } | null;
      readonly rgb: {
        readonly __typename?: 'RgbaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly b: number | null;
        readonly g: number | null;
        readonly r: number | null;
      } | null;
    } | null;
    readonly buttonTextColor: {
      readonly __typename?: 'Color';
      readonly alpha: number | null;
      readonly hex: string | null;
      readonly hsl: {
        readonly __typename?: 'HslaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly l: number | null;
        readonly s: number | null;
      } | null;
      readonly hsv: {
        readonly __typename?: 'HsvaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly s: number | null;
        readonly v: number | null;
      } | null;
      readonly rgb: {
        readonly __typename?: 'RgbaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly b: number | null;
        readonly g: number | null;
        readonly r: number | null;
      } | null;
    } | null;
    readonly buttonBackgroundColor: {
      readonly __typename?: 'Color';
      readonly alpha: number | null;
      readonly hex: string | null;
      readonly hsl: {
        readonly __typename?: 'HslaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly l: number | null;
        readonly s: number | null;
      } | null;
      readonly hsv: {
        readonly __typename?: 'HsvaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly s: number | null;
        readonly v: number | null;
      } | null;
      readonly rgb: {
        readonly __typename?: 'RgbaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly b: number | null;
        readonly g: number | null;
        readonly r: number | null;
      } | null;
    } | null;
    readonly termsText: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly termsButton: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly actionUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly timeRanges: ReadonlyArray<{
      readonly __typename?: 'TimeRange';
      readonly startTime: string | null;
      readonly endTime: string | null;
    } | null> | null;
  } | null> | null;
};

export type IMarketingCardFragment = {
  readonly __typename?: 'MarketingCard';
  readonly _id: string;
  readonly hasCustomColors: boolean | null;
  readonly hasTerms: boolean | null;
  readonly BKPayAudience: string | null;
  readonly bkSpecificTargeting: boolean | null;
  readonly daysOfWeek: ReadonlyArray<string | null> | null;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly callToAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly textColor: {
    readonly __typename?: 'Color';
    readonly alpha: number | null;
    readonly hex: string | null;
    readonly hsl: {
      readonly __typename?: 'HslaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly l: number | null;
      readonly s: number | null;
    } | null;
    readonly hsv: {
      readonly __typename?: 'HsvaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly s: number | null;
      readonly v: number | null;
    } | null;
    readonly rgb: {
      readonly __typename?: 'RgbaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly b: number | null;
      readonly g: number | null;
      readonly r: number | null;
    } | null;
  } | null;
  readonly backgroundColor: {
    readonly __typename?: 'Color';
    readonly alpha: number | null;
    readonly hex: string | null;
    readonly hsl: {
      readonly __typename?: 'HslaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly l: number | null;
      readonly s: number | null;
    } | null;
    readonly hsv: {
      readonly __typename?: 'HsvaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly s: number | null;
      readonly v: number | null;
    } | null;
    readonly rgb: {
      readonly __typename?: 'RgbaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly b: number | null;
      readonly g: number | null;
      readonly r: number | null;
    } | null;
  } | null;
  readonly buttonTextColor: {
    readonly __typename?: 'Color';
    readonly alpha: number | null;
    readonly hex: string | null;
    readonly hsl: {
      readonly __typename?: 'HslaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly l: number | null;
      readonly s: number | null;
    } | null;
    readonly hsv: {
      readonly __typename?: 'HsvaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly s: number | null;
      readonly v: number | null;
    } | null;
    readonly rgb: {
      readonly __typename?: 'RgbaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly b: number | null;
      readonly g: number | null;
      readonly r: number | null;
    } | null;
  } | null;
  readonly buttonBackgroundColor: {
    readonly __typename?: 'Color';
    readonly alpha: number | null;
    readonly hex: string | null;
    readonly hsl: {
      readonly __typename?: 'HslaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly l: number | null;
      readonly s: number | null;
    } | null;
    readonly hsv: {
      readonly __typename?: 'HsvaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly h: number | null;
      readonly s: number | null;
      readonly v: number | null;
    } | null;
    readonly rgb: {
      readonly __typename?: 'RgbaColor';
      readonly _type: string | null;
      readonly a: number | null;
      readonly b: number | null;
      readonly g: number | null;
      readonly r: number | null;
    } | null;
  } | null;
  readonly termsText: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly termsButton: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly timeRanges: ReadonlyArray<{
    readonly __typename?: 'TimeRange';
    readonly startTime: string | null;
    readonly endTime: string | null;
  } | null> | null;
};

export type IMarketingTileCarouselFragment = {
  readonly __typename?: 'MarketingTileCarousel';
  readonly _id: string;
  readonly header: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly tiles: ReadonlyArray<{
    readonly __typename?: 'MarketingTileBasic';
    readonly _id: string;
    readonly marketingBasicTileDescription: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly marketingBasicTileImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly marketingBasicTileTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    readonly linkLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null> | null;
};

export type IMarketingTileGroupFragment = {
  readonly __typename?: 'MarketingTileGroup';
  readonly _id: string;
  readonly tiles: ReadonlyArray<
    | {
        readonly __typename?: 'MarketingTile';
        readonly _id: string;
        readonly textAlignment: string | null;
        readonly buttonAlignment: string | null;
        readonly textColor: string | null;
        readonly lockedAspectRatio: boolean | null;
        readonly backgroundImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly buttonAction: {
          readonly __typename?: 'LinkAction';
          readonly route: string | null;
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly textBlock: {
          readonly __typename?: 'LocaleTypographyBlock';
          readonly localeRaw: any | null;
        } | null;
        readonly termsText: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly termsButton: {
          readonly __typename?: 'LinkAction';
          readonly actionText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly actionUrl: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly ruleSet: {
          readonly __typename?: 'MarketingTileRuleSet';
          readonly BKPayAudience: string | null;
          readonly bkSpecificTargeting: boolean | null;
          readonly dayOfWeek: {
            readonly __typename?: 'DayOfWeekBand';
            readonly monday: boolean | null;
            readonly tuesday: boolean | null;
            readonly wednesday: boolean | null;
            readonly thursday: boolean | null;
            readonly friday: boolean | null;
            readonly saturday: boolean | null;
            readonly sunday: boolean | null;
          } | null;
          readonly betweenTimes: {
            readonly __typename?: 'BetweenTimes';
            readonly startTime: string | null;
            readonly endTime: string | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'MarketingTileBasic';
        readonly _id: string;
        readonly marketingBasicTileDescription: {
          readonly __typename?: 'LocaleTypographyBlock';
          readonly localeRaw: any | null;
        } | null;
        readonly marketingBasicTileImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly marketingBasicTileTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
        readonly linkLabel: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename: 'MarketingTileList';
        readonly _type: string | null;
        readonly tiles: ReadonlyArray<{
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null> | null;
      }
    | {
        readonly __typename?: 'MarketingTilePair';
        readonly left: {
          readonly __typename?: 'MarketingTile';
          readonly _id: string;
          readonly textAlignment: string | null;
          readonly buttonAlignment: string | null;
          readonly textColor: string | null;
          readonly lockedAspectRatio: boolean | null;
          readonly backgroundImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly buttonAction: {
            readonly __typename?: 'LinkAction';
            readonly route: string | null;
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly textBlock: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly termsText: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly termsButton: {
            readonly __typename?: 'LinkAction';
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly ruleSet: {
            readonly __typename?: 'MarketingTileRuleSet';
            readonly BKPayAudience: string | null;
            readonly bkSpecificTargeting: boolean | null;
            readonly dayOfWeek: {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
            } | null;
            readonly betweenTimes: {
              readonly __typename?: 'BetweenTimes';
              readonly startTime: string | null;
              readonly endTime: string | null;
            } | null;
          } | null;
        } | null;
        readonly right: {
          readonly __typename?: 'MarketingTile';
          readonly _id: string;
          readonly textAlignment: string | null;
          readonly buttonAlignment: string | null;
          readonly textColor: string | null;
          readonly lockedAspectRatio: boolean | null;
          readonly backgroundImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly buttonAction: {
            readonly __typename?: 'LinkAction';
            readonly route: string | null;
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly textBlock: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly termsText: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly termsButton: {
            readonly __typename?: 'LinkAction';
            readonly actionText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly actionUrl: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly ruleSet: {
            readonly __typename?: 'MarketingTileRuleSet';
            readonly BKPayAudience: string | null;
            readonly bkSpecificTargeting: boolean | null;
            readonly dayOfWeek: {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
            } | null;
            readonly betweenTimes: {
              readonly __typename?: 'BetweenTimes';
              readonly startTime: string | null;
              readonly endTime: string | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'MarketingTileQuad';
        readonly topLeftTile: {
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly topRightTile: {
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly bottomLeftTile: {
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly bottomRightTile: {
          readonly __typename?: 'MarketingTileBasic';
          readonly _id: string;
          readonly marketingBasicTileDescription: {
            readonly __typename?: 'LocaleTypographyBlock';
            readonly localeRaw: any | null;
          } | null;
          readonly marketingBasicTileImage: {
            readonly __typename?: 'LocaleImages';
            readonly locale: {
              readonly __typename?: 'Images';
              readonly imageDescription: string | null;
              readonly app: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly marketingBasicTileTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly link: {
            readonly __typename?: 'LocaleUrl';
            readonly locale: string | null;
          } | null;
          readonly linkLabel: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
      }
    | null
  > | null;
};

export type IMarketingTileFragment = {
  readonly __typename?: 'MarketingTile';
  readonly _id: string;
  readonly textAlignment: string | null;
  readonly buttonAlignment: string | null;
  readonly textColor: string | null;
  readonly lockedAspectRatio: boolean | null;
  readonly backgroundImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly buttonAction: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly textBlock: {
    readonly __typename?: 'LocaleTypographyBlock';
    readonly localeRaw: any | null;
  } | null;
  readonly termsText: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly termsButton: {
    readonly __typename?: 'LinkAction';
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionUrl: {
      readonly __typename?: 'LocaleUrl';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly ruleSet: {
    readonly __typename?: 'MarketingTileRuleSet';
    readonly BKPayAudience: string | null;
    readonly bkSpecificTargeting: boolean | null;
    readonly dayOfWeek: {
      readonly __typename?: 'DayOfWeekBand';
      readonly monday: boolean | null;
      readonly tuesday: boolean | null;
      readonly wednesday: boolean | null;
      readonly thursday: boolean | null;
      readonly friday: boolean | null;
      readonly saturday: boolean | null;
      readonly sunday: boolean | null;
    } | null;
    readonly betweenTimes: {
      readonly __typename?: 'BetweenTimes';
      readonly startTime: string | null;
      readonly endTime: string | null;
    } | null;
  } | null;
};

export type IMarketingTileBasicFragment = {
  readonly __typename?: 'MarketingTileBasic';
  readonly _id: string;
  readonly marketingBasicTileDescription: {
    readonly __typename?: 'LocaleTypographyBlock';
    readonly localeRaw: any | null;
  } | null;
  readonly marketingBasicTileImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly marketingBasicTileTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  readonly linkLabel: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IMarketingTileListFragment = {
  readonly __typename: 'MarketingTileList';
  readonly _type: string | null;
  readonly tiles: ReadonlyArray<{
    readonly __typename?: 'MarketingTileBasic';
    readonly _id: string;
    readonly marketingBasicTileDescription: {
      readonly __typename?: 'LocaleTypographyBlock';
      readonly localeRaw: any | null;
    } | null;
    readonly marketingBasicTileImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly marketingBasicTileTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
    readonly linkLabel: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null> | null;
};

export type IMembershipsInformationWidgetsFragment = {
  readonly __typename?: 'MembershipsInformationWidgets';
  readonly widgets: ReadonlyArray<{
    readonly __typename?: 'MembershipsWidgetContent';
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly widgetImage: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly widgetBackgroundColor: {
      readonly __typename?: 'Color';
      readonly alpha: number | null;
      readonly hex: string | null;
      readonly hsl: {
        readonly __typename?: 'HslaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly l: number | null;
        readonly s: number | null;
      } | null;
      readonly hsv: {
        readonly __typename?: 'HsvaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly h: number | null;
        readonly s: number | null;
        readonly v: number | null;
      } | null;
      readonly rgb: {
        readonly __typename?: 'RgbaColor';
        readonly _type: string | null;
        readonly a: number | null;
        readonly b: number | null;
        readonly g: number | null;
        readonly r: number | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IMembershipsModalsContentDetailsFragment = {
  readonly __typename?: 'MembershipsModalsContentDetails';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly firstButtonPath: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly firstButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly secondButtonPath: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly secondButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IMembershipsFragment = {
  readonly __typename?: 'Memberships';
  readonly _id: string;
  readonly testOnly: boolean | null;
  readonly membershipInternalName: string | null;
  readonly membershipName: string | null;
  readonly membershipBenefit: {
    readonly __typename?: 'MembershipsBenefit';
    readonly offer: {
      readonly __typename?: 'ConfigOffer';
      readonly _id: string;
      readonly _type: string;
      readonly testOnly: boolean | null;
      readonly loyaltyEngineId: string | null;
      readonly redemptionMethod: string | null;
      readonly shortCode: string | null;
      readonly mobileOrderOnly: boolean | null;
      readonly daypart: ReadonlyArray<string | null> | null;
      readonly redemptionType: string | null;
      readonly offerPrice: number | null;
      readonly uiPattern: string | null;
      readonly isUpcomingOffer: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly moreInfo: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly howToRedeem: {
        readonly __typename?: 'LocaleBlockContent';
        readonly enRaw: any | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly upsellOptions: ReadonlyArray<{
        readonly __typename?: 'SystemwideOffer';
        readonly _id: string;
        readonly loyaltyEngineId: string | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly localizedImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly name: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null> | null;
      readonly marketPrice:
        | {
            readonly __typename?: 'Combo';
            readonly _id: string;
            readonly _type: string;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | null;
      readonly localizedImage: {
        readonly __typename?: 'LocaleImages';
        readonly locale: {
          readonly __typename?: 'Images';
          readonly imageDescription: string | null;
          readonly app: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly lockedOffersPanel: {
        readonly __typename?: 'LockedOffersPanel';
        readonly completedChallengeHeader: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly completedChallengeDescription: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly promoCodePanel: {
        readonly __typename?: 'PromoCodePanel';
        readonly promoCodeLink: string | null;
        readonly promoCodeDescription: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly promoCodeLabel: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null;
      readonly incentives: ReadonlyArray<
        | {
            readonly __typename: 'Combo';
            readonly _id: string;
            readonly _type: string;
            readonly isOfferBenefit: boolean | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
        | {
            readonly __typename: 'OfferDiscount';
            readonly _id: string;
            readonly _type: string;
            readonly discountValue: number | null;
            readonly discountType: string | null;
            readonly discountProduct:
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          }
        | {
            readonly __typename: 'Picker';
            readonly _id: string;
            readonly _type: string;
            readonly isOfferBenefit: boolean | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'PickerOption';
              readonly option:
                | {
                    readonly __typename: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | null;
            } | null> | null;
          }
        | { readonly __typename: 'SwapMapping'; readonly _type: string }
        | null
      > | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      readonly rules: ReadonlyArray<
        | { readonly __typename?: 'BirthdayMultiplier' }
        | { readonly __typename?: 'CartLimit' }
        | { readonly __typename?: 'CartRequirement' }
        | { readonly __typename?: 'CartRequirementExclusion' }
        | { readonly __typename?: 'CoolDown' }
        | { readonly __typename?: 'DayOfWeekBand' }
        | { readonly __typename?: 'EmailVerifiedRequired' }
        | { readonly __typename?: 'FirstOrderOnly' }
        | { readonly __typename?: 'IsStackable' }
        | { readonly __typename?: 'Limit' }
        | {
            readonly __typename?: 'LoyaltyBetweenDates';
            readonly startDate: any | null;
            readonly endDate: any | null;
          }
        | { readonly __typename?: 'LoyaltyBetweenTimes' }
        | { readonly __typename?: 'PaymentMethod' }
        | {
            readonly __typename?: 'RequiresAuthentication';
            readonly requiresAuthentication: boolean | null;
          }
        | { readonly __typename?: 'RestaurantGroupRestrictions' }
        | { readonly __typename?: 'ServiceModeRestrictions' }
        | { readonly __typename?: 'SubtotalSpend' }
        | { readonly __typename?: 'TierList' }
        | { readonly __typename?: 'TotalSpend' }
        | { readonly __typename?: 'TransactionCount' }
        | {
            readonly __typename?: 'UserAttributes';
            readonly userAttributesItem: ReadonlyArray<{
              readonly __typename?: 'UserAttributesItem';
              readonly attributeItem: string | null;
              readonly useAttributeBatteryLevelFilter: string | null;
              readonly useAttributeBatteryLevelValue: number | null;
              readonly userAttributeBooleanValue: string | null;
              readonly userAttributeStringFilter: string | null;
              readonly userAttributeStringValue: string | null;
            } | null> | null;
          }
        | { readonly __typename?: 'UserCreated' }
        | null
      > | null;
    } | null;
  } | null;
  readonly membershipModalsContent: {
    readonly __typename?: 'MembershipsModalsContent';
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly membershipActivationSuccess: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly membershipCardRemoval: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly membershipAccountLinkFailure: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly membershipBenefitActivationPending: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly membershipExpired: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly membershipOfferAssignmentFailure: {
      readonly __typename?: 'MembershipsModalsContentDetails';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly firstButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly firstButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonPath: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly secondButtonText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null;
  readonly membershipIntegrationTypeContent: {
    readonly __typename?: 'MembershipsIntegrationTypeContent';
    readonly membershipIntegrationType: string | null;
    readonly externalIntegrationPath: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly externalIntegrationText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly membershipPageConfiguration: {
    readonly __typename?: 'MembershipsPageStyleContent';
    readonly informationWidgets: {
      readonly __typename?: 'MembershipsInformationWidgets';
      readonly widgets: ReadonlyArray<{
        readonly __typename?: 'MembershipsWidgetContent';
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
        readonly widgetImage: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly widgetBackgroundColor: {
          readonly __typename?: 'Color';
          readonly alpha: number | null;
          readonly hex: string | null;
          readonly hsl: {
            readonly __typename?: 'HslaColor';
            readonly _type: string | null;
            readonly a: number | null;
            readonly h: number | null;
            readonly l: number | null;
            readonly s: number | null;
          } | null;
          readonly hsv: {
            readonly __typename?: 'HsvaColor';
            readonly _type: string | null;
            readonly a: number | null;
            readonly h: number | null;
            readonly s: number | null;
            readonly v: number | null;
          } | null;
          readonly rgb: {
            readonly __typename?: 'RgbaColor';
            readonly _type: string | null;
            readonly a: number | null;
            readonly b: number | null;
            readonly g: number | null;
            readonly r: number | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly membershipPageStyle: {
      readonly __typename?: 'MembershipsPageStyle';
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly actionButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly actionButtonLink: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly membershipInternalPageConfiguration: {
    readonly __typename?: 'MembershipInternalPageConfiguration';
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  } | null;
};

export type IMenuImageFragment = {
  readonly __typename?: 'Image';
  readonly hotspot: {
    readonly __typename?: 'SanityImageHotspot';
    readonly x: number | null;
    readonly y: number | null;
    readonly height: number | null;
    readonly width: number | null;
  } | null;
  readonly crop: {
    readonly __typename?: 'SanityImageCrop';
    readonly top: number | null;
    readonly bottom: number | null;
    readonly left: number | null;
    readonly right: number | null;
  } | null;
  readonly asset: {
    readonly __typename?: 'SanityImageAsset';
    readonly _id: string;
    readonly metadata: {
      readonly __typename?: 'SanityImageMetadata';
      readonly lqip: string | null;
    } | null;
  } | null;
};

export type IMenuImagesFragment = {
  readonly __typename?: 'Images';
  readonly imageDescription: string | null;
  readonly app: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type INotificationDetailsFragment = {
  readonly __typename?: 'NotificationDetails';
  readonly enabled: boolean | null;
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly path: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type IConditionAllergenFragment = {
  readonly __typename?: 'ConditionAllergen';
  readonly _type: string | null;
  readonly allergenIdentifier: string | null;
  readonly comparisonOperator: string | null;
  readonly comparisonValue: number | null;
};

export type IConditionNutritionFragment = {
  readonly __typename?: 'ConditionNutrition';
  readonly _type: string | null;
  readonly nutritionIdentifier: string | null;
  readonly comparisonOperator: string | null;
  readonly comparisonValue: number | null;
};

export type IConditionParentCategoryFragment = {
  readonly __typename?: 'ConditionParentCategory';
  readonly _type: string | null;
  readonly parentCategory:
    | { readonly __typename?: 'Picker'; readonly _id: string }
    | { readonly __typename?: 'Section'; readonly _id: string }
    | null;
};

export type IConditionItemOneOfFragment = {
  readonly __typename?: 'ConditionItemOneOf';
  readonly _type: string | null;
  readonly items: ReadonlyArray<{
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly productSize: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly default: boolean | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
  } | null> | null;
};

export type IFilterFragment = {
  readonly __typename?: 'Filter';
  readonly _type: string | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly conditions: ReadonlyArray<
    | {
        readonly __typename?: 'ConditionAllergen';
        readonly _type: string | null;
        readonly allergenIdentifier: string | null;
        readonly comparisonOperator: string | null;
        readonly comparisonValue: number | null;
      }
    | {
        readonly __typename?: 'ConditionItemOneOf';
        readonly _type: string | null;
        readonly items: ReadonlyArray<{
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly productSize: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
        } | null> | null;
      }
    | {
        readonly __typename?: 'ConditionNutrition';
        readonly _type: string | null;
        readonly nutritionIdentifier: string | null;
        readonly comparisonOperator: string | null;
        readonly comparisonValue: number | null;
      }
    | {
        readonly __typename?: 'ConditionParentCategory';
        readonly _type: string | null;
        readonly parentCategory:
          | { readonly __typename?: 'Picker'; readonly _id: string }
          | { readonly __typename?: 'Section'; readonly _id: string }
          | null;
      }
    | null
  > | null;
};

export type IFilterGroupFragment = {
  readonly __typename?: 'FilterGroup';
  readonly _type: string | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly filters: ReadonlyArray<{
    readonly __typename?: 'Filter';
    readonly _type: string | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly conditions: ReadonlyArray<
      | {
          readonly __typename?: 'ConditionAllergen';
          readonly _type: string | null;
          readonly allergenIdentifier: string | null;
          readonly comparisonOperator: string | null;
          readonly comparisonValue: number | null;
        }
      | {
          readonly __typename?: 'ConditionItemOneOf';
          readonly _type: string | null;
          readonly items: ReadonlyArray<{
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'ConditionNutrition';
          readonly _type: string | null;
          readonly nutritionIdentifier: string | null;
          readonly comparisonOperator: string | null;
          readonly comparisonValue: number | null;
        }
      | {
          readonly __typename?: 'ConditionParentCategory';
          readonly _type: string | null;
          readonly parentCategory:
            | { readonly __typename?: 'Picker'; readonly _id: string }
            | { readonly __typename?: 'Section'; readonly _id: string }
            | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type INutritionExplorerItemFragment = {
  readonly __typename?: 'Item';
  readonly _id: string;
  readonly _type: string;
  readonly productSize: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'ItemOption';
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOptionModifier';
      readonly default: boolean | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
  readonly nutrition: {
    readonly __typename?: 'Nutrition';
    readonly calories: number | null;
    readonly caloriesPer100: number | null;
    readonly carbohydrates: number | null;
    readonly carbohydratesPer100: number | null;
    readonly cholesterol: number | null;
    readonly energyKJ: number | null;
    readonly energyKJPer100: number | null;
    readonly fat: number | null;
    readonly fatPer100: number | null;
    readonly fiber: number | null;
    readonly proteins: number | null;
    readonly proteinsPer100: number | null;
    readonly salt: number | null;
    readonly saltPer100: number | null;
    readonly saturatedFat: number | null;
    readonly saturatedFatPer100: number | null;
    readonly sodium: number | null;
    readonly sugar: number | null;
    readonly sugarPer100: number | null;
    readonly transFat: number | null;
    readonly weight: number | null;
  } | null;
  readonly additionalItemInformation: {
    readonly __typename?: 'AdditionalItemInformation';
    readonly ingredients: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly additives: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly producerDetails: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly nutritionWithModifiers: {
    readonly __typename?: 'Nutrition';
    readonly calories: number | null;
    readonly caloriesPer100: number | null;
    readonly carbohydrates: number | null;
    readonly carbohydratesPer100: number | null;
    readonly cholesterol: number | null;
    readonly energyKJ: number | null;
    readonly energyKJPer100: number | null;
    readonly fat: number | null;
    readonly fatPer100: number | null;
    readonly fiber: number | null;
    readonly proteins: number | null;
    readonly proteinsPer100: number | null;
    readonly salt: number | null;
    readonly saltPer100: number | null;
    readonly saturatedFat: number | null;
    readonly saturatedFatPer100: number | null;
    readonly sodium: number | null;
    readonly sugar: number | null;
    readonly sugarPer100: number | null;
    readonly transFat: number | null;
    readonly weight: number | null;
  } | null;
  readonly allergens: {
    readonly __typename?: 'OpAllergen';
    readonly milk: number | null;
    readonly eggs: number | null;
    readonly fish: number | null;
    readonly peanuts: number | null;
    readonly shellfish: number | null;
    readonly treeNuts: number | null;
    readonly soy: number | null;
    readonly wheat: number | null;
    readonly mustard: number | null;
    readonly sesame: number | null;
    readonly celery: number | null;
    readonly lupin: number | null;
    readonly gluten: number | null;
    readonly sulphurDioxide: number | null;
  } | null;
};

export type INutritionExplorerComboFragment = {
  readonly __typename?: 'Combo';
  readonly _id: string;
  readonly _type: string;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly mainItem: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly productSize: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly default: boolean | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
  } | null;
};

export type INutritionExplorerPickerFragment = {
  readonly __typename?: 'Picker';
  readonly _id: string;
  readonly _type: string;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly pickerDefaults: ReadonlyArray<{
    readonly __typename?: 'PickerItemMapping';
    readonly pickerAspectValueIdentifier: string | null;
    readonly pickerAspect: { readonly __typename?: 'PickerAspect'; readonly _id: string } | null;
  } | null> | null;
  readonly pickerAspects: ReadonlyArray<{
    readonly __typename?: 'PickerAspect';
    readonly _id: string;
    readonly _type: string;
    readonly pickerAspectOptions: ReadonlyArray<{
      readonly __typename?: 'PickerAspectValue';
      readonly identifier: string | null;
    } | null> | null;
  } | null> | null;
  readonly pickerAspectItemOptionMappings: ReadonlyArray<{
    readonly __typename?: 'PickerAspectItemOptions';
    readonly pickerAspect: { readonly __typename?: 'PickerAspect'; readonly _id: string } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ListItem';
      readonly value: string | null;
    } | null> | null;
  } | null> | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'PickerOption';
    readonly pickerItemMappings: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: { readonly __typename?: 'PickerAspect'; readonly _id: string } | null;
    } | null> | null;
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly productSize: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
        }
      | null;
  } | null> | null;
};

export type INutritionExplorerSectionFragment = {
  readonly __typename?: 'Section';
  readonly _id: string;
  readonly _type: string;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly carouselImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly options: ReadonlyArray<
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly productSize: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly productSize: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOption';
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOptionModifier';
            readonly default: boolean | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
        readonly additionalItemInformation: {
          readonly __typename?: 'AdditionalItemInformation';
          readonly ingredients: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly additives: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly producerDetails: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly nutritionWithModifiers: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
        readonly allergens: {
          readonly __typename?: 'OpAllergen';
          readonly milk: number | null;
          readonly eggs: number | null;
          readonly fish: number | null;
          readonly peanuts: number | null;
          readonly shellfish: number | null;
          readonly treeNuts: number | null;
          readonly soy: number | null;
          readonly wheat: number | null;
          readonly mustard: number | null;
          readonly sesame: number | null;
          readonly celery: number | null;
          readonly lupin: number | null;
          readonly gluten: number | null;
          readonly sulphurDioxide: number | null;
        } | null;
      }
    | { readonly __typename?: 'Offer' }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly pickerDefaults: ReadonlyArray<{
          readonly __typename?: 'PickerItemMapping';
          readonly pickerAspectValueIdentifier: string | null;
          readonly pickerAspect: {
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
          } | null;
        } | null> | null;
        readonly pickerAspects: ReadonlyArray<{
          readonly __typename?: 'PickerAspect';
          readonly _id: string;
          readonly _type: string;
          readonly pickerAspectOptions: ReadonlyArray<{
            readonly __typename?: 'PickerAspectValue';
            readonly identifier: string | null;
          } | null> | null;
        } | null> | null;
        readonly pickerAspectItemOptionMappings: ReadonlyArray<{
          readonly __typename?: 'PickerAspectItemOptions';
          readonly pickerAspect: {
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ListItem';
            readonly value: string | null;
          } | null> | null;
        } | null> | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly pickerItemMappings: ReadonlyArray<{
            readonly __typename?: 'PickerItemMapping';
            readonly pickerAspectValueIdentifier: string | null;
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
            } | null;
          } | null> | null;
          readonly option:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly default: boolean | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly productSize: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOption';
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOptionModifier';
                    readonly default: boolean | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                  } | null> | null;
                } | null> | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly additionalItemInformation: {
                  readonly __typename?: 'AdditionalItemInformation';
                  readonly ingredients: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly additives: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly producerDetails: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly nutritionWithModifiers: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly allergens: {
                  readonly __typename?: 'OpAllergen';
                  readonly milk: number | null;
                  readonly eggs: number | null;
                  readonly fish: number | null;
                  readonly peanuts: number | null;
                  readonly shellfish: number | null;
                  readonly treeNuts: number | null;
                  readonly soy: number | null;
                  readonly wheat: number | null;
                  readonly mustard: number | null;
                  readonly sesame: number | null;
                  readonly celery: number | null;
                  readonly lupin: number | null;
                  readonly gluten: number | null;
                  readonly sulphurDioxide: number | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | {
        readonly __typename?: 'Section';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly options: ReadonlyArray<
          | { readonly __typename?: 'Combo' }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
            }
          | { readonly __typename?: 'Offer' }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pickerDefaults: ReadonlyArray<{
                readonly __typename?: 'PickerItemMapping';
                readonly pickerAspectValueIdentifier: string | null;
                readonly pickerAspect: {
                  readonly __typename?: 'PickerAspect';
                  readonly _id: string;
                } | null;
              } | null> | null;
              readonly pickerAspects: ReadonlyArray<{
                readonly __typename?: 'PickerAspect';
                readonly _id: string;
                readonly _type: string;
                readonly pickerAspectOptions: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectValue';
                  readonly identifier: string | null;
                } | null> | null;
              } | null> | null;
              readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                readonly __typename?: 'PickerAspectItemOptions';
                readonly pickerAspect: {
                  readonly __typename?: 'PickerAspect';
                  readonly _id: string;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ListItem';
                  readonly value: string | null;
                } | null> | null;
              } | null> | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly pickerItemMappings: ReadonlyArray<{
                  readonly __typename?: 'PickerItemMapping';
                  readonly pickerAspectValueIdentifier: string | null;
                  readonly pickerAspect: {
                    readonly __typename?: 'PickerAspect';
                    readonly _id: string;
                  } | null;
                } | null> | null;
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly productSize: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOption';
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOptionModifier';
                            readonly default: boolean | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                          } | null> | null;
                        } | null> | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly additionalItemInformation: {
                          readonly __typename?: 'AdditionalItemInformation';
                          readonly ingredients: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly additives: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly producerDetails: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly nutritionWithModifiers: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly allergens: {
                          readonly __typename?: 'OpAllergen';
                          readonly milk: number | null;
                          readonly eggs: number | null;
                          readonly fish: number | null;
                          readonly peanuts: number | null;
                          readonly shellfish: number | null;
                          readonly treeNuts: number | null;
                          readonly soy: number | null;
                          readonly wheat: number | null;
                          readonly mustard: number | null;
                          readonly sesame: number | null;
                          readonly celery: number | null;
                          readonly lupin: number | null;
                          readonly gluten: number | null;
                          readonly sulphurDioxide: number | null;
                        } | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'Section';
              readonly _type: string;
              readonly _id: string;
              readonly options: ReadonlyArray<
                | { readonly __typename?: 'Combo' }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly default: boolean | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                  }
                | { readonly __typename?: 'Offer' }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pickerDefaults: ReadonlyArray<{
                      readonly __typename?: 'PickerItemMapping';
                      readonly pickerAspectValueIdentifier: string | null;
                      readonly pickerAspect: {
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                      } | null;
                    } | null> | null;
                    readonly pickerAspects: ReadonlyArray<{
                      readonly __typename?: 'PickerAspect';
                      readonly _id: string;
                      readonly _type: string;
                      readonly pickerAspectOptions: ReadonlyArray<{
                        readonly __typename?: 'PickerAspectValue';
                        readonly identifier: string | null;
                      } | null> | null;
                    } | null> | null;
                    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                      readonly __typename?: 'PickerAspectItemOptions';
                      readonly pickerAspect: {
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ListItem';
                        readonly value: string | null;
                      } | null> | null;
                    } | null> | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly pickerItemMappings: ReadonlyArray<{
                        readonly __typename?: 'PickerItemMapping';
                        readonly pickerAspectValueIdentifier: string | null;
                        readonly pickerAspect: {
                          readonly __typename?: 'PickerAspect';
                          readonly _id: string;
                        } | null;
                      } | null> | null;
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly productSize: string | null;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOption';
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOptionModifier';
                                  readonly default: boolean | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                } | null> | null;
                              } | null> | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly additionalItemInformation: {
                                readonly __typename?: 'AdditionalItemInformation';
                                readonly ingredients: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly additives: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly producerDetails: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                              readonly nutritionWithModifiers: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly allergens: {
                                readonly __typename?: 'OpAllergen';
                                readonly milk: number | null;
                                readonly eggs: number | null;
                                readonly fish: number | null;
                                readonly peanuts: number | null;
                                readonly shellfish: number | null;
                                readonly treeNuts: number | null;
                                readonly soy: number | null;
                                readonly wheat: number | null;
                                readonly mustard: number | null;
                                readonly sesame: number | null;
                                readonly celery: number | null;
                                readonly lupin: number | null;
                                readonly gluten: number | null;
                                readonly sulphurDioxide: number | null;
                              } | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly productSize: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOption';
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOptionModifier';
                                readonly default: boolean | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly additionalItemInformation: {
                              readonly __typename?: 'AdditionalItemInformation';
                              readonly ingredients: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly additives: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly producerDetails: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                            readonly nutritionWithModifiers: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly allergens: {
                              readonly __typename?: 'OpAllergen';
                              readonly milk: number | null;
                              readonly eggs: number | null;
                              readonly fish: number | null;
                              readonly peanuts: number | null;
                              readonly shellfish: number | null;
                              readonly treeNuts: number | null;
                              readonly soy: number | null;
                              readonly wheat: number | null;
                              readonly mustard: number | null;
                              readonly sesame: number | null;
                              readonly celery: number | null;
                              readonly lupin: number | null;
                              readonly gluten: number | null;
                              readonly sulphurDioxide: number | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | { readonly __typename?: 'Section' }
                | null
              > | null;
            }
          | null
        > | null;
      }
    | null
  > | null;
};

export type INutritionExplorerMenuFragment = {
  readonly __typename?: 'Menu';
  readonly _id: string;
  readonly _type: string;
  readonly options: ReadonlyArray<
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly productSize: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly productSize: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOption';
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOptionModifier';
            readonly default: boolean | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
        readonly additionalItemInformation: {
          readonly __typename?: 'AdditionalItemInformation';
          readonly ingredients: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly additives: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly producerDetails: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly nutritionWithModifiers: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
        readonly allergens: {
          readonly __typename?: 'OpAllergen';
          readonly milk: number | null;
          readonly eggs: number | null;
          readonly fish: number | null;
          readonly peanuts: number | null;
          readonly shellfish: number | null;
          readonly treeNuts: number | null;
          readonly soy: number | null;
          readonly wheat: number | null;
          readonly mustard: number | null;
          readonly sesame: number | null;
          readonly celery: number | null;
          readonly lupin: number | null;
          readonly gluten: number | null;
          readonly sulphurDioxide: number | null;
        } | null;
      }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly pickerDefaults: ReadonlyArray<{
          readonly __typename?: 'PickerItemMapping';
          readonly pickerAspectValueIdentifier: string | null;
          readonly pickerAspect: {
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
          } | null;
        } | null> | null;
        readonly pickerAspects: ReadonlyArray<{
          readonly __typename?: 'PickerAspect';
          readonly _id: string;
          readonly _type: string;
          readonly pickerAspectOptions: ReadonlyArray<{
            readonly __typename?: 'PickerAspectValue';
            readonly identifier: string | null;
          } | null> | null;
        } | null> | null;
        readonly pickerAspectItemOptionMappings: ReadonlyArray<{
          readonly __typename?: 'PickerAspectItemOptions';
          readonly pickerAspect: {
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ListItem';
            readonly value: string | null;
          } | null> | null;
        } | null> | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly pickerItemMappings: ReadonlyArray<{
            readonly __typename?: 'PickerItemMapping';
            readonly pickerAspectValueIdentifier: string | null;
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
            } | null;
          } | null> | null;
          readonly option:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly default: boolean | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly productSize: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOption';
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOptionModifier';
                    readonly default: boolean | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                  } | null> | null;
                } | null> | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly additionalItemInformation: {
                  readonly __typename?: 'AdditionalItemInformation';
                  readonly ingredients: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly additives: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly producerDetails: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly nutritionWithModifiers: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly allergens: {
                  readonly __typename?: 'OpAllergen';
                  readonly milk: number | null;
                  readonly eggs: number | null;
                  readonly fish: number | null;
                  readonly peanuts: number | null;
                  readonly shellfish: number | null;
                  readonly treeNuts: number | null;
                  readonly soy: number | null;
                  readonly wheat: number | null;
                  readonly mustard: number | null;
                  readonly sesame: number | null;
                  readonly celery: number | null;
                  readonly lupin: number | null;
                  readonly gluten: number | null;
                  readonly sulphurDioxide: number | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | {
        readonly __typename?: 'Section';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly carouselImage: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly productSize: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOption';
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOptionModifier';
                    readonly default: boolean | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                  } | null> | null;
                } | null> | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly additionalItemInformation: {
                  readonly __typename?: 'AdditionalItemInformation';
                  readonly ingredients: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly additives: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly producerDetails: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly nutritionWithModifiers: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly allergens: {
                  readonly __typename?: 'OpAllergen';
                  readonly milk: number | null;
                  readonly eggs: number | null;
                  readonly fish: number | null;
                  readonly peanuts: number | null;
                  readonly shellfish: number | null;
                  readonly treeNuts: number | null;
                  readonly soy: number | null;
                  readonly wheat: number | null;
                  readonly mustard: number | null;
                  readonly sesame: number | null;
                  readonly celery: number | null;
                  readonly lupin: number | null;
                  readonly gluten: number | null;
                  readonly sulphurDioxide: number | null;
                } | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
            }
          | { readonly __typename?: 'Offer' }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pickerDefaults: ReadonlyArray<{
                readonly __typename?: 'PickerItemMapping';
                readonly pickerAspectValueIdentifier: string | null;
                readonly pickerAspect: {
                  readonly __typename?: 'PickerAspect';
                  readonly _id: string;
                } | null;
              } | null> | null;
              readonly pickerAspects: ReadonlyArray<{
                readonly __typename?: 'PickerAspect';
                readonly _id: string;
                readonly _type: string;
                readonly pickerAspectOptions: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectValue';
                  readonly identifier: string | null;
                } | null> | null;
              } | null> | null;
              readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                readonly __typename?: 'PickerAspectItemOptions';
                readonly pickerAspect: {
                  readonly __typename?: 'PickerAspect';
                  readonly _id: string;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ListItem';
                  readonly value: string | null;
                } | null> | null;
              } | null> | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly pickerItemMappings: ReadonlyArray<{
                  readonly __typename?: 'PickerItemMapping';
                  readonly pickerAspectValueIdentifier: string | null;
                  readonly pickerAspect: {
                    readonly __typename?: 'PickerAspect';
                    readonly _id: string;
                  } | null;
                } | null> | null;
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly productSize: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOption';
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOptionModifier';
                            readonly default: boolean | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                          } | null> | null;
                        } | null> | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly additionalItemInformation: {
                          readonly __typename?: 'AdditionalItemInformation';
                          readonly ingredients: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly additives: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly producerDetails: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly nutritionWithModifiers: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly allergens: {
                          readonly __typename?: 'OpAllergen';
                          readonly milk: number | null;
                          readonly eggs: number | null;
                          readonly fish: number | null;
                          readonly peanuts: number | null;
                          readonly shellfish: number | null;
                          readonly treeNuts: number | null;
                          readonly soy: number | null;
                          readonly wheat: number | null;
                          readonly mustard: number | null;
                          readonly sesame: number | null;
                          readonly celery: number | null;
                          readonly lupin: number | null;
                          readonly gluten: number | null;
                          readonly sulphurDioxide: number | null;
                        } | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'Section';
              readonly _id: string;
              readonly _type: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly options: ReadonlyArray<
                | { readonly __typename?: 'Combo' }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly default: boolean | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                  }
                | { readonly __typename?: 'Offer' }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pickerDefaults: ReadonlyArray<{
                      readonly __typename?: 'PickerItemMapping';
                      readonly pickerAspectValueIdentifier: string | null;
                      readonly pickerAspect: {
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                      } | null;
                    } | null> | null;
                    readonly pickerAspects: ReadonlyArray<{
                      readonly __typename?: 'PickerAspect';
                      readonly _id: string;
                      readonly _type: string;
                      readonly pickerAspectOptions: ReadonlyArray<{
                        readonly __typename?: 'PickerAspectValue';
                        readonly identifier: string | null;
                      } | null> | null;
                    } | null> | null;
                    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                      readonly __typename?: 'PickerAspectItemOptions';
                      readonly pickerAspect: {
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ListItem';
                        readonly value: string | null;
                      } | null> | null;
                    } | null> | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly pickerItemMappings: ReadonlyArray<{
                        readonly __typename?: 'PickerItemMapping';
                        readonly pickerAspectValueIdentifier: string | null;
                        readonly pickerAspect: {
                          readonly __typename?: 'PickerAspect';
                          readonly _id: string;
                        } | null;
                      } | null> | null;
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly productSize: string | null;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOption';
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOptionModifier';
                                  readonly default: boolean | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                } | null> | null;
                              } | null> | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly additionalItemInformation: {
                                readonly __typename?: 'AdditionalItemInformation';
                                readonly ingredients: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly additives: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly producerDetails: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                              readonly nutritionWithModifiers: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly allergens: {
                                readonly __typename?: 'OpAllergen';
                                readonly milk: number | null;
                                readonly eggs: number | null;
                                readonly fish: number | null;
                                readonly peanuts: number | null;
                                readonly shellfish: number | null;
                                readonly treeNuts: number | null;
                                readonly soy: number | null;
                                readonly wheat: number | null;
                                readonly mustard: number | null;
                                readonly sesame: number | null;
                                readonly celery: number | null;
                                readonly lupin: number | null;
                                readonly gluten: number | null;
                                readonly sulphurDioxide: number | null;
                              } | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly productSize: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOption';
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOptionModifier';
                                readonly default: boolean | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly additionalItemInformation: {
                              readonly __typename?: 'AdditionalItemInformation';
                              readonly ingredients: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly additives: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly producerDetails: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                            readonly nutritionWithModifiers: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly allergens: {
                              readonly __typename?: 'OpAllergen';
                              readonly milk: number | null;
                              readonly eggs: number | null;
                              readonly fish: number | null;
                              readonly peanuts: number | null;
                              readonly shellfish: number | null;
                              readonly treeNuts: number | null;
                              readonly soy: number | null;
                              readonly wheat: number | null;
                              readonly mustard: number | null;
                              readonly sesame: number | null;
                              readonly celery: number | null;
                              readonly lupin: number | null;
                              readonly gluten: number | null;
                              readonly sulphurDioxide: number | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'Section';
                    readonly _type: string;
                    readonly _id: string;
                    readonly options: ReadonlyArray<
                      | { readonly __typename?: 'Combo' }
                      | {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly productSize: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOption';
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOptionModifier';
                              readonly default: boolean | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                            } | null> | null;
                          } | null> | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly additionalItemInformation: {
                            readonly __typename?: 'AdditionalItemInformation';
                            readonly ingredients: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly additives: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly producerDetails: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                          readonly nutritionWithModifiers: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly allergens: {
                            readonly __typename?: 'OpAllergen';
                            readonly milk: number | null;
                            readonly eggs: number | null;
                            readonly fish: number | null;
                            readonly peanuts: number | null;
                            readonly shellfish: number | null;
                            readonly treeNuts: number | null;
                            readonly soy: number | null;
                            readonly wheat: number | null;
                            readonly mustard: number | null;
                            readonly sesame: number | null;
                            readonly celery: number | null;
                            readonly lupin: number | null;
                            readonly gluten: number | null;
                            readonly sulphurDioxide: number | null;
                          } | null;
                        }
                      | { readonly __typename?: 'Offer' }
                      | {
                          readonly __typename?: 'Picker';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pickerDefaults: ReadonlyArray<{
                            readonly __typename?: 'PickerItemMapping';
                            readonly pickerAspectValueIdentifier: string | null;
                            readonly pickerAspect: {
                              readonly __typename?: 'PickerAspect';
                              readonly _id: string;
                            } | null;
                          } | null> | null;
                          readonly pickerAspects: ReadonlyArray<{
                            readonly __typename?: 'PickerAspect';
                            readonly _id: string;
                            readonly _type: string;
                            readonly pickerAspectOptions: ReadonlyArray<{
                              readonly __typename?: 'PickerAspectValue';
                              readonly identifier: string | null;
                            } | null> | null;
                          } | null> | null;
                          readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                            readonly __typename?: 'PickerAspectItemOptions';
                            readonly pickerAspect: {
                              readonly __typename?: 'PickerAspect';
                              readonly _id: string;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ListItem';
                              readonly value: string | null;
                            } | null> | null;
                          } | null> | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'PickerOption';
                            readonly pickerItemMappings: ReadonlyArray<{
                              readonly __typename?: 'PickerItemMapping';
                              readonly pickerAspectValueIdentifier: string | null;
                              readonly pickerAspect: {
                                readonly __typename?: 'PickerAspect';
                                readonly _id: string;
                              } | null;
                            } | null> | null;
                            readonly option:
                              | {
                                  readonly __typename?: 'Combo';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly image: {
                                    readonly __typename?: 'Image';
                                    readonly hotspot: {
                                      readonly __typename?: 'SanityImageHotspot';
                                      readonly x: number | null;
                                      readonly y: number | null;
                                      readonly height: number | null;
                                      readonly width: number | null;
                                    } | null;
                                    readonly crop: {
                                      readonly __typename?: 'SanityImageCrop';
                                      readonly top: number | null;
                                      readonly bottom: number | null;
                                      readonly left: number | null;
                                      readonly right: number | null;
                                    } | null;
                                    readonly asset: {
                                      readonly __typename?: 'SanityImageAsset';
                                      readonly _id: string;
                                      readonly metadata: {
                                        readonly __typename?: 'SanityImageMetadata';
                                        readonly lqip: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly mainItem: {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly productSize: string | null;
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'ItemOption';
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ItemOptionModifier';
                                        readonly default: boolean | null;
                                        readonly nutrition: {
                                          readonly __typename?: 'Nutrition';
                                          readonly calories: number | null;
                                          readonly caloriesPer100: number | null;
                                          readonly carbohydrates: number | null;
                                          readonly carbohydratesPer100: number | null;
                                          readonly cholesterol: number | null;
                                          readonly energyKJ: number | null;
                                          readonly energyKJPer100: number | null;
                                          readonly fat: number | null;
                                          readonly fatPer100: number | null;
                                          readonly fiber: number | null;
                                          readonly proteins: number | null;
                                          readonly proteinsPer100: number | null;
                                          readonly salt: number | null;
                                          readonly saltPer100: number | null;
                                          readonly saturatedFat: number | null;
                                          readonly saturatedFatPer100: number | null;
                                          readonly sodium: number | null;
                                          readonly sugar: number | null;
                                          readonly sugarPer100: number | null;
                                          readonly transFat: number | null;
                                          readonly weight: number | null;
                                        } | null;
                                      } | null> | null;
                                    } | null> | null;
                                    readonly nutrition: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly additionalItemInformation: {
                                      readonly __typename?: 'AdditionalItemInformation';
                                      readonly ingredients: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly additives: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly producerDetails: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                    } | null;
                                    readonly nutritionWithModifiers: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly allergens: {
                                      readonly __typename?: 'OpAllergen';
                                      readonly milk: number | null;
                                      readonly eggs: number | null;
                                      readonly fish: number | null;
                                      readonly peanuts: number | null;
                                      readonly shellfish: number | null;
                                      readonly treeNuts: number | null;
                                      readonly soy: number | null;
                                      readonly wheat: number | null;
                                      readonly mustard: number | null;
                                      readonly sesame: number | null;
                                      readonly celery: number | null;
                                      readonly lupin: number | null;
                                      readonly gluten: number | null;
                                      readonly sulphurDioxide: number | null;
                                    } | null;
                                  } | null;
                                }
                              | {
                                  readonly __typename?: 'Item';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly productSize: string | null;
                                  readonly name: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly image: {
                                    readonly __typename?: 'Image';
                                    readonly hotspot: {
                                      readonly __typename?: 'SanityImageHotspot';
                                      readonly x: number | null;
                                      readonly y: number | null;
                                      readonly height: number | null;
                                      readonly width: number | null;
                                    } | null;
                                    readonly crop: {
                                      readonly __typename?: 'SanityImageCrop';
                                      readonly top: number | null;
                                      readonly bottom: number | null;
                                      readonly left: number | null;
                                      readonly right: number | null;
                                    } | null;
                                    readonly asset: {
                                      readonly __typename?: 'SanityImageAsset';
                                      readonly _id: string;
                                      readonly metadata: {
                                        readonly __typename?: 'SanityImageMetadata';
                                        readonly lqip: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly options: ReadonlyArray<{
                                    readonly __typename?: 'ItemOption';
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'ItemOptionModifier';
                                      readonly default: boolean | null;
                                      readonly nutrition: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                    } | null> | null;
                                  } | null> | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                  readonly additionalItemInformation: {
                                    readonly __typename?: 'AdditionalItemInformation';
                                    readonly ingredients: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly additives: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly producerDetails: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                  } | null;
                                  readonly nutritionWithModifiers: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                  readonly allergens: {
                                    readonly __typename?: 'OpAllergen';
                                    readonly milk: number | null;
                                    readonly eggs: number | null;
                                    readonly fish: number | null;
                                    readonly peanuts: number | null;
                                    readonly shellfish: number | null;
                                    readonly treeNuts: number | null;
                                    readonly soy: number | null;
                                    readonly wheat: number | null;
                                    readonly mustard: number | null;
                                    readonly sesame: number | null;
                                    readonly celery: number | null;
                                    readonly lupin: number | null;
                                    readonly gluten: number | null;
                                    readonly sulphurDioxide: number | null;
                                  } | null;
                                }
                              | null;
                          } | null> | null;
                        }
                      | { readonly __typename?: 'Section' }
                      | null
                    > | null;
                  }
                | null
              > | null;
            }
          | null
        > | null;
      }
    | null
  > | null;
  readonly pickerBackgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type INutritionExplorerWidgetFragment = {
  readonly __typename?: 'NutritionExplorerWidget';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly menu: {
    readonly __typename?: 'Menu';
    readonly _id: string;
    readonly _type: string;
    readonly options: ReadonlyArray<
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly productSize: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
        }
      | {
          readonly __typename?: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly pickerDefaults: ReadonlyArray<{
            readonly __typename?: 'PickerItemMapping';
            readonly pickerAspectValueIdentifier: string | null;
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
            } | null;
          } | null> | null;
          readonly pickerAspects: ReadonlyArray<{
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
            readonly _type: string;
            readonly pickerAspectOptions: ReadonlyArray<{
              readonly __typename?: 'PickerAspectValue';
              readonly identifier: string | null;
            } | null> | null;
          } | null> | null;
          readonly pickerAspectItemOptionMappings: ReadonlyArray<{
            readonly __typename?: 'PickerAspectItemOptions';
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ListItem';
              readonly value: string | null;
            } | null> | null;
          } | null> | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly pickerItemMappings: ReadonlyArray<{
              readonly __typename?: 'PickerItemMapping';
              readonly pickerAspectValueIdentifier: string | null;
              readonly pickerAspect: {
                readonly __typename?: 'PickerAspect';
                readonly _id: string;
              } | null;
            } | null> | null;
            readonly option:
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly default: boolean | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly default: boolean | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'Section';
          readonly _id: string;
          readonly _type: string;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly carouselImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly default: boolean | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly productSize: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOption';
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOptionModifier';
                    readonly default: boolean | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                  } | null> | null;
                } | null> | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly additionalItemInformation: {
                  readonly __typename?: 'AdditionalItemInformation';
                  readonly ingredients: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly additives: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly producerDetails: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly nutritionWithModifiers: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
                readonly allergens: {
                  readonly __typename?: 'OpAllergen';
                  readonly milk: number | null;
                  readonly eggs: number | null;
                  readonly fish: number | null;
                  readonly peanuts: number | null;
                  readonly shellfish: number | null;
                  readonly treeNuts: number | null;
                  readonly soy: number | null;
                  readonly wheat: number | null;
                  readonly mustard: number | null;
                  readonly sesame: number | null;
                  readonly celery: number | null;
                  readonly lupin: number | null;
                  readonly gluten: number | null;
                  readonly sulphurDioxide: number | null;
                } | null;
              }
            | { readonly __typename?: 'Offer' }
            | {
                readonly __typename?: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pickerDefaults: ReadonlyArray<{
                  readonly __typename?: 'PickerItemMapping';
                  readonly pickerAspectValueIdentifier: string | null;
                  readonly pickerAspect: {
                    readonly __typename?: 'PickerAspect';
                    readonly _id: string;
                  } | null;
                } | null> | null;
                readonly pickerAspects: ReadonlyArray<{
                  readonly __typename?: 'PickerAspect';
                  readonly _id: string;
                  readonly _type: string;
                  readonly pickerAspectOptions: ReadonlyArray<{
                    readonly __typename?: 'PickerAspectValue';
                    readonly identifier: string | null;
                  } | null> | null;
                } | null> | null;
                readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectItemOptions';
                  readonly pickerAspect: {
                    readonly __typename?: 'PickerAspect';
                    readonly _id: string;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ListItem';
                    readonly value: string | null;
                  } | null> | null;
                } | null> | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'PickerOption';
                  readonly pickerItemMappings: ReadonlyArray<{
                    readonly __typename?: 'PickerItemMapping';
                    readonly pickerAspectValueIdentifier: string | null;
                    readonly pickerAspect: {
                      readonly __typename?: 'PickerAspect';
                      readonly _id: string;
                    } | null;
                  } | null> | null;
                  readonly option:
                    | {
                        readonly __typename?: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly productSize: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOption';
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOptionModifier';
                              readonly default: boolean | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                            } | null> | null;
                          } | null> | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly additionalItemInformation: {
                            readonly __typename?: 'AdditionalItemInformation';
                            readonly ingredients: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly additives: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly producerDetails: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                          readonly nutritionWithModifiers: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly allergens: {
                            readonly __typename?: 'OpAllergen';
                            readonly milk: number | null;
                            readonly eggs: number | null;
                            readonly fish: number | null;
                            readonly peanuts: number | null;
                            readonly shellfish: number | null;
                            readonly treeNuts: number | null;
                            readonly soy: number | null;
                            readonly wheat: number | null;
                            readonly mustard: number | null;
                            readonly sesame: number | null;
                            readonly celery: number | null;
                            readonly lupin: number | null;
                            readonly gluten: number | null;
                            readonly sulphurDioxide: number | null;
                          } | null;
                        } | null;
                      }
                    | {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly productSize: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOption';
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOptionModifier';
                            readonly default: boolean | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                          } | null> | null;
                        } | null> | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly additionalItemInformation: {
                          readonly __typename?: 'AdditionalItemInformation';
                          readonly ingredients: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly additives: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly producerDetails: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly nutritionWithModifiers: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly allergens: {
                          readonly __typename?: 'OpAllergen';
                          readonly milk: number | null;
                          readonly eggs: number | null;
                          readonly fish: number | null;
                          readonly peanuts: number | null;
                          readonly shellfish: number | null;
                          readonly treeNuts: number | null;
                          readonly soy: number | null;
                          readonly wheat: number | null;
                          readonly mustard: number | null;
                          readonly sesame: number | null;
                          readonly celery: number | null;
                          readonly lupin: number | null;
                          readonly gluten: number | null;
                          readonly sulphurDioxide: number | null;
                        } | null;
                      }
                    | null;
                } | null> | null;
              }
            | {
                readonly __typename?: 'Section';
                readonly _id: string;
                readonly _type: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly options: ReadonlyArray<
                  | { readonly __typename?: 'Combo' }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                    }
                  | { readonly __typename?: 'Offer' }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pickerDefaults: ReadonlyArray<{
                        readonly __typename?: 'PickerItemMapping';
                        readonly pickerAspectValueIdentifier: string | null;
                        readonly pickerAspect: {
                          readonly __typename?: 'PickerAspect';
                          readonly _id: string;
                        } | null;
                      } | null> | null;
                      readonly pickerAspects: ReadonlyArray<{
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                        readonly _type: string;
                        readonly pickerAspectOptions: ReadonlyArray<{
                          readonly __typename?: 'PickerAspectValue';
                          readonly identifier: string | null;
                        } | null> | null;
                      } | null> | null;
                      readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                        readonly __typename?: 'PickerAspectItemOptions';
                        readonly pickerAspect: {
                          readonly __typename?: 'PickerAspect';
                          readonly _id: string;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ListItem';
                          readonly value: string | null;
                        } | null> | null;
                      } | null> | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'PickerOption';
                        readonly pickerItemMappings: ReadonlyArray<{
                          readonly __typename?: 'PickerItemMapping';
                          readonly pickerAspectValueIdentifier: string | null;
                          readonly pickerAspect: {
                            readonly __typename?: 'PickerAspect';
                            readonly _id: string;
                          } | null;
                        } | null> | null;
                        readonly option:
                          | {
                              readonly __typename?: 'Combo';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly mainItem: {
                                readonly __typename?: 'Item';
                                readonly _id: string;
                                readonly _type: string;
                                readonly productSize: string | null;
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly image: {
                                  readonly __typename?: 'Image';
                                  readonly hotspot: {
                                    readonly __typename?: 'SanityImageHotspot';
                                    readonly x: number | null;
                                    readonly y: number | null;
                                    readonly height: number | null;
                                    readonly width: number | null;
                                  } | null;
                                  readonly crop: {
                                    readonly __typename?: 'SanityImageCrop';
                                    readonly top: number | null;
                                    readonly bottom: number | null;
                                    readonly left: number | null;
                                    readonly right: number | null;
                                  } | null;
                                  readonly asset: {
                                    readonly __typename?: 'SanityImageAsset';
                                    readonly _id: string;
                                    readonly metadata: {
                                      readonly __typename?: 'SanityImageMetadata';
                                      readonly lqip: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOption';
                                  readonly options: ReadonlyArray<{
                                    readonly __typename?: 'ItemOptionModifier';
                                    readonly default: boolean | null;
                                    readonly nutrition: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                  } | null> | null;
                                } | null> | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                                readonly additionalItemInformation: {
                                  readonly __typename?: 'AdditionalItemInformation';
                                  readonly ingredients: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly additives: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly producerDetails: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                } | null;
                                readonly nutritionWithModifiers: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                                readonly allergens: {
                                  readonly __typename?: 'OpAllergen';
                                  readonly milk: number | null;
                                  readonly eggs: number | null;
                                  readonly fish: number | null;
                                  readonly peanuts: number | null;
                                  readonly shellfish: number | null;
                                  readonly treeNuts: number | null;
                                  readonly soy: number | null;
                                  readonly wheat: number | null;
                                  readonly mustard: number | null;
                                  readonly sesame: number | null;
                                  readonly celery: number | null;
                                  readonly lupin: number | null;
                                  readonly gluten: number | null;
                                  readonly sulphurDioxide: number | null;
                                } | null;
                              } | null;
                            }
                          | {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly productSize: string | null;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOption';
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOptionModifier';
                                  readonly default: boolean | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                } | null> | null;
                              } | null> | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly additionalItemInformation: {
                                readonly __typename?: 'AdditionalItemInformation';
                                readonly ingredients: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly additives: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly producerDetails: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                              readonly nutritionWithModifiers: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly allergens: {
                                readonly __typename?: 'OpAllergen';
                                readonly milk: number | null;
                                readonly eggs: number | null;
                                readonly fish: number | null;
                                readonly peanuts: number | null;
                                readonly shellfish: number | null;
                                readonly treeNuts: number | null;
                                readonly soy: number | null;
                                readonly wheat: number | null;
                                readonly mustard: number | null;
                                readonly sesame: number | null;
                                readonly celery: number | null;
                                readonly lupin: number | null;
                                readonly gluten: number | null;
                                readonly sulphurDioxide: number | null;
                              } | null;
                            }
                          | null;
                      } | null> | null;
                    }
                  | {
                      readonly __typename?: 'Section';
                      readonly _type: string;
                      readonly _id: string;
                      readonly options: ReadonlyArray<
                        | { readonly __typename?: 'Combo' }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly productSize: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOption';
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOptionModifier';
                                readonly default: boolean | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly additionalItemInformation: {
                              readonly __typename?: 'AdditionalItemInformation';
                              readonly ingredients: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly additives: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly producerDetails: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                            readonly nutritionWithModifiers: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly allergens: {
                              readonly __typename?: 'OpAllergen';
                              readonly milk: number | null;
                              readonly eggs: number | null;
                              readonly fish: number | null;
                              readonly peanuts: number | null;
                              readonly shellfish: number | null;
                              readonly treeNuts: number | null;
                              readonly soy: number | null;
                              readonly wheat: number | null;
                              readonly mustard: number | null;
                              readonly sesame: number | null;
                              readonly celery: number | null;
                              readonly lupin: number | null;
                              readonly gluten: number | null;
                              readonly sulphurDioxide: number | null;
                            } | null;
                          }
                        | { readonly __typename?: 'Offer' }
                        | {
                            readonly __typename?: 'Picker';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pickerDefaults: ReadonlyArray<{
                              readonly __typename?: 'PickerItemMapping';
                              readonly pickerAspectValueIdentifier: string | null;
                              readonly pickerAspect: {
                                readonly __typename?: 'PickerAspect';
                                readonly _id: string;
                              } | null;
                            } | null> | null;
                            readonly pickerAspects: ReadonlyArray<{
                              readonly __typename?: 'PickerAspect';
                              readonly _id: string;
                              readonly _type: string;
                              readonly pickerAspectOptions: ReadonlyArray<{
                                readonly __typename?: 'PickerAspectValue';
                                readonly identifier: string | null;
                              } | null> | null;
                            } | null> | null;
                            readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                              readonly __typename?: 'PickerAspectItemOptions';
                              readonly pickerAspect: {
                                readonly __typename?: 'PickerAspect';
                                readonly _id: string;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ListItem';
                                readonly value: string | null;
                              } | null> | null;
                            } | null> | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'PickerOption';
                              readonly pickerItemMappings: ReadonlyArray<{
                                readonly __typename?: 'PickerItemMapping';
                                readonly pickerAspectValueIdentifier: string | null;
                                readonly pickerAspect: {
                                  readonly __typename?: 'PickerAspect';
                                  readonly _id: string;
                                } | null;
                              } | null> | null;
                              readonly option:
                                | {
                                    readonly __typename?: 'Combo';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly mainItem: {
                                      readonly __typename?: 'Item';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly productSize: string | null;
                                      readonly name: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly image: {
                                        readonly __typename?: 'Image';
                                        readonly hotspot: {
                                          readonly __typename?: 'SanityImageHotspot';
                                          readonly x: number | null;
                                          readonly y: number | null;
                                          readonly height: number | null;
                                          readonly width: number | null;
                                        } | null;
                                        readonly crop: {
                                          readonly __typename?: 'SanityImageCrop';
                                          readonly top: number | null;
                                          readonly bottom: number | null;
                                          readonly left: number | null;
                                          readonly right: number | null;
                                        } | null;
                                        readonly asset: {
                                          readonly __typename?: 'SanityImageAsset';
                                          readonly _id: string;
                                          readonly metadata: {
                                            readonly __typename?: 'SanityImageMetadata';
                                            readonly lqip: string | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ItemOption';
                                        readonly options: ReadonlyArray<{
                                          readonly __typename?: 'ItemOptionModifier';
                                          readonly default: boolean | null;
                                          readonly nutrition: {
                                            readonly __typename?: 'Nutrition';
                                            readonly calories: number | null;
                                            readonly caloriesPer100: number | null;
                                            readonly carbohydrates: number | null;
                                            readonly carbohydratesPer100: number | null;
                                            readonly cholesterol: number | null;
                                            readonly energyKJ: number | null;
                                            readonly energyKJPer100: number | null;
                                            readonly fat: number | null;
                                            readonly fatPer100: number | null;
                                            readonly fiber: number | null;
                                            readonly proteins: number | null;
                                            readonly proteinsPer100: number | null;
                                            readonly salt: number | null;
                                            readonly saltPer100: number | null;
                                            readonly saturatedFat: number | null;
                                            readonly saturatedFatPer100: number | null;
                                            readonly sodium: number | null;
                                            readonly sugar: number | null;
                                            readonly sugarPer100: number | null;
                                            readonly transFat: number | null;
                                            readonly weight: number | null;
                                          } | null;
                                        } | null> | null;
                                      } | null> | null;
                                      readonly nutrition: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                      readonly additionalItemInformation: {
                                        readonly __typename?: 'AdditionalItemInformation';
                                        readonly ingredients: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                        readonly additives: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                        readonly producerDetails: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                      } | null;
                                      readonly nutritionWithModifiers: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                      readonly allergens: {
                                        readonly __typename?: 'OpAllergen';
                                        readonly milk: number | null;
                                        readonly eggs: number | null;
                                        readonly fish: number | null;
                                        readonly peanuts: number | null;
                                        readonly shellfish: number | null;
                                        readonly treeNuts: number | null;
                                        readonly soy: number | null;
                                        readonly wheat: number | null;
                                        readonly mustard: number | null;
                                        readonly sesame: number | null;
                                        readonly celery: number | null;
                                        readonly lupin: number | null;
                                        readonly gluten: number | null;
                                        readonly sulphurDioxide: number | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly productSize: string | null;
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'ItemOption';
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ItemOptionModifier';
                                        readonly default: boolean | null;
                                        readonly nutrition: {
                                          readonly __typename?: 'Nutrition';
                                          readonly calories: number | null;
                                          readonly caloriesPer100: number | null;
                                          readonly carbohydrates: number | null;
                                          readonly carbohydratesPer100: number | null;
                                          readonly cholesterol: number | null;
                                          readonly energyKJ: number | null;
                                          readonly energyKJPer100: number | null;
                                          readonly fat: number | null;
                                          readonly fatPer100: number | null;
                                          readonly fiber: number | null;
                                          readonly proteins: number | null;
                                          readonly proteinsPer100: number | null;
                                          readonly salt: number | null;
                                          readonly saltPer100: number | null;
                                          readonly saturatedFat: number | null;
                                          readonly saturatedFatPer100: number | null;
                                          readonly sodium: number | null;
                                          readonly sugar: number | null;
                                          readonly sugarPer100: number | null;
                                          readonly transFat: number | null;
                                          readonly weight: number | null;
                                        } | null;
                                      } | null> | null;
                                    } | null> | null;
                                    readonly nutrition: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly additionalItemInformation: {
                                      readonly __typename?: 'AdditionalItemInformation';
                                      readonly ingredients: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly additives: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly producerDetails: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                    } | null;
                                    readonly nutritionWithModifiers: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly allergens: {
                                      readonly __typename?: 'OpAllergen';
                                      readonly milk: number | null;
                                      readonly eggs: number | null;
                                      readonly fish: number | null;
                                      readonly peanuts: number | null;
                                      readonly shellfish: number | null;
                                      readonly treeNuts: number | null;
                                      readonly soy: number | null;
                                      readonly wheat: number | null;
                                      readonly mustard: number | null;
                                      readonly sesame: number | null;
                                      readonly celery: number | null;
                                      readonly lupin: number | null;
                                      readonly gluten: number | null;
                                      readonly sulphurDioxide: number | null;
                                    } | null;
                                  }
                                | null;
                            } | null> | null;
                          }
                        | { readonly __typename?: 'Section' }
                        | null
                      > | null;
                    }
                  | null
                > | null;
              }
            | null
          > | null;
        }
      | null
    > | null;
    readonly pickerBackgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly viewMenuButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly moreOptionsButtonText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly madLibFilterGroup: {
    readonly __typename?: 'FilterGroup';
    readonly _type: string | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly filters: ReadonlyArray<{
      readonly __typename?: 'Filter';
      readonly _type: string | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly conditions: ReadonlyArray<
        | {
            readonly __typename?: 'ConditionAllergen';
            readonly _type: string | null;
            readonly allergenIdentifier: string | null;
            readonly comparisonOperator: string | null;
            readonly comparisonValue: number | null;
          }
        | {
            readonly __typename?: 'ConditionItemOneOf';
            readonly _type: string | null;
            readonly items: ReadonlyArray<{
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
            } | null> | null;
          }
        | {
            readonly __typename?: 'ConditionNutrition';
            readonly _type: string | null;
            readonly nutritionIdentifier: string | null;
            readonly comparisonOperator: string | null;
            readonly comparisonValue: number | null;
          }
        | {
            readonly __typename?: 'ConditionParentCategory';
            readonly _type: string | null;
            readonly parentCategory:
              | { readonly __typename?: 'Picker'; readonly _id: string }
              | { readonly __typename?: 'Section'; readonly _id: string }
              | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
  readonly modalFilterGroups: ReadonlyArray<{
    readonly __typename?: 'FilterGroup';
    readonly _type: string | null;
    readonly description: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly filters: ReadonlyArray<{
      readonly __typename?: 'Filter';
      readonly _type: string | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly conditions: ReadonlyArray<
        | {
            readonly __typename?: 'ConditionAllergen';
            readonly _type: string | null;
            readonly allergenIdentifier: string | null;
            readonly comparisonOperator: string | null;
            readonly comparisonValue: number | null;
          }
        | {
            readonly __typename?: 'ConditionItemOneOf';
            readonly _type: string | null;
            readonly items: ReadonlyArray<{
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
            } | null> | null;
          }
        | {
            readonly __typename?: 'ConditionNutrition';
            readonly _type: string | null;
            readonly nutritionIdentifier: string | null;
            readonly comparisonOperator: string | null;
            readonly comparisonValue: number | null;
          }
        | {
            readonly __typename?: 'ConditionParentCategory';
            readonly _type: string | null;
            readonly parentCategory:
              | { readonly __typename?: 'Picker'; readonly _id: string }
              | { readonly __typename?: 'Section'; readonly _id: string }
              | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null> | null;
  readonly categoryWhitelist: ReadonlyArray<
    | { readonly __typename?: 'Picker'; readonly _id: string; readonly _type: string }
    | { readonly __typename?: 'Section'; readonly _id: string; readonly _type: string }
    | null
  > | null;
};

export type INutritionFragment = {
  readonly __typename?: 'Nutrition';
  readonly calories: number | null;
  readonly caloriesPer100: number | null;
  readonly carbohydrates: number | null;
  readonly carbohydratesPer100: number | null;
  readonly cholesterol: number | null;
  readonly energyKJ: number | null;
  readonly energyKJPer100: number | null;
  readonly fat: number | null;
  readonly fatPer100: number | null;
  readonly fiber: number | null;
  readonly proteins: number | null;
  readonly proteinsPer100: number | null;
  readonly salt: number | null;
  readonly saltPer100: number | null;
  readonly saturatedFat: number | null;
  readonly saturatedFatPer100: number | null;
  readonly sodium: number | null;
  readonly sugar: number | null;
  readonly sugarPer100: number | null;
  readonly transFat: number | null;
  readonly weight: number | null;
};

export type IOfferCarouselFragment = {
  readonly __typename?: 'OfferCarouselSection';
  readonly _id: string;
  readonly offerCarouselTitle: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly offerCarouselList: ReadonlyArray<
    | {
        readonly __typename?: 'ExternalOffers';
        readonly _type: string;
        readonly _id: string;
        readonly testOnly: boolean | null;
        readonly title: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'SystemwideOffer';
        readonly _type: string;
        readonly _id: string;
        readonly testOnly: boolean | null;
        readonly loyaltyEngineId: string | null;
        readonly name: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly moreInfo: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly localizedImage: {
          readonly __typename?: 'LocaleImages';
          readonly locale: {
            readonly __typename?: 'Images';
            readonly imageDescription: string | null;
            readonly app: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly rules: ReadonlyArray<
          | { readonly __typename?: 'BirthdayMultiplier' }
          | { readonly __typename?: 'CartLimit' }
          | { readonly __typename?: 'CartRequirement' }
          | { readonly __typename?: 'CartRequirementExclusion' }
          | { readonly __typename?: 'CoolDown' }
          | { readonly __typename?: 'DayOfWeekBand' }
          | { readonly __typename?: 'EmailVerifiedRequired' }
          | { readonly __typename?: 'FirstOrderOnly' }
          | { readonly __typename?: 'IsStackable' }
          | { readonly __typename?: 'Limit' }
          | { readonly __typename?: 'LoyaltyBetweenDates' }
          | { readonly __typename?: 'LoyaltyBetweenTimes' }
          | { readonly __typename?: 'PaymentMethod' }
          | { readonly __typename?: 'RequiresAuthentication' }
          | { readonly __typename?: 'RestaurantGroupRestrictions' }
          | { readonly __typename?: 'ServiceModeRestrictions' }
          | { readonly __typename?: 'SubtotalSpend' }
          | { readonly __typename?: 'TierList' }
          | { readonly __typename?: 'TotalSpend' }
          | { readonly __typename?: 'TransactionCount' }
          | {
              readonly __typename?: 'UserAttributes';
              readonly userAttributesItem: ReadonlyArray<{
                readonly __typename?: 'UserAttributesItem';
                readonly attributeItem: string | null;
                readonly useAttributeBatteryLevelFilter: string | null;
                readonly useAttributeBatteryLevelValue: number | null;
                readonly userAttributeBooleanValue: string | null;
                readonly userAttributeStringFilter: string | null;
                readonly userAttributeStringValue: string | null;
              } | null> | null;
            }
          | { readonly __typename?: 'UserCreated' }
          | null
        > | null;
      }
    | null
  > | null;
};

export type IOffersSelectRestaurantCtaFragment = {
  readonly __typename?: 'OffersBrowsingPanel';
  readonly offersSelectRestaurantCta: {
    readonly __typename?: 'OffersSelectRestaurantCta';
    readonly minimumNumberOfOffers: number | null;
    readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly ctaButtonText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
  } | null;
};

export type IAllowRedemptionWithoutLoginFragment = {
  readonly __typename?: 'AllowRedemptionWithoutLogin';
  readonly ruleSetType: string | null;
};

export type IBetweenDatesFragment = {
  readonly __typename?: 'BetweenDates';
  readonly endDate: any | null;
  readonly startDate: any | null;
  readonly ruleSetType: string | null;
};

export type IBetweenTimesFragment = {
  readonly __typename?: 'BetweenTimes';
  readonly endTime: string | null;
  readonly startTime: string | null;
  readonly ruleSetType: string | null;
};

export type ICartPropertyFragment = {
  readonly __typename?: 'CartProperty';
  readonly key: string | null;
  readonly negated: boolean | null;
  readonly operator: string | null;
  readonly value: string | null;
  readonly ruleSetType: string | null;
};

export type ICoolDownFragment = {
  readonly __typename?: 'CoolDown';
  readonly minimumElapsedTimeSeconds: number | null;
  readonly ruleSetType: string | null;
};

export type IDayOfWeekBandFragment = {
  readonly __typename?: 'DayOfWeekBand';
  readonly monday: boolean | null;
  readonly tuesday: boolean | null;
  readonly wednesday: boolean | null;
  readonly thursday: boolean | null;
  readonly friday: boolean | null;
  readonly saturday: boolean | null;
  readonly sunday: boolean | null;
  readonly ruleSetType: string | null;
};

export type IFirstOrderOnlyFragment = {
  readonly __typename?: 'FirstOrderOnly';
  readonly ruleSetType: string | null;
};

export type ILimitFragment = {
  readonly __typename?: 'Limit';
  readonly interval: string | null;
  readonly maximumRedemptions: number | null;
  readonly ruleSetType: string | null;
};

export type IPaymentCardTypeFragment = {
  readonly __typename?: 'PaymentCardType';
  readonly cardType: string | null;
  readonly ruleSetType: string | null;
};

export type IPurchaseFragment = {
  readonly __typename?: 'Purchase';
  readonly quantity: number | null;
  readonly sanityId: string | null;
  readonly ruleSetType: string | null;
  readonly option:
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly operationalItem: {
          readonly __typename?: 'OpItem';
          readonly daypart: ReadonlyArray<string | null> | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly option:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | {
        readonly __typename?: 'ProductCategory';
        readonly _id: string;
        readonly hierarchyLevel: string | null;
        readonly ruleSetType: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      }
    | null;
};

export type IRequiresAssignmentFragment = {
  readonly __typename?: 'RequiresAssignment';
  readonly ruleSetType: string | null;
};

export type IRestaurantGroupRestrictionListFragment = {
  readonly __typename?: 'RestaurantGroupRestrictions';
  readonly ruleSetType: string | null;
  readonly restaurantGroupRestrictionList: ReadonlyArray<{
    readonly __typename?: 'RestaurantGroup';
    readonly restaurants: ReadonlyArray<{
      readonly __typename?: 'Restaurant';
      readonly number: string | null;
    } | null> | null;
  } | null> | null;
};

export type IItemMetaFragment = {
  readonly __typename?: 'Item';
  readonly _id: string;
  readonly _type: string;
  readonly operationalItem: {
    readonly __typename?: 'OpItem';
    readonly daypart: ReadonlyArray<string | null> | null;
  } | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IComboMetaFragment = {
  readonly __typename?: 'Combo';
  readonly _id: string;
  readonly _type: string;
  readonly mainItem: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IPickerMetaFragment = {
  readonly __typename?: 'Picker';
  readonly _id: string;
  readonly _type: string;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'PickerOption';
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
  } | null> | null;
};

export type IActivateOfferMetaFragment = {
  readonly __typename?: 'OfferActivation';
  readonly _type: string;
};

export type IDiscountOfferMetaFragment = {
  readonly __typename?: 'OfferDiscount';
  readonly discountValue: number | null;
  readonly discountType: string | null;
  readonly _type: string;
};

export type IOffersFragment = {
  readonly __typename?: 'Offer';
  readonly _id: string;
  readonly _type: string;
  readonly daypart: ReadonlyArray<string | null> | null;
  readonly forSupport: boolean | null;
  readonly redemptionMethod: string | null;
  readonly offerPrice: number | null;
  readonly uiPattern: string | null;
  readonly shortCode: string | null;
  readonly name: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly headerSuperText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly moreInfo: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly howToRedeem: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly offerTags: ReadonlyArray<{
    readonly __typename?: 'Tag';
    readonly value: string | null;
    readonly _id: string;
  } | null> | null;
  readonly offerCTA: {
    readonly __typename?: 'LinkAction';
    readonly route: string | null;
    readonly actionText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
  readonly marketPrice:
    | {
        readonly __typename?: 'Combo';
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | null;
  readonly localizedImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly option:
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly operationalItem: {
          readonly __typename?: 'OpItem';
          readonly daypart: ReadonlyArray<string | null> | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | { readonly __typename?: 'OfferActivation'; readonly _type: string }
    | {
        readonly __typename?: 'OfferDiscount';
        readonly discountValue: number | null;
        readonly discountType: string | null;
        readonly _type: string;
      }
    | { readonly __typename?: 'OfferLoyaltyAction' }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly option:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | null;
  readonly lockedOffersPanel: {
    readonly __typename?: 'LockedOffersPanel';
    readonly completedChallengeHeader: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly completedChallengeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly promoCodePanel: {
    readonly __typename?: 'PromoCodePanel';
    readonly promoCodeLink: string | null;
    readonly promoCodeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly promoCodeLabel: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly ruleSet: ReadonlyArray<
    | { readonly __typename?: 'AllowRedemptionWithoutLogin'; readonly ruleSetType: string | null }
    | {
        readonly __typename?: 'And';
        readonly ruleSetType: string | null;
        readonly ruleSet: ReadonlyArray<
          | {
              readonly __typename?: 'AllowRedemptionWithoutLogin';
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenDates';
              readonly endDate: any | null;
              readonly startDate: any | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenTimes';
              readonly endTime: string | null;
              readonly startTime: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartContainsPlu' }
          | {
              readonly __typename?: 'CartProperty';
              readonly key: string | null;
              readonly negated: boolean | null;
              readonly operator: string | null;
              readonly value: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartRequirement' }
          | {
              readonly __typename?: 'CoolDown';
              readonly minimumElapsedTimeSeconds: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
          | { readonly __typename?: 'IsStackable' }
          | {
              readonly __typename?: 'Limit';
              readonly interval: string | null;
              readonly maximumRedemptions: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'PaymentCardType';
              readonly cardType: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Purchase';
              readonly quantity: number | null;
              readonly sanityId: string | null;
              readonly ruleSetType: string | null;
              readonly option:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ProductCategory';
                    readonly _id: string;
                    readonly hierarchyLevel: string | null;
                    readonly ruleSetType: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null;
            }
          | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'RestaurantGroupRestrictions';
              readonly ruleSetType: string | null;
              readonly restaurantGroupRestrictionList: ReadonlyArray<{
                readonly __typename?: 'RestaurantGroup';
                readonly restaurants: ReadonlyArray<{
                  readonly __typename?: 'Restaurant';
                  readonly number: string | null;
                } | null> | null;
              } | null> | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'BetweenDates';
        readonly endDate: any | null;
        readonly startDate: any | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'BetweenTimes';
        readonly endTime: string | null;
        readonly startTime: string | null;
        readonly ruleSetType: string | null;
      }
    | { readonly __typename?: 'CartContainsPlu' }
    | {
        readonly __typename?: 'CartProperty';
        readonly key: string | null;
        readonly negated: boolean | null;
        readonly operator: string | null;
        readonly value: string | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'CoolDown';
        readonly minimumElapsedTimeSeconds: number | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'DayOfWeekBand';
        readonly monday: boolean | null;
        readonly tuesday: boolean | null;
        readonly wednesday: boolean | null;
        readonly thursday: boolean | null;
        readonly friday: boolean | null;
        readonly saturday: boolean | null;
        readonly sunday: boolean | null;
        readonly ruleSetType: string | null;
      }
    | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
    | {
        readonly __typename?: 'Limit';
        readonly interval: string | null;
        readonly maximumRedemptions: number | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'Or';
        readonly ruleSetType: string | null;
        readonly ruleSet: ReadonlyArray<
          | {
              readonly __typename?: 'AllowRedemptionWithoutLogin';
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenDates';
              readonly endDate: any | null;
              readonly startDate: any | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenTimes';
              readonly endTime: string | null;
              readonly startTime: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartContainsPlu' }
          | {
              readonly __typename?: 'CartProperty';
              readonly key: string | null;
              readonly negated: boolean | null;
              readonly operator: string | null;
              readonly value: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartRequirement' }
          | {
              readonly __typename?: 'CoolDown';
              readonly minimumElapsedTimeSeconds: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'Limit';
              readonly interval: string | null;
              readonly maximumRedemptions: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'PaymentCardType';
              readonly cardType: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Purchase';
              readonly quantity: number | null;
              readonly sanityId: string | null;
              readonly ruleSetType: string | null;
              readonly option:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ProductCategory';
                    readonly _id: string;
                    readonly hierarchyLevel: string | null;
                    readonly ruleSetType: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null;
            }
          | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'RestaurantGroupRestrictions';
              readonly ruleSetType: string | null;
              readonly restaurantGroupRestrictionList: ReadonlyArray<{
                readonly __typename?: 'RestaurantGroup';
                readonly restaurants: ReadonlyArray<{
                  readonly __typename?: 'Restaurant';
                  readonly number: string | null;
                } | null> | null;
              } | null> | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'OrderHistory';
        readonly rangeType: string | null;
        readonly from: any | null;
        readonly to: any | null;
        readonly lastNSeconds: number | null;
        readonly isSequential: boolean | null;
        readonly ruleSetType: string | null;
        readonly ruleSet: ReadonlyArray<
          | {
              readonly __typename?: 'And';
              readonly ruleSetType: string | null;
              readonly ruleSet: ReadonlyArray<
                | {
                    readonly __typename?: 'AllowRedemptionWithoutLogin';
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'BetweenDates';
                    readonly endDate: any | null;
                    readonly startDate: any | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'BetweenTimes';
                    readonly endTime: string | null;
                    readonly startTime: string | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'CartContainsPlu' }
                | {
                    readonly __typename?: 'CartProperty';
                    readonly key: string | null;
                    readonly negated: boolean | null;
                    readonly operator: string | null;
                    readonly value: string | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'CartRequirement' }
                | {
                    readonly __typename?: 'CoolDown';
                    readonly minimumElapsedTimeSeconds: number | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'DayOfWeekBand';
                    readonly monday: boolean | null;
                    readonly tuesday: boolean | null;
                    readonly wednesday: boolean | null;
                    readonly thursday: boolean | null;
                    readonly friday: boolean | null;
                    readonly saturday: boolean | null;
                    readonly sunday: boolean | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
                | { readonly __typename?: 'IsStackable' }
                | {
                    readonly __typename?: 'Limit';
                    readonly interval: string | null;
                    readonly maximumRedemptions: number | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'PaymentCardType';
                    readonly cardType: string | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'Purchase';
                    readonly quantity: number | null;
                    readonly sanityId: string | null;
                    readonly ruleSetType: string | null;
                    readonly option:
                      | {
                          readonly __typename?: 'Combo';
                          readonly _id: string;
                          readonly _type: string;
                          readonly mainItem: {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        }
                      | {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        }
                      | {
                          readonly __typename?: 'Picker';
                          readonly _id: string;
                          readonly _type: string;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'PickerOption';
                            readonly option:
                              | {
                                  readonly __typename?: 'Combo';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly mainItem: {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly operationalItem: {
                                      readonly __typename?: 'OpItem';
                                      readonly daypart: ReadonlyArray<string | null> | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  } | null;
                                  readonly vendorConfigs: {
                                    readonly __typename?: 'VendorConfigs';
                                    readonly ncr: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly ncrDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partner: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partnerDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumber: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumberDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicom: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicomDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdi: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdiDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rpos: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rposDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDeliveryDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly toshibaLoyalty: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly pluConfigs: {
                                    readonly __typename?: 'PluConfigs';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly partner: ReadonlyArray<{
                                      readonly __typename?: 'PluConfig';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly serviceMode: string | null;
                                      readonly posIntegration: {
                                        readonly __typename?: 'PosIntegration';
                                        readonly _id: string;
                                        readonly _type: string;
                                        readonly name: string | null;
                                      } | null;
                                      readonly vendorConfig: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null> | null;
                                  } | null;
                                }
                              | {
                                  readonly __typename?: 'Item';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly operationalItem: {
                                    readonly __typename?: 'OpItem';
                                    readonly daypart: ReadonlyArray<string | null> | null;
                                  } | null;
                                  readonly vendorConfigs: {
                                    readonly __typename?: 'VendorConfigs';
                                    readonly ncr: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly ncrDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partner: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partnerDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumber: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumberDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicom: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicomDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdi: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdiDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rpos: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rposDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDeliveryDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly toshibaLoyalty: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly pluConfigs: {
                                    readonly __typename?: 'PluConfigs';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly partner: ReadonlyArray<{
                                      readonly __typename?: 'PluConfig';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly serviceMode: string | null;
                                      readonly posIntegration: {
                                        readonly __typename?: 'PosIntegration';
                                        readonly _id: string;
                                        readonly _type: string;
                                        readonly name: string | null;
                                      } | null;
                                      readonly vendorConfig: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null> | null;
                                  } | null;
                                }
                              | null;
                          } | null> | null;
                        }
                      | {
                          readonly __typename?: 'ProductCategory';
                          readonly _id: string;
                          readonly hierarchyLevel: string | null;
                          readonly ruleSetType: string;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        }
                      | null;
                  }
                | {
                    readonly __typename?: 'RequiresAssignment';
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'RestaurantGroupRestrictions';
                    readonly ruleSetType: string | null;
                    readonly restaurantGroupRestrictionList: ReadonlyArray<{
                      readonly __typename?: 'RestaurantGroup';
                      readonly restaurants: ReadonlyArray<{
                        readonly __typename?: 'Restaurant';
                        readonly number: string | null;
                      } | null> | null;
                    } | null> | null;
                  }
                | null
              > | null;
            }
          | {
              readonly __typename?: 'BetweenTimes';
              readonly endTime: string | null;
              readonly startTime: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartContainsPlu' }
          | {
              readonly __typename?: 'CartProperty';
              readonly key: string | null;
              readonly negated: boolean | null;
              readonly operator: string | null;
              readonly value: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Or';
              readonly ruleSetType: string | null;
              readonly ruleSet: ReadonlyArray<
                | {
                    readonly __typename?: 'AllowRedemptionWithoutLogin';
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'BetweenDates';
                    readonly endDate: any | null;
                    readonly startDate: any | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'BetweenTimes';
                    readonly endTime: string | null;
                    readonly startTime: string | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'CartContainsPlu' }
                | {
                    readonly __typename?: 'CartProperty';
                    readonly key: string | null;
                    readonly negated: boolean | null;
                    readonly operator: string | null;
                    readonly value: string | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'CartRequirement' }
                | {
                    readonly __typename?: 'CoolDown';
                    readonly minimumElapsedTimeSeconds: number | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'DayOfWeekBand';
                    readonly monday: boolean | null;
                    readonly tuesday: boolean | null;
                    readonly wednesday: boolean | null;
                    readonly thursday: boolean | null;
                    readonly friday: boolean | null;
                    readonly saturday: boolean | null;
                    readonly sunday: boolean | null;
                    readonly ruleSetType: string | null;
                  }
                | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
                | {
                    readonly __typename?: 'Limit';
                    readonly interval: string | null;
                    readonly maximumRedemptions: number | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'PaymentCardType';
                    readonly cardType: string | null;
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'Purchase';
                    readonly quantity: number | null;
                    readonly sanityId: string | null;
                    readonly ruleSetType: string | null;
                    readonly option:
                      | {
                          readonly __typename?: 'Combo';
                          readonly _id: string;
                          readonly _type: string;
                          readonly mainItem: {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        }
                      | {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        }
                      | {
                          readonly __typename?: 'Picker';
                          readonly _id: string;
                          readonly _type: string;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'PickerOption';
                            readonly option:
                              | {
                                  readonly __typename?: 'Combo';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly mainItem: {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly operationalItem: {
                                      readonly __typename?: 'OpItem';
                                      readonly daypart: ReadonlyArray<string | null> | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  } | null;
                                  readonly vendorConfigs: {
                                    readonly __typename?: 'VendorConfigs';
                                    readonly ncr: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly ncrDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partner: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partnerDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumber: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumberDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicom: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicomDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdi: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdiDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rpos: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rposDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDeliveryDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly toshibaLoyalty: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly pluConfigs: {
                                    readonly __typename?: 'PluConfigs';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly partner: ReadonlyArray<{
                                      readonly __typename?: 'PluConfig';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly serviceMode: string | null;
                                      readonly posIntegration: {
                                        readonly __typename?: 'PosIntegration';
                                        readonly _id: string;
                                        readonly _type: string;
                                        readonly name: string | null;
                                      } | null;
                                      readonly vendorConfig: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null> | null;
                                  } | null;
                                }
                              | {
                                  readonly __typename?: 'Item';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly operationalItem: {
                                    readonly __typename?: 'OpItem';
                                    readonly daypart: ReadonlyArray<string | null> | null;
                                  } | null;
                                  readonly vendorConfigs: {
                                    readonly __typename?: 'VendorConfigs';
                                    readonly ncr: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly ncrDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partner: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly partnerDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumber: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly productNumberDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicom: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly sicomDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdi: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly qdiDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rpos: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly rposDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly simplyDeliveryDelivery: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                    readonly toshibaLoyalty: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly pluConfigs: {
                                    readonly __typename?: 'PluConfigs';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly partner: ReadonlyArray<{
                                      readonly __typename?: 'PluConfig';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly serviceMode: string | null;
                                      readonly posIntegration: {
                                        readonly __typename?: 'PosIntegration';
                                        readonly _id: string;
                                        readonly _type: string;
                                        readonly name: string | null;
                                      } | null;
                                      readonly vendorConfig: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null> | null;
                                  } | null;
                                }
                              | null;
                          } | null> | null;
                        }
                      | {
                          readonly __typename?: 'ProductCategory';
                          readonly _id: string;
                          readonly hierarchyLevel: string | null;
                          readonly ruleSetType: string;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        }
                      | null;
                  }
                | {
                    readonly __typename?: 'RequiresAssignment';
                    readonly ruleSetType: string | null;
                  }
                | {
                    readonly __typename?: 'RestaurantGroupRestrictions';
                    readonly ruleSetType: string | null;
                    readonly restaurantGroupRestrictionList: ReadonlyArray<{
                      readonly __typename?: 'RestaurantGroup';
                      readonly restaurants: ReadonlyArray<{
                        readonly __typename?: 'Restaurant';
                        readonly number: string | null;
                      } | null> | null;
                    } | null> | null;
                  }
                | null
              > | null;
            }
          | {
              readonly __typename?: 'PaymentCardType';
              readonly cardType: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Purchase';
              readonly quantity: number | null;
              readonly sanityId: string | null;
              readonly ruleSetType: string | null;
              readonly option:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ProductCategory';
                    readonly _id: string;
                    readonly hierarchyLevel: string | null;
                    readonly ruleSetType: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'PaymentCardType';
        readonly cardType: string | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'Purchase';
        readonly quantity: number | null;
        readonly sanityId: string | null;
        readonly ruleSetType: string | null;
        readonly option:
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'ProductCategory';
              readonly _id: string;
              readonly hierarchyLevel: string | null;
              readonly ruleSetType: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | null;
      }
    | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
    | {
        readonly __typename?: 'RestaurantGroupRestrictions';
        readonly ruleSetType: string | null;
        readonly restaurantGroupRestrictionList: ReadonlyArray<{
          readonly __typename?: 'RestaurantGroup';
          readonly restaurants: ReadonlyArray<{
            readonly __typename?: 'Restaurant';
            readonly number: string | null;
          } | null> | null;
        } | null> | null;
      }
    | null
  > | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IOptInSettingEmailAndPushFragment = {
  readonly __typename?: 'OptInSettingEmailAndPush';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly pushKey: string | null;
  readonly emailKey: string | null;
  readonly singleTickBox: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IOptInPrivacyPreferencesFragment = {
  readonly __typename?: 'OptInPrivacyPreferences';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly key: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly view: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type IOptInSettingEmailFragment = {
  readonly __typename?: 'OptInSettingEmail';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly emailKey: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IOptInSettingSmsFragment = {
  readonly __typename?: 'OptInSettingSms';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly smsKey: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IOptInSettingPushFragment = {
  readonly __typename?: 'OptInSettingPush';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly pushKey: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IOrderHistoryFragment = {
  readonly __typename?: 'OrderHistory';
  readonly rangeType: string | null;
  readonly from: any | null;
  readonly to: any | null;
  readonly lastNSeconds: number | null;
  readonly isSequential: boolean | null;
  readonly ruleSetType: string | null;
  readonly ruleSet: ReadonlyArray<
    | {
        readonly __typename?: 'And';
        readonly ruleSetType: string | null;
        readonly ruleSet: ReadonlyArray<
          | {
              readonly __typename?: 'AllowRedemptionWithoutLogin';
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenDates';
              readonly endDate: any | null;
              readonly startDate: any | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenTimes';
              readonly endTime: string | null;
              readonly startTime: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartContainsPlu' }
          | {
              readonly __typename?: 'CartProperty';
              readonly key: string | null;
              readonly negated: boolean | null;
              readonly operator: string | null;
              readonly value: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartRequirement' }
          | {
              readonly __typename?: 'CoolDown';
              readonly minimumElapsedTimeSeconds: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
          | { readonly __typename?: 'IsStackable' }
          | {
              readonly __typename?: 'Limit';
              readonly interval: string | null;
              readonly maximumRedemptions: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'PaymentCardType';
              readonly cardType: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Purchase';
              readonly quantity: number | null;
              readonly sanityId: string | null;
              readonly ruleSetType: string | null;
              readonly option:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ProductCategory';
                    readonly _id: string;
                    readonly hierarchyLevel: string | null;
                    readonly ruleSetType: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null;
            }
          | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'RestaurantGroupRestrictions';
              readonly ruleSetType: string | null;
              readonly restaurantGroupRestrictionList: ReadonlyArray<{
                readonly __typename?: 'RestaurantGroup';
                readonly restaurants: ReadonlyArray<{
                  readonly __typename?: 'Restaurant';
                  readonly number: string | null;
                } | null> | null;
              } | null> | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'BetweenTimes';
        readonly endTime: string | null;
        readonly startTime: string | null;
        readonly ruleSetType: string | null;
      }
    | { readonly __typename?: 'CartContainsPlu' }
    | {
        readonly __typename?: 'CartProperty';
        readonly key: string | null;
        readonly negated: boolean | null;
        readonly operator: string | null;
        readonly value: string | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'DayOfWeekBand';
        readonly monday: boolean | null;
        readonly tuesday: boolean | null;
        readonly wednesday: boolean | null;
        readonly thursday: boolean | null;
        readonly friday: boolean | null;
        readonly saturday: boolean | null;
        readonly sunday: boolean | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'Or';
        readonly ruleSetType: string | null;
        readonly ruleSet: ReadonlyArray<
          | {
              readonly __typename?: 'AllowRedemptionWithoutLogin';
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenDates';
              readonly endDate: any | null;
              readonly startDate: any | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'BetweenTimes';
              readonly endTime: string | null;
              readonly startTime: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartContainsPlu' }
          | {
              readonly __typename?: 'CartProperty';
              readonly key: string | null;
              readonly negated: boolean | null;
              readonly operator: string | null;
              readonly value: string | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'CartRequirement' }
          | {
              readonly __typename?: 'CoolDown';
              readonly minimumElapsedTimeSeconds: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'DayOfWeekBand';
              readonly monday: boolean | null;
              readonly tuesday: boolean | null;
              readonly wednesday: boolean | null;
              readonly thursday: boolean | null;
              readonly friday: boolean | null;
              readonly saturday: boolean | null;
              readonly sunday: boolean | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'Limit';
              readonly interval: string | null;
              readonly maximumRedemptions: number | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'PaymentCardType';
              readonly cardType: string | null;
              readonly ruleSetType: string | null;
            }
          | {
              readonly __typename?: 'Purchase';
              readonly quantity: number | null;
              readonly sanityId: string | null;
              readonly ruleSetType: string | null;
              readonly option:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _id: string;
                    readonly _type: string;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'PickerOption';
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ProductCategory';
                    readonly _id: string;
                    readonly hierarchyLevel: string | null;
                    readonly ruleSetType: string;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null;
            }
          | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
          | {
              readonly __typename?: 'RestaurantGroupRestrictions';
              readonly ruleSetType: string | null;
              readonly restaurantGroupRestrictionList: ReadonlyArray<{
                readonly __typename?: 'RestaurantGroup';
                readonly restaurants: ReadonlyArray<{
                  readonly __typename?: 'Restaurant';
                  readonly number: string | null;
                } | null> | null;
              } | null> | null;
            }
          | null
        > | null;
      }
    | {
        readonly __typename?: 'PaymentCardType';
        readonly cardType: string | null;
        readonly ruleSetType: string | null;
      }
    | {
        readonly __typename?: 'Purchase';
        readonly quantity: number | null;
        readonly sanityId: string | null;
        readonly ruleSetType: string | null;
        readonly option:
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | {
              readonly __typename?: 'ProductCategory';
              readonly _id: string;
              readonly hierarchyLevel: string | null;
              readonly ruleSetType: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | null;
      }
    | null
  > | null;
};

export type IOurServicesFragment = {
  readonly __typename?: 'OurServices';
  readonly _id: string;
  readonly servicesHeaderTextLocale: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly servicesHeroTextLocale: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly ourServicesHeroImageLocale: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IPickerAvailabilityFragment = {
  readonly __typename?: 'Picker';
  readonly showInStaticMenu: boolean | null;
  readonly hideCalories: boolean | null;
  readonly hideNutritionLegalDisclaimer: boolean | null;
  readonly imagesByChannels: {
    readonly __typename?: 'ImagesByChannels';
    readonly imageRestaurant: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDelivery: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'PickerOption';
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly showInStaticMenu: boolean | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly showInStaticMenu: boolean | null;
            readonly hideCalories: boolean | null;
            readonly hideNutritionLegalDisclaimer: boolean | null;
            readonly itemSize: number | null;
            readonly itemUnit: string | null;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly injectDefaultSelection: boolean | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ComboSlot';
            readonly minAmount: number | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlotOption';
              readonly maxAmount: number | null;
              readonly option:
                | {
                    readonly __typename?: 'Item';
                    readonly showInStaticMenu: boolean | null;
                    readonly hideCalories: boolean | null;
                    readonly hideNutritionLegalDisclaimer: boolean | null;
                    readonly itemSize: number | null;
                    readonly itemUnit: string | null;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly injectDefaultSelection: boolean | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly default: boolean | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                        readonly modifierMultiplier: {
                          readonly __typename?: 'ModifierMultiplier';
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                          readonly modifier: {
                            readonly __typename?: 'Modifier';
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                  }
                | { readonly __typename?: 'Picker' }
                | { readonly __typename?: 'Section' }
                | null;
            } | null> | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly showInStaticMenu: boolean | null;
          readonly hideCalories: boolean | null;
          readonly hideNutritionLegalDisclaimer: boolean | null;
          readonly itemSize: number | null;
          readonly itemUnit: string | null;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly injectDefaultSelection: boolean | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly default: boolean | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
          } | null> | null;
        }
      | null;
  } | null> | null;
};

export type IPickerWithVendorConfigFragment = {
  readonly __typename?: 'Picker';
  readonly _id: string;
  readonly _type: string;
  readonly uiPattern: string | null;
  readonly isOfferBenefit: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly pickerDefaults: ReadonlyArray<{
    readonly __typename?: 'PickerItemMapping';
    readonly _key: string | null;
    readonly pickerAspectValueIdentifier: string | null;
    readonly pickerAspect: {
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly pickerAspects: ReadonlyArray<{
    readonly __typename?: 'PickerAspect';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pickerAspectOptions: ReadonlyArray<{
      readonly __typename?: 'PickerAspectValue';
      readonly identifier: string | null;
      readonly hideNameInItemPreview: boolean | null;
      readonly showInStaticMenu: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
  readonly pickerAspectItemOptionMappings: ReadonlyArray<{
    readonly __typename?: 'PickerAspectItemOptions';
    readonly pickerAspect: {
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ListItem';
      readonly value: string | null;
    } | null> | null;
  } | null> | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'PickerOption';
    readonly _key: string | null;
    readonly default: boolean | null;
    readonly type: string | null;
    readonly pickerItemMappings: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: { readonly __typename?: 'PickerAspect'; readonly _id: string } | null;
    } | null> | null;
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly _key: string | null;
          readonly _id: string;
          readonly _type: string;
          readonly uiPattern: string | null;
          readonly hideMainItemDescription: boolean | null;
          readonly forceModifiersToBottom: boolean | null;
          readonly isOfferBenefit: boolean | null;
          readonly promotion: {
            readonly __typename?: 'SystemwidePromotion';
            readonly bonusPoints: number | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          } | null;
          readonly markerItem: {
            readonly __typename?: 'MarkerItem';
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ComboSlot';
            readonly _type: string;
            readonly _id: string;
            readonly uiPattern: string | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly respectMaximum: boolean | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly optionVisibilitySettings: {
              readonly __typename?: 'OptionVisibilitySettings';
              readonly visibleOptions: number | null;
              readonly toggleButtonTextClosed: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly toggleButtonTextOpen: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlotOption';
              readonly _key: string | null;
              readonly minAmount: number | null;
              readonly defaultAmount: number | null;
              readonly isPremium: boolean | null;
              readonly option:
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly rewardEligible: boolean | null;
                    readonly isDummyItem: boolean | null;
                    readonly labelAsPerPerson: boolean | null;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly legalInformation: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly imagesByChannels: {
                      readonly __typename?: 'ImagesByChannels';
                      readonly imageRestaurant: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDelivery: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly sourcesOfGluten: string | null;
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly componentStyle: string | null;
                      readonly upsellModifier: boolean | null;
                      readonly allowMultipleSelections: boolean | null;
                      readonly displayModifierMultiplierName: boolean | null;
                      readonly injectDefaultSelection: boolean | null;
                      readonly singleChoiceOnly: boolean | null;
                      readonly minAmount: number | null;
                      readonly maxAmount: number | null;
                      readonly _key: string | null;
                      readonly type: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly displayGroup: {
                        readonly __typename?: 'DisplayGroup';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly _key: string | null;
                        readonly default: boolean | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                        readonly modifierMultiplier: {
                          readonly __typename?: 'ModifierMultiplier';
                          readonly multiplier: number | null;
                          readonly prefix: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly modifier: {
                            readonly __typename?: 'Modifier';
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly imageDescription: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly productHierarchy: {
                      readonly __typename?: 'ProductHierarchy';
                      readonly L1: string | null;
                      readonly L2: string | null;
                      readonly L3: string | null;
                      readonly L4: string | null;
                      readonly L5: string | null;
                    } | null;
                    readonly menuObjectSettings: {
                      readonly __typename?: 'MenuObjectSettings';
                      readonly limitPerOrder: number | null;
                    } | null;
                    readonly channelExclusions: {
                      readonly __typename?: 'ChannelExclusions';
                      readonly delivery: boolean | null;
                      readonly pickup: boolean | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _type: string;
                    readonly uiPattern: string | null;
                    readonly pickerAspects: ReadonlyArray<{
                      readonly __typename?: 'PickerAspect';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly pickerAspectOptions: ReadonlyArray<{
                        readonly __typename?: 'PickerAspectValue';
                        readonly identifier: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                  }
                | { readonly __typename?: 'Section' }
                | null;
            } | null> | null;
          } | null> | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _key: string | null;
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly promotion: {
            readonly __typename?: 'SystemwidePromotion';
            readonly bonusPoints: number | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
        }
      | null;
  } | null> | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly quickConfigs: ReadonlyArray<{
    readonly __typename?: 'QuickConfig';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly rules: ReadonlyArray<{
      readonly __typename?: 'QuickConfigRule';
      readonly itemOptions: ReadonlyArray<{
        readonly __typename?: 'ListItem';
        readonly value: string | null;
      } | null> | null;
      readonly modifier: { readonly __typename?: 'ListItem'; readonly value: string | null } | null;
    } | null> | null;
  } | null> | null;
  readonly channelExclusions: {
    readonly __typename?: 'ChannelExclusions';
    readonly delivery: boolean | null;
    readonly pickup: boolean | null;
  } | null;
};

export type IPickerFragment = {
  readonly __typename?: 'Picker';
  readonly _id: string;
  readonly _type: string;
  readonly uiPattern: string | null;
  readonly isOfferBenefit: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly pickerDefaults: ReadonlyArray<{
    readonly __typename?: 'PickerItemMapping';
    readonly _key: string | null;
    readonly pickerAspectValueIdentifier: string | null;
    readonly pickerAspect: {
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly pickerAspects: ReadonlyArray<{
    readonly __typename?: 'PickerAspect';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pickerAspectOptions: ReadonlyArray<{
      readonly __typename?: 'PickerAspectValue';
      readonly identifier: string | null;
      readonly hideNameInItemPreview: boolean | null;
      readonly showInStaticMenu: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
  readonly pickerAspectItemOptionMappings: ReadonlyArray<{
    readonly __typename?: 'PickerAspectItemOptions';
    readonly pickerAspect: {
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ListItem';
      readonly value: string | null;
    } | null> | null;
  } | null> | null;
  readonly options: ReadonlyArray<{
    readonly __typename?: 'PickerOption';
    readonly _key: string | null;
    readonly default: boolean | null;
    readonly type: string | null;
    readonly pickerItemMappings: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: { readonly __typename?: 'PickerAspect'; readonly _id: string } | null;
    } | null> | null;
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly _key: string | null;
          readonly _id: string;
          readonly _type: string;
          readonly uiPattern: string | null;
          readonly hideMainItemDescription: boolean | null;
          readonly forceModifiersToBottom: boolean | null;
          readonly isOfferBenefit: boolean | null;
          readonly promotion: {
            readonly __typename?: 'SystemwidePromotion';
            readonly bonusPoints: number | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          } | null;
          readonly markerItem: {
            readonly __typename?: 'MarkerItem';
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ComboSlot';
            readonly _type: string;
            readonly _id: string;
            readonly uiPattern: string | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly respectMaximum: boolean | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly optionVisibilitySettings: {
              readonly __typename?: 'OptionVisibilitySettings';
              readonly visibleOptions: number | null;
              readonly toggleButtonTextClosed: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly toggleButtonTextOpen: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlotOption';
              readonly _key: string | null;
              readonly minAmount: number | null;
              readonly defaultAmount: number | null;
              readonly isPremium: boolean | null;
              readonly option:
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly rewardEligible: boolean | null;
                    readonly isDummyItem: boolean | null;
                    readonly labelAsPerPerson: boolean | null;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly legalInformation: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly imagesByChannels: {
                      readonly __typename?: 'ImagesByChannels';
                      readonly imageRestaurant: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDelivery: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly sourcesOfGluten: string | null;
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly componentStyle: string | null;
                      readonly upsellModifier: boolean | null;
                      readonly allowMultipleSelections: boolean | null;
                      readonly displayModifierMultiplierName: boolean | null;
                      readonly injectDefaultSelection: boolean | null;
                      readonly singleChoiceOnly: boolean | null;
                      readonly minAmount: number | null;
                      readonly maxAmount: number | null;
                      readonly _key: string | null;
                      readonly type: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly displayGroup: {
                        readonly __typename?: 'DisplayGroup';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly _key: string | null;
                        readonly default: boolean | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                        readonly modifierMultiplier: {
                          readonly __typename?: 'ModifierMultiplier';
                          readonly multiplier: number | null;
                          readonly prefix: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly modifier: {
                            readonly __typename?: 'Modifier';
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly imageDescription: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly productHierarchy: {
                      readonly __typename?: 'ProductHierarchy';
                      readonly L1: string | null;
                      readonly L2: string | null;
                      readonly L3: string | null;
                      readonly L4: string | null;
                      readonly L5: string | null;
                    } | null;
                    readonly menuObjectSettings: {
                      readonly __typename?: 'MenuObjectSettings';
                      readonly limitPerOrder: number | null;
                    } | null;
                    readonly channelExclusions: {
                      readonly __typename?: 'ChannelExclusions';
                      readonly delivery: boolean | null;
                      readonly pickup: boolean | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Picker';
                    readonly _type: string;
                    readonly uiPattern: string | null;
                    readonly pickerAspects: ReadonlyArray<{
                      readonly __typename?: 'PickerAspect';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly pickerAspectOptions: ReadonlyArray<{
                        readonly __typename?: 'PickerAspectValue';
                        readonly identifier: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                  }
                | { readonly __typename?: 'Section' }
                | null;
            } | null> | null;
          } | null> | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _key: string | null;
          readonly _id: string;
          readonly _type: string;
          readonly rewardEligible: boolean | null;
          readonly isDummyItem: boolean | null;
          readonly labelAsPerPerson: boolean | null;
          readonly productSize: string | null;
          readonly promotion: {
            readonly __typename?: 'SystemwidePromotion';
            readonly bonusPoints: number | null;
          } | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imagesByChannels: {
            readonly __typename?: 'ImagesByChannels';
            readonly imageRestaurant: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDelivery: {
              readonly __typename?: 'Image';
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly additionalItemInformation: {
            readonly __typename?: 'AdditionalItemInformation';
            readonly sourcesOfGluten: string | null;
            readonly ingredients: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly additives: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly producerDetails: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly nutritionWithModifiers: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
          readonly allergens: {
            readonly __typename?: 'OpAllergen';
            readonly milk: number | null;
            readonly eggs: number | null;
            readonly fish: number | null;
            readonly peanuts: number | null;
            readonly shellfish: number | null;
            readonly treeNuts: number | null;
            readonly soy: number | null;
            readonly wheat: number | null;
            readonly mustard: number | null;
            readonly sesame: number | null;
            readonly celery: number | null;
            readonly lupin: number | null;
            readonly gluten: number | null;
            readonly sulphurDioxide: number | null;
          } | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ItemOption';
            readonly componentStyle: string | null;
            readonly upsellModifier: boolean | null;
            readonly allowMultipleSelections: boolean | null;
            readonly displayModifierMultiplierName: boolean | null;
            readonly injectDefaultSelection: boolean | null;
            readonly singleChoiceOnly: boolean | null;
            readonly minAmount: number | null;
            readonly maxAmount: number | null;
            readonly _key: string | null;
            readonly type: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly displayGroup: {
              readonly __typename?: 'DisplayGroup';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOptionModifier';
              readonly _key: string | null;
              readonly default: boolean | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly modifierMultiplier: {
                readonly __typename?: 'ModifierMultiplier';
                readonly multiplier: number | null;
                readonly prefix: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly modifier: {
                  readonly __typename?: 'Modifier';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly productHierarchy: {
            readonly __typename?: 'ProductHierarchy';
            readonly L1: string | null;
            readonly L2: string | null;
            readonly L3: string | null;
            readonly L4: string | null;
            readonly L5: string | null;
          } | null;
          readonly menuObjectSettings: {
            readonly __typename?: 'MenuObjectSettings';
            readonly limitPerOrder: number | null;
          } | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
        }
      | null;
  } | null> | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly legalInformation: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly quickConfigs: ReadonlyArray<{
    readonly __typename?: 'QuickConfig';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly rules: ReadonlyArray<{
      readonly __typename?: 'QuickConfigRule';
      readonly itemOptions: ReadonlyArray<{
        readonly __typename?: 'ListItem';
        readonly value: string | null;
      } | null> | null;
      readonly modifier: { readonly __typename?: 'ListItem'; readonly value: string | null } | null;
    } | null> | null;
  } | null> | null;
  readonly channelExclusions: {
    readonly __typename?: 'ChannelExclusions';
    readonly delivery: boolean | null;
    readonly pickup: boolean | null;
  } | null;
};

export type IPluConfigFragment = {
  readonly __typename?: 'PluConfig';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly serviceMode: string | null;
  readonly posIntegration: {
    readonly __typename?: 'PosIntegration';
    readonly _id: string;
    readonly _type: string;
    readonly name: string | null;
  } | null;
  readonly vendorConfig: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
};

export type IPluConfigsFragment = {
  readonly __typename?: 'PluConfigs';
  readonly _key: string | null;
  readonly _type: string | null;
  readonly partner: ReadonlyArray<{
    readonly __typename?: 'PluConfig';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly serviceMode: string | null;
    readonly posIntegration: {
      readonly __typename?: 'PosIntegration';
      readonly _id: string;
      readonly _type: string;
      readonly name: string | null;
    } | null;
    readonly vendorConfig: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IProductCategoryFragment = {
  readonly __typename?: 'ProductCategory';
  readonly _id: string;
  readonly hierarchyLevel: string | null;
  readonly ruleSetType: string;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
};

export type IProductListSectionFragment = {
  readonly __typename?: 'Section';
  readonly _id: string;
  readonly _type: string;
  readonly daypart: ReadonlyArray<string | null> | null;
  readonly uiPattern: string | null;
  readonly showInStaticMenu: boolean | null;
  readonly isBurgersForBreakfastSection: boolean | null;
  readonly hiddenFromMainMenu: boolean | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly headerText: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly carouselImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly options: ReadonlyArray<
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly uiPattern: string | null;
        readonly showInStaticMenu: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imagesByChannels: {
          readonly __typename?: 'ImagesByChannels';
          readonly imageRestaurant: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDelivery: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly showInStaticMenu: boolean | null;
        readonly hideCalories: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imagesByChannels: {
          readonly __typename?: 'ImagesByChannels';
          readonly imageRestaurant: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDelivery: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | { readonly __typename?: 'Offer' }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly uiPattern: string | null;
        readonly showInStaticMenu: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imagesByChannels: {
          readonly __typename?: 'ImagesByChannels';
          readonly imageRestaurant: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDelivery: {
            readonly __typename?: 'Image';
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        readonly __typename?: 'Section';
        readonly _id: string;
        readonly _type: string;
        readonly hiddenFromMainMenu: boolean | null;
        readonly uiPattern: string | null;
        readonly showInStaticMenu: boolean | null;
        readonly daypart: ReadonlyArray<string | null> | null;
        readonly isBurgersForBreakfastSection: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly headerText: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly carouselImage: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly uiPattern: string | null;
              readonly showInStaticMenu: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly showInStaticMenu: boolean | null;
              readonly hideCalories: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | { readonly __typename?: 'Offer' }
          | {
              readonly __typename?: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly uiPattern: string | null;
              readonly showInStaticMenu: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            }
          | { readonly __typename?: 'Section' }
          | null
        > | null;
      }
    | null
  > | null;
};

export type IQuickConfigFragment = {
  readonly __typename?: 'QuickConfig';
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly rules: ReadonlyArray<{
    readonly __typename?: 'QuickConfigRule';
    readonly itemOptions: ReadonlyArray<{
      readonly __typename?: 'ListItem';
      readonly value: string | null;
    } | null> | null;
    readonly modifier: { readonly __typename?: 'ListItem'; readonly value: string | null } | null;
  } | null> | null;
};

export type IRefillDrinkModeFragment = {
  readonly __typename?: 'RefillDrinksServiceMode';
  readonly cateringDeliveryServiceMode: boolean | null;
  readonly cateringPickUpServiceMode: boolean | null;
  readonly curbsideServiceMode: boolean | null;
  readonly deliveryServiceMode: boolean | null;
  readonly dineInServiceMode: boolean | null;
  readonly driveThruServiceMode: boolean | null;
  readonly pickUpServiceMode: boolean | null;
  readonly tableServiceMode: boolean | null;
};

export type IRefillDrinkTimersFragment = {
  readonly __typename?: 'RefillDrinksTimers';
  readonly timeBottomSheet: number | null;
  readonly timeRecentOrder: number | null;
};

export type IRewardPriceFragment = {
  readonly __typename?: 'RewardPrice';
  readonly price: number | null;
  readonly ruleSetType: string | null;
};

export type IRewardFragment = {
  readonly __typename?: 'Reward';
  readonly _id: string;
  readonly testOnly: boolean | null;
  readonly loyaltyEngineId: string | null;
  readonly incentiveNotInMenu: boolean | null;
  readonly mobileOrderOnly: boolean | null;
  readonly redemptionMethod: string | null;
  readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly locale: any | null;
  } | null;
  readonly ruleSet: ReadonlyArray<
    | { readonly __typename?: 'BirthdayMultiplier' }
    | { readonly __typename?: 'CartLimit' }
    | { readonly __typename?: 'CartRequirement' }
    | { readonly __typename?: 'CartRequirementExclusion' }
    | { readonly __typename?: 'CoolDown' }
    | { readonly __typename?: 'DayOfWeekBand' }
    | { readonly __typename?: 'EmailVerifiedRequired' }
    | { readonly __typename?: 'FirstOrderOnly' }
    | { readonly __typename?: 'Limit' }
    | { readonly __typename?: 'LoyaltyBetweenDates' }
    | { readonly __typename?: 'LoyaltyBetweenTimes' }
    | { readonly __typename?: 'LoyaltyPoints' }
    | { readonly __typename?: 'PaymentMethod' }
    | { readonly __typename?: 'RequiresAuthentication' }
    | { readonly __typename?: 'RestaurantGroupRestrictions' }
    | {
        readonly __typename?: 'RewardPrice';
        readonly price: number | null;
        readonly ruleSetType: string | null;
      }
    | { readonly __typename?: 'ServiceModeRestrictions' }
    | { readonly __typename?: 'SubtotalSpend' }
    | { readonly __typename?: 'TierList' }
    | { readonly __typename?: 'TotalSpend' }
    | { readonly __typename?: 'TransactionCount' }
    | { readonly __typename?: 'UserAttributes' }
    | { readonly __typename?: 'UserCreated' }
    | null
  > | null;
  readonly moreInfo: {
    readonly __typename?: 'LocaleBlockContent';
    readonly enRaw: any | null;
  } | null;
  readonly howToRedeem: {
    readonly __typename?: 'LocaleBlockContent';
    readonly enRaw: any | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly imageDescription: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
  readonly incentives: ReadonlyArray<
    | {
        readonly __typename: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly operationalItem: {
          readonly __typename?: 'OpItem';
          readonly daypart: ReadonlyArray<string | null> | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename: 'OfferDiscount';
        readonly _id: string;
        readonly _type: string;
        readonly discountValue: number | null;
        readonly discountType: string | null;
        readonly discountProduct:
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | null;
      }
    | {
        readonly __typename: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly option:
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | null
  > | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IRewardsSectionFragment = {
  readonly __typename?: 'RewardsSection';
  readonly _id: string;
  readonly rewardsList: ReadonlyArray<{
    readonly __typename?: 'FeaturedReward';
    readonly reward: {
      readonly __typename?: 'Reward';
      readonly _id: string;
      readonly testOnly: boolean | null;
      readonly loyaltyEngineId: string | null;
      readonly incentiveNotInMenu: boolean | null;
      readonly mobileOrderOnly: boolean | null;
      readonly redemptionMethod: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
      readonly ruleSet: ReadonlyArray<
        | { readonly __typename?: 'BirthdayMultiplier' }
        | { readonly __typename?: 'CartLimit' }
        | { readonly __typename?: 'CartRequirement' }
        | { readonly __typename?: 'CartRequirementExclusion' }
        | { readonly __typename?: 'CoolDown' }
        | { readonly __typename?: 'DayOfWeekBand' }
        | { readonly __typename?: 'EmailVerifiedRequired' }
        | { readonly __typename?: 'FirstOrderOnly' }
        | { readonly __typename?: 'Limit' }
        | { readonly __typename?: 'LoyaltyBetweenDates' }
        | { readonly __typename?: 'LoyaltyBetweenTimes' }
        | { readonly __typename?: 'LoyaltyPoints' }
        | { readonly __typename?: 'PaymentMethod' }
        | { readonly __typename?: 'RequiresAuthentication' }
        | { readonly __typename?: 'RestaurantGroupRestrictions' }
        | {
            readonly __typename?: 'RewardPrice';
            readonly price: number | null;
            readonly ruleSetType: string | null;
          }
        | { readonly __typename?: 'ServiceModeRestrictions' }
        | { readonly __typename?: 'SubtotalSpend' }
        | { readonly __typename?: 'TierList' }
        | { readonly __typename?: 'TotalSpend' }
        | { readonly __typename?: 'TransactionCount' }
        | { readonly __typename?: 'UserAttributes' }
        | { readonly __typename?: 'UserCreated' }
        | null
      > | null;
      readonly moreInfo: {
        readonly __typename?: 'LocaleBlockContent';
        readonly enRaw: any | null;
      } | null;
      readonly howToRedeem: {
        readonly __typename?: 'LocaleBlockContent';
        readonly enRaw: any | null;
      } | null;
      readonly backgroundImage: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly incentives: ReadonlyArray<
        | {
            readonly __typename: 'Combo';
            readonly _id: string;
            readonly _type: string;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          }
        | {
            readonly __typename: 'OfferDiscount';
            readonly _id: string;
            readonly _type: string;
            readonly discountValue: number | null;
            readonly discountType: string | null;
            readonly discountProduct:
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          }
        | {
            readonly __typename: 'Picker';
            readonly _id: string;
            readonly _type: string;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'PickerOption';
              readonly option:
                | {
                    readonly __typename: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | null;
            } | null> | null;
          }
        | null
      > | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly category: {
      readonly __typename?: 'RewardCategory';
      readonly label: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly image: {
        readonly __typename?: 'LocaleImage';
        readonly locale: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ISystemwideOffersFragment = {
  readonly __typename?: 'SystemwideOffer';
  readonly _id: string;
  readonly _type: string;
  readonly testOnly: boolean | null;
  readonly loyaltyEngineId: string | null;
  readonly shortCode: string | null;
  readonly mobileOrderOnly: boolean | null;
  readonly redemptionMethod: string | null;
  readonly daypart: ReadonlyArray<string | null> | null;
  readonly redemptionType: string | null;
  readonly offerPrice: number | null;
  readonly uiPattern: string | null;
  readonly isUpcomingOffer: boolean | null;
  readonly name: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly description: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly moreInfo: {
    readonly __typename?: 'LocaleBlockContent';
    readonly localeRaw: any | null;
  } | null;
  readonly howToRedeem: {
    readonly __typename?: 'LocaleBlockContent';
    readonly enRaw: any | null;
  } | null;
  readonly backgroundImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly x: number | null;
      readonly y: number | null;
      readonly height: number | null;
      readonly width: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly upsellOptions: ReadonlyArray<{
    readonly __typename?: 'SystemwideOffer';
    readonly _id: string;
    readonly loyaltyEngineId: string | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null> | null;
  readonly marketPrice:
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | null;
  readonly localizedImage: {
    readonly __typename?: 'LocaleImages';
    readonly locale: {
      readonly __typename?: 'Images';
      readonly imageDescription: string | null;
      readonly app: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly lockedOffersPanel: {
    readonly __typename?: 'LockedOffersPanel';
    readonly completedChallengeHeader: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly completedChallengeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly promoCodePanel: {
    readonly __typename?: 'PromoCodePanel';
    readonly promoCodeLink: string | null;
    readonly promoCodeDescription: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly promoCodeLabel: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
  } | null;
  readonly incentives: ReadonlyArray<
    | {
        readonly __typename: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly isOfferBenefit: boolean | null;
        readonly mainItem: {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {
        readonly __typename: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly operationalItem: {
          readonly __typename?: 'OpItem';
          readonly daypart: ReadonlyArray<string | null> | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | { readonly __typename: 'OfferActivation'; readonly _id: string; readonly _type: string }
    | {
        readonly __typename: 'OfferDiscount';
        readonly _id: string;
        readonly _type: string;
        readonly discountValue: number | null;
        readonly discountType: string | null;
        readonly discountProduct:
          | {
              readonly __typename?: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | null;
      }
    | {
        readonly __typename: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly isOfferBenefit: boolean | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'PickerOption';
          readonly option:
            | {
                readonly __typename: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        } | null> | null;
      }
    | { readonly __typename: 'SwapMapping'; readonly _type: string }
    | null
  > | null;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string | null;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly discountPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'Plu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
  readonly pluConfigs: {
    readonly __typename?: 'PluConfigs';
    readonly _key: string | null;
    readonly _type: string | null;
    readonly partner: ReadonlyArray<{
      readonly __typename?: 'PluConfig';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly serviceMode: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _id: string;
        readonly _type: string;
        readonly name: string | null;
      } | null;
      readonly vendorConfig: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly rules: ReadonlyArray<
    | { readonly __typename?: 'BirthdayMultiplier' }
    | { readonly __typename?: 'CartLimit' }
    | { readonly __typename?: 'CartRequirement' }
    | { readonly __typename?: 'CartRequirementExclusion' }
    | { readonly __typename?: 'CoolDown' }
    | { readonly __typename?: 'DayOfWeekBand' }
    | { readonly __typename?: 'EmailVerifiedRequired' }
    | { readonly __typename?: 'FirstOrderOnly' }
    | { readonly __typename?: 'IsStackable' }
    | { readonly __typename?: 'Limit' }
    | {
        readonly __typename?: 'LoyaltyBetweenDates';
        readonly startDate: any | null;
        readonly endDate: any | null;
      }
    | { readonly __typename?: 'LoyaltyBetweenTimes' }
    | { readonly __typename?: 'PaymentMethod' }
    | {
        readonly __typename?: 'RequiresAuthentication';
        readonly requiresAuthentication: boolean | null;
      }
    | { readonly __typename?: 'RestaurantGroupRestrictions' }
    | { readonly __typename?: 'ServiceModeRestrictions' }
    | { readonly __typename?: 'SubtotalSpend' }
    | { readonly __typename?: 'TierList' }
    | { readonly __typename?: 'TotalSpend' }
    | { readonly __typename?: 'TransactionCount' }
    | {
        readonly __typename?: 'UserAttributes';
        readonly userAttributesItem: ReadonlyArray<{
          readonly __typename?: 'UserAttributesItem';
          readonly attributeItem: string | null;
          readonly useAttributeBatteryLevelFilter: string | null;
          readonly useAttributeBatteryLevelValue: number | null;
          readonly userAttributeBooleanValue: string | null;
          readonly userAttributeStringFilter: string | null;
          readonly userAttributeStringValue: string | null;
        } | null> | null;
      }
    | { readonly __typename?: 'UserCreated' }
    | null
  > | null;
};

export type ITabDetailContentFragment = {
  readonly __typename?: 'TabDetailContent';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly image: {
    readonly __typename?: 'LocaleImage';
    readonly locale: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ITitleAndBodyFragment = {
  readonly __typename?: 'TitleAndBody';
  readonly title: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
  readonly body: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
};

export type ITrendingFragment = {
  readonly __typename?: 'Trending';
  readonly _id: string;
  readonly promotions: ReadonlyArray<
    | {
        readonly __typename?: 'Combo';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'ExternalLink';
        readonly _key: string | null;
        readonly url: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly link: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      }
    | {
        readonly __typename?: 'Item';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | { readonly __typename?: 'Offer'; readonly _id: string; readonly _type: string }
    | {
        readonly __typename?: 'Picker';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | {
        readonly __typename?: 'Section';
        readonly _id: string;
        readonly _type: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      }
    | null
  > | null;
};

export type IVendorConfigFragment = {
  readonly __typename?: 'VendorConfig';
  readonly pluType: string | null;
  readonly parentSanityId: string | null;
  readonly pullUpLevels: number | null;
  readonly constantPlu: string | null;
  readonly discountPlu: string | null;
  readonly quantityBasedPlu: ReadonlyArray<{
    readonly __typename?: 'Plu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly multiConstantPlus: ReadonlyArray<{
    readonly __typename?: 'Plu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly parentChildPlu: {
    readonly __typename?: 'ParentChildPlu';
    readonly plu: string | null;
    readonly childPlu: string | null;
  } | null;
  readonly sizeBasedPlu: {
    readonly __typename?: 'SizeBasedPlu';
    readonly comboPlu: string | null;
    readonly comboSize: string | null;
  } | null;
};

export type IVendorConfigsFragment = {
  readonly __typename?: 'VendorConfigs';
  readonly ncr: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly ncrDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partner: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partnerDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumber: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumberDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicom: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicomDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdi: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdiDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rpos: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rposDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDeliveryDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly toshibaLoyalty: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string | null;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly discountPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'Plu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
};

export type IVideoFileFragment = {
  readonly __typename?: 'File';
  readonly asset: {
    readonly __typename?: 'SanityFileAsset';
    readonly assetId: string | null;
    readonly path: string | null;
    readonly url: string | null;
  } | null;
};

export type IGetItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetItemQuery = {
  readonly __typename?: 'RootQuery';
  readonly Item: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IGetItemWithVendorConfigQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetItemWithVendorConfigQuery = {
  readonly __typename?: 'RootQuery';
  readonly Item: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IGetItemAvailabilityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetItemAvailabilityQuery = {
  readonly __typename?: 'RootQuery';
  readonly Item: {
    readonly __typename?: 'Item';
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly itemSize: number | null;
    readonly itemUnit: string | null;
    readonly operationalItem: {
      readonly __typename?: 'OpItem';
      readonly daypart: ReadonlyArray<string | null> | null;
    } | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly injectDefaultSelection: boolean | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly default: boolean | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ISingleLoyaltyContentQueryVariables = Exact<{ [key: string]: never }>;

export type ISingleLoyaltyContentQuery = {
  readonly __typename?: 'RootQuery';
  readonly allLoyaltyContent: ReadonlyArray<{
    readonly __typename?: 'LoyaltyContent';
    readonly _id: string;
  }>;
};

export type IBirthdayLegalTextQueryVariables = Exact<{
  loyaltyContentId: Scalars['ID']['input'];
}>;

export type IBirthdayLegalTextQuery = {
  readonly __typename?: 'RootQuery';
  readonly LoyaltyContent: {
    readonly __typename?: 'LoyaltyContent';
    readonly birthdayLegalText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type ISystemwidePromotionMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ISystemwidePromotionMessageQuery = {
  readonly __typename?: 'RootQuery';
  readonly SystemwidePromotion: {
    readonly __typename?: 'SystemwidePromotion';
    readonly promoMessage: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IPosDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IPosDataQuery = {
  readonly __typename?: 'RootQuery';
  readonly RestaurantPosData: {
    readonly __typename?: 'RestaurantPosData';
    readonly _id: string;
    readonly plus: string | null;
    readonly deliveryPLUs: string | null;
  } | null;
};

export type IGetOfferByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetOfferByIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly Offer: {
    readonly __typename?: 'Offer';
    readonly _id: string;
    readonly _type: string;
    readonly daypart: ReadonlyArray<string | null> | null;
    readonly forSupport: boolean | null;
    readonly redemptionMethod: string | null;
    readonly offerPrice: number | null;
    readonly uiPattern: string | null;
    readonly shortCode: string | null;
    readonly name: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly headerSuperText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly offerTags: ReadonlyArray<{
      readonly __typename?: 'Tag';
      readonly value: string | null;
      readonly _id: string;
    } | null> | null;
    readonly offerCTA: {
      readonly __typename?: 'LinkAction';
      readonly route: string | null;
      readonly actionText: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly marketPrice:
      | {
          readonly __typename?: 'Combo';
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | null;
    readonly localizedImage: {
      readonly __typename?: 'LocaleImages';
      readonly locale: {
        readonly __typename?: 'Images';
        readonly imageDescription: string | null;
        readonly app: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly option:
      | {
          readonly __typename?: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename?: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | { readonly __typename?: 'OfferActivation'; readonly _type: string }
      | {
          readonly __typename?: 'OfferDiscount';
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly _type: string;
        }
      | { readonly __typename?: 'OfferLoyaltyAction' }
      | {
          readonly __typename?: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | null;
    readonly lockedOffersPanel: {
      readonly __typename?: 'LockedOffersPanel';
      readonly completedChallengeHeader: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly completedChallengeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly promoCodePanel: {
      readonly __typename?: 'PromoCodePanel';
      readonly promoCodeLink: string | null;
      readonly promoCodeDescription: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly promoCodeLabel: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
    readonly ruleSet: ReadonlyArray<
      | { readonly __typename?: 'AllowRedemptionWithoutLogin'; readonly ruleSetType: string | null }
      | {
          readonly __typename?: 'And';
          readonly ruleSetType: string | null;
          readonly ruleSet: ReadonlyArray<
            | {
                readonly __typename?: 'AllowRedemptionWithoutLogin';
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'BetweenDates';
                readonly endDate: any | null;
                readonly startDate: any | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'BetweenTimes';
                readonly endTime: string | null;
                readonly startTime: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'CartContainsPlu' }
            | {
                readonly __typename?: 'CartProperty';
                readonly key: string | null;
                readonly negated: boolean | null;
                readonly operator: string | null;
                readonly value: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'CartRequirement' }
            | {
                readonly __typename?: 'CoolDown';
                readonly minimumElapsedTimeSeconds: number | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'DayOfWeekBand';
                readonly monday: boolean | null;
                readonly tuesday: boolean | null;
                readonly wednesday: boolean | null;
                readonly thursday: boolean | null;
                readonly friday: boolean | null;
                readonly saturday: boolean | null;
                readonly sunday: boolean | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
            | { readonly __typename?: 'IsStackable' }
            | {
                readonly __typename?: 'Limit';
                readonly interval: string | null;
                readonly maximumRedemptions: number | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'PaymentCardType';
                readonly cardType: string | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'Purchase';
                readonly quantity: number | null;
                readonly sanityId: string | null;
                readonly ruleSetType: string | null;
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _id: string;
                      readonly _type: string;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'PickerOption';
                        readonly option:
                          | {
                              readonly __typename?: 'Combo';
                              readonly _id: string;
                              readonly _type: string;
                              readonly mainItem: {
                                readonly __typename?: 'Item';
                                readonly _id: string;
                                readonly _type: string;
                                readonly operationalItem: {
                                  readonly __typename?: 'OpItem';
                                  readonly daypart: ReadonlyArray<string | null> | null;
                                } | null;
                                readonly vendorConfigs: {
                                  readonly __typename?: 'VendorConfigs';
                                  readonly ncr: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly ncrDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partner: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partnerDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumber: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumberDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicom: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicomDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdi: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdiDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rpos: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rposDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDeliveryDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly toshibaLoyalty: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly pluConfigs: {
                                  readonly __typename?: 'PluConfigs';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly partner: ReadonlyArray<{
                                    readonly __typename?: 'PluConfig';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly serviceMode: string | null;
                                    readonly posIntegration: {
                                      readonly __typename?: 'PosIntegration';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly name: string | null;
                                    } | null;
                                    readonly vendorConfig: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null> | null;
                                } | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | null;
                      } | null> | null;
                    }
                  | {
                      readonly __typename?: 'ProductCategory';
                      readonly _id: string;
                      readonly hierarchyLevel: string | null;
                      readonly ruleSetType: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | null;
              }
            | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
            | {
                readonly __typename?: 'RestaurantGroupRestrictions';
                readonly ruleSetType: string | null;
                readonly restaurantGroupRestrictionList: ReadonlyArray<{
                  readonly __typename?: 'RestaurantGroup';
                  readonly restaurants: ReadonlyArray<{
                    readonly __typename?: 'Restaurant';
                    readonly number: string | null;
                  } | null> | null;
                } | null> | null;
              }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'BetweenDates';
          readonly endDate: any | null;
          readonly startDate: any | null;
          readonly ruleSetType: string | null;
        }
      | {
          readonly __typename?: 'BetweenTimes';
          readonly endTime: string | null;
          readonly startTime: string | null;
          readonly ruleSetType: string | null;
        }
      | { readonly __typename?: 'CartContainsPlu' }
      | {
          readonly __typename?: 'CartProperty';
          readonly key: string | null;
          readonly negated: boolean | null;
          readonly operator: string | null;
          readonly value: string | null;
          readonly ruleSetType: string | null;
        }
      | {
          readonly __typename?: 'CoolDown';
          readonly minimumElapsedTimeSeconds: number | null;
          readonly ruleSetType: string | null;
        }
      | {
          readonly __typename?: 'DayOfWeekBand';
          readonly monday: boolean | null;
          readonly tuesday: boolean | null;
          readonly wednesday: boolean | null;
          readonly thursday: boolean | null;
          readonly friday: boolean | null;
          readonly saturday: boolean | null;
          readonly sunday: boolean | null;
          readonly ruleSetType: string | null;
        }
      | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
      | {
          readonly __typename?: 'Limit';
          readonly interval: string | null;
          readonly maximumRedemptions: number | null;
          readonly ruleSetType: string | null;
        }
      | {
          readonly __typename?: 'Or';
          readonly ruleSetType: string | null;
          readonly ruleSet: ReadonlyArray<
            | {
                readonly __typename?: 'AllowRedemptionWithoutLogin';
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'BetweenDates';
                readonly endDate: any | null;
                readonly startDate: any | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'BetweenTimes';
                readonly endTime: string | null;
                readonly startTime: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'CartContainsPlu' }
            | {
                readonly __typename?: 'CartProperty';
                readonly key: string | null;
                readonly negated: boolean | null;
                readonly operator: string | null;
                readonly value: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'CartRequirement' }
            | {
                readonly __typename?: 'CoolDown';
                readonly minimumElapsedTimeSeconds: number | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'DayOfWeekBand';
                readonly monday: boolean | null;
                readonly tuesday: boolean | null;
                readonly wednesday: boolean | null;
                readonly thursday: boolean | null;
                readonly friday: boolean | null;
                readonly saturday: boolean | null;
                readonly sunday: boolean | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
            | {
                readonly __typename?: 'Limit';
                readonly interval: string | null;
                readonly maximumRedemptions: number | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'PaymentCardType';
                readonly cardType: string | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'Purchase';
                readonly quantity: number | null;
                readonly sanityId: string | null;
                readonly ruleSetType: string | null;
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _id: string;
                      readonly _type: string;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'PickerOption';
                        readonly option:
                          | {
                              readonly __typename?: 'Combo';
                              readonly _id: string;
                              readonly _type: string;
                              readonly mainItem: {
                                readonly __typename?: 'Item';
                                readonly _id: string;
                                readonly _type: string;
                                readonly operationalItem: {
                                  readonly __typename?: 'OpItem';
                                  readonly daypart: ReadonlyArray<string | null> | null;
                                } | null;
                                readonly vendorConfigs: {
                                  readonly __typename?: 'VendorConfigs';
                                  readonly ncr: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly ncrDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partner: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partnerDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumber: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumberDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicom: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicomDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdi: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdiDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rpos: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rposDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDeliveryDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly toshibaLoyalty: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly pluConfigs: {
                                  readonly __typename?: 'PluConfigs';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly partner: ReadonlyArray<{
                                    readonly __typename?: 'PluConfig';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly serviceMode: string | null;
                                    readonly posIntegration: {
                                      readonly __typename?: 'PosIntegration';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly name: string | null;
                                    } | null;
                                    readonly vendorConfig: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null> | null;
                                } | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | null;
                      } | null> | null;
                    }
                  | {
                      readonly __typename?: 'ProductCategory';
                      readonly _id: string;
                      readonly hierarchyLevel: string | null;
                      readonly ruleSetType: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | null;
              }
            | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
            | {
                readonly __typename?: 'RestaurantGroupRestrictions';
                readonly ruleSetType: string | null;
                readonly restaurantGroupRestrictionList: ReadonlyArray<{
                  readonly __typename?: 'RestaurantGroup';
                  readonly restaurants: ReadonlyArray<{
                    readonly __typename?: 'Restaurant';
                    readonly number: string | null;
                  } | null> | null;
                } | null> | null;
              }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'OrderHistory';
          readonly rangeType: string | null;
          readonly from: any | null;
          readonly to: any | null;
          readonly lastNSeconds: number | null;
          readonly isSequential: boolean | null;
          readonly ruleSetType: string | null;
          readonly ruleSet: ReadonlyArray<
            | {
                readonly __typename?: 'And';
                readonly ruleSetType: string | null;
                readonly ruleSet: ReadonlyArray<
                  | {
                      readonly __typename?: 'AllowRedemptionWithoutLogin';
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'BetweenDates';
                      readonly endDate: any | null;
                      readonly startDate: any | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'BetweenTimes';
                      readonly endTime: string | null;
                      readonly startTime: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'CartContainsPlu' }
                  | {
                      readonly __typename?: 'CartProperty';
                      readonly key: string | null;
                      readonly negated: boolean | null;
                      readonly operator: string | null;
                      readonly value: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'CartRequirement' }
                  | {
                      readonly __typename?: 'CoolDown';
                      readonly minimumElapsedTimeSeconds: number | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
                  | { readonly __typename?: 'IsStackable' }
                  | {
                      readonly __typename?: 'Limit';
                      readonly interval: string | null;
                      readonly maximumRedemptions: number | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'PaymentCardType';
                      readonly cardType: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'Purchase';
                      readonly quantity: number | null;
                      readonly sanityId: string | null;
                      readonly ruleSetType: string | null;
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Picker';
                            readonly _id: string;
                            readonly _type: string;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'PickerOption';
                              readonly option:
                                | {
                                    readonly __typename?: 'Combo';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly mainItem: {
                                      readonly __typename?: 'Item';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly operationalItem: {
                                        readonly __typename?: 'OpItem';
                                        readonly daypart: ReadonlyArray<string | null> | null;
                                      } | null;
                                      readonly vendorConfigs: {
                                        readonly __typename?: 'VendorConfigs';
                                        readonly ncr: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly ncrDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly partner: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly partnerDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly productNumber: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly productNumberDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly sicom: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly sicomDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly qdi: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly qdiDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly rpos: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly rposDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly simplyDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly simplyDeliveryDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly toshibaLoyalty: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      readonly pluConfigs: {
                                        readonly __typename?: 'PluConfigs';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly partner: ReadonlyArray<{
                                          readonly __typename?: 'PluConfig';
                                          readonly _key: string | null;
                                          readonly _type: string | null;
                                          readonly serviceMode: string | null;
                                          readonly posIntegration: {
                                            readonly __typename?: 'PosIntegration';
                                            readonly _id: string;
                                            readonly _type: string;
                                            readonly name: string | null;
                                          } | null;
                                          readonly vendorConfig: {
                                            readonly __typename?: 'VendorConfig';
                                            readonly pluType: string | null;
                                            readonly parentSanityId: string | null;
                                            readonly pullUpLevels: number | null;
                                            readonly constantPlu: string | null;
                                            readonly discountPlu: string | null;
                                            readonly quantityBasedPlu: ReadonlyArray<{
                                              readonly __typename?: 'Plu';
                                              readonly quantity: number | null;
                                              readonly plu: string | null;
                                              readonly qualifier: string | null;
                                            } | null> | null;
                                            readonly multiConstantPlus: ReadonlyArray<{
                                              readonly __typename?: 'Plu';
                                              readonly quantity: number | null;
                                              readonly plu: string | null;
                                              readonly qualifier: string | null;
                                            } | null> | null;
                                            readonly parentChildPlu: {
                                              readonly __typename?: 'ParentChildPlu';
                                              readonly plu: string | null;
                                              readonly childPlu: string | null;
                                            } | null;
                                            readonly sizeBasedPlu: {
                                              readonly __typename?: 'SizeBasedPlu';
                                              readonly comboPlu: string | null;
                                              readonly comboSize: string | null;
                                            } | null;
                                          } | null;
                                        } | null> | null;
                                      } | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  }
                                | {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly operationalItem: {
                                      readonly __typename?: 'OpItem';
                                      readonly daypart: ReadonlyArray<string | null> | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  }
                                | null;
                            } | null> | null;
                          }
                        | {
                            readonly __typename?: 'ProductCategory';
                            readonly _id: string;
                            readonly hierarchyLevel: string | null;
                            readonly ruleSetType: string;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                          }
                        | null;
                    }
                  | {
                      readonly __typename?: 'RequiresAssignment';
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'RestaurantGroupRestrictions';
                      readonly ruleSetType: string | null;
                      readonly restaurantGroupRestrictionList: ReadonlyArray<{
                        readonly __typename?: 'RestaurantGroup';
                        readonly restaurants: ReadonlyArray<{
                          readonly __typename?: 'Restaurant';
                          readonly number: string | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | null
                > | null;
              }
            | {
                readonly __typename?: 'BetweenTimes';
                readonly endTime: string | null;
                readonly startTime: string | null;
                readonly ruleSetType: string | null;
              }
            | { readonly __typename?: 'CartContainsPlu' }
            | {
                readonly __typename?: 'CartProperty';
                readonly key: string | null;
                readonly negated: boolean | null;
                readonly operator: string | null;
                readonly value: string | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'DayOfWeekBand';
                readonly monday: boolean | null;
                readonly tuesday: boolean | null;
                readonly wednesday: boolean | null;
                readonly thursday: boolean | null;
                readonly friday: boolean | null;
                readonly saturday: boolean | null;
                readonly sunday: boolean | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'Or';
                readonly ruleSetType: string | null;
                readonly ruleSet: ReadonlyArray<
                  | {
                      readonly __typename?: 'AllowRedemptionWithoutLogin';
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'BetweenDates';
                      readonly endDate: any | null;
                      readonly startDate: any | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'BetweenTimes';
                      readonly endTime: string | null;
                      readonly startTime: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'CartContainsPlu' }
                  | {
                      readonly __typename?: 'CartProperty';
                      readonly key: string | null;
                      readonly negated: boolean | null;
                      readonly operator: string | null;
                      readonly value: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'CartRequirement' }
                  | {
                      readonly __typename?: 'CoolDown';
                      readonly minimumElapsedTimeSeconds: number | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                      readonly ruleSetType: string | null;
                    }
                  | { readonly __typename?: 'FirstOrderOnly'; readonly ruleSetType: string | null }
                  | {
                      readonly __typename?: 'Limit';
                      readonly interval: string | null;
                      readonly maximumRedemptions: number | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'PaymentCardType';
                      readonly cardType: string | null;
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'Purchase';
                      readonly quantity: number | null;
                      readonly sanityId: string | null;
                      readonly ruleSetType: string | null;
                      readonly option:
                        | {
                            readonly __typename?: 'Combo';
                            readonly _id: string;
                            readonly _type: string;
                            readonly mainItem: {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly operationalItem: {
                              readonly __typename?: 'OpItem';
                              readonly daypart: ReadonlyArray<string | null> | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Picker';
                            readonly _id: string;
                            readonly _type: string;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'PickerOption';
                              readonly option:
                                | {
                                    readonly __typename?: 'Combo';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly mainItem: {
                                      readonly __typename?: 'Item';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly operationalItem: {
                                        readonly __typename?: 'OpItem';
                                        readonly daypart: ReadonlyArray<string | null> | null;
                                      } | null;
                                      readonly vendorConfigs: {
                                        readonly __typename?: 'VendorConfigs';
                                        readonly ncr: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly ncrDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly partner: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly partnerDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly productNumber: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly productNumberDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly sicom: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly sicomDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly qdi: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly qdiDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly rpos: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly rposDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly simplyDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly simplyDeliveryDelivery: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                        readonly toshibaLoyalty: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      readonly pluConfigs: {
                                        readonly __typename?: 'PluConfigs';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly partner: ReadonlyArray<{
                                          readonly __typename?: 'PluConfig';
                                          readonly _key: string | null;
                                          readonly _type: string | null;
                                          readonly serviceMode: string | null;
                                          readonly posIntegration: {
                                            readonly __typename?: 'PosIntegration';
                                            readonly _id: string;
                                            readonly _type: string;
                                            readonly name: string | null;
                                          } | null;
                                          readonly vendorConfig: {
                                            readonly __typename?: 'VendorConfig';
                                            readonly pluType: string | null;
                                            readonly parentSanityId: string | null;
                                            readonly pullUpLevels: number | null;
                                            readonly constantPlu: string | null;
                                            readonly discountPlu: string | null;
                                            readonly quantityBasedPlu: ReadonlyArray<{
                                              readonly __typename?: 'Plu';
                                              readonly quantity: number | null;
                                              readonly plu: string | null;
                                              readonly qualifier: string | null;
                                            } | null> | null;
                                            readonly multiConstantPlus: ReadonlyArray<{
                                              readonly __typename?: 'Plu';
                                              readonly quantity: number | null;
                                              readonly plu: string | null;
                                              readonly qualifier: string | null;
                                            } | null> | null;
                                            readonly parentChildPlu: {
                                              readonly __typename?: 'ParentChildPlu';
                                              readonly plu: string | null;
                                              readonly childPlu: string | null;
                                            } | null;
                                            readonly sizeBasedPlu: {
                                              readonly __typename?: 'SizeBasedPlu';
                                              readonly comboPlu: string | null;
                                              readonly comboSize: string | null;
                                            } | null;
                                          } | null;
                                        } | null> | null;
                                      } | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  }
                                | {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly operationalItem: {
                                      readonly __typename?: 'OpItem';
                                      readonly daypart: ReadonlyArray<string | null> | null;
                                    } | null;
                                    readonly vendorConfigs: {
                                      readonly __typename?: 'VendorConfigs';
                                      readonly ncr: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly ncrDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partner: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly partnerDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumber: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly productNumberDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicom: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly sicomDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdi: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly qdiDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rpos: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly rposDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly simplyDeliveryDelivery: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                      readonly toshibaLoyalty: {
                                        readonly __typename?: 'VendorConfig';
                                        readonly pluType: string | null;
                                        readonly parentSanityId: string | null;
                                        readonly pullUpLevels: number | null;
                                        readonly constantPlu: string | null;
                                        readonly discountPlu: string | null;
                                        readonly quantityBasedPlu: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly multiConstantPlus: ReadonlyArray<{
                                          readonly __typename?: 'Plu';
                                          readonly quantity: number | null;
                                          readonly plu: string | null;
                                          readonly qualifier: string | null;
                                        } | null> | null;
                                        readonly parentChildPlu: {
                                          readonly __typename?: 'ParentChildPlu';
                                          readonly plu: string | null;
                                          readonly childPlu: string | null;
                                        } | null;
                                        readonly sizeBasedPlu: {
                                          readonly __typename?: 'SizeBasedPlu';
                                          readonly comboPlu: string | null;
                                          readonly comboSize: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pluConfigs: {
                                      readonly __typename?: 'PluConfigs';
                                      readonly _key: string | null;
                                      readonly _type: string | null;
                                      readonly partner: ReadonlyArray<{
                                        readonly __typename?: 'PluConfig';
                                        readonly _key: string | null;
                                        readonly _type: string | null;
                                        readonly serviceMode: string | null;
                                        readonly posIntegration: {
                                          readonly __typename?: 'PosIntegration';
                                          readonly _id: string;
                                          readonly _type: string;
                                          readonly name: string | null;
                                        } | null;
                                        readonly vendorConfig: {
                                          readonly __typename?: 'VendorConfig';
                                          readonly pluType: string | null;
                                          readonly parentSanityId: string | null;
                                          readonly pullUpLevels: number | null;
                                          readonly constantPlu: string | null;
                                          readonly discountPlu: string | null;
                                          readonly quantityBasedPlu: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly multiConstantPlus: ReadonlyArray<{
                                            readonly __typename?: 'Plu';
                                            readonly quantity: number | null;
                                            readonly plu: string | null;
                                            readonly qualifier: string | null;
                                          } | null> | null;
                                          readonly parentChildPlu: {
                                            readonly __typename?: 'ParentChildPlu';
                                            readonly plu: string | null;
                                            readonly childPlu: string | null;
                                          } | null;
                                          readonly sizeBasedPlu: {
                                            readonly __typename?: 'SizeBasedPlu';
                                            readonly comboPlu: string | null;
                                            readonly comboSize: string | null;
                                          } | null;
                                        } | null;
                                      } | null> | null;
                                    } | null;
                                  }
                                | null;
                            } | null> | null;
                          }
                        | {
                            readonly __typename?: 'ProductCategory';
                            readonly _id: string;
                            readonly hierarchyLevel: string | null;
                            readonly ruleSetType: string;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                          }
                        | null;
                    }
                  | {
                      readonly __typename?: 'RequiresAssignment';
                      readonly ruleSetType: string | null;
                    }
                  | {
                      readonly __typename?: 'RestaurantGroupRestrictions';
                      readonly ruleSetType: string | null;
                      readonly restaurantGroupRestrictionList: ReadonlyArray<{
                        readonly __typename?: 'RestaurantGroup';
                        readonly restaurants: ReadonlyArray<{
                          readonly __typename?: 'Restaurant';
                          readonly number: string | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | null
                > | null;
              }
            | {
                readonly __typename?: 'PaymentCardType';
                readonly cardType: string | null;
                readonly ruleSetType: string | null;
              }
            | {
                readonly __typename?: 'Purchase';
                readonly quantity: number | null;
                readonly sanityId: string | null;
                readonly ruleSetType: string | null;
                readonly option:
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _id: string;
                      readonly _type: string;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'PickerOption';
                        readonly option:
                          | {
                              readonly __typename?: 'Combo';
                              readonly _id: string;
                              readonly _type: string;
                              readonly mainItem: {
                                readonly __typename?: 'Item';
                                readonly _id: string;
                                readonly _type: string;
                                readonly operationalItem: {
                                  readonly __typename?: 'OpItem';
                                  readonly daypart: ReadonlyArray<string | null> | null;
                                } | null;
                                readonly vendorConfigs: {
                                  readonly __typename?: 'VendorConfigs';
                                  readonly ncr: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly ncrDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partner: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partnerDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumber: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumberDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicom: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicomDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdi: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdiDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rpos: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rposDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDeliveryDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly toshibaLoyalty: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly pluConfigs: {
                                  readonly __typename?: 'PluConfigs';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly partner: ReadonlyArray<{
                                    readonly __typename?: 'PluConfig';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly serviceMode: string | null;
                                    readonly posIntegration: {
                                      readonly __typename?: 'PosIntegration';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly name: string | null;
                                    } | null;
                                    readonly vendorConfig: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null> | null;
                                } | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly operationalItem: {
                                readonly __typename?: 'OpItem';
                                readonly daypart: ReadonlyArray<string | null> | null;
                              } | null;
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            }
                          | null;
                      } | null> | null;
                    }
                  | {
                      readonly __typename?: 'ProductCategory';
                      readonly _id: string;
                      readonly hierarchyLevel: string | null;
                      readonly ruleSetType: string;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | null;
              }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'PaymentCardType';
          readonly cardType: string | null;
          readonly ruleSetType: string | null;
        }
      | {
          readonly __typename?: 'Purchase';
          readonly quantity: number | null;
          readonly sanityId: string | null;
          readonly ruleSetType: string | null;
          readonly option:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'PickerOption';
                  readonly option:
                    | {
                        readonly __typename?: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly operationalItem: {
                            readonly __typename?: 'OpItem';
                            readonly daypart: ReadonlyArray<string | null> | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      }
                    | null;
                } | null> | null;
              }
            | {
                readonly __typename?: 'ProductCategory';
                readonly _id: string;
                readonly hierarchyLevel: string | null;
                readonly ruleSetType: string;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              }
            | null;
        }
      | { readonly __typename?: 'RequiresAssignment'; readonly ruleSetType: string | null }
      | {
          readonly __typename?: 'RestaurantGroupRestrictions';
          readonly ruleSetType: string | null;
          readonly restaurantGroupRestrictionList: ReadonlyArray<{
            readonly __typename?: 'RestaurantGroup';
            readonly restaurants: ReadonlyArray<{
              readonly __typename?: 'Restaurant';
              readonly number: string | null;
            } | null> | null;
          } | null> | null;
        }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IGetPickerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetPickerQuery = {
  readonly __typename?: 'RootQuery';
  readonly Picker: {
    readonly __typename?: 'Picker';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly isOfferBenefit: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pickerDefaults: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly _key: string | null;
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pickerAspects: ReadonlyArray<{
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly _type: string;
      readonly uiPattern: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pickerAspectOptions: ReadonlyArray<{
        readonly __typename?: 'PickerAspectValue';
        readonly identifier: string | null;
        readonly hideNameInItemPreview: boolean | null;
        readonly showInStaticMenu: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
      readonly __typename?: 'PickerAspectItemOptions';
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ListItem';
        readonly value: string | null;
      } | null> | null;
    } | null> | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'PickerOption';
      readonly _key: string | null;
      readonly default: boolean | null;
      readonly type: string | null;
      readonly pickerItemMappings: ReadonlyArray<{
        readonly __typename?: 'PickerItemMapping';
        readonly pickerAspectValueIdentifier: string | null;
        readonly pickerAspect: {
          readonly __typename?: 'PickerAspect';
          readonly _id: string;
        } | null;
      } | null> | null;
      readonly option:
        | {
            readonly __typename?: 'Combo';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly uiPattern: string | null;
            readonly hideMainItemDescription: boolean | null;
            readonly forceModifiersToBottom: boolean | null;
            readonly isOfferBenefit: boolean | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            } | null;
            readonly markerItem: {
              readonly __typename?: 'MarkerItem';
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlot';
              readonly _type: string;
              readonly _id: string;
              readonly uiPattern: string | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly respectMaximum: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly optionVisibilitySettings: {
                readonly __typename?: 'OptionVisibilitySettings';
                readonly visibleOptions: number | null;
                readonly toggleButtonTextClosed: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly toggleButtonTextOpen: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ComboSlotOption';
                readonly _key: string | null;
                readonly minAmount: number | null;
                readonly defaultAmount: number | null;
                readonly isPremium: boolean | null;
                readonly option:
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly rewardEligible: boolean | null;
                      readonly isDummyItem: boolean | null;
                      readonly labelAsPerPerson: boolean | null;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly description: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly legalInformation: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly sourcesOfGluten: string | null;
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly componentStyle: string | null;
                        readonly upsellModifier: boolean | null;
                        readonly allowMultipleSelections: boolean | null;
                        readonly displayModifierMultiplierName: boolean | null;
                        readonly injectDefaultSelection: boolean | null;
                        readonly singleChoiceOnly: boolean | null;
                        readonly minAmount: number | null;
                        readonly maxAmount: number | null;
                        readonly _key: string | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly displayGroup: {
                          readonly __typename?: 'DisplayGroup';
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly _key: string | null;
                          readonly default: boolean | null;
                          readonly type: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                          readonly modifierMultiplier: {
                            readonly __typename?: 'ModifierMultiplier';
                            readonly multiplier: number | null;
                            readonly prefix: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly modifier: {
                              readonly __typename?: 'Modifier';
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly imageDescription: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly productHierarchy: {
                        readonly __typename?: 'ProductHierarchy';
                        readonly L1: string | null;
                        readonly L2: string | null;
                        readonly L3: string | null;
                        readonly L4: string | null;
                        readonly L5: string | null;
                      } | null;
                      readonly menuObjectSettings: {
                        readonly __typename?: 'MenuObjectSettings';
                        readonly limitPerOrder: number | null;
                      } | null;
                      readonly channelExclusions: {
                        readonly __typename?: 'ChannelExclusions';
                        readonly delivery: boolean | null;
                        readonly pickup: boolean | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _type: string;
                      readonly uiPattern: string | null;
                      readonly pickerAspects: ReadonlyArray<{
                        readonly __typename?: 'PickerAspect';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly pickerAspectOptions: ReadonlyArray<{
                          readonly __typename?: 'PickerAspectValue';
                          readonly identifier: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | { readonly __typename?: 'Section' }
                  | null;
              } | null> | null;
            } | null> | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          }
        | null;
    } | null> | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly quickConfigs: ReadonlyArray<{
      readonly __typename?: 'QuickConfig';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly rules: ReadonlyArray<{
        readonly __typename?: 'QuickConfigRule';
        readonly itemOptions: ReadonlyArray<{
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null> | null;
        readonly modifier: {
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IGetPickerWithVendorConfigQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetPickerWithVendorConfigQuery = {
  readonly __typename?: 'RootQuery';
  readonly Picker: {
    readonly __typename?: 'Picker';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly isOfferBenefit: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pickerDefaults: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly _key: string | null;
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pickerAspects: ReadonlyArray<{
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly _type: string;
      readonly uiPattern: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pickerAspectOptions: ReadonlyArray<{
        readonly __typename?: 'PickerAspectValue';
        readonly identifier: string | null;
        readonly hideNameInItemPreview: boolean | null;
        readonly showInStaticMenu: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
      readonly __typename?: 'PickerAspectItemOptions';
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ListItem';
        readonly value: string | null;
      } | null> | null;
    } | null> | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'PickerOption';
      readonly _key: string | null;
      readonly default: boolean | null;
      readonly type: string | null;
      readonly pickerItemMappings: ReadonlyArray<{
        readonly __typename?: 'PickerItemMapping';
        readonly pickerAspectValueIdentifier: string | null;
        readonly pickerAspect: {
          readonly __typename?: 'PickerAspect';
          readonly _id: string;
        } | null;
      } | null> | null;
      readonly option:
        | {
            readonly __typename?: 'Combo';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly uiPattern: string | null;
            readonly hideMainItemDescription: boolean | null;
            readonly forceModifiersToBottom: boolean | null;
            readonly isOfferBenefit: boolean | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            } | null;
            readonly markerItem: {
              readonly __typename?: 'MarkerItem';
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlot';
              readonly _type: string;
              readonly _id: string;
              readonly uiPattern: string | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly respectMaximum: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly optionVisibilitySettings: {
                readonly __typename?: 'OptionVisibilitySettings';
                readonly visibleOptions: number | null;
                readonly toggleButtonTextClosed: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly toggleButtonTextOpen: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ComboSlotOption';
                readonly _key: string | null;
                readonly minAmount: number | null;
                readonly defaultAmount: number | null;
                readonly isPremium: boolean | null;
                readonly option:
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly rewardEligible: boolean | null;
                      readonly isDummyItem: boolean | null;
                      readonly labelAsPerPerson: boolean | null;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly description: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly legalInformation: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly sourcesOfGluten: string | null;
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly componentStyle: string | null;
                        readonly upsellModifier: boolean | null;
                        readonly allowMultipleSelections: boolean | null;
                        readonly displayModifierMultiplierName: boolean | null;
                        readonly injectDefaultSelection: boolean | null;
                        readonly singleChoiceOnly: boolean | null;
                        readonly minAmount: number | null;
                        readonly maxAmount: number | null;
                        readonly _key: string | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly displayGroup: {
                          readonly __typename?: 'DisplayGroup';
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly _key: string | null;
                          readonly default: boolean | null;
                          readonly type: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                          readonly modifierMultiplier: {
                            readonly __typename?: 'ModifierMultiplier';
                            readonly multiplier: number | null;
                            readonly prefix: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly modifier: {
                              readonly __typename?: 'Modifier';
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly imageDescription: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly productHierarchy: {
                        readonly __typename?: 'ProductHierarchy';
                        readonly L1: string | null;
                        readonly L2: string | null;
                        readonly L3: string | null;
                        readonly L4: string | null;
                        readonly L5: string | null;
                      } | null;
                      readonly menuObjectSettings: {
                        readonly __typename?: 'MenuObjectSettings';
                        readonly limitPerOrder: number | null;
                      } | null;
                      readonly channelExclusions: {
                        readonly __typename?: 'ChannelExclusions';
                        readonly delivery: boolean | null;
                        readonly pickup: boolean | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _type: string;
                      readonly uiPattern: string | null;
                      readonly pickerAspects: ReadonlyArray<{
                        readonly __typename?: 'PickerAspect';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly pickerAspectOptions: ReadonlyArray<{
                          readonly __typename?: 'PickerAspectValue';
                          readonly identifier: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | { readonly __typename?: 'Section' }
                  | null;
              } | null> | null;
            } | null> | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          }
        | null;
    } | null> | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly quickConfigs: ReadonlyArray<{
      readonly __typename?: 'QuickConfig';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly rules: ReadonlyArray<{
        readonly __typename?: 'QuickConfigRule';
        readonly itemOptions: ReadonlyArray<{
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null> | null;
        readonly modifier: {
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IGetPickerAvailabilityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetPickerAvailabilityQuery = {
  readonly __typename?: 'RootQuery';
  readonly Picker: {
    readonly __typename?: 'Picker';
    readonly showInStaticMenu: boolean | null;
    readonly hideCalories: boolean | null;
    readonly hideNutritionLegalDisclaimer: boolean | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'PickerOption';
      readonly option:
        | {
            readonly __typename?: 'Combo';
            readonly showInStaticMenu: boolean | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly showInStaticMenu: boolean | null;
              readonly hideCalories: boolean | null;
              readonly hideNutritionLegalDisclaimer: boolean | null;
              readonly itemSize: number | null;
              readonly itemUnit: string | null;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly injectDefaultSelection: boolean | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly default: boolean | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlot';
              readonly minAmount: number | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ComboSlotOption';
                readonly maxAmount: number | null;
                readonly option:
                  | {
                      readonly __typename?: 'Item';
                      readonly showInStaticMenu: boolean | null;
                      readonly hideCalories: boolean | null;
                      readonly hideNutritionLegalDisclaimer: boolean | null;
                      readonly itemSize: number | null;
                      readonly itemUnit: string | null;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly injectDefaultSelection: boolean | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                          readonly modifierMultiplier: {
                            readonly __typename?: 'ModifierMultiplier';
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                            readonly modifier: {
                              readonly __typename?: 'Modifier';
                              readonly vendorConfigs: {
                                readonly __typename?: 'VendorConfigs';
                                readonly ncr: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly ncrDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partner: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly partnerDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumber: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly productNumberDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicom: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly sicomDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdi: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly qdiDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rpos: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly rposDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly simplyDeliveryDelivery: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                                readonly toshibaLoyalty: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pluConfigs: {
                                readonly __typename?: 'PluConfigs';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly partner: ReadonlyArray<{
                                  readonly __typename?: 'PluConfig';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly serviceMode: string | null;
                                  readonly posIntegration: {
                                    readonly __typename?: 'PosIntegration';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: string | null;
                                  } | null;
                                  readonly vendorConfig: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null> | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | { readonly __typename?: 'Picker' }
                  | { readonly __typename?: 'Section' }
                  | null;
              } | null> | null;
            } | null> | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly showInStaticMenu: boolean | null;
            readonly hideCalories: boolean | null;
            readonly hideNutritionLegalDisclaimer: boolean | null;
            readonly itemSize: number | null;
            readonly itemUnit: string | null;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly injectDefaultSelection: boolean | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly default: boolean | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null> | null;
          }
        | null;
    } | null> | null;
  } | null;
};

export type IPlaceholderFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type IPlaceholderFieldsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allPlaceholderField: ReadonlyArray<{
    readonly __typename?: 'PlaceholderField';
    readonly _id: string;
    readonly formatter: string | null;
    readonly path: string | null;
    readonly fieldId: string | null;
  }>;
};

export type IGetProductCategoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetProductCategoryQuery = {
  readonly __typename?: 'RootQuery';
  readonly ProductCategory: {
    readonly __typename?: 'ProductCategory';
    readonly _id: string;
    readonly hierarchyLevel: string | null;
    readonly ruleSetType: string;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type IGetMenuSectionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetMenuSectionsQuery = {
  readonly __typename?: 'RootQuery';
  readonly Menu: {
    readonly __typename?: 'Menu';
    readonly _id: string;
    readonly _type: string;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<
      | { readonly __typename?: 'Combo' }
      | { readonly __typename?: 'Item' }
      | {
          readonly __typename?: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly uiPattern: string | null;
          readonly isOfferBenefit: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly pickerDefaults: ReadonlyArray<{
            readonly __typename?: 'PickerItemMapping';
            readonly _key: string | null;
            readonly pickerAspectValueIdentifier: string | null;
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null> | null;
          readonly pickerAspects: ReadonlyArray<{
            readonly __typename?: 'PickerAspect';
            readonly _id: string;
            readonly _type: string;
            readonly uiPattern: string | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly pickerAspectOptions: ReadonlyArray<{
              readonly __typename?: 'PickerAspectValue';
              readonly identifier: string | null;
              readonly hideNameInItemPreview: boolean | null;
              readonly showInStaticMenu: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly pickerAspectItemOptionMappings: ReadonlyArray<{
            readonly __typename?: 'PickerAspectItemOptions';
            readonly pickerAspect: {
              readonly __typename?: 'PickerAspect';
              readonly _id: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ListItem';
              readonly value: string | null;
            } | null> | null;
          } | null> | null;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly _key: string | null;
            readonly default: boolean | null;
            readonly type: string | null;
            readonly pickerItemMappings: ReadonlyArray<{
              readonly __typename?: 'PickerItemMapping';
              readonly pickerAspectValueIdentifier: string | null;
              readonly pickerAspect: {
                readonly __typename?: 'PickerAspect';
                readonly _id: string;
              } | null;
            } | null> | null;
            readonly option:
              | {
                  readonly __typename?: 'Combo';
                  readonly _key: string | null;
                  readonly _id: string;
                  readonly _type: string;
                  readonly uiPattern: string | null;
                  readonly hideMainItemDescription: boolean | null;
                  readonly forceModifiersToBottom: boolean | null;
                  readonly isOfferBenefit: boolean | null;
                  readonly promotion: {
                    readonly __typename?: 'SystemwidePromotion';
                    readonly bonusPoints: number | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly imagesByChannels: {
                    readonly __typename?: 'ImagesByChannels';
                    readonly imageRestaurant: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDelivery: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly rewardEligible: boolean | null;
                    readonly isDummyItem: boolean | null;
                    readonly labelAsPerPerson: boolean | null;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly description: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly legalInformation: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly imagesByChannels: {
                      readonly __typename?: 'ImagesByChannels';
                      readonly imageRestaurant: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDelivery: {
                        readonly __typename?: 'Image';
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly sourcesOfGluten: string | null;
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly componentStyle: string | null;
                      readonly upsellModifier: boolean | null;
                      readonly allowMultipleSelections: boolean | null;
                      readonly displayModifierMultiplierName: boolean | null;
                      readonly injectDefaultSelection: boolean | null;
                      readonly singleChoiceOnly: boolean | null;
                      readonly minAmount: number | null;
                      readonly maxAmount: number | null;
                      readonly _key: string | null;
                      readonly type: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly displayGroup: {
                        readonly __typename?: 'DisplayGroup';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly _key: string | null;
                        readonly default: boolean | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                        readonly modifierMultiplier: {
                          readonly __typename?: 'ModifierMultiplier';
                          readonly multiplier: number | null;
                          readonly prefix: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly modifier: {
                            readonly __typename?: 'Modifier';
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly imageDescription: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly productHierarchy: {
                      readonly __typename?: 'ProductHierarchy';
                      readonly L1: string | null;
                      readonly L2: string | null;
                      readonly L3: string | null;
                      readonly L4: string | null;
                      readonly L5: string | null;
                    } | null;
                    readonly menuObjectSettings: {
                      readonly __typename?: 'MenuObjectSettings';
                      readonly limitPerOrder: number | null;
                    } | null;
                    readonly channelExclusions: {
                      readonly __typename?: 'ChannelExclusions';
                      readonly delivery: boolean | null;
                      readonly pickup: boolean | null;
                    } | null;
                  } | null;
                  readonly markerItem: {
                    readonly __typename?: 'MarkerItem';
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ComboSlot';
                    readonly _type: string;
                    readonly _id: string;
                    readonly uiPattern: string | null;
                    readonly minAmount: number | null;
                    readonly maxAmount: number | null;
                    readonly respectMaximum: boolean | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly optionVisibilitySettings: {
                      readonly __typename?: 'OptionVisibilitySettings';
                      readonly visibleOptions: number | null;
                      readonly toggleButtonTextClosed: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly toggleButtonTextOpen: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ComboSlotOption';
                      readonly _key: string | null;
                      readonly minAmount: number | null;
                      readonly defaultAmount: number | null;
                      readonly isPremium: boolean | null;
                      readonly option:
                        | {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly rewardEligible: boolean | null;
                            readonly isDummyItem: boolean | null;
                            readonly labelAsPerPerson: boolean | null;
                            readonly productSize: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly description: {
                              readonly __typename?: 'LocaleBlockContent';
                              readonly localeRaw: any | null;
                            } | null;
                            readonly legalInformation: {
                              readonly __typename?: 'LocaleBlockContent';
                              readonly localeRaw: any | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly imageDescription: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly imagesByChannels: {
                              readonly __typename?: 'ImagesByChannels';
                              readonly imageRestaurant: {
                                readonly __typename?: 'Image';
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly imageDelivery: {
                                readonly __typename?: 'Image';
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly additionalItemInformation: {
                              readonly __typename?: 'AdditionalItemInformation';
                              readonly sourcesOfGluten: string | null;
                              readonly ingredients: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly additives: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly producerDetails: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                            readonly nutritionWithModifiers: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly allergens: {
                              readonly __typename?: 'OpAllergen';
                              readonly milk: number | null;
                              readonly eggs: number | null;
                              readonly fish: number | null;
                              readonly peanuts: number | null;
                              readonly shellfish: number | null;
                              readonly treeNuts: number | null;
                              readonly soy: number | null;
                              readonly wheat: number | null;
                              readonly mustard: number | null;
                              readonly sesame: number | null;
                              readonly celery: number | null;
                              readonly lupin: number | null;
                              readonly gluten: number | null;
                              readonly sulphurDioxide: number | null;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOption';
                              readonly componentStyle: string | null;
                              readonly upsellModifier: boolean | null;
                              readonly allowMultipleSelections: boolean | null;
                              readonly displayModifierMultiplierName: boolean | null;
                              readonly injectDefaultSelection: boolean | null;
                              readonly singleChoiceOnly: boolean | null;
                              readonly minAmount: number | null;
                              readonly maxAmount: number | null;
                              readonly _key: string | null;
                              readonly type: string | null;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly displayGroup: {
                                readonly __typename?: 'DisplayGroup';
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOptionModifier';
                                readonly _key: string | null;
                                readonly default: boolean | null;
                                readonly type: string | null;
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly vendorConfigs: {
                                  readonly __typename?: 'VendorConfigs';
                                  readonly ncr: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly ncrDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partner: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly partnerDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumber: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly productNumberDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicom: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly sicomDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdi: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly qdiDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rpos: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly rposDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly simplyDeliveryDelivery: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                  readonly toshibaLoyalty: {
                                    readonly __typename?: 'VendorConfig';
                                    readonly pluType: string | null;
                                    readonly parentSanityId: string | null;
                                    readonly pullUpLevels: number | null;
                                    readonly constantPlu: string | null;
                                    readonly discountPlu: string | null;
                                    readonly quantityBasedPlu: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly multiConstantPlus: ReadonlyArray<{
                                      readonly __typename?: 'Plu';
                                      readonly quantity: number | null;
                                      readonly plu: string | null;
                                      readonly qualifier: string | null;
                                    } | null> | null;
                                    readonly parentChildPlu: {
                                      readonly __typename?: 'ParentChildPlu';
                                      readonly plu: string | null;
                                      readonly childPlu: string | null;
                                    } | null;
                                    readonly sizeBasedPlu: {
                                      readonly __typename?: 'SizeBasedPlu';
                                      readonly comboPlu: string | null;
                                      readonly comboSize: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly pluConfigs: {
                                  readonly __typename?: 'PluConfigs';
                                  readonly _key: string | null;
                                  readonly _type: string | null;
                                  readonly partner: ReadonlyArray<{
                                    readonly __typename?: 'PluConfig';
                                    readonly _key: string | null;
                                    readonly _type: string | null;
                                    readonly serviceMode: string | null;
                                    readonly posIntegration: {
                                      readonly __typename?: 'PosIntegration';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly name: string | null;
                                    } | null;
                                    readonly vendorConfig: {
                                      readonly __typename?: 'VendorConfig';
                                      readonly pluType: string | null;
                                      readonly parentSanityId: string | null;
                                      readonly pullUpLevels: number | null;
                                      readonly constantPlu: string | null;
                                      readonly discountPlu: string | null;
                                      readonly quantityBasedPlu: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly multiConstantPlus: ReadonlyArray<{
                                        readonly __typename?: 'Plu';
                                        readonly quantity: number | null;
                                        readonly plu: string | null;
                                        readonly qualifier: string | null;
                                      } | null> | null;
                                      readonly parentChildPlu: {
                                        readonly __typename?: 'ParentChildPlu';
                                        readonly plu: string | null;
                                        readonly childPlu: string | null;
                                      } | null;
                                      readonly sizeBasedPlu: {
                                        readonly __typename?: 'SizeBasedPlu';
                                        readonly comboPlu: string | null;
                                        readonly comboSize: string | null;
                                      } | null;
                                    } | null;
                                  } | null> | null;
                                } | null;
                                readonly modifierMultiplier: {
                                  readonly __typename?: 'ModifierMultiplier';
                                  readonly multiplier: number | null;
                                  readonly prefix: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly modifier: {
                                    readonly __typename?: 'Modifier';
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly imageDescription: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                            readonly productHierarchy: {
                              readonly __typename?: 'ProductHierarchy';
                              readonly L1: string | null;
                              readonly L2: string | null;
                              readonly L3: string | null;
                              readonly L4: string | null;
                              readonly L5: string | null;
                            } | null;
                            readonly menuObjectSettings: {
                              readonly __typename?: 'MenuObjectSettings';
                              readonly limitPerOrder: number | null;
                            } | null;
                            readonly channelExclusions: {
                              readonly __typename?: 'ChannelExclusions';
                              readonly delivery: boolean | null;
                              readonly pickup: boolean | null;
                            } | null;
                          }
                        | {
                            readonly __typename?: 'Picker';
                            readonly _type: string;
                            readonly uiPattern: string | null;
                            readonly pickerAspects: ReadonlyArray<{
                              readonly __typename?: 'PickerAspect';
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly pickerAspectOptions: ReadonlyArray<{
                                readonly __typename?: 'PickerAspectValue';
                                readonly identifier: string | null;
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                          }
                        | { readonly __typename?: 'Section' }
                        | null;
                    } | null> | null;
                  } | null> | null;
                  readonly menuObjectSettings: {
                    readonly __typename?: 'MenuObjectSettings';
                    readonly limitPerOrder: number | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _key: string | null;
                  readonly _id: string;
                  readonly _type: string;
                  readonly rewardEligible: boolean | null;
                  readonly isDummyItem: boolean | null;
                  readonly labelAsPerPerson: boolean | null;
                  readonly productSize: string | null;
                  readonly promotion: {
                    readonly __typename?: 'SystemwidePromotion';
                    readonly bonusPoints: number | null;
                  } | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly legalInformation: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly imagesByChannels: {
                    readonly __typename?: 'ImagesByChannels';
                    readonly imageRestaurant: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDelivery: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly sourcesOfGluten: string | null;
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly componentStyle: string | null;
                    readonly upsellModifier: boolean | null;
                    readonly allowMultipleSelections: boolean | null;
                    readonly displayModifierMultiplierName: boolean | null;
                    readonly injectDefaultSelection: boolean | null;
                    readonly singleChoiceOnly: boolean | null;
                    readonly minAmount: number | null;
                    readonly maxAmount: number | null;
                    readonly _key: string | null;
                    readonly type: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly displayGroup: {
                      readonly __typename?: 'DisplayGroup';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly _key: string | null;
                      readonly default: boolean | null;
                      readonly type: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                      readonly modifierMultiplier: {
                        readonly __typename?: 'ModifierMultiplier';
                        readonly multiplier: number | null;
                        readonly prefix: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly modifier: {
                          readonly __typename?: 'Modifier';
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly imageDescription: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly productHierarchy: {
                    readonly __typename?: 'ProductHierarchy';
                    readonly L1: string | null;
                    readonly L2: string | null;
                    readonly L3: string | null;
                    readonly L4: string | null;
                    readonly L5: string | null;
                  } | null;
                  readonly menuObjectSettings: {
                    readonly __typename?: 'MenuObjectSettings';
                    readonly limitPerOrder: number | null;
                  } | null;
                  readonly channelExclusions: {
                    readonly __typename?: 'ChannelExclusions';
                    readonly delivery: boolean | null;
                    readonly pickup: boolean | null;
                  } | null;
                }
              | null;
          } | null> | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly description: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly legalInformation: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
          readonly quickConfigs: ReadonlyArray<{
            readonly __typename?: 'QuickConfig';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly rules: ReadonlyArray<{
              readonly __typename?: 'QuickConfigRule';
              readonly itemOptions: ReadonlyArray<{
                readonly __typename?: 'ListItem';
                readonly value: string | null;
              } | null> | null;
              readonly modifier: {
                readonly __typename?: 'ListItem';
                readonly value: string | null;
              } | null;
            } | null> | null;
          } | null> | null;
          readonly channelExclusions: {
            readonly __typename?: 'ChannelExclusions';
            readonly delivery: boolean | null;
            readonly pickup: boolean | null;
          } | null;
        }
      | {
          readonly __typename?: 'Section';
          readonly _id: string;
          readonly _type: string;
          readonly daypart: ReadonlyArray<string | null> | null;
          readonly uiPattern: string | null;
          readonly showInStaticMenu: boolean | null;
          readonly isBurgersForBreakfastSection: boolean | null;
          readonly hiddenFromMainMenu: boolean | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly headerText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly imageDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly carouselImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly options: ReadonlyArray<
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly uiPattern: string | null;
                readonly showInStaticMenu: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imagesByChannels: {
                  readonly __typename?: 'ImagesByChannels';
                  readonly imageRestaurant: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDelivery: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly showInStaticMenu: boolean | null;
                readonly hideCalories: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imagesByChannels: {
                  readonly __typename?: 'ImagesByChannels';
                  readonly imageRestaurant: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDelivery: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              }
            | { readonly __typename?: 'Offer' }
            | {
                readonly __typename?: 'Picker';
                readonly _id: string;
                readonly _type: string;
                readonly uiPattern: string | null;
                readonly showInStaticMenu: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imagesByChannels: {
                  readonly __typename?: 'ImagesByChannels';
                  readonly imageRestaurant: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDelivery: {
                    readonly __typename?: 'Image';
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              }
            | {
                readonly __typename?: 'Section';
                readonly _id: string;
                readonly _type: string;
                readonly hiddenFromMainMenu: boolean | null;
                readonly uiPattern: string | null;
                readonly showInStaticMenu: boolean | null;
                readonly daypart: ReadonlyArray<string | null> | null;
                readonly isBurgersForBreakfastSection: boolean | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly image: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly headerText: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly imageDescription: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly carouselImage: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<
                  | {
                      readonly __typename?: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly uiPattern: string | null;
                      readonly showInStaticMenu: boolean | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly showInStaticMenu: boolean | null;
                      readonly hideCalories: boolean | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | { readonly __typename?: 'Offer' }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _id: string;
                      readonly _type: string;
                      readonly uiPattern: string | null;
                      readonly showInStaticMenu: boolean | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | { readonly __typename?: 'Section' }
                  | null
                > | null;
              }
            | null
          > | null;
        }
      | null
    > | null;
    readonly headerText: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly pickerBackgroundImage: {
      readonly __typename?: 'Image';
      readonly asset: { readonly __typename?: 'SanityImageAsset'; readonly _id: string } | null;
    } | null;
  } | null;
};

export type IGetMenuPickerByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetMenuPickerByIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly Picker: {
    readonly __typename?: 'Picker';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly isOfferBenefit: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly pickerDefaults: ReadonlyArray<{
      readonly __typename?: 'PickerItemMapping';
      readonly _key: string | null;
      readonly pickerAspectValueIdentifier: string | null;
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pickerAspects: ReadonlyArray<{
      readonly __typename?: 'PickerAspect';
      readonly _id: string;
      readonly _type: string;
      readonly uiPattern: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly pickerAspectOptions: ReadonlyArray<{
        readonly __typename?: 'PickerAspectValue';
        readonly identifier: string | null;
        readonly hideNameInItemPreview: boolean | null;
        readonly showInStaticMenu: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
      readonly __typename?: 'PickerAspectItemOptions';
      readonly pickerAspect: {
        readonly __typename?: 'PickerAspect';
        readonly _id: string;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ListItem';
        readonly value: string | null;
      } | null> | null;
    } | null> | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'PickerOption';
      readonly _key: string | null;
      readonly default: boolean | null;
      readonly type: string | null;
      readonly pickerItemMappings: ReadonlyArray<{
        readonly __typename?: 'PickerItemMapping';
        readonly pickerAspectValueIdentifier: string | null;
        readonly pickerAspect: {
          readonly __typename?: 'PickerAspect';
          readonly _id: string;
        } | null;
      } | null> | null;
      readonly option:
        | {
            readonly __typename?: 'Combo';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly uiPattern: string | null;
            readonly hideMainItemDescription: boolean | null;
            readonly forceModifiersToBottom: boolean | null;
            readonly isOfferBenefit: boolean | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly mainItem: {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            } | null;
            readonly markerItem: {
              readonly __typename?: 'MarkerItem';
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ComboSlot';
              readonly _type: string;
              readonly _id: string;
              readonly uiPattern: string | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly respectMaximum: boolean | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly optionVisibilitySettings: {
                readonly __typename?: 'OptionVisibilitySettings';
                readonly visibleOptions: number | null;
                readonly toggleButtonTextClosed: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly toggleButtonTextOpen: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ComboSlotOption';
                readonly _key: string | null;
                readonly minAmount: number | null;
                readonly defaultAmount: number | null;
                readonly isPremium: boolean | null;
                readonly option:
                  | {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly rewardEligible: boolean | null;
                      readonly isDummyItem: boolean | null;
                      readonly labelAsPerPerson: boolean | null;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly description: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly legalInformation: {
                        readonly __typename?: 'LocaleBlockContent';
                        readonly localeRaw: any | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly imagesByChannels: {
                        readonly __typename?: 'ImagesByChannels';
                        readonly imageRestaurant: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDelivery: {
                          readonly __typename?: 'Image';
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly sourcesOfGluten: string | null;
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly componentStyle: string | null;
                        readonly upsellModifier: boolean | null;
                        readonly allowMultipleSelections: boolean | null;
                        readonly displayModifierMultiplierName: boolean | null;
                        readonly injectDefaultSelection: boolean | null;
                        readonly singleChoiceOnly: boolean | null;
                        readonly minAmount: number | null;
                        readonly maxAmount: number | null;
                        readonly _key: string | null;
                        readonly type: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly displayGroup: {
                          readonly __typename?: 'DisplayGroup';
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly _key: string | null;
                          readonly default: boolean | null;
                          readonly type: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly vendorConfigs: {
                            readonly __typename?: 'VendorConfigs';
                            readonly ncr: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly ncrDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partner: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly partnerDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumber: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly productNumberDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicom: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly sicomDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdi: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly qdiDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rpos: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly rposDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly simplyDeliveryDelivery: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                            readonly toshibaLoyalty: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly pluConfigs: {
                            readonly __typename?: 'PluConfigs';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly partner: ReadonlyArray<{
                              readonly __typename?: 'PluConfig';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly serviceMode: string | null;
                              readonly posIntegration: {
                                readonly __typename?: 'PosIntegration';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: string | null;
                              } | null;
                              readonly vendorConfig: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null> | null;
                          } | null;
                          readonly modifierMultiplier: {
                            readonly __typename?: 'ModifierMultiplier';
                            readonly multiplier: number | null;
                            readonly prefix: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly modifier: {
                              readonly __typename?: 'Modifier';
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly imageDescription: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly productHierarchy: {
                        readonly __typename?: 'ProductHierarchy';
                        readonly L1: string | null;
                        readonly L2: string | null;
                        readonly L3: string | null;
                        readonly L4: string | null;
                        readonly L5: string | null;
                      } | null;
                      readonly menuObjectSettings: {
                        readonly __typename?: 'MenuObjectSettings';
                        readonly limitPerOrder: number | null;
                      } | null;
                      readonly channelExclusions: {
                        readonly __typename?: 'ChannelExclusions';
                        readonly delivery: boolean | null;
                        readonly pickup: boolean | null;
                      } | null;
                    }
                  | {
                      readonly __typename?: 'Picker';
                      readonly _type: string;
                      readonly uiPattern: string | null;
                      readonly pickerAspects: ReadonlyArray<{
                        readonly __typename?: 'PickerAspect';
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly pickerAspectOptions: ReadonlyArray<{
                          readonly __typename?: 'PickerAspectValue';
                          readonly identifier: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                    }
                  | { readonly __typename?: 'Section' }
                  | null;
              } | null> | null;
            } | null> | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
          }
        | {
            readonly __typename?: 'Item';
            readonly _key: string | null;
            readonly _id: string;
            readonly _type: string;
            readonly rewardEligible: boolean | null;
            readonly isDummyItem: boolean | null;
            readonly labelAsPerPerson: boolean | null;
            readonly productSize: string | null;
            readonly promotion: {
              readonly __typename?: 'SystemwidePromotion';
              readonly bonusPoints: number | null;
            } | null;
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly description: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly legalInformation: {
              readonly __typename?: 'LocaleBlockContent';
              readonly localeRaw: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly imagesByChannels: {
              readonly __typename?: 'ImagesByChannels';
              readonly imageRestaurant: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDelivery: {
                readonly __typename?: 'Image';
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly nutrition: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly additionalItemInformation: {
              readonly __typename?: 'AdditionalItemInformation';
              readonly sourcesOfGluten: string | null;
              readonly ingredients: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly additives: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly producerDetails: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
            readonly nutritionWithModifiers: {
              readonly __typename?: 'Nutrition';
              readonly calories: number | null;
              readonly caloriesPer100: number | null;
              readonly carbohydrates: number | null;
              readonly carbohydratesPer100: number | null;
              readonly cholesterol: number | null;
              readonly energyKJ: number | null;
              readonly energyKJPer100: number | null;
              readonly fat: number | null;
              readonly fatPer100: number | null;
              readonly fiber: number | null;
              readonly proteins: number | null;
              readonly proteinsPer100: number | null;
              readonly salt: number | null;
              readonly saltPer100: number | null;
              readonly saturatedFat: number | null;
              readonly saturatedFatPer100: number | null;
              readonly sodium: number | null;
              readonly sugar: number | null;
              readonly sugarPer100: number | null;
              readonly transFat: number | null;
              readonly weight: number | null;
            } | null;
            readonly allergens: {
              readonly __typename?: 'OpAllergen';
              readonly milk: number | null;
              readonly eggs: number | null;
              readonly fish: number | null;
              readonly peanuts: number | null;
              readonly shellfish: number | null;
              readonly treeNuts: number | null;
              readonly soy: number | null;
              readonly wheat: number | null;
              readonly mustard: number | null;
              readonly sesame: number | null;
              readonly celery: number | null;
              readonly lupin: number | null;
              readonly gluten: number | null;
              readonly sulphurDioxide: number | null;
            } | null;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ItemOption';
              readonly componentStyle: string | null;
              readonly upsellModifier: boolean | null;
              readonly allowMultipleSelections: boolean | null;
              readonly displayModifierMultiplierName: boolean | null;
              readonly injectDefaultSelection: boolean | null;
              readonly singleChoiceOnly: boolean | null;
              readonly minAmount: number | null;
              readonly maxAmount: number | null;
              readonly _key: string | null;
              readonly type: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly displayGroup: {
                readonly __typename?: 'DisplayGroup';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOptionModifier';
                readonly _key: string | null;
                readonly default: boolean | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
                readonly modifierMultiplier: {
                  readonly __typename?: 'ModifierMultiplier';
                  readonly multiplier: number | null;
                  readonly prefix: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly modifier: {
                    readonly __typename?: 'Modifier';
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDescription: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly nutrition: {
                  readonly __typename?: 'Nutrition';
                  readonly calories: number | null;
                  readonly caloriesPer100: number | null;
                  readonly carbohydrates: number | null;
                  readonly carbohydratesPer100: number | null;
                  readonly cholesterol: number | null;
                  readonly energyKJ: number | null;
                  readonly energyKJPer100: number | null;
                  readonly fat: number | null;
                  readonly fatPer100: number | null;
                  readonly fiber: number | null;
                  readonly proteins: number | null;
                  readonly proteinsPer100: number | null;
                  readonly salt: number | null;
                  readonly saltPer100: number | null;
                  readonly saturatedFat: number | null;
                  readonly saturatedFatPer100: number | null;
                  readonly sodium: number | null;
                  readonly sugar: number | null;
                  readonly sugarPer100: number | null;
                  readonly transFat: number | null;
                  readonly weight: number | null;
                } | null;
              } | null> | null;
            } | null> | null;
            readonly productHierarchy: {
              readonly __typename?: 'ProductHierarchy';
              readonly L1: string | null;
              readonly L2: string | null;
              readonly L3: string | null;
              readonly L4: string | null;
              readonly L5: string | null;
            } | null;
            readonly menuObjectSettings: {
              readonly __typename?: 'MenuObjectSettings';
              readonly limitPerOrder: number | null;
            } | null;
            readonly channelExclusions: {
              readonly __typename?: 'ChannelExclusions';
              readonly delivery: boolean | null;
              readonly pickup: boolean | null;
            } | null;
          }
        | null;
    } | null> | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly quickConfigs: ReadonlyArray<{
      readonly __typename?: 'QuickConfig';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly rules: ReadonlyArray<{
        readonly __typename?: 'QuickConfigRule';
        readonly itemOptions: ReadonlyArray<{
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null> | null;
        readonly modifier: {
          readonly __typename?: 'ListItem';
          readonly value: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IGetMenuComboByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetMenuComboByIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly Combo: {
    readonly __typename?: 'Combo';
    readonly _id: string;
    readonly _type: string;
    readonly uiPattern: string | null;
    readonly hideMainItemDescription: boolean | null;
    readonly forceModifiersToBottom: boolean | null;
    readonly isOfferBenefit: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly mainItem: {
      readonly __typename?: 'Item';
      readonly _id: string;
      readonly _type: string;
      readonly rewardEligible: boolean | null;
      readonly isDummyItem: boolean | null;
      readonly labelAsPerPerson: boolean | null;
      readonly productSize: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly legalInformation: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
      readonly image: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDescription: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly imagesByChannels: {
        readonly __typename?: 'ImagesByChannels';
        readonly imageRestaurant: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly imageDelivery: {
          readonly __typename?: 'Image';
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      readonly nutrition: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly additionalItemInformation: {
        readonly __typename?: 'AdditionalItemInformation';
        readonly sourcesOfGluten: string | null;
        readonly ingredients: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly additives: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly producerDetails: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly nutritionWithModifiers: {
        readonly __typename?: 'Nutrition';
        readonly calories: number | null;
        readonly caloriesPer100: number | null;
        readonly carbohydrates: number | null;
        readonly carbohydratesPer100: number | null;
        readonly cholesterol: number | null;
        readonly energyKJ: number | null;
        readonly energyKJPer100: number | null;
        readonly fat: number | null;
        readonly fatPer100: number | null;
        readonly fiber: number | null;
        readonly proteins: number | null;
        readonly proteinsPer100: number | null;
        readonly salt: number | null;
        readonly saltPer100: number | null;
        readonly saturatedFat: number | null;
        readonly saturatedFatPer100: number | null;
        readonly sodium: number | null;
        readonly sugar: number | null;
        readonly sugarPer100: number | null;
        readonly transFat: number | null;
        readonly weight: number | null;
      } | null;
      readonly allergens: {
        readonly __typename?: 'OpAllergen';
        readonly milk: number | null;
        readonly eggs: number | null;
        readonly fish: number | null;
        readonly peanuts: number | null;
        readonly shellfish: number | null;
        readonly treeNuts: number | null;
        readonly soy: number | null;
        readonly wheat: number | null;
        readonly mustard: number | null;
        readonly sesame: number | null;
        readonly celery: number | null;
        readonly lupin: number | null;
        readonly gluten: number | null;
        readonly sulphurDioxide: number | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOption';
        readonly componentStyle: string | null;
        readonly upsellModifier: boolean | null;
        readonly allowMultipleSelections: boolean | null;
        readonly displayModifierMultiplierName: boolean | null;
        readonly injectDefaultSelection: boolean | null;
        readonly singleChoiceOnly: boolean | null;
        readonly minAmount: number | null;
        readonly maxAmount: number | null;
        readonly _key: string | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly displayGroup: {
          readonly __typename?: 'DisplayGroup';
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        } | null;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ItemOptionModifier';
          readonly _key: string | null;
          readonly default: boolean | null;
          readonly type: string | null;
          readonly name: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly modifierMultiplier: {
            readonly __typename?: 'ModifierMultiplier';
            readonly multiplier: number | null;
            readonly prefix: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly modifier: {
              readonly __typename?: 'Modifier';
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
            } | null;
          } | null;
          readonly nutrition: {
            readonly __typename?: 'Nutrition';
            readonly calories: number | null;
            readonly caloriesPer100: number | null;
            readonly carbohydrates: number | null;
            readonly carbohydratesPer100: number | null;
            readonly cholesterol: number | null;
            readonly energyKJ: number | null;
            readonly energyKJPer100: number | null;
            readonly fat: number | null;
            readonly fatPer100: number | null;
            readonly fiber: number | null;
            readonly proteins: number | null;
            readonly proteinsPer100: number | null;
            readonly salt: number | null;
            readonly saltPer100: number | null;
            readonly saturatedFat: number | null;
            readonly saturatedFatPer100: number | null;
            readonly sodium: number | null;
            readonly sugar: number | null;
            readonly sugarPer100: number | null;
            readonly transFat: number | null;
            readonly weight: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
      readonly productHierarchy: {
        readonly __typename?: 'ProductHierarchy';
        readonly L1: string | null;
        readonly L2: string | null;
        readonly L3: string | null;
        readonly L4: string | null;
        readonly L5: string | null;
      } | null;
      readonly menuObjectSettings: {
        readonly __typename?: 'MenuObjectSettings';
        readonly limitPerOrder: number | null;
      } | null;
      readonly channelExclusions: {
        readonly __typename?: 'ChannelExclusions';
        readonly delivery: boolean | null;
        readonly pickup: boolean | null;
      } | null;
    } | null;
    readonly markerItem: {
      readonly __typename?: 'MarkerItem';
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly pluConfigs: {
        readonly __typename?: 'PluConfigs';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly partner: ReadonlyArray<{
          readonly __typename?: 'PluConfig';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly serviceMode: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _id: string;
            readonly _type: string;
            readonly name: string | null;
          } | null;
          readonly vendorConfig: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ComboSlot';
      readonly _type: string;
      readonly _id: string;
      readonly uiPattern: string | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly respectMaximum: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly optionVisibilitySettings: {
        readonly __typename?: 'OptionVisibilitySettings';
        readonly visibleOptions: number | null;
        readonly toggleButtonTextClosed: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly toggleButtonTextOpen: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ComboSlotOption';
        readonly _key: string | null;
        readonly minAmount: number | null;
        readonly defaultAmount: number | null;
        readonly isPremium: boolean | null;
        readonly option:
          | {
              readonly __typename?: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly rewardEligible: boolean | null;
              readonly isDummyItem: boolean | null;
              readonly labelAsPerPerson: boolean | null;
              readonly productSize: string | null;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly description: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly legalInformation: {
                readonly __typename?: 'LocaleBlockContent';
                readonly localeRaw: any | null;
              } | null;
              readonly image: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
              readonly imageDescription: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly imagesByChannels: {
                readonly __typename?: 'ImagesByChannels';
                readonly imageRestaurant: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly imageDelivery: {
                  readonly __typename?: 'Image';
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
              readonly nutrition: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly additionalItemInformation: {
                readonly __typename?: 'AdditionalItemInformation';
                readonly sourcesOfGluten: string | null;
                readonly ingredients: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly additives: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly producerDetails: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
              } | null;
              readonly nutritionWithModifiers: {
                readonly __typename?: 'Nutrition';
                readonly calories: number | null;
                readonly caloriesPer100: number | null;
                readonly carbohydrates: number | null;
                readonly carbohydratesPer100: number | null;
                readonly cholesterol: number | null;
                readonly energyKJ: number | null;
                readonly energyKJPer100: number | null;
                readonly fat: number | null;
                readonly fatPer100: number | null;
                readonly fiber: number | null;
                readonly proteins: number | null;
                readonly proteinsPer100: number | null;
                readonly salt: number | null;
                readonly saltPer100: number | null;
                readonly saturatedFat: number | null;
                readonly saturatedFatPer100: number | null;
                readonly sodium: number | null;
                readonly sugar: number | null;
                readonly sugarPer100: number | null;
                readonly transFat: number | null;
                readonly weight: number | null;
              } | null;
              readonly allergens: {
                readonly __typename?: 'OpAllergen';
                readonly milk: number | null;
                readonly eggs: number | null;
                readonly fish: number | null;
                readonly peanuts: number | null;
                readonly shellfish: number | null;
                readonly treeNuts: number | null;
                readonly soy: number | null;
                readonly wheat: number | null;
                readonly mustard: number | null;
                readonly sesame: number | null;
                readonly celery: number | null;
                readonly lupin: number | null;
                readonly gluten: number | null;
                readonly sulphurDioxide: number | null;
              } | null;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'ItemOption';
                readonly componentStyle: string | null;
                readonly upsellModifier: boolean | null;
                readonly allowMultipleSelections: boolean | null;
                readonly displayModifierMultiplierName: boolean | null;
                readonly injectDefaultSelection: boolean | null;
                readonly singleChoiceOnly: boolean | null;
                readonly minAmount: number | null;
                readonly maxAmount: number | null;
                readonly _key: string | null;
                readonly type: string | null;
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly displayGroup: {
                  readonly __typename?: 'DisplayGroup';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null;
                readonly options: ReadonlyArray<{
                  readonly __typename?: 'ItemOptionModifier';
                  readonly _key: string | null;
                  readonly default: boolean | null;
                  readonly type: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                  readonly modifierMultiplier: {
                    readonly __typename?: 'ModifierMultiplier';
                    readonly multiplier: number | null;
                    readonly prefix: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly modifier: {
                      readonly __typename?: 'Modifier';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly imageDescription: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                } | null> | null;
              } | null> | null;
              readonly productHierarchy: {
                readonly __typename?: 'ProductHierarchy';
                readonly L1: string | null;
                readonly L2: string | null;
                readonly L3: string | null;
                readonly L4: string | null;
                readonly L5: string | null;
              } | null;
              readonly menuObjectSettings: {
                readonly __typename?: 'MenuObjectSettings';
                readonly limitPerOrder: number | null;
              } | null;
              readonly channelExclusions: {
                readonly __typename?: 'ChannelExclusions';
                readonly delivery: boolean | null;
                readonly pickup: boolean | null;
              } | null;
            }
          | {
              readonly __typename?: 'Picker';
              readonly _type: string;
              readonly uiPattern: string | null;
              readonly pickerAspects: ReadonlyArray<{
                readonly __typename?: 'PickerAspect';
                readonly name: {
                  readonly __typename?: 'LocaleString';
                  readonly locale: string | null;
                } | null;
                readonly pickerAspectOptions: ReadonlyArray<{
                  readonly __typename?: 'PickerAspectValue';
                  readonly identifier: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                } | null> | null;
              } | null> | null;
            }
          | { readonly __typename?: 'Section' }
          | null;
      } | null> | null;
    } | null> | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
  } | null;
};

export type IGetMenuItemByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetMenuItemByIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly Item: {
    readonly __typename?: 'Item';
    readonly _id: string;
    readonly _type: string;
    readonly rewardEligible: boolean | null;
    readonly isDummyItem: boolean | null;
    readonly labelAsPerPerson: boolean | null;
    readonly productSize: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly legalInformation: {
      readonly __typename?: 'LocaleBlockContent';
      readonly localeRaw: any | null;
    } | null;
    readonly image: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly imagesByChannels: {
      readonly __typename?: 'ImagesByChannels';
      readonly imageRestaurant: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
      readonly imageDelivery: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly nutrition: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly additionalItemInformation: {
      readonly __typename?: 'AdditionalItemInformation';
      readonly sourcesOfGluten: string | null;
      readonly ingredients: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly additives: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly producerDetails: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly nutritionWithModifiers: {
      readonly __typename?: 'Nutrition';
      readonly calories: number | null;
      readonly caloriesPer100: number | null;
      readonly carbohydrates: number | null;
      readonly carbohydratesPer100: number | null;
      readonly cholesterol: number | null;
      readonly energyKJ: number | null;
      readonly energyKJPer100: number | null;
      readonly fat: number | null;
      readonly fatPer100: number | null;
      readonly fiber: number | null;
      readonly proteins: number | null;
      readonly proteinsPer100: number | null;
      readonly salt: number | null;
      readonly saltPer100: number | null;
      readonly saturatedFat: number | null;
      readonly saturatedFatPer100: number | null;
      readonly sodium: number | null;
      readonly sugar: number | null;
      readonly sugarPer100: number | null;
      readonly transFat: number | null;
      readonly weight: number | null;
    } | null;
    readonly allergens: {
      readonly __typename?: 'OpAllergen';
      readonly milk: number | null;
      readonly eggs: number | null;
      readonly fish: number | null;
      readonly peanuts: number | null;
      readonly shellfish: number | null;
      readonly treeNuts: number | null;
      readonly soy: number | null;
      readonly wheat: number | null;
      readonly mustard: number | null;
      readonly sesame: number | null;
      readonly celery: number | null;
      readonly lupin: number | null;
      readonly gluten: number | null;
      readonly sulphurDioxide: number | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ItemOption';
      readonly componentStyle: string | null;
      readonly upsellModifier: boolean | null;
      readonly allowMultipleSelections: boolean | null;
      readonly displayModifierMultiplierName: boolean | null;
      readonly injectDefaultSelection: boolean | null;
      readonly singleChoiceOnly: boolean | null;
      readonly minAmount: number | null;
      readonly maxAmount: number | null;
      readonly _key: string | null;
      readonly type: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly displayGroup: {
        readonly __typename?: 'DisplayGroup';
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
      } | null;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ItemOptionModifier';
        readonly _key: string | null;
        readonly default: boolean | null;
        readonly type: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly modifierMultiplier: {
          readonly __typename?: 'ModifierMultiplier';
          readonly multiplier: number | null;
          readonly prefix: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly modifier: {
            readonly __typename?: 'Modifier';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly imageDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        } | null;
        readonly nutrition: {
          readonly __typename?: 'Nutrition';
          readonly calories: number | null;
          readonly caloriesPer100: number | null;
          readonly carbohydrates: number | null;
          readonly carbohydratesPer100: number | null;
          readonly cholesterol: number | null;
          readonly energyKJ: number | null;
          readonly energyKJPer100: number | null;
          readonly fat: number | null;
          readonly fatPer100: number | null;
          readonly fiber: number | null;
          readonly proteins: number | null;
          readonly proteinsPer100: number | null;
          readonly salt: number | null;
          readonly saltPer100: number | null;
          readonly saturatedFat: number | null;
          readonly saturatedFatPer100: number | null;
          readonly sodium: number | null;
          readonly sugar: number | null;
          readonly sugarPer100: number | null;
          readonly transFat: number | null;
          readonly weight: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    readonly productHierarchy: {
      readonly __typename?: 'ProductHierarchy';
      readonly L1: string | null;
      readonly L2: string | null;
      readonly L3: string | null;
      readonly L4: string | null;
      readonly L5: string | null;
    } | null;
    readonly menuObjectSettings: {
      readonly __typename?: 'MenuObjectSettings';
      readonly limitPerOrder: number | null;
    } | null;
    readonly channelExclusions: {
      readonly __typename?: 'ChannelExclusions';
      readonly delivery: boolean | null;
      readonly pickup: boolean | null;
    } | null;
  } | null;
};

export type IRedeemInRestaurantConfigQueryVariables = Exact<{
  redeemInRestaurantConfigId: Scalars['ID']['input'];
}>;

export type IRedeemInRestaurantConfigQuery = {
  readonly __typename?: 'RootQuery';
  readonly ConfigRedeemInRestaurant: {
    readonly __typename?: 'ConfigRedeemInRestaurant';
    readonly _id: string;
    readonly showQrCode: boolean | null;
    readonly widgets: ReadonlyArray<
      | {
          readonly __typename?: 'LoyaltyLegacyShortCodeWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly subtitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly instructions: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyMarketingTileGroupWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly marketingTileGroup: {
            readonly __typename?: 'MarketingTileGroup';
            readonly _id: string;
            readonly tiles: ReadonlyArray<
              | {
                  readonly __typename?: 'MarketingTile';
                  readonly _id: string;
                  readonly textAlignment: string | null;
                  readonly buttonAlignment: string | null;
                  readonly textColor: string | null;
                  readonly lockedAspectRatio: boolean | null;
                  readonly backgroundImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly buttonAction: {
                    readonly __typename?: 'LinkAction';
                    readonly route: string | null;
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly textBlock: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsText: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly termsButton: {
                    readonly __typename?: 'LinkAction';
                    readonly actionText: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly actionUrl: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly ruleSet: {
                    readonly __typename?: 'MarketingTileRuleSet';
                    readonly BKPayAudience: string | null;
                    readonly bkSpecificTargeting: boolean | null;
                    readonly dayOfWeek: {
                      readonly __typename?: 'DayOfWeekBand';
                      readonly monday: boolean | null;
                      readonly tuesday: boolean | null;
                      readonly wednesday: boolean | null;
                      readonly thursday: boolean | null;
                      readonly friday: boolean | null;
                      readonly saturday: boolean | null;
                      readonly sunday: boolean | null;
                    } | null;
                    readonly betweenTimes: {
                      readonly __typename?: 'BetweenTimes';
                      readonly startTime: string | null;
                      readonly endTime: string | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileBasic';
                  readonly _id: string;
                  readonly marketingBasicTileDescription: {
                    readonly __typename?: 'LocaleTypographyBlock';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly marketingBasicTileImage: {
                    readonly __typename?: 'LocaleImages';
                    readonly locale: {
                      readonly __typename?: 'Images';
                      readonly imageDescription: string | null;
                      readonly app: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly marketingBasicTileTitle: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly link: {
                    readonly __typename?: 'LocaleUrl';
                    readonly locale: string | null;
                  } | null;
                  readonly linkLabel: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'MarketingTileList';
                  readonly _type: string | null;
                  readonly tiles: ReadonlyArray<{
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'MarketingTilePair';
                  readonly left: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly right: {
                    readonly __typename?: 'MarketingTile';
                    readonly _id: string;
                    readonly textAlignment: string | null;
                    readonly buttonAlignment: string | null;
                    readonly textColor: string | null;
                    readonly lockedAspectRatio: boolean | null;
                    readonly backgroundImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly buttonAction: {
                      readonly __typename?: 'LinkAction';
                      readonly route: string | null;
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly textBlock: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsText: {
                      readonly __typename?: 'LocaleBlockContent';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly termsButton: {
                      readonly __typename?: 'LinkAction';
                      readonly actionText: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly actionUrl: {
                        readonly __typename?: 'LocaleUrl';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly ruleSet: {
                      readonly __typename?: 'MarketingTileRuleSet';
                      readonly BKPayAudience: string | null;
                      readonly bkSpecificTargeting: boolean | null;
                      readonly dayOfWeek: {
                        readonly __typename?: 'DayOfWeekBand';
                        readonly monday: boolean | null;
                        readonly tuesday: boolean | null;
                        readonly wednesday: boolean | null;
                        readonly thursday: boolean | null;
                        readonly friday: boolean | null;
                        readonly saturday: boolean | null;
                        readonly sunday: boolean | null;
                      } | null;
                      readonly betweenTimes: {
                        readonly __typename?: 'BetweenTimes';
                        readonly startTime: string | null;
                        readonly endTime: string | null;
                      } | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'MarketingTileQuad';
                  readonly topLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly topRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomLeftTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly bottomRightTile: {
                    readonly __typename?: 'MarketingTileBasic';
                    readonly _id: string;
                    readonly marketingBasicTileDescription: {
                      readonly __typename?: 'LocaleTypographyBlock';
                      readonly localeRaw: any | null;
                    } | null;
                    readonly marketingBasicTileImage: {
                      readonly __typename?: 'LocaleImages';
                      readonly locale: {
                        readonly __typename?: 'Images';
                        readonly imageDescription: string | null;
                        readonly app: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly marketingBasicTileTitle: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly link: {
                      readonly __typename?: 'LocaleUrl';
                      readonly locale: string | null;
                    } | null;
                    readonly linkLabel: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                }
              | null
            > | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyQRAndShortCodeWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly shortCodeAlwaysDisplayGenerateButton: boolean | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly shortCodeText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly qrAndShortCodeHowToEarnModalContent: {
            readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
            readonly _key: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly details: {
              readonly __typename?: 'LocaleBlockContent';
              readonly locale: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly buttonCtaLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
          readonly qrAndShortCodeErrorModalContent: {
            readonly __typename?: 'LoyaltyQRAndShortCodeModalContent';
            readonly _key: string | null;
            readonly title: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly details: {
              readonly __typename?: 'LocaleBlockContent';
              readonly locale: any | null;
            } | null;
            readonly image: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
            readonly buttonCtaLabel: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyQRCodeWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly subtitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly expirationMessage: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyShortCodeWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly defaultCode: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly alternativeTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly subtitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyTextWidget';
          readonly _key: string | null;
          readonly componentKey: string | null;
          readonly text: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type IResturantGroupByGroupIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type IResturantGroupByGroupIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly allRestaurantGroup: ReadonlyArray<{
    readonly __typename?: 'RestaurantGroup';
    readonly _id: string;
    readonly name: string | null;
    readonly restaurants: ReadonlyArray<{
      readonly __typename?: 'Restaurant';
      readonly _id: string;
      readonly number: string | null;
    } | null> | null;
  }>;
};

export type IResturantGroupByGeolocationQueryVariables = Exact<{
  geolocation: Scalars['Boolean']['input'];
}>;

export type IResturantGroupByGeolocationQuery = {
  readonly __typename?: 'RootQuery';
  readonly allRestaurantGroup: ReadonlyArray<{
    readonly __typename?: 'RestaurantGroup';
    readonly _id: string;
    readonly name: string | null;
    readonly geolocation: boolean | null;
    readonly restaurants: ReadonlyArray<{
      readonly __typename?: 'Restaurant';
      readonly _id: string;
      readonly number: string | null;
    } | null> | null;
  }>;
};

export type IGetRestaurantsQueryVariables = Exact<{
  filter?: InputMaybe<IRestaurantFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type IGetRestaurantsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allRestaurant: ReadonlyArray<{
    readonly __typename?: 'Restaurant';
    readonly _id: string;
    readonly environment: string | null;
    readonly chaseMerchantId: string | null;
    readonly drinkStationType: string | null;
    readonly driveThruLaneType: string | null;
    readonly email: ReadonlyArray<string | null> | null;
    readonly franchiseGroupId: number | null;
    readonly franchiseGroupName: string | null;
    readonly frontCounterClosed: boolean | null;
    readonly hasBreakfast: boolean | null;
    readonly hasBurgersForBreakfast: boolean | null;
    readonly hasCurbside: boolean | null;
    readonly hasDineIn: boolean | null;
    readonly hasCatering: boolean | null;
    readonly hideClickAndCollectOrdering: boolean | null;
    readonly hasDelivery: boolean | null;
    readonly hasDriveThru: boolean | null;
    readonly hasTableService: boolean | null;
    readonly hasMobileOrdering: boolean | null;
    readonly hasParking: boolean | null;
    readonly hasPlayground: boolean | null;
    readonly hasTakeOut: boolean | null;
    readonly hasWifi: boolean | null;
    readonly hasLoyalty: boolean | null;
    readonly isDarkKitchen: boolean | null;
    readonly isHalal: boolean | null;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly mobileOrderingStatus: string | null;
    readonly name: string | null;
    readonly number: string | null;
    readonly parkingType: string | null;
    readonly phoneNumber: string | null;
    readonly playgroundType: string | null;
    readonly posRestaurantId: string | null;
    readonly showStoreLocatorOffersButton: boolean | null;
    readonly status: string | null;
    readonly timezone: string | null;
    readonly vatNumber: string | null;
    readonly deliveryHours: {
      readonly __typename?: 'HoursOfOperation';
      readonly _type: string | null;
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
    } | null;
    readonly diningRoomHours: {
      readonly __typename?: 'HoursOfOperation';
      readonly _type: string | null;
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
    } | null;
    readonly curbsideHours: {
      readonly __typename?: 'HoursOfOperation';
      readonly _type: string | null;
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
    } | null;
    readonly driveThruHours: {
      readonly __typename?: 'HoursOfOperation';
      readonly _type: string | null;
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly close: string | null;
        readonly open: string | null;
      } | null;
    } | null;
    readonly pos: {
      readonly __typename?: 'Pos';
      readonly _type: string | null;
      readonly vendor: string | null;
    } | null;
    readonly physicalAddress: {
      readonly __typename?: 'Address';
      readonly _type: string | null;
      readonly address1: string | null;
      readonly address2: string | null;
      readonly city: string | null;
      readonly country: string | null;
      readonly postalCode: string | null;
      readonly stateProvince: string | null;
    } | null;
    readonly restaurantPosData: {
      readonly __typename?: 'RestaurantPosData';
      readonly _id: string;
    } | null;
    readonly restaurantImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly width: number | null;
        readonly height: number | null;
        readonly x: number | null;
        readonly y: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly label: string | null;
        readonly title: string | null;
        readonly url: string | null;
        readonly source: {
          readonly __typename?: 'SanityAssetSourceData';
          readonly id: string | null;
          readonly url: string | null;
        } | null;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly amenities: ReadonlyArray<{
      readonly __typename?: 'RestaurantAmenity';
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly icon: {
        readonly __typename?: 'Image';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly label: string | null;
          readonly title: string | null;
          readonly url: string | null;
          readonly source: {
            readonly __typename?: 'SanityAssetSourceData';
            readonly id: string | null;
            readonly url: string | null;
          } | null;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly customerFacingAddress: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  }>;
};

export type IRestaurantFragment = {
  readonly __typename?: 'Restaurant';
  readonly _id: string;
  readonly environment: string | null;
  readonly chaseMerchantId: string | null;
  readonly drinkStationType: string | null;
  readonly driveThruLaneType: string | null;
  readonly email: ReadonlyArray<string | null> | null;
  readonly franchiseGroupId: number | null;
  readonly franchiseGroupName: string | null;
  readonly frontCounterClosed: boolean | null;
  readonly hasBreakfast: boolean | null;
  readonly hasBurgersForBreakfast: boolean | null;
  readonly hasCurbside: boolean | null;
  readonly hasDineIn: boolean | null;
  readonly hasCatering: boolean | null;
  readonly hideClickAndCollectOrdering: boolean | null;
  readonly hasDelivery: boolean | null;
  readonly hasDriveThru: boolean | null;
  readonly hasTableService: boolean | null;
  readonly hasMobileOrdering: boolean | null;
  readonly hasParking: boolean | null;
  readonly hasPlayground: boolean | null;
  readonly hasTakeOut: boolean | null;
  readonly hasWifi: boolean | null;
  readonly hasLoyalty: boolean | null;
  readonly isDarkKitchen: boolean | null;
  readonly isHalal: boolean | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly mobileOrderingStatus: string | null;
  readonly name: string | null;
  readonly number: string | null;
  readonly parkingType: string | null;
  readonly phoneNumber: string | null;
  readonly playgroundType: string | null;
  readonly posRestaurantId: string | null;
  readonly showStoreLocatorOffersButton: boolean | null;
  readonly status: string | null;
  readonly timezone: string | null;
  readonly vatNumber: string | null;
  readonly deliveryHours: {
    readonly __typename?: 'HoursOfOperation';
    readonly _type: string | null;
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
  } | null;
  readonly diningRoomHours: {
    readonly __typename?: 'HoursOfOperation';
    readonly _type: string | null;
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
  } | null;
  readonly curbsideHours: {
    readonly __typename?: 'HoursOfOperation';
    readonly _type: string | null;
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
  } | null;
  readonly driveThruHours: {
    readonly __typename?: 'HoursOfOperation';
    readonly _type: string | null;
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly close: string | null;
      readonly open: string | null;
    } | null;
  } | null;
  readonly pos: {
    readonly __typename?: 'Pos';
    readonly _type: string | null;
    readonly vendor: string | null;
  } | null;
  readonly physicalAddress: {
    readonly __typename?: 'Address';
    readonly _type: string | null;
    readonly address1: string | null;
    readonly address2: string | null;
    readonly city: string | null;
    readonly country: string | null;
    readonly postalCode: string | null;
    readonly stateProvince: string | null;
  } | null;
  readonly restaurantPosData: {
    readonly __typename?: 'RestaurantPosData';
    readonly _id: string;
  } | null;
  readonly restaurantImage: {
    readonly __typename?: 'Image';
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly width: number | null;
      readonly height: number | null;
      readonly x: number | null;
      readonly y: number | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string;
      readonly label: string | null;
      readonly title: string | null;
      readonly url: string | null;
      readonly source: {
        readonly __typename?: 'SanityAssetSourceData';
        readonly id: string | null;
        readonly url: string | null;
      } | null;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetadata';
        readonly lqip: string | null;
      } | null;
    } | null;
  } | null;
  readonly amenities: ReadonlyArray<{
    readonly __typename?: 'RestaurantAmenity';
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly icon: {
      readonly __typename?: 'Image';
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly label: string | null;
        readonly title: string | null;
        readonly url: string | null;
        readonly source: {
          readonly __typename?: 'SanityAssetSourceData';
          readonly id: string | null;
          readonly url: string | null;
        } | null;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  readonly customerFacingAddress: {
    readonly __typename?: 'LocaleString';
    readonly locale: string | null;
  } | null;
};

export type IHoursFragment = {
  readonly __typename?: 'HoursOfOperation';
  readonly _type: string | null;
  readonly friClose: string | null;
  readonly friOpen: string | null;
  readonly monClose: string | null;
  readonly monOpen: string | null;
  readonly satClose: string | null;
  readonly satOpen: string | null;
  readonly sunClose: string | null;
  readonly sunOpen: string | null;
  readonly thrClose: string | null;
  readonly thrOpen: string | null;
  readonly tueClose: string | null;
  readonly tueOpen: string | null;
  readonly wedClose: string | null;
  readonly wedOpen: string | null;
  readonly sunAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly monAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly tueAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly wedAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly thrAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly friAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly satAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
};

export type IImageAssetFragment = {
  readonly __typename?: 'SanityImageAsset';
  readonly _id: string;
  readonly label: string | null;
  readonly title: string | null;
  readonly url: string | null;
  readonly source: {
    readonly __typename?: 'SanityAssetSourceData';
    readonly id: string | null;
    readonly url: string | null;
  } | null;
  readonly metadata: {
    readonly __typename?: 'SanityImageMetadata';
    readonly lqip: string | null;
  } | null;
};

export type IGetRestaurantLoyaltyEnabledQueryVariables = Exact<{
  storeId: Scalars['ID']['input'];
}>;

export type IGetRestaurantLoyaltyEnabledQuery = {
  readonly __typename?: 'RootQuery';
  readonly Restaurant: {
    readonly __typename?: 'Restaurant';
    readonly hasLoyalty: boolean | null;
  } | null;
};

export type IGetRestaurantFzDetailsQueryVariables = Exact<{
  storeId: Scalars['ID']['input'];
}>;

export type IGetRestaurantFzDetailsQuery = {
  readonly __typename?: 'RootQuery';
  readonly Restaurant: {
    readonly __typename?: 'Restaurant';
    readonly fzAddress: string | null;
    readonly fzZipCode: string | null;
    readonly fzCity: string | null;
    readonly fzeeGroup: string | null;
    readonly fzAuthorizedRepresentative: string | null;
    readonly fzEmail: string | null;
    readonly fzTelephone: string | null;
    readonly fzRegistryCourt: string | null;
    readonly fzHrb: string | null;
    readonly fzUstNr: string | null;
    readonly physicalAddress: {
      readonly __typename?: 'Address';
      readonly address1: string | null;
      readonly address2: string | null;
      readonly city: string | null;
      readonly country: string | null;
      readonly postalCode: string | null;
      readonly stateProvince: string | null;
    } | null;
    readonly customerFacingAddress: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  } | null;
};

export type IGetRestaurantVrPaymentMerchantIdQueryVariables = Exact<{
  storeId: Scalars['ID']['input'];
}>;

export type IGetRestaurantVrPaymentMerchantIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly Restaurant: {
    readonly __typename?: 'Restaurant';
    readonly vrPaymentMerchantId: string | null;
  } | null;
};

export type IPaymentMethodsFragment = {
  readonly __typename?: 'PaymentMethodConfiguration';
  readonly name: string | null;
  readonly paymentMethodBrand: string | null;
  readonly state: boolean | null;
  readonly isOnlinePayment: boolean | null;
};

export type IGetPaymentMethodsControlQueryVariables = Exact<{
  storeId: Scalars['ID']['input'];
}>;

export type IGetPaymentMethodsControlQuery = {
  readonly __typename?: 'RootQuery';
  readonly Restaurant: {
    readonly __typename?: 'Restaurant';
    readonly paymentMethods: ReadonlyArray<{
      readonly __typename?: 'PaymentMethodConfiguration';
      readonly name: string | null;
      readonly paymentMethodBrand: string | null;
      readonly state: boolean | null;
      readonly isOnlinePayment: boolean | null;
    } | null> | null;
  } | null;
};

export type ILoyaltyRewardsByEngineIdQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ILoyaltyRewardsByEngineIdQuery = {
  readonly __typename?: 'RootQuery';
  readonly allReward: ReadonlyArray<{
    readonly __typename?: 'Reward';
    readonly _id: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly incentiveNotInMenu: boolean | null;
    readonly mobileOrderOnly: boolean | null;
    readonly redemptionMethod: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly ruleSet: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'Limit' }
      | { readonly __typename?: 'LoyaltyBetweenDates' }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'LoyaltyPoints' }
      | { readonly __typename?: 'PaymentMethod' }
      | { readonly __typename?: 'RequiresAuthentication' }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | {
          readonly __typename?: 'RewardPrice';
          readonly price: number | null;
          readonly ruleSetType: string | null;
        }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | { readonly __typename?: 'UserAttributes' }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly image: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  }>;
};

export type ILoyaltyRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type ILoyaltyRewardsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allReward: ReadonlyArray<{
    readonly __typename?: 'Reward';
    readonly _id: string;
    readonly testOnly: boolean | null;
    readonly loyaltyEngineId: string | null;
    readonly incentiveNotInMenu: boolean | null;
    readonly mobileOrderOnly: boolean | null;
    readonly redemptionMethod: string | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly description: {
      readonly __typename?: 'LocaleBlockContent';
      readonly locale: any | null;
    } | null;
    readonly ruleSet: ReadonlyArray<
      | { readonly __typename?: 'BirthdayMultiplier' }
      | { readonly __typename?: 'CartLimit' }
      | { readonly __typename?: 'CartRequirement' }
      | { readonly __typename?: 'CartRequirementExclusion' }
      | { readonly __typename?: 'CoolDown' }
      | { readonly __typename?: 'DayOfWeekBand' }
      | { readonly __typename?: 'EmailVerifiedRequired' }
      | { readonly __typename?: 'FirstOrderOnly' }
      | { readonly __typename?: 'Limit' }
      | { readonly __typename?: 'LoyaltyBetweenDates' }
      | { readonly __typename?: 'LoyaltyBetweenTimes' }
      | { readonly __typename?: 'LoyaltyPoints' }
      | { readonly __typename?: 'PaymentMethod' }
      | { readonly __typename?: 'RequiresAuthentication' }
      | { readonly __typename?: 'RestaurantGroupRestrictions' }
      | {
          readonly __typename?: 'RewardPrice';
          readonly price: number | null;
          readonly ruleSetType: string | null;
        }
      | { readonly __typename?: 'ServiceModeRestrictions' }
      | { readonly __typename?: 'SubtotalSpend' }
      | { readonly __typename?: 'TierList' }
      | { readonly __typename?: 'TotalSpend' }
      | { readonly __typename?: 'TransactionCount' }
      | { readonly __typename?: 'UserAttributes' }
      | { readonly __typename?: 'UserCreated' }
      | null
    > | null;
    readonly moreInfo: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly howToRedeem: {
      readonly __typename?: 'LocaleBlockContent';
      readonly enRaw: any | null;
    } | null;
    readonly backgroundImage: {
      readonly __typename?: 'Image';
      readonly hotspot: {
        readonly __typename?: 'SanityImageHotspot';
        readonly x: number | null;
        readonly y: number | null;
        readonly height: number | null;
        readonly width: number | null;
      } | null;
      readonly crop: {
        readonly __typename?: 'SanityImageCrop';
        readonly top: number | null;
        readonly bottom: number | null;
        readonly left: number | null;
        readonly right: number | null;
      } | null;
      readonly asset: {
        readonly __typename?: 'SanityImageAsset';
        readonly _id: string;
        readonly metadata: {
          readonly __typename?: 'SanityImageMetadata';
          readonly lqip: string | null;
        } | null;
      } | null;
    } | null;
    readonly image: {
      readonly __typename?: 'LocaleImage';
      readonly locale: {
        readonly __typename?: 'Image';
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly x: number | null;
          readonly y: number | null;
          readonly height: number | null;
          readonly width: number | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetadata';
            readonly lqip: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly imageDescription: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly incentives: ReadonlyArray<
      | {
          readonly __typename: 'Combo';
          readonly _id: string;
          readonly _type: string;
          readonly mainItem: {
            readonly __typename?: 'Item';
            readonly _id: string;
            readonly _type: string;
            readonly operationalItem: {
              readonly __typename?: 'OpItem';
              readonly daypart: ReadonlyArray<string | null> | null;
            } | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
            readonly pluConfigs: {
              readonly __typename?: 'PluConfigs';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly partner: ReadonlyArray<{
                readonly __typename?: 'PluConfig';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly serviceMode: string | null;
                readonly posIntegration: {
                  readonly __typename?: 'PosIntegration';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: string | null;
                } | null;
                readonly vendorConfig: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'Item';
          readonly _id: string;
          readonly _type: string;
          readonly operationalItem: {
            readonly __typename?: 'OpItem';
            readonly daypart: ReadonlyArray<string | null> | null;
          } | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
          readonly pluConfigs: {
            readonly __typename?: 'PluConfigs';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly partner: ReadonlyArray<{
              readonly __typename?: 'PluConfig';
              readonly _key: string | null;
              readonly _type: string | null;
              readonly serviceMode: string | null;
              readonly posIntegration: {
                readonly __typename?: 'PosIntegration';
                readonly _id: string;
                readonly _type: string;
                readonly name: string | null;
              } | null;
              readonly vendorConfig: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string | null;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly discountPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'Plu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        }
      | {
          readonly __typename: 'OfferDiscount';
          readonly _id: string;
          readonly _type: string;
          readonly discountValue: number | null;
          readonly discountType: string | null;
          readonly discountProduct:
            | {
                readonly __typename?: 'Combo';
                readonly _id: string;
                readonly _type: string;
                readonly mainItem: {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              }
            | null;
        }
      | {
          readonly __typename: 'Picker';
          readonly _id: string;
          readonly _type: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'PickerOption';
            readonly option:
              | {
                  readonly __typename: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | {
                  readonly __typename: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly operationalItem: {
                    readonly __typename?: 'OpItem';
                    readonly daypart: ReadonlyArray<string | null> | null;
                  } | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pluConfigs: {
                    readonly __typename?: 'PluConfigs';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly partner: ReadonlyArray<{
                      readonly __typename?: 'PluConfig';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly serviceMode: string | null;
                      readonly posIntegration: {
                        readonly __typename?: 'PosIntegration';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: string | null;
                      } | null;
                      readonly vendorConfig: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  } | null;
                }
              | null;
          } | null> | null;
        }
      | null
    > | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string | null;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly discountPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'Plu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
    readonly pluConfigs: {
      readonly __typename?: 'PluConfigs';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly partner: ReadonlyArray<{
        readonly __typename?: 'PluConfig';
        readonly _key: string | null;
        readonly _type: string | null;
        readonly serviceMode: string | null;
        readonly posIntegration: {
          readonly __typename?: 'PosIntegration';
          readonly _id: string;
          readonly _type: string;
          readonly name: string | null;
        } | null;
        readonly vendorConfig: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string | null;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly discountPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'Plu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  }>;
};

export type ILoyaltyFeaturedRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type ILoyaltyFeaturedRewardsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allRewardsSection: ReadonlyArray<{
    readonly __typename?: 'RewardsSection';
    readonly _id: string;
    readonly rewardsList: ReadonlyArray<{
      readonly __typename?: 'FeaturedReward';
      readonly reward: {
        readonly __typename?: 'Reward';
        readonly _id: string;
        readonly testOnly: boolean | null;
        readonly loyaltyEngineId: string | null;
        readonly incentiveNotInMenu: boolean | null;
        readonly mobileOrderOnly: boolean | null;
        readonly redemptionMethod: string | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly description: {
          readonly __typename?: 'LocaleBlockContent';
          readonly locale: any | null;
        } | null;
        readonly ruleSet: ReadonlyArray<
          | { readonly __typename?: 'BirthdayMultiplier' }
          | { readonly __typename?: 'CartLimit' }
          | { readonly __typename?: 'CartRequirement' }
          | { readonly __typename?: 'CartRequirementExclusion' }
          | { readonly __typename?: 'CoolDown' }
          | { readonly __typename?: 'DayOfWeekBand' }
          | { readonly __typename?: 'EmailVerifiedRequired' }
          | { readonly __typename?: 'FirstOrderOnly' }
          | { readonly __typename?: 'Limit' }
          | { readonly __typename?: 'LoyaltyBetweenDates' }
          | { readonly __typename?: 'LoyaltyBetweenTimes' }
          | { readonly __typename?: 'LoyaltyPoints' }
          | { readonly __typename?: 'PaymentMethod' }
          | { readonly __typename?: 'RequiresAuthentication' }
          | { readonly __typename?: 'RestaurantGroupRestrictions' }
          | {
              readonly __typename?: 'RewardPrice';
              readonly price: number | null;
              readonly ruleSetType: string | null;
            }
          | { readonly __typename?: 'ServiceModeRestrictions' }
          | { readonly __typename?: 'SubtotalSpend' }
          | { readonly __typename?: 'TierList' }
          | { readonly __typename?: 'TotalSpend' }
          | { readonly __typename?: 'TransactionCount' }
          | { readonly __typename?: 'UserAttributes' }
          | { readonly __typename?: 'UserCreated' }
          | null
        > | null;
        readonly moreInfo: {
          readonly __typename?: 'LocaleBlockContent';
          readonly enRaw: any | null;
        } | null;
        readonly howToRedeem: {
          readonly __typename?: 'LocaleBlockContent';
          readonly enRaw: any | null;
        } | null;
        readonly backgroundImage: {
          readonly __typename?: 'Image';
          readonly hotspot: {
            readonly __typename?: 'SanityImageHotspot';
            readonly x: number | null;
            readonly y: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly crop: {
            readonly __typename?: 'SanityImageCrop';
            readonly top: number | null;
            readonly bottom: number | null;
            readonly left: number | null;
            readonly right: number | null;
          } | null;
          readonly asset: {
            readonly __typename?: 'SanityImageAsset';
            readonly _id: string;
            readonly metadata: {
              readonly __typename?: 'SanityImageMetadata';
              readonly lqip: string | null;
            } | null;
          } | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
        readonly imageDescription: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly incentives: ReadonlyArray<
          | {
              readonly __typename: 'Combo';
              readonly _id: string;
              readonly _type: string;
              readonly mainItem: {
                readonly __typename?: 'Item';
                readonly _id: string;
                readonly _type: string;
                readonly operationalItem: {
                  readonly __typename?: 'OpItem';
                  readonly daypart: ReadonlyArray<string | null> | null;
                } | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
                readonly pluConfigs: {
                  readonly __typename?: 'PluConfigs';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly partner: ReadonlyArray<{
                    readonly __typename?: 'PluConfig';
                    readonly _key: string | null;
                    readonly _type: string | null;
                    readonly serviceMode: string | null;
                    readonly posIntegration: {
                      readonly __typename?: 'PosIntegration';
                      readonly _id: string;
                      readonly _type: string;
                      readonly name: string | null;
                    } | null;
                    readonly vendorConfig: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string | null;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly discountPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'Plu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename: 'Item';
              readonly _id: string;
              readonly _type: string;
              readonly operationalItem: {
                readonly __typename?: 'OpItem';
                readonly daypart: ReadonlyArray<string | null> | null;
              } | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string | null;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly discountPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'Plu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
              readonly pluConfigs: {
                readonly __typename?: 'PluConfigs';
                readonly _key: string | null;
                readonly _type: string | null;
                readonly partner: ReadonlyArray<{
                  readonly __typename?: 'PluConfig';
                  readonly _key: string | null;
                  readonly _type: string | null;
                  readonly serviceMode: string | null;
                  readonly posIntegration: {
                    readonly __typename?: 'PosIntegration';
                    readonly _id: string;
                    readonly _type: string;
                    readonly name: string | null;
                  } | null;
                  readonly vendorConfig: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string | null;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly discountPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'Plu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null> | null;
              } | null;
            }
          | {
              readonly __typename: 'OfferDiscount';
              readonly _id: string;
              readonly _type: string;
              readonly discountValue: number | null;
              readonly discountType: string | null;
              readonly discountProduct:
                | {
                    readonly __typename?: 'Combo';
                    readonly _id: string;
                    readonly _type: string;
                    readonly mainItem: {
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly operationalItem: {
                      readonly __typename?: 'OpItem';
                      readonly daypart: ReadonlyArray<string | null> | null;
                    } | null;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string | null;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly discountPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'Plu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly pluConfigs: {
                      readonly __typename?: 'PluConfigs';
                      readonly _key: string | null;
                      readonly _type: string | null;
                      readonly partner: ReadonlyArray<{
                        readonly __typename?: 'PluConfig';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly serviceMode: string | null;
                        readonly posIntegration: {
                          readonly __typename?: 'PosIntegration';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: string | null;
                        } | null;
                        readonly vendorConfig: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  }
                | null;
            }
          | {
              readonly __typename: 'Picker';
              readonly _id: string;
              readonly _type: string;
              readonly options: ReadonlyArray<{
                readonly __typename?: 'PickerOption';
                readonly option:
                  | {
                      readonly __typename: 'Combo';
                      readonly _id: string;
                      readonly _type: string;
                      readonly mainItem: {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly operationalItem: {
                          readonly __typename?: 'OpItem';
                          readonly daypart: ReadonlyArray<string | null> | null;
                        } | null;
                        readonly vendorConfigs: {
                          readonly __typename?: 'VendorConfigs';
                          readonly ncr: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly ncrDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partner: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly partnerDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumber: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly productNumberDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicom: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly sicomDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdi: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly qdiDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rpos: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly rposDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly simplyDeliveryDelivery: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                          readonly toshibaLoyalty: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pluConfigs: {
                          readonly __typename?: 'PluConfigs';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly partner: ReadonlyArray<{
                            readonly __typename?: 'PluConfig';
                            readonly _key: string | null;
                            readonly _type: string | null;
                            readonly serviceMode: string | null;
                            readonly posIntegration: {
                              readonly __typename?: 'PosIntegration';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: string | null;
                            } | null;
                            readonly vendorConfig: {
                              readonly __typename?: 'VendorConfig';
                              readonly pluType: string | null;
                              readonly parentSanityId: string | null;
                              readonly pullUpLevels: number | null;
                              readonly constantPlu: string | null;
                              readonly discountPlu: string | null;
                              readonly quantityBasedPlu: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly multiConstantPlus: ReadonlyArray<{
                                readonly __typename?: 'Plu';
                                readonly quantity: number | null;
                                readonly plu: string | null;
                                readonly qualifier: string | null;
                              } | null> | null;
                              readonly parentChildPlu: {
                                readonly __typename?: 'ParentChildPlu';
                                readonly plu: string | null;
                                readonly childPlu: string | null;
                              } | null;
                              readonly sizeBasedPlu: {
                                readonly __typename?: 'SizeBasedPlu';
                                readonly comboPlu: string | null;
                                readonly comboSize: string | null;
                              } | null;
                            } | null;
                          } | null> | null;
                        } | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      readonly __typename: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly operationalItem: {
                        readonly __typename?: 'OpItem';
                        readonly daypart: ReadonlyArray<string | null> | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | null;
              } | null> | null;
            }
          | null
        > | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string | null;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly discountPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'Plu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
        readonly pluConfigs: {
          readonly __typename?: 'PluConfigs';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly partner: ReadonlyArray<{
            readonly __typename?: 'PluConfig';
            readonly _key: string | null;
            readonly _type: string | null;
            readonly serviceMode: string | null;
            readonly posIntegration: {
              readonly __typename?: 'PosIntegration';
              readonly _id: string;
              readonly _type: string;
              readonly name: string | null;
            } | null;
            readonly vendorConfig: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string | null;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly discountPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'Plu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
      readonly category: {
        readonly __typename?: 'RewardCategory';
        readonly label: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly image: {
          readonly __typename?: 'LocaleImage';
          readonly locale: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  }>;
};

export type ISingleAppDataQueryVariables = Exact<{ [key: string]: never }>;

export type ISingleAppDataQuery = {
  readonly __typename?: 'RootQuery';
  readonly allAppData: ReadonlyArray<{ readonly __typename?: 'AppData'; readonly _id: string }>;
};

export type IRequiredUserAcceptanceQueryVariables = Exact<{ [key: string]: never }>;

export type IRequiredUserAcceptanceQuery = {
  readonly __typename?: 'RootQuery';
  readonly allStaticPage: ReadonlyArray<{
    readonly __typename?: 'StaticPage';
    readonly _id: string;
    readonly _updatedAt: any;
    readonly title: string | null;
    readonly localeTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
  }>;
};

export type IAcceptanceAgremeentsByIdsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type IAcceptanceAgremeentsByIdsQuery = {
  readonly __typename?: 'RootQuery';
  readonly allStaticPage: ReadonlyArray<{
    readonly __typename?: 'StaticPage';
    readonly _id: string;
    readonly widgets: ReadonlyArray<
      | { readonly __typename?: 'AccordionWidget' }
      | { readonly __typename?: 'AnchorLinksWidget' }
      | { readonly __typename?: 'AnchorWidget' }
      | { readonly __typename?: 'CallToActionWidget' }
      | { readonly __typename?: 'DoubleImageWidget' }
      | { readonly __typename?: 'DownloadFileWidget' }
      | {
          readonly __typename?: 'HeaderWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly headingContent: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'ImageWidget' }
      | { readonly __typename?: 'InfoCellsWidget' }
      | {
          readonly __typename?: 'LocaleBlockTextWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly localeBlockTextContent: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
        }
      | { readonly __typename?: 'LoyaltyBannerWidget' }
      | { readonly __typename?: 'LoyaltyTabSelectorWidget' }
      | { readonly __typename?: 'MultiWidget' }
      | { readonly __typename?: 'NutritionExplorerWidget' }
      | { readonly __typename?: 'NutritionInfoWidget' }
      | { readonly __typename?: 'PreviewWidget' }
      | { readonly __typename?: 'QuoteWidget' }
      | { readonly __typename?: 'RewardsCarouselWidget' }
      | { readonly __typename?: 'VideoWidget' }
      | { readonly __typename?: 'WebViewWidget' }
      | { readonly __typename?: 'YoutubeWidget' }
      | null
    > | null;
    readonly pageHtml: { readonly __typename?: 'Html'; readonly code: string | null } | null;
  }>;
};

export type IGetStaticPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetStaticPageQuery = {
  readonly __typename?: 'RootQuery';
  readonly StaticPage: {
    readonly __typename?: 'StaticPage';
    readonly _id: string;
    readonly title: string | null;
    readonly path: {
      readonly __typename?: 'Slug';
      readonly _key: string | null;
      readonly _type: string | null;
      readonly current: string | null;
    } | null;
    readonly localeTitle: {
      readonly __typename?: 'LocaleString';
      readonly locale: string | null;
    } | null;
    readonly metaTags: {
      readonly __typename?: 'MetaTags';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly ogTags: {
      readonly __typename?: 'OgTags';
      readonly title: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly description: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly url: { readonly __typename?: 'LocaleUrl'; readonly locale: string | null } | null;
      readonly imageUrl: {
        readonly __typename?: 'LocaleUrl';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly widgets: ReadonlyArray<
      | {
          readonly __typename?: 'AccordionWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly accordionTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly accordionContent: ReadonlyArray<{
            readonly __typename?: 'AccordionEntry';
            readonly collapsedContent: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly expandedContent: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'AnchorLinksWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'AnchorWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly anchorName: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'CallToActionWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly callToActionContent: ReadonlyArray<{
            readonly __typename?: 'CallToAction';
            readonly buttonLink: string | null;
            readonly heading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly subheading: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly body: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly buttonText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly link: {
              readonly __typename?: 'LocaleUrl';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'DoubleImageWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly image1: {
            readonly __typename?: 'ImageWidget';
            readonly attributionLink: string | null;
            readonly caption: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly image2: {
            readonly __typename?: 'ImageWidget';
            readonly attributionLink: string | null;
            readonly caption: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly image: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'DownloadFileWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly url: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'HeaderWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly headingContent: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly taglineContent: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly headerImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'ImageWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly attributionLink: string | null;
          readonly caption: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly image: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'InfoCellsWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly title: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly icon: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly infoCells: ReadonlyArray<{
            readonly __typename?: 'LoyaltyTiersUnauthenticatedCell';
            readonly loyaltyTiersSignupCellTitle: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly loyaltyTiersSignupCellDescription: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly loyaltyTiersSignupCellImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'LocaleBlockTextWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly blockTextInversion: boolean | null;
          readonly localeBlockTextContent: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyBannerWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly enabled: boolean | null;
          readonly unauthenticatedDesktopBackgroundImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly unauthenticatedMobileBackgroundImage: {
            readonly __typename?: 'LocaleImage';
            readonly locale: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          readonly __typename?: 'LoyaltyTabSelectorWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly tabs: ReadonlyArray<{
            readonly __typename?: 'Link';
            readonly _type: string | null;
            readonly link: string | null;
            readonly title: string | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'MultiWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly widget: string | null;
        }
      | {
          readonly __typename?: 'NutritionExplorerWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly menu: {
            readonly __typename?: 'Menu';
            readonly _id: string;
            readonly _type: string;
            readonly options: ReadonlyArray<
              | {
                  readonly __typename?: 'Combo';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly mainItem: {
                    readonly __typename?: 'Item';
                    readonly _id: string;
                    readonly _type: string;
                    readonly productSize: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly image: {
                      readonly __typename?: 'Image';
                      readonly hotspot: {
                        readonly __typename?: 'SanityImageHotspot';
                        readonly x: number | null;
                        readonly y: number | null;
                        readonly height: number | null;
                        readonly width: number | null;
                      } | null;
                      readonly crop: {
                        readonly __typename?: 'SanityImageCrop';
                        readonly top: number | null;
                        readonly bottom: number | null;
                        readonly left: number | null;
                        readonly right: number | null;
                      } | null;
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOption';
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOptionModifier';
                        readonly default: boolean | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                      } | null> | null;
                    } | null> | null;
                    readonly nutrition: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly additionalItemInformation: {
                      readonly __typename?: 'AdditionalItemInformation';
                      readonly ingredients: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly additives: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly producerDetails: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly nutritionWithModifiers: {
                      readonly __typename?: 'Nutrition';
                      readonly calories: number | null;
                      readonly caloriesPer100: number | null;
                      readonly carbohydrates: number | null;
                      readonly carbohydratesPer100: number | null;
                      readonly cholesterol: number | null;
                      readonly energyKJ: number | null;
                      readonly energyKJPer100: number | null;
                      readonly fat: number | null;
                      readonly fatPer100: number | null;
                      readonly fiber: number | null;
                      readonly proteins: number | null;
                      readonly proteinsPer100: number | null;
                      readonly salt: number | null;
                      readonly saltPer100: number | null;
                      readonly saturatedFat: number | null;
                      readonly saturatedFatPer100: number | null;
                      readonly sodium: number | null;
                      readonly sugar: number | null;
                      readonly sugarPer100: number | null;
                      readonly transFat: number | null;
                      readonly weight: number | null;
                    } | null;
                    readonly allergens: {
                      readonly __typename?: 'OpAllergen';
                      readonly milk: number | null;
                      readonly eggs: number | null;
                      readonly fish: number | null;
                      readonly peanuts: number | null;
                      readonly shellfish: number | null;
                      readonly treeNuts: number | null;
                      readonly soy: number | null;
                      readonly wheat: number | null;
                      readonly mustard: number | null;
                      readonly sesame: number | null;
                      readonly celery: number | null;
                      readonly lupin: number | null;
                      readonly gluten: number | null;
                      readonly sulphurDioxide: number | null;
                    } | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly default: boolean | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'Picker';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly pickerDefaults: ReadonlyArray<{
                    readonly __typename?: 'PickerItemMapping';
                    readonly pickerAspectValueIdentifier: string | null;
                    readonly pickerAspect: {
                      readonly __typename?: 'PickerAspect';
                      readonly _id: string;
                    } | null;
                  } | null> | null;
                  readonly pickerAspects: ReadonlyArray<{
                    readonly __typename?: 'PickerAspect';
                    readonly _id: string;
                    readonly _type: string;
                    readonly pickerAspectOptions: ReadonlyArray<{
                      readonly __typename?: 'PickerAspectValue';
                      readonly identifier: string | null;
                    } | null> | null;
                  } | null> | null;
                  readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                    readonly __typename?: 'PickerAspectItemOptions';
                    readonly pickerAspect: {
                      readonly __typename?: 'PickerAspect';
                      readonly _id: string;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ListItem';
                      readonly value: string | null;
                    } | null> | null;
                  } | null> | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'PickerOption';
                    readonly pickerItemMappings: ReadonlyArray<{
                      readonly __typename?: 'PickerItemMapping';
                      readonly pickerAspectValueIdentifier: string | null;
                      readonly pickerAspect: {
                        readonly __typename?: 'PickerAspect';
                        readonly _id: string;
                      } | null;
                    } | null> | null;
                    readonly option:
                      | {
                          readonly __typename?: 'Combo';
                          readonly _id: string;
                          readonly _type: string;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly mainItem: {
                            readonly __typename?: 'Item';
                            readonly _id: string;
                            readonly _type: string;
                            readonly productSize: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly image: {
                              readonly __typename?: 'Image';
                              readonly hotspot: {
                                readonly __typename?: 'SanityImageHotspot';
                                readonly x: number | null;
                                readonly y: number | null;
                                readonly height: number | null;
                                readonly width: number | null;
                              } | null;
                              readonly crop: {
                                readonly __typename?: 'SanityImageCrop';
                                readonly top: number | null;
                                readonly bottom: number | null;
                                readonly left: number | null;
                                readonly right: number | null;
                              } | null;
                              readonly asset: {
                                readonly __typename?: 'SanityImageAsset';
                                readonly _id: string;
                                readonly metadata: {
                                  readonly __typename?: 'SanityImageMetadata';
                                  readonly lqip: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOption';
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOptionModifier';
                                readonly default: boolean | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                              } | null> | null;
                            } | null> | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly additionalItemInformation: {
                              readonly __typename?: 'AdditionalItemInformation';
                              readonly ingredients: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly additives: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly producerDetails: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                            } | null;
                            readonly nutritionWithModifiers: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                            readonly allergens: {
                              readonly __typename?: 'OpAllergen';
                              readonly milk: number | null;
                              readonly eggs: number | null;
                              readonly fish: number | null;
                              readonly peanuts: number | null;
                              readonly shellfish: number | null;
                              readonly treeNuts: number | null;
                              readonly soy: number | null;
                              readonly wheat: number | null;
                              readonly mustard: number | null;
                              readonly sesame: number | null;
                              readonly celery: number | null;
                              readonly lupin: number | null;
                              readonly gluten: number | null;
                              readonly sulphurDioxide: number | null;
                            } | null;
                          } | null;
                        }
                      | {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly productSize: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOption';
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOptionModifier';
                              readonly default: boolean | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                            } | null> | null;
                          } | null> | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly additionalItemInformation: {
                            readonly __typename?: 'AdditionalItemInformation';
                            readonly ingredients: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly additives: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly producerDetails: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                          readonly nutritionWithModifiers: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly allergens: {
                            readonly __typename?: 'OpAllergen';
                            readonly milk: number | null;
                            readonly eggs: number | null;
                            readonly fish: number | null;
                            readonly peanuts: number | null;
                            readonly shellfish: number | null;
                            readonly treeNuts: number | null;
                            readonly soy: number | null;
                            readonly wheat: number | null;
                            readonly mustard: number | null;
                            readonly sesame: number | null;
                            readonly celery: number | null;
                            readonly lupin: number | null;
                            readonly gluten: number | null;
                            readonly sulphurDioxide: number | null;
                          } | null;
                        }
                      | null;
                  } | null> | null;
                }
              | {
                  readonly __typename?: 'Section';
                  readonly _id: string;
                  readonly _type: string;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly carouselImage: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly options: ReadonlyArray<
                    | {
                        readonly __typename?: 'Combo';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly mainItem: {
                          readonly __typename?: 'Item';
                          readonly _id: string;
                          readonly _type: string;
                          readonly productSize: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOption';
                            readonly options: ReadonlyArray<{
                              readonly __typename?: 'ItemOptionModifier';
                              readonly default: boolean | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                            } | null> | null;
                          } | null> | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly additionalItemInformation: {
                            readonly __typename?: 'AdditionalItemInformation';
                            readonly ingredients: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly additives: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly producerDetails: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                          readonly nutritionWithModifiers: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                          readonly allergens: {
                            readonly __typename?: 'OpAllergen';
                            readonly milk: number | null;
                            readonly eggs: number | null;
                            readonly fish: number | null;
                            readonly peanuts: number | null;
                            readonly shellfish: number | null;
                            readonly treeNuts: number | null;
                            readonly soy: number | null;
                            readonly wheat: number | null;
                            readonly mustard: number | null;
                            readonly sesame: number | null;
                            readonly celery: number | null;
                            readonly lupin: number | null;
                            readonly gluten: number | null;
                            readonly sulphurDioxide: number | null;
                          } | null;
                        } | null;
                      }
                    | {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly productSize: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOption';
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOptionModifier';
                            readonly default: boolean | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                          } | null> | null;
                        } | null> | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly additionalItemInformation: {
                          readonly __typename?: 'AdditionalItemInformation';
                          readonly ingredients: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly additives: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly producerDetails: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly nutritionWithModifiers: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly allergens: {
                          readonly __typename?: 'OpAllergen';
                          readonly milk: number | null;
                          readonly eggs: number | null;
                          readonly fish: number | null;
                          readonly peanuts: number | null;
                          readonly shellfish: number | null;
                          readonly treeNuts: number | null;
                          readonly soy: number | null;
                          readonly wheat: number | null;
                          readonly mustard: number | null;
                          readonly sesame: number | null;
                          readonly celery: number | null;
                          readonly lupin: number | null;
                          readonly gluten: number | null;
                          readonly sulphurDioxide: number | null;
                        } | null;
                      }
                    | { readonly __typename?: 'Offer' }
                    | {
                        readonly __typename?: 'Picker';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly pickerDefaults: ReadonlyArray<{
                          readonly __typename?: 'PickerItemMapping';
                          readonly pickerAspectValueIdentifier: string | null;
                          readonly pickerAspect: {
                            readonly __typename?: 'PickerAspect';
                            readonly _id: string;
                          } | null;
                        } | null> | null;
                        readonly pickerAspects: ReadonlyArray<{
                          readonly __typename?: 'PickerAspect';
                          readonly _id: string;
                          readonly _type: string;
                          readonly pickerAspectOptions: ReadonlyArray<{
                            readonly __typename?: 'PickerAspectValue';
                            readonly identifier: string | null;
                          } | null> | null;
                        } | null> | null;
                        readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                          readonly __typename?: 'PickerAspectItemOptions';
                          readonly pickerAspect: {
                            readonly __typename?: 'PickerAspect';
                            readonly _id: string;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ListItem';
                            readonly value: string | null;
                          } | null> | null;
                        } | null> | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'PickerOption';
                          readonly pickerItemMappings: ReadonlyArray<{
                            readonly __typename?: 'PickerItemMapping';
                            readonly pickerAspectValueIdentifier: string | null;
                            readonly pickerAspect: {
                              readonly __typename?: 'PickerAspect';
                              readonly _id: string;
                            } | null;
                          } | null> | null;
                          readonly option:
                            | {
                                readonly __typename?: 'Combo';
                                readonly _id: string;
                                readonly _type: string;
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly image: {
                                  readonly __typename?: 'Image';
                                  readonly hotspot: {
                                    readonly __typename?: 'SanityImageHotspot';
                                    readonly x: number | null;
                                    readonly y: number | null;
                                    readonly height: number | null;
                                    readonly width: number | null;
                                  } | null;
                                  readonly crop: {
                                    readonly __typename?: 'SanityImageCrop';
                                    readonly top: number | null;
                                    readonly bottom: number | null;
                                    readonly left: number | null;
                                    readonly right: number | null;
                                  } | null;
                                  readonly asset: {
                                    readonly __typename?: 'SanityImageAsset';
                                    readonly _id: string;
                                    readonly metadata: {
                                      readonly __typename?: 'SanityImageMetadata';
                                      readonly lqip: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly mainItem: {
                                  readonly __typename?: 'Item';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly productSize: string | null;
                                  readonly name: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly image: {
                                    readonly __typename?: 'Image';
                                    readonly hotspot: {
                                      readonly __typename?: 'SanityImageHotspot';
                                      readonly x: number | null;
                                      readonly y: number | null;
                                      readonly height: number | null;
                                      readonly width: number | null;
                                    } | null;
                                    readonly crop: {
                                      readonly __typename?: 'SanityImageCrop';
                                      readonly top: number | null;
                                      readonly bottom: number | null;
                                      readonly left: number | null;
                                      readonly right: number | null;
                                    } | null;
                                    readonly asset: {
                                      readonly __typename?: 'SanityImageAsset';
                                      readonly _id: string;
                                      readonly metadata: {
                                        readonly __typename?: 'SanityImageMetadata';
                                        readonly lqip: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  readonly options: ReadonlyArray<{
                                    readonly __typename?: 'ItemOption';
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'ItemOptionModifier';
                                      readonly default: boolean | null;
                                      readonly nutrition: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                    } | null> | null;
                                  } | null> | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                  readonly additionalItemInformation: {
                                    readonly __typename?: 'AdditionalItemInformation';
                                    readonly ingredients: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly additives: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly producerDetails: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                  } | null;
                                  readonly nutritionWithModifiers: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                  readonly allergens: {
                                    readonly __typename?: 'OpAllergen';
                                    readonly milk: number | null;
                                    readonly eggs: number | null;
                                    readonly fish: number | null;
                                    readonly peanuts: number | null;
                                    readonly shellfish: number | null;
                                    readonly treeNuts: number | null;
                                    readonly soy: number | null;
                                    readonly wheat: number | null;
                                    readonly mustard: number | null;
                                    readonly sesame: number | null;
                                    readonly celery: number | null;
                                    readonly lupin: number | null;
                                    readonly gluten: number | null;
                                    readonly sulphurDioxide: number | null;
                                  } | null;
                                } | null;
                              }
                            | {
                                readonly __typename?: 'Item';
                                readonly _id: string;
                                readonly _type: string;
                                readonly productSize: string | null;
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly image: {
                                  readonly __typename?: 'Image';
                                  readonly hotspot: {
                                    readonly __typename?: 'SanityImageHotspot';
                                    readonly x: number | null;
                                    readonly y: number | null;
                                    readonly height: number | null;
                                    readonly width: number | null;
                                  } | null;
                                  readonly crop: {
                                    readonly __typename?: 'SanityImageCrop';
                                    readonly top: number | null;
                                    readonly bottom: number | null;
                                    readonly left: number | null;
                                    readonly right: number | null;
                                  } | null;
                                  readonly asset: {
                                    readonly __typename?: 'SanityImageAsset';
                                    readonly _id: string;
                                    readonly metadata: {
                                      readonly __typename?: 'SanityImageMetadata';
                                      readonly lqip: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOption';
                                  readonly options: ReadonlyArray<{
                                    readonly __typename?: 'ItemOptionModifier';
                                    readonly default: boolean | null;
                                    readonly nutrition: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                  } | null> | null;
                                } | null> | null;
                                readonly nutrition: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                                readonly additionalItemInformation: {
                                  readonly __typename?: 'AdditionalItemInformation';
                                  readonly ingredients: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly additives: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                  readonly producerDetails: {
                                    readonly __typename?: 'LocaleString';
                                    readonly locale: string | null;
                                  } | null;
                                } | null;
                                readonly nutritionWithModifiers: {
                                  readonly __typename?: 'Nutrition';
                                  readonly calories: number | null;
                                  readonly caloriesPer100: number | null;
                                  readonly carbohydrates: number | null;
                                  readonly carbohydratesPer100: number | null;
                                  readonly cholesterol: number | null;
                                  readonly energyKJ: number | null;
                                  readonly energyKJPer100: number | null;
                                  readonly fat: number | null;
                                  readonly fatPer100: number | null;
                                  readonly fiber: number | null;
                                  readonly proteins: number | null;
                                  readonly proteinsPer100: number | null;
                                  readonly salt: number | null;
                                  readonly saltPer100: number | null;
                                  readonly saturatedFat: number | null;
                                  readonly saturatedFatPer100: number | null;
                                  readonly sodium: number | null;
                                  readonly sugar: number | null;
                                  readonly sugarPer100: number | null;
                                  readonly transFat: number | null;
                                  readonly weight: number | null;
                                } | null;
                                readonly allergens: {
                                  readonly __typename?: 'OpAllergen';
                                  readonly milk: number | null;
                                  readonly eggs: number | null;
                                  readonly fish: number | null;
                                  readonly peanuts: number | null;
                                  readonly shellfish: number | null;
                                  readonly treeNuts: number | null;
                                  readonly soy: number | null;
                                  readonly wheat: number | null;
                                  readonly mustard: number | null;
                                  readonly sesame: number | null;
                                  readonly celery: number | null;
                                  readonly lupin: number | null;
                                  readonly gluten: number | null;
                                  readonly sulphurDioxide: number | null;
                                } | null;
                              }
                            | null;
                        } | null> | null;
                      }
                    | {
                        readonly __typename?: 'Section';
                        readonly _id: string;
                        readonly _type: string;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly options: ReadonlyArray<
                          | { readonly __typename?: 'Combo' }
                          | {
                              readonly __typename?: 'Item';
                              readonly _id: string;
                              readonly _type: string;
                              readonly productSize: string | null;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'ItemOption';
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ItemOptionModifier';
                                  readonly default: boolean | null;
                                  readonly nutrition: {
                                    readonly __typename?: 'Nutrition';
                                    readonly calories: number | null;
                                    readonly caloriesPer100: number | null;
                                    readonly carbohydrates: number | null;
                                    readonly carbohydratesPer100: number | null;
                                    readonly cholesterol: number | null;
                                    readonly energyKJ: number | null;
                                    readonly energyKJPer100: number | null;
                                    readonly fat: number | null;
                                    readonly fatPer100: number | null;
                                    readonly fiber: number | null;
                                    readonly proteins: number | null;
                                    readonly proteinsPer100: number | null;
                                    readonly salt: number | null;
                                    readonly saltPer100: number | null;
                                    readonly saturatedFat: number | null;
                                    readonly saturatedFatPer100: number | null;
                                    readonly sodium: number | null;
                                    readonly sugar: number | null;
                                    readonly sugarPer100: number | null;
                                    readonly transFat: number | null;
                                    readonly weight: number | null;
                                  } | null;
                                } | null> | null;
                              } | null> | null;
                              readonly nutrition: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly additionalItemInformation: {
                                readonly __typename?: 'AdditionalItemInformation';
                                readonly ingredients: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly additives: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly producerDetails: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                              readonly nutritionWithModifiers: {
                                readonly __typename?: 'Nutrition';
                                readonly calories: number | null;
                                readonly caloriesPer100: number | null;
                                readonly carbohydrates: number | null;
                                readonly carbohydratesPer100: number | null;
                                readonly cholesterol: number | null;
                                readonly energyKJ: number | null;
                                readonly energyKJPer100: number | null;
                                readonly fat: number | null;
                                readonly fatPer100: number | null;
                                readonly fiber: number | null;
                                readonly proteins: number | null;
                                readonly proteinsPer100: number | null;
                                readonly salt: number | null;
                                readonly saltPer100: number | null;
                                readonly saturatedFat: number | null;
                                readonly saturatedFatPer100: number | null;
                                readonly sodium: number | null;
                                readonly sugar: number | null;
                                readonly sugarPer100: number | null;
                                readonly transFat: number | null;
                                readonly weight: number | null;
                              } | null;
                              readonly allergens: {
                                readonly __typename?: 'OpAllergen';
                                readonly milk: number | null;
                                readonly eggs: number | null;
                                readonly fish: number | null;
                                readonly peanuts: number | null;
                                readonly shellfish: number | null;
                                readonly treeNuts: number | null;
                                readonly soy: number | null;
                                readonly wheat: number | null;
                                readonly mustard: number | null;
                                readonly sesame: number | null;
                                readonly celery: number | null;
                                readonly lupin: number | null;
                                readonly gluten: number | null;
                                readonly sulphurDioxide: number | null;
                              } | null;
                            }
                          | { readonly __typename?: 'Offer' }
                          | {
                              readonly __typename?: 'Picker';
                              readonly _id: string;
                              readonly _type: string;
                              readonly name: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly image: {
                                readonly __typename?: 'Image';
                                readonly hotspot: {
                                  readonly __typename?: 'SanityImageHotspot';
                                  readonly x: number | null;
                                  readonly y: number | null;
                                  readonly height: number | null;
                                  readonly width: number | null;
                                } | null;
                                readonly crop: {
                                  readonly __typename?: 'SanityImageCrop';
                                  readonly top: number | null;
                                  readonly bottom: number | null;
                                  readonly left: number | null;
                                  readonly right: number | null;
                                } | null;
                                readonly asset: {
                                  readonly __typename?: 'SanityImageAsset';
                                  readonly _id: string;
                                  readonly metadata: {
                                    readonly __typename?: 'SanityImageMetadata';
                                    readonly lqip: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              readonly pickerDefaults: ReadonlyArray<{
                                readonly __typename?: 'PickerItemMapping';
                                readonly pickerAspectValueIdentifier: string | null;
                                readonly pickerAspect: {
                                  readonly __typename?: 'PickerAspect';
                                  readonly _id: string;
                                } | null;
                              } | null> | null;
                              readonly pickerAspects: ReadonlyArray<{
                                readonly __typename?: 'PickerAspect';
                                readonly _id: string;
                                readonly _type: string;
                                readonly pickerAspectOptions: ReadonlyArray<{
                                  readonly __typename?: 'PickerAspectValue';
                                  readonly identifier: string | null;
                                } | null> | null;
                              } | null> | null;
                              readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                                readonly __typename?: 'PickerAspectItemOptions';
                                readonly pickerAspect: {
                                  readonly __typename?: 'PickerAspect';
                                  readonly _id: string;
                                } | null;
                                readonly options: ReadonlyArray<{
                                  readonly __typename?: 'ListItem';
                                  readonly value: string | null;
                                } | null> | null;
                              } | null> | null;
                              readonly options: ReadonlyArray<{
                                readonly __typename?: 'PickerOption';
                                readonly pickerItemMappings: ReadonlyArray<{
                                  readonly __typename?: 'PickerItemMapping';
                                  readonly pickerAspectValueIdentifier: string | null;
                                  readonly pickerAspect: {
                                    readonly __typename?: 'PickerAspect';
                                    readonly _id: string;
                                  } | null;
                                } | null> | null;
                                readonly option:
                                  | {
                                      readonly __typename?: 'Combo';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly name: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly image: {
                                        readonly __typename?: 'Image';
                                        readonly hotspot: {
                                          readonly __typename?: 'SanityImageHotspot';
                                          readonly x: number | null;
                                          readonly y: number | null;
                                          readonly height: number | null;
                                          readonly width: number | null;
                                        } | null;
                                        readonly crop: {
                                          readonly __typename?: 'SanityImageCrop';
                                          readonly top: number | null;
                                          readonly bottom: number | null;
                                          readonly left: number | null;
                                          readonly right: number | null;
                                        } | null;
                                        readonly asset: {
                                          readonly __typename?: 'SanityImageAsset';
                                          readonly _id: string;
                                          readonly metadata: {
                                            readonly __typename?: 'SanityImageMetadata';
                                            readonly lqip: string | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      readonly mainItem: {
                                        readonly __typename?: 'Item';
                                        readonly _id: string;
                                        readonly _type: string;
                                        readonly productSize: string | null;
                                        readonly name: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                        readonly image: {
                                          readonly __typename?: 'Image';
                                          readonly hotspot: {
                                            readonly __typename?: 'SanityImageHotspot';
                                            readonly x: number | null;
                                            readonly y: number | null;
                                            readonly height: number | null;
                                            readonly width: number | null;
                                          } | null;
                                          readonly crop: {
                                            readonly __typename?: 'SanityImageCrop';
                                            readonly top: number | null;
                                            readonly bottom: number | null;
                                            readonly left: number | null;
                                            readonly right: number | null;
                                          } | null;
                                          readonly asset: {
                                            readonly __typename?: 'SanityImageAsset';
                                            readonly _id: string;
                                            readonly metadata: {
                                              readonly __typename?: 'SanityImageMetadata';
                                              readonly lqip: string | null;
                                            } | null;
                                          } | null;
                                        } | null;
                                        readonly options: ReadonlyArray<{
                                          readonly __typename?: 'ItemOption';
                                          readonly options: ReadonlyArray<{
                                            readonly __typename?: 'ItemOptionModifier';
                                            readonly default: boolean | null;
                                            readonly nutrition: {
                                              readonly __typename?: 'Nutrition';
                                              readonly calories: number | null;
                                              readonly caloriesPer100: number | null;
                                              readonly carbohydrates: number | null;
                                              readonly carbohydratesPer100: number | null;
                                              readonly cholesterol: number | null;
                                              readonly energyKJ: number | null;
                                              readonly energyKJPer100: number | null;
                                              readonly fat: number | null;
                                              readonly fatPer100: number | null;
                                              readonly fiber: number | null;
                                              readonly proteins: number | null;
                                              readonly proteinsPer100: number | null;
                                              readonly salt: number | null;
                                              readonly saltPer100: number | null;
                                              readonly saturatedFat: number | null;
                                              readonly saturatedFatPer100: number | null;
                                              readonly sodium: number | null;
                                              readonly sugar: number | null;
                                              readonly sugarPer100: number | null;
                                              readonly transFat: number | null;
                                              readonly weight: number | null;
                                            } | null;
                                          } | null> | null;
                                        } | null> | null;
                                        readonly nutrition: {
                                          readonly __typename?: 'Nutrition';
                                          readonly calories: number | null;
                                          readonly caloriesPer100: number | null;
                                          readonly carbohydrates: number | null;
                                          readonly carbohydratesPer100: number | null;
                                          readonly cholesterol: number | null;
                                          readonly energyKJ: number | null;
                                          readonly energyKJPer100: number | null;
                                          readonly fat: number | null;
                                          readonly fatPer100: number | null;
                                          readonly fiber: number | null;
                                          readonly proteins: number | null;
                                          readonly proteinsPer100: number | null;
                                          readonly salt: number | null;
                                          readonly saltPer100: number | null;
                                          readonly saturatedFat: number | null;
                                          readonly saturatedFatPer100: number | null;
                                          readonly sodium: number | null;
                                          readonly sugar: number | null;
                                          readonly sugarPer100: number | null;
                                          readonly transFat: number | null;
                                          readonly weight: number | null;
                                        } | null;
                                        readonly additionalItemInformation: {
                                          readonly __typename?: 'AdditionalItemInformation';
                                          readonly ingredients: {
                                            readonly __typename?: 'LocaleString';
                                            readonly locale: string | null;
                                          } | null;
                                          readonly additives: {
                                            readonly __typename?: 'LocaleString';
                                            readonly locale: string | null;
                                          } | null;
                                          readonly producerDetails: {
                                            readonly __typename?: 'LocaleString';
                                            readonly locale: string | null;
                                          } | null;
                                        } | null;
                                        readonly nutritionWithModifiers: {
                                          readonly __typename?: 'Nutrition';
                                          readonly calories: number | null;
                                          readonly caloriesPer100: number | null;
                                          readonly carbohydrates: number | null;
                                          readonly carbohydratesPer100: number | null;
                                          readonly cholesterol: number | null;
                                          readonly energyKJ: number | null;
                                          readonly energyKJPer100: number | null;
                                          readonly fat: number | null;
                                          readonly fatPer100: number | null;
                                          readonly fiber: number | null;
                                          readonly proteins: number | null;
                                          readonly proteinsPer100: number | null;
                                          readonly salt: number | null;
                                          readonly saltPer100: number | null;
                                          readonly saturatedFat: number | null;
                                          readonly saturatedFatPer100: number | null;
                                          readonly sodium: number | null;
                                          readonly sugar: number | null;
                                          readonly sugarPer100: number | null;
                                          readonly transFat: number | null;
                                          readonly weight: number | null;
                                        } | null;
                                        readonly allergens: {
                                          readonly __typename?: 'OpAllergen';
                                          readonly milk: number | null;
                                          readonly eggs: number | null;
                                          readonly fish: number | null;
                                          readonly peanuts: number | null;
                                          readonly shellfish: number | null;
                                          readonly treeNuts: number | null;
                                          readonly soy: number | null;
                                          readonly wheat: number | null;
                                          readonly mustard: number | null;
                                          readonly sesame: number | null;
                                          readonly celery: number | null;
                                          readonly lupin: number | null;
                                          readonly gluten: number | null;
                                          readonly sulphurDioxide: number | null;
                                        } | null;
                                      } | null;
                                    }
                                  | {
                                      readonly __typename?: 'Item';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly productSize: string | null;
                                      readonly name: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly image: {
                                        readonly __typename?: 'Image';
                                        readonly hotspot: {
                                          readonly __typename?: 'SanityImageHotspot';
                                          readonly x: number | null;
                                          readonly y: number | null;
                                          readonly height: number | null;
                                          readonly width: number | null;
                                        } | null;
                                        readonly crop: {
                                          readonly __typename?: 'SanityImageCrop';
                                          readonly top: number | null;
                                          readonly bottom: number | null;
                                          readonly left: number | null;
                                          readonly right: number | null;
                                        } | null;
                                        readonly asset: {
                                          readonly __typename?: 'SanityImageAsset';
                                          readonly _id: string;
                                          readonly metadata: {
                                            readonly __typename?: 'SanityImageMetadata';
                                            readonly lqip: string | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ItemOption';
                                        readonly options: ReadonlyArray<{
                                          readonly __typename?: 'ItemOptionModifier';
                                          readonly default: boolean | null;
                                          readonly nutrition: {
                                            readonly __typename?: 'Nutrition';
                                            readonly calories: number | null;
                                            readonly caloriesPer100: number | null;
                                            readonly carbohydrates: number | null;
                                            readonly carbohydratesPer100: number | null;
                                            readonly cholesterol: number | null;
                                            readonly energyKJ: number | null;
                                            readonly energyKJPer100: number | null;
                                            readonly fat: number | null;
                                            readonly fatPer100: number | null;
                                            readonly fiber: number | null;
                                            readonly proteins: number | null;
                                            readonly proteinsPer100: number | null;
                                            readonly salt: number | null;
                                            readonly saltPer100: number | null;
                                            readonly saturatedFat: number | null;
                                            readonly saturatedFatPer100: number | null;
                                            readonly sodium: number | null;
                                            readonly sugar: number | null;
                                            readonly sugarPer100: number | null;
                                            readonly transFat: number | null;
                                            readonly weight: number | null;
                                          } | null;
                                        } | null> | null;
                                      } | null> | null;
                                      readonly nutrition: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                      readonly additionalItemInformation: {
                                        readonly __typename?: 'AdditionalItemInformation';
                                        readonly ingredients: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                        readonly additives: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                        readonly producerDetails: {
                                          readonly __typename?: 'LocaleString';
                                          readonly locale: string | null;
                                        } | null;
                                      } | null;
                                      readonly nutritionWithModifiers: {
                                        readonly __typename?: 'Nutrition';
                                        readonly calories: number | null;
                                        readonly caloriesPer100: number | null;
                                        readonly carbohydrates: number | null;
                                        readonly carbohydratesPer100: number | null;
                                        readonly cholesterol: number | null;
                                        readonly energyKJ: number | null;
                                        readonly energyKJPer100: number | null;
                                        readonly fat: number | null;
                                        readonly fatPer100: number | null;
                                        readonly fiber: number | null;
                                        readonly proteins: number | null;
                                        readonly proteinsPer100: number | null;
                                        readonly salt: number | null;
                                        readonly saltPer100: number | null;
                                        readonly saturatedFat: number | null;
                                        readonly saturatedFatPer100: number | null;
                                        readonly sodium: number | null;
                                        readonly sugar: number | null;
                                        readonly sugarPer100: number | null;
                                        readonly transFat: number | null;
                                        readonly weight: number | null;
                                      } | null;
                                      readonly allergens: {
                                        readonly __typename?: 'OpAllergen';
                                        readonly milk: number | null;
                                        readonly eggs: number | null;
                                        readonly fish: number | null;
                                        readonly peanuts: number | null;
                                        readonly shellfish: number | null;
                                        readonly treeNuts: number | null;
                                        readonly soy: number | null;
                                        readonly wheat: number | null;
                                        readonly mustard: number | null;
                                        readonly sesame: number | null;
                                        readonly celery: number | null;
                                        readonly lupin: number | null;
                                        readonly gluten: number | null;
                                        readonly sulphurDioxide: number | null;
                                      } | null;
                                    }
                                  | null;
                              } | null> | null;
                            }
                          | {
                              readonly __typename?: 'Section';
                              readonly _type: string;
                              readonly _id: string;
                              readonly options: ReadonlyArray<
                                | { readonly __typename?: 'Combo' }
                                | {
                                    readonly __typename?: 'Item';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly productSize: string | null;
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'ItemOption';
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ItemOptionModifier';
                                        readonly default: boolean | null;
                                        readonly nutrition: {
                                          readonly __typename?: 'Nutrition';
                                          readonly calories: number | null;
                                          readonly caloriesPer100: number | null;
                                          readonly carbohydrates: number | null;
                                          readonly carbohydratesPer100: number | null;
                                          readonly cholesterol: number | null;
                                          readonly energyKJ: number | null;
                                          readonly energyKJPer100: number | null;
                                          readonly fat: number | null;
                                          readonly fatPer100: number | null;
                                          readonly fiber: number | null;
                                          readonly proteins: number | null;
                                          readonly proteinsPer100: number | null;
                                          readonly salt: number | null;
                                          readonly saltPer100: number | null;
                                          readonly saturatedFat: number | null;
                                          readonly saturatedFatPer100: number | null;
                                          readonly sodium: number | null;
                                          readonly sugar: number | null;
                                          readonly sugarPer100: number | null;
                                          readonly transFat: number | null;
                                          readonly weight: number | null;
                                        } | null;
                                      } | null> | null;
                                    } | null> | null;
                                    readonly nutrition: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly additionalItemInformation: {
                                      readonly __typename?: 'AdditionalItemInformation';
                                      readonly ingredients: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly additives: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                      readonly producerDetails: {
                                        readonly __typename?: 'LocaleString';
                                        readonly locale: string | null;
                                      } | null;
                                    } | null;
                                    readonly nutritionWithModifiers: {
                                      readonly __typename?: 'Nutrition';
                                      readonly calories: number | null;
                                      readonly caloriesPer100: number | null;
                                      readonly carbohydrates: number | null;
                                      readonly carbohydratesPer100: number | null;
                                      readonly cholesterol: number | null;
                                      readonly energyKJ: number | null;
                                      readonly energyKJPer100: number | null;
                                      readonly fat: number | null;
                                      readonly fatPer100: number | null;
                                      readonly fiber: number | null;
                                      readonly proteins: number | null;
                                      readonly proteinsPer100: number | null;
                                      readonly salt: number | null;
                                      readonly saltPer100: number | null;
                                      readonly saturatedFat: number | null;
                                      readonly saturatedFatPer100: number | null;
                                      readonly sodium: number | null;
                                      readonly sugar: number | null;
                                      readonly sugarPer100: number | null;
                                      readonly transFat: number | null;
                                      readonly weight: number | null;
                                    } | null;
                                    readonly allergens: {
                                      readonly __typename?: 'OpAllergen';
                                      readonly milk: number | null;
                                      readonly eggs: number | null;
                                      readonly fish: number | null;
                                      readonly peanuts: number | null;
                                      readonly shellfish: number | null;
                                      readonly treeNuts: number | null;
                                      readonly soy: number | null;
                                      readonly wheat: number | null;
                                      readonly mustard: number | null;
                                      readonly sesame: number | null;
                                      readonly celery: number | null;
                                      readonly lupin: number | null;
                                      readonly gluten: number | null;
                                      readonly sulphurDioxide: number | null;
                                    } | null;
                                  }
                                | { readonly __typename?: 'Offer' }
                                | {
                                    readonly __typename?: 'Picker';
                                    readonly _id: string;
                                    readonly _type: string;
                                    readonly name: {
                                      readonly __typename?: 'LocaleString';
                                      readonly locale: string | null;
                                    } | null;
                                    readonly image: {
                                      readonly __typename?: 'Image';
                                      readonly hotspot: {
                                        readonly __typename?: 'SanityImageHotspot';
                                        readonly x: number | null;
                                        readonly y: number | null;
                                        readonly height: number | null;
                                        readonly width: number | null;
                                      } | null;
                                      readonly crop: {
                                        readonly __typename?: 'SanityImageCrop';
                                        readonly top: number | null;
                                        readonly bottom: number | null;
                                        readonly left: number | null;
                                        readonly right: number | null;
                                      } | null;
                                      readonly asset: {
                                        readonly __typename?: 'SanityImageAsset';
                                        readonly _id: string;
                                        readonly metadata: {
                                          readonly __typename?: 'SanityImageMetadata';
                                          readonly lqip: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    readonly pickerDefaults: ReadonlyArray<{
                                      readonly __typename?: 'PickerItemMapping';
                                      readonly pickerAspectValueIdentifier: string | null;
                                      readonly pickerAspect: {
                                        readonly __typename?: 'PickerAspect';
                                        readonly _id: string;
                                      } | null;
                                    } | null> | null;
                                    readonly pickerAspects: ReadonlyArray<{
                                      readonly __typename?: 'PickerAspect';
                                      readonly _id: string;
                                      readonly _type: string;
                                      readonly pickerAspectOptions: ReadonlyArray<{
                                        readonly __typename?: 'PickerAspectValue';
                                        readonly identifier: string | null;
                                      } | null> | null;
                                    } | null> | null;
                                    readonly pickerAspectItemOptionMappings: ReadonlyArray<{
                                      readonly __typename?: 'PickerAspectItemOptions';
                                      readonly pickerAspect: {
                                        readonly __typename?: 'PickerAspect';
                                        readonly _id: string;
                                      } | null;
                                      readonly options: ReadonlyArray<{
                                        readonly __typename?: 'ListItem';
                                        readonly value: string | null;
                                      } | null> | null;
                                    } | null> | null;
                                    readonly options: ReadonlyArray<{
                                      readonly __typename?: 'PickerOption';
                                      readonly pickerItemMappings: ReadonlyArray<{
                                        readonly __typename?: 'PickerItemMapping';
                                        readonly pickerAspectValueIdentifier: string | null;
                                        readonly pickerAspect: {
                                          readonly __typename?: 'PickerAspect';
                                          readonly _id: string;
                                        } | null;
                                      } | null> | null;
                                      readonly option:
                                        | {
                                            readonly __typename?: 'Combo';
                                            readonly _id: string;
                                            readonly _type: string;
                                            readonly name: {
                                              readonly __typename?: 'LocaleString';
                                              readonly locale: string | null;
                                            } | null;
                                            readonly image: {
                                              readonly __typename?: 'Image';
                                              readonly hotspot: {
                                                readonly __typename?: 'SanityImageHotspot';
                                                readonly x: number | null;
                                                readonly y: number | null;
                                                readonly height: number | null;
                                                readonly width: number | null;
                                              } | null;
                                              readonly crop: {
                                                readonly __typename?: 'SanityImageCrop';
                                                readonly top: number | null;
                                                readonly bottom: number | null;
                                                readonly left: number | null;
                                                readonly right: number | null;
                                              } | null;
                                              readonly asset: {
                                                readonly __typename?: 'SanityImageAsset';
                                                readonly _id: string;
                                                readonly metadata: {
                                                  readonly __typename?: 'SanityImageMetadata';
                                                  readonly lqip: string | null;
                                                } | null;
                                              } | null;
                                            } | null;
                                            readonly mainItem: {
                                              readonly __typename?: 'Item';
                                              readonly _id: string;
                                              readonly _type: string;
                                              readonly productSize: string | null;
                                              readonly name: {
                                                readonly __typename?: 'LocaleString';
                                                readonly locale: string | null;
                                              } | null;
                                              readonly image: {
                                                readonly __typename?: 'Image';
                                                readonly hotspot: {
                                                  readonly __typename?: 'SanityImageHotspot';
                                                  readonly x: number | null;
                                                  readonly y: number | null;
                                                  readonly height: number | null;
                                                  readonly width: number | null;
                                                } | null;
                                                readonly crop: {
                                                  readonly __typename?: 'SanityImageCrop';
                                                  readonly top: number | null;
                                                  readonly bottom: number | null;
                                                  readonly left: number | null;
                                                  readonly right: number | null;
                                                } | null;
                                                readonly asset: {
                                                  readonly __typename?: 'SanityImageAsset';
                                                  readonly _id: string;
                                                  readonly metadata: {
                                                    readonly __typename?: 'SanityImageMetadata';
                                                    readonly lqip: string | null;
                                                  } | null;
                                                } | null;
                                              } | null;
                                              readonly options: ReadonlyArray<{
                                                readonly __typename?: 'ItemOption';
                                                readonly options: ReadonlyArray<{
                                                  readonly __typename?: 'ItemOptionModifier';
                                                  readonly default: boolean | null;
                                                  readonly nutrition: {
                                                    readonly __typename?: 'Nutrition';
                                                    readonly calories: number | null;
                                                    readonly caloriesPer100: number | null;
                                                    readonly carbohydrates: number | null;
                                                    readonly carbohydratesPer100: number | null;
                                                    readonly cholesterol: number | null;
                                                    readonly energyKJ: number | null;
                                                    readonly energyKJPer100: number | null;
                                                    readonly fat: number | null;
                                                    readonly fatPer100: number | null;
                                                    readonly fiber: number | null;
                                                    readonly proteins: number | null;
                                                    readonly proteinsPer100: number | null;
                                                    readonly salt: number | null;
                                                    readonly saltPer100: number | null;
                                                    readonly saturatedFat: number | null;
                                                    readonly saturatedFatPer100: number | null;
                                                    readonly sodium: number | null;
                                                    readonly sugar: number | null;
                                                    readonly sugarPer100: number | null;
                                                    readonly transFat: number | null;
                                                    readonly weight: number | null;
                                                  } | null;
                                                } | null> | null;
                                              } | null> | null;
                                              readonly nutrition: {
                                                readonly __typename?: 'Nutrition';
                                                readonly calories: number | null;
                                                readonly caloriesPer100: number | null;
                                                readonly carbohydrates: number | null;
                                                readonly carbohydratesPer100: number | null;
                                                readonly cholesterol: number | null;
                                                readonly energyKJ: number | null;
                                                readonly energyKJPer100: number | null;
                                                readonly fat: number | null;
                                                readonly fatPer100: number | null;
                                                readonly fiber: number | null;
                                                readonly proteins: number | null;
                                                readonly proteinsPer100: number | null;
                                                readonly salt: number | null;
                                                readonly saltPer100: number | null;
                                                readonly saturatedFat: number | null;
                                                readonly saturatedFatPer100: number | null;
                                                readonly sodium: number | null;
                                                readonly sugar: number | null;
                                                readonly sugarPer100: number | null;
                                                readonly transFat: number | null;
                                                readonly weight: number | null;
                                              } | null;
                                              readonly additionalItemInformation: {
                                                readonly __typename?: 'AdditionalItemInformation';
                                                readonly ingredients: {
                                                  readonly __typename?: 'LocaleString';
                                                  readonly locale: string | null;
                                                } | null;
                                                readonly additives: {
                                                  readonly __typename?: 'LocaleString';
                                                  readonly locale: string | null;
                                                } | null;
                                                readonly producerDetails: {
                                                  readonly __typename?: 'LocaleString';
                                                  readonly locale: string | null;
                                                } | null;
                                              } | null;
                                              readonly nutritionWithModifiers: {
                                                readonly __typename?: 'Nutrition';
                                                readonly calories: number | null;
                                                readonly caloriesPer100: number | null;
                                                readonly carbohydrates: number | null;
                                                readonly carbohydratesPer100: number | null;
                                                readonly cholesterol: number | null;
                                                readonly energyKJ: number | null;
                                                readonly energyKJPer100: number | null;
                                                readonly fat: number | null;
                                                readonly fatPer100: number | null;
                                                readonly fiber: number | null;
                                                readonly proteins: number | null;
                                                readonly proteinsPer100: number | null;
                                                readonly salt: number | null;
                                                readonly saltPer100: number | null;
                                                readonly saturatedFat: number | null;
                                                readonly saturatedFatPer100: number | null;
                                                readonly sodium: number | null;
                                                readonly sugar: number | null;
                                                readonly sugarPer100: number | null;
                                                readonly transFat: number | null;
                                                readonly weight: number | null;
                                              } | null;
                                              readonly allergens: {
                                                readonly __typename?: 'OpAllergen';
                                                readonly milk: number | null;
                                                readonly eggs: number | null;
                                                readonly fish: number | null;
                                                readonly peanuts: number | null;
                                                readonly shellfish: number | null;
                                                readonly treeNuts: number | null;
                                                readonly soy: number | null;
                                                readonly wheat: number | null;
                                                readonly mustard: number | null;
                                                readonly sesame: number | null;
                                                readonly celery: number | null;
                                                readonly lupin: number | null;
                                                readonly gluten: number | null;
                                                readonly sulphurDioxide: number | null;
                                              } | null;
                                            } | null;
                                          }
                                        | {
                                            readonly __typename?: 'Item';
                                            readonly _id: string;
                                            readonly _type: string;
                                            readonly productSize: string | null;
                                            readonly name: {
                                              readonly __typename?: 'LocaleString';
                                              readonly locale: string | null;
                                            } | null;
                                            readonly image: {
                                              readonly __typename?: 'Image';
                                              readonly hotspot: {
                                                readonly __typename?: 'SanityImageHotspot';
                                                readonly x: number | null;
                                                readonly y: number | null;
                                                readonly height: number | null;
                                                readonly width: number | null;
                                              } | null;
                                              readonly crop: {
                                                readonly __typename?: 'SanityImageCrop';
                                                readonly top: number | null;
                                                readonly bottom: number | null;
                                                readonly left: number | null;
                                                readonly right: number | null;
                                              } | null;
                                              readonly asset: {
                                                readonly __typename?: 'SanityImageAsset';
                                                readonly _id: string;
                                                readonly metadata: {
                                                  readonly __typename?: 'SanityImageMetadata';
                                                  readonly lqip: string | null;
                                                } | null;
                                              } | null;
                                            } | null;
                                            readonly options: ReadonlyArray<{
                                              readonly __typename?: 'ItemOption';
                                              readonly options: ReadonlyArray<{
                                                readonly __typename?: 'ItemOptionModifier';
                                                readonly default: boolean | null;
                                                readonly nutrition: {
                                                  readonly __typename?: 'Nutrition';
                                                  readonly calories: number | null;
                                                  readonly caloriesPer100: number | null;
                                                  readonly carbohydrates: number | null;
                                                  readonly carbohydratesPer100: number | null;
                                                  readonly cholesterol: number | null;
                                                  readonly energyKJ: number | null;
                                                  readonly energyKJPer100: number | null;
                                                  readonly fat: number | null;
                                                  readonly fatPer100: number | null;
                                                  readonly fiber: number | null;
                                                  readonly proteins: number | null;
                                                  readonly proteinsPer100: number | null;
                                                  readonly salt: number | null;
                                                  readonly saltPer100: number | null;
                                                  readonly saturatedFat: number | null;
                                                  readonly saturatedFatPer100: number | null;
                                                  readonly sodium: number | null;
                                                  readonly sugar: number | null;
                                                  readonly sugarPer100: number | null;
                                                  readonly transFat: number | null;
                                                  readonly weight: number | null;
                                                } | null;
                                              } | null> | null;
                                            } | null> | null;
                                            readonly nutrition: {
                                              readonly __typename?: 'Nutrition';
                                              readonly calories: number | null;
                                              readonly caloriesPer100: number | null;
                                              readonly carbohydrates: number | null;
                                              readonly carbohydratesPer100: number | null;
                                              readonly cholesterol: number | null;
                                              readonly energyKJ: number | null;
                                              readonly energyKJPer100: number | null;
                                              readonly fat: number | null;
                                              readonly fatPer100: number | null;
                                              readonly fiber: number | null;
                                              readonly proteins: number | null;
                                              readonly proteinsPer100: number | null;
                                              readonly salt: number | null;
                                              readonly saltPer100: number | null;
                                              readonly saturatedFat: number | null;
                                              readonly saturatedFatPer100: number | null;
                                              readonly sodium: number | null;
                                              readonly sugar: number | null;
                                              readonly sugarPer100: number | null;
                                              readonly transFat: number | null;
                                              readonly weight: number | null;
                                            } | null;
                                            readonly additionalItemInformation: {
                                              readonly __typename?: 'AdditionalItemInformation';
                                              readonly ingredients: {
                                                readonly __typename?: 'LocaleString';
                                                readonly locale: string | null;
                                              } | null;
                                              readonly additives: {
                                                readonly __typename?: 'LocaleString';
                                                readonly locale: string | null;
                                              } | null;
                                              readonly producerDetails: {
                                                readonly __typename?: 'LocaleString';
                                                readonly locale: string | null;
                                              } | null;
                                            } | null;
                                            readonly nutritionWithModifiers: {
                                              readonly __typename?: 'Nutrition';
                                              readonly calories: number | null;
                                              readonly caloriesPer100: number | null;
                                              readonly carbohydrates: number | null;
                                              readonly carbohydratesPer100: number | null;
                                              readonly cholesterol: number | null;
                                              readonly energyKJ: number | null;
                                              readonly energyKJPer100: number | null;
                                              readonly fat: number | null;
                                              readonly fatPer100: number | null;
                                              readonly fiber: number | null;
                                              readonly proteins: number | null;
                                              readonly proteinsPer100: number | null;
                                              readonly salt: number | null;
                                              readonly saltPer100: number | null;
                                              readonly saturatedFat: number | null;
                                              readonly saturatedFatPer100: number | null;
                                              readonly sodium: number | null;
                                              readonly sugar: number | null;
                                              readonly sugarPer100: number | null;
                                              readonly transFat: number | null;
                                              readonly weight: number | null;
                                            } | null;
                                            readonly allergens: {
                                              readonly __typename?: 'OpAllergen';
                                              readonly milk: number | null;
                                              readonly eggs: number | null;
                                              readonly fish: number | null;
                                              readonly peanuts: number | null;
                                              readonly shellfish: number | null;
                                              readonly treeNuts: number | null;
                                              readonly soy: number | null;
                                              readonly wheat: number | null;
                                              readonly mustard: number | null;
                                              readonly sesame: number | null;
                                              readonly celery: number | null;
                                              readonly lupin: number | null;
                                              readonly gluten: number | null;
                                              readonly sulphurDioxide: number | null;
                                            } | null;
                                          }
                                        | null;
                                    } | null> | null;
                                  }
                                | { readonly __typename?: 'Section' }
                                | null
                              > | null;
                            }
                          | null
                        > | null;
                      }
                    | null
                  > | null;
                }
              | null
            > | null;
            readonly pickerBackgroundImage: {
              readonly __typename?: 'Image';
              readonly hotspot: {
                readonly __typename?: 'SanityImageHotspot';
                readonly x: number | null;
                readonly y: number | null;
                readonly height: number | null;
                readonly width: number | null;
              } | null;
              readonly crop: {
                readonly __typename?: 'SanityImageCrop';
                readonly top: number | null;
                readonly bottom: number | null;
                readonly left: number | null;
                readonly right: number | null;
              } | null;
              readonly asset: {
                readonly __typename?: 'SanityImageAsset';
                readonly _id: string;
                readonly metadata: {
                  readonly __typename?: 'SanityImageMetadata';
                  readonly lqip: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          readonly viewMenuButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly moreOptionsButtonText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly madLibFilterGroup: {
            readonly __typename?: 'FilterGroup';
            readonly _type: string | null;
            readonly description: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly filters: ReadonlyArray<{
              readonly __typename?: 'Filter';
              readonly _type: string | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly conditions: ReadonlyArray<
                | {
                    readonly __typename?: 'ConditionAllergen';
                    readonly _type: string | null;
                    readonly allergenIdentifier: string | null;
                    readonly comparisonOperator: string | null;
                    readonly comparisonValue: number | null;
                  }
                | {
                    readonly __typename?: 'ConditionItemOneOf';
                    readonly _type: string | null;
                    readonly items: ReadonlyArray<{
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ConditionNutrition';
                    readonly _type: string | null;
                    readonly nutritionIdentifier: string | null;
                    readonly comparisonOperator: string | null;
                    readonly comparisonValue: number | null;
                  }
                | {
                    readonly __typename?: 'ConditionParentCategory';
                    readonly _type: string | null;
                    readonly parentCategory:
                      | { readonly __typename?: 'Picker'; readonly _id: string }
                      | { readonly __typename?: 'Section'; readonly _id: string }
                      | null;
                  }
                | null
              > | null;
            } | null> | null;
          } | null;
          readonly modalFilterGroups: ReadonlyArray<{
            readonly __typename?: 'FilterGroup';
            readonly _type: string | null;
            readonly description: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly filters: ReadonlyArray<{
              readonly __typename?: 'Filter';
              readonly _type: string | null;
              readonly description: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly conditions: ReadonlyArray<
                | {
                    readonly __typename?: 'ConditionAllergen';
                    readonly _type: string | null;
                    readonly allergenIdentifier: string | null;
                    readonly comparisonOperator: string | null;
                    readonly comparisonValue: number | null;
                  }
                | {
                    readonly __typename?: 'ConditionItemOneOf';
                    readonly _type: string | null;
                    readonly items: ReadonlyArray<{
                      readonly __typename?: 'Item';
                      readonly _id: string;
                      readonly _type: string;
                      readonly productSize: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly image: {
                        readonly __typename?: 'Image';
                        readonly hotspot: {
                          readonly __typename?: 'SanityImageHotspot';
                          readonly x: number | null;
                          readonly y: number | null;
                          readonly height: number | null;
                          readonly width: number | null;
                        } | null;
                        readonly crop: {
                          readonly __typename?: 'SanityImageCrop';
                          readonly top: number | null;
                          readonly bottom: number | null;
                          readonly left: number | null;
                          readonly right: number | null;
                        } | null;
                        readonly asset: {
                          readonly __typename?: 'SanityImageAsset';
                          readonly _id: string;
                          readonly metadata: {
                            readonly __typename?: 'SanityImageMetadata';
                            readonly lqip: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly options: ReadonlyArray<{
                        readonly __typename?: 'ItemOption';
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOptionModifier';
                          readonly default: boolean | null;
                          readonly nutrition: {
                            readonly __typename?: 'Nutrition';
                            readonly calories: number | null;
                            readonly caloriesPer100: number | null;
                            readonly carbohydrates: number | null;
                            readonly carbohydratesPer100: number | null;
                            readonly cholesterol: number | null;
                            readonly energyKJ: number | null;
                            readonly energyKJPer100: number | null;
                            readonly fat: number | null;
                            readonly fatPer100: number | null;
                            readonly fiber: number | null;
                            readonly proteins: number | null;
                            readonly proteinsPer100: number | null;
                            readonly salt: number | null;
                            readonly saltPer100: number | null;
                            readonly saturatedFat: number | null;
                            readonly saturatedFatPer100: number | null;
                            readonly sodium: number | null;
                            readonly sugar: number | null;
                            readonly sugarPer100: number | null;
                            readonly transFat: number | null;
                            readonly weight: number | null;
                          } | null;
                        } | null> | null;
                      } | null> | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly additionalItemInformation: {
                        readonly __typename?: 'AdditionalItemInformation';
                        readonly ingredients: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly additives: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly producerDetails: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                      } | null;
                      readonly nutritionWithModifiers: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                      readonly allergens: {
                        readonly __typename?: 'OpAllergen';
                        readonly milk: number | null;
                        readonly eggs: number | null;
                        readonly fish: number | null;
                        readonly peanuts: number | null;
                        readonly shellfish: number | null;
                        readonly treeNuts: number | null;
                        readonly soy: number | null;
                        readonly wheat: number | null;
                        readonly mustard: number | null;
                        readonly sesame: number | null;
                        readonly celery: number | null;
                        readonly lupin: number | null;
                        readonly gluten: number | null;
                        readonly sulphurDioxide: number | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    readonly __typename?: 'ConditionNutrition';
                    readonly _type: string | null;
                    readonly nutritionIdentifier: string | null;
                    readonly comparisonOperator: string | null;
                    readonly comparisonValue: number | null;
                  }
                | {
                    readonly __typename?: 'ConditionParentCategory';
                    readonly _type: string | null;
                    readonly parentCategory:
                      | { readonly __typename?: 'Picker'; readonly _id: string }
                      | { readonly __typename?: 'Section'; readonly _id: string }
                      | null;
                  }
                | null
              > | null;
            } | null> | null;
          } | null> | null;
          readonly categoryWhitelist: ReadonlyArray<
            | { readonly __typename?: 'Picker'; readonly _id: string; readonly _type: string }
            | { readonly __typename?: 'Section'; readonly _id: string; readonly _type: string }
            | null
          > | null;
        }
      | {
          readonly __typename?: 'NutritionInfoWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly nutritionalSection: {
            readonly __typename?: 'NutritionalSection';
            readonly name: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly products: ReadonlyArray<
              | {
                  readonly __typename?: 'Item';
                  readonly _id: string;
                  readonly _type: string;
                  readonly rewardEligible: boolean | null;
                  readonly isDummyItem: boolean | null;
                  readonly labelAsPerPerson: boolean | null;
                  readonly productSize: string | null;
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly description: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly legalInformation: {
                    readonly __typename?: 'LocaleBlockContent';
                    readonly localeRaw: any | null;
                  } | null;
                  readonly image: {
                    readonly __typename?: 'Image';
                    readonly hotspot: {
                      readonly __typename?: 'SanityImageHotspot';
                      readonly x: number | null;
                      readonly y: number | null;
                      readonly height: number | null;
                      readonly width: number | null;
                    } | null;
                    readonly crop: {
                      readonly __typename?: 'SanityImageCrop';
                      readonly top: number | null;
                      readonly bottom: number | null;
                      readonly left: number | null;
                      readonly right: number | null;
                    } | null;
                    readonly asset: {
                      readonly __typename?: 'SanityImageAsset';
                      readonly _id: string;
                      readonly metadata: {
                        readonly __typename?: 'SanityImageMetadata';
                        readonly lqip: string | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly imageDescription: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly imagesByChannels: {
                    readonly __typename?: 'ImagesByChannels';
                    readonly imageRestaurant: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                    readonly imageDelivery: {
                      readonly __typename?: 'Image';
                      readonly asset: {
                        readonly __typename?: 'SanityImageAsset';
                        readonly _id: string;
                        readonly metadata: {
                          readonly __typename?: 'SanityImageMetadata';
                          readonly lqip: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                  readonly nutrition: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly additionalItemInformation: {
                    readonly __typename?: 'AdditionalItemInformation';
                    readonly sourcesOfGluten: string | null;
                    readonly ingredients: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly additives: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly producerDetails: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                  } | null;
                  readonly nutritionWithModifiers: {
                    readonly __typename?: 'Nutrition';
                    readonly calories: number | null;
                    readonly caloriesPer100: number | null;
                    readonly carbohydrates: number | null;
                    readonly carbohydratesPer100: number | null;
                    readonly cholesterol: number | null;
                    readonly energyKJ: number | null;
                    readonly energyKJPer100: number | null;
                    readonly fat: number | null;
                    readonly fatPer100: number | null;
                    readonly fiber: number | null;
                    readonly proteins: number | null;
                    readonly proteinsPer100: number | null;
                    readonly salt: number | null;
                    readonly saltPer100: number | null;
                    readonly saturatedFat: number | null;
                    readonly saturatedFatPer100: number | null;
                    readonly sodium: number | null;
                    readonly sugar: number | null;
                    readonly sugarPer100: number | null;
                    readonly transFat: number | null;
                    readonly weight: number | null;
                  } | null;
                  readonly allergens: {
                    readonly __typename?: 'OpAllergen';
                    readonly milk: number | null;
                    readonly eggs: number | null;
                    readonly fish: number | null;
                    readonly peanuts: number | null;
                    readonly shellfish: number | null;
                    readonly treeNuts: number | null;
                    readonly soy: number | null;
                    readonly wheat: number | null;
                    readonly mustard: number | null;
                    readonly sesame: number | null;
                    readonly celery: number | null;
                    readonly lupin: number | null;
                    readonly gluten: number | null;
                    readonly sulphurDioxide: number | null;
                  } | null;
                  readonly options: ReadonlyArray<{
                    readonly __typename?: 'ItemOption';
                    readonly componentStyle: string | null;
                    readonly upsellModifier: boolean | null;
                    readonly allowMultipleSelections: boolean | null;
                    readonly displayModifierMultiplierName: boolean | null;
                    readonly injectDefaultSelection: boolean | null;
                    readonly singleChoiceOnly: boolean | null;
                    readonly minAmount: number | null;
                    readonly maxAmount: number | null;
                    readonly _key: string | null;
                    readonly type: string | null;
                    readonly name: {
                      readonly __typename?: 'LocaleString';
                      readonly locale: string | null;
                    } | null;
                    readonly displayGroup: {
                      readonly __typename?: 'DisplayGroup';
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                    } | null;
                    readonly options: ReadonlyArray<{
                      readonly __typename?: 'ItemOptionModifier';
                      readonly _key: string | null;
                      readonly default: boolean | null;
                      readonly type: string | null;
                      readonly name: {
                        readonly __typename?: 'LocaleString';
                        readonly locale: string | null;
                      } | null;
                      readonly vendorConfigs: {
                        readonly __typename?: 'VendorConfigs';
                        readonly ncr: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly ncrDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partner: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly partnerDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumber: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly productNumberDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicom: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly sicomDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdi: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly qdiDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rpos: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly rposDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly simplyDeliveryDelivery: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                        readonly toshibaLoyalty: {
                          readonly __typename?: 'VendorConfig';
                          readonly pluType: string | null;
                          readonly parentSanityId: string | null;
                          readonly pullUpLevels: number | null;
                          readonly constantPlu: string | null;
                          readonly discountPlu: string | null;
                          readonly quantityBasedPlu: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly multiConstantPlus: ReadonlyArray<{
                            readonly __typename?: 'Plu';
                            readonly quantity: number | null;
                            readonly plu: string | null;
                            readonly qualifier: string | null;
                          } | null> | null;
                          readonly parentChildPlu: {
                            readonly __typename?: 'ParentChildPlu';
                            readonly plu: string | null;
                            readonly childPlu: string | null;
                          } | null;
                          readonly sizeBasedPlu: {
                            readonly __typename?: 'SizeBasedPlu';
                            readonly comboPlu: string | null;
                            readonly comboSize: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly pluConfigs: {
                        readonly __typename?: 'PluConfigs';
                        readonly _key: string | null;
                        readonly _type: string | null;
                        readonly partner: ReadonlyArray<{
                          readonly __typename?: 'PluConfig';
                          readonly _key: string | null;
                          readonly _type: string | null;
                          readonly serviceMode: string | null;
                          readonly posIntegration: {
                            readonly __typename?: 'PosIntegration';
                            readonly _id: string;
                            readonly _type: string;
                            readonly name: string | null;
                          } | null;
                          readonly vendorConfig: {
                            readonly __typename?: 'VendorConfig';
                            readonly pluType: string | null;
                            readonly parentSanityId: string | null;
                            readonly pullUpLevels: number | null;
                            readonly constantPlu: string | null;
                            readonly discountPlu: string | null;
                            readonly quantityBasedPlu: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly multiConstantPlus: ReadonlyArray<{
                              readonly __typename?: 'Plu';
                              readonly quantity: number | null;
                              readonly plu: string | null;
                              readonly qualifier: string | null;
                            } | null> | null;
                            readonly parentChildPlu: {
                              readonly __typename?: 'ParentChildPlu';
                              readonly plu: string | null;
                              readonly childPlu: string | null;
                            } | null;
                            readonly sizeBasedPlu: {
                              readonly __typename?: 'SizeBasedPlu';
                              readonly comboPlu: string | null;
                              readonly comboSize: string | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                      readonly modifierMultiplier: {
                        readonly __typename?: 'ModifierMultiplier';
                        readonly multiplier: number | null;
                        readonly prefix: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly modifier: {
                          readonly __typename?: 'Modifier';
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly image: {
                            readonly __typename?: 'Image';
                            readonly hotspot: {
                              readonly __typename?: 'SanityImageHotspot';
                              readonly x: number | null;
                              readonly y: number | null;
                              readonly height: number | null;
                              readonly width: number | null;
                            } | null;
                            readonly crop: {
                              readonly __typename?: 'SanityImageCrop';
                              readonly top: number | null;
                              readonly bottom: number | null;
                              readonly left: number | null;
                              readonly right: number | null;
                            } | null;
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly imageDescription: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                      } | null;
                      readonly nutrition: {
                        readonly __typename?: 'Nutrition';
                        readonly calories: number | null;
                        readonly caloriesPer100: number | null;
                        readonly carbohydrates: number | null;
                        readonly carbohydratesPer100: number | null;
                        readonly cholesterol: number | null;
                        readonly energyKJ: number | null;
                        readonly energyKJPer100: number | null;
                        readonly fat: number | null;
                        readonly fatPer100: number | null;
                        readonly fiber: number | null;
                        readonly proteins: number | null;
                        readonly proteinsPer100: number | null;
                        readonly salt: number | null;
                        readonly saltPer100: number | null;
                        readonly saturatedFat: number | null;
                        readonly saturatedFatPer100: number | null;
                        readonly sodium: number | null;
                        readonly sugar: number | null;
                        readonly sugarPer100: number | null;
                        readonly transFat: number | null;
                        readonly weight: number | null;
                      } | null;
                    } | null> | null;
                  } | null> | null;
                  readonly productHierarchy: {
                    readonly __typename?: 'ProductHierarchy';
                    readonly L1: string | null;
                    readonly L2: string | null;
                    readonly L3: string | null;
                    readonly L4: string | null;
                    readonly L5: string | null;
                  } | null;
                  readonly menuObjectSettings: {
                    readonly __typename?: 'MenuObjectSettings';
                    readonly limitPerOrder: number | null;
                  } | null;
                  readonly channelExclusions: {
                    readonly __typename?: 'ChannelExclusions';
                    readonly delivery: boolean | null;
                    readonly pickup: boolean | null;
                  } | null;
                }
              | {
                  readonly __typename?: 'NutritionalSection';
                  readonly name: {
                    readonly __typename?: 'LocaleString';
                    readonly locale: string | null;
                  } | null;
                  readonly products: ReadonlyArray<
                    | {
                        readonly __typename?: 'Item';
                        readonly _id: string;
                        readonly _type: string;
                        readonly rewardEligible: boolean | null;
                        readonly isDummyItem: boolean | null;
                        readonly labelAsPerPerson: boolean | null;
                        readonly productSize: string | null;
                        readonly name: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly description: {
                          readonly __typename?: 'LocaleBlockContent';
                          readonly localeRaw: any | null;
                        } | null;
                        readonly legalInformation: {
                          readonly __typename?: 'LocaleBlockContent';
                          readonly localeRaw: any | null;
                        } | null;
                        readonly image: {
                          readonly __typename?: 'Image';
                          readonly hotspot: {
                            readonly __typename?: 'SanityImageHotspot';
                            readonly x: number | null;
                            readonly y: number | null;
                            readonly height: number | null;
                            readonly width: number | null;
                          } | null;
                          readonly crop: {
                            readonly __typename?: 'SanityImageCrop';
                            readonly top: number | null;
                            readonly bottom: number | null;
                            readonly left: number | null;
                            readonly right: number | null;
                          } | null;
                          readonly asset: {
                            readonly __typename?: 'SanityImageAsset';
                            readonly _id: string;
                            readonly metadata: {
                              readonly __typename?: 'SanityImageMetadata';
                              readonly lqip: string | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly imageDescription: {
                          readonly __typename?: 'LocaleString';
                          readonly locale: string | null;
                        } | null;
                        readonly imagesByChannels: {
                          readonly __typename?: 'ImagesByChannels';
                          readonly imageRestaurant: {
                            readonly __typename?: 'Image';
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                          readonly imageDelivery: {
                            readonly __typename?: 'Image';
                            readonly asset: {
                              readonly __typename?: 'SanityImageAsset';
                              readonly _id: string;
                              readonly metadata: {
                                readonly __typename?: 'SanityImageMetadata';
                                readonly lqip: string | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                        readonly nutrition: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly additionalItemInformation: {
                          readonly __typename?: 'AdditionalItemInformation';
                          readonly sourcesOfGluten: string | null;
                          readonly ingredients: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly additives: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly producerDetails: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                        } | null;
                        readonly nutritionWithModifiers: {
                          readonly __typename?: 'Nutrition';
                          readonly calories: number | null;
                          readonly caloriesPer100: number | null;
                          readonly carbohydrates: number | null;
                          readonly carbohydratesPer100: number | null;
                          readonly cholesterol: number | null;
                          readonly energyKJ: number | null;
                          readonly energyKJPer100: number | null;
                          readonly fat: number | null;
                          readonly fatPer100: number | null;
                          readonly fiber: number | null;
                          readonly proteins: number | null;
                          readonly proteinsPer100: number | null;
                          readonly salt: number | null;
                          readonly saltPer100: number | null;
                          readonly saturatedFat: number | null;
                          readonly saturatedFatPer100: number | null;
                          readonly sodium: number | null;
                          readonly sugar: number | null;
                          readonly sugarPer100: number | null;
                          readonly transFat: number | null;
                          readonly weight: number | null;
                        } | null;
                        readonly allergens: {
                          readonly __typename?: 'OpAllergen';
                          readonly milk: number | null;
                          readonly eggs: number | null;
                          readonly fish: number | null;
                          readonly peanuts: number | null;
                          readonly shellfish: number | null;
                          readonly treeNuts: number | null;
                          readonly soy: number | null;
                          readonly wheat: number | null;
                          readonly mustard: number | null;
                          readonly sesame: number | null;
                          readonly celery: number | null;
                          readonly lupin: number | null;
                          readonly gluten: number | null;
                          readonly sulphurDioxide: number | null;
                        } | null;
                        readonly options: ReadonlyArray<{
                          readonly __typename?: 'ItemOption';
                          readonly componentStyle: string | null;
                          readonly upsellModifier: boolean | null;
                          readonly allowMultipleSelections: boolean | null;
                          readonly displayModifierMultiplierName: boolean | null;
                          readonly injectDefaultSelection: boolean | null;
                          readonly singleChoiceOnly: boolean | null;
                          readonly minAmount: number | null;
                          readonly maxAmount: number | null;
                          readonly _key: string | null;
                          readonly type: string | null;
                          readonly name: {
                            readonly __typename?: 'LocaleString';
                            readonly locale: string | null;
                          } | null;
                          readonly displayGroup: {
                            readonly __typename?: 'DisplayGroup';
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                          } | null;
                          readonly options: ReadonlyArray<{
                            readonly __typename?: 'ItemOptionModifier';
                            readonly _key: string | null;
                            readonly default: boolean | null;
                            readonly type: string | null;
                            readonly name: {
                              readonly __typename?: 'LocaleString';
                              readonly locale: string | null;
                            } | null;
                            readonly vendorConfigs: {
                              readonly __typename?: 'VendorConfigs';
                              readonly ncr: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly ncrDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partner: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly partnerDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumber: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly productNumberDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicom: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly sicomDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdi: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly qdiDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rpos: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly rposDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly simplyDeliveryDelivery: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                              readonly toshibaLoyalty: {
                                readonly __typename?: 'VendorConfig';
                                readonly pluType: string | null;
                                readonly parentSanityId: string | null;
                                readonly pullUpLevels: number | null;
                                readonly constantPlu: string | null;
                                readonly discountPlu: string | null;
                                readonly quantityBasedPlu: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly multiConstantPlus: ReadonlyArray<{
                                  readonly __typename?: 'Plu';
                                  readonly quantity: number | null;
                                  readonly plu: string | null;
                                  readonly qualifier: string | null;
                                } | null> | null;
                                readonly parentChildPlu: {
                                  readonly __typename?: 'ParentChildPlu';
                                  readonly plu: string | null;
                                  readonly childPlu: string | null;
                                } | null;
                                readonly sizeBasedPlu: {
                                  readonly __typename?: 'SizeBasedPlu';
                                  readonly comboPlu: string | null;
                                  readonly comboSize: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly pluConfigs: {
                              readonly __typename?: 'PluConfigs';
                              readonly _key: string | null;
                              readonly _type: string | null;
                              readonly partner: ReadonlyArray<{
                                readonly __typename?: 'PluConfig';
                                readonly _key: string | null;
                                readonly _type: string | null;
                                readonly serviceMode: string | null;
                                readonly posIntegration: {
                                  readonly __typename?: 'PosIntegration';
                                  readonly _id: string;
                                  readonly _type: string;
                                  readonly name: string | null;
                                } | null;
                                readonly vendorConfig: {
                                  readonly __typename?: 'VendorConfig';
                                  readonly pluType: string | null;
                                  readonly parentSanityId: string | null;
                                  readonly pullUpLevels: number | null;
                                  readonly constantPlu: string | null;
                                  readonly discountPlu: string | null;
                                  readonly quantityBasedPlu: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly multiConstantPlus: ReadonlyArray<{
                                    readonly __typename?: 'Plu';
                                    readonly quantity: number | null;
                                    readonly plu: string | null;
                                    readonly qualifier: string | null;
                                  } | null> | null;
                                  readonly parentChildPlu: {
                                    readonly __typename?: 'ParentChildPlu';
                                    readonly plu: string | null;
                                    readonly childPlu: string | null;
                                  } | null;
                                  readonly sizeBasedPlu: {
                                    readonly __typename?: 'SizeBasedPlu';
                                    readonly comboPlu: string | null;
                                    readonly comboSize: string | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            } | null;
                            readonly modifierMultiplier: {
                              readonly __typename?: 'ModifierMultiplier';
                              readonly multiplier: number | null;
                              readonly prefix: {
                                readonly __typename?: 'LocaleString';
                                readonly locale: string | null;
                              } | null;
                              readonly modifier: {
                                readonly __typename?: 'Modifier';
                                readonly name: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                                readonly image: {
                                  readonly __typename?: 'Image';
                                  readonly hotspot: {
                                    readonly __typename?: 'SanityImageHotspot';
                                    readonly x: number | null;
                                    readonly y: number | null;
                                    readonly height: number | null;
                                    readonly width: number | null;
                                  } | null;
                                  readonly crop: {
                                    readonly __typename?: 'SanityImageCrop';
                                    readonly top: number | null;
                                    readonly bottom: number | null;
                                    readonly left: number | null;
                                    readonly right: number | null;
                                  } | null;
                                  readonly asset: {
                                    readonly __typename?: 'SanityImageAsset';
                                    readonly _id: string;
                                    readonly metadata: {
                                      readonly __typename?: 'SanityImageMetadata';
                                      readonly lqip: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                readonly imageDescription: {
                                  readonly __typename?: 'LocaleString';
                                  readonly locale: string | null;
                                } | null;
                              } | null;
                            } | null;
                            readonly nutrition: {
                              readonly __typename?: 'Nutrition';
                              readonly calories: number | null;
                              readonly caloriesPer100: number | null;
                              readonly carbohydrates: number | null;
                              readonly carbohydratesPer100: number | null;
                              readonly cholesterol: number | null;
                              readonly energyKJ: number | null;
                              readonly energyKJPer100: number | null;
                              readonly fat: number | null;
                              readonly fatPer100: number | null;
                              readonly fiber: number | null;
                              readonly proteins: number | null;
                              readonly proteinsPer100: number | null;
                              readonly salt: number | null;
                              readonly saltPer100: number | null;
                              readonly saturatedFat: number | null;
                              readonly saturatedFatPer100: number | null;
                              readonly sodium: number | null;
                              readonly sugar: number | null;
                              readonly sugarPer100: number | null;
                              readonly transFat: number | null;
                              readonly weight: number | null;
                            } | null;
                          } | null> | null;
                        } | null> | null;
                        readonly productHierarchy: {
                          readonly __typename?: 'ProductHierarchy';
                          readonly L1: string | null;
                          readonly L2: string | null;
                          readonly L3: string | null;
                          readonly L4: string | null;
                          readonly L5: string | null;
                        } | null;
                        readonly menuObjectSettings: {
                          readonly __typename?: 'MenuObjectSettings';
                          readonly limitPerOrder: number | null;
                        } | null;
                        readonly channelExclusions: {
                          readonly __typename?: 'ChannelExclusions';
                          readonly delivery: boolean | null;
                          readonly pickup: boolean | null;
                        } | null;
                      }
                    | { readonly __typename?: 'NutritionalSection' }
                    | null
                  > | null;
                }
              | null
            > | null;
          } | null;
        }
      | {
          readonly __typename?: 'PreviewWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly previewContent: ReadonlyArray<{
            readonly __typename?: 'PreviewEntry';
            readonly linkURL: string | null;
            readonly previewImage: {
              readonly __typename?: 'LocaleImage';
              readonly locale: {
                readonly __typename?: 'Image';
                readonly hotspot: {
                  readonly __typename?: 'SanityImageHotspot';
                  readonly x: number | null;
                  readonly y: number | null;
                  readonly height: number | null;
                  readonly width: number | null;
                } | null;
                readonly crop: {
                  readonly __typename?: 'SanityImageCrop';
                  readonly top: number | null;
                  readonly bottom: number | null;
                  readonly left: number | null;
                  readonly right: number | null;
                } | null;
                readonly asset: {
                  readonly __typename?: 'SanityImageAsset';
                  readonly _id: string;
                  readonly metadata: {
                    readonly __typename?: 'SanityImageMetadata';
                    readonly lqip: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
            readonly titleText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly bodyText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly linkText: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly localizedLinkURL: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'QuoteWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly attributionName: string | null;
          readonly quoteText: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly attributionImage: {
            readonly __typename?: 'Image';
            readonly hotspot: {
              readonly __typename?: 'SanityImageHotspot';
              readonly x: number | null;
              readonly y: number | null;
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly crop: {
              readonly __typename?: 'SanityImageCrop';
              readonly top: number | null;
              readonly bottom: number | null;
              readonly left: number | null;
              readonly right: number | null;
            } | null;
            readonly asset: {
              readonly __typename?: 'SanityImageAsset';
              readonly _id: string;
              readonly metadata: {
                readonly __typename?: 'SanityImageMetadata';
                readonly lqip: string | null;
              } | null;
            } | null;
          } | null;
          readonly attributionTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | {
          readonly __typename?: 'RewardsCarouselWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly rewardsCarouselTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly rewardsCarouselDescription: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly rewardCarouselCategories: ReadonlyArray<{
            readonly __typename?: 'RewardCategory';
            readonly _id: string;
            readonly label: {
              readonly __typename?: 'LocaleString';
              readonly locale: string | null;
            } | null;
            readonly rewards: ReadonlyArray<{
              readonly __typename?: 'Reward';
              readonly _id: string;
              readonly name: {
                readonly __typename?: 'LocaleString';
                readonly locale: string | null;
              } | null;
              readonly image: {
                readonly __typename?: 'LocaleImage';
                readonly locale: {
                  readonly __typename?: 'Image';
                  readonly hotspot: {
                    readonly __typename?: 'SanityImageHotspot';
                    readonly x: number | null;
                    readonly y: number | null;
                    readonly height: number | null;
                    readonly width: number | null;
                  } | null;
                  readonly crop: {
                    readonly __typename?: 'SanityImageCrop';
                    readonly top: number | null;
                    readonly bottom: number | null;
                    readonly left: number | null;
                    readonly right: number | null;
                  } | null;
                  readonly asset: {
                    readonly __typename?: 'SanityImageAsset';
                    readonly _id: string;
                    readonly metadata: {
                      readonly __typename?: 'SanityImageMetadata';
                      readonly lqip: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
          } | null> | null;
        }
      | {
          readonly __typename?: 'VideoWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly attributionLink: string | null;
          readonly video: {
            readonly __typename?: 'LocaleFile';
            readonly locale: {
              readonly __typename?: 'File';
              readonly asset: {
                readonly __typename?: 'SanityFileAsset';
                readonly assetId: string | null;
                readonly path: string | null;
                readonly url: string | null;
              } | null;
            } | null;
          } | null;
          readonly caption: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
        }
      | { readonly __typename?: 'WebViewWidget' }
      | {
          readonly __typename?: 'YoutubeWidget';
          readonly _key: string | null;
          readonly _type: string | null;
          readonly videoId: string | null;
          readonly videoAutoplay: boolean | null;
        }
      | null
    > | null;
    readonly pageCSS: { readonly __typename?: 'Css'; readonly code: string | null } | null;
    readonly pageHtml: { readonly __typename?: 'Html'; readonly code: string | null } | null;
  } | null;
};

export type IGetSupportDataQueryVariables = Exact<{
  supportDataId: Scalars['ID']['input'];
}>;

export type IGetSupportDataQuery = {
  readonly __typename?: 'RootQuery';
  readonly SupportData: {
    readonly __typename?: 'SupportData';
    readonly _id: string;
    readonly supportCategoryGroups: ReadonlyArray<{
      readonly __typename?: 'SupportCategoryGroup';
      readonly _id: string;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly supportSubcategories: ReadonlyArray<{
        readonly __typename?: 'SupportSubcategory';
        readonly _id: string;
        readonly knowledgeForceIssueId: string | null;
        readonly supportSubcategoryForwardToOrders: boolean | null;
        readonly supportSubcategoryServiceModeSelector: boolean | null;
        readonly name: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly supportSubcategoryFaqs: ReadonlyArray<{
          readonly __typename?: 'SupportFaq';
          readonly supportFaqMParticleEvent: string | null;
          readonly supportFaqTitle: {
            readonly __typename?: 'LocaleString';
            readonly locale: string | null;
          } | null;
          readonly supportFaqContent: {
            readonly __typename?: 'LocaleBlockContent';
            readonly localeRaw: any | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    readonly deliveryNeverArriveSubcategory: {
      readonly __typename?: 'SupportSubcategory';
      readonly _id: string;
      readonly knowledgeForceIssueId: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly deliveryMissingItemsSubcategory: {
      readonly __typename?: 'SupportSubcategory';
      readonly _id: string;
      readonly knowledgeForceIssueId: string | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
    } | null;
    readonly orderingIssuesCategoryGroup: {
      readonly __typename?: 'SupportCategoryGroup';
      readonly _id: string;
    } | null;
    readonly supportDataFaqs: ReadonlyArray<{
      readonly __typename?: 'SupportFaq';
      readonly supportFaqMParticleEvent: string | null;
      readonly supportFaqTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly supportFaqContent: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly supportPolicyCover: ReadonlyArray<{
      readonly __typename?: 'PrivacyPolicyDisclaimersCell';
      readonly disclaimerTitle: string | null;
      readonly disclaimerDescription: string | null;
      readonly border: boolean | null;
      readonly fontSize: number | null;
      readonly paddingTop: number | null;
      readonly paddingRight: number | null;
      readonly paddingBottom: number | null;
      readonly paddingLeft: number | null;
      readonly radius: number | null;
      readonly disclaimerContent: {
        readonly __typename?: 'LocaleBlockContent';
        readonly locale: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IGetSupportSubcategoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetSupportSubcategoryQuery = {
  readonly __typename?: 'RootQuery';
  readonly SupportSubcategory: {
    readonly __typename?: 'SupportSubcategory';
    readonly _id: string;
    readonly _type: string;
    readonly _createdAt: any;
    readonly _key: string | null;
    readonly _rev: string;
    readonly _updatedAt: any;
    readonly knowledgeForceIssueId: string | null;
    readonly directTicketProcessingPercentage: number | null;
    readonly supportSubcategoryForwardToOrders: boolean | null;
    readonly supportSubcategoryServiceModeSelector: boolean | null;
    readonly supportSubcategorySubmitToSutherland: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly supportSubcategoryFaqs: ReadonlyArray<{
      readonly __typename?: 'SupportFaq';
      readonly supportFaqMParticleEvent: string | null;
      readonly supportFaqTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly supportFaqContent: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null> | null;
    readonly supportSubcategoryFaq: {
      readonly __typename?: 'SupportFaq';
      readonly supportFaqMParticleEvent: string | null;
      readonly supportFaqTitle: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly supportFaqContent: {
        readonly __typename?: 'LocaleBlockContent';
        readonly localeRaw: any | null;
      } | null;
    } | null;
  } | null;
};

export type IGetSupportCategoryGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetSupportCategoryGroupQuery = {
  readonly __typename?: 'RootQuery';
  readonly SupportCategoryGroup: {
    readonly __typename?: 'SupportCategoryGroup';
    readonly _id: string;
    readonly showRestaurantSelector: boolean | null;
    readonly name: { readonly __typename?: 'LocaleString'; readonly locale: string | null } | null;
    readonly supportSubcategories: ReadonlyArray<{
      readonly __typename?: 'SupportSubcategory';
      readonly _id: string;
      readonly knowledgeForceIssueId: string | null;
      readonly supportSubcategoryForwardToOrders: boolean | null;
      readonly supportSubcategoryServiceModeSelector: boolean | null;
      readonly name: {
        readonly __typename?: 'LocaleString';
        readonly locale: string | null;
      } | null;
      readonly supportSubcategoryFaqs: ReadonlyArray<{
        readonly __typename?: 'SupportFaq';
        readonly supportFaqMParticleEvent: string | null;
        readonly supportFaqTitle: {
          readonly __typename?: 'LocaleString';
          readonly locale: string | null;
        } | null;
        readonly supportFaqContent: {
          readonly __typename?: 'LocaleBlockContent';
          readonly localeRaw: any | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const LoyaltyPointsFragmentDoc = gql`
  fragment LoyaltyPoints on LoyaltyPoints {
    ruleSetType: _type
    points
  }
`;
export const TierListFragmentDoc = gql`
  fragment TierList on TierList {
    ruleSetType: _type
    tierList
  }
`;
export const ImageFragmentDoc = gql`
  fragment ImageFragment on Image {
    hotspot {
      x
      y
      height
      width
    }
    crop {
      top
      bottom
      left
      right
    }
    asset {
      metadata {
        lqip
      }
      _id
    }
  }
`;
export const ColorFragmentDoc = gql`
  fragment ColorFragment on Color {
    alpha
    hex
    hsl {
      _type
      a
      h
      l
      s
    }
    hsv {
      _type
      a
      h
      s
      v
    }
    rgb {
      _type
      a
      b
      g
      r
    }
  }
`;
export const ActiveCampaignFragmentDoc = gql`
  fragment ActiveCampaignFragment on FeatureAuthCampaign {
    campaignId
    campaignImage {
      locale: en {
        ...ImageFragment
      }
    }
    campaignImageAltText {
      locale: en
    }
    campaingBackgroundColor {
      ...ColorFragment
    }
    campaignText {
      localeRaw: enRaw
    }
    shouldDisplayExtraFields
  }
  ${ImageFragmentDoc}
  ${ColorFragmentDoc}
`;
export const MenuImageFragmentDoc = gql`
  fragment MenuImageFragment on Image {
    hotspot {
      x
      y
      height
      width
    }
    crop {
      top
      bottom
      left
      right
    }
    asset {
      metadata {
        lqip
      }
      _id
    }
  }
`;
export const ImagesByChannelsFragmentDoc = gql`
  fragment ImagesByChannelsFragment on ImagesByChannels {
    imageRestaurant {
      asset {
        _id
        metadata {
          lqip
        }
      }
    }
    imageDelivery {
      asset {
        _id
        metadata {
          lqip
        }
      }
    }
  }
`;
export const NutritionFragmentDoc = gql`
  fragment NutritionFragment on Nutrition {
    calories
    caloriesPer100
    carbohydrates
    carbohydratesPer100
    cholesterol
    energyKJ
    energyKJPer100
    fat
    fatPer100
    fiber
    proteins
    proteinsPer100
    salt
    saltPer100
    saturatedFat
    saturatedFatPer100
    sodium
    sugar
    sugarPer100
    transFat
    weight
  }
`;
export const AdditionalItemInformationFragmentDoc = gql`
  fragment AdditionalItemInformationFragment on AdditionalItemInformation {
    ingredients {
      locale: en
    }
    additives {
      locale: en
    }
    producerDetails {
      locale: en
    }
    sourcesOfGluten
  }
`;
export const AllergensFragmentDoc = gql`
  fragment AllergensFragment on OpAllergen {
    milk
    eggs
    fish
    peanuts
    shellfish
    treeNuts
    soy
    wheat
    mustard
    sesame
    celery
    lupin
    gluten
    sulphurDioxide
  }
`;
export const VendorConfigFragmentDoc = gql`
  fragment VendorConfigFragment on VendorConfig {
    pluType
    parentSanityId
    pullUpLevels
    constantPlu
    discountPlu
    quantityBasedPlu {
      quantity
      plu
      qualifier
    }
    multiConstantPlus {
      quantity
      plu
      qualifier
    }
    parentChildPlu {
      plu
      childPlu
    }
    sizeBasedPlu {
      comboPlu
      comboSize
    }
  }
`;
export const VendorConfigsFragmentDoc = gql`
  fragment VendorConfigsFragment on VendorConfigs {
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    partner {
      ...VendorConfigFragment
    }
    partnerDelivery {
      ...VendorConfigFragment
    }
    productNumber {
      ...VendorConfigFragment
    }
    productNumberDelivery {
      ...VendorConfigFragment
    }
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    qdi {
      ...VendorConfigFragment
    }
    qdiDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
    simplyDelivery {
      ...VendorConfigFragment
    }
    simplyDeliveryDelivery {
      ...VendorConfigFragment
    }
    toshibaLoyalty {
      ...VendorConfigFragment
    }
  }
  ${VendorConfigFragmentDoc}
`;
export const PluConfigFragmentDoc = gql`
  fragment PluConfigFragment on PluConfig {
    _key
    _type
    posIntegration {
      _id
      _type
      name
    }
    serviceMode
    vendorConfig {
      ...VendorConfigFragment
    }
  }
  ${VendorConfigFragmentDoc}
`;
export const PluConfigsFragmentDoc = gql`
  fragment PluConfigsFragment on PluConfigs {
    _key
    _type
    partner {
      ...PluConfigFragment
    }
  }
  ${PluConfigFragmentDoc}
`;
export const ItemOptionFragmentDoc = gql`
  fragment ItemOptionFragment on ItemOption {
    name {
      locale: en
    }
    displayGroup {
      name {
        locale: en
      }
    }
    componentStyle
    upsellModifier
    allowMultipleSelections
    displayModifierMultiplierName
    injectDefaultSelection
    singleChoiceOnly
    minAmount
    maxAmount
    _key
    type: _type
    options {
      _key
      type: _type
      name {
        locale: en
      }
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
      default
      modifierMultiplier {
        multiplier
        prefix {
          locale: en
        }
        modifier {
          name {
            locale: en
          }
          image {
            ...MenuImageFragment
          }
          imageDescription {
            locale: en
          }
        }
      }
      nutrition {
        ...NutritionFragment
      }
    }
  }
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${MenuImageFragmentDoc}
  ${NutritionFragmentDoc}
`;
export const ItemFragmentDoc = gql`
  fragment ItemFragment on Item {
    _id
    _type
    name {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    legalInformation {
      localeRaw: enRaw
    }
    image {
      ...MenuImageFragment
    }
    imageDescription {
      locale: en
    }
    imagesByChannels {
      ...ImagesByChannelsFragment
    }
    rewardEligible
    isDummyItem
    labelAsPerPerson
    nutrition {
      ...NutritionFragment
    }
    additionalItemInformation {
      ...AdditionalItemInformationFragment
    }
    nutritionWithModifiers {
      ...NutritionFragment
    }
    productSize
    allergens {
      ...AllergensFragment
    }
    options {
      ...ItemOptionFragment
    }
    productHierarchy {
      L1
      L2
      L3
      L4
      L5
    }
    menuObjectSettings {
      limitPerOrder
    }
    channelExclusions {
      delivery
      pickup
    }
  }
  ${MenuImageFragmentDoc}
  ${ImagesByChannelsFragmentDoc}
  ${NutritionFragmentDoc}
  ${AdditionalItemInformationFragmentDoc}
  ${AllergensFragmentDoc}
  ${ItemOptionFragmentDoc}
`;
export const ItemOptionAvailabilityFragmentDoc = gql`
  fragment ItemOptionAvailabilityFragment on ItemOption {
    injectDefaultSelection
    options {
      default
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
      modifierMultiplier {
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
        modifier {
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
      }
    }
  }
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const ItemAvailabilityFragmentDoc = gql`
  fragment ItemAvailabilityFragment on Item {
    operationalItem {
      daypart
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
    showInStaticMenu
    hideCalories
    hideNutritionLegalDisclaimer
    itemSize
    itemUnit
    options {
      ...ItemOptionAvailabilityFragment
    }
  }
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${ItemOptionAvailabilityFragmentDoc}
`;
export const AddOnSectionItemAccepetingFreeFragmentDoc = gql`
  fragment AddOnSectionItemAccepetingFreeFragment on AddOnSectionItemAcceptingFreeOffer {
    _key
    maxFreeQuantity
    itemAcceptingFreeOffer {
      ... on Item {
        _id
        name {
          locale: en
        }
      }
      ... on Combo {
        _id
        name {
          locale: en
        }
      }
    }
  }
`;
export const ImagesFragmentDoc = gql`
  fragment ImagesFragment on Images {
    app {
      ...ImageFragment
    }
    imageDescription
  }
  ${ImageFragmentDoc}
`;
export const AddOnSectionFreeModalInfosFragmentDoc = gql`
  fragment AddOnSectionFreeModalInfosFragment on AddOnSectionFreeModalFieldset {
    showFreeAddOnModal
    image {
      locale: en {
        ...ImagesFragment
      }
    }
    title {
      locale: en
    }
    description {
      locale: en
    }
    primaryButtonText {
      locale: en
    }
  }
  ${ImagesFragmentDoc}
`;
export const AddOnSectionOptionFragmentDoc = gql`
  fragment AddOnSectionOptionFragment on AddOnSectionOption {
    _key
    maxAmount
    option {
      ...ItemFragment
      ...ItemAvailabilityFragment
    }
    freeOption {
      ...ItemFragment
      ...ItemAvailabilityFragment
    }
    itemsAcceptingFreeOffers {
      ...AddOnSectionItemAccepetingFreeFragment
    }
    addOnSectionFreeModalInfos {
      ...AddOnSectionFreeModalInfosFragment
    }
  }
  ${ItemFragmentDoc}
  ${ItemAvailabilityFragmentDoc}
  ${AddOnSectionItemAccepetingFreeFragmentDoc}
  ${AddOnSectionFreeModalInfosFragmentDoc}
`;
export const AddOnSectionServiceModesFragmentDoc = gql`
  fragment AddOnSectionServiceModesFragment on AddOnSectionServiceModes {
    pickUpServiceMode
    driveThruServiceMode
    curbsideServiceMode
    dineInServiceMode
    tableServiceMode
    deliveryServiceMode
    cateringDeliveryServiceMode
    cateringPickUpServiceMode
  }
`;
export const AddOnSectionFragmentDoc = gql`
  fragment AddOnSectionFragment on AddOnSection {
    _id
    _key
    name {
      locale: en
    }
    maxAmount
    options {
      ...AddOnSectionOptionFragment
    }
    showSectionItemsOnCart
    sectionItemsServiceModes {
      ...AddOnSectionServiceModesFragment
    }
    enableAddonAsFreeItem
  }
  ${AddOnSectionOptionFragmentDoc}
  ${AddOnSectionServiceModesFragmentDoc}
`;
export const AggregatorRedirectButtonFragmentDoc = gql`
  fragment AggregatorRedirectButtonFragment on AggregatorRedirectButton {
    isActive
    name
    url
    text {
      locale: en
    }
  }
`;
export const RefFragmentDoc = gql`
  fragment ref on Image {
    asset {
      _id
    }
  }
`;
export const MarketingTileBasicFragmentDoc = gql`
  fragment MarketingTileBasicFragment on MarketingTileBasic {
    _id
    marketingBasicTileDescription {
      localeRaw: enRaw
    }
    marketingBasicTileImage {
      locale: en {
        ...ImagesFragment
      }
    }
    marketingBasicTileTitle {
      locale: en
    }
    link {
      locale: en
    }
    linkLabel {
      locale: en
    }
  }
  ${ImagesFragmentDoc}
`;
export const MarketingTileListFragmentDoc = gql`
  fragment MarketingTileListFragment on MarketingTileList {
    __typename
    _type
    tiles {
      ...MarketingTileBasicFragment
    }
  }
  ${MarketingTileBasicFragmentDoc}
`;
export const EmptyStateFragmentDoc = gql`
  fragment EmptyStateFragment on EmptyState {
    _id
    headerText {
      locale: en
    }
    logo {
      locale: en {
        ...ImagesFragment
      }
    }
    backgroundImage {
      image {
        ...ref
      }
    }
    marketingTileList {
      ...MarketingTileListFragment
    }
    image {
      locale: en {
        ...ImagesFragment
      }
    }
    title {
      locale: en
    }
    subtitle {
      locale: en
    }
    description {
      locale: enRaw
    }
    buttonAction {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    terms {
      locale: enRaw
    }
  }
  ${ImagesFragmentDoc}
  ${RefFragmentDoc}
  ${MarketingTileListFragmentDoc}
`;
export const HeroMaskCardFragmentDoc = gql`
  fragment HeroMaskCard on SimpleCard {
    _key
    title {
      locale: en
    }
    description {
      locale: en
    }
    image {
      ...ImageFragment
    }
  }
  ${ImageFragmentDoc}
`;
export const MainHeroFragmentDoc = gql`
  fragment MainHeroFragment on MainHero {
    _id
    image {
      locale: en {
        ...ImageFragment
      }
    }
    mobileImage {
      locale: en {
        ...ImageFragment
      }
    }
    superText {
      locale: en
    }
    mainText {
      locale: en
    }
    subText {
      locale: en
    }
    textColor
    action {
      route
      actionUrl {
        locale: en
      }
      actionText {
        locale: en
      }
    }
    actionRight {
      route
      actionUrl {
        locale: en
      }
      actionText {
        locale: en
      }
    }
    actionRightIcon {
      locale: en {
        ...ImageFragment
      }
    }
    actionLeft {
      route
      actionUrl {
        locale: en
      }
      actionText {
        locale: en
      }
    }
    actionLeftIcon {
      locale: en {
        ...ImageFragment
      }
    }
    maskCards {
      ...HeroMaskCard
    }
    loyaltyAction {
      route
      actionText {
        locale: en
      }
    }
  }
  ${ImageFragmentDoc}
  ${HeroMaskCardFragmentDoc}
`;
export const BrazeContentCardsFragmentDoc = gql`
  fragment BrazeContentCardsFragment on BrazeContentCards {
    _id
    contentCardsHeader: sectionheader {
      locale: en
    }
    contentCardsSubheader: sectionSubheader {
      locale: en
    }
  }
`;
export const AlertFrangmentFragmentDoc = gql`
  fragment AlertFrangment on Alert {
    _id
    heading {
      locale: en
    }
    body {
      locale: en
    }
    learnMoreText {
      locale: en
    }
    learnMoreUrl {
      locale: en
    }
  }
`;
export const MarketingTileFragmentDoc = gql`
  fragment MarketingTileFragment on MarketingTile {
    _id
    textAlignment
    backgroundImage {
      locale: en {
        ...ImagesFragment
      }
    }
    buttonAction {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    buttonAlignment
    textColor
    textBlock {
      localeRaw: enRaw
    }
    lockedAspectRatio
    termsText {
      localeRaw: enRaw
    }
    termsButton {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
    }
    ruleSet {
      dayOfWeek {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      betweenTimes {
        startTime
        endTime
      }
      BKPayAudience
      bkSpecificTargeting
    }
  }
  ${ImagesFragmentDoc}
`;
export const MarketingTileGroupFragmentDoc = gql`
  fragment MarketingTileGroupFragment on MarketingTileGroup {
    _id
    tiles {
      ... on MarketingTile {
        ...MarketingTileFragment
      }
      ... on MarketingTileBasic {
        ...MarketingTileBasicFragment
      }
      ... on MarketingTileList {
        ...MarketingTileListFragment
      }
      ... on MarketingTilePair {
        left {
          ...MarketingTileFragment
        }
        right {
          ...MarketingTileFragment
        }
      }
      ... on MarketingTileQuad {
        topLeftTile {
          ...MarketingTileBasicFragment
        }
        topRightTile {
          ...MarketingTileBasicFragment
        }
        bottomLeftTile {
          ...MarketingTileBasicFragment
        }
        bottomRightTile {
          ...MarketingTileBasicFragment
        }
      }
    }
  }
  ${MarketingTileFragmentDoc}
  ${MarketingTileBasicFragmentDoc}
  ${MarketingTileListFragmentDoc}
`;
export const TrendingFragmentDoc = gql`
  fragment TrendingFragment on Trending {
    _id
    promotions {
      ... on Picker {
        _id
        _type
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        image {
          ...MenuImageFragment
        }
        imageDescription {
          locale: en
        }
      }
      ... on Item {
        _id
        _type
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        image {
          ...MenuImageFragment
        }
        imageDescription {
          locale: en
        }
      }
      ... on Combo {
        _id
        _type
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        image {
          ...MenuImageFragment
        }
        imageDescription {
          locale: en
        }
      }
      ... on Section {
        _id
        _type
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        image {
          ...ImageFragment
        }
        imageDescription {
          locale: en
        }
      }
      ... on Offer {
        _id
        _type
      }
      ... on ExternalLink {
        _key
        type: _type
        name {
          locale: en
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
        imageDescription {
          locale: en
        }
        description {
          locale: en
        }
        link {
          locale: en
        }
        url
      }
    }
  }
  ${MenuImageFragmentDoc}
  ${ImageFragmentDoc}
`;
export const FanFavoritesFragmentDoc = gql`
  fragment FanFavoritesFragment on FanFavorites {
    _id
    fanFavoriteItems {
      ... on Picker {
        _id
        image {
          ...MenuImageFragment
        }
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        imageDescription {
          locale: en
        }
      }
      ... on Item {
        _id
        image {
          ...MenuImageFragment
        }
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        imageDescription {
          locale: en
        }
      }
      ... on Combo {
        _id
        image {
          ...MenuImageFragment
        }
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
      }
      ... on Section {
        _id
        image {
          ...MenuImageFragment
        }
        name {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        imageDescription {
          locale: en
        }
      }
    }
    favoriteSection {
      headerText {
        locale: en
      }
    }
  }
  ${MenuImageFragmentDoc}
`;
export const OurServicesFragmentDoc = gql`
  fragment OurServicesFragment on OurServices {
    _id
    servicesHeaderTextLocale {
      locale: en
    }
    servicesHeroTextLocale {
      locale: en
    }
    ourServicesHeroImageLocale {
      locale: en {
        ...ImageFragment
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const AppDownloadFragmentDoc = gql`
  fragment AppDownloadFragment on AppDownload {
    _id
    details {
      backgroundImage {
        ...ImageFragment
      }
      primaryImage {
        locale: en {
          ...ImageFragment
        }
      }
      headerSuperText {
        locale: en
      }
      header {
        locale: en
      }
      copy {
        locale: en
      }
      textDecorationLeft {
        ...ImageFragment
      }
      textDecorationRight {
        ...ImageFragment
      }
      submitButtonText {
        locale: en
      }
      iOSDownloadLink
      iOSDownloadImage {
        locale: en {
          ...ImageFragment
        }
      }
      androidDownloadLink
      androidDownloadImage {
        locale: en {
          ...ImageFragment
        }
      }
      huaweiDownloadLink
      huaweiDownloadImage {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const CateringPageConfigurationFragmentDoc = gql`
  fragment CateringPageConfigurationFragment on CateringPageConfiguration {
    components {
      ... on MainHero {
        ...MainHeroFragment
      }
      ... on BrazeContentCards {
        ...BrazeContentCardsFragment
      }
      ... on Alert {
        ...AlertFrangment
      }
      ... on MarketingTileGroup {
        ...MarketingTileGroupFragment
      }
      ... on OffersSection {
        _id
      }
      ... on Trending {
        ...TrendingFragment
      }
      ... on FanFavorites {
        ...FanFavoritesFragment
      }
      ... on OurServices {
        ...OurServicesFragment
      }
      ... on AppDownload {
        ...AppDownloadFragment
      }
      ... on RecentItems {
        _id
      }
      ... on LockedOffers {
        _id
      }
    }
  }
  ${MainHeroFragmentDoc}
  ${BrazeContentCardsFragmentDoc}
  ${AlertFrangmentFragmentDoc}
  ${MarketingTileGroupFragmentDoc}
  ${TrendingFragmentDoc}
  ${FanFavoritesFragmentDoc}
  ${OurServicesFragmentDoc}
  ${AppDownloadFragmentDoc}
`;
export const HomePageConfigurationFragmentDoc = gql`
  fragment HomePageConfigurationFragment on HomePageConfiguration {
    components {
      ... on MainHero {
        _id
        __typename
      }
      ... on Hero {
        _id
        __typename
      }
      ... on BrazeContentCards {
        _id
        __typename
      }
      ... on Alert {
        _id
        __typename
      }
      ... on MarketingTileGroup {
        _id
        __typename
      }
      ... on MarketingTileCarousel {
        _id
        __typename
      }
      ... on MarketingCardGroup {
        _id
        __typename
      }
      ... on OffersSection {
        _id
        __typename
      }
      ... on Trending {
        _id
        __typename
      }
      ... on FanFavorites {
        _id
        __typename
      }
      ... on OurServices {
        _id
        __typename
      }
      ... on AppDownload {
        _id
        __typename
      }
      ... on RecentItems {
        _id
        __typename
      }
      ... on RewardsSection {
        _id
        __typename
      }
      ... on OfferCarouselSection {
        _id
        __typename
      }
    }
  }
`;
export const LinkWidgetFragmentDoc = gql`
  fragment LinkWidgetFragment on LinkWidget {
    _key
    _type
    text {
      locale: en
    }
    link {
      locale: en
    }
  }
`;
export const NavigationLinkFragmentDoc = gql`
  fragment NavigationLinkFragment on NavigationDesktopLink {
    _key
    hideLinkBasedOnLdFlag {
      launchDarklyFlag
    }
    navLink {
      ... on LinkWidget {
        ...LinkWidgetFragment
      }
    }
    launchDarklyAlternateNavigationLink {
      enabled
      launchDarklyFlagDependency {
        launchDarklyFlag
      }
      navLink {
        ... on LinkWidget {
          ...LinkWidgetFragment
        }
      }
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const NavigationLinksFragmentDoc = gql`
  fragment NavigationLinksFragment on NavigationDesktop {
    navigationLinks {
      ...NavigationLinkFragment
    }
    navigationLinksWithServiceModeOrStoreSelected {
      ...NavigationLinkFragment
    }
  }
  ${NavigationLinkFragmentDoc}
`;
export const NavigationSectionFragmentDoc = gql`
  fragment NavigationSectionFragment on NavigationSection {
    name {
      locale: en
    }
    pages {
      ... on StaticPage {
        title
        localeTitle {
          locale: en
        }
        path {
          current
        }
        localePath {
          locale: en {
            current
          }
        }
        redirectUrl {
          locale: en
        }
      }
      ... on NavigationSection {
        name {
          locale: en
        }
        pages {
          ... on StaticPage {
            title
            localeTitle {
              locale: en
            }
            path {
              current
            }
            localePath {
              locale: en {
                current
              }
            }
            redirectUrl {
              locale: en
            }
          }
        }
      }
    }
  }
`;
export const AboutMenuEntriesDesktopFragmentDoc = gql`
  fragment AboutMenuEntriesDesktopFragment on NavigationDesktop {
    aboutMenuEntries {
      ...NavigationSectionFragment
    }
  }
  ${NavigationSectionFragmentDoc}
`;
export const NavigationDesktopFragmentDoc = gql`
  fragment NavigationDesktopFragment on FeatureNavigation {
    navigationDesktop {
      _key
      _type
      ...NavigationLinksFragment
      ...AboutMenuEntriesDesktopFragment
    }
  }
  ${NavigationLinksFragmentDoc}
  ${AboutMenuEntriesDesktopFragmentDoc}
`;
export const NavigationTabFragmentDoc = gql`
  fragment NavigationTabFragment on NavigationMobileTab {
    _key
    _type
    hideLinkBasedOnLdFlag {
      launchDarklyFlag
    }
    tabIcon {
      locale: en {
        ...ImageFragment
      }
    }
    tabIconActive {
      locale: en {
        ...ImageFragment
      }
    }
    tabLink {
      ... on LinkWidget {
        ...LinkWidgetFragment
      }
    }
    launchDarklyAlternateMobileTab {
      _key
      _type
      enabled
      launchDarklyFlagDependency {
        launchDarklyFlag
      }
      tabIcon {
        locale: en {
          ...ImageFragment
        }
      }
      tabIconActive {
        locale: en {
          ...ImageFragment
        }
      }
      tabLink {
        ... on LinkWidget {
          ...LinkWidgetFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${LinkWidgetFragmentDoc}
`;
export const NavigationTabsFragmentDoc = gql`
  fragment NavigationTabsFragment on NavigationMobile {
    navigationTabs {
      ...NavigationTabFragment
    }
    navigationTabsWithServiceModeSelected {
      ...NavigationTabFragment
    }
  }
  ${NavigationTabFragmentDoc}
`;
export const AboutMenuEntriesMobileFragmentDoc = gql`
  fragment AboutMenuEntriesMobileFragment on NavigationMobile {
    aboutMenuEntries {
      ...NavigationSectionFragment
    }
  }
  ${NavigationSectionFragmentDoc}
`;
export const NavigationMobileFragmentDoc = gql`
  fragment NavigationMobileFragment on FeatureNavigation {
    navigationMobile {
      _key
      _type
      ...NavigationTabsFragment
      ...AboutMenuEntriesMobileFragment
    }
  }
  ${NavigationTabsFragmentDoc}
  ${AboutMenuEntriesMobileFragmentDoc}
`;
export const NavigationButtonFragmentDoc = gql`
  fragment NavigationButtonFragment on FeatureNavigation {
    navigationMobileHeaderButton {
      _key
      icon {
        locale: en {
          ...ImageFragment
        }
      }
      iconActive {
        locale: en {
          ...ImageFragment
        }
      }
      link {
        locale: en
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const NutritionItemFragmentDoc = gql`
  fragment NutritionItemFragment on NutritionItem {
    _type
    additionalItems {
      ... on NutritionItem {
        name {
          locale: en
        }
        nutritionKey
        omitUnitFromValue
        unit {
          locale: en
        }
      }
    }
    name {
      locale: en
    }
    nutritionKey
    omitUnitFromValue
    unit {
      locale: en
    }
  }
`;
export const NutritionItemGroupFragmentDoc = gql`
  fragment NutritionItemGroupFragment on NutritionItemGroup {
    _key
    _type
    mainItem {
      ...NutritionItemFragment
    }
    lineItems {
      ...NutritionItemFragment
    }
  }
  ${NutritionItemFragmentDoc}
`;
export const NutritionTableFragmentDoc = gql`
  fragment NutritionTableFragment on NutritionTable {
    _key
    _type
    title {
      locale: en
    }
    items {
      ... on NutritionItem {
        ...NutritionItemFragment
      }
      ... on NutritionItemGroup {
        ...NutritionItemGroupFragment
      }
    }
  }
  ${NutritionItemFragmentDoc}
  ${NutritionItemGroupFragmentDoc}
`;
export const RoundingRulesFragmentDoc = gql`
  fragment RoundingRulesFragment on RoundingRules {
    enabled
    decimalPlaces
    roundingType
  }
`;
export const OffersPageMarketingTileFragmentDoc = gql`
  fragment OffersPageMarketingTileFragment on OffersPageMarketingTile {
    marketingTileReference {
      ...MarketingTileFragment
    }
    displayPosition
    customPosition
  }
  ${MarketingTileFragmentDoc}
`;
export const ItemUnavailableModalFragmentDoc = gql`
  fragment itemUnavailableModalFragment on FeatureStaticMenu {
    itemUnavailableModal {
      _key
      _type
      header {
        locale: en
      }
      body {
        locale: en
      }
      continue {
        locale: en
      }
      selectNew {
        locale: en
      }
      itemNotAvailableForDelivery {
        locale: en
      }
      image {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const CopyrightAndLanguageSelectorFragmentDoc = gql`
  fragment CopyrightAndLanguageSelectorFragment on CopyrightAndLanguageSelector {
    _type
    copyrightText {
      locale: en
    }
  }
`;
export const HighlightedLinkWidgetFragmentDoc = gql`
  fragment HighlightedLinkWidgetFragment on HighlightedLinkWidget {
    _key
    _type
    text {
      locale: en
    }
    link {
      locale: en
    }
  }
`;
export const HighlightedVerticalLinkListWidgetFragmentDoc = gql`
  fragment HighlightedVerticalLinkListWidgetFragment on HighlightedVerticalLinkListWidget {
    _type
    header {
      locale: en
    }
    subheader {
      locale: en
    }
    highlightedLinks {
      ...HighlightedLinkWidgetFragment
    }
  }
  ${HighlightedLinkWidgetFragmentDoc}
`;
export const HorizontalLinkListWidgetFragmentDoc = gql`
  fragment HorizontalLinkListWidgetFragment on HorizontalLinkListWidget {
    _type
    linkWidgets {
      ...LinkWidgetFragment
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const ImageWidgetFragmentDoc = gql`
  fragment ImageWidgetFragment on ImageWidget {
    _type
    image {
      locale: en {
        ...ImageFragment
      }
    }
    imageDescription {
      locale: en
    }
    attributionLink
  }
  ${ImageFragmentDoc}
`;
export const SocialIconsWidgetFragmentDoc = gql`
  fragment SocialIconsWidgetFragment on SocialIconsWidget {
    _type
    instagramUrl {
      locale: en
    }
    facebookUrl {
      locale: en
    }
    youtubeUrl {
      locale: en
    }
    twitterUrl {
      locale: en
    }
    tiktokUrl {
      locale: en
    }
    linkedinUrl {
      locale: en
    }
    snapchatUrl {
      locale: en
    }
  }
`;
export const SocialIconsWithHeaderWidgetFragmentDoc = gql`
  fragment SocialIconsWithHeaderWidgetFragment on SocialIconsWithHeaderWidget {
    _type
    header {
      locale: en
    }
    socialIconsWidget {
      ...SocialIconsWidgetFragment
    }
  }
  ${SocialIconsWidgetFragmentDoc}
`;
export const TextWidgetFragmentDoc = gql`
  fragment TextWidgetFragment on TextWidget {
    _type
    text {
      locale: en
    }
  }
`;
export const TextWidgetWithUrlFragmentDoc = gql`
  fragment TextWidgetWithUrlFragment on TextWidgetWithUrl {
    _type
    text {
      locale: en
    }
    textUrl {
      locale: en
    }
    url {
      locale: en
    }
  }
`;
export const VerticalLinkListWithHeaderWidgetFragmentDoc = gql`
  fragment VerticalLinkListWithHeaderWidgetFragment on VerticalLinkListWithHeaderWidget {
    _type
    header {
      locale: en
    }
    linkWidgets {
      ...LinkWidgetFragment
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const VerticalLinkListWidgetFragmentDoc = gql`
  fragment VerticalLinkListWidgetFragment on VerticalLinkListWidget {
    _type
    linkWidgets {
      ...LinkWidgetFragment
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const WordmarkAndSocialIconsFragmentDoc = gql`
  fragment WordmarkAndSocialIconsFragment on WordmarkAndSocialIcons {
    _type
    socialIconsWidget {
      ...SocialIconsWidgetFragment
    }
  }
  ${SocialIconsWidgetFragmentDoc}
`;
export const HeroContentFragmentDoc = gql`
  fragment HeroContent on AppData {
    _id
    mainHeroImageAuthenticatedLocale {
      locale: en {
        ...ImageFragment
      }
    }
    mainHeroImageLocale {
      locale: en {
        ...ImageFragment
      }
    }
    mainHeroMobileImageLocale {
      locale: en {
        ...ImageFragment
      }
    }
    heroTextAuthenticatedLocale {
      locale: en
    }
    heroTextLocale {
      locale: en
    }
    heroTextColor
    heroActionAuthenticated {
      route
      actionText {
        locale: en
      }
    }
    heroAction {
      route
      actionText {
        locale: en
      }
    }
    heroActionLeft {
      actionText {
        locale: en
      }
      route
    }
    heroActionRight {
      actionText {
        locale: en
      }
      route
    }
    heroSuperTextAuthenticated {
      locale: en
    }
    heroSuperText {
      locale: en
    }
    heroSubTextAuthenticated {
      locale: en
    }
    heroSubText {
      locale: en
    }
    heroMaskCards {
      ...HeroMaskCard
    }
    loyaltyHeroAction {
      route
      actionText {
        locale: en
      }
    }
    loyaltyHeroSuperText {
      locale: en
    }
    loyaltyHeroSubText {
      locale: en
    }
    heroActionLeftIcon {
      locale: en {
        ...ImageFragment
      }
    }
    heroActionRightIcon {
      locale: en {
        ...ImageFragment
      }
    }
  }
  ${ImageFragmentDoc}
  ${HeroMaskCardFragmentDoc}
`;
export const HeroFragmentDoc = gql`
  fragment HeroFragment on Hero {
    _id
    image {
      locale: en {
        ...ImageFragment
      }
    }
    altImageText {
      locale: en
    }
    header {
      locale: en
    }
    subheader {
      locale: en
    }
    primaryCta {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    showComplementaryCTA
    complementaryCta {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    reversed
    hasTerms
    termsText {
      localeRaw: enRaw
    }
    termsButton {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    backgroundColor
    colorOption
    backgroundSplit
  }
  ${ImageFragmentDoc}
`;
export const LoadingGraphicsFragmentDoc = gql`
  fragment LoadingGraphicsFragment on LoadingGraphics {
    cartInitialLoader {
      desktopGraphic {
        ...ref
      }
      mobileGraphic {
        ...ref
      }
      title {
        locale: en
      }
      text {
        locale: en
      }
    }
  }
  ${RefFragmentDoc}
`;
export const VideoFileFragmentDoc = gql`
  fragment VideoFileFragment on File {
    asset {
      assetId
      path
      url
    }
  }
`;
export const LocaleVideoFileFragmentDoc = gql`
  fragment LocaleVideoFileFragment on LocaleFile {
    locale: en {
      ...VideoFileFragment
    }
  }
  ${VideoFileFragmentDoc}
`;
export const ClaimPointsScreenContentFragmentDoc = gql`
  fragment ClaimPointsScreenContentFragment on ClaimPointsScreenContent {
    rewardsIcon {
      locale: en {
        ...ImageFragment
      }
    }
    secondImage {
      locale: en {
        ...ImageFragment
      }
    }
    screenTitle {
      locale: en
    }
    instructionsTextBlock {
      localeRaw: enRaw
    }
    topButtonTitle {
      locale: en
    }
    topButtonUrl {
      locale: en
    }
    bottomButtonTitle {
      locale: en
    }
    bottomButtonUrl {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const ClaimPointsSurveyContentFragmentDoc = gql`
  fragment ClaimPointsSurveyContentFragment on ClaimPointsSurveyScreenContent {
    rewardsIcon {
      locale: en {
        ...ImageFragment
      }
    }
    secondImage {
      locale: en {
        ...ImageFragment
      }
    }
    screenTitle {
      locale: en
    }
    instructionsTextBlock {
      localeRaw: enRaw
    }
    expirationDateInstructionsTextBlock {
      localeRaw: enRaw
    }
    inputBoxExpirationDatePlaceholder {
      locale: en
    }
    expirationTimeReceipts
    topButtonTitle {
      locale: en
    }
    topButtonUrl {
      locale: en
    }
    bottomButtonTitle {
      locale: en
    }
    bottomButtonUrl {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const ClaimPointsQrCodeContentFragmentDoc = gql`
  fragment ClaimPointsQrCodeContentFragment on ClaimQrCode {
    title {
      locale: en
    }
    body {
      localeRaw: enRaw
    }
    link {
      ... on LinkWidget {
        ...LinkWidgetFragment
      }
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const ClaimPointsFailedSubmissionContentFragmentDoc = gql`
  fragment ClaimPointsFailedSubmissionContentFragment on ClaimPointsFailedSubmissionContent {
    screenTitle {
      locale: en
    }
    failureTextBlock {
      localeRaw: enRaw
    }
    buttonTitle {
      locale: en
    }
    buttonUrl {
      locale: en
    }
  }
`;
export const ExternalOffersFragmentDoc = gql`
  fragment ExternalOffersFragment on ExternalOffers {
    _id
    testOnly
    authentificationRequired
    image {
      locale: en {
        ...ImageFragment
      }
    }
    backgroundImage {
      ...ImageFragment
    }
    title {
      locale: en
    }
    subtitle {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    buttonsConfiguration {
      firstButton {
        buttonPath {
          locale: en
        }
        buttonText {
          locale: en
        }
      }
      secondButton {
        buttonPath {
          locale: en
        }
        buttonText {
          locale: en
        }
      }
    }
    unauthenticatedUsersContent {
      title {
        locale: en
      }
      description {
        localeRaw: enRaw
      }
    }
    externalOfferModal {
      title {
        locale: en
      }
      description {
        localeRaw: enRaw
      }
    }
    tierIncentiveList {
      tierName
    }
  }
  ${ImageFragmentDoc}
`;
export const LinkPhysicalCardSectionFragmentDoc = gql`
  fragment LinkPhysicalCardSectionFragment on LinkCardSection {
    title {
      locale: en
    }
    subtitle {
      locale: en
    }
    image {
      locale: en {
        ...ImageFragment
      }
    }
    imageDescription {
      locale: en
    }
    ctaButtonLabel {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const LinkPhysicalCardFaqFragmentDoc = gql`
  fragment LinkPhysicalCardFaqFragment on LinkCardFaqSection {
    title {
      locale: en
    }
    subtitle {
      locale: en
    }
    marketingTileList {
      ...MarketingTileListFragment
    }
  }
  ${MarketingTileListFragmentDoc}
`;
export const LinkCardActivationSuccessModalFragmentDoc = gql`
  fragment LinkCardActivationSuccessModalFragment on LinkCardActivationSuccessModal {
    title {
      locale: en
    }
    description {
      locale: en
    }
    image {
      locale: en {
        ...ImageFragment
      }
    }
    imageDescription {
      locale: en
    }
    ctaButtonLabel {
      locale: en
    }
    closeButtonLabel {
      locale: en
    }
    downloadAppButtonLabel {
      locale: en
    }
    downloadAppUrl {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const LinkCardErrorModalFragmentDoc = gql`
  fragment LinkCardErrorModalFragment on LinkCardErrorModal {
    title {
      locale: en
    }
    subtitle {
      locale: en
    }
    ctaButtonLabel {
      locale: en
    }
    closeButtonLabel {
      locale: en
    }
  }
`;
export const LinkCardDefaultModalFragmentDoc = gql`
  fragment LinkCardDefaultModalFragment on LinkCardDefaultModal {
    title {
      locale: en
    }
    content {
      locale: en
    }
    ctaButtonLabel {
      locale: en
    }
    closeButtonLabel {
      locale: en
    }
  }
`;
export const MenuImagesFragmentDoc = gql`
  fragment MenuImagesFragment on Images {
    app {
      ...MenuImageFragment
    }
    imageDescription
  }
  ${MenuImageFragmentDoc}
`;
export const LoyaltyOfferTemplateFragmentDoc = gql`
  fragment LoyaltyOfferTemplate on OfferTemplate {
    _id
    _type
    testOnly
    loyaltyEngineId
    name {
      localeRaw: enRaw
    }
    description {
      localeRaw: enRaw
    }
    backgroundImage {
      ...MenuImageFragment
    }
    localizedImage {
      locale: en {
        ...MenuImagesFragment
      }
    }
  }
  ${MenuImageFragmentDoc}
  ${MenuImagesFragmentDoc}
`;
export const LoyaltyOffersPageMarketingTileFragmentDoc = gql`
  fragment LoyaltyOffersPageMarketingTileFragment on LoyaltyOffersPageMarketingTile {
    marketingTileReference {
      ...MarketingTileFragment
    }
    displayPosition
    customPosition
  }
  ${MarketingTileFragmentDoc}
`;
export const OptInModalFragmentDoc = gql`
  fragment OptInModalFragment on OptInModalContent {
    image {
      locale: en {
        ...ImagesFragment
      }
    }
    title {
      locale: en
    }
    description {
      locale: en
    }
    buttonText {
      locale: en
    }
    blockText {
      localeRaw: enRaw
    }
  }
  ${ImagesFragmentDoc}
`;
export const ShortCodeConfirmationModalFragmentDoc = gql`
  fragment ShortCodeConfirmationModalFragment on ShortCodeConfirmationModalContent {
    image {
      locale: en {
        ...ImagesFragment
      }
    }
    header {
      locale: en
    }
    body {
      locale: en
    }
    actionButton {
      buttonText {
        locale: en
      }
      buttonPath {
        locale: en
      }
    }
    dismissButtonText {
      locale: en
    }
  }
  ${ImagesFragmentDoc}
`;
export const LoyaltyButtonWidgetFragmentDoc = gql`
  fragment LoyaltyButtonWidgetFragment on LoyaltyButtonWidget {
    _key
    componentKey
    link {
      ...LinkWidgetFragment
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const LocaleImageFragmentDoc = gql`
  fragment LocaleImageFragment on LocaleImage {
    locale: en {
      ...ImageFragment
    }
  }
  ${ImageFragmentDoc}
`;
export const LoyaltyImageWidgetFragmentDoc = gql`
  fragment LoyaltyImageWidgetFragment on LoyaltyImageWidget {
    _key
    componentKey
    image {
      ...LocaleImageFragment
    }
  }
  ${LocaleImageFragmentDoc}
`;
export const LocaleStringFragmentDoc = gql`
  fragment LocaleStringFragment on LocaleString {
    locale: en
  }
`;
export const LoyaltyTextWidgetFragmentDoc = gql`
  fragment LoyaltyTextWidgetFragment on LoyaltyTextWidget {
    _key
    componentKey
    text {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyTitleWidgetFragmentDoc = gql`
  fragment LoyaltyTitleWidgetFragment on LoyaltyTitleWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyPaymentMethodWidgetFragmentDoc = gql`
  fragment LoyaltyPaymentMethodWidgetFragment on LoyaltyPaymentMethodWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyQrScanButtonWidgetFragmentDoc = gql`
  fragment LoyaltyQRScanButtonWidgetFragment on LoyaltyQRScanButtonWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
    expirationMessage {
      ...LocaleStringFragment
    }
    link {
      ...LinkWidgetFragment
    }
  }
  ${LocaleStringFragmentDoc}
  ${LinkWidgetFragmentDoc}
`;
export const LoyaltyQrScanHeaderWidgetFragmentDoc = gql`
  fragment LoyaltyQRScanHeaderWidgetFragment on LoyaltyQRScanHeaderWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
    expirationMessage {
      ...LocaleStringFragment
    }
    link {
      ...LinkWidgetFragment
    }
  }
  ${LocaleStringFragmentDoc}
  ${LinkWidgetFragmentDoc}
`;
export const LoyaltyCartWidgetFragmentDoc = gql`
  fragment LoyaltyCartWidgetFragment on LoyaltyCartWidget {
    _key
    componentKey
    loyaltyEmptyCartTileWidget {
      ...MarketingTileBasicFragment
    }
  }
  ${MarketingTileBasicFragmentDoc}
`;
export const LoyaltyShortCodeWidgetFragmentDoc = gql`
  fragment LoyaltyShortCodeWidgetFragment on LoyaltyShortCodeWidget {
    _key
    componentKey
    defaultCode {
      ...LocaleStringFragment
    }
    title {
      ...LocaleStringFragment
    }
    alternativeTitle {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyLegacyShortCodeWidgetFragmentDoc = gql`
  fragment LoyaltyLegacyShortCodeWidgetFragment on LoyaltyLegacyShortCodeWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
    instructions {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyQrCodeWidgetFragmentDoc = gql`
  fragment LoyaltyQRCodeWidgetFragment on LoyaltyQRCodeWidget {
    _key
    componentKey
    title {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
    expirationMessage {
      ...LocaleStringFragment
    }
  }
  ${LocaleStringFragmentDoc}
`;
export const LoyaltyBasicCardWidgetFragmentDoc = gql`
  fragment LoyaltyBasicCardWidgetFragment on LoyaltyBasicCardWidget {
    _key
    componentKey
    linkedCard {
      ...MarketingTileBasicFragment
    }
    unlinkedCard {
      ...MarketingTileBasicFragment
    }
  }
  ${MarketingTileBasicFragmentDoc}
`;
export const LoyaltyButtonLinkTextWidgetFragmentDoc = gql`
  fragment LoyaltyButtonLinkTextWidgetFragment on LoyaltyButtonLinkTextWidget {
    _key
    componentKey
    type
    link {
      ...LinkWidgetFragment
    }
  }
  ${LinkWidgetFragmentDoc}
`;
export const LoyaltyButtonArrayWidgetFragmentDoc = gql`
  fragment LoyaltyButtonArrayWidgetFragment on LoyaltyButtonArrayWidget {
    _key
    componentKey
    buttons {
      ...LoyaltyButtonLinkTextWidgetFragment
    }
  }
  ${LoyaltyButtonLinkTextWidgetFragmentDoc}
`;
export const LoyaltyQrAndShortCodeModalContentFragmentDoc = gql`
  fragment LoyaltyQRAndShortCodeModalContentFragment on LoyaltyQRAndShortCodeModalContent {
    _key
    title {
      locale: en
    }
    details {
      locale: enRaw
    }
    image {
      ...ImageFragment
    }
    buttonCtaLabel {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const LoyaltyQrAndShortCodeWidgetFragmentDoc = gql`
  fragment LoyaltyQRAndShortCodeWidgetFragment on LoyaltyQRAndShortCodeWidget {
    _key
    componentKey
    title {
      locale: en
    }
    shortCodeText {
      locale: en
    }
    qrAndShortCodeHowToEarnModalContent {
      ...LoyaltyQRAndShortCodeModalContentFragment
    }
    qrAndShortCodeErrorModalContent {
      ...LoyaltyQRAndShortCodeModalContentFragment
    }
    shortCodeAlwaysDisplayGenerateButton
  }
  ${LoyaltyQrAndShortCodeModalContentFragmentDoc}
`;
export const MarketingCardFragmentDoc = gql`
  fragment MarketingCardFragment on MarketingCard {
    _id
    header {
      locale: en
    }
    description {
      locale: en
    }
    image {
      locale: en {
        ...ImagesFragment
      }
    }
    callToAction {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    hasCustomColors
    textColor {
      ...ColorFragment
    }
    backgroundColor {
      ...ColorFragment
    }
    buttonTextColor {
      ...ColorFragment
    }
    buttonBackgroundColor {
      ...ColorFragment
    }
    hasTerms
    termsText {
      localeRaw: enRaw
    }
    termsButton {
      actionText {
        locale: en
      }
      actionUrl {
        locale: en
      }
      route
    }
    BKPayAudience
    bkSpecificTargeting
    daysOfWeek
    timeRanges {
      startTime
      endTime
    }
  }
  ${ImagesFragmentDoc}
  ${ColorFragmentDoc}
`;
export const MarketingCardGroupFragmentDoc = gql`
  fragment MarketingCardGroupFragment on MarketingCardGroup {
    _id
    Cards {
      ... on MarketingCard {
        ...MarketingCardFragment
      }
    }
  }
  ${MarketingCardFragmentDoc}
`;
export const MarketingTileCarouselFragmentDoc = gql`
  fragment MarketingTileCarouselFragment on MarketingTileCarousel {
    _id
    header {
      ...LocaleStringFragment
    }
    tiles {
      ... on MarketingTileBasic {
        ...MarketingTileBasicFragment
      }
    }
  }
  ${LocaleStringFragmentDoc}
  ${MarketingTileBasicFragmentDoc}
`;
export const ConfigOfferFragmentDoc = gql`
  fragment ConfigOfferFragment on ConfigOffer {
    _id
    _type
    testOnly
    loyaltyEngineId
    name {
      localeRaw: enRaw
    }
    description {
      localeRaw: enRaw
    }
    moreInfo {
      localeRaw: enRaw
    }
    howToRedeem {
      enRaw
    }
    redemptionMethod
    backgroundImage {
      ...MenuImageFragment
    }
    shortCode
    mobileOrderOnly
    daypart
    redemptionType
    upsellOptions {
      _id
      loyaltyEngineId
      description {
        localeRaw: enRaw
      }
      localizedImage {
        locale: en {
          ...MenuImagesFragment
        }
      }
      name {
        localeRaw: enRaw
      }
    }
    offerPrice
    marketPrice {
      ... on Item {
        _id
        _type
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Combo {
        _id
        _type
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
    }
    localizedImage {
      locale: en {
        ...MenuImagesFragment
      }
    }
    uiPattern
    isUpcomingOffer
    lockedOffersPanel {
      completedChallengeHeader {
        localeRaw: enRaw
      }
      completedChallengeDescription {
        localeRaw: enRaw
      }
    }
    promoCodePanel {
      promoCodeDescription {
        localeRaw: enRaw
      }
      promoCodeLabel {
        localeRaw: enRaw
      }
      promoCodeLink
    }
    incentives {
      __typename
      ... on Combo {
        _id
        _type
        mainItem {
          _id
          _type
          operationalItem {
            daypart
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
        isOfferBenefit
      }
      ... on Item {
        _id
        _type
        operationalItem {
          daypart
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Picker {
        _id
        _type
        options {
          option {
            __typename
            ... on Combo {
              _id
              _type
              mainItem {
                _id
                _type
                operationalItem {
                  daypart
                }
                vendorConfigs {
                  ...VendorConfigsFragment
                }
                pluConfigs {
                  ...PluConfigsFragment
                }
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            ... on Item {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
          }
        }
        isOfferBenefit
      }
      ... on OfferDiscount {
        _id
        _type
        discountValue
        discountType
        discountProduct {
          ... on Item {
            _id
            _type
            operationalItem {
              daypart
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
          ... on Combo {
            _id
            _type
            mainItem {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
        }
      }
      ... on OfferActivation {
        _id
        _type
      }
      ... on SwapMapping {
        _type
      }
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
    rules {
      ... on RequiresAuthentication {
        requiresAuthentication
      }
      ... on LoyaltyBetweenDates {
        startDate
        endDate
      }
      ... on UserAttributes {
        userAttributesItem {
          attributeItem
          useAttributeBatteryLevelFilter
          useAttributeBatteryLevelValue
          userAttributeBooleanValue
          userAttributeStringFilter
          userAttributeStringValue
        }
      }
    }
  }
  ${MenuImageFragmentDoc}
  ${MenuImagesFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const MembershipsModalsContentDetailsFragmentDoc = gql`
  fragment MembershipsModalsContentDetailsFragment on MembershipsModalsContentDetails {
    title {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    firstButtonPath {
      locale: en
    }
    firstButtonText {
      locale: en
    }
    secondButtonPath {
      locale: en
    }
    secondButtonText {
      locale: en
    }
  }
`;
export const MembershipsInformationWidgetsFragmentDoc = gql`
  fragment MembershipsInformationWidgetsFragment on MembershipsInformationWidgets {
    widgets {
      title {
        locale: en
      }
      description {
        locale: enRaw
      }
      widgetImage {
        locale: en {
          ...ImageFragment
        }
      }
      widgetBackgroundColor {
        ...ColorFragment
      }
    }
  }
  ${ImageFragmentDoc}
  ${ColorFragmentDoc}
`;
export const MembershipsFragmentDoc = gql`
  fragment MembershipsFragment on Memberships {
    _id
    testOnly
    membershipInternalName
    membershipName
    membershipBenefit {
      offer {
        ...ConfigOfferFragment
      }
    }
    membershipModalsContent {
      image {
        ...ImageFragment
      }
      membershipActivationSuccess {
        ...MembershipsModalsContentDetailsFragment
      }
      membershipCardRemoval {
        ...MembershipsModalsContentDetailsFragment
      }
      membershipAccountLinkFailure {
        ...MembershipsModalsContentDetailsFragment
      }
      membershipBenefitActivationPending {
        ...MembershipsModalsContentDetailsFragment
      }
      membershipExpired {
        ...MembershipsModalsContentDetailsFragment
      }
      membershipOfferAssignmentFailure {
        ...MembershipsModalsContentDetailsFragment
      }
    }
    membershipIntegrationTypeContent {
      membershipIntegrationType
      externalIntegrationPath {
        locale: en
      }
      externalIntegrationText {
        locale: en
      }
    }
    membershipPageConfiguration {
      informationWidgets {
        ...MembershipsInformationWidgetsFragment
      }
      membershipPageStyle {
        image {
          ...ImageFragment
        }
      }
      actionButtonText {
        locale: en
      }
      actionButtonLink {
        locale: en
      }
    }
    membershipInternalPageConfiguration {
      title {
        locale: en
      }
    }
  }
  ${ConfigOfferFragmentDoc}
  ${ImageFragmentDoc}
  ${MembershipsModalsContentDetailsFragmentDoc}
  ${MembershipsInformationWidgetsFragmentDoc}
`;
export const NotificationDetailsFragmentDoc = gql`
  fragment NotificationDetailsFragment on NotificationDetails {
    enabled
    title {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    image {
      locale: en {
        ...ImageFragment
      }
    }
    path {
      locale: en
    }
  }
  ${ImageFragmentDoc}
`;
export const NutritionExplorerItemFragmentDoc = gql`
  fragment NutritionExplorerItemFragment on Item {
    _id
    _type
    name {
      locale: en
    }
    image {
      ...ImageFragment
    }
    options {
      options {
        default
        nutrition {
          ...NutritionFragment
        }
      }
    }
    nutrition {
      ...NutritionFragment
    }
    productSize
    additionalItemInformation {
      ingredients {
        locale: en
      }
      additives {
        locale: en
      }
      producerDetails {
        locale: en
      }
    }
    nutritionWithModifiers {
      ...NutritionFragment
    }
    allergens {
      milk
      eggs
      fish
      peanuts
      shellfish
      treeNuts
      soy
      wheat
      mustard
      sesame
      celery
      lupin
      gluten
      sulphurDioxide
    }
  }
  ${ImageFragmentDoc}
  ${NutritionFragmentDoc}
`;
export const NutritionExplorerComboFragmentDoc = gql`
  fragment NutritionExplorerComboFragment on Combo {
    _id
    _type
    name {
      locale: en
    }
    image {
      ...ImageFragment
    }
    mainItem {
      ...NutritionExplorerItemFragment
    }
  }
  ${ImageFragmentDoc}
  ${NutritionExplorerItemFragmentDoc}
`;
export const NutritionExplorerPickerFragmentDoc = gql`
  fragment NutritionExplorerPickerFragment on Picker {
    _id
    _type
    name {
      locale: en
    }
    image {
      ...ImageFragment
    }
    pickerDefaults {
      pickerAspect {
        _id
      }
      pickerAspectValueIdentifier
    }
    pickerAspects {
      _id
      _type
      pickerAspectOptions {
        identifier
      }
    }
    pickerAspectItemOptionMappings {
      pickerAspect {
        _id
      }
      options {
        value
      }
    }
    options {
      pickerItemMappings {
        pickerAspectValueIdentifier
        pickerAspect {
          _id
        }
      }
      option {
        ...NutritionExplorerItemFragment
        ...NutritionExplorerComboFragment
      }
    }
  }
  ${ImageFragmentDoc}
  ${NutritionExplorerItemFragmentDoc}
  ${NutritionExplorerComboFragmentDoc}
`;
export const NutritionExplorerSectionFragmentDoc = gql`
  fragment NutritionExplorerSectionFragment on Section {
    _id
    _type
    name {
      locale: en
    }
    carouselImage {
      ...ImageFragment
    }
    image {
      ...ImageFragment
    }
    options {
      ...NutritionExplorerPickerFragment
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
      ... on Section {
        _id
        _type
        name {
          locale: en
        }
        options {
          ...NutritionExplorerPickerFragment
          ...NutritionExplorerItemFragment
          ... on Section {
            _type
            _id
            options {
              ...NutritionExplorerPickerFragment
              ...NutritionExplorerItemFragment
            }
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${NutritionExplorerPickerFragmentDoc}
  ${NutritionExplorerItemFragmentDoc}
  ${NutritionExplorerComboFragmentDoc}
`;
export const NutritionExplorerMenuFragmentDoc = gql`
  fragment NutritionExplorerMenuFragment on Menu {
    _id
    _type
    options {
      ...NutritionExplorerPickerFragment
      ...NutritionExplorerSectionFragment
      ...NutritionExplorerItemFragment
      ...NutritionExplorerComboFragment
    }
    pickerBackgroundImage {
      ...ImageFragment
    }
  }
  ${NutritionExplorerPickerFragmentDoc}
  ${NutritionExplorerSectionFragmentDoc}
  ${NutritionExplorerItemFragmentDoc}
  ${NutritionExplorerComboFragmentDoc}
  ${ImageFragmentDoc}
`;
export const ConditionAllergenFragmentDoc = gql`
  fragment ConditionAllergenFragment on ConditionAllergen {
    _type
    allergenIdentifier
    comparisonOperator
    comparisonValue
  }
`;
export const ConditionNutritionFragmentDoc = gql`
  fragment ConditionNutritionFragment on ConditionNutrition {
    _type
    nutritionIdentifier
    comparisonOperator
    comparisonValue
  }
`;
export const ConditionParentCategoryFragmentDoc = gql`
  fragment ConditionParentCategoryFragment on ConditionParentCategory {
    _type
    parentCategory {
      ... on Section {
        _id
      }
      ... on Picker {
        _id
      }
    }
  }
`;
export const ConditionItemOneOfFragmentDoc = gql`
  fragment ConditionItemOneOfFragment on ConditionItemOneOf {
    _type
    items {
      ...NutritionExplorerItemFragment
    }
  }
  ${NutritionExplorerItemFragmentDoc}
`;
export const FilterFragmentDoc = gql`
  fragment FilterFragment on Filter {
    _type
    description {
      locale: en
    }
    conditions {
      ...ConditionAllergenFragment
      ...ConditionNutritionFragment
      ...ConditionParentCategoryFragment
      ...ConditionItemOneOfFragment
    }
  }
  ${ConditionAllergenFragmentDoc}
  ${ConditionNutritionFragmentDoc}
  ${ConditionParentCategoryFragmentDoc}
  ${ConditionItemOneOfFragmentDoc}
`;
export const FilterGroupFragmentDoc = gql`
  fragment FilterGroupFragment on FilterGroup {
    _type
    description {
      locale: en
    }
    filters {
      ...FilterFragment
    }
  }
  ${FilterFragmentDoc}
`;
export const NutritionExplorerWidgetFragmentDoc = gql`
  fragment NutritionExplorerWidgetFragment on NutritionExplorerWidget {
    _key
    _type
    menu {
      ...NutritionExplorerMenuFragment
    }
    viewMenuButtonText {
      locale: en
    }
    moreOptionsButtonText {
      locale: en
    }
    madLibFilterGroup {
      ...FilterGroupFragment
    }
    modalFilterGroups {
      ...FilterGroupFragment
    }
    categoryWhitelist {
      ... on Section {
        _id
        _type
      }
      ... on Picker {
        _id
        _type
      }
    }
  }
  ${NutritionExplorerMenuFragmentDoc}
  ${FilterGroupFragmentDoc}
`;
export const OfferCarouselFragmentDoc = gql`
  fragment OfferCarouselFragment on OfferCarouselSection {
    _id
    offerCarouselTitle {
      locale: en
    }
    offerCarouselList {
      ... on ExternalOffers {
        _type
        _id
        testOnly
        title {
          locale: en
        }
        description {
          localeRaw: enRaw
        }
        image {
          locale: en {
            ...MenuImageFragment
          }
        }
      }
      ... on SystemwideOffer {
        _type
        _id
        testOnly
        loyaltyEngineId
        name {
          localeRaw: enRaw
        }
        description {
          localeRaw: enRaw
        }
        moreInfo {
          localeRaw: enRaw
        }
        localizedImage {
          locale: en {
            ...MenuImagesFragment
          }
        }
        rules {
          ... on UserAttributes {
            userAttributesItem {
              attributeItem
              useAttributeBatteryLevelFilter
              useAttributeBatteryLevelValue
              userAttributeBooleanValue
              userAttributeStringFilter
              userAttributeStringValue
            }
          }
        }
      }
    }
  }
  ${MenuImageFragmentDoc}
  ${MenuImagesFragmentDoc}
`;
export const OffersSelectRestaurantCtaFragmentDoc = gql`
  fragment OffersSelectRestaurantCtaFragment on OffersBrowsingPanel {
    offersSelectRestaurantCta {
      title {
        locale: en
      }
      body {
        locale: en
      }
      ctaButtonText {
        locale: en
      }
      link {
        locale: en
      }
      minimumNumberOfOffers
    }
  }
`;
export const ItemMetaFragmentDoc = gql`
  fragment ItemMetaFragment on Item {
    _id
    _type
    operationalItem {
      daypart
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
  }
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const ComboMetaFragmentDoc = gql`
  fragment ComboMetaFragment on Combo {
    _id
    _type
    mainItem {
      ...ItemMetaFragment
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
  }
  ${ItemMetaFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const PickerMetaFragmentDoc = gql`
  fragment PickerMetaFragment on Picker {
    _id
    _type
    options {
      option {
        ...ItemMetaFragment
        ...ComboMetaFragment
      }
    }
  }
  ${ItemMetaFragmentDoc}
  ${ComboMetaFragmentDoc}
`;
export const ActivateOfferMetaFragmentDoc = gql`
  fragment ActivateOfferMetaFragment on OfferActivation {
    _type
  }
`;
export const DiscountOfferMetaFragmentDoc = gql`
  fragment DiscountOfferMetaFragment on OfferDiscount {
    discountValue
    discountType
    _type
  }
`;
export const AllowRedemptionWithoutLoginFragmentDoc = gql`
  fragment AllowRedemptionWithoutLogin on AllowRedemptionWithoutLogin {
    ruleSetType: _type
  }
`;
export const BetweenDatesFragmentDoc = gql`
  fragment BetweenDates on BetweenDates {
    ruleSetType: _type
    endDate
    startDate
  }
`;
export const BetweenTimesFragmentDoc = gql`
  fragment BetweenTimes on BetweenTimes {
    ruleSetType: _type
    endTime
    startTime
  }
`;
export const CartPropertyFragmentDoc = gql`
  fragment CartProperty on CartProperty {
    ruleSetType: _type
    key
    negated
    operator
    value
  }
`;
export const CoolDownFragmentDoc = gql`
  fragment CoolDown on CoolDown {
    ruleSetType: _type
    minimumElapsedTimeSeconds
  }
`;
export const DayOfWeekBandFragmentDoc = gql`
  fragment DayOfWeekBand on DayOfWeekBand {
    ruleSetType: _type
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
`;
export const FirstOrderOnlyFragmentDoc = gql`
  fragment FirstOrderOnly on FirstOrderOnly {
    ruleSetType: _type
  }
`;
export const LimitFragmentDoc = gql`
  fragment Limit on Limit {
    ruleSetType: _type
    interval
    maximumRedemptions
  }
`;
export const PaymentCardTypeFragmentDoc = gql`
  fragment PaymentCardType on PaymentCardType {
    ruleSetType: _type
    cardType
  }
`;
export const ProductCategoryFragmentDoc = gql`
  fragment ProductCategoryFragment on ProductCategory {
    ruleSetType: _type
    _id
    name {
      locale: en
    }
    image {
      ...MenuImageFragment
    }
    hierarchyLevel
  }
  ${MenuImageFragmentDoc}
`;
export const PurchaseFragmentDoc = gql`
  fragment Purchase on Purchase {
    ruleSetType: _type
    quantity
    sanityId
    option {
      ...ProductCategoryFragment
      ...ComboMetaFragment
      ...ItemMetaFragment
      ...PickerMetaFragment
    }
  }
  ${ProductCategoryFragmentDoc}
  ${ComboMetaFragmentDoc}
  ${ItemMetaFragmentDoc}
  ${PickerMetaFragmentDoc}
`;
export const RequiresAssignmentFragmentDoc = gql`
  fragment RequiresAssignment on RequiresAssignment {
    ruleSetType: _type
  }
`;
export const RestaurantGroupRestrictionListFragmentDoc = gql`
  fragment RestaurantGroupRestrictionListFragment on RestaurantGroupRestrictions {
    ruleSetType: _type
    restaurantGroupRestrictionList {
      restaurants {
        number
      }
    }
  }
`;
export const OrderHistoryFragmentDoc = gql`
  fragment OrderHistoryFragment on OrderHistory {
    ruleSetType: _type
    rangeType
    from
    to
    lastNSeconds
    isSequential
    ruleSet {
      ... on BetweenTimes {
        ...BetweenTimes
      }
      ... on CartProperty {
        ...CartProperty
      }
      ... on DayOfWeekBand {
        ...DayOfWeekBand
      }
      ... on PaymentCardType {
        ...PaymentCardType
      }
      ... on Purchase {
        ...Purchase
      }
      ... on And {
        ruleSetType: _type
        ruleSet {
          ... on AllowRedemptionWithoutLogin {
            ...AllowRedemptionWithoutLogin
          }
          ... on BetweenDates {
            ...BetweenDates
          }
          ... on BetweenTimes {
            ...BetweenTimes
          }
          ... on CartProperty {
            ...CartProperty
          }
          ... on CoolDown {
            ...CoolDown
          }
          ... on DayOfWeekBand {
            ...DayOfWeekBand
          }
          ... on FirstOrderOnly {
            ...FirstOrderOnly
          }
          ... on Limit {
            ...Limit
          }
          ... on PaymentCardType {
            ...PaymentCardType
          }
          ... on Purchase {
            ...Purchase
          }
          ... on RequiresAssignment {
            ...RequiresAssignment
          }
          ... on RestaurantGroupRestrictions {
            ...RestaurantGroupRestrictionListFragment
          }
        }
      }
      ... on Or {
        ruleSetType: _type
        ruleSet {
          ... on AllowRedemptionWithoutLogin {
            ...AllowRedemptionWithoutLogin
          }
          ... on BetweenDates {
            ...BetweenDates
          }
          ... on BetweenTimes {
            ...BetweenTimes
          }
          ... on CartProperty {
            ...CartProperty
          }
          ... on CoolDown {
            ...CoolDown
          }
          ... on DayOfWeekBand {
            ...DayOfWeekBand
          }
          ... on FirstOrderOnly {
            ...FirstOrderOnly
          }
          ... on Limit {
            ...Limit
          }
          ... on PaymentCardType {
            ...PaymentCardType
          }
          ... on Purchase {
            ...Purchase
          }
          ... on RequiresAssignment {
            ...RequiresAssignment
          }
          ... on RestaurantGroupRestrictions {
            ...RestaurantGroupRestrictionListFragment
          }
        }
      }
    }
  }
  ${BetweenTimesFragmentDoc}
  ${CartPropertyFragmentDoc}
  ${DayOfWeekBandFragmentDoc}
  ${PaymentCardTypeFragmentDoc}
  ${PurchaseFragmentDoc}
  ${AllowRedemptionWithoutLoginFragmentDoc}
  ${BetweenDatesFragmentDoc}
  ${CoolDownFragmentDoc}
  ${FirstOrderOnlyFragmentDoc}
  ${LimitFragmentDoc}
  ${RequiresAssignmentFragmentDoc}
  ${RestaurantGroupRestrictionListFragmentDoc}
`;
export const OffersFragmentDoc = gql`
  fragment OffersFragment on Offer {
    _id
    _type
    name {
      localeRaw: enRaw
    }
    headerSuperText {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    moreInfo {
      localeRaw: enRaw
    }
    howToRedeem {
      localeRaw: enRaw
    }
    backgroundImage {
      ...MenuImageFragment
    }
    offerTags {
      value
      _id
    }
    offerCTA {
      actionText {
        locale: en
      }
      route
    }
    daypart
    forSupport
    redemptionMethod
    offerPrice
    marketPrice {
      ... on Item {
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Combo {
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
    }
    localizedImage {
      locale: en {
        ...MenuImagesFragment
      }
    }
    option {
      ...ComboMetaFragment
      ...ItemMetaFragment
      ...PickerMetaFragment
      ...ActivateOfferMetaFragment
      ...DiscountOfferMetaFragment
    }
    uiPattern
    lockedOffersPanel {
      completedChallengeHeader {
        localeRaw: enRaw
      }
      completedChallengeDescription {
        localeRaw: enRaw
      }
    }
    promoCodePanel {
      promoCodeDescription {
        localeRaw: enRaw
      }
      promoCodeLabel {
        localeRaw: enRaw
      }
      promoCodeLink
    }
    shortCode
    ruleSet {
      ... on AllowRedemptionWithoutLogin {
        ...AllowRedemptionWithoutLogin
      }
      ... on BetweenDates {
        ...BetweenDates
      }
      ... on BetweenTimes {
        ...BetweenTimes
      }
      ... on CartProperty {
        ...CartProperty
      }
      ... on CoolDown {
        ...CoolDown
      }
      ... on DayOfWeekBand {
        ...DayOfWeekBand
      }
      ... on FirstOrderOnly {
        ...FirstOrderOnly
      }
      ... on Limit {
        ...Limit
      }
      ... on OrderHistory {
        ...OrderHistoryFragment
      }
      ... on PaymentCardType {
        ...PaymentCardType
      }
      ... on Purchase {
        ...Purchase
      }
      ... on RequiresAssignment {
        ...RequiresAssignment
      }
      ... on RestaurantGroupRestrictions {
        ...RestaurantGroupRestrictionListFragment
      }
      ... on And {
        ruleSetType: _type
        ruleSet {
          ... on AllowRedemptionWithoutLogin {
            ...AllowRedemptionWithoutLogin
          }
          ... on BetweenDates {
            ...BetweenDates
          }
          ... on BetweenTimes {
            ...BetweenTimes
          }
          ... on CartProperty {
            ...CartProperty
          }
          ... on CoolDown {
            ...CoolDown
          }
          ... on DayOfWeekBand {
            ...DayOfWeekBand
          }
          ... on FirstOrderOnly {
            ...FirstOrderOnly
          }
          ... on Limit {
            ...Limit
          }
          ... on PaymentCardType {
            ...PaymentCardType
          }
          ... on Purchase {
            ...Purchase
          }
          ... on RequiresAssignment {
            ...RequiresAssignment
          }
          ... on RestaurantGroupRestrictions {
            ...RestaurantGroupRestrictionListFragment
          }
        }
      }
      ... on Or {
        ruleSetType: _type
        ruleSet {
          ... on AllowRedemptionWithoutLogin {
            ...AllowRedemptionWithoutLogin
          }
          ... on BetweenDates {
            ...BetweenDates
          }
          ... on BetweenTimes {
            ...BetweenTimes
          }
          ... on CartProperty {
            ...CartProperty
          }
          ... on CoolDown {
            ...CoolDown
          }
          ... on DayOfWeekBand {
            ...DayOfWeekBand
          }
          ... on FirstOrderOnly {
            ...FirstOrderOnly
          }
          ... on Limit {
            ...Limit
          }
          ... on PaymentCardType {
            ...PaymentCardType
          }
          ... on Purchase {
            ...Purchase
          }
          ... on RequiresAssignment {
            ...RequiresAssignment
          }
          ... on RestaurantGroupRestrictions {
            ...RestaurantGroupRestrictionListFragment
          }
        }
      }
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
  }
  ${MenuImageFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${MenuImagesFragmentDoc}
  ${ComboMetaFragmentDoc}
  ${ItemMetaFragmentDoc}
  ${PickerMetaFragmentDoc}
  ${ActivateOfferMetaFragmentDoc}
  ${DiscountOfferMetaFragmentDoc}
  ${AllowRedemptionWithoutLoginFragmentDoc}
  ${BetweenDatesFragmentDoc}
  ${BetweenTimesFragmentDoc}
  ${CartPropertyFragmentDoc}
  ${CoolDownFragmentDoc}
  ${DayOfWeekBandFragmentDoc}
  ${FirstOrderOnlyFragmentDoc}
  ${LimitFragmentDoc}
  ${OrderHistoryFragmentDoc}
  ${PaymentCardTypeFragmentDoc}
  ${PurchaseFragmentDoc}
  ${RequiresAssignmentFragmentDoc}
  ${RestaurantGroupRestrictionListFragmentDoc}
`;
export const OptInSettingEmailAndPushFragmentDoc = gql`
  fragment OptInSettingEmailAndPushFragment on OptInSettingEmailAndPush {
    _key
    _type
    pushKey
    emailKey
    singleTickBox
    name {
      locale: en
    }
    description {
      locale: en
    }
  }
`;
export const OptInPrivacyPreferencesFragmentDoc = gql`
  fragment OptInPrivacyPreferencesFragment on OptInPrivacyPreferences {
    _key
    _type
    key
    name {
      locale: en
    }
    description {
      locale: en
    }
    view {
      locale: en
    }
  }
`;
export const OptInSettingEmailFragmentDoc = gql`
  fragment OptInSettingEmailFragment on OptInSettingEmail {
    _key
    _type
    emailKey
    name {
      locale: en
    }
    description {
      locale: en
    }
  }
`;
export const OptInSettingSmsFragmentDoc = gql`
  fragment OptInSettingSmsFragment on OptInSettingSms {
    _key
    _type
    smsKey
    name {
      locale: en
    }
    description {
      locale: en
    }
  }
`;
export const OptInSettingPushFragmentDoc = gql`
  fragment OptInSettingPushFragment on OptInSettingPush {
    _key
    _type
    pushKey
    name {
      locale: en
    }
    description {
      locale: en
    }
  }
`;
export const ComboAvailabilityFragmentDoc = gql`
  fragment ComboAvailabilityFragment on Combo {
    mainItem {
      ...ItemAvailabilityFragment
    }
    showInStaticMenu
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
    options {
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
      minAmount
      options {
        maxAmount
        option {
          ... on Item {
            ...ItemAvailabilityFragment
          }
        }
      }
    }
  }
  ${ItemAvailabilityFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const PickerAvailabilityFragmentDoc = gql`
  fragment PickerAvailabilityFragment on Picker {
    showInStaticMenu
    hideCalories
    hideNutritionLegalDisclaimer
    imagesByChannels {
      ...ImagesByChannelsFragment
    }
    options {
      option {
        ... on Combo {
          ...ComboAvailabilityFragment
        }
        ... on Item {
          ...ItemAvailabilityFragment
        }
      }
    }
  }
  ${ImagesByChannelsFragmentDoc}
  ${ComboAvailabilityFragmentDoc}
  ${ItemAvailabilityFragmentDoc}
`;
export const ComboFragmentDoc = gql`
  fragment ComboFragment on Combo {
    _id
    _type
    name {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    image {
      ...MenuImageFragment
    }
    imageDescription {
      locale: en
    }
    imagesByChannels {
      ...ImagesByChannelsFragment
    }
    mainItem {
      ...ItemFragment
    }
    markerItem {
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
    }
    uiPattern
    hideMainItemDescription
    forceModifiersToBottom
    options {
      name {
        locale: en
      }
      _type
      _id
      uiPattern
      minAmount
      maxAmount
      respectMaximum
      optionVisibilitySettings {
        visibleOptions
        toggleButtonTextClosed {
          locale: en
        }
        toggleButtonTextOpen {
          locale: en
        }
      }
      options {
        _key
        minAmount
        defaultAmount
        isPremium
        option {
          ... on Item {
            ...ItemFragment
          }
          ... on Picker {
            _type
            uiPattern
            pickerAspects {
              name {
                locale: en
              }
              pickerAspectOptions {
                name {
                  locale: en
                }
                identifier
              }
            }
          }
        }
      }
    }
    menuObjectSettings {
      limitPerOrder
    }
    isOfferBenefit
  }
  ${MenuImageFragmentDoc}
  ${ImagesByChannelsFragmentDoc}
  ${ItemFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const QuickConfigFragmentDoc = gql`
  fragment QuickConfigFragment on QuickConfig {
    name {
      locale: en
    }
    rules {
      itemOptions {
        value
      }
      modifier {
        value
      }
    }
  }
`;
export const PickerWithVendorConfigFragmentDoc = gql`
  fragment PickerWithVendorConfigFragment on Picker {
    _id
    _type
    name {
      locale: en
    }
    pickerDefaults {
      _key
      pickerAspect {
        _id
        name {
          locale: en
        }
      }
      pickerAspectValueIdentifier
    }
    pickerAspects {
      _id
      _type
      name {
        locale: en
      }
      uiPattern
      pickerAspectOptions {
        identifier
        name {
          locale: en
        }
        description {
          locale: en
        }
        image {
          ...MenuImageFragment
        }
        imageDescription {
          locale: en
        }
        hideNameInItemPreview
        showInStaticMenu
      }
    }
    pickerAspectItemOptionMappings {
      pickerAspect {
        _id
        name {
          locale: en
        }
      }
      options {
        value
      }
    }
    options {
      type: _type
      _key
      pickerItemMappings {
        pickerAspectValueIdentifier
        pickerAspect {
          _id
        }
      }
      option {
        ... on Combo {
          _key
          _id
          promotion {
            bonusPoints
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
          ...ComboFragment
        }
        ... on Item {
          _key
          promotion {
            bonusPoints
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
          ...ItemFragment
        }
      }
      default
    }
    image {
      ...MenuImageFragment
    }
    imageDescription {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    quickConfigs {
      ...QuickConfigFragment
    }
    channelExclusions {
      delivery
      pickup
    }
    uiPattern
    isOfferBenefit
  }
  ${MenuImageFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${ComboFragmentDoc}
  ${ItemFragmentDoc}
  ${QuickConfigFragmentDoc}
`;
export const PickerFragmentDoc = gql`
  fragment PickerFragment on Picker {
    _id
    _type
    name {
      locale: en
    }
    pickerDefaults {
      _key
      pickerAspect {
        _id
        name {
          locale: en
        }
      }
      pickerAspectValueIdentifier
    }
    pickerAspects {
      _id
      _type
      name {
        locale: en
      }
      uiPattern
      pickerAspectOptions {
        identifier
        name {
          locale: en
        }
        description {
          locale: en
        }
        image {
          ...MenuImageFragment
        }
        imageDescription {
          locale: en
        }
        hideNameInItemPreview
        showInStaticMenu
      }
    }
    pickerAspectItemOptionMappings {
      pickerAspect {
        _id
        name {
          locale: en
        }
      }
      options {
        value
      }
    }
    options {
      type: _type
      _key
      pickerItemMappings {
        pickerAspectValueIdentifier
        pickerAspect {
          _id
        }
      }
      option {
        ... on Combo {
          _key
          _id
          promotion {
            bonusPoints
          }
          ...ComboFragment
        }
        ... on Item {
          _key
          promotion {
            bonusPoints
          }
          ...ItemFragment
        }
      }
      default
    }
    image {
      ...MenuImageFragment
    }
    imageDescription {
      locale: en
    }
    description {
      localeRaw: enRaw
    }
    legalInformation {
      localeRaw: enRaw
    }
    quickConfigs {
      ...QuickConfigFragment
    }
    channelExclusions {
      delivery
      pickup
    }
    uiPattern
    isOfferBenefit
  }
  ${MenuImageFragmentDoc}
  ${ComboFragmentDoc}
  ${ItemFragmentDoc}
  ${QuickConfigFragmentDoc}
`;
export const ProductListSectionFragmentDoc = gql`
  fragment ProductListSectionFragment on Section {
    _id
    _type
    daypart
    name {
      locale: en
    }
    image {
      ...ImageFragment
    }
    uiPattern
    showInStaticMenu
    isBurgersForBreakfastSection
    headerText {
      locale: en
    }
    imageDescription {
      locale: en
    }
    carouselImage {
      ...ImageFragment
    }
    hiddenFromMainMenu
    options {
      ... on Section {
        _id
        _type
        name {
          locale: en
        }
        image {
          ...ImageFragment
        }
        headerText {
          locale: en
        }
        imageDescription {
          locale: en
        }
        carouselImage {
          ...ImageFragment
        }
        hiddenFromMainMenu
        options {
          ... on Item {
            _id
            _type
            name {
              locale: en
            }
            image {
              ...ImageFragment
            }
            imagesByChannels {
              ...ImagesByChannelsFragment
            }
            showInStaticMenu
            hideCalories
          }
          ... on Picker {
            _id
            _type
            name {
              locale: en
            }
            image {
              ...ImageFragment
            }
            imagesByChannels {
              ...ImagesByChannelsFragment
            }
            uiPattern
            showInStaticMenu
          }
          ... on Combo {
            _id
            _type
            name {
              locale: en
            }
            image {
              ...ImageFragment
            }
            imagesByChannels {
              ...ImagesByChannelsFragment
            }
            uiPattern
            showInStaticMenu
          }
        }
        uiPattern
        showInStaticMenu
        daypart
        isBurgersForBreakfastSection
      }
      ... on Combo {
        _id
        _type
        name {
          locale: en
        }
        image {
          ...ImageFragment
        }
        imagesByChannels {
          ...ImagesByChannelsFragment
        }
        uiPattern
        showInStaticMenu
      }
      ... on Picker {
        _id
        _type
        name {
          locale: en
        }
        image {
          ...ImageFragment
        }
        imagesByChannels {
          ...ImagesByChannelsFragment
        }
        uiPattern
        showInStaticMenu
      }
      ... on Item {
        _id
        _type
        name {
          locale: en
        }
        image {
          ...ImageFragment
        }
        imagesByChannels {
          ...ImagesByChannelsFragment
        }
        showInStaticMenu
        hideCalories
      }
    }
  }
  ${ImageFragmentDoc}
  ${ImagesByChannelsFragmentDoc}
`;
export const RefillDrinkModeFragmentDoc = gql`
  fragment RefillDrinkModeFragment on RefillDrinksServiceMode {
    cateringDeliveryServiceMode
    cateringPickUpServiceMode
    curbsideServiceMode
    deliveryServiceMode
    dineInServiceMode
    driveThruServiceMode
    pickUpServiceMode
    tableServiceMode
  }
`;
export const RefillDrinkTimersFragmentDoc = gql`
  fragment RefillDrinkTimersFragment on RefillDrinksTimers {
    timeBottomSheet
    timeRecentOrder
  }
`;
export const RewardPriceFragmentDoc = gql`
  fragment RewardPrice on RewardPrice {
    ruleSetType: _type
    price
  }
`;
export const RewardFragmentDoc = gql`
  fragment RewardFragment on Reward {
    _id
    testOnly
    loyaltyEngineId
    name {
      locale: en
    }
    incentiveNotInMenu
    description {
      locale: enRaw
    }
    mobileOrderOnly
    redemptionMethod
    ruleSet {
      ... on RewardPrice {
        ...RewardPrice
      }
    }
    moreInfo {
      enRaw
    }
    howToRedeem {
      enRaw
    }
    backgroundImage {
      ...MenuImageFragment
    }
    image {
      locale: en {
        ...ImageFragment
      }
    }
    imageDescription {
      locale: en
    }
    incentives {
      __typename
      ... on Combo {
        _id
        _type
        mainItem {
          _id
          _type
          operationalItem {
            daypart
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Item {
        _id
        _type
        operationalItem {
          daypart
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Picker {
        _id
        _type
        options {
          option {
            __typename
            ... on Combo {
              _id
              _type
              mainItem {
                _id
                _type
                operationalItem {
                  daypart
                }
                vendorConfigs {
                  ...VendorConfigsFragment
                }
                pluConfigs {
                  ...PluConfigsFragment
                }
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            ... on Item {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
          }
        }
      }
      ... on OfferDiscount {
        _id
        _type
        discountValue
        discountType
        discountProduct {
          ... on Item {
            _id
            _type
            operationalItem {
              daypart
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
          ... on Combo {
            _id
            _type
            mainItem {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
        }
      }
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
  }
  ${RewardPriceFragmentDoc}
  ${MenuImageFragmentDoc}
  ${ImageFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const RewardsSectionFragmentDoc = gql`
  fragment RewardsSectionFragment on RewardsSection {
    _id
    rewardsList {
      reward {
        ...RewardFragment
      }
      category {
        label {
          locale: en
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${RewardFragmentDoc}
  ${ImageFragmentDoc}
`;
export const SystemwideOffersFragmentDoc = gql`
  fragment SystemwideOffersFragment on SystemwideOffer {
    _id
    _type
    testOnly
    loyaltyEngineId
    name {
      localeRaw: enRaw
    }
    description {
      localeRaw: enRaw
    }
    moreInfo {
      localeRaw: enRaw
    }
    howToRedeem {
      enRaw
    }
    backgroundImage {
      ...MenuImageFragment
    }
    shortCode
    mobileOrderOnly
    redemptionMethod
    daypart
    redemptionType
    upsellOptions {
      _id
      loyaltyEngineId
      description {
        localeRaw: enRaw
      }
      localizedImage {
        locale: en {
          ...MenuImagesFragment
        }
      }
      name {
        localeRaw: enRaw
      }
    }
    offerPrice
    marketPrice {
      ... on Item {
        _id
        _type
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Combo {
        _id
        _type
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
    }
    localizedImage {
      locale: en {
        ...MenuImagesFragment
      }
    }
    uiPattern
    isUpcomingOffer
    lockedOffersPanel {
      completedChallengeHeader {
        localeRaw: enRaw
      }
      completedChallengeDescription {
        localeRaw: enRaw
      }
    }
    promoCodePanel {
      promoCodeDescription {
        localeRaw: enRaw
      }
      promoCodeLabel {
        localeRaw: enRaw
      }
      promoCodeLink
    }
    incentives {
      __typename
      ... on Combo {
        _id
        _type
        mainItem {
          _id
          _type
          operationalItem {
            daypart
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
        isOfferBenefit
      }
      ... on Item {
        _id
        _type
        operationalItem {
          daypart
        }
        vendorConfigs {
          ...VendorConfigsFragment
        }
        pluConfigs {
          ...PluConfigsFragment
        }
      }
      ... on Picker {
        _id
        _type
        options {
          option {
            __typename
            ... on Combo {
              _id
              _type
              mainItem {
                _id
                _type
                operationalItem {
                  daypart
                }
                vendorConfigs {
                  ...VendorConfigsFragment
                }
                pluConfigs {
                  ...PluConfigsFragment
                }
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            ... on Item {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
          }
        }
        isOfferBenefit
      }
      ... on OfferDiscount {
        _id
        _type
        discountValue
        discountType
        discountProduct {
          ... on Item {
            _id
            _type
            operationalItem {
              daypart
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
          ... on Combo {
            _id
            _type
            mainItem {
              _id
              _type
              operationalItem {
                daypart
              }
              vendorConfigs {
                ...VendorConfigsFragment
              }
              pluConfigs {
                ...PluConfigsFragment
              }
            }
            vendorConfigs {
              ...VendorConfigsFragment
            }
            pluConfigs {
              ...PluConfigsFragment
            }
          }
        }
      }
      ... on OfferActivation {
        _id
        _type
      }
      ... on SwapMapping {
        _type
      }
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    pluConfigs {
      ...PluConfigsFragment
    }
    rules {
      ... on RequiresAuthentication {
        requiresAuthentication
      }
      ... on LoyaltyBetweenDates {
        startDate
        endDate
      }
      ... on UserAttributes {
        userAttributesItem {
          attributeItem
          useAttributeBatteryLevelFilter
          useAttributeBatteryLevelValue
          userAttributeBooleanValue
          userAttributeStringFilter
          userAttributeStringValue
        }
      }
    }
  }
  ${MenuImageFragmentDoc}
  ${MenuImagesFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;
export const TabDetailContentFragmentDoc = gql`
  fragment TabDetailContentFragment on TabDetailContent {
    title {
      locale: en
    }
    body {
      locale: en
    }
    image {
      locale: en {
        ...ImageFragment
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const TitleAndBodyFragmentDoc = gql`
  fragment TitleAndBodyFragment on TitleAndBody {
    title {
      locale: en
    }
    body {
      locale: en
    }
  }
`;
export const HoursFragmentDoc = gql`
  fragment HoursFragment on HoursOfOperation {
    _type
    friClose
    friOpen
    sunAdditionalTimeSlot {
      close
      open
    }
    monClose
    monOpen
    monAdditionalTimeSlot {
      close
      open
    }
    satClose
    satOpen
    tueAdditionalTimeSlot {
      close
      open
    }
    sunClose
    sunOpen
    wedAdditionalTimeSlot {
      close
      open
    }
    thrClose
    thrOpen
    thrAdditionalTimeSlot {
      close
      open
    }
    tueClose
    tueOpen
    friAdditionalTimeSlot {
      close
      open
    }
    wedClose
    wedOpen
    satAdditionalTimeSlot {
      close
      open
    }
  }
`;
export const ImageAssetFragmentDoc = gql`
  fragment ImageAssetFragment on SanityImageAsset {
    _id
    label
    title
    url
    source {
      id
      url
    }
    metadata {
      lqip
    }
  }
`;
export const RestaurantFragmentDoc = gql`
  fragment RestaurantFragment on Restaurant {
    _id
    environment
    chaseMerchantId
    deliveryHours {
      ...HoursFragment
    }
    diningRoomHours {
      ...HoursFragment
    }
    curbsideHours {
      ...HoursFragment
    }
    driveThruHours {
      ...HoursFragment
    }
    drinkStationType
    driveThruLaneType
    email
    franchiseGroupId
    franchiseGroupName
    frontCounterClosed
    hasBreakfast
    hasBurgersForBreakfast
    hasCurbside
    hasDineIn
    hasCatering
    hideClickAndCollectOrdering
    hasDelivery
    hasDriveThru
    hasTableService
    hasMobileOrdering
    hasParking
    hasPlayground
    hasTakeOut
    hasWifi
    hasLoyalty
    isDarkKitchen
    isHalal
    latitude
    longitude
    mobileOrderingStatus
    name
    number
    parkingType
    phoneNumber
    playgroundType
    pos {
      _type
      vendor
    }
    physicalAddress {
      _type
      address1
      address2
      city
      country
      postalCode
      stateProvince
    }
    posRestaurantId
    restaurantPosData {
      _id
    }
    showStoreLocatorOffersButton
    status
    restaurantImage {
      hotspot {
        width
        height
        x
        y
      }
      crop {
        top
        bottom
        left
        right
      }
      asset {
        ...ImageAssetFragment
      }
    }
    amenities {
      name {
        locale: en
      }
      icon {
        asset {
          ...ImageAssetFragment
        }
      }
    }
    timezone
    vatNumber
    customerFacingAddress {
      locale: en
    }
  }
  ${HoursFragmentDoc}
  ${ImageAssetFragmentDoc}
`;
export const PaymentMethodsFragmentDoc = gql`
  fragment PaymentMethodsFragment on PaymentMethodConfiguration {
    name
    paymentMethodBrand
    state
    isOnlinePayment
  }
`;
export const AllFeatureIdsDocument = gql`
  query allFeatureIds {
    allFeatureAccountForm(limit: 1) {
      _id
    }
    allFeatureGeolocationMapMarkers(limit: 1) {
      _id
    }
    allFeatureGeolocationModal(limit: 1) {
      _id
    }
    allFeatureHomePage(limit: 1) {
      _id
    }
    allFeatureOffers(limit: 1) {
      _id
    }
    allFeatureFooter(limit: 1) {
      _id
    }
    allFeatureNavigation(limit: 1) {
      _id
    }
    allFeatureAccountDeletePage(limit: 1) {
      _id
    }
    allFeatureAccountRequestInfoPage(limit: 1) {
      _id
    }
    allSupportData(limit: 1) {
      _id
    }
    allFeatureStaticMenu(limit: 1) {
      _id
    }
    allFeatureMenu(limit: 1) {
      _id
    }
    allFeatureLayout(limit: 1) {
      _id
    }
    allFeatureDisclaimers(limit: 1) {
      _id
    }
    allFeatureExternalLinkSection(limit: 1) {
      _id
    }
    allFeatureAccount(limit: 1) {
      _id
    }
    allFeatureCateringPage(limit: 1) {
      _id
    }
    allLoyaltyUI(limit: 1) {
      _id
    }
    allFeatureNutrition(limit: 1) {
      _id
    }
    allRewardList(limit: 1) {
      _id
    }
    allClaimPointsUI(limit: 1) {
      _id
    }
    allFeatureOnboarding(limit: 1) {
      _id
    }
    allFeatureLockers(limit: 1) {
      _id
    }
    allFeatureBeeperModal(limit: 1) {
      _id
    }
    allLinkPhysicalCardUi(limit: 1) {
      _id
    }
    allLiveMemberships(limit: 1) {
      _id
    }
    allLoyaltyLiveExternalOffers(limit: 1) {
      _id
    }
    allFeatureInvitationCode(where: { enableInvitationCode: { eq: true } }, limit: 1) {
      _id
    }
    allFeatureAccountUpdatedAgreements(limit: 1) {
      _id
    }
    allFeatureDonation(limit: 1) {
      _id
    }
    allFeatureIFrame(limit: 1) {
      _id
    }
    allFeatureQRCodeRefillDrinks(limit: 1) {
      _id
    }
    allFeatureAggregatorRedirectButtons(limit: 1) {
      _id
    }
    allFeatureGeolocationTermsModal(limit: 1) {
      _id
    }
    allConfigRedeemInRestaurant(limit: 1) {
      _id
    }
    allBirthdayAndCatering(limit: 1) {
      _id
    }
    allFeatureFeesAndTaxes(limit: 1) {
      _id
    }
    allFeatureEventReservations(limit: 1) {
      _id
    }
  }
`;

/**
 * __useAllFeatureIdsQuery__
 *
 * To run a query within a React component, call `useAllFeatureIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeatureIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeatureIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeatureIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IAllFeatureIdsQuery, IAllFeatureIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IAllFeatureIdsQuery, IAllFeatureIdsQueryVariables>(
    AllFeatureIdsDocument,
    options
  );
}
export function useAllFeatureIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IAllFeatureIdsQuery,
    IAllFeatureIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IAllFeatureIdsQuery, IAllFeatureIdsQueryVariables>(
    AllFeatureIdsDocument,
    options
  );
}
export type AllFeatureIdsQueryHookResult = ReturnType<typeof useAllFeatureIdsQuery>;
export type AllFeatureIdsLazyQueryHookResult = ReturnType<typeof useAllFeatureIdsLazyQuery>;
export type AllFeatureIdsQueryResult = Apollo.QueryResult<
  IAllFeatureIdsQuery,
  IAllFeatureIdsQueryVariables
>;
export const AllItemsDocument = gql`
  query allItems($where: ItemFilter) {
    allItem(where: $where) {
      ... on Item {
        ...ItemFragment
        ...ItemAvailabilityFragment
        description {
          localeRaw: enRaw
        }
      }
    }
  }
  ${ItemFragmentDoc}
  ${ItemAvailabilityFragmentDoc}
`;

/**
 * __useAllItemsQuery__
 *
 * To run a query within a React component, call `useAllItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IAllItemsQuery, IAllItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IAllItemsQuery, IAllItemsQueryVariables>(
    AllItemsDocument,
    options
  );
}
export function useAllItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IAllItemsQuery, IAllItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IAllItemsQuery, IAllItemsQueryVariables>(
    AllItemsDocument,
    options
  );
}
export type AllItemsQueryHookResult = ReturnType<typeof useAllItemsQuery>;
export type AllItemsLazyQueryHookResult = ReturnType<typeof useAllItemsLazyQuery>;
export type AllItemsQueryResult = Apollo.QueryResult<IAllItemsQuery, IAllItemsQueryVariables>;
export const GetComboDocument = gql`
  query GetCombo($id: ID!) {
    Combo(id: $id) {
      ...ComboFragment
    }
  }
  ${ComboFragmentDoc}
`;

/**
 * __useGetComboQuery__
 *
 * To run a query within a React component, call `useGetComboQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComboQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IGetComboQuery, IGetComboQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetComboQuery, IGetComboQueryVariables>(
    GetComboDocument,
    options
  );
}
export function useGetComboLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetComboQuery, IGetComboQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetComboQuery, IGetComboQueryVariables>(
    GetComboDocument,
    options
  );
}
export type GetComboQueryHookResult = ReturnType<typeof useGetComboQuery>;
export type GetComboLazyQueryHookResult = ReturnType<typeof useGetComboLazyQuery>;
export type GetComboQueryResult = Apollo.QueryResult<IGetComboQuery, IGetComboQueryVariables>;
export const GetComboWithVendorConfigDocument = gql`
  query GetComboWithVendorConfig($id: ID!) {
    Combo(id: $id) {
      ...ComboFragment
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
    }
  }
  ${ComboFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;

/**
 * __useGetComboWithVendorConfigQuery__
 *
 * To run a query within a React component, call `useGetComboWithVendorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboWithVendorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboWithVendorConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComboWithVendorConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetComboWithVendorConfigQuery,
    IGetComboWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetComboWithVendorConfigQuery,
    IGetComboWithVendorConfigQueryVariables
  >(GetComboWithVendorConfigDocument, options);
}
export function useGetComboWithVendorConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetComboWithVendorConfigQuery,
    IGetComboWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetComboWithVendorConfigQuery,
    IGetComboWithVendorConfigQueryVariables
  >(GetComboWithVendorConfigDocument, options);
}
export type GetComboWithVendorConfigQueryHookResult = ReturnType<
  typeof useGetComboWithVendorConfigQuery
>;
export type GetComboWithVendorConfigLazyQueryHookResult = ReturnType<
  typeof useGetComboWithVendorConfigLazyQuery
>;
export type GetComboWithVendorConfigQueryResult = Apollo.QueryResult<
  IGetComboWithVendorConfigQuery,
  IGetComboWithVendorConfigQueryVariables
>;
export const GetComboAvailabilityDocument = gql`
  query GetComboAvailability($id: ID!) {
    Combo(id: $id) {
      ...ComboAvailabilityFragment
    }
  }
  ${ComboAvailabilityFragmentDoc}
`;

/**
 * __useGetComboAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetComboAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComboAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComboAvailabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetComboAvailabilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetComboAvailabilityQuery,
    IGetComboAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetComboAvailabilityQuery, IGetComboAvailabilityQueryVariables>(
    GetComboAvailabilityDocument,
    options
  );
}
export function useGetComboAvailabilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetComboAvailabilityQuery,
    IGetComboAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetComboAvailabilityQuery,
    IGetComboAvailabilityQueryVariables
  >(GetComboAvailabilityDocument, options);
}
export type GetComboAvailabilityQueryHookResult = ReturnType<typeof useGetComboAvailabilityQuery>;
export type GetComboAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetComboAvailabilityLazyQuery
>;
export type GetComboAvailabilityQueryResult = Apollo.QueryResult<
  IGetComboAvailabilityQuery,
  IGetComboAvailabilityQueryVariables
>;
export const FeatureAccountDeletePageDocument = gql`
  query featureAccountDeletePage($featureAccountDeletePageId: ID!) {
    FeatureAccountDeletePage(id: $featureAccountDeletePageId) {
      headerText {
        locale: en
      }
      content {
        locale: enRaw
      }
      confirmButtonText {
        locale: en
      }
      cancelButtonText {
        locale: en
      }
      errorText {
        locale: en
      }
      oneTrustDeleteUrl
    }
  }
`;

/**
 * __useFeatureAccountDeletePageQuery__
 *
 * To run a query within a React component, call `useFeatureAccountDeletePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountDeletePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountDeletePageQuery({
 *   variables: {
 *      featureAccountDeletePageId: // value for 'featureAccountDeletePageId'
 *   },
 * });
 */
export function useFeatureAccountDeletePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAccountDeletePageQuery,
    IFeatureAccountDeletePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureAccountDeletePageQuery,
    IFeatureAccountDeletePageQueryVariables
  >(FeatureAccountDeletePageDocument, options);
}
export function useFeatureAccountDeletePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAccountDeletePageQuery,
    IFeatureAccountDeletePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureAccountDeletePageQuery,
    IFeatureAccountDeletePageQueryVariables
  >(FeatureAccountDeletePageDocument, options);
}
export type FeatureAccountDeletePageQueryHookResult = ReturnType<
  typeof useFeatureAccountDeletePageQuery
>;
export type FeatureAccountDeletePageLazyQueryHookResult = ReturnType<
  typeof useFeatureAccountDeletePageLazyQuery
>;
export type FeatureAccountDeletePageQueryResult = Apollo.QueryResult<
  IFeatureAccountDeletePageQuery,
  IFeatureAccountDeletePageQueryVariables
>;
export const FeatureAccountFormDocument = gql`
  query featureAccountForm($featureAccountFormId: ID!) {
    FeatureAccountForm(id: $featureAccountFormId) {
      minimumSignUpAge
      postalOrZipDisclaimer {
        locale: en
      }
      desktopImage {
        locale: en {
          ...ImagesFragment
        }
      }
      activeCampaigns {
        ...ActiveCampaignFragment
      }
      signUpTerms {
        signUpTermsCells {
          signUpTermsCellDisplayedText {
            locale: en
          }
          signUpTermsCellUrl {
            locale: en
          }
          signUpTermsCellIsRequired
        }
      }
      privacyPolicyDisclaimers {
        privacyPolicyDisclaimersCells {
          disclaimerTitle
          disclaimerDescription
          disclaimerContent {
            locale: enRaw
          }
          border
          fontSize
          paddingTop
          paddingRight
          paddingBottom
          paddingLeft
          radius
        }
      }
    }
  }
  ${ImagesFragmentDoc}
  ${ActiveCampaignFragmentDoc}
`;

/**
 * __useFeatureAccountFormQuery__
 *
 * To run a query within a React component, call `useFeatureAccountFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountFormQuery({
 *   variables: {
 *      featureAccountFormId: // value for 'featureAccountFormId'
 *   },
 * });
 */
export function useFeatureAccountFormQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAccountFormQuery,
    IFeatureAccountFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureAccountFormQuery, IFeatureAccountFormQueryVariables>(
    FeatureAccountFormDocument,
    options
  );
}
export function useFeatureAccountFormLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAccountFormQuery,
    IFeatureAccountFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureAccountFormQuery, IFeatureAccountFormQueryVariables>(
    FeatureAccountFormDocument,
    options
  );
}
export type FeatureAccountFormQueryHookResult = ReturnType<typeof useFeatureAccountFormQuery>;
export type FeatureAccountFormLazyQueryHookResult = ReturnType<
  typeof useFeatureAccountFormLazyQuery
>;
export type FeatureAccountFormQueryResult = Apollo.QueryResult<
  IFeatureAccountFormQuery,
  IFeatureAccountFormQueryVariables
>;
export const FeatureAccountRequestInfoPageDocument = gql`
  query featureAccountRequestInfoPage($featureAccountRequestInfoPageId: ID!) {
    FeatureAccountRequestInfoPage(id: $featureAccountRequestInfoPageId) {
      headerText {
        locale: en
      }
      content {
        locale: enRaw
      }
      confirmButtonText {
        locale: en
      }
      errorText {
        locale: en
      }
    }
  }
`;

/**
 * __useFeatureAccountRequestInfoPageQuery__
 *
 * To run a query within a React component, call `useFeatureAccountRequestInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountRequestInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountRequestInfoPageQuery({
 *   variables: {
 *      featureAccountRequestInfoPageId: // value for 'featureAccountRequestInfoPageId'
 *   },
 * });
 */
export function useFeatureAccountRequestInfoPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAccountRequestInfoPageQuery,
    IFeatureAccountRequestInfoPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureAccountRequestInfoPageQuery,
    IFeatureAccountRequestInfoPageQueryVariables
  >(FeatureAccountRequestInfoPageDocument, options);
}
export function useFeatureAccountRequestInfoPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAccountRequestInfoPageQuery,
    IFeatureAccountRequestInfoPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureAccountRequestInfoPageQuery,
    IFeatureAccountRequestInfoPageQueryVariables
  >(FeatureAccountRequestInfoPageDocument, options);
}
export type FeatureAccountRequestInfoPageQueryHookResult = ReturnType<
  typeof useFeatureAccountRequestInfoPageQuery
>;
export type FeatureAccountRequestInfoPageLazyQueryHookResult = ReturnType<
  typeof useFeatureAccountRequestInfoPageLazyQuery
>;
export type FeatureAccountRequestInfoPageQueryResult = Apollo.QueryResult<
  IFeatureAccountRequestInfoPageQuery,
  IFeatureAccountRequestInfoPageQueryVariables
>;
export const FeatureAccountUpdatedAgreementsDocument = gql`
  query featureAccountUpdatedAgreements($featureAccountUpdatedAgreementsId: ID!) {
    FeatureAccountUpdatedAgreements(id: $featureAccountUpdatedAgreementsId) {
      updateAgreementText {
        locale: enRaw
      }
      marketingCommunicationText {
        locale: enRaw
      }
    }
  }
`;

/**
 * __useFeatureAccountUpdatedAgreementsQuery__
 *
 * To run a query within a React component, call `useFeatureAccountUpdatedAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountUpdatedAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountUpdatedAgreementsQuery({
 *   variables: {
 *      featureAccountUpdatedAgreementsId: // value for 'featureAccountUpdatedAgreementsId'
 *   },
 * });
 */
export function useFeatureAccountUpdatedAgreementsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAccountUpdatedAgreementsQuery,
    IFeatureAccountUpdatedAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureAccountUpdatedAgreementsQuery,
    IFeatureAccountUpdatedAgreementsQueryVariables
  >(FeatureAccountUpdatedAgreementsDocument, options);
}
export function useFeatureAccountUpdatedAgreementsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAccountUpdatedAgreementsQuery,
    IFeatureAccountUpdatedAgreementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureAccountUpdatedAgreementsQuery,
    IFeatureAccountUpdatedAgreementsQueryVariables
  >(FeatureAccountUpdatedAgreementsDocument, options);
}
export type FeatureAccountUpdatedAgreementsQueryHookResult = ReturnType<
  typeof useFeatureAccountUpdatedAgreementsQuery
>;
export type FeatureAccountUpdatedAgreementsLazyQueryHookResult = ReturnType<
  typeof useFeatureAccountUpdatedAgreementsLazyQuery
>;
export type FeatureAccountUpdatedAgreementsQueryResult = Apollo.QueryResult<
  IFeatureAccountUpdatedAgreementsQuery,
  IFeatureAccountUpdatedAgreementsQueryVariables
>;
export const FeatureAccountDocument = gql`
  query featureAccount($featureAccountId: ID!) {
    FeatureAccount(id: $featureAccountId) {
      _id
      emailAndPushOptInSettings {
        ...OptInSettingEmailAndPushFragment
        ...OptInSettingEmailFragment
        ...OptInSettingSmsFragment
        ...OptInSettingPushFragment
        ...OptInPrivacyPreferencesFragment
      }
    }
  }
  ${OptInSettingEmailAndPushFragmentDoc}
  ${OptInSettingEmailFragmentDoc}
  ${OptInSettingSmsFragmentDoc}
  ${OptInSettingPushFragmentDoc}
  ${OptInPrivacyPreferencesFragmentDoc}
`;

/**
 * __useFeatureAccountQuery__
 *
 * To run a query within a React component, call `useFeatureAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAccountQuery({
 *   variables: {
 *      featureAccountId: // value for 'featureAccountId'
 *   },
 * });
 */
export function useFeatureAccountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAccountQuery,
    IFeatureAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureAccountQuery, IFeatureAccountQueryVariables>(
    FeatureAccountDocument,
    options
  );
}
export function useFeatureAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAccountQuery,
    IFeatureAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureAccountQuery, IFeatureAccountQueryVariables>(
    FeatureAccountDocument,
    options
  );
}
export type FeatureAccountQueryHookResult = ReturnType<typeof useFeatureAccountQuery>;
export type FeatureAccountLazyQueryHookResult = ReturnType<typeof useFeatureAccountLazyQuery>;
export type FeatureAccountQueryResult = Apollo.QueryResult<
  IFeatureAccountQuery,
  IFeatureAccountQueryVariables
>;
export const FeatureAggregatorRedirectButtonsDocument = gql`
  query featureAggregatorRedirectButtons($featureAggregatorRedirectButtonsID: ID!) {
    FeatureAggregatorRedirectButtons(id: $featureAggregatorRedirectButtonsID) {
      _id
      aggregatorRedirectButtons {
        ...AggregatorRedirectButtonFragment
      }
    }
  }
  ${AggregatorRedirectButtonFragmentDoc}
`;

/**
 * __useFeatureAggregatorRedirectButtonsQuery__
 *
 * To run a query within a React component, call `useFeatureAggregatorRedirectButtonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAggregatorRedirectButtonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAggregatorRedirectButtonsQuery({
 *   variables: {
 *      featureAggregatorRedirectButtonsID: // value for 'featureAggregatorRedirectButtonsID'
 *   },
 * });
 */
export function useFeatureAggregatorRedirectButtonsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAggregatorRedirectButtonsQuery,
    IFeatureAggregatorRedirectButtonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureAggregatorRedirectButtonsQuery,
    IFeatureAggregatorRedirectButtonsQueryVariables
  >(FeatureAggregatorRedirectButtonsDocument, options);
}
export function useFeatureAggregatorRedirectButtonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAggregatorRedirectButtonsQuery,
    IFeatureAggregatorRedirectButtonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureAggregatorRedirectButtonsQuery,
    IFeatureAggregatorRedirectButtonsQueryVariables
  >(FeatureAggregatorRedirectButtonsDocument, options);
}
export type FeatureAggregatorRedirectButtonsQueryHookResult = ReturnType<
  typeof useFeatureAggregatorRedirectButtonsQuery
>;
export type FeatureAggregatorRedirectButtonsLazyQueryHookResult = ReturnType<
  typeof useFeatureAggregatorRedirectButtonsLazyQuery
>;
export type FeatureAggregatorRedirectButtonsQueryResult = Apollo.QueryResult<
  IFeatureAggregatorRedirectButtonsQuery,
  IFeatureAggregatorRedirectButtonsQueryVariables
>;
export const FeatureBeeperDocument = gql`
  query featureBeeper($featureBeeperId: ID!) {
    FeatureBeeperModal(id: $featureBeeperId) {
      beeperModalTitle {
        locale: en
      }
      beeperModalImage {
        locale: en {
          ...ImageFragment
        }
      }
      beeperModalBody {
        locale: en
      }
      beeperButtonText {
        locale: en
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureBeeperQuery__
 *
 * To run a query within a React component, call `useFeatureBeeperQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureBeeperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureBeeperQuery({
 *   variables: {
 *      featureBeeperId: // value for 'featureBeeperId'
 *   },
 * });
 */
export function useFeatureBeeperQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFeatureBeeperQuery, IFeatureBeeperQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureBeeperQuery, IFeatureBeeperQueryVariables>(
    FeatureBeeperDocument,
    options
  );
}
export function useFeatureBeeperLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureBeeperQuery,
    IFeatureBeeperQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureBeeperQuery, IFeatureBeeperQueryVariables>(
    FeatureBeeperDocument,
    options
  );
}
export type FeatureBeeperQueryHookResult = ReturnType<typeof useFeatureBeeperQuery>;
export type FeatureBeeperLazyQueryHookResult = ReturnType<typeof useFeatureBeeperLazyQuery>;
export type FeatureBeeperQueryResult = Apollo.QueryResult<
  IFeatureBeeperQuery,
  IFeatureBeeperQueryVariables
>;
export const FeatureBirthdayCateringDocument = gql`
  query featureBirthdayCatering($featureBirthdayCateringConfigId: ID!) {
    BirthdayAndCatering(id: $featureBirthdayCateringConfigId) {
      advancedBooking {
        catering
        birthday
      }
      availableHours {
        startTime
        endTime
      }
      maximumDaysBetweenRequestAndEvent
      minimumKids
      privacyPolicyDisclaimers {
        privacyPolicyDisclaimersCells {
          disclaimerTitle
          disclaimerDescription
          disclaimerContent {
            locale: enRaw
          }
          border
          fontSize
          paddingTop
          paddingRight
          paddingBottom
          paddingLeft
          radius
        }
      }
    }
  }
`;

/**
 * __useFeatureBirthdayCateringQuery__
 *
 * To run a query within a React component, call `useFeatureBirthdayCateringQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureBirthdayCateringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureBirthdayCateringQuery({
 *   variables: {
 *      featureBirthdayCateringConfigId: // value for 'featureBirthdayCateringConfigId'
 *   },
 * });
 */
export function useFeatureBirthdayCateringQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureBirthdayCateringQuery,
    IFeatureBirthdayCateringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureBirthdayCateringQuery,
    IFeatureBirthdayCateringQueryVariables
  >(FeatureBirthdayCateringDocument, options);
}
export function useFeatureBirthdayCateringLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureBirthdayCateringQuery,
    IFeatureBirthdayCateringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureBirthdayCateringQuery,
    IFeatureBirthdayCateringQueryVariables
  >(FeatureBirthdayCateringDocument, options);
}
export type FeatureBirthdayCateringQueryHookResult = ReturnType<
  typeof useFeatureBirthdayCateringQuery
>;
export type FeatureBirthdayCateringLazyQueryHookResult = ReturnType<
  typeof useFeatureBirthdayCateringLazyQuery
>;
export type FeatureBirthdayCateringQueryResult = Apollo.QueryResult<
  IFeatureBirthdayCateringQuery,
  IFeatureBirthdayCateringQueryVariables
>;
export const FeatureCateringPageDocument = gql`
  query featureCateringPage($featureCateringPageId: ID!) {
    FeatureCateringPage(id: $featureCateringPageId) {
      appUnauthenticated {
        ...CateringPageConfigurationFragment
      }
      appAuthenticated {
        ...CateringPageConfigurationFragment
      }
      webUnauthenticated {
        ...CateringPageConfigurationFragment
      }
      webAuthenticated {
        ...CateringPageConfigurationFragment
      }
    }
  }
  ${CateringPageConfigurationFragmentDoc}
`;

/**
 * __useFeatureCateringPageQuery__
 *
 * To run a query within a React component, call `useFeatureCateringPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureCateringPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureCateringPageQuery({
 *   variables: {
 *      featureCateringPageId: // value for 'featureCateringPageId'
 *   },
 * });
 */
export function useFeatureCateringPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureCateringPageQuery,
    IFeatureCateringPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureCateringPageQuery, IFeatureCateringPageQueryVariables>(
    FeatureCateringPageDocument,
    options
  );
}
export function useFeatureCateringPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureCateringPageQuery,
    IFeatureCateringPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureCateringPageQuery,
    IFeatureCateringPageQueryVariables
  >(FeatureCateringPageDocument, options);
}
export type FeatureCateringPageQueryHookResult = ReturnType<typeof useFeatureCateringPageQuery>;
export type FeatureCateringPageLazyQueryHookResult = ReturnType<
  typeof useFeatureCateringPageLazyQuery
>;
export type FeatureCateringPageQueryResult = Apollo.QueryResult<
  IFeatureCateringPageQuery,
  IFeatureCateringPageQueryVariables
>;
export const FeatureLoyaltyClaimPointsContentDocument = gql`
  query featureLoyaltyClaimPointsContent($featureClaimPointsUIId: ID!) {
    ClaimPointsUI(id: $featureClaimPointsUIId) {
      _id
      claimPointsHomeScreenContent {
        ...ClaimPointsScreenContentFragment
      }
      claimQrCode {
        ...ClaimPointsQrCodeContentFragment
      }
      claimPointsSurveyContent {
        ...ClaimPointsSurveyContentFragment
      }
      claimPointsSuccessfulSubmissionScreenContent {
        ...ClaimPointsScreenContentFragment
      }
      claimOnHistory {
        linkText {
          locale: enRaw
        }
      }
      claimPointsPendingContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsExpiredContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsInvalidCodeContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsAlreadyClaimedContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsTransactionNotFoundContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsInvalidRestaurantContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsDailyAmountExceededContent {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsDuplicateTransactionId {
        ...ClaimPointsFailedSubmissionContentFragment
      }
      claimPointsTooManyRequests {
        ...ClaimPointsFailedSubmissionContentFragment
      }
    }
  }
  ${ClaimPointsScreenContentFragmentDoc}
  ${ClaimPointsQrCodeContentFragmentDoc}
  ${ClaimPointsSurveyContentFragmentDoc}
  ${ClaimPointsFailedSubmissionContentFragmentDoc}
`;

/**
 * __useFeatureLoyaltyClaimPointsContentQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyClaimPointsContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyClaimPointsContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyClaimPointsContentQuery({
 *   variables: {
 *      featureClaimPointsUIId: // value for 'featureClaimPointsUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyClaimPointsContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyClaimPointsContentQuery,
    IFeatureLoyaltyClaimPointsContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyClaimPointsContentQuery,
    IFeatureLoyaltyClaimPointsContentQueryVariables
  >(FeatureLoyaltyClaimPointsContentDocument, options);
}
export function useFeatureLoyaltyClaimPointsContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyClaimPointsContentQuery,
    IFeatureLoyaltyClaimPointsContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyClaimPointsContentQuery,
    IFeatureLoyaltyClaimPointsContentQueryVariables
  >(FeatureLoyaltyClaimPointsContentDocument, options);
}
export type FeatureLoyaltyClaimPointsContentQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyClaimPointsContentQuery
>;
export type FeatureLoyaltyClaimPointsContentLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyClaimPointsContentLazyQuery
>;
export type FeatureLoyaltyClaimPointsContentQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyClaimPointsContentQuery,
  IFeatureLoyaltyClaimPointsContentQueryVariables
>;
export const FeatureDisclaimersDocument = gql`
  query featureDisclaimers($featureDisclaimersId: ID!) {
    FeatureDisclaimers(id: $featureDisclaimersId) {
      _id
      superUpsell {
        localeRaw: enRaw
      }
      calorie {
        localeRaw: enRaw
      }
      price {
        locale: en
      }
      itemAvailability {
        locale: en
      }
      images {
        locale: en
      }
      phoneNumber {
        locale: en
      }
      country {
        locale: en
      }
      offers {
        locale: en
      }
      copyrightAndTrademark {
        locale: en
      }
      reservedRights {
        locale: en
      }
      communicationPreferencesBlock {
        localeRaw: enRaw
      }
      signUpDisclaimers {
        locale: en
      }
      signUpMarketDisclaimers {
        locale: en
      }
      deliveryDisclaimer {
        locale: en
      }
      deliveryLogo {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureDisclaimersQuery__
 *
 * To run a query within a React component, call `useFeatureDisclaimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDisclaimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDisclaimersQuery({
 *   variables: {
 *      featureDisclaimersId: // value for 'featureDisclaimersId'
 *   },
 * });
 */
export function useFeatureDisclaimersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureDisclaimersQuery,
    IFeatureDisclaimersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureDisclaimersQuery, IFeatureDisclaimersQueryVariables>(
    FeatureDisclaimersDocument,
    options
  );
}
export function useFeatureDisclaimersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureDisclaimersQuery,
    IFeatureDisclaimersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureDisclaimersQuery, IFeatureDisclaimersQueryVariables>(
    FeatureDisclaimersDocument,
    options
  );
}
export type FeatureDisclaimersQueryHookResult = ReturnType<typeof useFeatureDisclaimersQuery>;
export type FeatureDisclaimersLazyQueryHookResult = ReturnType<
  typeof useFeatureDisclaimersLazyQuery
>;
export type FeatureDisclaimersQueryResult = Apollo.QueryResult<
  IFeatureDisclaimersQuery,
  IFeatureDisclaimersQueryVariables
>;
export const FeatureDonationDocument = gql`
  query featureDonation($featureDonationId: ID!) {
    FeatureDonation(id: $featureDonationId) {
      _id
      donationItemList {
        ... on Item {
          ...ItemFragment
          description {
            localeRaw: enRaw
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
      }
      logoImage {
        locale: en {
          ...ImageFragment
        }
      }
      header {
        locale: en
      }
      subtitle {
        locale: en
      }
      dialogInformation {
        header {
          locale: en
        }
        subtitle {
          locale: en
        }
      }
    }
  }
  ${ItemFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureDonationQuery__
 *
 * To run a query within a React component, call `useFeatureDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureDonationQuery({
 *   variables: {
 *      featureDonationId: // value for 'featureDonationId'
 *   },
 * });
 */
export function useFeatureDonationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureDonationQuery,
    IFeatureDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureDonationQuery, IFeatureDonationQueryVariables>(
    FeatureDonationDocument,
    options
  );
}
export function useFeatureDonationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureDonationQuery,
    IFeatureDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureDonationQuery, IFeatureDonationQueryVariables>(
    FeatureDonationDocument,
    options
  );
}
export type FeatureDonationQueryHookResult = ReturnType<typeof useFeatureDonationQuery>;
export type FeatureDonationLazyQueryHookResult = ReturnType<typeof useFeatureDonationLazyQuery>;
export type FeatureDonationQueryResult = Apollo.QueryResult<
  IFeatureDonationQuery,
  IFeatureDonationQueryVariables
>;
export const FeatureEventReservationsDocument = gql`
  query featureEventReservations($featureEventReservationsId: ID!) {
    FeatureEventReservations(id: $featureEventReservationsId) {
      enabledEvents {
        ... on FeatureBirthday {
          eventType
          birthdayAttendees {
            minimum
            maximum
            key
          }
          allergens {
            minimum
            maximum
          }
          reservationTime {
            earliestReservationDay
            latestReservationDay
            availableHours {
              startTime
              endTime
            }
          }
        }
        ... on FeatureCatering {
          eventType
          cateringAttendees {
            minimum
            maximum
            key
          }
          allergens {
            minimum
            maximum
          }
          reservationTime {
            earliestReservationDay
            latestReservationDay
            availableHours {
              startTime
              endTime
            }
          }
        }
      }
      privacyPolicyDisclaimers {
        privacyPolicyDisclaimersCells {
          disclaimerTitle
          disclaimerDescription
          disclaimerContent {
            locale: enRaw
          }
          border
          fontSize
          paddingTop
          paddingRight
          paddingBottom
          paddingLeft
          radius
        }
      }
    }
  }
`;

/**
 * __useFeatureEventReservationsQuery__
 *
 * To run a query within a React component, call `useFeatureEventReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureEventReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureEventReservationsQuery({
 *   variables: {
 *      featureEventReservationsId: // value for 'featureEventReservationsId'
 *   },
 * });
 */
export function useFeatureEventReservationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureEventReservationsQuery,
    IFeatureEventReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureEventReservationsQuery,
    IFeatureEventReservationsQueryVariables
  >(FeatureEventReservationsDocument, options);
}
export function useFeatureEventReservationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureEventReservationsQuery,
    IFeatureEventReservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureEventReservationsQuery,
    IFeatureEventReservationsQueryVariables
  >(FeatureEventReservationsDocument, options);
}
export type FeatureEventReservationsQueryHookResult = ReturnType<
  typeof useFeatureEventReservationsQuery
>;
export type FeatureEventReservationsLazyQueryHookResult = ReturnType<
  typeof useFeatureEventReservationsLazyQuery
>;
export type FeatureEventReservationsQueryResult = Apollo.QueryResult<
  IFeatureEventReservationsQuery,
  IFeatureEventReservationsQueryVariables
>;
export const FeatureExternalLinkSectionDocument = gql`
  query featureExternalLinkSection($featureExternalLinkId: ID!) {
    FeatureExternalLinkSection(id: $featureExternalLinkId) {
      _id
      FeatureExternalLinks {
        link {
          localeUrl: fr
        }
        title
      }
    }
  }
`;

/**
 * __useFeatureExternalLinkSectionQuery__
 *
 * To run a query within a React component, call `useFeatureExternalLinkSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureExternalLinkSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureExternalLinkSectionQuery({
 *   variables: {
 *      featureExternalLinkId: // value for 'featureExternalLinkId'
 *   },
 * });
 */
export function useFeatureExternalLinkSectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureExternalLinkSectionQuery,
    IFeatureExternalLinkSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureExternalLinkSectionQuery,
    IFeatureExternalLinkSectionQueryVariables
  >(FeatureExternalLinkSectionDocument, options);
}
export function useFeatureExternalLinkSectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureExternalLinkSectionQuery,
    IFeatureExternalLinkSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureExternalLinkSectionQuery,
    IFeatureExternalLinkSectionQueryVariables
  >(FeatureExternalLinkSectionDocument, options);
}
export type FeatureExternalLinkSectionQueryHookResult = ReturnType<
  typeof useFeatureExternalLinkSectionQuery
>;
export type FeatureExternalLinkSectionLazyQueryHookResult = ReturnType<
  typeof useFeatureExternalLinkSectionLazyQuery
>;
export type FeatureExternalLinkSectionQueryResult = Apollo.QueryResult<
  IFeatureExternalLinkSectionQuery,
  IFeatureExternalLinkSectionQueryVariables
>;
export const FeatureFeesAndTaxesDocument = gql`
  query featureFeesAndTaxes($featureFeesAndTaxesId: ID!) {
    FeatureFeesAndTaxes(id: $featureFeesAndTaxesId) {
      _id
      bagFeeConfigurations {
        bagTaxExplainer {
          locale: en
        }
      }
    }
  }
`;

/**
 * __useFeatureFeesAndTaxesQuery__
 *
 * To run a query within a React component, call `useFeatureFeesAndTaxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFeesAndTaxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFeesAndTaxesQuery({
 *   variables: {
 *      featureFeesAndTaxesId: // value for 'featureFeesAndTaxesId'
 *   },
 * });
 */
export function useFeatureFeesAndTaxesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureFeesAndTaxesQuery,
    IFeatureFeesAndTaxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureFeesAndTaxesQuery, IFeatureFeesAndTaxesQueryVariables>(
    FeatureFeesAndTaxesDocument,
    options
  );
}
export function useFeatureFeesAndTaxesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureFeesAndTaxesQuery,
    IFeatureFeesAndTaxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureFeesAndTaxesQuery,
    IFeatureFeesAndTaxesQueryVariables
  >(FeatureFeesAndTaxesDocument, options);
}
export type FeatureFeesAndTaxesQueryHookResult = ReturnType<typeof useFeatureFeesAndTaxesQuery>;
export type FeatureFeesAndTaxesLazyQueryHookResult = ReturnType<
  typeof useFeatureFeesAndTaxesLazyQuery
>;
export type FeatureFeesAndTaxesQueryResult = Apollo.QueryResult<
  IFeatureFeesAndTaxesQuery,
  IFeatureFeesAndTaxesQueryVariables
>;
export const FeatureFooterDocument = gql`
  query featureFooter($featureFooterId: ID!) {
    FeatureFooter(id: $featureFooterId) {
      rows {
        _key
        columns {
          _key
          options {
            ... on CopyrightAndLanguageSelector {
              ...CopyrightAndLanguageSelectorFragment
            }
            ... on HighlightedVerticalLinkListWidget {
              ...HighlightedVerticalLinkListWidgetFragment
            }
            ... on HorizontalLinkListWidget {
              ...HorizontalLinkListWidgetFragment
            }
            ... on ImageWidget {
              ...ImageWidgetFragment
            }
            ... on SocialIconsWithHeaderWidget {
              ...SocialIconsWithHeaderWidgetFragment
            }
            ... on TextWidget {
              ...TextWidgetFragment
            }
            ... on TextWidgetWithUrl {
              ...TextWidgetWithUrlFragment
            }
            ... on VerticalLinkListWidget {
              ...VerticalLinkListWidgetFragment
            }
            ... on VerticalLinkListWithHeaderWidget {
              ...VerticalLinkListWithHeaderWidgetFragment
            }
            ... on WordmarkAndSocialIcons {
              ...WordmarkAndSocialIconsFragment
            }
          }
        }
      }
    }
  }
  ${CopyrightAndLanguageSelectorFragmentDoc}
  ${HighlightedVerticalLinkListWidgetFragmentDoc}
  ${HorizontalLinkListWidgetFragmentDoc}
  ${ImageWidgetFragmentDoc}
  ${SocialIconsWithHeaderWidgetFragmentDoc}
  ${TextWidgetFragmentDoc}
  ${TextWidgetWithUrlFragmentDoc}
  ${VerticalLinkListWidgetFragmentDoc}
  ${VerticalLinkListWithHeaderWidgetFragmentDoc}
  ${WordmarkAndSocialIconsFragmentDoc}
`;

/**
 * __useFeatureFooterQuery__
 *
 * To run a query within a React component, call `useFeatureFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFooterQuery({
 *   variables: {
 *      featureFooterId: // value for 'featureFooterId'
 *   },
 * });
 */
export function useFeatureFooterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFeatureFooterQuery, IFeatureFooterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureFooterQuery, IFeatureFooterQueryVariables>(
    FeatureFooterDocument,
    options
  );
}
export function useFeatureFooterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureFooterQuery,
    IFeatureFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureFooterQuery, IFeatureFooterQueryVariables>(
    FeatureFooterDocument,
    options
  );
}
export type FeatureFooterQueryHookResult = ReturnType<typeof useFeatureFooterQuery>;
export type FeatureFooterLazyQueryHookResult = ReturnType<typeof useFeatureFooterLazyQuery>;
export type FeatureFooterQueryResult = Apollo.QueryResult<
  IFeatureFooterQuery,
  IFeatureFooterQueryVariables
>;
export const FeatureGeolocationMapMarkersDocument = gql`
  query featureGeolocationMapMarkers($featureGeolocationMapMarkersId: ID!) {
    FeatureGeolocationMapMarkers(id: $featureGeolocationMapMarkersId) {
      _id
      driverIcon {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureGeolocationMapMarkersQuery__
 *
 * To run a query within a React component, call `useFeatureGeolocationMapMarkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureGeolocationMapMarkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureGeolocationMapMarkersQuery({
 *   variables: {
 *      featureGeolocationMapMarkersId: // value for 'featureGeolocationMapMarkersId'
 *   },
 * });
 */
export function useFeatureGeolocationMapMarkersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureGeolocationMapMarkersQuery,
    IFeatureGeolocationMapMarkersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureGeolocationMapMarkersQuery,
    IFeatureGeolocationMapMarkersQueryVariables
  >(FeatureGeolocationMapMarkersDocument, options);
}
export function useFeatureGeolocationMapMarkersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureGeolocationMapMarkersQuery,
    IFeatureGeolocationMapMarkersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureGeolocationMapMarkersQuery,
    IFeatureGeolocationMapMarkersQueryVariables
  >(FeatureGeolocationMapMarkersDocument, options);
}
export type FeatureGeolocationMapMarkersQueryHookResult = ReturnType<
  typeof useFeatureGeolocationMapMarkersQuery
>;
export type FeatureGeolocationMapMarkersLazyQueryHookResult = ReturnType<
  typeof useFeatureGeolocationMapMarkersLazyQuery
>;
export type FeatureGeolocationMapMarkersQueryResult = Apollo.QueryResult<
  IFeatureGeolocationMapMarkersQuery,
  IFeatureGeolocationMapMarkersQueryVariables
>;
export const FeatureGeolocationModalDocument = gql`
  query featureGeolocationModal($featureGeolocationModalId: ID!) {
    FeatureGeolocationModal(id: $featureGeolocationModalId) {
      header {
        locale: en
      }
      body {
        locale: en
      }
    }
  }
`;

/**
 * __useFeatureGeolocationModalQuery__
 *
 * To run a query within a React component, call `useFeatureGeolocationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureGeolocationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureGeolocationModalQuery({
 *   variables: {
 *      featureGeolocationModalId: // value for 'featureGeolocationModalId'
 *   },
 * });
 */
export function useFeatureGeolocationModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureGeolocationModalQuery,
    IFeatureGeolocationModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureGeolocationModalQuery,
    IFeatureGeolocationModalQueryVariables
  >(FeatureGeolocationModalDocument, options);
}
export function useFeatureGeolocationModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureGeolocationModalQuery,
    IFeatureGeolocationModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureGeolocationModalQuery,
    IFeatureGeolocationModalQueryVariables
  >(FeatureGeolocationModalDocument, options);
}
export type FeatureGeolocationModalQueryHookResult = ReturnType<
  typeof useFeatureGeolocationModalQuery
>;
export type FeatureGeolocationModalLazyQueryHookResult = ReturnType<
  typeof useFeatureGeolocationModalLazyQuery
>;
export type FeatureGeolocationModalQueryResult = Apollo.QueryResult<
  IFeatureGeolocationModalQuery,
  IFeatureGeolocationModalQueryVariables
>;
export const FeatureWebHomePageDocument = gql`
  query featureWebHomePage($featureHomePageId: ID!) {
    FeatureHomePage(id: $featureHomePageId) {
      unauthenticated: webUnauthenticated {
        ...HomePageConfigurationFragment
      }
      authenticated: webAuthenticated {
        ...HomePageConfigurationFragment
      }
    }
  }
  ${HomePageConfigurationFragmentDoc}
`;

/**
 * __useFeatureWebHomePageQuery__
 *
 * To run a query within a React component, call `useFeatureWebHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureWebHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureWebHomePageQuery({
 *   variables: {
 *      featureHomePageId: // value for 'featureHomePageId'
 *   },
 * });
 */
export function useFeatureWebHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureWebHomePageQuery,
    IFeatureWebHomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureWebHomePageQuery, IFeatureWebHomePageQueryVariables>(
    FeatureWebHomePageDocument,
    options
  );
}
export function useFeatureWebHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureWebHomePageQuery,
    IFeatureWebHomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureWebHomePageQuery, IFeatureWebHomePageQueryVariables>(
    FeatureWebHomePageDocument,
    options
  );
}
export type FeatureWebHomePageQueryHookResult = ReturnType<typeof useFeatureWebHomePageQuery>;
export type FeatureWebHomePageLazyQueryHookResult = ReturnType<
  typeof useFeatureWebHomePageLazyQuery
>;
export type FeatureWebHomePageQueryResult = Apollo.QueryResult<
  IFeatureWebHomePageQuery,
  IFeatureWebHomePageQueryVariables
>;
export const FeatureAppHomePageDocument = gql`
  query featureAppHomePage($featureHomePageId: ID!) {
    FeatureHomePage(id: $featureHomePageId) {
      unauthenticated: appUnauthenticated {
        ...HomePageConfigurationFragment
      }
      authenticated: appAuthenticated {
        ...HomePageConfigurationFragment
      }
    }
  }
  ${HomePageConfigurationFragmentDoc}
`;

/**
 * __useFeatureAppHomePageQuery__
 *
 * To run a query within a React component, call `useFeatureAppHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureAppHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureAppHomePageQuery({
 *   variables: {
 *      featureHomePageId: // value for 'featureHomePageId'
 *   },
 * });
 */
export function useFeatureAppHomePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureAppHomePageQuery,
    IFeatureAppHomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureAppHomePageQuery, IFeatureAppHomePageQueryVariables>(
    FeatureAppHomePageDocument,
    options
  );
}
export function useFeatureAppHomePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureAppHomePageQuery,
    IFeatureAppHomePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureAppHomePageQuery, IFeatureAppHomePageQueryVariables>(
    FeatureAppHomePageDocument,
    options
  );
}
export type FeatureAppHomePageQueryHookResult = ReturnType<typeof useFeatureAppHomePageQuery>;
export type FeatureAppHomePageLazyQueryHookResult = ReturnType<
  typeof useFeatureAppHomePageLazyQuery
>;
export type FeatureAppHomePageQueryResult = Apollo.QueryResult<
  IFeatureAppHomePageQuery,
  IFeatureAppHomePageQueryVariables
>;
export const MainHeroDocument = gql`
  query mainHero($mainHeroId: ID!) {
    MainHero(id: $mainHeroId) {
      ...MainHeroFragment
    }
  }
  ${MainHeroFragmentDoc}
`;

/**
 * __useMainHeroQuery__
 *
 * To run a query within a React component, call `useMainHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainHeroQuery({
 *   variables: {
 *      mainHeroId: // value for 'mainHeroId'
 *   },
 * });
 */
export function useMainHeroQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IMainHeroQuery, IMainHeroQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IMainHeroQuery, IMainHeroQueryVariables>(
    MainHeroDocument,
    options
  );
}
export function useMainHeroLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IMainHeroQuery, IMainHeroQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IMainHeroQuery, IMainHeroQueryVariables>(
    MainHeroDocument,
    options
  );
}
export type MainHeroQueryHookResult = ReturnType<typeof useMainHeroQuery>;
export type MainHeroLazyQueryHookResult = ReturnType<typeof useMainHeroLazyQuery>;
export type MainHeroQueryResult = Apollo.QueryResult<IMainHeroQuery, IMainHeroQueryVariables>;
export const HeroDocument = gql`
  query hero($heroId: ID!) {
    Hero(id: $heroId) {
      ...HeroFragment
    }
  }
  ${HeroFragmentDoc}
`;

/**
 * __useHeroQuery__
 *
 * To run a query within a React component, call `useHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroQuery({
 *   variables: {
 *      heroId: // value for 'heroId'
 *   },
 * });
 */
export function useHeroQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IHeroQuery, IHeroQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IHeroQuery, IHeroQueryVariables>(HeroDocument, options);
}
export function useHeroLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IHeroQuery, IHeroQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IHeroQuery, IHeroQueryVariables>(HeroDocument, options);
}
export type HeroQueryHookResult = ReturnType<typeof useHeroQuery>;
export type HeroLazyQueryHookResult = ReturnType<typeof useHeroLazyQuery>;
export type HeroQueryResult = Apollo.QueryResult<IHeroQuery, IHeroQueryVariables>;
export const BrazeContentCardsDocument = gql`
  query brazeContentCards($brazeContentCardsId: ID!) {
    BrazeContentCards(id: $brazeContentCardsId) {
      ...BrazeContentCardsFragment
    }
  }
  ${BrazeContentCardsFragmentDoc}
`;

/**
 * __useBrazeContentCardsQuery__
 *
 * To run a query within a React component, call `useBrazeContentCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrazeContentCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrazeContentCardsQuery({
 *   variables: {
 *      brazeContentCardsId: // value for 'brazeContentCardsId'
 *   },
 * });
 */
export function useBrazeContentCardsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IBrazeContentCardsQuery,
    IBrazeContentCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IBrazeContentCardsQuery, IBrazeContentCardsQueryVariables>(
    BrazeContentCardsDocument,
    options
  );
}
export function useBrazeContentCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IBrazeContentCardsQuery,
    IBrazeContentCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IBrazeContentCardsQuery, IBrazeContentCardsQueryVariables>(
    BrazeContentCardsDocument,
    options
  );
}
export type BrazeContentCardsQueryHookResult = ReturnType<typeof useBrazeContentCardsQuery>;
export type BrazeContentCardsLazyQueryHookResult = ReturnType<typeof useBrazeContentCardsLazyQuery>;
export type BrazeContentCardsQueryResult = Apollo.QueryResult<
  IBrazeContentCardsQuery,
  IBrazeContentCardsQueryVariables
>;
export const AlertDocument = gql`
  query alert($alertId: ID!) {
    Alert(id: $alertId) {
      ...AlertFrangment
    }
  }
  ${AlertFrangmentFragmentDoc}
`;

/**
 * __useAlertQuery__
 *
 * To run a query within a React component, call `useAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useAlertQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IAlertQuery, IAlertQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IAlertQuery, IAlertQueryVariables>(AlertDocument, options);
}
export function useAlertLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IAlertQuery, IAlertQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IAlertQuery, IAlertQueryVariables>(AlertDocument, options);
}
export type AlertQueryHookResult = ReturnType<typeof useAlertQuery>;
export type AlertLazyQueryHookResult = ReturnType<typeof useAlertLazyQuery>;
export type AlertQueryResult = Apollo.QueryResult<IAlertQuery, IAlertQueryVariables>;
export const MarketingCardGroupsDocument = gql`
  query marketingCardGroups($where: MarketingCardGroupFilter) {
    allMarketingCardGroup(where: $where) {
      ... on MarketingCardGroup {
        ...MarketingCardGroupFragment
      }
    }
  }
  ${MarketingCardGroupFragmentDoc}
`;

/**
 * __useMarketingCardGroupsQuery__
 *
 * To run a query within a React component, call `useMarketingCardGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingCardGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingCardGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMarketingCardGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IMarketingCardGroupsQuery,
    IMarketingCardGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IMarketingCardGroupsQuery, IMarketingCardGroupsQueryVariables>(
    MarketingCardGroupsDocument,
    options
  );
}
export function useMarketingCardGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IMarketingCardGroupsQuery,
    IMarketingCardGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IMarketingCardGroupsQuery,
    IMarketingCardGroupsQueryVariables
  >(MarketingCardGroupsDocument, options);
}
export type MarketingCardGroupsQueryHookResult = ReturnType<typeof useMarketingCardGroupsQuery>;
export type MarketingCardGroupsLazyQueryHookResult = ReturnType<
  typeof useMarketingCardGroupsLazyQuery
>;
export type MarketingCardGroupsQueryResult = Apollo.QueryResult<
  IMarketingCardGroupsQuery,
  IMarketingCardGroupsQueryVariables
>;
export const MarketingTileCarouselDocument = gql`
  query marketingTileCarousel($marketingTileCarouselId: ID!) {
    MarketingTileCarousel(id: $marketingTileCarouselId) {
      ...MarketingTileCarouselFragment
    }
  }
  ${MarketingTileCarouselFragmentDoc}
`;

/**
 * __useMarketingTileCarouselQuery__
 *
 * To run a query within a React component, call `useMarketingTileCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingTileCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingTileCarouselQuery({
 *   variables: {
 *      marketingTileCarouselId: // value for 'marketingTileCarouselId'
 *   },
 * });
 */
export function useMarketingTileCarouselQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IMarketingTileCarouselQuery,
    IMarketingTileCarouselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IMarketingTileCarouselQuery,
    IMarketingTileCarouselQueryVariables
  >(MarketingTileCarouselDocument, options);
}
export function useMarketingTileCarouselLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IMarketingTileCarouselQuery,
    IMarketingTileCarouselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IMarketingTileCarouselQuery,
    IMarketingTileCarouselQueryVariables
  >(MarketingTileCarouselDocument, options);
}
export type MarketingTileCarouselQueryHookResult = ReturnType<typeof useMarketingTileCarouselQuery>;
export type MarketingTileCarouselLazyQueryHookResult = ReturnType<
  typeof useMarketingTileCarouselLazyQuery
>;
export type MarketingTileCarouselQueryResult = Apollo.QueryResult<
  IMarketingTileCarouselQuery,
  IMarketingTileCarouselQueryVariables
>;
export const MarketingTileGroupsDocument = gql`
  query marketingTileGroups($where: MarketingTileGroupFilter) {
    allMarketingTileGroup(where: $where) {
      ... on MarketingTileGroup {
        ...MarketingTileGroupFragment
      }
    }
  }
  ${MarketingTileGroupFragmentDoc}
`;

/**
 * __useMarketingTileGroupsQuery__
 *
 * To run a query within a React component, call `useMarketingTileGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingTileGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingTileGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMarketingTileGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IMarketingTileGroupsQuery,
    IMarketingTileGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IMarketingTileGroupsQuery, IMarketingTileGroupsQueryVariables>(
    MarketingTileGroupsDocument,
    options
  );
}
export function useMarketingTileGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IMarketingTileGroupsQuery,
    IMarketingTileGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IMarketingTileGroupsQuery,
    IMarketingTileGroupsQueryVariables
  >(MarketingTileGroupsDocument, options);
}
export type MarketingTileGroupsQueryHookResult = ReturnType<typeof useMarketingTileGroupsQuery>;
export type MarketingTileGroupsLazyQueryHookResult = ReturnType<
  typeof useMarketingTileGroupsLazyQuery
>;
export type MarketingTileGroupsQueryResult = Apollo.QueryResult<
  IMarketingTileGroupsQuery,
  IMarketingTileGroupsQueryVariables
>;
export const TrendingDocument = gql`
  query trending($trendingId: ID!) {
    Trending(id: $trendingId) {
      ...TrendingFragment
    }
  }
  ${TrendingFragmentDoc}
`;

/**
 * __useTrendingQuery__
 *
 * To run a query within a React component, call `useTrendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingQuery({
 *   variables: {
 *      trendingId: // value for 'trendingId'
 *   },
 * });
 */
export function useTrendingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ITrendingQuery, ITrendingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ITrendingQuery, ITrendingQueryVariables>(
    TrendingDocument,
    options
  );
}
export function useTrendingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ITrendingQuery, ITrendingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ITrendingQuery, ITrendingQueryVariables>(
    TrendingDocument,
    options
  );
}
export type TrendingQueryHookResult = ReturnType<typeof useTrendingQuery>;
export type TrendingLazyQueryHookResult = ReturnType<typeof useTrendingLazyQuery>;
export type TrendingQueryResult = Apollo.QueryResult<ITrendingQuery, ITrendingQueryVariables>;
export const FanFavoritesDocument = gql`
  query fanFavorites($fanFavoritesId: ID!) {
    FanFavorites(id: $fanFavoritesId) {
      ...FanFavoritesFragment
    }
  }
  ${FanFavoritesFragmentDoc}
`;

/**
 * __useFanFavoritesQuery__
 *
 * To run a query within a React component, call `useFanFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFanFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFanFavoritesQuery({
 *   variables: {
 *      fanFavoritesId: // value for 'fanFavoritesId'
 *   },
 * });
 */
export function useFanFavoritesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFanFavoritesQuery, IFanFavoritesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFanFavoritesQuery, IFanFavoritesQueryVariables>(
    FanFavoritesDocument,
    options
  );
}
export function useFanFavoritesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFanFavoritesQuery,
    IFanFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFanFavoritesQuery, IFanFavoritesQueryVariables>(
    FanFavoritesDocument,
    options
  );
}
export type FanFavoritesQueryHookResult = ReturnType<typeof useFanFavoritesQuery>;
export type FanFavoritesLazyQueryHookResult = ReturnType<typeof useFanFavoritesLazyQuery>;
export type FanFavoritesQueryResult = Apollo.QueryResult<
  IFanFavoritesQuery,
  IFanFavoritesQueryVariables
>;
export const OurServicesDocument = gql`
  query ourServices($ourServicesId: ID!) {
    OurServices(id: $ourServicesId) {
      ...OurServicesFragment
    }
  }
  ${OurServicesFragmentDoc}
`;

/**
 * __useOurServicesQuery__
 *
 * To run a query within a React component, call `useOurServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOurServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOurServicesQuery({
 *   variables: {
 *      ourServicesId: // value for 'ourServicesId'
 *   },
 * });
 */
export function useOurServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IOurServicesQuery, IOurServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IOurServicesQuery, IOurServicesQueryVariables>(
    OurServicesDocument,
    options
  );
}
export function useOurServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IOurServicesQuery, IOurServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IOurServicesQuery, IOurServicesQueryVariables>(
    OurServicesDocument,
    options
  );
}
export type OurServicesQueryHookResult = ReturnType<typeof useOurServicesQuery>;
export type OurServicesLazyQueryHookResult = ReturnType<typeof useOurServicesLazyQuery>;
export type OurServicesQueryResult = Apollo.QueryResult<
  IOurServicesQuery,
  IOurServicesQueryVariables
>;
export const AppDownloadDocument = gql`
  query appDownload($appDownloadId: ID!) {
    AppDownload(id: $appDownloadId) {
      ...AppDownloadFragment
    }
  }
  ${AppDownloadFragmentDoc}
`;

/**
 * __useAppDownloadQuery__
 *
 * To run a query within a React component, call `useAppDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDownloadQuery({
 *   variables: {
 *      appDownloadId: // value for 'appDownloadId'
 *   },
 * });
 */
export function useAppDownloadQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IAppDownloadQuery, IAppDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IAppDownloadQuery, IAppDownloadQueryVariables>(
    AppDownloadDocument,
    options
  );
}
export function useAppDownloadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IAppDownloadQuery, IAppDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IAppDownloadQuery, IAppDownloadQueryVariables>(
    AppDownloadDocument,
    options
  );
}
export type AppDownloadQueryHookResult = ReturnType<typeof useAppDownloadQuery>;
export type AppDownloadLazyQueryHookResult = ReturnType<typeof useAppDownloadLazyQuery>;
export type AppDownloadQueryResult = Apollo.QueryResult<
  IAppDownloadQuery,
  IAppDownloadQueryVariables
>;
export const RewardsSectionDocument = gql`
  query rewardsSection($rewardsSectionId: ID!) {
    RewardsSection(id: $rewardsSectionId) {
      ...RewardsSectionFragment
    }
  }
  ${RewardsSectionFragmentDoc}
`;

/**
 * __useRewardsSectionQuery__
 *
 * To run a query within a React component, call `useRewardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsSectionQuery({
 *   variables: {
 *      rewardsSectionId: // value for 'rewardsSectionId'
 *   },
 * });
 */
export function useRewardsSectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IRewardsSectionQuery,
    IRewardsSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IRewardsSectionQuery, IRewardsSectionQueryVariables>(
    RewardsSectionDocument,
    options
  );
}
export function useRewardsSectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IRewardsSectionQuery,
    IRewardsSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IRewardsSectionQuery, IRewardsSectionQueryVariables>(
    RewardsSectionDocument,
    options
  );
}
export type RewardsSectionQueryHookResult = ReturnType<typeof useRewardsSectionQuery>;
export type RewardsSectionLazyQueryHookResult = ReturnType<typeof useRewardsSectionLazyQuery>;
export type RewardsSectionQueryResult = Apollo.QueryResult<
  IRewardsSectionQuery,
  IRewardsSectionQueryVariables
>;
export const OfferCarouselSectionDocument = gql`
  query offerCarouselSection($offerCarouselId: ID!) {
    OfferCarouselSection(id: $offerCarouselId) {
      ...OfferCarouselFragment
    }
  }
  ${OfferCarouselFragmentDoc}
`;

/**
 * __useOfferCarouselSectionQuery__
 *
 * To run a query within a React component, call `useOfferCarouselSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferCarouselSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferCarouselSectionQuery({
 *   variables: {
 *      offerCarouselId: // value for 'offerCarouselId'
 *   },
 * });
 */
export function useOfferCarouselSectionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IOfferCarouselSectionQuery,
    IOfferCarouselSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IOfferCarouselSectionQuery, IOfferCarouselSectionQueryVariables>(
    OfferCarouselSectionDocument,
    options
  );
}
export function useOfferCarouselSectionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IOfferCarouselSectionQuery,
    IOfferCarouselSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IOfferCarouselSectionQuery,
    IOfferCarouselSectionQueryVariables
  >(OfferCarouselSectionDocument, options);
}
export type OfferCarouselSectionQueryHookResult = ReturnType<typeof useOfferCarouselSectionQuery>;
export type OfferCarouselSectionLazyQueryHookResult = ReturnType<
  typeof useOfferCarouselSectionLazyQuery
>;
export type OfferCarouselSectionQueryResult = Apollo.QueryResult<
  IOfferCarouselSectionQuery,
  IOfferCarouselSectionQueryVariables
>;
export const FeatureIFrameDocument = gql`
  query featureIFrame($featureIFrameId: ID!) {
    FeatureIFrame(id: $featureIFrameId) {
      iFrameCampaignUrlWeb {
        locale: en
      }
      iFrameCampaignUrlApp {
        locale: en
      }
      iFrameAvailableForAthenticatedUsers
      passLoyaltyIdToIframe
      passCognitoIdToIframe
      enableIFrameComponent
    }
  }
`;

/**
 * __useFeatureIFrameQuery__
 *
 * To run a query within a React component, call `useFeatureIFrameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureIFrameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureIFrameQuery({
 *   variables: {
 *      featureIFrameId: // value for 'featureIFrameId'
 *   },
 * });
 */
export function useFeatureIFrameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFeatureIFrameQuery, IFeatureIFrameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureIFrameQuery, IFeatureIFrameQueryVariables>(
    FeatureIFrameDocument,
    options
  );
}
export function useFeatureIFrameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureIFrameQuery,
    IFeatureIFrameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureIFrameQuery, IFeatureIFrameQueryVariables>(
    FeatureIFrameDocument,
    options
  );
}
export type FeatureIFrameQueryHookResult = ReturnType<typeof useFeatureIFrameQuery>;
export type FeatureIFrameLazyQueryHookResult = ReturnType<typeof useFeatureIFrameLazyQuery>;
export type FeatureIFrameQueryResult = Apollo.QueryResult<
  IFeatureIFrameQuery,
  IFeatureIFrameQueryVariables
>;
export const FeatureInvitationCodeDocument = gql`
  query featureInvitationCode($featureInvitationCodeId: ID!) {
    FeatureInvitationCode(id: $featureInvitationCodeId) {
      enableInvitationCode
      invitationCodeTitle {
        locale: en
      }
      shareMyCodeButtonText {
        locale: en
      }
      shareMyCodeButtonUrl {
        locale: en
      }
      termsAndConditions {
        locale: enRaw
      }
      invitationCodeUnlocksBenefitModal {
        title {
          locale: en
        }
        description {
          locale: en
        }
        shareMyCodeButtonText {
          locale: en
        }
        shareMyCodeButtonLink {
          locale: en
        }
      }
      invitationCodeSignInModal {
        title {
          locale: en
        }
        description {
          locale: en
        }
        shareMyCodeButtonText {
          locale: en
        }
        shareMyCodeButtonLink {
          locale: en
        }
      }
      invitationCodeInformationCards {
        cardImage {
          ...ImageFragment
        }
        cardImageBackgroundColor {
          ...ColorFragment
        }
        cardTitle {
          locale: en
        }
        cardDescription {
          locale: enRaw
        }
      }
      invitationCodeBenefit {
        loyaltyEngineId
        name {
          locale: en
        }
        internalName
        description {
          localeRaw: enRaw
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
        howToRedeem {
          localeRaw: enRaw
        }
        moreInfo {
          localeRaw: enRaw
        }
        incentive {
          offer {
            ...ConfigOfferFragment
          }
          bonusPoints
          type
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${ColorFragmentDoc}
  ${ConfigOfferFragmentDoc}
`;

/**
 * __useFeatureInvitationCodeQuery__
 *
 * To run a query within a React component, call `useFeatureInvitationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureInvitationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureInvitationCodeQuery({
 *   variables: {
 *      featureInvitationCodeId: // value for 'featureInvitationCodeId'
 *   },
 * });
 */
export function useFeatureInvitationCodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureInvitationCodeQuery,
    IFeatureInvitationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureInvitationCodeQuery,
    IFeatureInvitationCodeQueryVariables
  >(FeatureInvitationCodeDocument, options);
}
export function useFeatureInvitationCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureInvitationCodeQuery,
    IFeatureInvitationCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureInvitationCodeQuery,
    IFeatureInvitationCodeQueryVariables
  >(FeatureInvitationCodeDocument, options);
}
export type FeatureInvitationCodeQueryHookResult = ReturnType<typeof useFeatureInvitationCodeQuery>;
export type FeatureInvitationCodeLazyQueryHookResult = ReturnType<
  typeof useFeatureInvitationCodeLazyQuery
>;
export type FeatureInvitationCodeQueryResult = Apollo.QueryResult<
  IFeatureInvitationCodeQuery,
  IFeatureInvitationCodeQueryVariables
>;
export const FeatureLayoutDocument = gql`
  query featureLayout($featureLayoutId: ID!) {
    FeatureLayout(id: $featureLayoutId) {
      _id
      defaultHeroImage {
        locale: en {
          ...ImageFragment
        }
      }
      recentOrderHeroText {
        locale: en
      }
      loadingGraphics {
        ...LoadingGraphicsFragment
      }
      geolocationModalImage {
        locale: en {
          ...ImageFragment
        }
      }
      appleWalletImage {
        locale: en {
          ...ImageFragment
        }
      }
      googlePayImage {
        locale: en {
          ...ImageFragment
        }
      }
      giftCardImage {
        locale: en {
          ...ImageFragment
        }
      }
      giftCardTextImage {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${LoadingGraphicsFragmentDoc}
`;

/**
 * __useFeatureLayoutQuery__
 *
 * To run a query within a React component, call `useFeatureLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLayoutQuery({
 *   variables: {
 *      featureLayoutId: // value for 'featureLayoutId'
 *   },
 * });
 */
export function useFeatureLayoutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFeatureLayoutQuery, IFeatureLayoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureLayoutQuery, IFeatureLayoutQueryVariables>(
    FeatureLayoutDocument,
    options
  );
}
export function useFeatureLayoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLayoutQuery,
    IFeatureLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureLayoutQuery, IFeatureLayoutQueryVariables>(
    FeatureLayoutDocument,
    options
  );
}
export type FeatureLayoutQueryHookResult = ReturnType<typeof useFeatureLayoutQuery>;
export type FeatureLayoutLazyQueryHookResult = ReturnType<typeof useFeatureLayoutLazyQuery>;
export type FeatureLayoutQueryResult = Apollo.QueryResult<
  IFeatureLayoutQuery,
  IFeatureLayoutQueryVariables
>;
export const FeatureLoyaltyLinkPhysicalCardContentDocument = gql`
  query featureLoyaltyLinkPhysicalCardContent($featureLinkPhysicalCardUIId: ID!) {
    LinkPhysicalCardUi(id: $featureLinkPhysicalCardUIId) {
      _id
      linkCardSectionLinkedState {
        ...LinkPhysicalCardSectionFragment
      }
      linkCardSectionUnlinkedState {
        ...LinkPhysicalCardSectionFragment
      }
      linkCardFaqSection {
        ...LinkPhysicalCardFaqFragment
      }
      linkCardActivationSuccessModal {
        ...LinkCardActivationSuccessModalFragment
      }
      linkCardActivationWithRewardSuccessModal {
        ...LinkCardActivationSuccessModalFragment
      }
      linkCardErrorModalGeneric {
        ...LinkCardErrorModalFragment
      }
      linkCardErrorModalLinked {
        ...LinkCardErrorModalFragment
      }
      linkCardErrorModalReplace {
        ...LinkCardErrorModalFragment
      }
      linkCardDeactivationModal {
        ...LinkCardDefaultModalFragment
      }
    }
  }
  ${LinkPhysicalCardSectionFragmentDoc}
  ${LinkPhysicalCardFaqFragmentDoc}
  ${LinkCardActivationSuccessModalFragmentDoc}
  ${LinkCardErrorModalFragmentDoc}
  ${LinkCardDefaultModalFragmentDoc}
`;

/**
 * __useFeatureLoyaltyLinkPhysicalCardContentQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyLinkPhysicalCardContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyLinkPhysicalCardContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyLinkPhysicalCardContentQuery({
 *   variables: {
 *      featureLinkPhysicalCardUIId: // value for 'featureLinkPhysicalCardUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyLinkPhysicalCardContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyLinkPhysicalCardContentQuery,
    IFeatureLoyaltyLinkPhysicalCardContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyLinkPhysicalCardContentQuery,
    IFeatureLoyaltyLinkPhysicalCardContentQueryVariables
  >(FeatureLoyaltyLinkPhysicalCardContentDocument, options);
}
export function useFeatureLoyaltyLinkPhysicalCardContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyLinkPhysicalCardContentQuery,
    IFeatureLoyaltyLinkPhysicalCardContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyLinkPhysicalCardContentQuery,
    IFeatureLoyaltyLinkPhysicalCardContentQueryVariables
  >(FeatureLoyaltyLinkPhysicalCardContentDocument, options);
}
export type FeatureLoyaltyLinkPhysicalCardContentQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyLinkPhysicalCardContentQuery
>;
export type FeatureLoyaltyLinkPhysicalCardContentLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyLinkPhysicalCardContentLazyQuery
>;
export type FeatureLoyaltyLinkPhysicalCardContentQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyLinkPhysicalCardContentQuery,
  IFeatureLoyaltyLinkPhysicalCardContentQueryVariables
>;
export const FeatureLocationTermsDocument = gql`
  query featureLocationTerms($featureLocationTermsId: ID!) {
    FeatureGeolocationTermsModal(id: $featureLocationTermsId) {
      _id
      geolocationTermsModalTitle
      geolocationTermsContent {
        enRaw
      }
    }
  }
`;

/**
 * __useFeatureLocationTermsQuery__
 *
 * To run a query within a React component, call `useFeatureLocationTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLocationTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLocationTermsQuery({
 *   variables: {
 *      featureLocationTermsId: // value for 'featureLocationTermsId'
 *   },
 * });
 */
export function useFeatureLocationTermsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLocationTermsQuery,
    IFeatureLocationTermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureLocationTermsQuery, IFeatureLocationTermsQueryVariables>(
    FeatureLocationTermsDocument,
    options
  );
}
export function useFeatureLocationTermsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLocationTermsQuery,
    IFeatureLocationTermsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLocationTermsQuery,
    IFeatureLocationTermsQueryVariables
  >(FeatureLocationTermsDocument, options);
}
export type FeatureLocationTermsQueryHookResult = ReturnType<typeof useFeatureLocationTermsQuery>;
export type FeatureLocationTermsLazyQueryHookResult = ReturnType<
  typeof useFeatureLocationTermsLazyQuery
>;
export type FeatureLocationTermsQueryResult = Apollo.QueryResult<
  IFeatureLocationTermsQuery,
  IFeatureLocationTermsQueryVariables
>;
export const FeatureLockersDocument = gql`
  query featureLockers($featureLockersId: ID!) {
    FeatureLockers(id: $featureLockersId) {
      lockersPinInstructions {
        locale: enRaw
      }
      lockersTitle {
        locale: en
      }
      lockersModalImage {
        locale: en {
          ...ImageFragment
        }
      }
      lockersDescription {
        locale: enRaw
      }
      lockersCodeTitle {
        locale: enRaw
      }
      lockersViewOrderButtonText {
        locale: en
      }
      lockersHowToUseLockersButtonText {
        locale: en
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLockersQuery__
 *
 * To run a query within a React component, call `useFeatureLockersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLockersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLockersQuery({
 *   variables: {
 *      featureLockersId: // value for 'featureLockersId'
 *   },
 * });
 */
export function useFeatureLockersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLockersQuery,
    IFeatureLockersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureLockersQuery, IFeatureLockersQueryVariables>(
    FeatureLockersDocument,
    options
  );
}
export function useFeatureLockersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLockersQuery,
    IFeatureLockersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureLockersQuery, IFeatureLockersQueryVariables>(
    FeatureLockersDocument,
    options
  );
}
export type FeatureLockersQueryHookResult = ReturnType<typeof useFeatureLockersQuery>;
export type FeatureLockersLazyQueryHookResult = ReturnType<typeof useFeatureLockersLazyQuery>;
export type FeatureLockersQueryResult = Apollo.QueryResult<
  IFeatureLockersQuery,
  IFeatureLockersQueryVariables
>;
export const FeatureLoyaltyBackgroundDocument = gql`
  query featureLoyaltyBackground($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      imageBackground {
        image {
          ...ref
        }
      }
    }
  }
  ${RefFragmentDoc}
`;

/**
 * __useFeatureLoyaltyBackgroundQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyBackgroundQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyBackgroundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyBackgroundQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyBackgroundQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyBackgroundQuery,
    IFeatureLoyaltyBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyBackgroundQuery,
    IFeatureLoyaltyBackgroundQueryVariables
  >(FeatureLoyaltyBackgroundDocument, options);
}
export function useFeatureLoyaltyBackgroundLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyBackgroundQuery,
    IFeatureLoyaltyBackgroundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyBackgroundQuery,
    IFeatureLoyaltyBackgroundQueryVariables
  >(FeatureLoyaltyBackgroundDocument, options);
}
export type FeatureLoyaltyBackgroundQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyBackgroundQuery
>;
export type FeatureLoyaltyBackgroundLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyBackgroundLazyQuery
>;
export type FeatureLoyaltyBackgroundQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyBackgroundQuery,
  IFeatureLoyaltyBackgroundQueryVariables
>;
export const FeatureLoyaltyDashboardDocument = gql`
  query featureLoyaltyDashboard($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      loyaltyDashboard {
        widgets {
          __typename
          ... on LoyaltyGreetingWidget {
            _key
            componentKey
          }
          ... on LoyaltyLinkWidget {
            _key
            componentKey
            links {
              _key
              text {
                locale: en
              }
              link {
                locale: en
              }
            }
          }
          ... on LoyaltyHistoryWidget {
            _key
            componentKey
          }
          ... on LoyaltyPointsAndRewardsWidget {
            _key
            componentKey
          }
          ... on LoyaltyLinkWidget {
            _key
            componentKey
          }
          ... on LoyaltyLogoWidget {
            _key
            componentKey
          }
          ... on LoyaltyTiersWidget {
            _key
            componentKey
            sortByPointValue
            title {
              locale: en
            }
          }
          ... on LoyaltyMarketingTileGroupWidget {
            _key
            componentKey
            disclaimer: marketingTileWidgetDisclaimer {
              localeRaw: enRaw
            }
            marketingTileGroup {
              ...MarketingTileGroupFragment
            }
            title: marketingTileWidgetTitle {
              locale: en
            }
          }
          ... on LoyaltyDoubleButtonWidget {
            _key
            componentKey
            leftButtonText {
              locale: en
            }
            leftButtonPath {
              locale: en
            }
            rightButtonText {
              locale: en
            }
            rightButtonPath {
              locale: en
            }
          }
          ... on LoyaltyPointsMeterWidget {
            _key
            componentKey
            imageBackground {
              image {
                ...ImageFragment
              }
            }
            ctaButton {
              buttonPath {
                locale: en
              }
              buttonText {
                locale: en
              }
            }
          }
          ... on LoyaltyNotificationsWidget {
            _key
            componentKey
            title {
              locale: en
            }
            notifications {
              ... on Reward {
                loyaltyEngineId
                notificationDetails {
                  ...NotificationDetailsFragment
                }
              }
              ... on SystemwideOffer {
                loyaltyEngineId
                redemptionType
                notificationDetails {
                  ...NotificationDetailsFragment
                }
              }
              ... on SystemwidePromotion {
                loyaltyEngineId
                notificationDetails {
                  ...NotificationDetailsFragment
                }
              }
            }
          }
          ... on LoyaltyQuestsWidget {
            _key
            componentKey
            title {
              locale: en
            }
          }
          ... on RewardsListWidget {
            _key
            componentKey
          }
        }
      }
    }
  }
  ${MarketingTileGroupFragmentDoc}
  ${ImageFragmentDoc}
  ${NotificationDetailsFragmentDoc}
`;

/**
 * __useFeatureLoyaltyDashboardQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyDashboardQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyDashboardQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyDashboardQuery,
    IFeatureLoyaltyDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyDashboardQuery,
    IFeatureLoyaltyDashboardQueryVariables
  >(FeatureLoyaltyDashboardDocument, options);
}
export function useFeatureLoyaltyDashboardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyDashboardQuery,
    IFeatureLoyaltyDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyDashboardQuery,
    IFeatureLoyaltyDashboardQueryVariables
  >(FeatureLoyaltyDashboardDocument, options);
}
export type FeatureLoyaltyDashboardQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyDashboardQuery
>;
export type FeatureLoyaltyDashboardLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyDashboardLazyQuery
>;
export type FeatureLoyaltyDashboardQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyDashboardQuery,
  IFeatureLoyaltyDashboardQueryVariables
>;
export const FeatureEarningCalculationDocument = gql`
  query featureEarningCalculation($id: ID!) {
    EarningCalculation(id: $id) {
      _id
      offerRedemptionCooldownPeriod
      offerRedemptionLimits {
        sameOfferLimit
        standardOffersLimit
      }
      rewardsRedemptionConfig {
        standardRewardsLimit
      }
      enableLoyaltyTiers
      loyaltyTiersEntranceBehaviorConfigurations {
        loyaltyTierKey
        pointsNeeded
        pointsNeededToMaintain
        pointsToEnterCalculationTimeInterval
        pointsToEnterCalculationTimeUnit
        pointsToMaintainCalculationTimeInterval
        pointsToMaintainCalculationTimeUnit
      }
    }
  }
`;

/**
 * __useFeatureEarningCalculationQuery__
 *
 * To run a query within a React component, call `useFeatureEarningCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureEarningCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureEarningCalculationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureEarningCalculationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureEarningCalculationQuery,
    IFeatureEarningCalculationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureEarningCalculationQuery,
    IFeatureEarningCalculationQueryVariables
  >(FeatureEarningCalculationDocument, options);
}
export function useFeatureEarningCalculationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureEarningCalculationQuery,
    IFeatureEarningCalculationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureEarningCalculationQuery,
    IFeatureEarningCalculationQueryVariables
  >(FeatureEarningCalculationDocument, options);
}
export type FeatureEarningCalculationQueryHookResult = ReturnType<
  typeof useFeatureEarningCalculationQuery
>;
export type FeatureEarningCalculationLazyQueryHookResult = ReturnType<
  typeof useFeatureEarningCalculationLazyQuery
>;
export type FeatureEarningCalculationQueryResult = Apollo.QueryResult<
  IFeatureEarningCalculationQuery,
  IFeatureEarningCalculationQueryVariables
>;
export const AllLoyaltyExternalOffersDocument = gql`
  query AllLoyaltyExternalOffers($id: ID!) {
    LoyaltyLiveExternalOffers(id: $id) {
      _id
      liveExternalOffers {
        ...ExternalOffersFragment
      }
    }
  }
  ${ExternalOffersFragmentDoc}
`;

/**
 * __useAllLoyaltyExternalOffersQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyExternalOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyExternalOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyExternalOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllLoyaltyExternalOffersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IAllLoyaltyExternalOffersQuery,
    IAllLoyaltyExternalOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IAllLoyaltyExternalOffersQuery,
    IAllLoyaltyExternalOffersQueryVariables
  >(AllLoyaltyExternalOffersDocument, options);
}
export function useAllLoyaltyExternalOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IAllLoyaltyExternalOffersQuery,
    IAllLoyaltyExternalOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IAllLoyaltyExternalOffersQuery,
    IAllLoyaltyExternalOffersQueryVariables
  >(AllLoyaltyExternalOffersDocument, options);
}
export type AllLoyaltyExternalOffersQueryHookResult = ReturnType<
  typeof useAllLoyaltyExternalOffersQuery
>;
export type AllLoyaltyExternalOffersLazyQueryHookResult = ReturnType<
  typeof useAllLoyaltyExternalOffersLazyQuery
>;
export type AllLoyaltyExternalOffersQueryResult = Apollo.QueryResult<
  IAllLoyaltyExternalOffersQuery,
  IAllLoyaltyExternalOffersQueryVariables
>;
export const LoyaltyExternalOffersEmptyStatesDocument = gql`
  query loyaltyExternalOffersEmptyStates($id: ID!) {
    LoyaltyLiveExternalOffers(id: $id) {
      _id
      externalOffersEmptyState {
        title {
          locale: en
        }
        description {
          locale: en
        }
        emptyStateImageContent {
          image {
            locale: en {
              ...ImageFragment
            }
          }
          ctaButtonText {
            locale: en
          }
          ctaButtonLink {
            locale: en
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useLoyaltyExternalOffersEmptyStatesQuery__
 *
 * To run a query within a React component, call `useLoyaltyExternalOffersEmptyStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyExternalOffersEmptyStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyExternalOffersEmptyStatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyExternalOffersEmptyStatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyExternalOffersEmptyStatesQuery,
    ILoyaltyExternalOffersEmptyStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyExternalOffersEmptyStatesQuery,
    ILoyaltyExternalOffersEmptyStatesQueryVariables
  >(LoyaltyExternalOffersEmptyStatesDocument, options);
}
export function useLoyaltyExternalOffersEmptyStatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyExternalOffersEmptyStatesQuery,
    ILoyaltyExternalOffersEmptyStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyExternalOffersEmptyStatesQuery,
    ILoyaltyExternalOffersEmptyStatesQueryVariables
  >(LoyaltyExternalOffersEmptyStatesDocument, options);
}
export type LoyaltyExternalOffersEmptyStatesQueryHookResult = ReturnType<
  typeof useLoyaltyExternalOffersEmptyStatesQuery
>;
export type LoyaltyExternalOffersEmptyStatesLazyQueryHookResult = ReturnType<
  typeof useLoyaltyExternalOffersEmptyStatesLazyQuery
>;
export type LoyaltyExternalOffersEmptyStatesQueryResult = Apollo.QueryResult<
  ILoyaltyExternalOffersEmptyStatesQuery,
  ILoyaltyExternalOffersEmptyStatesQueryVariables
>;
export const FeatureLoyaltyFeaturedRewardsDocument = gql`
  query featureLoyaltyFeaturedRewards($id: ID!) {
    LoyaltyUI(id: $id) {
      _id
      featuredRewards {
        ...RewardsSectionFragment
      }
    }
  }
  ${RewardsSectionFragmentDoc}
`;

/**
 * __useFeatureLoyaltyFeaturedRewardsQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyFeaturedRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyFeaturedRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyFeaturedRewardsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureLoyaltyFeaturedRewardsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyFeaturedRewardsQuery,
    IFeatureLoyaltyFeaturedRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyFeaturedRewardsQuery,
    IFeatureLoyaltyFeaturedRewardsQueryVariables
  >(FeatureLoyaltyFeaturedRewardsDocument, options);
}
export function useFeatureLoyaltyFeaturedRewardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyFeaturedRewardsQuery,
    IFeatureLoyaltyFeaturedRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyFeaturedRewardsQuery,
    IFeatureLoyaltyFeaturedRewardsQueryVariables
  >(FeatureLoyaltyFeaturedRewardsDocument, options);
}
export type FeatureLoyaltyFeaturedRewardsQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyFeaturedRewardsQuery
>;
export type FeatureLoyaltyFeaturedRewardsLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyFeaturedRewardsLazyQuery
>;
export type FeatureLoyaltyFeaturedRewardsQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyFeaturedRewardsQuery,
  IFeatureLoyaltyFeaturedRewardsQueryVariables
>;
export const FeatureLoyaltyInRestaurantRedemptionDocument = gql`
  query featureLoyaltyInRestaurantRedemption($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      loyaltyInRestaurantRedemption {
        topWidgets {
          ... on LoyaltyImageWidget {
            ...LoyaltyImageWidgetFragment
          }
          ... on LoyaltyTextWidget {
            ...LoyaltyTextWidgetFragment
          }
          ... on LoyaltyTitleWidget {
            ...LoyaltyTitleWidgetFragment
          }
          ... on LoyaltyPaymentMethodWidget {
            ...LoyaltyPaymentMethodWidgetFragment
          }
          ... on LoyaltyButtonWidget {
            ...LoyaltyButtonWidgetFragment
          }
          ... on LoyaltyButtonArrayWidget {
            ...LoyaltyButtonArrayWidgetFragment
          }
          ... on LoyaltyQRAndShortCodeWidget {
            ...LoyaltyQRAndShortCodeWidgetFragment
          }
          ... on LoyaltyMarketingTileGroupWidget {
            _key
            componentKey
            marketingTileGroup {
              ...MarketingTileGroupFragment
            }
          }
          ... on LoyaltyQRScanButtonWidget {
            ...LoyaltyQRScanButtonWidgetFragment
          }
          ... on LoyaltyQRScanHeaderWidget {
            ...LoyaltyQRScanHeaderWidgetFragment
          }
          ... on LoyaltyQRCodeWidget {
            ...LoyaltyQRCodeWidgetFragment
          }
          ... on LoyaltyShortCodeWidget {
            ...LoyaltyShortCodeWidgetFragment
          }
          ... on LoyaltyLegacyShortCodeWidget {
            ...LoyaltyLegacyShortCodeWidgetFragment
          }
          ... on LoyaltyCartWidget {
            ...LoyaltyCartWidgetFragment
          }
          ... on LoyaltyBasicCardWidget {
            ...LoyaltyBasicCardWidgetFragment
          }
        }
        bottomWidgets {
          ... on LoyaltyImageWidget {
            ...LoyaltyImageWidgetFragment
          }
          ... on LoyaltyTextWidget {
            ...LoyaltyTextWidgetFragment
          }
          ... on LoyaltyTitleWidget {
            ...LoyaltyTitleWidgetFragment
          }
          ... on LoyaltyPaymentMethodWidget {
            ...LoyaltyPaymentMethodWidgetFragment
          }
          ... on LoyaltyButtonWidget {
            ...LoyaltyButtonWidgetFragment
          }
          ... on LoyaltyButtonArrayWidget {
            ...LoyaltyButtonArrayWidgetFragment
          }
          ... on LoyaltyMarketingTileGroupWidget {
            _key
            componentKey
            marketingTileGroup {
              ...MarketingTileGroupFragment
            }
          }
          ... on LoyaltyQRScanButtonWidget {
            ...LoyaltyQRScanButtonWidgetFragment
          }
          ... on LoyaltyQRScanHeaderWidget {
            ...LoyaltyQRScanHeaderWidgetFragment
          }
          ... on LoyaltyQRCodeWidget {
            ...LoyaltyQRCodeWidgetFragment
          }
          ... on LoyaltyShortCodeWidget {
            ...LoyaltyShortCodeWidgetFragment
          }
          ... on LoyaltyLegacyShortCodeWidget {
            ...LoyaltyLegacyShortCodeWidgetFragment
          }
          ... on LoyaltyCartWidget {
            ...LoyaltyCartWidgetFragment
          }
          ... on LoyaltyBasicCardWidget {
            ...LoyaltyBasicCardWidgetFragment
          }
        }
      }
    }
  }
  ${LoyaltyImageWidgetFragmentDoc}
  ${LoyaltyTextWidgetFragmentDoc}
  ${LoyaltyTitleWidgetFragmentDoc}
  ${LoyaltyPaymentMethodWidgetFragmentDoc}
  ${LoyaltyButtonWidgetFragmentDoc}
  ${LoyaltyButtonArrayWidgetFragmentDoc}
  ${LoyaltyQrAndShortCodeWidgetFragmentDoc}
  ${MarketingTileGroupFragmentDoc}
  ${LoyaltyQrScanButtonWidgetFragmentDoc}
  ${LoyaltyQrScanHeaderWidgetFragmentDoc}
  ${LoyaltyQrCodeWidgetFragmentDoc}
  ${LoyaltyShortCodeWidgetFragmentDoc}
  ${LoyaltyLegacyShortCodeWidgetFragmentDoc}
  ${LoyaltyCartWidgetFragmentDoc}
  ${LoyaltyBasicCardWidgetFragmentDoc}
`;

/**
 * __useFeatureLoyaltyInRestaurantRedemptionQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyInRestaurantRedemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyInRestaurantRedemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyInRestaurantRedemptionQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyInRestaurantRedemptionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyInRestaurantRedemptionQuery,
    IFeatureLoyaltyInRestaurantRedemptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyInRestaurantRedemptionQuery,
    IFeatureLoyaltyInRestaurantRedemptionQueryVariables
  >(FeatureLoyaltyInRestaurantRedemptionDocument, options);
}
export function useFeatureLoyaltyInRestaurantRedemptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyInRestaurantRedemptionQuery,
    IFeatureLoyaltyInRestaurantRedemptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyInRestaurantRedemptionQuery,
    IFeatureLoyaltyInRestaurantRedemptionQueryVariables
  >(FeatureLoyaltyInRestaurantRedemptionDocument, options);
}
export type FeatureLoyaltyInRestaurantRedemptionQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyInRestaurantRedemptionQuery
>;
export type FeatureLoyaltyInRestaurantRedemptionLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyInRestaurantRedemptionLazyQuery
>;
export type FeatureLoyaltyInRestaurantRedemptionQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyInRestaurantRedemptionQuery,
  IFeatureLoyaltyInRestaurantRedemptionQueryVariables
>;
export const FeatureLoyaltyUiNoRewardDocument = gql`
  query featureLoyaltyUINoReward($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      noRewardPoints {
        ...EmptyStateFragment
      }
      noRewardsAvailable {
        ...EmptyStateFragment
      }
    }
  }
  ${EmptyStateFragmentDoc}
`;

/**
 * __useFeatureLoyaltyUiNoRewardQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyUiNoRewardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyUiNoRewardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyUiNoRewardQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyUiNoRewardQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyUiNoRewardQuery,
    IFeatureLoyaltyUiNoRewardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyUiNoRewardQuery,
    IFeatureLoyaltyUiNoRewardQueryVariables
  >(FeatureLoyaltyUiNoRewardDocument, options);
}
export function useFeatureLoyaltyUiNoRewardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyUiNoRewardQuery,
    IFeatureLoyaltyUiNoRewardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyUiNoRewardQuery,
    IFeatureLoyaltyUiNoRewardQueryVariables
  >(FeatureLoyaltyUiNoRewardDocument, options);
}
export type FeatureLoyaltyUiNoRewardQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiNoRewardQuery
>;
export type FeatureLoyaltyUiNoRewardLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiNoRewardLazyQuery
>;
export type FeatureLoyaltyUiNoRewardQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyUiNoRewardQuery,
  IFeatureLoyaltyUiNoRewardQueryVariables
>;
export const FeatureLoyaltyNotAuthenticatedDocument = gql`
  query featureLoyaltyNotAuthenticated($id: ID!) {
    LoyaltyUI(id: $id) {
      _id
      loyaltyNotAuthenticated {
        loyaltySignupTitle {
          locale: en
        }
        loyaltySignupCells {
          loyaltySignupCellImage {
            locale: en {
              ...ImageFragment
            }
          }
          loyaltySignupCellTitle {
            locale: en
          }
          loyaltySignupCellDescription {
            locale: en
          }
        }
        loyaltySignupButtonText {
          locale: en
        }
        loyaltySignupLegalText {
          locale: en
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLoyaltyNotAuthenticatedQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyNotAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyNotAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyNotAuthenticatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureLoyaltyNotAuthenticatedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyNotAuthenticatedQuery,
    IFeatureLoyaltyNotAuthenticatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyNotAuthenticatedQuery,
    IFeatureLoyaltyNotAuthenticatedQueryVariables
  >(FeatureLoyaltyNotAuthenticatedDocument, options);
}
export function useFeatureLoyaltyNotAuthenticatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyNotAuthenticatedQuery,
    IFeatureLoyaltyNotAuthenticatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyNotAuthenticatedQuery,
    IFeatureLoyaltyNotAuthenticatedQueryVariables
  >(FeatureLoyaltyNotAuthenticatedDocument, options);
}
export type FeatureLoyaltyNotAuthenticatedQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAuthenticatedQuery
>;
export type FeatureLoyaltyNotAuthenticatedLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAuthenticatedLazyQuery
>;
export type FeatureLoyaltyNotAuthenticatedQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyNotAuthenticatedQuery,
  IFeatureLoyaltyNotAuthenticatedQueryVariables
>;
export const FeatureLoyaltyNotAvailableAtDeliveryModeDocument = gql`
  query featureLoyaltyNotAvailableAtDeliveryMode($id: ID!) {
    LoyaltyUI(id: $id) {
      _id
      loyaltyNotAvailableAtDeliveryMode {
        image {
          locale: en {
            ...ImagesFragment
          }
        }
        title {
          locale: en
        }
        description {
          locale: en
        }
        firstButtonText {
          locale: en
        }
        firstRedirectPath {
          locale: en
        }
      }
    }
  }
  ${ImagesFragmentDoc}
`;

/**
 * __useFeatureLoyaltyNotAvailableAtDeliveryModeQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyNotAvailableAtDeliveryModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyNotAvailableAtDeliveryModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyNotAvailableAtDeliveryModeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureLoyaltyNotAvailableAtDeliveryModeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyNotAvailableAtDeliveryModeQuery,
    IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyNotAvailableAtDeliveryModeQuery,
    IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables
  >(FeatureLoyaltyNotAvailableAtDeliveryModeDocument, options);
}
export function useFeatureLoyaltyNotAvailableAtDeliveryModeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyNotAvailableAtDeliveryModeQuery,
    IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyNotAvailableAtDeliveryModeQuery,
    IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables
  >(FeatureLoyaltyNotAvailableAtDeliveryModeDocument, options);
}
export type FeatureLoyaltyNotAvailableAtDeliveryModeQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAvailableAtDeliveryModeQuery
>;
export type FeatureLoyaltyNotAvailableAtDeliveryModeLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAvailableAtDeliveryModeLazyQuery
>;
export type FeatureLoyaltyNotAvailableAtDeliveryModeQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyNotAvailableAtDeliveryModeQuery,
  IFeatureLoyaltyNotAvailableAtDeliveryModeQueryVariables
>;
export const FeatureLoyaltyNotAvailableAtThisRestaurantDocument = gql`
  query featureLoyaltyNotAvailableAtThisRestaurant($id: ID!) {
    LoyaltyUI(id: $id) {
      _id
      loyaltyNotAvailableAtThisRestaurant {
        image {
          locale: en {
            ...ImagesFragment
          }
        }
        title {
          locale: en
        }
        description {
          locale: en
        }
        firstButtonText {
          locale: en
        }
        firstRedirectPath {
          locale: en
        }
        secondButtonText {
          locale: en
        }
        secondRedirectPath {
          locale: en
        }
      }
    }
  }
  ${ImagesFragmentDoc}
`;

/**
 * __useFeatureLoyaltyNotAvailableAtThisRestaurantQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyNotAvailableAtThisRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyNotAvailableAtThisRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyNotAvailableAtThisRestaurantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureLoyaltyNotAvailableAtThisRestaurantQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyNotAvailableAtThisRestaurantQuery,
    IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyNotAvailableAtThisRestaurantQuery,
    IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables
  >(FeatureLoyaltyNotAvailableAtThisRestaurantDocument, options);
}
export function useFeatureLoyaltyNotAvailableAtThisRestaurantLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyNotAvailableAtThisRestaurantQuery,
    IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyNotAvailableAtThisRestaurantQuery,
    IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables
  >(FeatureLoyaltyNotAvailableAtThisRestaurantDocument, options);
}
export type FeatureLoyaltyNotAvailableAtThisRestaurantQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAvailableAtThisRestaurantQuery
>;
export type FeatureLoyaltyNotAvailableAtThisRestaurantLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyNotAvailableAtThisRestaurantLazyQuery
>;
export type FeatureLoyaltyNotAvailableAtThisRestaurantQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyNotAvailableAtThisRestaurantQuery,
  IFeatureLoyaltyNotAvailableAtThisRestaurantQueryVariables
>;
export const FeatureLoyaltyUiNotEnrolledDocument = gql`
  query featureLoyaltyUINotEnrolled($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      notEnrolledState {
        ...EmptyStateFragment
      }
      notEnrolledStateMobile {
        ...EmptyStateFragment
      }
    }
  }
  ${EmptyStateFragmentDoc}
`;

/**
 * __useFeatureLoyaltyUiNotEnrolledQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyUiNotEnrolledQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyUiNotEnrolledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyUiNotEnrolledQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyUiNotEnrolledQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyUiNotEnrolledQuery,
    IFeatureLoyaltyUiNotEnrolledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyUiNotEnrolledQuery,
    IFeatureLoyaltyUiNotEnrolledQueryVariables
  >(FeatureLoyaltyUiNotEnrolledDocument, options);
}
export function useFeatureLoyaltyUiNotEnrolledLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyUiNotEnrolledQuery,
    IFeatureLoyaltyUiNotEnrolledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyUiNotEnrolledQuery,
    IFeatureLoyaltyUiNotEnrolledQueryVariables
  >(FeatureLoyaltyUiNotEnrolledDocument, options);
}
export type FeatureLoyaltyUiNotEnrolledQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiNotEnrolledQuery
>;
export type FeatureLoyaltyUiNotEnrolledLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiNotEnrolledLazyQuery
>;
export type FeatureLoyaltyUiNotEnrolledQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyUiNotEnrolledQuery,
  IFeatureLoyaltyUiNotEnrolledQueryVariables
>;
export const LoyaltyOffersUiIncentiveNotAvailableDocument = gql`
  query loyaltyOffersUIIncentiveNotAvailable($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      offerNotAvailableModalContent {
        image {
          locale: en {
            ...ImagesFragment
          }
        }
        title {
          locale: en
        }
        description {
          locale: en
        }
        primaryButton {
          buttonText {
            locale: en
          }
          buttonPath {
            locale: en
          }
        }
        secondaryButton {
          buttonText {
            locale: en
          }
          buttonPath {
            locale: en
          }
        }
      }
      rewardNotAvailableModalContent {
        image {
          locale: en {
            ...ImagesFragment
          }
        }
        title {
          locale: en
        }
        description {
          locale: en
        }
        primaryButton {
          buttonText {
            locale: en
          }
          buttonPath {
            locale: en
          }
        }
        secondaryButton {
          buttonText {
            locale: en
          }
          buttonPath {
            locale: en
          }
        }
      }
    }
  }
  ${ImagesFragmentDoc}
`;

/**
 * __useLoyaltyOffersUiIncentiveNotAvailableQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiIncentiveNotAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiIncentiveNotAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiIncentiveNotAvailableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiIncentiveNotAvailableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiIncentiveNotAvailableQuery,
    ILoyaltyOffersUiIncentiveNotAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiIncentiveNotAvailableQuery,
    ILoyaltyOffersUiIncentiveNotAvailableQueryVariables
  >(LoyaltyOffersUiIncentiveNotAvailableDocument, options);
}
export function useLoyaltyOffersUiIncentiveNotAvailableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiIncentiveNotAvailableQuery,
    ILoyaltyOffersUiIncentiveNotAvailableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiIncentiveNotAvailableQuery,
    ILoyaltyOffersUiIncentiveNotAvailableQueryVariables
  >(LoyaltyOffersUiIncentiveNotAvailableDocument, options);
}
export type LoyaltyOffersUiIncentiveNotAvailableQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiIncentiveNotAvailableQuery
>;
export type LoyaltyOffersUiIncentiveNotAvailableLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiIncentiveNotAvailableLazyQuery
>;
export type LoyaltyOffersUiIncentiveNotAvailableQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiIncentiveNotAvailableQuery,
  ILoyaltyOffersUiIncentiveNotAvailableQueryVariables
>;
export const FeatureSortedLoyaltyOffersDocument = gql`
  query featureSortedLoyaltyOffers($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      sortedSystemwideOffers {
        ...SystemwideOffersFragment
      }
    }
  }
  ${SystemwideOffersFragmentDoc}
`;

/**
 * __useFeatureSortedLoyaltyOffersQuery__
 *
 * To run a query within a React component, call `useFeatureSortedLoyaltyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureSortedLoyaltyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureSortedLoyaltyOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeatureSortedLoyaltyOffersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureSortedLoyaltyOffersQuery,
    IFeatureSortedLoyaltyOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureSortedLoyaltyOffersQuery,
    IFeatureSortedLoyaltyOffersQueryVariables
  >(FeatureSortedLoyaltyOffersDocument, options);
}
export function useFeatureSortedLoyaltyOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureSortedLoyaltyOffersQuery,
    IFeatureSortedLoyaltyOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureSortedLoyaltyOffersQuery,
    IFeatureSortedLoyaltyOffersQueryVariables
  >(FeatureSortedLoyaltyOffersDocument, options);
}
export type FeatureSortedLoyaltyOffersQueryHookResult = ReturnType<
  typeof useFeatureSortedLoyaltyOffersQuery
>;
export type FeatureSortedLoyaltyOffersLazyQueryHookResult = ReturnType<
  typeof useFeatureSortedLoyaltyOffersLazyQuery
>;
export type FeatureSortedLoyaltyOffersQueryResult = Apollo.QueryResult<
  IFeatureSortedLoyaltyOffersQuery,
  IFeatureSortedLoyaltyOffersQueryVariables
>;
export const LoyaltyOffersUiLoggedInDocument = gql`
  query loyaltyOffersUILoggedIn($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      endOfOffersLoggedIn {
        endOfOffersMessage {
          locale: en
        }
        endOfOffersImage {
          locale: en {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useLoyaltyOffersUiLoggedInQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiLoggedInQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiLoggedInQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiLoggedInQuery,
    ILoyaltyOffersUiLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiLoggedInQuery,
    ILoyaltyOffersUiLoggedInQueryVariables
  >(LoyaltyOffersUiLoggedInDocument, options);
}
export function useLoyaltyOffersUiLoggedInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiLoggedInQuery,
    ILoyaltyOffersUiLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiLoggedInQuery,
    ILoyaltyOffersUiLoggedInQueryVariables
  >(LoyaltyOffersUiLoggedInDocument, options);
}
export type LoyaltyOffersUiLoggedInQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiLoggedInQuery
>;
export type LoyaltyOffersUiLoggedInLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiLoggedInLazyQuery
>;
export type LoyaltyOffersUiLoggedInQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiLoggedInQuery,
  ILoyaltyOffersUiLoggedInQueryVariables
>;
export const LoyaltyOffersUiLoggedOutDocument = gql`
  query loyaltyOffersUILoggedOut($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      endOfOffersLoggedOut {
        title {
          locale: en
        }
        body {
          locale: en
        }
        ctaButtonText {
          locale: en
        }
        link {
          locale: en
        }
      }
    }
  }
`;

/**
 * __useLoyaltyOffersUiLoggedOutQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiLoggedOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiLoggedOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiLoggedOutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiLoggedOutQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiLoggedOutQuery,
    ILoyaltyOffersUiLoggedOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiLoggedOutQuery,
    ILoyaltyOffersUiLoggedOutQueryVariables
  >(LoyaltyOffersUiLoggedOutDocument, options);
}
export function useLoyaltyOffersUiLoggedOutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiLoggedOutQuery,
    ILoyaltyOffersUiLoggedOutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiLoggedOutQuery,
    ILoyaltyOffersUiLoggedOutQueryVariables
  >(LoyaltyOffersUiLoggedOutDocument, options);
}
export type LoyaltyOffersUiLoggedOutQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiLoggedOutQuery
>;
export type LoyaltyOffersUiLoggedOutLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiLoggedOutLazyQuery
>;
export type LoyaltyOffersUiLoggedOutQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiLoggedOutQuery,
  ILoyaltyOffersUiLoggedOutQueryVariables
>;
export const LoyaltyOffersUiCooldownContentDocument = gql`
  query loyaltyOffersUICooldownContent($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      globalOfferCooldownContent {
        title {
          locale: en
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
        description {
          localeRaw: en
        }
        actionButton {
          buttonText {
            locale: en
          }
          buttonPath {
            locale: en
          }
        }
        terms {
          localeRaw: enRaw
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useLoyaltyOffersUiCooldownContentQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiCooldownContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiCooldownContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiCooldownContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiCooldownContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiCooldownContentQuery,
    ILoyaltyOffersUiCooldownContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiCooldownContentQuery,
    ILoyaltyOffersUiCooldownContentQueryVariables
  >(LoyaltyOffersUiCooldownContentDocument, options);
}
export function useLoyaltyOffersUiCooldownContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiCooldownContentQuery,
    ILoyaltyOffersUiCooldownContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiCooldownContentQuery,
    ILoyaltyOffersUiCooldownContentQueryVariables
  >(LoyaltyOffersUiCooldownContentDocument, options);
}
export type LoyaltyOffersUiCooldownContentQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiCooldownContentQuery
>;
export type LoyaltyOffersUiCooldownContentLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiCooldownContentLazyQuery
>;
export type LoyaltyOffersUiCooldownContentQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiCooldownContentQuery,
  ILoyaltyOffersUiCooldownContentQueryVariables
>;
export const LoyaltyOffersUiEnableOfferBadgesDocument = gql`
  query loyaltyOffersUIEnableOfferBadges($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      enableOfferBadges
    }
  }
`;

/**
 * __useLoyaltyOffersUiEnableOfferBadgesQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiEnableOfferBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiEnableOfferBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiEnableOfferBadgesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiEnableOfferBadgesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiEnableOfferBadgesQuery,
    ILoyaltyOffersUiEnableOfferBadgesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiEnableOfferBadgesQuery,
    ILoyaltyOffersUiEnableOfferBadgesQueryVariables
  >(LoyaltyOffersUiEnableOfferBadgesDocument, options);
}
export function useLoyaltyOffersUiEnableOfferBadgesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiEnableOfferBadgesQuery,
    ILoyaltyOffersUiEnableOfferBadgesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiEnableOfferBadgesQuery,
    ILoyaltyOffersUiEnableOfferBadgesQueryVariables
  >(LoyaltyOffersUiEnableOfferBadgesDocument, options);
}
export type LoyaltyOffersUiEnableOfferBadgesQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiEnableOfferBadgesQuery
>;
export type LoyaltyOffersUiEnableOfferBadgesLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiEnableOfferBadgesLazyQuery
>;
export type LoyaltyOffersUiEnableOfferBadgesQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiEnableOfferBadgesQuery,
  ILoyaltyOffersUiEnableOfferBadgesQueryVariables
>;
export const LoyaltyOffersUiEnableGreyedOutOffersDocument = gql`
  query loyaltyOffersUIEnableGreyedOutOffers($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      enableGreyedOutOffers
    }
  }
`;

/**
 * __useLoyaltyOffersUiEnableGreyedOutOffersQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiEnableGreyedOutOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiEnableGreyedOutOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiEnableGreyedOutOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiEnableGreyedOutOffersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiEnableGreyedOutOffersQuery,
    ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiEnableGreyedOutOffersQuery,
    ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables
  >(LoyaltyOffersUiEnableGreyedOutOffersDocument, options);
}
export function useLoyaltyOffersUiEnableGreyedOutOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiEnableGreyedOutOffersQuery,
    ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiEnableGreyedOutOffersQuery,
    ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables
  >(LoyaltyOffersUiEnableGreyedOutOffersDocument, options);
}
export type LoyaltyOffersUiEnableGreyedOutOffersQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiEnableGreyedOutOffersQuery
>;
export type LoyaltyOffersUiEnableGreyedOutOffersLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiEnableGreyedOutOffersLazyQuery
>;
export type LoyaltyOffersUiEnableGreyedOutOffersQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiEnableGreyedOutOffersQuery,
  ILoyaltyOffersUiEnableGreyedOutOffersQueryVariables
>;
export const LoyaltyOffersUiOfferMarketingTilesDocument = gql`
  query loyaltyOffersUIOfferMarketingTiles($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      loyaltyOffersPageMarketingTiles {
        ...LoyaltyOffersPageMarketingTileFragment
      }
    }
  }
  ${LoyaltyOffersPageMarketingTileFragmentDoc}
`;

/**
 * __useLoyaltyOffersUiOfferMarketingTilesQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersUiOfferMarketingTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersUiOfferMarketingTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersUiOfferMarketingTilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersUiOfferMarketingTilesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersUiOfferMarketingTilesQuery,
    ILoyaltyOffersUiOfferMarketingTilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersUiOfferMarketingTilesQuery,
    ILoyaltyOffersUiOfferMarketingTilesQueryVariables
  >(LoyaltyOffersUiOfferMarketingTilesDocument, options);
}
export function useLoyaltyOffersUiOfferMarketingTilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersUiOfferMarketingTilesQuery,
    ILoyaltyOffersUiOfferMarketingTilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersUiOfferMarketingTilesQuery,
    ILoyaltyOffersUiOfferMarketingTilesQueryVariables
  >(LoyaltyOffersUiOfferMarketingTilesDocument, options);
}
export type LoyaltyOffersUiOfferMarketingTilesQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiOfferMarketingTilesQuery
>;
export type LoyaltyOffersUiOfferMarketingTilesLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersUiOfferMarketingTilesLazyQuery
>;
export type LoyaltyOffersUiOfferMarketingTilesQueryResult = Apollo.QueryResult<
  ILoyaltyOffersUiOfferMarketingTilesQuery,
  ILoyaltyOffersUiOfferMarketingTilesQueryVariables
>;
export const LoyaltyOffersEmptyStatesDocument = gql`
  query loyaltyOffersEmptyStates($id: ID!) {
    LoyaltyOffersUI(id: $id) {
      _id
      offersEmptyStates {
        emptyStateOffersTitle {
          locale: en
        }
        emptyStateOffersDescription {
          locale: en
        }
        emptyStateOffersImage {
          locale: en {
            ...ImageFragment
          }
        }
        ctaButtonText {
          locale: en
        }
        ctaButtonLink {
          locale: en
        }
        emptyStateFavoriteOffersTitle {
          locale: en
        }
        emptyStateFavoriteOffersDescription {
          locale: en
        }
        emptyStateFavoriteOffersImage {
          locale: en {
            ...ImageFragment
          }
        }
        ctaButtonEmptyStateFavoriteOffersText {
          locale: en
        }
        ctaButtonEmptyStateFavoriteOffersLink {
          locale: en
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useLoyaltyOffersEmptyStatesQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersEmptyStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersEmptyStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersEmptyStatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyOffersEmptyStatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOffersEmptyStatesQuery,
    ILoyaltyOffersEmptyStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOffersEmptyStatesQuery,
    ILoyaltyOffersEmptyStatesQueryVariables
  >(LoyaltyOffersEmptyStatesDocument, options);
}
export function useLoyaltyOffersEmptyStatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOffersEmptyStatesQuery,
    ILoyaltyOffersEmptyStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOffersEmptyStatesQuery,
    ILoyaltyOffersEmptyStatesQueryVariables
  >(LoyaltyOffersEmptyStatesDocument, options);
}
export type LoyaltyOffersEmptyStatesQueryHookResult = ReturnType<
  typeof useLoyaltyOffersEmptyStatesQuery
>;
export type LoyaltyOffersEmptyStatesLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOffersEmptyStatesLazyQuery
>;
export type LoyaltyOffersEmptyStatesQueryResult = Apollo.QueryResult<
  ILoyaltyOffersEmptyStatesQuery,
  ILoyaltyOffersEmptyStatesQueryVariables
>;
export const FeatureLoyaltyOffersDocument = gql`
  query featureLoyaltyOffers {
    allSystemwideOffer {
      ...SystemwideOffersFragment
    }
  }
  ${SystemwideOffersFragmentDoc}
`;

/**
 * __useFeatureLoyaltyOffersQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureLoyaltyOffersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyOffersQuery,
    IFeatureLoyaltyOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureLoyaltyOffersQuery, IFeatureLoyaltyOffersQueryVariables>(
    FeatureLoyaltyOffersDocument,
    options
  );
}
export function useFeatureLoyaltyOffersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyOffersQuery,
    IFeatureLoyaltyOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyOffersQuery,
    IFeatureLoyaltyOffersQueryVariables
  >(FeatureLoyaltyOffersDocument, options);
}
export type FeatureLoyaltyOffersQueryHookResult = ReturnType<typeof useFeatureLoyaltyOffersQuery>;
export type FeatureLoyaltyOffersLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyOffersLazyQuery
>;
export type FeatureLoyaltyOffersQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyOffersQuery,
  IFeatureLoyaltyOffersQueryVariables
>;
export const LoyaltySystemwideOffersByEngineIdDocument = gql`
  query LoyaltySystemwideOffersByEngineId($ids: [String!]!) {
    allSystemwideOffer(where: { loyaltyEngineId: { in: $ids } }) {
      ...SystemwideOffersFragment
    }
  }
  ${SystemwideOffersFragmentDoc}
`;

/**
 * __useLoyaltySystemwideOffersByEngineIdQuery__
 *
 * To run a query within a React component, call `useLoyaltySystemwideOffersByEngineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltySystemwideOffersByEngineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltySystemwideOffersByEngineIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoyaltySystemwideOffersByEngineIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltySystemwideOffersByEngineIdQuery,
    ILoyaltySystemwideOffersByEngineIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltySystemwideOffersByEngineIdQuery,
    ILoyaltySystemwideOffersByEngineIdQueryVariables
  >(LoyaltySystemwideOffersByEngineIdDocument, options);
}
export function useLoyaltySystemwideOffersByEngineIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltySystemwideOffersByEngineIdQuery,
    ILoyaltySystemwideOffersByEngineIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltySystemwideOffersByEngineIdQuery,
    ILoyaltySystemwideOffersByEngineIdQueryVariables
  >(LoyaltySystemwideOffersByEngineIdDocument, options);
}
export type LoyaltySystemwideOffersByEngineIdQueryHookResult = ReturnType<
  typeof useLoyaltySystemwideOffersByEngineIdQuery
>;
export type LoyaltySystemwideOffersByEngineIdLazyQueryHookResult = ReturnType<
  typeof useLoyaltySystemwideOffersByEngineIdLazyQuery
>;
export type LoyaltySystemwideOffersByEngineIdQueryResult = Apollo.QueryResult<
  ILoyaltySystemwideOffersByEngineIdQuery,
  ILoyaltySystemwideOffersByEngineIdQueryVariables
>;
export const LoyaltySystemwideOffersByIdsDocument = gql`
  query LoyaltySystemwideOffersByIds($ids: [ID!]!) {
    allSystemwideOffer(where: { _id: { in: $ids } }) {
      ...SystemwideOffersFragment
    }
  }
  ${SystemwideOffersFragmentDoc}
`;

/**
 * __useLoyaltySystemwideOffersByIdsQuery__
 *
 * To run a query within a React component, call `useLoyaltySystemwideOffersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltySystemwideOffersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltySystemwideOffersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoyaltySystemwideOffersByIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltySystemwideOffersByIdsQuery,
    ILoyaltySystemwideOffersByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltySystemwideOffersByIdsQuery,
    ILoyaltySystemwideOffersByIdsQueryVariables
  >(LoyaltySystemwideOffersByIdsDocument, options);
}
export function useLoyaltySystemwideOffersByIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltySystemwideOffersByIdsQuery,
    ILoyaltySystemwideOffersByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltySystemwideOffersByIdsQuery,
    ILoyaltySystemwideOffersByIdsQueryVariables
  >(LoyaltySystemwideOffersByIdsDocument, options);
}
export type LoyaltySystemwideOffersByIdsQueryHookResult = ReturnType<
  typeof useLoyaltySystemwideOffersByIdsQuery
>;
export type LoyaltySystemwideOffersByIdsLazyQueryHookResult = ReturnType<
  typeof useLoyaltySystemwideOffersByIdsLazyQuery
>;
export type LoyaltySystemwideOffersByIdsQueryResult = Apollo.QueryResult<
  ILoyaltySystemwideOffersByIdsQuery,
  ILoyaltySystemwideOffersByIdsQueryVariables
>;
export const LoyaltyOfferConfigsDocument = gql`
  query LoyaltyOfferConfigs($ids: [String!]!) {
    allConfigOffer(where: { loyaltyEngineId: { in: $ids } }) {
      ...ConfigOfferFragment
    }
  }
  ${ConfigOfferFragmentDoc}
`;

/**
 * __useLoyaltyOfferConfigsQuery__
 *
 * To run a query within a React component, call `useLoyaltyOfferConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOfferConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOfferConfigsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoyaltyOfferConfigsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOfferConfigsQuery,
    ILoyaltyOfferConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ILoyaltyOfferConfigsQuery, ILoyaltyOfferConfigsQueryVariables>(
    LoyaltyOfferConfigsDocument,
    options
  );
}
export function useLoyaltyOfferConfigsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOfferConfigsQuery,
    ILoyaltyOfferConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOfferConfigsQuery,
    ILoyaltyOfferConfigsQueryVariables
  >(LoyaltyOfferConfigsDocument, options);
}
export type LoyaltyOfferConfigsQueryHookResult = ReturnType<typeof useLoyaltyOfferConfigsQuery>;
export type LoyaltyOfferConfigsLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOfferConfigsLazyQuery
>;
export type LoyaltyOfferConfigsQueryResult = Apollo.QueryResult<
  ILoyaltyOfferConfigsQuery,
  ILoyaltyOfferConfigsQueryVariables
>;
export const LoyaltyOfferTemplatesDocument = gql`
  query LoyaltyOfferTemplates($ids: [String!]!) {
    allOfferTemplate(where: { loyaltyEngineId: { in: $ids } }) {
      ...LoyaltyOfferTemplate
    }
  }
  ${LoyaltyOfferTemplateFragmentDoc}
`;

/**
 * __useLoyaltyOfferTemplatesQuery__
 *
 * To run a query within a React component, call `useLoyaltyOfferTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOfferTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOfferTemplatesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoyaltyOfferTemplatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyOfferTemplatesQuery,
    ILoyaltyOfferTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyOfferTemplatesQuery,
    ILoyaltyOfferTemplatesQueryVariables
  >(LoyaltyOfferTemplatesDocument, options);
}
export function useLoyaltyOfferTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyOfferTemplatesQuery,
    ILoyaltyOfferTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyOfferTemplatesQuery,
    ILoyaltyOfferTemplatesQueryVariables
  >(LoyaltyOfferTemplatesDocument, options);
}
export type LoyaltyOfferTemplatesQueryHookResult = ReturnType<typeof useLoyaltyOfferTemplatesQuery>;
export type LoyaltyOfferTemplatesLazyQueryHookResult = ReturnType<
  typeof useLoyaltyOfferTemplatesLazyQuery
>;
export type LoyaltyOfferTemplatesQueryResult = Apollo.QueryResult<
  ILoyaltyOfferTemplatesQuery,
  ILoyaltyOfferTemplatesQueryVariables
>;
export const FeatureLoyaltyUiOptInModalDocument = gql`
  query featureLoyaltyUIOptInModal($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      optInModal {
        ...OptInModalFragment
      }
      optInModalError {
        ...OptInModalFragment
      }
      optInModalLoading {
        title {
          locale: en
        }
        description {
          locale: en
        }
      }
    }
  }
  ${OptInModalFragmentDoc}
`;

/**
 * __useFeatureLoyaltyUiOptInModalQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyUiOptInModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyUiOptInModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyUiOptInModalQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyUiOptInModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyUiOptInModalQuery,
    IFeatureLoyaltyUiOptInModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyUiOptInModalQuery,
    IFeatureLoyaltyUiOptInModalQueryVariables
  >(FeatureLoyaltyUiOptInModalDocument, options);
}
export function useFeatureLoyaltyUiOptInModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyUiOptInModalQuery,
    IFeatureLoyaltyUiOptInModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyUiOptInModalQuery,
    IFeatureLoyaltyUiOptInModalQueryVariables
  >(FeatureLoyaltyUiOptInModalDocument, options);
}
export type FeatureLoyaltyUiOptInModalQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiOptInModalQuery
>;
export type FeatureLoyaltyUiOptInModalLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyUiOptInModalLazyQuery
>;
export type FeatureLoyaltyUiOptInModalQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyUiOptInModalQuery,
  IFeatureLoyaltyUiOptInModalQueryVariables
>;
export const FeatureLoyaltyRewardListCategoriesOnlyDocument = gql`
  query featureLoyaltyRewardListCategoriesOnly($featureLoyaltyRewardListCategoriesId: ID!) {
    RewardList(id: $featureLoyaltyRewardListCategoriesId) {
      _id
      rewardCategories {
        _id
        label {
          locale: en
        }
      }
    }
  }
`;

/**
 * __useFeatureLoyaltyRewardListCategoriesOnlyQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyRewardListCategoriesOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyRewardListCategoriesOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyRewardListCategoriesOnlyQuery({
 *   variables: {
 *      featureLoyaltyRewardListCategoriesId: // value for 'featureLoyaltyRewardListCategoriesId'
 *   },
 * });
 */
export function useFeatureLoyaltyRewardListCategoriesOnlyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyRewardListCategoriesOnlyQuery,
    IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyRewardListCategoriesOnlyQuery,
    IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables
  >(FeatureLoyaltyRewardListCategoriesOnlyDocument, options);
}
export function useFeatureLoyaltyRewardListCategoriesOnlyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyRewardListCategoriesOnlyQuery,
    IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyRewardListCategoriesOnlyQuery,
    IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables
  >(FeatureLoyaltyRewardListCategoriesOnlyDocument, options);
}
export type FeatureLoyaltyRewardListCategoriesOnlyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListCategoriesOnlyQuery
>;
export type FeatureLoyaltyRewardListCategoriesOnlyLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListCategoriesOnlyLazyQuery
>;
export type FeatureLoyaltyRewardListCategoriesOnlyQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyRewardListCategoriesOnlyQuery,
  IFeatureLoyaltyRewardListCategoriesOnlyQueryVariables
>;
export const FeatureLoyaltyRewardListCategoriesDocument = gql`
  query featureLoyaltyRewardListCategories($featureLoyaltyRewardListCategoriesId: ID!) {
    RewardList(id: $featureLoyaltyRewardListCategoriesId) {
      _id
      rewardCategories {
        label {
          locale: en
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
        imageDescription {
          locale: en
        }
        rewards {
          _id
          loyaltyEngineId
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLoyaltyRewardListCategoriesQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyRewardListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyRewardListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyRewardListCategoriesQuery({
 *   variables: {
 *      featureLoyaltyRewardListCategoriesId: // value for 'featureLoyaltyRewardListCategoriesId'
 *   },
 * });
 */
export function useFeatureLoyaltyRewardListCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyRewardListCategoriesQuery,
    IFeatureLoyaltyRewardListCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyRewardListCategoriesQuery,
    IFeatureLoyaltyRewardListCategoriesQueryVariables
  >(FeatureLoyaltyRewardListCategoriesDocument, options);
}
export function useFeatureLoyaltyRewardListCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyRewardListCategoriesQuery,
    IFeatureLoyaltyRewardListCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyRewardListCategoriesQuery,
    IFeatureLoyaltyRewardListCategoriesQueryVariables
  >(FeatureLoyaltyRewardListCategoriesDocument, options);
}
export type FeatureLoyaltyRewardListCategoriesQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListCategoriesQuery
>;
export type FeatureLoyaltyRewardListCategoriesLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListCategoriesLazyQuery
>;
export type FeatureLoyaltyRewardListCategoriesQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyRewardListCategoriesQuery,
  IFeatureLoyaltyRewardListCategoriesQueryVariables
>;
export const FeatureLoyaltyShortCodeConfirmationModalDocument = gql`
  query featureLoyaltyShortCodeConfirmationModal($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      shortCodeConfirmationModalContent {
        ...ShortCodeConfirmationModalFragment
      }
    }
  }
  ${ShortCodeConfirmationModalFragmentDoc}
`;

/**
 * __useFeatureLoyaltyShortCodeConfirmationModalQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyShortCodeConfirmationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyShortCodeConfirmationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyShortCodeConfirmationModalQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyShortCodeConfirmationModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyShortCodeConfirmationModalQuery,
    IFeatureLoyaltyShortCodeConfirmationModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyShortCodeConfirmationModalQuery,
    IFeatureLoyaltyShortCodeConfirmationModalQueryVariables
  >(FeatureLoyaltyShortCodeConfirmationModalDocument, options);
}
export function useFeatureLoyaltyShortCodeConfirmationModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyShortCodeConfirmationModalQuery,
    IFeatureLoyaltyShortCodeConfirmationModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyShortCodeConfirmationModalQuery,
    IFeatureLoyaltyShortCodeConfirmationModalQueryVariables
  >(FeatureLoyaltyShortCodeConfirmationModalDocument, options);
}
export type FeatureLoyaltyShortCodeConfirmationModalQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyShortCodeConfirmationModalQuery
>;
export type FeatureLoyaltyShortCodeConfirmationModalLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyShortCodeConfirmationModalLazyQuery
>;
export type FeatureLoyaltyShortCodeConfirmationModalQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyShortCodeConfirmationModalQuery,
  IFeatureLoyaltyShortCodeConfirmationModalQueryVariables
>;
export const FeatureLoyaltyOverrideShortCodeModalContentDocument = gql`
  query featureLoyaltyOverrideShortCodeModalContent($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      overrideShortCodeModalContent {
        header {
          ...LocaleStringFragment
        }
        body {
          ...LocaleStringFragment
        }
        confirmButton {
          ...LocaleStringFragment
        }
        discardButton {
          ...LocaleStringFragment
        }
      }
    }
  }
  ${LocaleStringFragmentDoc}
`;

/**
 * __useFeatureLoyaltyOverrideShortCodeModalContentQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyOverrideShortCodeModalContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyOverrideShortCodeModalContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyOverrideShortCodeModalContentQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyOverrideShortCodeModalContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyOverrideShortCodeModalContentQuery,
    IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyOverrideShortCodeModalContentQuery,
    IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables
  >(FeatureLoyaltyOverrideShortCodeModalContentDocument, options);
}
export function useFeatureLoyaltyOverrideShortCodeModalContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyOverrideShortCodeModalContentQuery,
    IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyOverrideShortCodeModalContentQuery,
    IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables
  >(FeatureLoyaltyOverrideShortCodeModalContentDocument, options);
}
export type FeatureLoyaltyOverrideShortCodeModalContentQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyOverrideShortCodeModalContentQuery
>;
export type FeatureLoyaltyOverrideShortCodeModalContentLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyOverrideShortCodeModalContentLazyQuery
>;
export type FeatureLoyaltyOverrideShortCodeModalContentQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyOverrideShortCodeModalContentQuery,
  IFeatureLoyaltyOverrideShortCodeModalContentQueryVariables
>;
export const FeatureLoyaltySurpriseModalDocument = gql`
  query featureLoyaltySurpriseModal($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      surpriseAndDelightModalContent {
        backgroundImage {
          locale: en {
            ...ImageFragment
          }
        }
        buttonText {
          locale: en
        }
        description {
          locale: en
        }
        noThanksText {
          locale: en
        }
        prompt {
          locale: en
        }
        termsText {
          localeRaw: enRaw
        }
        title {
          locale: en
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLoyaltySurpriseModalQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltySurpriseModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltySurpriseModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltySurpriseModalQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltySurpriseModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltySurpriseModalQuery,
    IFeatureLoyaltySurpriseModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltySurpriseModalQuery,
    IFeatureLoyaltySurpriseModalQueryVariables
  >(FeatureLoyaltySurpriseModalDocument, options);
}
export function useFeatureLoyaltySurpriseModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltySurpriseModalQuery,
    IFeatureLoyaltySurpriseModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltySurpriseModalQuery,
    IFeatureLoyaltySurpriseModalQueryVariables
  >(FeatureLoyaltySurpriseModalDocument, options);
}
export type FeatureLoyaltySurpriseModalQueryHookResult = ReturnType<
  typeof useFeatureLoyaltySurpriseModalQuery
>;
export type FeatureLoyaltySurpriseModalLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltySurpriseModalLazyQuery
>;
export type FeatureLoyaltySurpriseModalQueryResult = Apollo.QueryResult<
  IFeatureLoyaltySurpriseModalQuery,
  IFeatureLoyaltySurpriseModalQueryVariables
>;
export const FeatureLoyaltyTiersContentDocument = gql`
  query featureLoyaltyTiersContent($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      loyaltyTiersContent {
        loyaltyTiersUnauthenticatedCells {
          loyaltyTiersSignupCellImage {
            locale: en {
              ...ImageFragment
            }
          }
          loyaltyTiersSignupCellTitle {
            locale: en
          }
          loyaltyTiersSignupCellDescription {
            locale: en
          }
        }
        loyaltyTiersHowItWorksButtonText {
          locale: en
        }
        loyaltyTiersHowItWorksButtonLink {
          locale: en
        }
        loyaltyTiersRewardsButtonText {
          locale: en
        }
        loyaltyTiersRewardsButtonLink {
          locale: en
        }
        loyaltyTiersRewardsHistoryButtonText {
          locale: en
        }
        loyaltyTiersRewardsHistoryButtonLink {
          locale: en
        }
        loyaltyTiersOrderMoreButtonText {
          locale: en
        }
        loyaltyTiersOrderMoreButtonLink {
          locale: en
        }
        loyaltyTiersUIConfig {
          loyaltyTiersUIConfigID
          loyaltyTiersUIConfigName {
            locale: en
          }
          loyaltyTiersUIConfigIcon {
            locale: en {
              ...ImageFragment
            }
          }
          loyaltyTiersModalImage {
            ...ImageFragment
          }
          loyaltyTiersModalTitle {
            locale: en
          }
          loyaltyTiersModalDescription {
            localeRaw: enRaw
          }
          loyaltyTiersModalMainBtnLabel {
            locale: en
          }
          loyaltyTiersModalMainBtnLink {
            locale: en
          }
          loyaltyTiersCarouselRewards {
            _id
            loyaltyEngineId
            name {
              locale: en
            }
            description {
              locale: enRaw
            }
            image {
              locale: en {
                ...ImageFragment
              }
            }
            imageDescription {
              locale: en
            }
            ruleSet {
              ... on LoyaltyPoints {
                ...LoyaltyPoints
              }
              ... on TierList {
                ...TierList
              }
            }
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${LoyaltyPointsFragmentDoc}
  ${TierListFragmentDoc}
`;

/**
 * __useFeatureLoyaltyTiersContentQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyTiersContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyTiersContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyTiersContentQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyTiersContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyTiersContentQuery,
    IFeatureLoyaltyTiersContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyTiersContentQuery,
    IFeatureLoyaltyTiersContentQueryVariables
  >(FeatureLoyaltyTiersContentDocument, options);
}
export function useFeatureLoyaltyTiersContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyTiersContentQuery,
    IFeatureLoyaltyTiersContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyTiersContentQuery,
    IFeatureLoyaltyTiersContentQueryVariables
  >(FeatureLoyaltyTiersContentDocument, options);
}
export type FeatureLoyaltyTiersContentQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyTiersContentQuery
>;
export type FeatureLoyaltyTiersContentLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyTiersContentLazyQuery
>;
export type FeatureLoyaltyTiersContentQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyTiersContentQuery,
  IFeatureLoyaltyTiersContentQueryVariables
>;
export const FeatureLoyaltyRewardListsDocument = gql`
  query featureLoyaltyRewardLists($where: RewardListFilter) {
    allRewardList(where: $where) {
      _id
      rewardListLoyaltyTier
      rewardCategories {
        label {
          locale: en
        }
        image {
          locale: en {
            ...ImageFragment
          }
        }
        imageDescription {
          locale: en
        }
        rewards {
          _id
          loyaltyEngineId
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLoyaltyRewardListsQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyRewardListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyRewardListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyRewardListsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFeatureLoyaltyRewardListsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyRewardListsQuery,
    IFeatureLoyaltyRewardListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureLoyaltyRewardListsQuery,
    IFeatureLoyaltyRewardListsQueryVariables
  >(FeatureLoyaltyRewardListsDocument, options);
}
export function useFeatureLoyaltyRewardListsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyRewardListsQuery,
    IFeatureLoyaltyRewardListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureLoyaltyRewardListsQuery,
    IFeatureLoyaltyRewardListsQueryVariables
  >(FeatureLoyaltyRewardListsDocument, options);
}
export type FeatureLoyaltyRewardListsQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListsQuery
>;
export type FeatureLoyaltyRewardListsLazyQueryHookResult = ReturnType<
  typeof useFeatureLoyaltyRewardListsLazyQuery
>;
export type FeatureLoyaltyRewardListsQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyRewardListsQuery,
  IFeatureLoyaltyRewardListsQueryVariables
>;
export const FeatureLoyaltyUiDocument = gql`
  query featureLoyaltyUI($featureLoyaltyUIId: ID!) {
    LoyaltyUI(id: $featureLoyaltyUIId) {
      _id
      rewardsLogo {
        locale: en {
          ...ImageFragment
        }
      }
      rewardsLogoInverted {
        locale: en {
          ...ImageFragment
        }
      }
      loyaltyCTAButtons {
        loyaltyBannerHomepageAuthenticatedButton {
          loyaltyBannerHomepageAuthenticatedLabel {
            locale: en
          }
          loyaltyBannerHomepageAuthenticatedLink {
            locale: en
          }
        }
        loyaltyBannerHomepageUnauthenticatedButton {
          loyaltyBannerHomepageUnauthenticatedLabel {
            locale: en
          }
          loyaltyBannerHomepageUnauthenticatedLink {
            locale: en
          }
        }
        loyaltyBannerRewardsButton {
          loyaltyBannerRewardsLabel {
            locale: en
          }
          loyaltyBannerRewardsLink {
            locale: en
          }
        }
      }
      rewardsLearnMoreIcon {
        locale: en {
          ...ImageFragment
        }
      }
      rewardsProgressBarIcon {
        locale: en {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useFeatureLoyaltyUiQuery__
 *
 * To run a query within a React component, call `useFeatureLoyaltyUiQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureLoyaltyUiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureLoyaltyUiQuery({
 *   variables: {
 *      featureLoyaltyUIId: // value for 'featureLoyaltyUIId'
 *   },
 * });
 */
export function useFeatureLoyaltyUiQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureLoyaltyUiQuery,
    IFeatureLoyaltyUiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureLoyaltyUiQuery, IFeatureLoyaltyUiQueryVariables>(
    FeatureLoyaltyUiDocument,
    options
  );
}
export function useFeatureLoyaltyUiLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureLoyaltyUiQuery,
    IFeatureLoyaltyUiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureLoyaltyUiQuery, IFeatureLoyaltyUiQueryVariables>(
    FeatureLoyaltyUiDocument,
    options
  );
}
export type FeatureLoyaltyUiQueryHookResult = ReturnType<typeof useFeatureLoyaltyUiQuery>;
export type FeatureLoyaltyUiLazyQueryHookResult = ReturnType<typeof useFeatureLoyaltyUiLazyQuery>;
export type FeatureLoyaltyUiQueryResult = Apollo.QueryResult<
  IFeatureLoyaltyUiQuery,
  IFeatureLoyaltyUiQueryVariables
>;
export const FeatureMembershipsDocument = gql`
  query featureMemberships($featureMembershipsId: ID!) {
    LiveMemberships(id: $featureMembershipsId) {
      _id
      liveMemberships {
        ...MembershipsFragment
      }
    }
  }
  ${MembershipsFragmentDoc}
`;

/**
 * __useFeatureMembershipsQuery__
 *
 * To run a query within a React component, call `useFeatureMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureMembershipsQuery({
 *   variables: {
 *      featureMembershipsId: // value for 'featureMembershipsId'
 *   },
 * });
 */
export function useFeatureMembershipsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureMembershipsQuery,
    IFeatureMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureMembershipsQuery, IFeatureMembershipsQueryVariables>(
    FeatureMembershipsDocument,
    options
  );
}
export function useFeatureMembershipsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureMembershipsQuery,
    IFeatureMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureMembershipsQuery, IFeatureMembershipsQueryVariables>(
    FeatureMembershipsDocument,
    options
  );
}
export type FeatureMembershipsQueryHookResult = ReturnType<typeof useFeatureMembershipsQuery>;
export type FeatureMembershipsLazyQueryHookResult = ReturnType<
  typeof useFeatureMembershipsLazyQuery
>;
export type FeatureMembershipsQueryResult = Apollo.QueryResult<
  IFeatureMembershipsQuery,
  IFeatureMembershipsQueryVariables
>;
export const FeatureMenuDocument = gql`
  query featureMenu($featureMenuId: ID!) {
    FeatureMenu(id: $featureMenuId) {
      _id
      additionalDetails {
        additionalDetailsList {
          key
          regex
          required
          validationError {
            locale: en
          }
          isPii
          description {
            locale: en
          }
        }
      }
      menuHeroImage {
        locale: en {
          ...ImageFragment
        }
      }
      menuHeroText {
        locale: en
      }
      dayParts {
        key
        endTime
        startTime
        weekDays {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        displayName {
          locale: en
        }
        icon {
          ...ImageFragment
        }
      }
      defaultMenu {
        _id
      }
      cateringMenu {
        _id
      }
      donationTermAndConditionUrl {
        locale: en
      }
      donationItemsAtCheckout {
        ... on Item {
          ...ItemFragment
          description {
            localeRaw: enRaw
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
      }
      upsellItemsAtCheckoutRestaurant {
        ... on Item {
          ...ItemFragment
          description {
            localeRaw: enRaw
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
      }
      upsellItemsAtCheckoutDelivery {
        ... on Item {
          ...ItemFragment
          description {
            localeRaw: enRaw
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
        }
      }
      upsellItemsAtCheckoutCatering {
        ... on Item {
          ...ItemFragment
          description {
            localeRaw: enRaw
          }
          vendorConfigs {
            ...VendorConfigsFragment
          }
          pluConfigs {
            ...PluConfigsFragment
          }
        }
      }
      cartAddOnSections {
        ...AddOnSectionFragment
      }
      cartAddOnSectionsCatering {
        ...AddOnSectionFragment
      }
      enableDynamicPickerImages
    }
  }
  ${ImageFragmentDoc}
  ${ItemFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
  ${AddOnSectionFragmentDoc}
`;

/**
 * __useFeatureMenuQuery__
 *
 * To run a query within a React component, call `useFeatureMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureMenuQuery({
 *   variables: {
 *      featureMenuId: // value for 'featureMenuId'
 *   },
 * });
 */
export function useFeatureMenuQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IFeatureMenuQuery, IFeatureMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureMenuQuery, IFeatureMenuQueryVariables>(
    FeatureMenuDocument,
    options
  );
}
export function useFeatureMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IFeatureMenuQuery, IFeatureMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureMenuQuery, IFeatureMenuQueryVariables>(
    FeatureMenuDocument,
    options
  );
}
export type FeatureMenuQueryHookResult = ReturnType<typeof useFeatureMenuQuery>;
export type FeatureMenuLazyQueryHookResult = ReturnType<typeof useFeatureMenuLazyQuery>;
export type FeatureMenuQueryResult = Apollo.QueryResult<
  IFeatureMenuQuery,
  IFeatureMenuQueryVariables
>;
export const FeatureNavigationDocument = gql`
  query featureNavigation($featureNavigationId: ID!) {
    FeatureNavigation(id: $featureNavigationId) {
      ...NavigationDesktopFragment
      ...NavigationMobileFragment
      ...NavigationButtonFragment
      aboutMenuEntries {
        ...NavigationSectionFragment
      }
    }
  }
  ${NavigationDesktopFragmentDoc}
  ${NavigationMobileFragmentDoc}
  ${NavigationButtonFragmentDoc}
  ${NavigationSectionFragmentDoc}
`;

/**
 * __useFeatureNavigationQuery__
 *
 * To run a query within a React component, call `useFeatureNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureNavigationQuery({
 *   variables: {
 *      featureNavigationId: // value for 'featureNavigationId'
 *   },
 * });
 */
export function useFeatureNavigationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureNavigationQuery,
    IFeatureNavigationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureNavigationQuery, IFeatureNavigationQueryVariables>(
    FeatureNavigationDocument,
    options
  );
}
export function useFeatureNavigationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureNavigationQuery,
    IFeatureNavigationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureNavigationQuery, IFeatureNavigationQueryVariables>(
    FeatureNavigationDocument,
    options
  );
}
export type FeatureNavigationQueryHookResult = ReturnType<typeof useFeatureNavigationQuery>;
export type FeatureNavigationLazyQueryHookResult = ReturnType<typeof useFeatureNavigationLazyQuery>;
export type FeatureNavigationQueryResult = Apollo.QueryResult<
  IFeatureNavigationQuery,
  IFeatureNavigationQueryVariables
>;
export const FeatureNutritionDocument = gql`
  query featureNutrition($featureNutritionId: ID!) {
    FeatureNutrition(id: $featureNutritionId) {
      _id
      nutritionTables {
        ...NutritionTableFragment
      }
      roundingRules {
        ...RoundingRulesFragment
      }
    }
  }
  ${NutritionTableFragmentDoc}
  ${RoundingRulesFragmentDoc}
`;

/**
 * __useFeatureNutritionQuery__
 *
 * To run a query within a React component, call `useFeatureNutritionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureNutritionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureNutritionQuery({
 *   variables: {
 *      featureNutritionId: // value for 'featureNutritionId'
 *   },
 * });
 */
export function useFeatureNutritionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureNutritionQuery,
    IFeatureNutritionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureNutritionQuery, IFeatureNutritionQueryVariables>(
    FeatureNutritionDocument,
    options
  );
}
export function useFeatureNutritionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureNutritionQuery,
    IFeatureNutritionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureNutritionQuery, IFeatureNutritionQueryVariables>(
    FeatureNutritionDocument,
    options
  );
}
export type FeatureNutritionQueryHookResult = ReturnType<typeof useFeatureNutritionQuery>;
export type FeatureNutritionLazyQueryHookResult = ReturnType<typeof useFeatureNutritionLazyQuery>;
export type FeatureNutritionQueryResult = Apollo.QueryResult<
  IFeatureNutritionQuery,
  IFeatureNutritionQueryVariables
>;
export const FeatureQrCodeRefillDrinksDocument = gql`
  query featureQRCodeRefillDrinks($featureQRCodeRefillDrinksId: ID!) {
    FeatureQRCodeRefillDrinks(id: $featureQRCodeRefillDrinksId) {
      _id
      refillDrinksServiceMode {
        ...RefillDrinkModeFragment
      }
      refillDrinksTimers {
        ...RefillDrinkTimersFragment
      }
      restaurants {
        ... on Restaurant {
          _id
        }
      }
      items {
        ... on Item {
          _id
        }
      }
    }
  }
  ${RefillDrinkModeFragmentDoc}
  ${RefillDrinkTimersFragmentDoc}
`;

/**
 * __useFeatureQrCodeRefillDrinksQuery__
 *
 * To run a query within a React component, call `useFeatureQrCodeRefillDrinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureQrCodeRefillDrinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureQrCodeRefillDrinksQuery({
 *   variables: {
 *      featureQRCodeRefillDrinksId: // value for 'featureQRCodeRefillDrinksId'
 *   },
 * });
 */
export function useFeatureQrCodeRefillDrinksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureQrCodeRefillDrinksQuery,
    IFeatureQrCodeRefillDrinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IFeatureQrCodeRefillDrinksQuery,
    IFeatureQrCodeRefillDrinksQueryVariables
  >(FeatureQrCodeRefillDrinksDocument, options);
}
export function useFeatureQrCodeRefillDrinksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureQrCodeRefillDrinksQuery,
    IFeatureQrCodeRefillDrinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IFeatureQrCodeRefillDrinksQuery,
    IFeatureQrCodeRefillDrinksQueryVariables
  >(FeatureQrCodeRefillDrinksDocument, options);
}
export type FeatureQrCodeRefillDrinksQueryHookResult = ReturnType<
  typeof useFeatureQrCodeRefillDrinksQuery
>;
export type FeatureQrCodeRefillDrinksLazyQueryHookResult = ReturnType<
  typeof useFeatureQrCodeRefillDrinksLazyQuery
>;
export type FeatureQrCodeRefillDrinksQueryResult = Apollo.QueryResult<
  IFeatureQrCodeRefillDrinksQuery,
  IFeatureQrCodeRefillDrinksQueryVariables
>;
export const FeatureStaticMenuDocument = gql`
  query featureStaticMenu($featureStaticMenuId: ID!) {
    FeatureStaticMenu(id: $featureStaticMenuId) {
      ...itemUnavailableModalFragment
    }
  }
  ${ItemUnavailableModalFragmentDoc}
`;

/**
 * __useFeatureStaticMenuQuery__
 *
 * To run a query within a React component, call `useFeatureStaticMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureStaticMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureStaticMenuQuery({
 *   variables: {
 *      featureStaticMenuId: // value for 'featureStaticMenuId'
 *   },
 * });
 */
export function useFeatureStaticMenuQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFeatureStaticMenuQuery,
    IFeatureStaticMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IFeatureStaticMenuQuery, IFeatureStaticMenuQueryVariables>(
    FeatureStaticMenuDocument,
    options
  );
}
export function useFeatureStaticMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFeatureStaticMenuQuery,
    IFeatureStaticMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IFeatureStaticMenuQuery, IFeatureStaticMenuQueryVariables>(
    FeatureStaticMenuDocument,
    options
  );
}
export type FeatureStaticMenuQueryHookResult = ReturnType<typeof useFeatureStaticMenuQuery>;
export type FeatureStaticMenuLazyQueryHookResult = ReturnType<typeof useFeatureStaticMenuLazyQuery>;
export type FeatureStaticMenuQueryResult = Apollo.QueryResult<
  IFeatureStaticMenuQuery,
  IFeatureStaticMenuQueryVariables
>;
export const GetItemDocument = gql`
  query GetItem($id: ID!) {
    Item(id: $id) {
      ...ItemFragment
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IGetItemQuery, IGetItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetItemQuery, IGetItemQueryVariables>(GetItemDocument, options);
}
export function useGetItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetItemQuery, IGetItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetItemQuery, IGetItemQueryVariables>(
    GetItemDocument,
    options
  );
}
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<IGetItemQuery, IGetItemQueryVariables>;
export const GetItemWithVendorConfigDocument = gql`
  query GetItemWithVendorConfig($id: ID!) {
    Item(id: $id) {
      ...ItemFragment
      vendorConfigs {
        ...VendorConfigsFragment
      }
      pluConfigs {
        ...PluConfigsFragment
      }
    }
  }
  ${ItemFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${PluConfigsFragmentDoc}
`;

/**
 * __useGetItemWithVendorConfigQuery__
 *
 * To run a query within a React component, call `useGetItemWithVendorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemWithVendorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemWithVendorConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemWithVendorConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetItemWithVendorConfigQuery,
    IGetItemWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetItemWithVendorConfigQuery,
    IGetItemWithVendorConfigQueryVariables
  >(GetItemWithVendorConfigDocument, options);
}
export function useGetItemWithVendorConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetItemWithVendorConfigQuery,
    IGetItemWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetItemWithVendorConfigQuery,
    IGetItemWithVendorConfigQueryVariables
  >(GetItemWithVendorConfigDocument, options);
}
export type GetItemWithVendorConfigQueryHookResult = ReturnType<
  typeof useGetItemWithVendorConfigQuery
>;
export type GetItemWithVendorConfigLazyQueryHookResult = ReturnType<
  typeof useGetItemWithVendorConfigLazyQuery
>;
export type GetItemWithVendorConfigQueryResult = Apollo.QueryResult<
  IGetItemWithVendorConfigQuery,
  IGetItemWithVendorConfigQueryVariables
>;
export const GetItemAvailabilityDocument = gql`
  query GetItemAvailability($id: ID!) {
    Item(id: $id) {
      ...ItemAvailabilityFragment
    }
  }
  ${ItemAvailabilityFragmentDoc}
`;

/**
 * __useGetItemAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetItemAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemAvailabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemAvailabilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetItemAvailabilityQuery,
    IGetItemAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetItemAvailabilityQuery, IGetItemAvailabilityQueryVariables>(
    GetItemAvailabilityDocument,
    options
  );
}
export function useGetItemAvailabilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetItemAvailabilityQuery,
    IGetItemAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetItemAvailabilityQuery,
    IGetItemAvailabilityQueryVariables
  >(GetItemAvailabilityDocument, options);
}
export type GetItemAvailabilityQueryHookResult = ReturnType<typeof useGetItemAvailabilityQuery>;
export type GetItemAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetItemAvailabilityLazyQuery
>;
export type GetItemAvailabilityQueryResult = Apollo.QueryResult<
  IGetItemAvailabilityQuery,
  IGetItemAvailabilityQueryVariables
>;
export const SingleLoyaltyContentDocument = gql`
  query singleLoyaltyContent {
    allLoyaltyContent(limit: 1) {
      _id
    }
  }
`;

/**
 * __useSingleLoyaltyContentQuery__
 *
 * To run a query within a React component, call `useSingleLoyaltyContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleLoyaltyContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleLoyaltyContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSingleLoyaltyContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ISingleLoyaltyContentQuery,
    ISingleLoyaltyContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ISingleLoyaltyContentQuery, ISingleLoyaltyContentQueryVariables>(
    SingleLoyaltyContentDocument,
    options
  );
}
export function useSingleLoyaltyContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ISingleLoyaltyContentQuery,
    ISingleLoyaltyContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ISingleLoyaltyContentQuery,
    ISingleLoyaltyContentQueryVariables
  >(SingleLoyaltyContentDocument, options);
}
export type SingleLoyaltyContentQueryHookResult = ReturnType<typeof useSingleLoyaltyContentQuery>;
export type SingleLoyaltyContentLazyQueryHookResult = ReturnType<
  typeof useSingleLoyaltyContentLazyQuery
>;
export type SingleLoyaltyContentQueryResult = Apollo.QueryResult<
  ISingleLoyaltyContentQuery,
  ISingleLoyaltyContentQueryVariables
>;
export const BirthdayLegalTextDocument = gql`
  query birthdayLegalText($loyaltyContentId: ID!) {
    LoyaltyContent(id: $loyaltyContentId) {
      birthdayLegalText {
        locale: en
      }
    }
  }
`;

/**
 * __useBirthdayLegalTextQuery__
 *
 * To run a query within a React component, call `useBirthdayLegalTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirthdayLegalTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirthdayLegalTextQuery({
 *   variables: {
 *      loyaltyContentId: // value for 'loyaltyContentId'
 *   },
 * });
 */
export function useBirthdayLegalTextQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IBirthdayLegalTextQuery,
    IBirthdayLegalTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IBirthdayLegalTextQuery, IBirthdayLegalTextQueryVariables>(
    BirthdayLegalTextDocument,
    options
  );
}
export function useBirthdayLegalTextLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IBirthdayLegalTextQuery,
    IBirthdayLegalTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IBirthdayLegalTextQuery, IBirthdayLegalTextQueryVariables>(
    BirthdayLegalTextDocument,
    options
  );
}
export type BirthdayLegalTextQueryHookResult = ReturnType<typeof useBirthdayLegalTextQuery>;
export type BirthdayLegalTextLazyQueryHookResult = ReturnType<typeof useBirthdayLegalTextLazyQuery>;
export type BirthdayLegalTextQueryResult = Apollo.QueryResult<
  IBirthdayLegalTextQuery,
  IBirthdayLegalTextQueryVariables
>;
export const SystemwidePromotionMessageDocument = gql`
  query systemwidePromotionMessage($id: ID!) {
    SystemwidePromotion(id: $id) {
      promoMessage {
        locale: en
      }
    }
  }
`;

/**
 * __useSystemwidePromotionMessageQuery__
 *
 * To run a query within a React component, call `useSystemwidePromotionMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemwidePromotionMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemwidePromotionMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemwidePromotionMessageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ISystemwidePromotionMessageQuery,
    ISystemwidePromotionMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ISystemwidePromotionMessageQuery,
    ISystemwidePromotionMessageQueryVariables
  >(SystemwidePromotionMessageDocument, options);
}
export function useSystemwidePromotionMessageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ISystemwidePromotionMessageQuery,
    ISystemwidePromotionMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ISystemwidePromotionMessageQuery,
    ISystemwidePromotionMessageQueryVariables
  >(SystemwidePromotionMessageDocument, options);
}
export type SystemwidePromotionMessageQueryHookResult = ReturnType<
  typeof useSystemwidePromotionMessageQuery
>;
export type SystemwidePromotionMessageLazyQueryHookResult = ReturnType<
  typeof useSystemwidePromotionMessageLazyQuery
>;
export type SystemwidePromotionMessageQueryResult = Apollo.QueryResult<
  ISystemwidePromotionMessageQuery,
  ISystemwidePromotionMessageQueryVariables
>;
export const PosDataDocument = gql`
  query posData($id: ID!) {
    RestaurantPosData(id: $id) {
      _id
      plus
      deliveryPLUs
    }
  }
`;

/**
 * __usePosDataQuery__
 *
 * To run a query within a React component, call `usePosDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePosDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IPosDataQuery, IPosDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IPosDataQuery, IPosDataQueryVariables>(PosDataDocument, options);
}
export function usePosDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IPosDataQuery, IPosDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IPosDataQuery, IPosDataQueryVariables>(
    PosDataDocument,
    options
  );
}
export type PosDataQueryHookResult = ReturnType<typeof usePosDataQuery>;
export type PosDataLazyQueryHookResult = ReturnType<typeof usePosDataLazyQuery>;
export type PosDataQueryResult = Apollo.QueryResult<IPosDataQuery, IPosDataQueryVariables>;
export const GetOfferByIdDocument = gql`
  query GetOfferById($id: ID!) {
    Offer(id: $id) {
      ...OffersFragment
    }
  }
  ${OffersFragmentDoc}
`;

/**
 * __useGetOfferByIdQuery__
 *
 * To run a query within a React component, call `useGetOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IGetOfferByIdQuery, IGetOfferByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetOfferByIdQuery, IGetOfferByIdQueryVariables>(
    GetOfferByIdDocument,
    options
  );
}
export function useGetOfferByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetOfferByIdQuery,
    IGetOfferByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetOfferByIdQuery, IGetOfferByIdQueryVariables>(
    GetOfferByIdDocument,
    options
  );
}
export type GetOfferByIdQueryHookResult = ReturnType<typeof useGetOfferByIdQuery>;
export type GetOfferByIdLazyQueryHookResult = ReturnType<typeof useGetOfferByIdLazyQuery>;
export type GetOfferByIdQueryResult = Apollo.QueryResult<
  IGetOfferByIdQuery,
  IGetOfferByIdQueryVariables
>;
export const GetPickerDocument = gql`
  query GetPicker($id: ID!) {
    Picker(id: $id) {
      ...PickerFragment
    }
  }
  ${PickerFragmentDoc}
`;

/**
 * __useGetPickerQuery__
 *
 * To run a query within a React component, call `useGetPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPickerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IGetPickerQuery, IGetPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetPickerQuery, IGetPickerQueryVariables>(
    GetPickerDocument,
    options
  );
}
export function useGetPickerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetPickerQuery, IGetPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetPickerQuery, IGetPickerQueryVariables>(
    GetPickerDocument,
    options
  );
}
export type GetPickerQueryHookResult = ReturnType<typeof useGetPickerQuery>;
export type GetPickerLazyQueryHookResult = ReturnType<typeof useGetPickerLazyQuery>;
export type GetPickerQueryResult = Apollo.QueryResult<IGetPickerQuery, IGetPickerQueryVariables>;
export const GetPickerWithVendorConfigDocument = gql`
  query GetPickerWithVendorConfig($id: ID!) {
    Picker(id: $id) {
      ...PickerWithVendorConfigFragment
    }
  }
  ${PickerWithVendorConfigFragmentDoc}
`;

/**
 * __useGetPickerWithVendorConfigQuery__
 *
 * To run a query within a React component, call `useGetPickerWithVendorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickerWithVendorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickerWithVendorConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPickerWithVendorConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetPickerWithVendorConfigQuery,
    IGetPickerWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetPickerWithVendorConfigQuery,
    IGetPickerWithVendorConfigQueryVariables
  >(GetPickerWithVendorConfigDocument, options);
}
export function useGetPickerWithVendorConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetPickerWithVendorConfigQuery,
    IGetPickerWithVendorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetPickerWithVendorConfigQuery,
    IGetPickerWithVendorConfigQueryVariables
  >(GetPickerWithVendorConfigDocument, options);
}
export type GetPickerWithVendorConfigQueryHookResult = ReturnType<
  typeof useGetPickerWithVendorConfigQuery
>;
export type GetPickerWithVendorConfigLazyQueryHookResult = ReturnType<
  typeof useGetPickerWithVendorConfigLazyQuery
>;
export type GetPickerWithVendorConfigQueryResult = Apollo.QueryResult<
  IGetPickerWithVendorConfigQuery,
  IGetPickerWithVendorConfigQueryVariables
>;
export const GetPickerAvailabilityDocument = gql`
  query GetPickerAvailability($id: ID!) {
    Picker(id: $id) {
      ...PickerAvailabilityFragment
    }
  }
  ${PickerAvailabilityFragmentDoc}
`;

/**
 * __useGetPickerAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetPickerAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickerAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickerAvailabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPickerAvailabilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetPickerAvailabilityQuery,
    IGetPickerAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetPickerAvailabilityQuery,
    IGetPickerAvailabilityQueryVariables
  >(GetPickerAvailabilityDocument, options);
}
export function useGetPickerAvailabilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetPickerAvailabilityQuery,
    IGetPickerAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetPickerAvailabilityQuery,
    IGetPickerAvailabilityQueryVariables
  >(GetPickerAvailabilityDocument, options);
}
export type GetPickerAvailabilityQueryHookResult = ReturnType<typeof useGetPickerAvailabilityQuery>;
export type GetPickerAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetPickerAvailabilityLazyQuery
>;
export type GetPickerAvailabilityQueryResult = Apollo.QueryResult<
  IGetPickerAvailabilityQuery,
  IGetPickerAvailabilityQueryVariables
>;
export const PlaceholderFieldsDocument = gql`
  query PlaceholderFields {
    allPlaceholderField {
      _id
      formatter
      path
      fieldId
    }
  }
`;

/**
 * __usePlaceholderFieldsQuery__
 *
 * To run a query within a React component, call `usePlaceholderFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceholderFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceholderFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaceholderFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IPlaceholderFieldsQuery,
    IPlaceholderFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IPlaceholderFieldsQuery, IPlaceholderFieldsQueryVariables>(
    PlaceholderFieldsDocument,
    options
  );
}
export function usePlaceholderFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IPlaceholderFieldsQuery,
    IPlaceholderFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IPlaceholderFieldsQuery, IPlaceholderFieldsQueryVariables>(
    PlaceholderFieldsDocument,
    options
  );
}
export type PlaceholderFieldsQueryHookResult = ReturnType<typeof usePlaceholderFieldsQuery>;
export type PlaceholderFieldsLazyQueryHookResult = ReturnType<typeof usePlaceholderFieldsLazyQuery>;
export type PlaceholderFieldsQueryResult = Apollo.QueryResult<
  IPlaceholderFieldsQuery,
  IPlaceholderFieldsQueryVariables
>;
export const GetProductCategoryDocument = gql`
  query GetProductCategory($id: ID!) {
    ProductCategory(id: $id) {
      ...ProductCategoryFragment
    }
  }
  ${ProductCategoryFragmentDoc}
`;

/**
 * __useGetProductCategoryQuery__
 *
 * To run a query within a React component, call `useGetProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductCategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetProductCategoryQuery,
    IGetProductCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetProductCategoryQuery, IGetProductCategoryQueryVariables>(
    GetProductCategoryDocument,
    options
  );
}
export function useGetProductCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetProductCategoryQuery,
    IGetProductCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetProductCategoryQuery, IGetProductCategoryQueryVariables>(
    GetProductCategoryDocument,
    options
  );
}
export type GetProductCategoryQueryHookResult = ReturnType<typeof useGetProductCategoryQuery>;
export type GetProductCategoryLazyQueryHookResult = ReturnType<
  typeof useGetProductCategoryLazyQuery
>;
export type GetProductCategoryQueryResult = Apollo.QueryResult<
  IGetProductCategoryQuery,
  IGetProductCategoryQueryVariables
>;
export const GetMenuSectionsDocument = gql`
  query GetMenuSections($id: ID!) {
    Menu(id: $id) {
      _id
      _type
      name {
        locale: en
      }
      image {
        ...ImageFragment
      }
      options {
        ...ProductListSectionFragment
        ...PickerFragment
      }
      headerText {
        locale: en
      }
      pickerBackgroundImage {
        asset {
          _id
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${ProductListSectionFragmentDoc}
  ${PickerFragmentDoc}
`;

/**
 * __useGetMenuSectionsQuery__
 *
 * To run a query within a React component, call `useGetMenuSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuSectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuSectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetMenuSectionsQuery,
    IGetMenuSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetMenuSectionsQuery, IGetMenuSectionsQueryVariables>(
    GetMenuSectionsDocument,
    options
  );
}
export function useGetMenuSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetMenuSectionsQuery,
    IGetMenuSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetMenuSectionsQuery, IGetMenuSectionsQueryVariables>(
    GetMenuSectionsDocument,
    options
  );
}
export type GetMenuSectionsQueryHookResult = ReturnType<typeof useGetMenuSectionsQuery>;
export type GetMenuSectionsLazyQueryHookResult = ReturnType<typeof useGetMenuSectionsLazyQuery>;
export type GetMenuSectionsQueryResult = Apollo.QueryResult<
  IGetMenuSectionsQuery,
  IGetMenuSectionsQueryVariables
>;
export const GetMenuPickerByIdDocument = gql`
  query GetMenuPickerById($id: ID!) {
    Picker(id: $id) {
      ...PickerFragment
    }
  }
  ${PickerFragmentDoc}
`;

/**
 * __useGetMenuPickerByIdQuery__
 *
 * To run a query within a React component, call `useGetMenuPickerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPickerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPickerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuPickerByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetMenuPickerByIdQuery,
    IGetMenuPickerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetMenuPickerByIdQuery, IGetMenuPickerByIdQueryVariables>(
    GetMenuPickerByIdDocument,
    options
  );
}
export function useGetMenuPickerByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetMenuPickerByIdQuery,
    IGetMenuPickerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetMenuPickerByIdQuery, IGetMenuPickerByIdQueryVariables>(
    GetMenuPickerByIdDocument,
    options
  );
}
export type GetMenuPickerByIdQueryHookResult = ReturnType<typeof useGetMenuPickerByIdQuery>;
export type GetMenuPickerByIdLazyQueryHookResult = ReturnType<typeof useGetMenuPickerByIdLazyQuery>;
export type GetMenuPickerByIdQueryResult = Apollo.QueryResult<
  IGetMenuPickerByIdQuery,
  IGetMenuPickerByIdQueryVariables
>;
export const GetMenuComboByIdDocument = gql`
  query GetMenuComboById($id: ID!) {
    Combo(id: $id) {
      ...ComboFragment
    }
  }
  ${ComboFragmentDoc}
`;

/**
 * __useGetMenuComboByIdQuery__
 *
 * To run a query within a React component, call `useGetMenuComboByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuComboByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuComboByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuComboByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetMenuComboByIdQuery,
    IGetMenuComboByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetMenuComboByIdQuery, IGetMenuComboByIdQueryVariables>(
    GetMenuComboByIdDocument,
    options
  );
}
export function useGetMenuComboByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetMenuComboByIdQuery,
    IGetMenuComboByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetMenuComboByIdQuery, IGetMenuComboByIdQueryVariables>(
    GetMenuComboByIdDocument,
    options
  );
}
export type GetMenuComboByIdQueryHookResult = ReturnType<typeof useGetMenuComboByIdQuery>;
export type GetMenuComboByIdLazyQueryHookResult = ReturnType<typeof useGetMenuComboByIdLazyQuery>;
export type GetMenuComboByIdQueryResult = Apollo.QueryResult<
  IGetMenuComboByIdQuery,
  IGetMenuComboByIdQueryVariables
>;
export const GetMenuItemByIdDocument = gql`
  query GetMenuItemById($id: ID!) {
    Item(id: $id) {
      ...ItemFragment
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useGetMenuItemByIdQuery__
 *
 * To run a query within a React component, call `useGetMenuItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuItemByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMenuItemByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetMenuItemByIdQuery,
    IGetMenuItemByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetMenuItemByIdQuery, IGetMenuItemByIdQueryVariables>(
    GetMenuItemByIdDocument,
    options
  );
}
export function useGetMenuItemByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetMenuItemByIdQuery,
    IGetMenuItemByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetMenuItemByIdQuery, IGetMenuItemByIdQueryVariables>(
    GetMenuItemByIdDocument,
    options
  );
}
export type GetMenuItemByIdQueryHookResult = ReturnType<typeof useGetMenuItemByIdQuery>;
export type GetMenuItemByIdLazyQueryHookResult = ReturnType<typeof useGetMenuItemByIdLazyQuery>;
export type GetMenuItemByIdQueryResult = Apollo.QueryResult<
  IGetMenuItemByIdQuery,
  IGetMenuItemByIdQueryVariables
>;
export const RedeemInRestaurantConfigDocument = gql`
  query RedeemInRestaurantConfig($redeemInRestaurantConfigId: ID!) {
    ConfigRedeemInRestaurant(id: $redeemInRestaurantConfigId) {
      _id
      showQrCode
      widgets {
        ... on LoyaltyQRAndShortCodeWidget {
          ...LoyaltyQRAndShortCodeWidgetFragment
        }
        ... on LoyaltyQRCodeWidget {
          ...LoyaltyQRCodeWidgetFragment
        }
        ... on LoyaltyShortCodeWidget {
          ...LoyaltyShortCodeWidgetFragment
        }
        ... on LoyaltyTextWidget {
          ...LoyaltyTextWidgetFragment
        }
        ... on LoyaltyMarketingTileGroupWidget {
          _key
          componentKey
          marketingTileGroup {
            ...MarketingTileGroupFragment
          }
        }
        ... on LoyaltyLegacyShortCodeWidget {
          ...LoyaltyLegacyShortCodeWidgetFragment
        }
      }
    }
  }
  ${LoyaltyQrAndShortCodeWidgetFragmentDoc}
  ${LoyaltyQrCodeWidgetFragmentDoc}
  ${LoyaltyShortCodeWidgetFragmentDoc}
  ${LoyaltyTextWidgetFragmentDoc}
  ${MarketingTileGroupFragmentDoc}
  ${LoyaltyLegacyShortCodeWidgetFragmentDoc}
`;

/**
 * __useRedeemInRestaurantConfigQuery__
 *
 * To run a query within a React component, call `useRedeemInRestaurantConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedeemInRestaurantConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedeemInRestaurantConfigQuery({
 *   variables: {
 *      redeemInRestaurantConfigId: // value for 'redeemInRestaurantConfigId'
 *   },
 * });
 */
export function useRedeemInRestaurantConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IRedeemInRestaurantConfigQuery,
    IRedeemInRestaurantConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IRedeemInRestaurantConfigQuery,
    IRedeemInRestaurantConfigQueryVariables
  >(RedeemInRestaurantConfigDocument, options);
}
export function useRedeemInRestaurantConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IRedeemInRestaurantConfigQuery,
    IRedeemInRestaurantConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IRedeemInRestaurantConfigQuery,
    IRedeemInRestaurantConfigQueryVariables
  >(RedeemInRestaurantConfigDocument, options);
}
export type RedeemInRestaurantConfigQueryHookResult = ReturnType<
  typeof useRedeemInRestaurantConfigQuery
>;
export type RedeemInRestaurantConfigLazyQueryHookResult = ReturnType<
  typeof useRedeemInRestaurantConfigLazyQuery
>;
export type RedeemInRestaurantConfigQueryResult = Apollo.QueryResult<
  IRedeemInRestaurantConfigQuery,
  IRedeemInRestaurantConfigQueryVariables
>;
export const ResturantGroupByGroupIdDocument = gql`
  query ResturantGroupByGroupId($id: String!) {
    allRestaurantGroup(where: { groupId: { eq: $id } }) {
      _id
      name
      restaurants {
        _id
        number
      }
    }
  }
`;

/**
 * __useResturantGroupByGroupIdQuery__
 *
 * To run a query within a React component, call `useResturantGroupByGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useResturantGroupByGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResturantGroupByGroupIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResturantGroupByGroupIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IResturantGroupByGroupIdQuery,
    IResturantGroupByGroupIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IResturantGroupByGroupIdQuery,
    IResturantGroupByGroupIdQueryVariables
  >(ResturantGroupByGroupIdDocument, options);
}
export function useResturantGroupByGroupIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IResturantGroupByGroupIdQuery,
    IResturantGroupByGroupIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IResturantGroupByGroupIdQuery,
    IResturantGroupByGroupIdQueryVariables
  >(ResturantGroupByGroupIdDocument, options);
}
export type ResturantGroupByGroupIdQueryHookResult = ReturnType<
  typeof useResturantGroupByGroupIdQuery
>;
export type ResturantGroupByGroupIdLazyQueryHookResult = ReturnType<
  typeof useResturantGroupByGroupIdLazyQuery
>;
export type ResturantGroupByGroupIdQueryResult = Apollo.QueryResult<
  IResturantGroupByGroupIdQuery,
  IResturantGroupByGroupIdQueryVariables
>;
export const ResturantGroupByGeolocationDocument = gql`
  query ResturantGroupByGeolocation($geolocation: Boolean!) {
    allRestaurantGroup(where: { geolocation: { eq: $geolocation } }) {
      _id
      name
      geolocation
      restaurants {
        _id
        number
      }
    }
  }
`;

/**
 * __useResturantGroupByGeolocationQuery__
 *
 * To run a query within a React component, call `useResturantGroupByGeolocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useResturantGroupByGeolocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResturantGroupByGeolocationQuery({
 *   variables: {
 *      geolocation: // value for 'geolocation'
 *   },
 * });
 */
export function useResturantGroupByGeolocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IResturantGroupByGeolocationQuery,
    IResturantGroupByGeolocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IResturantGroupByGeolocationQuery,
    IResturantGroupByGeolocationQueryVariables
  >(ResturantGroupByGeolocationDocument, options);
}
export function useResturantGroupByGeolocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IResturantGroupByGeolocationQuery,
    IResturantGroupByGeolocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IResturantGroupByGeolocationQuery,
    IResturantGroupByGeolocationQueryVariables
  >(ResturantGroupByGeolocationDocument, options);
}
export type ResturantGroupByGeolocationQueryHookResult = ReturnType<
  typeof useResturantGroupByGeolocationQuery
>;
export type ResturantGroupByGeolocationLazyQueryHookResult = ReturnType<
  typeof useResturantGroupByGeolocationLazyQuery
>;
export type ResturantGroupByGeolocationQueryResult = Apollo.QueryResult<
  IResturantGroupByGeolocationQuery,
  IResturantGroupByGeolocationQueryVariables
>;
export const GetRestaurantsDocument = gql`
  query GetRestaurants($filter: RestaurantFilter, $limit: Int) {
    allRestaurant(where: $filter, limit: $limit) {
      ...RestaurantFragment
    }
  }
  ${RestaurantFragmentDoc}
`;

/**
 * __useGetRestaurantsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRestaurantsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IGetRestaurantsQuery,
    IGetRestaurantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export function useGetRestaurantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetRestaurantsQuery,
    IGetRestaurantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export type GetRestaurantsQueryHookResult = ReturnType<typeof useGetRestaurantsQuery>;
export type GetRestaurantsLazyQueryHookResult = ReturnType<typeof useGetRestaurantsLazyQuery>;
export type GetRestaurantsQueryResult = Apollo.QueryResult<
  IGetRestaurantsQuery,
  IGetRestaurantsQueryVariables
>;
export const GetRestaurantLoyaltyEnabledDocument = gql`
  query GetRestaurantLoyaltyEnabled($storeId: ID!) {
    Restaurant(id: $storeId) {
      hasLoyalty
    }
  }
`;

/**
 * __useGetRestaurantLoyaltyEnabledQuery__
 *
 * To run a query within a React component, call `useGetRestaurantLoyaltyEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantLoyaltyEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantLoyaltyEnabledQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetRestaurantLoyaltyEnabledQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetRestaurantLoyaltyEnabledQuery,
    IGetRestaurantLoyaltyEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetRestaurantLoyaltyEnabledQuery,
    IGetRestaurantLoyaltyEnabledQueryVariables
  >(GetRestaurantLoyaltyEnabledDocument, options);
}
export function useGetRestaurantLoyaltyEnabledLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetRestaurantLoyaltyEnabledQuery,
    IGetRestaurantLoyaltyEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetRestaurantLoyaltyEnabledQuery,
    IGetRestaurantLoyaltyEnabledQueryVariables
  >(GetRestaurantLoyaltyEnabledDocument, options);
}
export type GetRestaurantLoyaltyEnabledQueryHookResult = ReturnType<
  typeof useGetRestaurantLoyaltyEnabledQuery
>;
export type GetRestaurantLoyaltyEnabledLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantLoyaltyEnabledLazyQuery
>;
export type GetRestaurantLoyaltyEnabledQueryResult = Apollo.QueryResult<
  IGetRestaurantLoyaltyEnabledQuery,
  IGetRestaurantLoyaltyEnabledQueryVariables
>;
export const GetRestaurantFzDetailsDocument = gql`
  query GetRestaurantFzDetails($storeId: ID!) {
    Restaurant(id: $storeId) {
      physicalAddress {
        address1
        address2
        city
        country
        postalCode
        stateProvince
      }
      customerFacingAddress {
        locale: en
      }
      fzAddress
      fzZipCode
      fzCity
      fzeeGroup
      fzAuthorizedRepresentative
      fzEmail
      fzTelephone
      fzRegistryCourt
      fzHrb
      fzUstNr
    }
  }
`;

/**
 * __useGetRestaurantFzDetailsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantFzDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantFzDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantFzDetailsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetRestaurantFzDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetRestaurantFzDetailsQuery,
    IGetRestaurantFzDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetRestaurantFzDetailsQuery,
    IGetRestaurantFzDetailsQueryVariables
  >(GetRestaurantFzDetailsDocument, options);
}
export function useGetRestaurantFzDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetRestaurantFzDetailsQuery,
    IGetRestaurantFzDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetRestaurantFzDetailsQuery,
    IGetRestaurantFzDetailsQueryVariables
  >(GetRestaurantFzDetailsDocument, options);
}
export type GetRestaurantFzDetailsQueryHookResult = ReturnType<
  typeof useGetRestaurantFzDetailsQuery
>;
export type GetRestaurantFzDetailsLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantFzDetailsLazyQuery
>;
export type GetRestaurantFzDetailsQueryResult = Apollo.QueryResult<
  IGetRestaurantFzDetailsQuery,
  IGetRestaurantFzDetailsQueryVariables
>;
export const GetRestaurantVrPaymentMerchantIdDocument = gql`
  query GetRestaurantVrPaymentMerchantId($storeId: ID!) {
    Restaurant(id: $storeId) {
      vrPaymentMerchantId
    }
  }
`;

/**
 * __useGetRestaurantVrPaymentMerchantIdQuery__
 *
 * To run a query within a React component, call `useGetRestaurantVrPaymentMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantVrPaymentMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantVrPaymentMerchantIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetRestaurantVrPaymentMerchantIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetRestaurantVrPaymentMerchantIdQuery,
    IGetRestaurantVrPaymentMerchantIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetRestaurantVrPaymentMerchantIdQuery,
    IGetRestaurantVrPaymentMerchantIdQueryVariables
  >(GetRestaurantVrPaymentMerchantIdDocument, options);
}
export function useGetRestaurantVrPaymentMerchantIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetRestaurantVrPaymentMerchantIdQuery,
    IGetRestaurantVrPaymentMerchantIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetRestaurantVrPaymentMerchantIdQuery,
    IGetRestaurantVrPaymentMerchantIdQueryVariables
  >(GetRestaurantVrPaymentMerchantIdDocument, options);
}
export type GetRestaurantVrPaymentMerchantIdQueryHookResult = ReturnType<
  typeof useGetRestaurantVrPaymentMerchantIdQuery
>;
export type GetRestaurantVrPaymentMerchantIdLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantVrPaymentMerchantIdLazyQuery
>;
export type GetRestaurantVrPaymentMerchantIdQueryResult = Apollo.QueryResult<
  IGetRestaurantVrPaymentMerchantIdQuery,
  IGetRestaurantVrPaymentMerchantIdQueryVariables
>;
export const GetPaymentMethodsControlDocument = gql`
  query GetPaymentMethodsControl($storeId: ID!) {
    Restaurant(id: $storeId) {
      paymentMethods {
        ...PaymentMethodsFragment
      }
    }
  }
  ${PaymentMethodsFragmentDoc}
`;

/**
 * __useGetPaymentMethodsControlQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsControlQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetPaymentMethodsControlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetPaymentMethodsControlQuery,
    IGetPaymentMethodsControlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetPaymentMethodsControlQuery,
    IGetPaymentMethodsControlQueryVariables
  >(GetPaymentMethodsControlDocument, options);
}
export function useGetPaymentMethodsControlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetPaymentMethodsControlQuery,
    IGetPaymentMethodsControlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetPaymentMethodsControlQuery,
    IGetPaymentMethodsControlQueryVariables
  >(GetPaymentMethodsControlDocument, options);
}
export type GetPaymentMethodsControlQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsControlQuery
>;
export type GetPaymentMethodsControlLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsControlLazyQuery
>;
export type GetPaymentMethodsControlQueryResult = Apollo.QueryResult<
  IGetPaymentMethodsControlQuery,
  IGetPaymentMethodsControlQueryVariables
>;
export const LoyaltyRewardsByEngineIdDocument = gql`
  query LoyaltyRewardsByEngineId($ids: [String!]!) {
    allReward(where: { loyaltyEngineId: { in: $ids } }) {
      ...RewardFragment
    }
  }
  ${RewardFragmentDoc}
`;

/**
 * __useLoyaltyRewardsByEngineIdQuery__
 *
 * To run a query within a React component, call `useLoyaltyRewardsByEngineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyRewardsByEngineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyRewardsByEngineIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoyaltyRewardsByEngineIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ILoyaltyRewardsByEngineIdQuery,
    ILoyaltyRewardsByEngineIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyRewardsByEngineIdQuery,
    ILoyaltyRewardsByEngineIdQueryVariables
  >(LoyaltyRewardsByEngineIdDocument, options);
}
export function useLoyaltyRewardsByEngineIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyRewardsByEngineIdQuery,
    ILoyaltyRewardsByEngineIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyRewardsByEngineIdQuery,
    ILoyaltyRewardsByEngineIdQueryVariables
  >(LoyaltyRewardsByEngineIdDocument, options);
}
export type LoyaltyRewardsByEngineIdQueryHookResult = ReturnType<
  typeof useLoyaltyRewardsByEngineIdQuery
>;
export type LoyaltyRewardsByEngineIdLazyQueryHookResult = ReturnType<
  typeof useLoyaltyRewardsByEngineIdLazyQuery
>;
export type LoyaltyRewardsByEngineIdQueryResult = Apollo.QueryResult<
  ILoyaltyRewardsByEngineIdQuery,
  ILoyaltyRewardsByEngineIdQueryVariables
>;
export const LoyaltyRewardsDocument = gql`
  query LoyaltyRewards {
    allReward {
      ...RewardFragment
    }
  }
  ${RewardFragmentDoc}
`;

/**
 * __useLoyaltyRewardsQuery__
 *
 * To run a query within a React component, call `useLoyaltyRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoyaltyRewardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ILoyaltyRewardsQuery,
    ILoyaltyRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ILoyaltyRewardsQuery, ILoyaltyRewardsQueryVariables>(
    LoyaltyRewardsDocument,
    options
  );
}
export function useLoyaltyRewardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyRewardsQuery,
    ILoyaltyRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ILoyaltyRewardsQuery, ILoyaltyRewardsQueryVariables>(
    LoyaltyRewardsDocument,
    options
  );
}
export type LoyaltyRewardsQueryHookResult = ReturnType<typeof useLoyaltyRewardsQuery>;
export type LoyaltyRewardsLazyQueryHookResult = ReturnType<typeof useLoyaltyRewardsLazyQuery>;
export type LoyaltyRewardsQueryResult = Apollo.QueryResult<
  ILoyaltyRewardsQuery,
  ILoyaltyRewardsQueryVariables
>;
export const LoyaltyFeaturedRewardsDocument = gql`
  query LoyaltyFeaturedRewards {
    allRewardsSection(where: { sanityInternalName: { eq: "FeatureRewards" } }) {
      ...RewardsSectionFragment
    }
  }
  ${RewardsSectionFragmentDoc}
`;

/**
 * __useLoyaltyFeaturedRewardsQuery__
 *
 * To run a query within a React component, call `useLoyaltyFeaturedRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyFeaturedRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyFeaturedRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoyaltyFeaturedRewardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ILoyaltyFeaturedRewardsQuery,
    ILoyaltyFeaturedRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ILoyaltyFeaturedRewardsQuery,
    ILoyaltyFeaturedRewardsQueryVariables
  >(LoyaltyFeaturedRewardsDocument, options);
}
export function useLoyaltyFeaturedRewardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ILoyaltyFeaturedRewardsQuery,
    ILoyaltyFeaturedRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ILoyaltyFeaturedRewardsQuery,
    ILoyaltyFeaturedRewardsQueryVariables
  >(LoyaltyFeaturedRewardsDocument, options);
}
export type LoyaltyFeaturedRewardsQueryHookResult = ReturnType<
  typeof useLoyaltyFeaturedRewardsQuery
>;
export type LoyaltyFeaturedRewardsLazyQueryHookResult = ReturnType<
  typeof useLoyaltyFeaturedRewardsLazyQuery
>;
export type LoyaltyFeaturedRewardsQueryResult = Apollo.QueryResult<
  ILoyaltyFeaturedRewardsQuery,
  ILoyaltyFeaturedRewardsQueryVariables
>;
export const SingleAppDataDocument = gql`
  query singleAppData {
    allAppData(limit: 1) {
      _id
    }
  }
`;

/**
 * __useSingleAppDataQuery__
 *
 * To run a query within a React component, call `useSingleAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleAppDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSingleAppDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ISingleAppDataQuery, ISingleAppDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ISingleAppDataQuery, ISingleAppDataQueryVariables>(
    SingleAppDataDocument,
    options
  );
}
export function useSingleAppDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ISingleAppDataQuery,
    ISingleAppDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ISingleAppDataQuery, ISingleAppDataQueryVariables>(
    SingleAppDataDocument,
    options
  );
}
export type SingleAppDataQueryHookResult = ReturnType<typeof useSingleAppDataQuery>;
export type SingleAppDataLazyQueryHookResult = ReturnType<typeof useSingleAppDataLazyQuery>;
export type SingleAppDataQueryResult = Apollo.QueryResult<
  ISingleAppDataQuery,
  ISingleAppDataQueryVariables
>;
export const RequiredUserAcceptanceDocument = gql`
  query RequiredUserAcceptance {
    allStaticPage(where: { requiredUserAcceptance: { eq: true } }) {
      _id
      _updatedAt
      title
      localeTitle {
        locale: en
      }
    }
  }
`;

/**
 * __useRequiredUserAcceptanceQuery__
 *
 * To run a query within a React component, call `useRequiredUserAcceptanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiredUserAcceptanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiredUserAcceptanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequiredUserAcceptanceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IRequiredUserAcceptanceQuery,
    IRequiredUserAcceptanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IRequiredUserAcceptanceQuery,
    IRequiredUserAcceptanceQueryVariables
  >(RequiredUserAcceptanceDocument, options);
}
export function useRequiredUserAcceptanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IRequiredUserAcceptanceQuery,
    IRequiredUserAcceptanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IRequiredUserAcceptanceQuery,
    IRequiredUserAcceptanceQueryVariables
  >(RequiredUserAcceptanceDocument, options);
}
export type RequiredUserAcceptanceQueryHookResult = ReturnType<
  typeof useRequiredUserAcceptanceQuery
>;
export type RequiredUserAcceptanceLazyQueryHookResult = ReturnType<
  typeof useRequiredUserAcceptanceLazyQuery
>;
export type RequiredUserAcceptanceQueryResult = Apollo.QueryResult<
  IRequiredUserAcceptanceQuery,
  IRequiredUserAcceptanceQueryVariables
>;
export const AcceptanceAgremeentsByIdsDocument = gql`
  query AcceptanceAgremeentsByIds($ids: [ID!]!) {
    allStaticPage(where: { _id: { in: $ids } }) {
      _id
      widgets {
        ... on HeaderWidget {
          _key
          _type
          headingContent {
            locale: en
          }
        }
        ... on LocaleBlockTextWidget {
          _key
          _type
          localeBlockTextContent {
            localeRaw: enRaw
          }
        }
      }
      pageHtml {
        code
      }
    }
  }
`;

/**
 * __useAcceptanceAgremeentsByIdsQuery__
 *
 * To run a query within a React component, call `useAcceptanceAgremeentsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptanceAgremeentsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptanceAgremeentsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcceptanceAgremeentsByIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IAcceptanceAgremeentsByIdsQuery,
    IAcceptanceAgremeentsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IAcceptanceAgremeentsByIdsQuery,
    IAcceptanceAgremeentsByIdsQueryVariables
  >(AcceptanceAgremeentsByIdsDocument, options);
}
export function useAcceptanceAgremeentsByIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IAcceptanceAgremeentsByIdsQuery,
    IAcceptanceAgremeentsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IAcceptanceAgremeentsByIdsQuery,
    IAcceptanceAgremeentsByIdsQueryVariables
  >(AcceptanceAgremeentsByIdsDocument, options);
}
export type AcceptanceAgremeentsByIdsQueryHookResult = ReturnType<
  typeof useAcceptanceAgremeentsByIdsQuery
>;
export type AcceptanceAgremeentsByIdsLazyQueryHookResult = ReturnType<
  typeof useAcceptanceAgremeentsByIdsLazyQuery
>;
export type AcceptanceAgremeentsByIdsQueryResult = Apollo.QueryResult<
  IAcceptanceAgremeentsByIdsQuery,
  IAcceptanceAgremeentsByIdsQueryVariables
>;
export const GetStaticPageDocument = gql`
  query GetStaticPage($id: ID!) {
    StaticPage(id: $id) {
      _id
      path {
        _key
        _type
        current
      }
      title
      localeTitle {
        locale: en
      }
      metaTags {
        title {
          locale: en
        }
        description {
          locale: en
        }
      }
      ogTags {
        title {
          locale: en
        }
        description {
          locale: en
        }
        url {
          locale: en
        }
        imageUrl {
          locale: en
        }
      }
      widgets {
        ...NutritionExplorerWidgetFragment
        ... on YoutubeWidget {
          _key
          _type
          videoId
          videoAutoplay
        }
        ... on HeaderWidget {
          _key
          _type
          headingContent {
            locale: en
          }
          taglineContent {
            locale: en
          }
          headerImage {
            locale: en {
              ...ImageFragment
            }
          }
        }
        ... on VideoWidget {
          _key
          _type
          video {
            locale: en {
              ...VideoFileFragment
            }
          }
          caption {
            locale: en
          }
          attributionLink
        }
        ... on AccordionWidget {
          _key
          _type
          accordionTitle {
            locale: en
          }
          accordionContent {
            collapsedContent {
              locale: en
            }
            expandedContent {
              locale: en
            }
          }
        }
        ... on LocaleBlockTextWidget {
          _key
          _type
          blockTextInversion
          localeBlockTextContent {
            localeRaw: enRaw
          }
        }
        ... on PreviewWidget {
          _key
          _type
          previewContent {
            previewImage {
              locale: en {
                ...ImageFragment
              }
            }
            titleText {
              locale: en
            }
            bodyText {
              locale: en
            }
            linkText {
              locale: en
            }
            linkURL
            localizedLinkURL {
              locale: en
            }
          }
        }
        ... on ImageWidget {
          _key
          _type
          caption {
            locale: en
          }
          attributionLink
          image {
            locale: en {
              ...ImageFragment
            }
          }
        }
        ... on CallToActionWidget {
          _key
          _type
          callToActionContent {
            heading {
              locale: en
            }
            subheading {
              locale: en
            }
            body {
              locale: en
            }
            buttonText {
              locale: en
            }
            buttonLink
            link {
              locale: en
            }
            image {
              locale: en {
                ...ImageFragment
              }
            }
          }
        }
        ... on QuoteWidget {
          _key
          _type
          quoteText {
            locale: en
          }
          attributionImage {
            ...ImageFragment
          }
          attributionName
          attributionTitle {
            locale: en
          }
        }
        ... on NutritionInfoWidget {
          _key
          _type
          nutritionalSection {
            name {
              locale: en
            }
            products {
              ...ItemFragment
              ... on NutritionalSection {
                name {
                  locale: en
                }
                products {
                  ...ItemFragment
                }
              }
            }
          }
        }
        ... on MultiWidget {
          _key
          _type
          widget
        }
        ... on DoubleImageWidget {
          _key
          _type
          image1 {
            caption {
              locale: en
            }
            attributionLink
            image {
              locale: en {
                ...ImageFragment
              }
            }
          }
          image2 {
            caption {
              locale: en
            }
            attributionLink
            image {
              locale: en {
                ...ImageFragment
              }
            }
          }
        }
        ... on AnchorWidget {
          _key
          _type
          anchorName {
            locale: en
          }
        }
        ... on RewardsCarouselWidget {
          _key
          _type
          rewardsCarouselTitle {
            locale: en
          }
          rewardsCarouselDescription {
            locale: en
          }
          rewardCarouselCategories {
            _id
            label {
              locale: en
            }
            rewards {
              _id
              name {
                locale: en
              }
              image {
                locale: en {
                  ...ImageFragment
                }
              }
            }
          }
        }
        ... on AnchorLinksWidget {
          _key
          _type
          title {
            locale: en
          }
        }
        ... on DownloadFileWidget {
          _key
          _type
          url {
            locale: en
          }
        }
        ... on LoyaltyBannerWidget {
          _key
          _type
          enabled
          unauthenticatedDesktopBackgroundImage {
            locale: en {
              ...ImageFragment
            }
          }
          unauthenticatedMobileBackgroundImage {
            locale: en {
              ...ImageFragment
            }
          }
        }
        ... on LoyaltyTabSelectorWidget {
          _key
          _type
          tabs {
            _type
            link
            title
          }
        }
        ... on InfoCellsWidget {
          _key
          _type
          title {
            locale: en
          }
          icon {
            locale: en {
              ...ImageFragment
            }
          }
          infoCells {
            loyaltyTiersSignupCellTitle {
              locale: en
            }
            loyaltyTiersSignupCellDescription {
              locale: en
            }
            loyaltyTiersSignupCellImage {
              locale: en {
                ...ImageFragment
              }
            }
          }
        }
      }
      pageCSS {
        code
      }
      pageHtml {
        code
      }
    }
  }
  ${NutritionExplorerWidgetFragmentDoc}
  ${ImageFragmentDoc}
  ${VideoFileFragmentDoc}
  ${ItemFragmentDoc}
`;

/**
 * __useGetStaticPageQuery__
 *
 * To run a query within a React component, call `useGetStaticPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaticPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<IGetStaticPageQuery, IGetStaticPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetStaticPageQuery, IGetStaticPageQueryVariables>(
    GetStaticPageDocument,
    options
  );
}
export function useGetStaticPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetStaticPageQuery,
    IGetStaticPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetStaticPageQuery, IGetStaticPageQueryVariables>(
    GetStaticPageDocument,
    options
  );
}
export type GetStaticPageQueryHookResult = ReturnType<typeof useGetStaticPageQuery>;
export type GetStaticPageLazyQueryHookResult = ReturnType<typeof useGetStaticPageLazyQuery>;
export type GetStaticPageQueryResult = Apollo.QueryResult<
  IGetStaticPageQuery,
  IGetStaticPageQueryVariables
>;
export const GetSupportDataDocument = gql`
  query GetSupportData($supportDataId: ID!) {
    SupportData(id: $supportDataId) {
      _id
      supportCategoryGroups {
        _id
        name {
          locale: en
        }
        supportSubcategories {
          _id
          name {
            locale: en
          }
          knowledgeForceIssueId
          supportSubcategoryForwardToOrders
          supportSubcategoryServiceModeSelector
          supportSubcategoryFaqs {
            supportFaqTitle {
              locale: en
            }
            supportFaqContent {
              localeRaw: enRaw
            }
            supportFaqMParticleEvent
          }
        }
      }
      deliveryNeverArriveSubcategory {
        _id
        name {
          locale: en
        }
        knowledgeForceIssueId
      }
      deliveryMissingItemsSubcategory {
        _id
        name {
          locale: en
        }
        knowledgeForceIssueId
      }
      orderingIssuesCategoryGroup {
        _id
      }
      supportDataFaqs {
        supportFaqTitle {
          locale: en
        }
        supportFaqContent {
          localeRaw: enRaw
        }
        supportFaqMParticleEvent
      }
      supportPolicyCover {
        disclaimerTitle
        disclaimerDescription
        disclaimerContent {
          locale: enRaw
        }
        border
        fontSize
        paddingTop
        paddingRight
        paddingBottom
        paddingLeft
        radius
      }
    }
  }
`;

/**
 * __useGetSupportDataQuery__
 *
 * To run a query within a React component, call `useGetSupportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportDataQuery({
 *   variables: {
 *      supportDataId: // value for 'supportDataId'
 *   },
 * });
 */
export function useGetSupportDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetSupportDataQuery,
    IGetSupportDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IGetSupportDataQuery, IGetSupportDataQueryVariables>(
    GetSupportDataDocument,
    options
  );
}
export function useGetSupportDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetSupportDataQuery,
    IGetSupportDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IGetSupportDataQuery, IGetSupportDataQueryVariables>(
    GetSupportDataDocument,
    options
  );
}
export type GetSupportDataQueryHookResult = ReturnType<typeof useGetSupportDataQuery>;
export type GetSupportDataLazyQueryHookResult = ReturnType<typeof useGetSupportDataLazyQuery>;
export type GetSupportDataQueryResult = Apollo.QueryResult<
  IGetSupportDataQuery,
  IGetSupportDataQueryVariables
>;
export const GetSupportSubcategoryDocument = gql`
  query GetSupportSubcategory($id: ID!) {
    SupportSubcategory(id: $id) {
      _id
      _type
      _createdAt
      _key
      _rev
      _updatedAt
      name {
        locale: en
      }
      knowledgeForceIssueId
      supportSubcategoryFaqs {
        supportFaqTitle {
          locale: en
        }
        supportFaqContent {
          localeRaw: enRaw
        }
        supportFaqMParticleEvent
      }
      directTicketProcessingPercentage
      supportSubcategoryFaq {
        supportFaqTitle {
          locale: en
        }
        supportFaqContent {
          localeRaw: enRaw
        }
        supportFaqMParticleEvent
      }
      supportSubcategoryForwardToOrders
      supportSubcategoryServiceModeSelector
      supportSubcategorySubmitToSutherland
    }
  }
`;

/**
 * __useGetSupportSubcategoryQuery__
 *
 * To run a query within a React component, call `useGetSupportSubcategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportSubcategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportSubcategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportSubcategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetSupportSubcategoryQuery,
    IGetSupportSubcategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetSupportSubcategoryQuery,
    IGetSupportSubcategoryQueryVariables
  >(GetSupportSubcategoryDocument, options);
}
export function useGetSupportSubcategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetSupportSubcategoryQuery,
    IGetSupportSubcategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetSupportSubcategoryQuery,
    IGetSupportSubcategoryQueryVariables
  >(GetSupportSubcategoryDocument, options);
}
export type GetSupportSubcategoryQueryHookResult = ReturnType<typeof useGetSupportSubcategoryQuery>;
export type GetSupportSubcategoryLazyQueryHookResult = ReturnType<
  typeof useGetSupportSubcategoryLazyQuery
>;
export type GetSupportSubcategoryQueryResult = Apollo.QueryResult<
  IGetSupportSubcategoryQuery,
  IGetSupportSubcategoryQueryVariables
>;
export const GetSupportCategoryGroupDocument = gql`
  query GetSupportCategoryGroup($id: ID!) {
    SupportCategoryGroup(id: $id) {
      _id
      name {
        locale: en
      }
      showRestaurantSelector
      supportSubcategories {
        _id
        name {
          locale: en
        }
        knowledgeForceIssueId
        supportSubcategoryForwardToOrders
        supportSubcategoryServiceModeSelector
        supportSubcategoryFaqs {
          supportFaqTitle {
            locale: en
          }
          supportFaqContent {
            localeRaw: enRaw
          }
          supportFaqMParticleEvent
        }
      }
    }
  }
`;

/**
 * __useGetSupportCategoryGroupQuery__
 *
 * To run a query within a React component, call `useGetSupportCategoryGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCategoryGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportCategoryGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportCategoryGroupQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IGetSupportCategoryGroupQuery,
    IGetSupportCategoryGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    IGetSupportCategoryGroupQuery,
    IGetSupportCategoryGroupQueryVariables
  >(GetSupportCategoryGroupDocument, options);
}
export function useGetSupportCategoryGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IGetSupportCategoryGroupQuery,
    IGetSupportCategoryGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    IGetSupportCategoryGroupQuery,
    IGetSupportCategoryGroupQueryVariables
  >(GetSupportCategoryGroupDocument, options);
}
export type GetSupportCategoryGroupQueryHookResult = ReturnType<
  typeof useGetSupportCategoryGroupQuery
>;
export type GetSupportCategoryGroupLazyQueryHookResult = ReturnType<
  typeof useGetSupportCategoryGroupLazyQuery
>;
export type GetSupportCategoryGroupQueryResult = Apollo.QueryResult<
  IGetSupportCategoryGroupQuery,
  IGetSupportCategoryGroupQueryVariables
>;
import { disableFragmentWarnings } from '@apollo/client';
disableFragmentWarnings();
